import { Dispatch, SetStateAction } from "react";

import { ExceptionReportInputType } from "components/ExceptionReports/ExceptionReportForms/ManualExceptionReportForm/ManualExceptionReportForm.types";
import { ExceptionReportType } from "components/ExceptionReports/ExceptionReports.types";
import { FormError } from "helpers/types";

import {
  ExceptionReportRequestedCompensationEnum,
  NoYesEnum,
} from "../../../../baseCODEGEN";

export const emptyFormInput: ExceptionReportInputType = {
  location: "",
  shiftDate: undefined,
  shiftScheduledStartTime: "",
  shiftScheduledEndTime: "",
  breakScheduledTime: undefined,
  details: "",
  differenceInWorkedHours: -1,
  shiftActualStartTime: "",
  shiftActualEndTime: "",
  breakActualTime: undefined,
  requestedCompensation: -1,
  mostSeniorClinician: "",
  patientSafetyImpacted: -1,
  rosteringExceptionReportReason: [],
  rosteringExceptionReportCategory: undefined,
};

export const isFollowingDay = (startTime: string, endTime: string) => {
  if (!startTime || !endTime) return false;

  const startTimeAsNum = Number(
    `${startTime?.slice(0, 2)}${startTime?.slice(3, 5)}`
  );
  const endTimeAsNum = Number(`${endTime?.slice(0, 2)}${endTime?.slice(3, 5)}`);

  return endTimeAsNum <= startTimeAsNum;
};

const conditions = (
  exceptionReportInput: ExceptionReportInputType
): {
  condition: boolean;
  fieldName: string;
  message: string;
}[] => [
  {
    condition: !exceptionReportInput.location,
    fieldName: "location",
    message: "Shift Location is a required field!",
  },
  {
    condition: !exceptionReportInput.shiftDate,
    fieldName: "shiftDate",
    message: "Shift Date is a required field!",
  },
  {
    condition: !exceptionReportInput.shiftScheduledStartTime,
    fieldName: "shiftScheduledStartTime",
    message: "Original Start Time is a required field!",
  },
  {
    condition: !exceptionReportInput.shiftScheduledEndTime,
    fieldName: "shiftScheduledEndTime",
    message: "Original End Time is a required field!",
  },
  {
    condition: !exceptionReportInput.rosteringExceptionReportCategory,
    fieldName: "rosteringExceptionReportCategory",
    message: "Category is a required field!",
  },
  {
    condition: !exceptionReportInput.rosteringExceptionReportReason?.length,
    fieldName: "rosteringExceptionReportReason",
    message: "Reason is a required field! Select at least one reason.",
  },
  {
    condition: exceptionReportInput.differenceInWorkedHours === -1,
    fieldName: "differenceInWorkedHours",
    message: "Difference in worked hours is a required field!",
  },
  {
    condition:
      !exceptionReportInput.shiftActualStartTime &&
      exceptionReportInput.differenceInWorkedHours === 1,
    fieldName: "shiftActualStartTime",
    message: "Actual Start Time is a required field!",
  },
  {
    condition:
      !exceptionReportInput.shiftActualEndTime &&
      exceptionReportInput.differenceInWorkedHours === 1,
    fieldName: "shiftActualEndTime",
    message: "Actual End Time is a required field!",
  },
  {
    condition: exceptionReportInput.patientSafetyImpacted === -1,
    fieldName: "patientSafetyImpacted",
    message: "Patient safety is a required field!",
  },
  {
    condition:
      exceptionReportInput.requestedCompensation === -1 &&
      exceptionReportInput.rosteringExceptionReportCategory
        ?.allowCompensation !== false,
    fieldName: "requestedCompensation",
    message: "Compensation is a required field!",
  },
];

export const validateRequiredFields = (
  exceptionReportInput: ExceptionReportInputType,
  setErrors: Dispatch<
    SetStateAction<FormError<ExceptionReportInputType> | null>
  >
) => {
  setErrors(null);
  conditions(exceptionReportInput).forEach(
    ({ condition, message, fieldName }) => {
      if (condition) setErrors((prev) => ({ ...prev, [fieldName]: message }));
    }
  );

  return conditions(exceptionReportInput).every(({ condition }) => !condition);
};

export const getCompensationValue = (compensation: number | null) => {
  switch (compensation) {
    case 0:
      return ExceptionReportRequestedCompensationEnum.TimeOffInLieu;
    case 1:
      return ExceptionReportRequestedCompensationEnum.Pay;
    case 2:
      return ExceptionReportRequestedCompensationEnum.NoPreference;
    default:
      return null;
  }
};

export const getNoYesValue = (value: number | null) => {
  switch (value) {
    case 0:
      return NoYesEnum.No;
    case 1:
      return NoYesEnum.Yes;
    default:
      return null;
  }
};

export const compensationInputMap: Record<
  ExceptionReportRequestedCompensationEnum,
  number
> = {
  [ExceptionReportRequestedCompensationEnum.TimeOffInLieu]: 0,
  [ExceptionReportRequestedCompensationEnum.Pay]: 1,
  [ExceptionReportRequestedCompensationEnum.NoPreference]: 2,
};

export const noYesInputMap: Record<NoYesEnum, number> = {
  [NoYesEnum.No]: 0,
  [NoYesEnum.Yes]: 1,
};

export const patientSafetyImpactedFormat = (
  value: number | undefined | null
) => {
  switch (value) {
    case 0:
      return "No";
    case 1:
      return "Yes";
    default:
      return null;
  }
};

export const compensationFormat = (value: number | undefined | null) => {
  switch (value) {
    case 0:
      return "TOIL (Time off in Lieu)";
    case 1:
      return "Pay";
    case 2:
      return "No Preference";
    default:
      return null;
  }
};

export const formatCompensationForModal = (
  compensation: string | undefined | null
) => {
  switch (compensation) {
    case "time_off_in_lieu":
      return "TOIL (Time off in Lieu)";
    case "pay":
      return "Pay";
    case "no_preference":
      return "No Preference";
    case "no_compensation":
      return "None";
    default:
      return null;
  }
};

export const formatYesNoEnumForModal = (type: string | undefined | null) => {
  switch (type) {
    case "no":
      return "No";
    case "yes":
      return "Yes";
    default:
      return null;
  }
};

export const getInitialFormInput = (
  exception: ExceptionReportType
): ExceptionReportInputType => {
  if (!exception) return emptyFormInput;

  return {
    id: exception.id ?? "",
    location: exception.location ?? "",
    shiftDate: exception.shiftDate,
    shiftScheduledStartTime: exception.shiftScheduledStartTime ?? "",
    shiftScheduledEndTime: exception.shiftScheduledEndTime ?? "",
    breakScheduledTime: exception.breakScheduledTime ?? "",
    details: exception.details ?? "",
    shiftActualStartTime: exception.shiftActualStartTime ?? "",
    shiftActualEndTime: exception.shiftActualEndTime ?? "",
    breakActualTime: exception.breakActualTime ?? "",
    mostSeniorClinician: exception.mostSeniorClinician ?? "",
    differenceInWorkedHours: exception.differenceInWorkedHours
      ? noYesInputMap[exception.differenceInWorkedHours]
      : emptyFormInput.differenceInWorkedHours,
    patientSafetyImpacted: exception.patientSafetyImpacted
      ? noYesInputMap[exception.patientSafetyImpacted]
      : emptyFormInput.patientSafetyImpacted,
    requestedCompensation: exception.requestedCompensation
      ? compensationInputMap[exception.requestedCompensation]
      : emptyFormInput.requestedCompensation,
    rosteringExceptionReportCategory: exception
      .rosteringExceptionReportReasons?.[0] && {
      allowCompensation:
        exception.rosteringExceptionReportReasons?.[0]
          .rosteringExceptionReportReasonCategory.allowCompensation,
      name: exception.rosteringExceptionReportReasons?.[0]
        .rosteringExceptionReportReasonCategory.name,
      id: exception.rosteringExceptionReportReasons?.[0]
        .rosteringExceptionReportReasonCategory.id,
    },
    rosteringExceptionReportReason:
      exception.rosteringExceptionReportReasons?.map(
        ({ name, id }: { name: string; id: string }) => ({
          name,
          id,
        })
      ),
  };
};
