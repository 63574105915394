import styled from "styled-components";

import { RBGREY } from "@patchworkhealth/web-components";

interface Props {
  expired?: boolean;
}
export const NoEntitlements = ({ expired }: Props) => {
  return (
    <Container>
      {!expired && <EmptyStateIcon />}
      <P>
        You currently don't have any {expired ? "past" : "active"} entitlements.
      </P>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 888px;
  gap: 20px;
`;

const P = styled.p`
  color: ${RBGREY.six};
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
`;

const EmptyStateIcon = () => (
  <svg
    width="135"
    height="122"
    viewBox="0 0 135 122"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M134.236 71.8571L109.593 38.5759C109.086 37.8898 108.425 37.3325 107.662 36.9489C106.9 36.5652 106.059 36.366 105.206 36.3672H30.7919C29.9388 36.366 29.0973 36.5652 28.3353 36.9489C27.5732 37.3325 26.912 37.8898 26.4049 38.5759L1.7616 71.8571V112.415C1.7616 114.622 2.6382 116.738 4.19856 118.298C5.75892 119.859 7.87523 120.735 10.0819 120.735H125.931C128.138 120.735 130.254 119.859 131.814 118.298C133.375 116.738 134.251 114.622 134.251 112.415L134.236 71.8571Z"
      fill="#B2B3B4"
    />
    <path
      d="M125.931 121.479H10.0666C7.65935 121.479 5.35066 120.522 3.64844 118.82C1.94623 117.118 0.989937 114.809 0.989937 112.402V71.8594C0.987747 71.6965 1.03815 71.5374 1.13365 71.4055L25.7769 38.1243C26.357 37.3445 27.1109 36.7108 27.9789 36.2735C28.8468 35.8363 29.8048 35.6075 30.7767 35.6055H105.191C106.162 35.6075 107.12 35.8363 107.988 36.2735C108.856 36.7108 109.61 37.3445 110.19 38.1243L134.834 71.4055C134.929 71.5374 134.979 71.6965 134.977 71.8594V112.417C134.973 114.817 134.019 117.117 132.324 118.815C130.629 120.513 128.33 121.471 125.931 121.479ZM2.51785 72.109V112.417C2.51785 114.423 3.31476 116.347 4.73327 117.766C6.15178 119.184 8.07569 119.981 10.0818 119.981H125.931C127.937 119.981 129.861 119.184 131.279 117.766C132.698 116.347 133.495 114.423 133.495 112.417V72.109L109.003 39.0168C108.564 38.4269 107.994 37.9474 107.337 37.6165C106.681 37.2857 105.956 37.1124 105.221 37.1107H30.7918C30.0566 37.1124 29.332 37.2857 28.6754 37.6165C28.0189 37.9474 27.4486 38.4269 27.0098 39.0168L2.51785 72.109Z"
      fill="#B2B3B4"
    />
    <path
      d="M134.236 71.8598V112.418C134.236 114.624 133.36 116.741 131.799 118.301C130.239 119.861 128.123 120.738 125.916 120.738H10.0668C7.86009 120.738 5.74379 119.861 4.18342 118.301C2.62306 116.741 1.74646 114.624 1.74646 112.418V71.8598H37.607L40.9805 88.5005C41.2858 89.996 42.0986 91.3401 43.2814 92.305C44.4641 93.27 45.944 93.7964 47.4704 93.7952H88.9509C90.4974 93.7939 91.9949 93.253 93.1852 92.2657C94.3755 91.2784 95.1839 89.9066 95.471 88.387L98.5874 71.8145L134.236 71.8598Z"
      fill="#ECECEC"
    />
    <path
      d="M125.923 121.479H10.0667C7.65941 121.479 5.35071 120.522 3.6485 118.82C1.94628 117.118 0.98999 114.809 0.98999 112.402V71.8593C0.98999 71.6587 1.06968 71.4663 1.21153 71.3244C1.35338 71.1826 1.54577 71.1029 1.74638 71.1029H37.607C37.7839 71.0993 37.9565 71.1579 38.0947 71.2685C38.2329 71.3791 38.328 71.5346 38.3633 71.708L41.7369 88.3486C42.0053 89.6762 42.7241 90.8704 43.7716 91.729C44.8191 92.5877 46.131 93.0582 47.4854 93.061H88.966C90.3361 93.0597 91.6628 92.5797 92.7166 91.7041C93.7704 90.8285 94.4851 89.6122 94.7373 88.2654L97.8536 71.6929C97.8874 71.5181 97.9818 71.3608 98.1202 71.2487C98.2585 71.1366 98.432 71.077 98.61 71.0802H134.244C134.444 71.0802 134.637 71.1599 134.779 71.3017C134.92 71.4436 135 71.636 135 71.8366V112.394C135.001 113.587 134.767 114.768 134.311 115.87C133.856 116.972 133.187 117.974 132.344 118.817C131.501 119.661 130.5 120.33 129.399 120.787C128.297 121.244 127.116 121.479 125.923 121.479ZM2.5179 72.6157V112.417C2.5179 114.423 3.31481 116.347 4.73332 117.766C6.15183 119.184 8.07575 119.981 10.0818 119.981H125.923C127.929 119.981 129.853 119.184 131.272 117.766C132.69 116.347 133.487 114.423 133.487 112.417V72.6157H99.2302L96.2047 88.568C95.8905 90.2654 94.9926 91.7995 93.6665 92.9046C92.3404 94.0097 90.6695 94.6162 88.9433 94.6191H47.4854C45.7822 94.6164 44.1322 94.0255 42.8146 92.9463C41.4969 91.8671 40.5925 90.3659 40.2543 88.6966L36.9867 72.6157H2.5179Z"
      fill="#B2B3B4"
    />
    <path
      d="M30.8599 104.21H16.0043C15.8037 104.21 15.6113 104.13 15.4695 103.989C15.3276 103.847 15.2479 103.654 15.2479 103.454C15.2479 103.253 15.3276 103.061 15.4695 102.919C15.6113 102.777 15.8037 102.697 16.0043 102.697H30.8599C31.0605 102.697 31.2529 102.777 31.3947 102.919C31.5366 103.061 31.6163 103.253 31.6163 103.454C31.6163 103.654 31.5366 103.847 31.3947 103.989C31.2529 104.13 31.0605 104.21 30.8599 104.21Z"
      fill="#B2B3B4"
    />
    <path
      d="M123.699 104.21H108.844C108.643 104.21 108.451 104.13 108.309 103.989C108.167 103.847 108.088 103.654 108.088 103.454C108.088 103.253 108.167 103.061 108.309 102.919C108.451 102.777 108.643 102.697 108.844 102.697H123.699C123.9 102.697 124.092 102.777 124.234 102.919C124.376 103.061 124.456 103.253 124.456 103.454C124.456 103.654 124.376 103.847 124.234 103.989C124.092 104.13 123.9 104.21 123.699 104.21Z"
      fill="#B2B3B4"
    />
    <path
      d="M41.1467 26.4065C40.9132 26.4053 40.683 26.35 40.4744 26.2451C40.2657 26.1401 40.0842 25.9882 39.9441 25.8013L29.6496 12.1863C29.4078 11.8653 29.3035 11.4615 29.3595 11.0636C29.4156 10.6657 29.6274 10.3063 29.9483 10.0646C30.2693 9.82287 30.6732 9.71854 31.0711 9.77457C31.4689 9.83061 31.8283 10.0424 32.07 10.3634L42.357 23.9784C42.5985 24.2985 42.7031 24.7013 42.6478 25.0984C42.5925 25.4956 42.3818 25.8545 42.062 26.0963C41.799 26.297 41.4775 26.4059 41.1467 26.4065Z"
      fill="#B8C4C9"
    />
    <path
      d="M89.8888 26.4053C89.5585 26.4021 89.2378 26.2934 88.9736 26.0952C88.6538 25.8533 88.4431 25.4944 88.3878 25.0973C88.3324 24.7002 88.437 24.2973 88.6786 23.9773L98.9731 10.3622C99.2197 10.0563 99.5757 9.85829 99.9658 9.8102C100.356 9.76211 100.749 9.86768 101.063 10.1046C101.376 10.3415 101.585 10.6911 101.646 11.0794C101.706 11.4677 101.613 11.8643 101.386 12.1851L91.099 25.8002C90.9581 25.9881 90.7754 26.1406 90.5654 26.2456C90.3553 26.3506 90.1237 26.4053 89.8888 26.4053Z"
      fill="#B8C4C9"
    />
    <path
      d="M66.7053 21.8688C66.3041 21.8688 65.9193 21.7094 65.6356 21.4257C65.3519 21.142 65.1925 20.7572 65.1925 20.356V1.55966C65.1925 1.15844 65.3519 0.77366 65.6356 0.489958C65.9193 0.206256 66.3041 0.046875 66.7053 0.046875C67.1065 0.046875 67.4913 0.206256 67.775 0.489958C68.0587 0.77366 68.2181 1.15844 68.2181 1.55966V20.356C68.2181 20.7572 68.0587 21.142 67.775 21.4257C67.4913 21.7094 67.1065 21.8688 66.7053 21.8688Z"
      fill="#B8C4C9"
    />
  </svg>
);
