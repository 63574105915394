import dayjs, { Dayjs } from "dayjs";

export const formatWeekDays = (day: number) => {
  switch (day) {
    case 0:
      return "Sun";
    case 1:
      return "Mon";
    case 2:
      return "Tues";
    case 3:
      return "Wed";
    case 4:
      return "Thu";
    case 5:
      return "Fri";
    case 6:
      return "Sat";
    default:
      return null;
  }
};

export const generateFirstDayOfEachWeekInRange = (day: Dayjs): Dayjs[] => {
  const dates: Dayjs[] = [day];
  for (let i = 1; i < 3; i++) {
    const date = dayjs(day).add(i, "week");
    dates.push(date);
  }
  return dates;
};

export const generateOneWeek = (day: Dayjs): Date[] => {
  const dates: Date[] = [];
  for (let i = 0; i < 7; i++) {
    const date = dayjs(day).add(i, "day").toDate();
    dates.push(date);
  }
  return dates;
};
