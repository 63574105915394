import { FormEvent, useState } from "react";
import { Container } from "react-bootstrap";
import toast from "react-hot-toast";

import { Login } from "components/Auth";
import LayoutAuth from "components/Layout/LayoutAuth";
import { Wrapper } from "components/Style";
import { useLoginMutation } from "gql/new-gql/__generated__/mutations.generated";
import { saveTokens } from "helpers/functions";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<string | null>(null);
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);

  const [login, { loading }] = useLoginMutation();

  const loginUser = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const { data } = await login({
        variables: {
          email: email,
          password: password,
        },
      });

      if (data?.login?.token && data?.login?.refreshToken) {
        toast.success("Logging In");
        saveTokens(data.login);
        window.location.reload();
        return;
      } else if (data?.login?.status) {
        setErrors(data.login.status);

        return;
      } else {
        setErrors("Credentials not valid, please try again.");
      }
    } catch (e) {
      setErrors("There was an error, please try again.");
    }
  };

  return (
    <LayoutAuth title="Login">
      <Wrapper>
        <Container>
          <Login
            errors={errors}
            isPasswordHidden={isPasswordHidden}
            setEmail={setEmail}
            setErrors={setErrors}
            setPassword={setPassword}
            loginUser={loginUser}
            setIsPasswordHidden={setIsPasswordHidden}
            loading={loading}
          />
        </Container>
      </Wrapper>
    </LayoutAuth>
  );
}

export default LoginPage;
