import { Dispatch, SetStateAction } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

import { formatTimeInTZ, parseTimeInTZ } from "helpers/functions";

import {
  IntercomHelpers,
  ModalShiftType,
  OnCallInputsType,
  ToolTipFunction,
} from "./TimesheetHelpers";

interface TimesheetsOnCallTimeFramesProps {
  shift: ModalShiftType[number];
  inputs: OnCallInputsType;
  setInputs: Dispatch<SetStateAction<OnCallInputsType>>;
  handlePreviewRates: (shift: ModalShiftType[number]) => void;
}

const TimesheetsOnCallTimeFrames = ({
  shift,
  inputs,
  setInputs,
  handlePreviewRates,
}: TimesheetsOnCallTimeFramesProps) => {
  /* Remove Time Frame -------------------------------------------- */

  const removeTimeFrame = (
    shift: ModalShiftType[0],
    timeFrameIndex: number
  ) => {
    const shiftsCopy = [...inputs.checkedShifts];
    const index = shiftsCopy.findIndex((x) => x.id === shift.id);
    shiftsCopy[index]["timeframes"].splice(timeFrameIndex, 1);

    setInputs({
      ...inputs,
      checkedShifts: shiftsCopy,
    });

    handlePreviewRates(shiftsCopy[index]);
  };

  /* TimeFrames ------------------------------------------------ */
  const selectTimeFrame = (
    shift: ModalShiftType[0],
    field: "startTime" | "endTime",
    value: string | Date | number | null,
    timeFrameIndex: number
  ) => {
    const shiftsCopy = [...inputs.checkedShifts];
    const index = shiftsCopy.findIndex((x) => x.id === shift.id);
    const newTime = parseTimeInTZ(value);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    shiftsCopy[index].timeframes[timeFrameIndex][field] = newTime.toDate();

    setInputs({
      ...inputs,
      checkedShifts: shiftsCopy,
    });

    handlePreviewRates(shiftsCopy[index]);
  };

  /* TimeFrame Breaks -------------------------------------------- */

  const selectTimeFrameBreaks = (
    shift: ModalShiftType[0],
    value: string,
    timeFrameIndex: number
  ) => {
    const shiftsCopy = [...inputs.checkedShifts];
    const index = shiftsCopy.findIndex((x) => x.id === shift.id);

    shiftsCopy[index]["timeframes"][timeFrameIndex]["breaks"] = parseInt(value);

    setInputs({
      ...inputs,
      checkedShifts: shiftsCopy,
    });

    handlePreviewRates(shiftsCopy[index]);
  };

  return (
    <div className="flex-1 sm:pl-8">
      {shift.timeframes.map((timeframe, tfindex) => {
        return (
          <>
            <p className="relative font-bold">
              Call-Out Hours {tfindex + 1}
              <button
                onClick={() => removeTimeFrame(shift, tfindex)}
                className="absolute right-0 mr-10 text-xs underline"
              >
                REMOVE TIMEFRAME
              </button>
              {tfindex === 0 && (
                <div className="absolute top-[-22px] left-[122px]">
                  {ToolTipFunction(IntercomHelpers[1])}
                </div>
              )}
            </p>

            {/* TimeFrame StartTime ------------------------------------------------------ */}

            <div className="h-[74px] flex items-center border-b">
              <p className="font-semibold w-[130px]">Start Time</p>

              <div className="flex flex-col items-center px-4">
                <DatePicker
                  className="w-[full] h-[24px] border rounded-lg text-center font-semibold cursor-pointer p-4 mb-2"
                  selected={
                    new Date(
                      formatTimeInTZ(timeframe.startTime, "DD MMM YYYY HH:mm")
                    )
                  }
                  shouldCloseOnSelect={false}
                  onChange={(date) => {
                    const formattedTime = moment(date).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    );
                    selectTimeFrame(shift, "startTime", formattedTime, tfindex);
                  }}
                  timeInputLabel="Time:"
                  dateFormat="HH:mm"
                  showTimeInput
                />
                <p>{formatTimeInTZ(timeframe.startTime, "DD MMM YYYY")}</p>
              </div>
            </div>

            {/* TimeFrame EndTime ---------------------------------------------------------- */}

            <div className="h-[74px] flex items-center border-b">
              <p className="font-semibold w-[130px]">End Time</p>

              <div className="flex flex-col items-center px-4">
                <DatePicker
                  className="w-[full] h-[24px] border rounded-lg text-center font-semibold cursor-pointer p-4 mb-2"
                  selected={
                    new Date(
                      formatTimeInTZ(timeframe.endTime, "DD MMM YYYY HH:mm")
                    )
                  }
                  shouldCloseOnSelect={false}
                  onChange={(date) => {
                    const formattedTime = moment(date).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    );
                    selectTimeFrame(shift, "endTime", formattedTime, tfindex);
                  }}
                  timeInputLabel="Time:"
                  dateFormat="HH:mm"
                  showTimeInput
                />
                <p>{formatTimeInTZ(timeframe.endTime, "DD MMM YYYY")}</p>
              </div>
            </div>

            {/* TimeFrame Breaks----------------------------------------------------------- */}

            <div className="h-[74px] flex items-center border-b mb-[20px]">
              <p className="font-semibold w-[130px]">Breaks</p>

              <div className="flex flex-col items-center justify-center px-4">
                <input
                  className="w-[full] h-[24px] border rounded-lg text-center font-semibold cursor-pointer p-4 mb-2"
                  min="0"
                  type="number"
                  value={timeframe.breaks}
                  onChange={(e) =>
                    selectTimeFrameBreaks(shift, e.target.value, tfindex)
                  }
                />
                <p>Mins</p>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default TimesheetsOnCallTimeFrames;
