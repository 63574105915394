import { Dispatch, Fragment, SetStateAction, useState } from "react";
import dayjs from "dayjs";
import styled from "styled-components";

import { FormSelect, GLOBAL, GREY } from "@patchworkhealth/web-components";

import { useActivitiesAndAlsoWorkingOnShift } from "components/Schedule/hooks/useServicePlanActivitiesForShiftModal";
import { LeaveRequest } from "components/Shared/LeaveRequest/LeaveRequest";
import { ProfileImage } from "components/Shared/ProfileImage";
import { RosteringShiftList } from "components/Shared/RosteringShiftList/RosteringShiftList";
import { ShiftModal } from "components/Shared/ShiftModal/ShiftModal";
import { RosteringTeamRotaQuery } from "components/TeamRota/__generated__/TeamRota.generated";
import { getConcurrentDates } from "components/TeamRota/TeamRota.helpers";
import { EmptyTeamRota } from "components/TeamRota/TeamRotaTable/components/EmptyTeamRota";
import { LeaveRequestTooltip } from "components/TeamRota/TeamRotaTable/components/LeaveCallendarPillTooltip";
import {
  formatLeaveType,
  formatTooltipTimeBottom,
  formatTooltipTimeTop,
  hasRosterOrgRegEvents,
  isTeamRotaEmpty,
  isWithinDay,
  TeamRotaEventType,
} from "components/TeamRota/TeamRotaTable/TeamRotaTable.helpers";
import { TeamRotaTableFiltersType } from "components/TeamRota/TeamRotaTable/TeamRotaTableFilters.types";
import { getBankHoliday, useBankHolidays } from "helpers/hooks/useBankHolidays";

import {
  RosteringLeaveRequestStatusEnum,
  RosteringLeaveTypeEnum,
} from "../../../baseCODEGEN";
import { GradeLineDivider } from "./components/GradeLineDivider";

interface Props {
  weekStartDate: Date;
  teamRotaData: RosteringTeamRotaQuery["rosteringTeamRota"] | undefined;
  loading: boolean;
  filters: TeamRotaTableFiltersType;
  setFilters: Dispatch<SetStateAction<TeamRotaTableFiltersType>>;
  teamRotaWorkers:
    | { workerName: string | null | undefined; id: string }[]
    | undefined;
}

export const TeamRotaTable = ({
  weekStartDate,
  teamRotaData,
  loading,
  filters,
  setFilters,
  teamRotaWorkers,
}: Props) => {
  const [shift, setShift] = useState<TeamRotaEventType | null>(null);
  const bankHolidays = useBankHolidays();
  const currentWeek = getConcurrentDates(weekStartDate);

  const eventIds =
    teamRotaData
      ?.flatMap(({ rosteringOrganisationRegistrations }) =>
        rosteringOrganisationRegistrations.map(({ eventsOnRoster }) =>
          eventsOnRoster.map(({ id }) => +id)
        )
      )
      .flat() || [];

  const {
    activitiesForShift,
    activitiesLabelForShift,
    alsoWorkingOnThisShift,
  } = useActivitiesAndAlsoWorkingOnShift({
    eventIds,
  });

  return (
    <>
      <div className="flex flex-col mt-3 bg-white border-b border-l border-r min-w-fit">
        <div className="flex position-sticky top-[62px] bg-white z-10 pt-4 pl-[24px] border-t ">
          <div className="w-[200px] mr-[12px]">
            <FormSelect
              placeholder="Select worker"
              options={teamRotaWorkers}
              getOptionLabel={(option) => option.workerName ?? ""}
              getOptionValue={(option) => option.id}
              value={filters.worker}
              onChange={(worker) => {
                setFilters((prev) => ({ ...prev, worker }));
              }}
              isClearable
            />
          </div>
          <div className="flex flex-1">
            {currentWeek.map((day, index) => {
              const bankHoliday = getBankHoliday(dayjs(day), bankHolidays);

              return (
                <div
                  className={`h-[81px]  min-w-[141px] w-[100%] border-r p-1 ${
                    index === currentWeek.length - 1 ? "border-r-0" : ""
                  }`}
                  key={day.getTime()}
                >
                  <p className="text-[12px] font-[600] text-[#9CA5B0] uppercase">
                    {dayjs(day).format("ddd")}
                  </p>
                  <p className="text-[22px] font-[600] text-blue-7 uppercase">
                    {dayjs(day).format("DD")}
                  </p>
                  {bankHoliday && (
                    <div className="flex gap-1 align-items-center">
                      <BankHolidayIcon />
                      <p className="text-[#C54714] text-[10px]">
                        {bankHoliday.title}
                      </p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-1 flex-col pl-[24px]">
          {teamRotaData?.map(
            ({ grade, rosteringOrganisationRegistrations }) =>
              hasRosterOrgRegEvents(rosteringOrganisationRegistrations) && (
                <Fragment key={grade?.id}>
                  <GradeLineDivider grade={grade} />
                  {rosteringOrganisationRegistrations.map(
                    ({
                      id,
                      workerName,
                      profilePictureUrl,
                      rosteringLeaveRequests,
                      eventsOnRoster,
                    }) => {
                      if (
                        !rosteringLeaveRequests.length &&
                        !eventsOnRoster.length
                      )
                        return null;

                      return (
                        <div className="flex" key={id}>
                          <div className="flex align-items-center min-w-[100px] w-[200px] mr-[12px]">
                            <ProfileImage
                              size="small"
                              src={profilePictureUrl}
                            />
                            <div className="w-[70%]">
                              <p className="text-[0.875rem] font-[600] text-ellipsis overflow-hidden whitespace-nowrap">
                                {workerName}
                              </p>
                              <p
                                className="text-[0.75rem] font-[500] text-ellipsis overflow-hidden whitespace-nowrap"
                                style={{ color: grade?.colour ?? "" }}
                              >
                                {grade?.title}
                              </p>
                            </div>
                          </div>
                          <div className="grid grid-cols-7">
                            {currentWeek.map((day, index) => {
                              const leaveRequests =
                                rosteringLeaveRequests.filter(
                                  ({ startDate, endDate }) =>
                                    isWithinDay({
                                      currentDay: day,
                                      startDate: new Date(startDate),
                                      endDate: new Date(endDate),
                                      isLeaveRequest: true,
                                    })
                                );

                              const shifts = eventsOnRoster.filter(
                                ({ startsAt, endsAt }) =>
                                  isWithinDay({
                                    currentDay: day,
                                    startDate: new Date(startsAt),
                                    endDate: new Date(endsAt),
                                    isLeaveRequest: true,
                                  })
                              );

                              const isMultiple =
                                shifts.length + leaveRequests.length > 1;
                              return (
                                <RosteringShiftList
                                  activitiesLabelForShift={
                                    activitiesLabelForShift
                                  }
                                  key={day.getTime() + index}
                                  shifts={shifts}
                                  isMultipleShifts={isMultiple}
                                  onShiftClick={(s) => {
                                    setShift(s);
                                  }}
                                  styles={{
                                    width: "149px",
                                  }}
                                  minShiftHeight={isMultiple ? "13px" : "41px"}
                                >
                                  {leaveRequests.map((leaveRequest) => {
                                    const leaveType = leaveRequest
                                      ?.rosteringLeaveType
                                      ?.leaveType as RosteringLeaveTypeEnum;

                                    return (
                                      <LeaveRequestTooltip
                                        key={leaveRequest.id}
                                        trigger={
                                          <LeaveRequest
                                            key={leaveRequest.id}
                                            leaveType={leaveType}
                                            leaveStatus={
                                              leaveRequest?.status as RosteringLeaveRequestStatusEnum
                                            }
                                          />
                                        }
                                      >
                                        <TooltipContainer>
                                          <p className="font-semibold">
                                            {formatLeaveType(leaveType)}
                                          </p>

                                          <p>
                                            {formatTooltipTimeTop(
                                              leaveRequest.startDate
                                            )}
                                          </p>

                                          <p>
                                            {formatTooltipTimeBottom(
                                              leaveRequest.endDate
                                            )}
                                          </p>
                                        </TooltipContainer>
                                      </LeaveRequestTooltip>
                                    );
                                  })}
                                </RosteringShiftList>
                              );
                            })}
                          </div>
                        </div>
                      );
                    }
                  )}
                </Fragment>
              )
          )}
        </div>
      </div>

      <EmptyTeamRota
        isEmpty={isTeamRotaEmpty(teamRotaData)}
        isLoading={loading}
      />
      {shift && (
        <ShiftModal
          eventId={+shift?.id}
          onHide={() => setShift(null)}
          activities={activitiesForShift(+shift?.id)}
          alsoWorkingOnThisShift={alsoWorkingOnThisShift(+shift.id)}
          activitiesLabelForShift={activitiesLabelForShift}
        />
      )}
    </>
  );
};
//TODO: MOVE TO SHARED COMPONENTS
export const EventsContainer = styled.div`
  align-items: center;
  border-right: 1px solid ${GREY.three};
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 8px;
  height: 57px;

  &:last-child {
    border-right: none;
  }

  button:first-child {
    border-top-right-radius: 4px;
  }

  button:last-child {
    border-bottom-right-radius: 4px;
  }
`;

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;

  p {
    color: ${GLOBAL.white};
    text-transform: capitalize;
  }
`;

const BankHolidayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="none"
  >
    <circle cx="4" cy="4.5" r="4" fill="#FFAD8C" />
    <circle cx="4" cy="4.5" r="2" fill="#C54714" />
  </svg>
);
