import styled from "styled-components";

import { GREY, RBCYAN } from "@patchworkhealth/web-components";

export const ExceptionReportDetailsContainer = styled.div`
  background: ${GREY.one};
  border: 1px solid ${GREY.two};
  border-radius: 8px;
  padding: 38px;
  display: flex;
  margin: 20px 0;
`;

export const TimeBlueContainer = styled.div`
  background: ${RBCYAN.two};
  border-radius: 6px;
  padding: 18px 24px;
  display: flex;
  gap: 20px;
  margin-top: 10px;
`;

export const HeaderLabel = styled.p`
  font-weight: 700;
  font-size: 10px !important;
  text-transform: uppercase;
  letter-spacing: 0.025rem;
  line-height: 12px;
  color: ${GREY.ten};
  margin: 0;
`;

export const ItemLabel = styled.p`
  margin-top: 3px !important;
  font-weight: 400;
  font-size: 12px !important;
  color: ${GREY.ten};
`;
