import { useState } from "react";
import dayjs from "dayjs";

import { RBRED } from "@patchworkhealth/web-components";

import { ExceptionReportStatus } from "components/ExceptionReports/components/ExceptionReportStatus/ExceptionReportStatus";
import { ShiftIcon } from "components/Shared/ShiftIcon";

import {
  formattedOvertimeString,
  isOvertimeValid,
} from "../ExceptionReports.helpers";
import {
  ExceptionReportShiftTypeIcon,
  TimeText,
} from "../ExceptionReports.styled";
import {
  ExceptionReportContainer,
  OvertimeLabel,
  TextItem,
  ViewReportButton,
} from "../ExceptionReports.styled";
import { ExceptionReportsType } from "../ExceptionReports.types";
import ExceptionReportDeleteModal from "../modals/ExceptionReportDeleteModal";

interface Props {
  exceptionReport: ExceptionReportsType;
  viewExceptionReport: (id: string) => void;
  editExceptionReport: (report: ExceptionReportsType) => void;
}

const ExceptionReportTableRow = ({
  exceptionReport,
  viewExceptionReport,
  editExceptionReport,
}: Props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const filteredCategories =
    exceptionReport?.rosteringExceptionReportReasons?.map(
      (report) => report.rosteringExceptionReportReasonCategory.name
    );

  const isDraft = exceptionReport?.status === "draft";
  const isPending = exceptionReport?.status === "pending";

  const handleDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const rosterName = exceptionReport?.rosteringEvent?.roster?.rota?.name;

  const date = exceptionReport.rosteringEvent
    ? exceptionReport.rosteringEvent.startsAt.slice(0, 10)
    : exceptionReport.shiftDate;

  const startTime = exceptionReport?.rosteringEvent
    ? exceptionReport?.rosteringEvent.startsAt.slice(11, 16)
    : exceptionReport?.shiftScheduledStartTime?.slice(0, 5);

  const endTime = exceptionReport?.rosteringEvent
    ? exceptionReport?.rosteringEvent.endsAt.slice(11, 16)
    : exceptionReport?.shiftScheduledEndTime?.slice(0, 5);

  const location = exceptionReport.rosteringEvent
    ? rosterName
    : exceptionReport.location;

  return (
    <>
      {showDeleteModal && (
        <ExceptionReportDeleteModal
          onHide={() => setShowDeleteModal(false)}
          exceptionReportId={exceptionReport.id}
        />
      )}

      <ExceptionReportContainer>
        <TextItem>{exceptionReport?.id}</TextItem>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              minHeight: "fit-content",
            }}
          >
            {exceptionReport?.rosteringEvent?.scheduleable?.shiftType?.name && (
              <ExceptionReportShiftTypeIcon>
                <ShiftIcon
                  type={
                    exceptionReport?.rosteringEvent?.scheduleable?.shiftType
                      ?.name
                  }
                />
              </ExceptionReportShiftTypeIcon>
            )}

            <div style={{ minWidth: "fit-content" }}>
              <TextItem style={{ fontWeight: 600, paddingBottom: 3 }}>
                {date ? dayjs(date)?.format("Do MMM YYYY") : "-"}
              </TextItem>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "fit-content",
                }}
              >
                <TimeText>
                  {startTime} - {endTime}
                </TimeText>

                {isOvertimeValid(exceptionReport?.overtime) ? (
                  <OvertimeLabel>
                    {formattedOvertimeString(exceptionReport?.overtime)}
                  </OvertimeLabel>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
        </div>

        <TextItem>{location || "-"}</TextItem>

        <TextItem>
          {!filteredCategories?.length ? "-" : filteredCategories?.[0]}
        </TextItem>

        <TextItem>
          {exceptionReport.submittedAt
            ? dayjs(exceptionReport.submittedAt).fromNow()
            : "-"}
        </TextItem>

        <ExceptionReportStatus status={exceptionReport.status} />

        <ViewReportButton
          onClick={() =>
            exceptionReport?.status === "draft"
              ? editExceptionReport(exceptionReport)
              : viewExceptionReport(exceptionReport.id)
          }
        >
          {exceptionReport?.status === "draft" ? "Edit" : "View"}
        </ViewReportButton>

        {isDraft || isPending ? (
          <ViewReportButton
            style={{ color: RBRED.six }}
            onClick={handleDeleteModal}
          >
            Delete
          </ViewReportButton>
        ) : (
          <div />
        )}
      </ExceptionReportContainer>
    </>
  );
};

export default ExceptionReportTableRow;
