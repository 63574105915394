import * as Tooltip from "@radix-ui/react-tooltip";

import { ApiActivitySummary } from "@patchworkhealth/serviceplans-api";
import { GREY } from "@patchworkhealth/web-components";

import { ShiftIcon } from "components/Shared/ShiftIcon";
import { Skeleton } from "helpers/components/Skeleton";
import { formatTimeInTZ } from "helpers/momentHelpers";
import { SailFlag, useFeatureFlag } from "helpers/useFeatureFlags";
import {
  CloseIcon,
  ModalCalendarIcon,
  ModalDepartmentIcon,
  ModalNotesIcon,
  ModalPhoneIcon,
  ModalSiteIcon,
} from "icons/calendarIcons";

import { ProfileImage } from "../ProfileImage";
import {
  RosteringShiftFragment,
  useRosteringEventQuery,
  useRosteringEventsByIdQuery,
  useRosterWorkersInShiftQuery,
} from "./__generated__/ShiftModal.generated";
import {
  ActivityPill,
  ModalBody,
  ModalContent,
  ModalStrip,
  StyledModal,
  WorkerPopover,
  WorkersOnShift,
} from "./ShiftModal.styled";

interface Props {
  eventId: number;
  onHide: () => void;
  activities: ApiActivitySummary[];
  alsoWorkingOnThisShift: number[];
  activitiesLabelForShift: (id: number, name: string | undefined) => string;
}

export const ShiftModal = ({
  onHide,
  eventId,
  activities,
  activitiesLabelForShift,
  alsoWorkingOnThisShift,
}: Props) => {
  const { data: shift, loading: shiftLoading } = useRosteringEventQuery({
    variables: { eventId },
  });

  const servicePlansEnabled = useFeatureFlag(SailFlag.servicePlansEnabled);

  const { data: workersInShiftData, loading: workersInShiftLoading } =
    useRosterWorkersInShiftQuery(
      shift?.rosteringEvent?.scheduleable?.id
        ? {
            variables: {
              shiftId: +shift?.rosteringEvent?.scheduleable?.id,
            },
            skip: activities.length > 0 && servicePlansEnabled,
          }
        : { skip: true }
    );

  const {
    data: workersInShiftDataUsingServicePlans,
    loading: workersInShiftLoadingUsingServicePlans,
  } = useRosteringEventsByIdQuery(
    shift?.rosteringEvent?.roster?.uniqueIdentifier && activities.length > 0
      ? {
          variables: {
            eventIds: alsoWorkingOnThisShift,
            rosterUniqueIdentifier:
              shift?.rosteringEvent?.roster?.uniqueIdentifier,
          },
          skip: !servicePlansEnabled,
        }
      : { skip: true }
  );

  const scheduleable = shift?.rosteringEvent?.scheduleable;
  const startsAt = shift?.rosteringEvent?.startsAt;
  const endsAt = shift?.rosteringEvent?.endsAt;

  const shiftName = scheduleable?.shiftType?.name;
  const phoneNumber = scheduleable?.eventWithPublicUserInfo?.phoneNumber;
  const organisationActivity = scheduleable?.organisationActivity;
  const sites = scheduleable?.sites;
  const location = scheduleable?.locations;
  const workerNotes = scheduleable?.workerNote;
  const alsoWorkingOnTheShiftUsingServicePlans =
    workersInShiftDataUsingServicePlans?.rosteringEventsById
      ?.map((event) => {
        if (event.scheduleable) {
          return event.scheduleable;
        }
      })
      .filter((event) => event !== undefined);

  return (
    <>
      <StyledModal
        show
        onHide={onHide}
        size="lg"
        className="calendar__modal"
        centered
      >
        <ModalBody type={shiftName}>
          <ModalStrip type={shiftName}>
            <div className="top_strip">
              {shiftLoading ? (
                <Skeleton height={40} width={40} circle />
              ) : (
                <ShiftIcon type={shiftName} />
              )}
              {shiftLoading ? (
                <Skeleton width={80} />
              ) : (
                <h3 data-testid="modal_shift_type">
                  {`${shiftName?.toUpperCase()} SHIFT`}
                </h3>
              )}
            </div>

            <div className="bottom_strip">
              <p>REQUEST SICK LEAVE</p>
              <div>
                <ModalPhoneIcon />
                <span>{phoneNumber}</span>
              </div>
            </div>
          </ModalStrip>

          <ModalContent>
            <button onClick={onHide}>
              <CloseIcon />
            </button>

            <div
              style={{ display: "flex", alignItems: "flex-start", gap: "5px" }}
            >
              <ModalCalendarIcon />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h2>
                  {startsAt
                    ? formatTimeInTZ(startsAt, "dddd, MMMM Do YYYY")
                    : "-"}{" "}
                </h2>
                <p data-testid="modal_shift_time">
                  {startsAt && endsAt
                    ? `${formatTimeInTZ(startsAt, "HH:mma")} - ${formatTimeInTZ(
                        endsAt,
                        "HH:mma"
                      )}`
                    : "-"}
                </p>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", gap: "5px" }}>
                <ModalDepartmentIcon />
                <h2 data-testid="modal_shift_activity">
                  {activitiesLabelForShift(eventId, organisationActivity?.name)}
                </h2>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  marginTop: "12px",
                  flexWrap: "wrap",
                }}
              >
                {activities?.length > 0 &&
                  activities.map((activity) => (
                    <ActivityPill key={activity.id}>
                      <div
                        style={{ color: GREY.six }}
                      >{`${activity.startTime} - ${activity.endTime}`}</div>
                      <div
                        style={{
                          fontWeight: "bold",
                          color: GREY.nine,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {activity.name}
                      </div>
                    </ActivityPill>
                  ))}
              </div>
            </div>
            {!!sites?.length && (
              <div
                data-testid="modal_site_location"
                style={{ display: "flex", gap: "5px" }}
              >
                <ModalSiteIcon />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h2 data-testid="modal_shift_site">
                    {sites.map(({ name }) => name).join(", ")}
                  </h2>
                  <p
                    data-testid="modal_shift_locations"
                    style={{ marginBottom: "-15px" }}
                  >
                    {location?.map(({ name }) => name).join(", ")}
                  </p>
                </div>
              </div>
            )}

            <div style={{ display: "flex", gap: "5px" }}>
              <ModalNotesIcon />
              <div>
                <h2>Notes</h2>
                <p data-testid="modal_shift_notes">
                  {workerNotes?.slice(0, 500) || "-"}
                </p>
              </div>
            </div>

            <h4 style={{ fontWeight: "600", color: GREY.ten }}>
              Also on this shift:
            </h4>
            <WorkersInShift
              rosterWorkersInShift={
                workersInShiftData
                  ? workersInShiftData.rosterWorkersInShift
                  : alsoWorkingOnTheShiftUsingServicePlans
              }
              loading={
                workersInShiftLoading || workersInShiftLoadingUsingServicePlans
              }
            />
          </ModalContent>
        </ModalBody>
      </StyledModal>
    </>
  );
};

const WorkersInShift = ({
  rosterWorkersInShift,
  loading,
}: {
  rosterWorkersInShift: RosterShift[] | undefined | null;
  loading: boolean;
}) => {
  if (loading) {
    return (
      <WorkersOnShift>
        {[...Array(8).keys()].map((_, index) => (
          <Skeleton height={40} width={40} circle key={index} />
        ))}
      </WorkersOnShift>
    );
  }

  if (!rosterWorkersInShift?.length) return null;

  return (
    <>
      <WorkersOnShift>
        {rosterWorkersInShift.map((worker) => (
          <Tooltip.Provider key={worker?.id} delayDuration={100}>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <div
                  style={{
                    border: `2px solid ${worker?.grade?.colour}`,
                    borderRadius: "22px",
                  }}
                >
                  <ProfileImage
                    size="small"
                    src={worker?.eventWithPublicUserInfo?.profilePictureUrl}
                  />
                </div>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <WorkerPopover>
                  <p>
                    {worker?.eventWithPublicUserInfo?.firstName?.slice(0, 12)}
                  </p>

                  <span style={{ color: worker?.grade?.colour ?? "" }}>
                    {worker?.grade?.title}
                  </span>
                </WorkerPopover>
              </Tooltip.Portal>
            </Tooltip.Root>
          </Tooltip.Provider>
        ))}
      </WorkersOnShift>
    </>
  );
};

type RosterShift = RosteringShiftFragment | undefined;
