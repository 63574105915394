import styled from "styled-components";

import { GLOBAL, GREY, RBRED } from "@patchworkhealth/web-components";

export const ExceptionReportRow = styled.div.attrs({ role: "row" })`
  border-bottom: 1px solid ${GLOBAL.borderGreyLight};
  display: grid;
  align-items: center;
`;

export const GridContainer = styled.div.attrs({ role: "table" })`
  flex: 1 0 0;
  min-height: 64px;
  overflow: auto;
  border: 1px solid ${GREY.two};
  background: ${GLOBAL.white};
  border-collapse: separate;
  border-radius: 8px;
  border-spacing: 0;
  width: 100%;

  ${ExceptionReportRow} {
    grid-template-columns: 0.3fr 1.7fr 1.5fr 1.7fr 1.2fr 0.8fr 0.5fr 0.5fr;
  }
`;

export const GridHeader = styled(ExceptionReportRow)`
  background: ${GLOBAL.white};
  position: sticky;
  top: 0;
  align-items: center;
  && {
    padding: 10px 10px;
  }
`;

export const ColumnHeader = styled.span.attrs({ role: "columnheader" })`
  color: ${GREY.five};
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.025em;
`;

export const ScrollableContainer = styled.div.attrs({ role: "row" })`
  display: block;
  max-height: 70vh;
  overflow: auto;
`;

export const ExceptionReportContainer = styled.div.attrs({ role: "row" })`
  border: 1px solid ${GREY.two};
  height: 64px;
  display: grid;
  grid-template-columns: 0.3fr 1.7fr 1.5fr 1.7fr 1.2fr 0.8fr 0.5fr 0.5fr;
  align-items: center;
  padding: 10px 10px;
`;

export const ViewReportButton = styled.button`
  color: ${GREY.five};
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

export const TextItem = styled.p`
  color: ${GREY.ten};
  font-size: 14px !important;
  margin-bottom: 0;
  padding: 0 3px 0 5px;
  line-height: normal;
`;

export const TimeText = styled.p`
  font-size: 12px !important;
  padding: 0 3px 0 6px;
  margin-bottom: 0;
`;
export const ExceptionReportShiftTypeIcon = styled.div`
  margin-right: 3px;
  svg {
    width: 25px;
    height: 25px;
  }
`;

export const OvertimeLabel = styled.p`
  background: ${RBRED.one};
  border-radius: 4px;
  color: ${RBRED.six};
  font-size: 10px !important;
  font-weight: 700;
  height: fit-content;
  padding: 2px 9px;
  text-align: center;
  text-transform: uppercase;
`;
