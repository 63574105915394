import styled from "styled-components";

import { BLUE, GREY } from "@patchworkhealth/web-components";

const FiltersOption = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 47px;
  margin: 0;
  padding: 0 16px;
  transition: 0.2s;

  &:hover {
    background: ${BLUE.one};
  }

  & + & {
    border-top: 1px solid ${GREY.two};
  }

  input {
    ~ * {
      color: ${GREY.five};
    }

    &:checked {
      color: ${GREY.ten};
    }
  }
`;

export default FiltersOption;
