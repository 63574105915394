import toast from "react-hot-toast";

import { DefaultButton } from "@patchworkhealth/web-components";

import { formatTimeInTZ, parseTimeInTZ } from "helpers/functions";

import {
  IntercomHelpers,
  ModalShiftType,
  OnCallInputsType,
  ToolTipFunction,
  ZeroHourDatePicker,
} from "./TimesheetHelpers";

interface TimesheetsOnCallTimeFramesProps {
  shift: ModalShiftType[number];
  inputs: OnCallInputsType;
  setInputs: React.Dispatch<React.SetStateAction<OnCallInputsType>>;
  handlePreviewRates: (shift: ModalShiftType[number]) => void;
}

const TimesheetsOnCallHours = ({
  shift,
  inputs,
  setInputs,
  handlePreviewRates,
}: TimesheetsOnCallTimeFramesProps) => {
  /* Handle Input Times ------------------------------------------- */

  const selectTime = (
    shift: ModalShiftType[0],
    time: "startTime" | "endTime",
    value: string | Date | null
  ) => {
    const shiftsCopy = [...inputs.checkedShifts];
    const index = shiftsCopy.findIndex((x) => x.id === shift.id);
    const selectedDateTime = parseTimeInTZ(value);

    if (time === "startTime") {
      const start = new Date(selectedDateTime.toDate());
      const end = new Date(shiftsCopy[index].endTime);

      if (start > end) {
        toast.error("Start time cannot be after end time.");
        return;
      }

      shiftsCopy[index].startTime = selectedDateTime.toDate();
    }

    if (time === "endTime") {
      const end = new Date(selectedDateTime.toDate());
      const start = new Date(shiftsCopy[index].startTime);

      if (end < start) {
        toast.error("End time cannot be before start time.");
        return;
      }
      shiftsCopy[index].endTime = selectedDateTime.toDate();
    }

    shiftsCopy[index].showReason = true;

    setInputs({
      ...inputs,
      checkedShifts: shiftsCopy,
    });

    handlePreviewRates(shiftsCopy[index]);
  };

  /* Increment/Decrement Times ---------------------------------- */

  const handleReset = (shift: ModalShiftType[0]) => {
    const shiftsCopy = [...inputs.checkedShifts];
    const index = shiftsCopy.findIndex((x) => x.id === shift.id);

    shiftsCopy[index].breakMinutes = shiftsCopy[index].originalBreakMinutes;
    shiftsCopy[index].startTime = shiftsCopy[index].originalStartTime;
    shiftsCopy[index].endTime = shiftsCopy[index].originalEndTime;
    shiftsCopy[index].showReason = false;
    shiftsCopy[index].reason = "";
    shiftsCopy[index].timeframes = [];

    setInputs({
      ...inputs,
      checkedShifts: shiftsCopy,
    });

    handlePreviewRates(shiftsCopy[index]);
  };

  return (
    <div className="sm:pl-8 sm:mt-[-10px]">
      <div className="flex">
        <p className="font-bold">On-Call Hours</p>
        {ToolTipFunction(IntercomHelpers[0])}
      </div>

      {/* StartTime ----------------------------------------------------------- */}

      <div className="flex h-auto mb-2 border-b">
        <p className="font-semibold w-[150px] mt-3">Start Time</p>

        <div className="flex flex-col items-center px-4 ">
          <ZeroHourDatePicker
            shift={shift}
            time={formatTimeInTZ(shift.startTime, "DD MMM YYYY HH:mm")}
            value="startTime"
            selectTime={selectTime}
          />

          <p>{formatTimeInTZ(shift.startTime, "DD MMM YYYY")}</p>
        </div>
      </div>

      {/* EndTime ------------------------------------------------------------- */}

      <div className="flex h-auto mb-2 border-b">
        <p className="font-semibold w-[150px] mt-3">End Time</p>

        <div className="flex flex-col items-center px-4 ">
          <ZeroHourDatePicker
            shift={shift}
            time={formatTimeInTZ(shift.endTime, "DD MMM YYYY HH:mm")}
            value="endTime"
            selectTime={selectTime}
          />
          <p>{formatTimeInTZ(shift.endTime, "DD MMM YYYY")}</p>
        </div>
      </div>

      {/* Reset Button --------------------------------------------------------- */}

      <div className="flex items-center mt-3">
        {shift.showReason && (
          <DefaultButton
            type={"grey"}
            text={"Reset"}
            onClick={() => handleReset(shift)}
          />
        )}
      </div>
    </div>
  );
};

export default TimesheetsOnCallHours;
