import { Dispatch, SetStateAction } from "react";
import { components, ControlProps, OptionProps } from "react-select";
import styled from "styled-components";

import {
  FormCheckbox,
  FormGroup,
  FormLabel,
  FormSelect,
  GLOBAL,
  GREY,
  RBBLACK,
  RBGREY,
} from "@patchworkhealth/web-components";

import { ActivityName } from "components/Schedule/hooks/useServicePlansShiftActivities";
import { RosteringWorkerRostersQuery } from "components/TeamRota/__generated__/TeamRota.generated";
import { SingleRosterType } from "components/TeamRota/hooks/useWorkerRoster";
import { EdgeOfRosterMessage } from "components/TeamRota/TeamRotaTable/components/EdgeOfRosterMessage";
import {
  TeamRotaShiftType,
  TeamRotaTableFiltersType,
} from "components/TeamRota/TeamRotaTable/TeamRotaTableFilters.types";
interface Props {
  workerRosters: RosteringWorkerRostersQuery["rosteringWorkerRosters"];
  roster: SingleRosterType | undefined;
  setRoster: Dispatch<SingleRosterType>;
  weekStartDate: Date;
  setWeekStartDate: Dispatch<Date>;
  filters: TeamRotaTableFiltersType;
  setFilters: Dispatch<SetStateAction<TeamRotaTableFiltersType>>;
  teamRotaActivities: ActivityName[];
  teamRotaShiftTypes: NonNullable<TeamRotaShiftType>[];
}
export const TeamRotaTableFilters = ({
  workerRosters,
  roster,
  setRoster,
  weekStartDate,
  setWeekStartDate,
  filters,
  setFilters,
  teamRotaActivities,
  teamRotaShiftTypes,
}: Props) => {
  return (
    <div className="mt-6">
      <div className="flex gap-1">
        <FormGroup className="w-[222px] z-20">
          <FormSelect
            options={workerRosters?.nodes}
            getOptionValue={(option) => `${option.id}`}
            getOptionLabel={(option) => option.rota.name}
            value={roster}
            onChange={(e) => {
              if (e) {
                setRoster(e);
                setWeekStartDate(new Date(e.rota.startDate));
              }
            }}
            components={{ Control, Option }}
          />
        </FormGroup>
        <FormGroup className="w-[222px] z-20">
          <FormSelect
            options={teamRotaActivities}
            getOptionValue={(option) => `${option?.id ?? ""}`}
            getOptionLabel={(option) => option?.name ?? ""}
            placeholder="Filter activity"
            value={filters.activity}
            onChange={(activity) => {
              setFilters((prev) => ({ ...prev, activity }));
            }}
            isClearable
          />
        </FormGroup>
        <FormGroup className="w-[222px] z-20">
          <FormSelect
            options={teamRotaShiftTypes}
            getOptionValue={(option) => `${option?.id ?? ""}`}
            getOptionLabel={(option) => option?.name ?? ""}
            placeholder="Filter shift type"
            value={filters.shiftType}
            onChange={(shiftType) => {
              setFilters((prev) => ({ ...prev, shiftType }));
            }}
            isClearable
          />
        </FormGroup>
        <FormGroup className="flex align-items-center justify-content-center ml-2">
          <FormCheckbox
            name="leaveOnly"
            action={() => {
              setFilters((prev) => ({
                ...prev,
                isLeaveOnly: !prev.isLeaveOnly,
              }));
            }}
            style={{ margin: 0 }}
            value={filters.isLeaveOnly}
          />
          <FormLabel
            htmlFor="leaveOnly"
            style={{ margin: 0, fontSize: "14px" }}
          >
            Show leave only
          </FormLabel>
        </FormGroup>
      </div>

      <EdgeOfRosterMessage roster={roster} weekStartDate={weekStartDate} />
    </div>
  );
};

const Control = ({
  children,
  ...props
}: ControlProps<
  NonNullable<
    RosteringWorkerRostersQuery["rosteringWorkerRosters"]
  >["nodes"][number],
  false
>) => (
  <components.Control {...props}>
    <h3 className="font-[700] text-[12px] mr-1">Viewing: </h3> {children}
  </components.Control>
);

const Option = (
  props: OptionProps<
    NonNullable<
      RosteringWorkerRostersQuery["rosteringWorkerRosters"]
    >["nodes"][number],
    false
  >
) => {
  const { children, data } = props;

  const isExpired = data.expired;

  return (
    <OptionWrapper isExpired={isExpired}>
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {children}{" "}
          {isExpired && (
            <ExpiredLabel>
              <p>Expired</p>
            </ExpiredLabel>
          )}
        </div>
      </components.Option>
    </OptionWrapper>
  );
};

const OptionWrapper = styled.div<{ isExpired: boolean }>`
  color: ${({ isExpired }) => (isExpired ? RBGREY.six : GLOBAL.darkBlue)};
  border: 1px solid ${GREY.one};
`;

const ExpiredLabel = styled.div`
  background: ${GLOBAL.borderGreyLight};
  width: fit-content;
  height: fit-content;
  padding: 2px 4px;
  border-radius: 4px;

  p {
    color: ${RBBLACK.four};
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
  }
`;
