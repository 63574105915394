import { Dispatch, SetStateAction, useMemo } from "react";

import { FormSelect } from "@patchworkhealth/web-components";

import { ExceptionReportInputType } from "components/ExceptionReports/ExceptionReportForms/ManualExceptionReportForm/ManualExceptionReportForm.types";
import { ExceptionReportShiftInputType } from "components/ExceptionReports/ExceptionReportForms/ShiftExceptionReportForm/ShiftExceptionReportForm.types";
import { FormError } from "helpers/types";

interface Props<
  T extends ExceptionReportShiftInputType | ExceptionReportInputType
> {
  property: keyof Pick<
    ExceptionReportInputType,
    "breakScheduledTime" | "breakActualTime"
  >;
  value: string;
  setInput: Dispatch<SetStateAction<T>>;
  setErrors: Dispatch<SetStateAction<FormError<T> | null>>;
}

export const DurationPicker = <
  T extends ExceptionReportShiftInputType | ExceptionReportInputType
>({
  property,
  setInput,
  value,
  setErrors,
}: Props<T>) => {
  const hoursArray = useMemo(
    () =>
      [...Array(24).keys()].map((_, i) => ({
        value: i,
        label: `${i}h`,
      })),
    []
  );

  const minutesArray = useMemo(
    () =>
      [...Array.from(Array(60).keys())].map((_, i) => ({
        value: i,
        label: `${i}m`,
      })),
    []
  );

  const formatDuration = (hours?: string, minutes?: string) => {
    if (!value) {
      setInput((prev) => ({ ...prev, [property]: "00:00:00" }));
    }

    const formattedHours = hours?.length === 1 ? `0${hours}` : hours;
    const formattedMinutes = minutes?.length === 1 ? `0${minutes}` : minutes;
    setErrors(null);
    setInput((prev) => ({
      ...prev,
      [property]: `${formattedHours ?? prev[property]?.slice(0, 2)}:${
        formattedMinutes ?? prev[property]?.slice(3, 5)
      }:00`,
    }));
  };

  const getHours = () => {
    if (!value) return undefined;
    const hours = value.slice(0, 2);

    if (hours[0] === "0") {
      return hoursArray.find((h) => h.value === +hours[1]);
    }
    return hoursArray.find((h) => h.value === +hours);
  };

  const getMinutes = () => {
    if (!value) return undefined;
    const minutes = value.slice(3, 5);

    if (minutes[0] === "0") {
      return minutesArray.find((m) => m.value === +minutes[1]);
    }
    return minutesArray.find((m) => m.value === +minutes);
  };

  return (
    <div
      style={{ display: "flex", gap: 10, width: "175px" }}
      data-testid="hour__min_picker"
    >
      <FormSelect
        options={hoursArray}
        onChange={(e) => formatDuration(e?.value.toString(), undefined)}
        placeholder="h"
        value={getHours()}
      />
      <FormSelect
        data-testid="minutes_picker"
        options={minutesArray}
        onChange={(e) => formatDuration(undefined, e?.value.toString())}
        placeholder="m"
        value={getMinutes()}
      />
    </div>
  );
};
