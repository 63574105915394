import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import { CalendarInputs, checkTabs, FormRadio } from "./CalendarHelpers";

interface CalendarHeaderProps {
  inputs: CalendarInputs;
  productType: number | null;
  setInputs: (inputs: CalendarInputs) => void;
}

function CalendarHeader({
  inputs,
  productType,
  setInputs,
}: CalendarHeaderProps) {
  /* Functions --------------------------------------------------------- */
  const handleMonthChange = (direction: "previous" | "next") => {
    setInputs({
      ...inputs,
      currentDate:
        direction === "previous"
          ? inputs.currentDate.clone().subtract(1, "month")
          : inputs.currentDate.clone().add(1, "month"),
    });
  };

  const handleDateChange = (date: Date | null) => {
    setInputs({
      ...inputs,
      currentDate: moment(date).startOf("month"),
    });
  };

  return (
    <div className="w-[1021px] mx-auto flex justify-between mb-4">
      <div className="flex items-center [&>button]:ml-4">
        <DatePicker
          selected={moment(inputs.currentDate).toDate()}
          onChange={handleDateChange}
          dateFormat="MMMM yyyy"
          showMonthYearPicker
          showFullMonthYearPicker
          className="px-6 py-1 mr-4 text-sm border rounded-lg border-grey-2 h-11"
        />

        {checkTabs(productType).map((tab) => (
          <FormRadio
            key={tab.value}
            label={tab.label}
            value={inputs.calendarTab === tab.value}
            name="selectedAllDepts"
            action={() => setInputs({ ...inputs, calendarTab: tab.value })}
          />
        ))}
      </div>

      <div className="flex items-center">
        <button
          className="text-gray-500 hover:text-gray-700 hover:bg-grey-2 w-10 rounded-full text-[22px]"
          onClick={() => handleMonthChange("previous")}
        >
          &lt;
        </button>
        <div className="text-center month text-lg font-bold w-[140px]">
          {inputs.currentDate.format("MMMM YYYY")}
        </div>
        <button
          className="text-gray-500 hover:text-gray-700 hover:bg-grey-2 w-10 rounded-full text-[22px]"
          onClick={() => handleMonthChange("next")}
        >
          &gt;
        </button>
      </div>
    </div>
  );
}

export default CalendarHeader;
