import { Dispatch } from "react";
import dayjs from "dayjs";

import { SingleRosterType } from "components/TeamRota/hooks/useWorkerRoster";
import {
  addDays,
  getEndOfWeek,
  isLeftEdgeDate,
  isRightEdgeDate,
} from "components/TeamRota/TeamRota.helpers";

interface Props {
  weekStartDate: Date;
  setWeekStartDate: Dispatch<Date>;
  roster: SingleRosterType | undefined;
}

export const TeamRotaNavigation = ({
  weekStartDate,
  setWeekStartDate,
  roster,
}: Props) => {
  const nextWeek = () => {
    setWeekStartDate(addDays(weekStartDate, 7));
  };

  const prevWeek = () => {
    setWeekStartDate(addDays(weekStartDate, -7));
  };

  return (
    <div className="flex align-items-center gap-4">
      {isLeftEdgeDate(weekStartDate, roster?.rota.startDate) && (
        <LeftChevron onClick={prevWeek} dataTestId="left_chevron" />
      )}
      <div className="min-w-[200px] text-center">
        <h1 className="text-[24px] font-[700] text-blue-7">
          {`${dayjs(weekStartDate).format("D")} - ${dayjs(
            getEndOfWeek(weekStartDate)
          ).format("D MMM YYYY")}`}
        </h1>
      </div>
      {isRightEdgeDate(weekStartDate, roster?.rota.endDate) && (
        <RightChevron onClick={nextWeek} dataTestId="right_chevron" />
      )}
    </div>
  );
};

const LeftChevron = ({
  className,
  onClick,
  dataTestId,
}: {
  className?: string;
  onClick: () => void;
  dataTestId: string;
}) => (
  <button
    onClick={onClick}
    onMouseDown={(e) => e.preventDefault()}
    data-testid={dataTestId}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={`cursor-pointer ${className || ""}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7071 5.29289C15.3166 4.90237 14.6834 4.90237 14.2929 5.29289L8.29289 11.2929C7.90237 11.6834 7.90237 12.3166 8.29289 12.7071L14.2929 18.7071C14.6834 19.0976 15.3166 19.0976 15.7071 18.7071C16.0976 18.3166 16.0976 17.6834 15.7071 17.2929L10.4142 12L15.7071 6.70711C16.0976 6.31658 16.0976 5.68342 15.7071 5.29289Z"
        fill="#7C7D80"
      />
    </svg>
  </button>
);

const RightChevron = ({
  onClick,
  dataTestId,
}: {
  onClick: () => void;
  dataTestId: string;
}) => (
  <LeftChevron
    className="transform rotate-180"
    onClick={onClick}
    dataTestId={dataTestId}
  />
);
