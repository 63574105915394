import DatePicker from "react-datepicker";
import styled from "styled-components";

import { GLOBAL, GREY } from "@patchworkhealth/web-components";

import { CalendarHeaderIcon, DatePickerChevron } from "icons/calendarIcons";

export const DatePickerComponent = ({
  endDate,
  handleEndDate,
  isClearable,
}) => {
  return (
    <DatepickerStyle>
      <span className="span_icon">
        <CalendarHeaderIcon />
      </span>
      <DatePicker
        placeholderText="Select From Date"
        selected={endDate}
        dateFormat="MM/dd/yyyy"
        isClearable={isClearable !== "no"}
        onChange={(date) => {
          handleEndDate(date);
        }}
      />

      <div className="calendar__Chevron">
        <DatePickerChevron />
      </div>
    </DatepickerStyle>
  );
};

const DatepickerStyle = styled.div`
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  position: relative;
  width: 250px;

  .span_icon {
    align-items: center;
    background: ${GLOBAL.backgroundColor};
    border: 1px solid ${GREY.three};
    border-bottom-left-radius: 6px;
    border-right: 0;
    border-top-left-radius: 6px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    width: 60px;
    svg {
      position: relative;
    }
  }

  .calendar__Chevron {
    position: absolute;
    right: 14px;
    top: 18px;
  }

  .react-datepicker__input-container {
    input {
      background: ${GLOBAL.white};
      border: 1px solid ${GREY.three};
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
      box-sizing: border-box;
      color: #002033 !important;
      height: 44px;
      padding-left: 10px;
      width: 205px !important;
      z-index: 10;

      &:focus {
        background-color: ${GLOBAL.white};
        border-color: ${GREY.ten};
        box-shadow: 0 0 4px 1px rgba(43, 192, 212, 0.5);
        color: ${GLOBAL.fontDark};
        outline: 0;
      }

      @media (max-width: 768px) {
        max-width: 100%;
      }
    }
  }

  .react-datepicker__close-icon {
    position: absolute;
    right: -34px;
  }

  .react-datepicker__close-icon::after {
    background-color: ${GREY.two};
    color: ${GREY.ten};
    height: 22px;
    width: 22px;
  }
`;
