import { GREY } from "@patchworkhealth/web-components";

type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColour?: string;
};

export const InfoIcon = ({
  width = "22",
  height = "22",
  viewBox = "0 0 22 22",
  strokeColour = `${GREY.five}`,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    stroke={strokeColour}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
      stroke={strokeColour}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 15V11"
      stroke={strokeColour}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 7H11.01"
      stroke={strokeColour}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
