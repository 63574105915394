import { useState } from "react";
import dayjs from "dayjs";

import Layout from "components/Layout";
import { useGetShiftsActivities } from "components/Schedule/hooks/useServicePlansShiftActivities";
import { useRosteringTeamRotaQuery } from "components/TeamRota/__generated__/TeamRota.generated";
import { useTeamRotaFilters } from "components/TeamRota/hooks/useTeamRotaFilters";
import { useWorkerRoster } from "components/TeamRota/hooks/useWorkerRoster";
import { getEndOfWeek } from "components/TeamRota/TeamRota.helpers";
import { TeamRotaHeader } from "components/TeamRota/TeamRotaHeader/TeamRotaHeader";
import { TeamRotaTable } from "components/TeamRota/TeamRotaTable/TeamRotaTable";
import {
  getTeamRotaActivities,
  getTeamRotaShiftTypes,
  getTeamRotaWorkers,
} from "components/TeamRota/TeamRotaTable/TeamRotaTable.helpers";
import { TeamRotaTableFilters } from "components/TeamRota/TeamRotaTable/TeamRotaTableFilters";

import {
  RosteringEventTypeEnum,
  RosteringLeaveRequestStatusEnum,
} from "../baseCODEGEN";

const TeamRota = () => {
  const [weekStartDate, setWeekStartDate] = useState(new Date());

  const { roster, setRoster, workerRosters } =
    useWorkerRoster(setWeekStartDate);

  const { data: teamRotaData, loading } = useRosteringTeamRotaQuery(
    roster
      ? {
          variables: {
            startDate: dayjs(weekStartDate).format("YYYY-MM-DD"),
            endDate: dayjs(getEndOfWeek(weekStartDate)).format("YYYY-MM-DD"),
            rosterId: roster?.id,
            eventTypes: [
              RosteringEventTypeEnum.Shift,
              RosteringEventTypeEnum.Rest,
            ],
            statuses: [
              RosteringLeaveRequestStatusEnum.Pending,
              RosteringLeaveRequestStatusEnum.Approved,
              RosteringLeaveRequestStatusEnum.PendingCancellation,
            ],
          },
        }
      : { skip: true }
  );

  const eventIds =
    teamRotaData?.rosteringTeamRota?.flatMap((teamRota) =>
      teamRota.rosteringOrganisationRegistrations.flatMap((rosterOrgReg) =>
        rosterOrgReg.eventsOnRoster.map((event) => +event.id)
      )
    ) || [];

  const { activityNames, eventIdsForActivities } = useGetShiftsActivities({
    eventIds,
  });

  const { filters, setFilters, filteredTeamRotaData } = useTeamRotaFilters(
    teamRotaData,
    eventIdsForActivities
  );

  const teamRotaWorkers = getTeamRotaWorkers(teamRotaData?.rosteringTeamRota);
  const teamRotaActivities = getTeamRotaActivities(
    teamRotaData?.rosteringTeamRota,
    activityNames
  );
  const teamRotaShiftTypes = getTeamRotaShiftTypes(
    teamRotaData?.rosteringTeamRota
  );

  return (
    <Layout headerValueText="Team Rota">
      <div style={{ minWidth: "fit-content", maxWidth: "1024px" }}>
        <TeamRotaHeader
          weekStartDate={weekStartDate}
          setWeekStartDate={setWeekStartDate}
          roster={roster}
        />
        <TeamRotaTableFilters
          roster={roster}
          setRoster={setRoster}
          weekStartDate={weekStartDate}
          setWeekStartDate={setWeekStartDate}
          workerRosters={workerRosters?.rosteringWorkerRosters}
          filters={filters}
          setFilters={setFilters}
          teamRotaActivities={teamRotaActivities}
          teamRotaShiftTypes={teamRotaShiftTypes}
        />
        <TeamRotaTable
          weekStartDate={weekStartDate}
          teamRotaData={filteredTeamRotaData}
          loading={loading}
          filters={filters}
          setFilters={setFilters}
          teamRotaWorkers={teamRotaWorkers}
        />
      </div>
    </Layout>
  );
};

export default TeamRota;
