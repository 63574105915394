import styled from "styled-components";

import ProfileIcon from "icons/images/profileImageDefault.svg";

type ProfileImageSize = "small" | "big";
interface Props {
  size: ProfileImageSize;
  src?: string | undefined | null;
  onClick?: () => void;
}
export const ProfileImage = ({ size, onClick, src }: Props) => {
  const getSrc = () => {
    const apiUrl = process.env.REACT_APP_API;

    if (src) return `${apiUrl}/${src}`;

    return ProfileIcon.toString();
  };

  return (
    <ImageStyles
      src={getSrc()}
      alt="Profile Pic"
      size={size}
      onClick={onClick}
    />
  );
};

const ImageStyles = styled.img<{ size: ProfileImageSize }>`
  border-radius: 50%;
  cursor: ${({ size }) => (size === "big" ? "pointer" : "initial")};
  height: ${({ size }) => (size === "big" ? "100px" : "40px")};
  margin-right: 8px;
  width: ${({ size }) => (size === "big" ? "100px" : "40px")};
`;
