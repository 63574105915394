import { useContext } from "react";
import { Switch } from "react-router-dom";

import Agencies from "containers/agencies";
import CalendarPage from "containers/calendar";
import Documents from "containers/documents";
import ExceptionReportsPage from "containers/exception-reports";
import ExpensesPage from "containers/expenses";
import { LeaveManagementPage } from "containers/leave-management";
import Organisations from "containers/organisations";
import PlannerPage from "containers/planner";
import Profile from "containers/profile";
import Schedules from "containers/schedules";
import SelfRosteringSubmissions from "containers/self-rostering";
import SelfRosteringPreferences from "containers/self-rostering-preferences";
import ShiftsPage from "containers/shifts";
import TeamRota from "containers/team-rota";
import Timesheets from "containers/timesheets";
import { UnauthorisedPage } from "containers/unauthorised";
import { AppContext } from "context/AppContext";
import { ProductType } from "helpers/productType";
import { SailFlag, useFeatureFlag } from "helpers/useFeatureFlags";

import { RouteWrapper as Route } from "./RouteWrapper";

export default function PrivateRoutes() {
  const store = useContext(AppContext);
  const showSelfRostering = useFeatureFlag(SailFlag.showSelfRostering);
  const showExpenses = [ProductType.Bank, ProductType.BankAndRota].includes(
    store.productType ?? -1
  );

  return (
    <Switch>
      <Route path="/shifts" component={ShiftsPage} isPrivate />

      <Route exact path="/team-rota" component={TeamRota} isPrivate />

      <Route exact path="/calendar" component={CalendarPage} isPrivate />
      <Route path="/timesheets" component={Timesheets} isPrivate />

      <Route path="/organisations" component={Organisations} isPrivate />
      <Route exact path="/documents" component={Documents} isPrivate />
      <Route exact path="/profile" component={Profile} isPrivate />
      <Route exact path="/agencies" component={Agencies} isPrivate />
      <Route
        exact
        path="/leave-management"
        component={LeaveManagementPage}
        isPrivate
      />

      {showExpenses && (
        <Route path="/expenses" component={ExpensesPage} isPrivate />
      )}

      {(store.productType === ProductType.Rota ||
        store.productType === ProductType.BankAndRota) && (
        <Route
          exact
          path="/exception-reports"
          component={ExceptionReportsPage}
          isPrivate
        />
      )}

      <Route exact path="/planner" component={PlannerPage} isPrivate />

      <Route
        exact
        path="/schedule"
        component={
          store.productType === ProductType.Bank ? PlannerPage : Schedules
        }
        isPrivate
      />

      {(store.productType === ProductType.Rota ||
        store.productType === ProductType.BankAndRota) &&
        showSelfRostering && (
          <Route
            exact
            path="/roster-preferences"
            component={SelfRosteringSubmissions}
            isPrivate
          />
        )}

      {(store.productType === ProductType.Rota ||
        store.productType === ProductType.BankAndRota) &&
        showSelfRostering && (
          <Route
            path="/roster-preferences/:rosteringPeriodId"
            component={SelfRosteringPreferences}
            isPrivate
          />
        )}

      <Route path="*" exact component={UnauthorisedPage} />
    </Switch>
  );
}
