import styled from "styled-components";

import { GLOBAL } from "@patchworkhealth/web-components";

const FiltersMenu = styled.div`
  background: ${GLOBAL.white};
  border-radius: 6px;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2))
    drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
  min-width: 133px;
`;

export default FiltersMenu;
