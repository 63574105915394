import styled from "styled-components";

import {
  BLUE,
  GLOBAL,
  GREY,
  SHIFTS_NEW as SHIFTS,
} from "@patchworkhealth/web-components";

import { exhaustiveMatchingGuard } from "helpers/exhaustiveMatchingGuard";

import { ShiftType } from "./RosteringShift";
export const OtherEventContainer = styled.div`
  background-image: repeating-linear-gradient(
    -65deg,
    ${GLOBAL.white},
    ${GLOBAL.white} 15px,
    ${GLOBAL.backgroundColor} 15px,
    ${GLOBAL.backgroundColor} 30px
  );

  min-height: 13px;
  max-height: 51px;
  height: 100%;
  width: 100%;
  min-width: 132px;

  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: center;
  p {
    font-size: 0.625rem;
    color: ${GREY.five};
    text-transform: capitalize;
    font-weight: 500;
    margin-top: 2px;
  }
`;

export const RosteringShiftContainer = styled.button<{
  shiftType: ShiftType;
  minShiftHeight?: number | `${string}px`;
}>`
  background: ${({ shiftType }) => getShiftBackgroundColor(shiftType)};
  border-left: ${({ shiftType }) =>
    `4px solid ${getBorderLeftColor(shiftType)}`};
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  position: relative;
  min-height: ${({ minShiftHeight }) => minShiftHeight || "13px"};
  max-height: 51px;
  height: 100%;
  width: 100%;
  min-width: 132px;

  display: flex;
  align-items: center;
  padding-left: 7px;

  p {
    font-size: 10px;
    font-weight: 500;
    color: ${GREY.ten};
    text-align: left;
  }

  .activity_name {
    color: ${({ shiftType }) => getTextColor(shiftType)};
    max-width: 90px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  svg {
    position: absolute;
    right: 10px;
    top: 15px;
  }

  /* Hide elements when container height is less than 51px */
`;

const getBorderLeftColor = (shiftType: ShiftType): string => {
  switch (shiftType) {
    case ShiftType.Early:
      return SHIFTS.early.three;
    case ShiftType.Twilight:
      return SHIFTS.twilight.three;
    case ShiftType.Late:
      return SHIFTS.late.three;
    case ShiftType.LongDay:
      return SHIFTS.longDay.three;
    case ShiftType.Night:
      return SHIFTS.night.three;
    case ShiftType.Day:
      return BLUE.seven;
    case ShiftType.OnCall:
      return SHIFTS.oncall.three;
    case ShiftType.Mid:
      return SHIFTS.mid.three;
    case ShiftType.Night1:
      return SHIFTS.night1.three;
    case ShiftType.Night2:
      return SHIFTS.night2.three;
    default:
      return exhaustiveMatchingGuard(shiftType);
  }
};

const getShiftBackgroundColor = (shiftType: ShiftType): string => {
  switch (shiftType) {
    case ShiftType.Day:
      return BLUE.one;
    case ShiftType.LongDay:
      return SHIFTS.longDay.one;
    case ShiftType.Early:
      return SHIFTS.early.one;
    case ShiftType.Twilight:
      return SHIFTS.twilight.one;
    case ShiftType.Late:
      return SHIFTS.late.one;
    case ShiftType.Night:
      return SHIFTS.night.one;
    case ShiftType.OnCall:
      return SHIFTS.oncall.one;
    case ShiftType.Mid:
      return SHIFTS.mid.one;
    case ShiftType.Night1:
      return SHIFTS.night1.one;
    case ShiftType.Night2:
      return SHIFTS.night2.one;
    default:
      return exhaustiveMatchingGuard(shiftType);
  }
};

const getTextColor = (shiftType: ShiftType): string => {
  switch (shiftType) {
    case ShiftType.Early:
      return SHIFTS.early.three;
    case ShiftType.Day:
      return BLUE.six;
    case ShiftType.Late:
      return SHIFTS.late.three;
    case ShiftType.Twilight:
      return SHIFTS.twilight.three;
    case ShiftType.Night:
      return GREY.seven;
    case ShiftType.LongDay:
      return SHIFTS.longDay.three;
    case ShiftType.OnCall:
      return SHIFTS.oncall.three;
    case ShiftType.Mid:
      return SHIFTS.mid.three;
    case ShiftType.Night1:
      return SHIFTS.night1.three;
    case ShiftType.Night2:
      return SHIFTS.night2.three;
    default:
      return exhaustiveMatchingGuard(shiftType);
  }
};
