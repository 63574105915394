import { useContext } from "react";
import toast from "react-hot-toast";
import moment from "moment";

import { TimesheetCardFragment } from "components/Timesheets/__generated__/Timesheets.generated";
import { AppContext } from "context/AppContext";
import { formatTimeInTZ } from "helpers/functions";

import { ShiftCardFragment } from "./__generated__/Shifts.generated";
import {
  checkProvisional,
  ShiftCardActions,
  ShiftStatusIcons,
} from "./ShiftHelpers";
import {
  OnCallBadgeIcon,
  ProvisionalBookedIcon,
  SessionIcon,
  ShiftCardBlockIcon,
  ShiftCardDpmtIcon,
  ShiftCardGradeIcon,
  ShiftCardLocationIcon,
} from "./ShiftIcons";

export const ShiftCard = ({
  shift,
  isMod = false,
  onClick,
  modStartDate,
}: {
  isMod?: boolean;
  shift: TimesheetCardFragment;
  onClick: (shift: TimesheetCardFragment) => void;
  modStartDate?: string | null;
}) => {
  /* State -------------------------------------------------------- */
  const { user } = useContext(AppContext);
  const {
    startTime,
    endTime,
    grade,
    department,
    site,
    status,
    shiftType,
    bookingAction,
    withSecondaryBank,
    deContractVersionAssignment,
  } = shift;

  const { showAgencyUI, needsToJoinAgency, statusCheck } = checkProvisional(
    user,
    shift
  );

  const footerContent = getFooterContent(shift, showAgencyUI);

  const shiftDate = moment(
    modStartDate ? modStartDate : shift.startTime
  ).format("ddd, Do MMM YY");

  const shiftEndDate = moment(shift.endTime).format("ddd, Do MMM YY");

  /* Render -------------------------------------------------------- */

  // const dateFormat = "YYYY-MM-DD HH:mm:ss Z";
  // Your API returned date string
  // var reportedStartTime = "2024-01-29 09:30:00 +0000";

  // // Use moment.js to parse the date string and convert it to a 24-hour time format
  // var formattedTime = moment(reportedStartTime, "YYYY-MM-DD HH:mm:ss Z").format(
  //   "HH:mm"
  // );

  // console.log(user?.id);

  return (
    <div
      onClick={() => {
        if (showAgencyUI && needsToJoinAgency && user?.id !== "89706") {
          toast.error("Please join agency first");
          return;
        }
        onClick(shift);
      }}
      className="bg-white my-4 h-[176px] w-[344px] cursor-pointer rounded-lg border-gray-300 border shadow transition relative"
    >
      <div className="relative h-[132px] w-full px-4 py-3  text-sm">
        <ShiftStatusIcons status={statusCheck} left={false} />
        <div className="absolute right-4 top-[47px] flex h-20 w-24 flex-col items-center justify-center [&>svg]:mb-[6px]">
          {shiftType === "ONCALL" && <OnCallBadgeIcon />}
          {shiftType === "SESSIONAL" && <SessionIcon />}

          {!showAgencyUI && bookingAction === "INSTANT_BOOK" && (
            <ShiftCardActions status="instant" />
          )}
          {withSecondaryBank && !showAgencyUI && (
            <ShiftCardActions status="collab" />
          )}
        </div>

        {/* Shift Card Dates ---------------------------------------- */}

        <div className="flex mb-4">
          <div className="mr-4">
            <p className="font-semibold">Date</p>
            <p> {shiftDate} </p>
            {shiftType === "ONCALL" && shiftDate !== shiftEndDate && (
              <p> {shiftEndDate} </p>
            )}
          </div>

          {!isMod &&
            ![
              "SIGN_OFF_REQUESTED",
              "SIGNED_OFF",
              "APPROVED",
              "PAID",
              "PAYROLL",
            ].includes(shift.status) && (
              <div>
                <p className="font-semibold">Time</p>
                <p>
                  {formatTimeInTZ(startTime)} - {formatTimeInTZ(endTime)}
                </p>
              </div>
            )}

          {shift.workerSignOff && shift.status === "SIGN_OFF_REQUESTED" && (
            <div>
              <p className="font-semibold">Time</p>
              <p>
                {moment(
                  shift?.workerSignOff?.reportedStartTime,
                  "YYYY-MM-DD HH:mm:ss +0000"
                ).format("HH:mm")}{" "}
                -{" "}
                {moment(
                  shift?.workerSignOff?.reportedEndTime,
                  "YYYY-MM-DD HH:mm:ss +0000"
                ).format(" HH:mm")}
              </p>
            </div>
          )}

          {shift.workerSignOff &&
            ["SIGNED_OFF", "APPROVED", "PAID", "PAYROLL"].includes(
              shift.status
            ) && (
              <div>
                <p className="font-semibold">Time 11</p>
                <p>
                  {moment(
                    shift?.workerSignOff?.confirmedStartTime,
                    "YYYY-MM-DD HH:mm:ss +0000"
                  ).format("HH:mm ")}
                  -
                  {moment(
                    shift?.workerSignOff?.confirmedEndTime,
                    "YYYY-MM-DD HH:mm:ss +0000"
                  ).format(" HH:mm")}
                </p>
              </div>
            )}
        </div>

        {/* ShiftCard Details ----------------------------------------*/}

        {status === "PROVISIONALLY_BOOKED" && (
          <div className="absolute bottom-2 right-2">
            <ProvisionalBookedIcon />
          </div>
        )}

        {shift.shiftBlockId && (
          <p className="mt-[-8px] mb-0.5 flex items-center">
            <ShiftCardBlockIcon />
            Block: {shift.shiftBlockId}
          </p>
        )}

        <p className="mb-0.5 flex items-center">
          <ShiftCardGradeIcon />
          {grade?.title?.slice(0, 28)}
        </p>
        <p className="mb-0.5 flex items-center">
          <ShiftCardDpmtIcon />
          {department?.name?.slice(0, 28)}
        </p>
        <p className="mb-0.5 flex items-center">
          <ShiftCardLocationIcon />
          {site?.name?.slice(0, 28)}
        </p>
      </div>

      {/* Shift Card Footer ------------------------------------- */}

      <ShiftCardFooter footerContent={footerContent} />
      {deContractVersionAssignment && (
        <div
          style={{
            position: "absolute",
            bottom: 10,
            right: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DeContractIcon />
          {shift.requiresDeContractToSign ? <NotSignedIcon /> : <SignedIcon />}
        </div>
      )}
    </div>
  );
};

/* ShiftCard Footer  --------------------------------------------- */

const ShiftCardFooter = ({
  footerContent,
}: {
  footerContent: {
    title?: string;
    value?: string;
  }[];
}) => {
  return (
    <div className="flex items-center w-full h-12 font-semibold border-t justify-evenly border-t-grey-2">
      {footerContent.map((content, index) => (
        <div
          key={content.title}
          className={`flex h-full w-full flex-col items-center justify-center ${
            index === 1 &&
            "rounded-br-lg border-l-[1px] border-l-grey-200 bg-gray-100"
          }`}
        >
          <p className="text-[9px] mb-[-2px] uppercase font-bold">
            {content.title}
          </p>
          <p className="font-bold">{content.value}</p>
        </div>
      ))}
    </div>
  );
};

/* ShiftCard Footer Content ----------------------------------------------------- */

const getFooterContent = (shift: ShiftCardFragment, showAgencyUI: boolean) => {
  const {
    grade,
    shiftType,
    actualTotalPay,
    status,
    actualCoreRate,
    actualUnsocialRate,
  } = shift;

  const footerContent = [];

  if (!showAgencyUI && shiftType === "ONCALL") {
    footerContent.push(
      {
        title: "On Call Rate",
        value: grade.onCallRate.toFixed(2),
      },
      {
        title: "Call Out Rate",
        value: grade.callOutRate.toFixed(2),
      }
    );
  }

  if (showAgencyUI) {
    footerContent.push({
      value: "Agency Shift",
    });
  }

  if (!showAgencyUI && shiftType === "SESSIONAL") {
    footerContent.push({
      value: `Total Pay: £${actualTotalPay?.toFixed(2)}`,
    });
  }

  if (
    !showAgencyUI &&
    shiftType !== "ONCALL" &&
    ["PAID", "APPROVED", "SIGNED_OFF", "SIGN_OFF_REQUESTED"].includes(status)
  ) {
    footerContent.push({
      title: "Total Pay",
      value: `£${actualTotalPay?.toFixed(2)}`,
    });
  }

  if (!showAgencyUI && shiftType === "HOURLY") {
    if (shift.actualCoreHours > 0) {
      footerContent.push({
        title: "CORE Hours",
        value: `£${actualCoreRate?.toFixed(2)}`,
      });
    }

    if (shift.actualUnsocialHours > 0) {
      footerContent.push({
        title: "UNSOCIAL Hours",
        value: `£${actualUnsocialRate?.toFixed(2)}`,
      });
    }
  }

  return footerContent;
};

// De Contract

const NotSignedIcon = () => (
  <svg
    width="5"
    height="5"
    viewBox="0 0 5 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2.5" cy="2.5" r="2.5" fill="#E6515D" />
  </svg>
);

const SignedIcon = () => (
  <svg
    width="5"
    height="5"
    viewBox="0 0 5 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2.5" cy="2.5" r="2.5" fill="#AEDDAE" />
  </svg>
);

const DeContractIcon = () => (
  <svg
    width="22"
    height="15"
    viewBox="0 0 15 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="mb-0.5"
  >
    <path
      d="M4.58178 8.16552C3.56172 8.16552 2.69688 7.81071 1.98727 7.1011C1.27766 6.39149 0.922852 5.52665 0.922852 4.50659C0.922852 3.48652 1.27766 2.62168 1.98727 1.91207C2.69688 1.20246 3.56172 0.847656 4.58178 0.847656H11.567C12.2988 0.847656 12.9253 1.10822 13.4464 1.62934C13.9675 2.15046 14.2281 2.77691 14.2281 3.5087C14.2281 4.24048 13.9675 4.86694 13.4464 5.38806C12.9253 5.90918 12.2988 6.16974 11.567 6.16974H5.24704C4.78136 6.16974 4.38775 6.00897 4.06621 5.68742C3.74466 5.36588 3.58389 4.97227 3.58389 4.50659C3.58389 4.04091 3.74466 3.64729 4.06621 3.32575C4.38775 3.00421 4.78136 2.84344 5.24704 2.84344H11.567V3.84133H5.24704C5.05855 3.84133 4.90044 3.90519 4.77271 4.03292C4.64498 4.16065 4.58134 4.31854 4.58178 4.50659C4.58178 4.69508 4.64565 4.85319 4.77338 4.98092C4.90111 5.10865 5.059 5.17229 5.24704 5.17185H11.567C12.0327 5.17185 12.4263 5.01108 12.7479 4.68953C13.0694 4.36799 13.2302 3.97438 13.2302 3.5087C13.2302 3.04302 13.0694 2.6494 12.7479 2.32786C12.4263 2.00632 12.0327 1.84555 11.567 1.84555H4.58178C3.85 1.84555 3.22354 2.10611 2.70242 2.62723C2.1813 3.14835 1.92074 3.7748 1.92074 4.50659C1.92074 5.23837 2.1813 5.86483 2.70242 6.38595C3.22354 6.90707 3.85 7.16763 4.58178 7.16763H11.567V8.16552H4.58178Z"
      fill="#545454"
    />
  </svg>
);
