import styled from "styled-components";

import { GREEN, ORANGE, RED } from "@patchworkhealth/web-components";

import { ExceptionReportStatusEnum } from "../../../../baseCODEGEN";

const decisionBoxThemes = (status: ExceptionReportStatusEnum) => {
  switch (status) {
    case ExceptionReportStatusEnum.Approved:
      return {
        background: GREEN.two,
        color: GREEN.five,
      };
    case ExceptionReportStatusEnum.Rejected:
    case ExceptionReportStatusEnum.RejectedDispute:
      return {
        background: RED.two,
        color: RED.five,
      };
    default:
      return {
        background: ORANGE.one,
        color: "#C54714",
      };
  }
};

export const DecisionInfoBox = styled.div<{
  status:
    | ExceptionReportStatusEnum.Approved
    | ExceptionReportStatusEnum.Rejected
    | ExceptionReportStatusEnum.RejectedDispute;
}>`
  background: ${({ status }) => decisionBoxThemes(status).background};
  color: ${({ status }) => decisionBoxThemes(status).color};
  border-radius: 8px;
  padding: ${({ status }) =>
    status === ExceptionReportStatusEnum.Rejected ? "15px" : "13px"};
  width: 257px;
  height: fit-content;
`;

export const DecisionBoxHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3px;
`;

export const DecisionBoxText = styled.p`
  font-size: 12px !important;
  font-weight: 400;
  margin-bottom: 3.5px;
  & span {
    font-weight: 550;
    font-size: 14px;
  }
`;
