import styled from "styled-components";

import { GLOBAL } from "@patchworkhealth/web-components";

import { ErrorFragment } from "gql/new-gql/__generated__/fragments.generated";

type ErrorListProps = {
  errors: ErrorFragment[];
};
const AuthErrorList = ({ errors }: ErrorListProps) => {
  if (errors.length === 0) return null;
  return (
    <StyledContainer>
      <ErrorHeading>Password Issues:</ErrorHeading>
      <List>
        {errors.map((errors) => (
          <ListItem key={errors.message}>
            {errors.attribute} {errors.message}
          </ListItem>
        ))}
      </List>
    </StyledContainer>
  );
};

export default AuthErrorList;
const StyledContainer = styled.div`
  color: ${GLOBAL.red};
  margin-bottom: 15px;
  margin-top: 15px;
`;
const ErrorHeading = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 2px;
  text-align: left;
`;

const List = styled.ul`
  font-size: 12px;
  list-style-type: disc;
  margin: 0;
  padding-left: 20px;
`;

const ListItem = styled.li`
  font-size: 12px;
  font-weight: 600;
  list-style-position: outside;
  margin-left: 0;

  &::first-letter {
    text-transform: capitalize;
  }
`;
