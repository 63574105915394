import styled from "styled-components";

import { GREY, RBCYAN } from "@patchworkhealth/web-components";

export const TextItemSmall = styled.p`
  color: ${GREY.ten};
  font-size: 10px !important;
  font-weight: 600;
  margin-bottom: 2px;
  text-transform: uppercase;
`;

export const ModalTextItem = styled.p`
  color: ${GREY.ten};
  font-size: 13px !important;
  line-height: normal;
  margin-bottom: 0;
`;

export const ModalHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 340px;
`;

export const ModalHeaderRight = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 125px;
`;

export const ReporterInfoContainer = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  margin-bottom: 24px;
`;

export const GradeLabel = styled.p<{ colour: string | undefined | null }>`
  color: ${({ colour }) => colour};
  font-size: 10px !important;
  font-weight: 600;
  margin-bottom: 2px;
`;

export const TextItem = styled.p`
  color: ${GREY.ten};
  font-size: 14px;
  font-weight: 400;
`;

export const TextItemHeader = styled(TextItem)`
  font-weight: 500;
`;

export const SubHeader = styled.h2`
  color: ${GREY.ten};
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
`;

export const SubHeaderSection = styled.div<{ isOpen: boolean }>`
  color: ${GREY.ten};
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 167px;
  svg {
    transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
    transition: all 0.2s ease-in-out;
  }
`;

export const ReasonForDisputingContainer = styled.div`
  min-height: 100px;
  background: ${RBCYAN.one};
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 15px 17px;

  p {
    font-size: 12px;
    font-weight: 400;
  }
`;
