import * as Types from '../../../../../baseCODEGEN';

import { gql } from '@apollo/client';
import { ErrorFragmentDoc } from '../../../../../gql/new-gql/__generated__/fragments.generated';
import * as Apollo from '@apollo/client';
import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from 'msw'
const defaultOptions = {} as const;
export type RosteringExceptionReportDisputeMutationVariables = Types.Exact<{
  rosteringExceptionReportDisputeId: Types.Scalars['Int'];
  note: Types.Scalars['String'];
}>;


export type RosteringExceptionReportDisputeMutation = { __typename?: 'Mutation', rosteringExceptionReportDispute?: { __typename?: 'RosteringExceptionReportDisputePayload', errors: Array<{ __typename?: 'ValidationError', attribute?: string | null, message: string }>, rosteringExceptionReport?: { __typename?: 'RosteringExceptionReport', id: string, status: Types.ExceptionReportStatusEnum } | null } | null };


export const RosteringExceptionReportDisputeDocument = gql`
    mutation RosteringExceptionReportDispute($rosteringExceptionReportDisputeId: Int!, $note: String!) {
  rosteringExceptionReportDispute(
    id: $rosteringExceptionReportDisputeId
    note: $note
  ) {
    errors {
      ...Error
    }
    rosteringExceptionReport {
      id
      status
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RosteringExceptionReportDisputeMutationFn = Apollo.MutationFunction<RosteringExceptionReportDisputeMutation, RosteringExceptionReportDisputeMutationVariables>;

/**
 * __useRosteringExceptionReportDisputeMutation__
 *
 * To run a mutation, you first call `useRosteringExceptionReportDisputeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRosteringExceptionReportDisputeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rosteringExceptionReportDisputeMutation, { data, loading, error }] = useRosteringExceptionReportDisputeMutation({
 *   variables: {
 *      rosteringExceptionReportDisputeId: // value for 'rosteringExceptionReportDisputeId'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useRosteringExceptionReportDisputeMutation(baseOptions?: Apollo.MutationHookOptions<RosteringExceptionReportDisputeMutation, RosteringExceptionReportDisputeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RosteringExceptionReportDisputeMutation, RosteringExceptionReportDisputeMutationVariables>(RosteringExceptionReportDisputeDocument, options);
      }
export type RosteringExceptionReportDisputeMutationHookResult = ReturnType<typeof useRosteringExceptionReportDisputeMutation>;
export type RosteringExceptionReportDisputeMutationResult = Apollo.MutationResult<RosteringExceptionReportDisputeMutation>;
export type RosteringExceptionReportDisputeMutationOptions = Apollo.BaseMutationOptions<RosteringExceptionReportDisputeMutation, RosteringExceptionReportDisputeMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRosteringExceptionReportDisputeMutation((req, res, ctx) => {
 *   const { rosteringExceptionReportDisputeId, note } = req.variables;
 *   return res(
 *     ctx.data({ rosteringExceptionReportDispute })
 *   )
 * })
 */
export const mockRosteringExceptionReportDisputeMutation = (resolver: ResponseResolver<GraphQLRequest<RosteringExceptionReportDisputeMutationVariables>, GraphQLContext<RosteringExceptionReportDisputeMutation>, any>) =>
  graphql.mutation<RosteringExceptionReportDisputeMutation, RosteringExceptionReportDisputeMutationVariables>(
    'RosteringExceptionReportDispute',
    resolver
  )
