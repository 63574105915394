import dayjs from "dayjs";
import styled from "styled-components";

import { RBGREY } from "@patchworkhealth/web-components";

import { LeaveInfoIcon } from "icons/leaveManagementIcons";

interface Props {
  sentToEsrAt: string;
}
export const EsrBanner = ({ sentToEsrAt }: Props) => {
  return (
    <EsrNotificationContainer data-testid="esr_banner">
      <LeaveInfoIcon />
      <div style={{ marginLeft: "14px" }}>
        {`TOIL compensation converted to pay on ${dayjs(sentToEsrAt).format(
          "Do MMM YYYY"
        )}`}
      </div>
    </EsrNotificationContainer>
  );
};

const EsrNotificationContainer = styled.div`
  background: ${RBGREY.one};
  display: flex;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: 10px 37px;
  align-items: center;

  svg {
    margin-left: 3px;
  }
`;
