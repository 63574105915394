import { ComponentProps, Key } from "react";

import { LeaveRecordsOptionsType } from "components/LeaveManagement/LeaveRecordTable/LeaveRecordTable.types";

import FiltersMenu from "./FiltersMenu.styled";
import FiltersOptionRadio from "./FiltersOptionRadio";

type Props<TOptions, TValue> = {
  options: TOptions;
  value: TValue;
  onChange: (event: TValue) => void;
};

const FiltersMenuSingle = <
  TOptions extends LeaveRecordsOptionsType<TOptions[number]["value"]>,
  TValue extends TOptions[number]["value"]
>({
  onChange,
  options,
  value,
  ...props
}: Props<TOptions, TValue> & ComponentProps<typeof FiltersMenu>) => {
  return (
    <FiltersMenu role="radiogroup" {...props}>
      {options.map((option) => (
        <FiltersOptionRadio
          key={option.value as Key}
          checked={option.value === value}
          onChange={() => onChange(option.value as TValue)}
        >
          {option.label}
        </FiltersOptionRadio>
      ))}
    </FiltersMenu>
  );
};

export default FiltersMenuSingle;
