import Lightbox from "react-image-lightbox";

import { profileImageIcon } from "icons/profileIcons";

const ProfilePicture = ({ store, setIsOpen }) => (
  <Lightbox
    onCloseRequest={() => setIsOpen(false)}
    mainSrc={
      store?.user === null
        ? profileImageIcon
        : store?.user?.profilePictureUrl == null
        ? profileImageIcon
        : `${process.env.REACT_APP_API}/${store.user.profilePictureUrl}`
    }
  />
);

export default ProfilePicture;
