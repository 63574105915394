import { useState } from "react";

import { ShiftCardFragment } from "components/ShiftsNew/__generated__/Shifts.generated";
import { ShiftsModal } from "components/ShiftsNew/ShiftsModal";

import { Loading } from "../Style";
import { useShiftsInProgressQuery } from "./__generated__/Timesheets.generated";
import { TimesheetsGroup } from "./TimesheetHelpers";

type InitInputs = {
  sidebarOpen: boolean;
  openModal: boolean;
  selectedShift: ShiftCardFragment | null;
  hasMoreShifts: boolean;
};

const initInputs: InitInputs = {
  sidebarOpen: false,
  openModal: false,
  selectedShift: null,
  hasMoreShifts: true,
};

const TimesheetsProgress = () => {
  /* State ---------------------------------------------- */

  const [inputs, setInputs] = useState(initInputs);

  /* Graphql ---------------------------------------------- */

  const { data, loading } = useShiftsInProgressQuery({
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const { signoffRequested = [], signedOff = [], approved = [] } = data || {};

  /* Functions ---------------------------------------------- */

  const toggleModal = (e: ShiftCardFragment | null = null) => {
    setInputs({
      ...inputs,
      openModal: !inputs.openModal,
      selectedShift: e || null,
    });
  };

  return (
    <div>
      {loading && <Loading />}

      {signoffRequested.length > 0 && (
        <TimesheetsGroup
          shifts={signoffRequested}
          toggleModal={toggleModal}
          title="Sign-off Requested"
        />
      )}
      {signedOff.length > 0 && (
        <TimesheetsGroup
          shifts={signedOff}
          toggleModal={toggleModal}
          title="Signed-Off"
        />
      )}
      {approved.length > 0 && (
        <TimesheetsGroup
          shifts={approved}
          toggleModal={toggleModal}
          title="Approved"
        />
      )}

      {!loading &&
        signoffRequested.length === 0 &&
        signedOff.length === 0 &&
        approved.length === 0 && <h4>No Shifts Available</h4>}

      {inputs.openModal && (
        <ShiftsModal
          handleClose={toggleModal}
          openModal={inputs.openModal}
          selectedShift={inputs.selectedShift}
        />
      )}
    </div>
  );
};

export default TimesheetsProgress;
