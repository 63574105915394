import * as Types from '../../../baseCODEGEN';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from 'msw'
const defaultOptions = {} as const;
export type CurrentWorkerForSelfRosteringQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentWorkerForSelfRosteringQuery = { __typename?: 'Query', currentWorker?: { __typename?: 'Worker', rosteringOrganisationRegistrations?: Array<{ __typename?: 'RosteringOrganisationRegistration', id: string, contractedWorkAmount?: number | null, contractedWorkIsHours?: boolean | null }> | null } | null };


export const CurrentWorkerForSelfRosteringDocument = gql`
    query CurrentWorkerForSelfRostering {
  currentWorker {
    rosteringOrganisationRegistrations {
      id
      contractedWorkAmount
      contractedWorkIsHours
    }
  }
}
    `;

/**
 * __useCurrentWorkerForSelfRosteringQuery__
 *
 * To run a query within a React component, call `useCurrentWorkerForSelfRosteringQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentWorkerForSelfRosteringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentWorkerForSelfRosteringQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentWorkerForSelfRosteringQuery(baseOptions?: Apollo.QueryHookOptions<CurrentWorkerForSelfRosteringQuery, CurrentWorkerForSelfRosteringQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentWorkerForSelfRosteringQuery, CurrentWorkerForSelfRosteringQueryVariables>(CurrentWorkerForSelfRosteringDocument, options);
      }
export function useCurrentWorkerForSelfRosteringLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentWorkerForSelfRosteringQuery, CurrentWorkerForSelfRosteringQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentWorkerForSelfRosteringQuery, CurrentWorkerForSelfRosteringQueryVariables>(CurrentWorkerForSelfRosteringDocument, options);
        }
export type CurrentWorkerForSelfRosteringQueryHookResult = ReturnType<typeof useCurrentWorkerForSelfRosteringQuery>;
export type CurrentWorkerForSelfRosteringLazyQueryHookResult = ReturnType<typeof useCurrentWorkerForSelfRosteringLazyQuery>;
export type CurrentWorkerForSelfRosteringQueryResult = Apollo.QueryResult<CurrentWorkerForSelfRosteringQuery, CurrentWorkerForSelfRosteringQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCurrentWorkerForSelfRosteringQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ currentWorker })
 *   )
 * })
 */
export const mockCurrentWorkerForSelfRosteringQuery = (resolver: ResponseResolver<GraphQLRequest<CurrentWorkerForSelfRosteringQueryVariables>, GraphQLContext<CurrentWorkerForSelfRosteringQuery>, any>) =>
  graphql.query<CurrentWorkerForSelfRosteringQuery, CurrentWorkerForSelfRosteringQueryVariables>(
    'CurrentWorkerForSelfRostering',
    resolver
  )
