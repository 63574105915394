import * as Tooltip from "@radix-ui/react-tooltip";
import styled, { keyframes } from "styled-components";

import { GLOBAL, GREY } from "@patchworkhealth/web-components";

const scaleIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);

  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const CalendarTooltipContent = styled(Tooltip.TooltipContent)`
  animation: ${scaleIn} 75ms ease-out;
  background: ${GREY.ten};
  border-radius: 8px;
  color: ${GLOBAL.white};
  font-size: 12px;
  font-weight: 600;
  min-width: 140px;
  padding: 6px 12px;
  z-index: 10;

  > div {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
`;
