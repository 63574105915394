import { Switch } from "react-router-dom";

import AccountActivationPage from "containers/account-activation";
import AgencyPortalPage from "containers/agency-portal";
import ForgottenPassword from "containers/forgotten-password";
import LoginPage from "containers/login";
import ResetPassword from "containers/reset-password";
import SingUpPage from "containers/signup";

import { RouteWrapper as Route } from "./RouteWrapper";

const PublicRoutes = () => (
  <Switch>
    <Route path="/" exact component={LoginPage} />
    <Route path="/enable_mobile" exact component={LoginPage} />
    <Route path="/forgot" exact component={ForgottenPassword} />
    <Route path="/signup" exact component={SingUpPage} />
    <Route path="/accept-invite" exact component={AccountActivationPage} />
    <Route path="/reset_password" exact component={ResetPassword} />

    <Route path="/portal" component={AgencyPortalPage} />

    <Route path="*" component={LoginPage} />
  </Switch>
);

export default PublicRoutes;
