import { ChangeEvent, useContext, useState } from "react";
import toast from "react-hot-toast";
import dayjs from "dayjs";

import {
  DefaultButton,
  FormGroup,
  FormInput,
  FormToggleButtons,
  Loading,
} from "@patchworkhealth/web-components";

import {
  useRosteringExceptionReportQuery,
  useRosteringExceptionReportReasonCategoriesQuery,
} from "components/ExceptionReports/__generated__/ExceptionReports.generated";
import { ShiftDetailsBox } from "components/ExceptionReports/components/ShiftDetailsBox/ShiftDetailsBox";
import { useSupervisors } from "components/ExceptionReports/components/SupervisorsList/useSupervisors";
import { CategoryReasonFormSelect } from "components/ExceptionReports/ExceptionReportForms/components/CategoryReasonFormSelect";
import { CompensationToggles } from "components/ExceptionReports/ExceptionReportForms/components/CompensationToggles";
import { DurationPicker } from "components/ExceptionReports/ExceptionReportForms/components/DurationPicker";
import { NoSupervisorPrompt } from "components/ExceptionReports/ExceptionReportForms/components/NoSupervisorPrompt";
import {
  onInputChange,
  onToggleChange,
  updateExceptionReportTableCache,
} from "components/ExceptionReports/ExceptionReportForms/ExceptionReportForms.helpers";
import {
  useRosteringExceptionReportCreateMutation,
  useRosteringExceptionReportUpdateMutation,
} from "components/ExceptionReports/ExceptionReportForms/ManualExceptionReportForm/__generated__/ManualExceptionReportForm.generated";
import {
  exceptionReportShiftInputEmptyState,
  getInitialShiftFormInput,
  getMutationVariables,
  isStartTimeBeforeEndTime,
  validateRequiredFields,
} from "components/ExceptionReports/ExceptionReportForms/ShiftExceptionReportForm/ShiftExceptionReportForm.helpers";
import { ExceptionReportShiftInputType } from "components/ExceptionReports/ExceptionReportForms/ShiftExceptionReportForm/ShiftExceptionReportForm.types";
import {
  ExceptionReportPages,
  RosteringShiftEventType,
} from "components/ExceptionReports/ExceptionReports.types";
import { TimeInput } from "components/Shared/TimeInput";
import { AppContext } from "context/AppContext";
import { formatError } from "helpers/formatError";
import { FormError } from "helpers/types";

import * as FormStyled from "./../ExceptionReportForms.styled";
import * as Styled from "./ShiftExceptionReportForm.styled";

interface Props {
  exceptionReportId: string | undefined;
  setExceptionReportId: (id: string) => void;
  shiftEvent: RosteringShiftEventType | undefined;
  setShiftEvent: (shiftEvent: RosteringShiftEventType) => void;
  setPage: (page: ExceptionReportPages) => void;
  returnHome: () => void;
}

export const ShiftExceptionReportForm = ({
  exceptionReportId,
  setExceptionReportId,
  shiftEvent,
  setShiftEvent,
  setPage,
  returnHome,
}: Props) => {
  const { user } = useContext(AppContext);

  const [exceptionReportShiftInput, setExceptionReportShiftInput] =
    useState<ExceptionReportShiftInputType>(
      exceptionReportShiftInputEmptyState
    );
  const { hasSupervisorsForCategory, hasSupervisors } = useSupervisors(
    exceptionReportShiftInput.rosteringExceptionReportCategory?.name,
    "tier1"
  );

  const { loading } = useRosteringExceptionReportQuery(
    exceptionReportId
      ? {
          variables: { reportId: +exceptionReportId },
          onCompleted: (data) => {
            setExceptionReportShiftInput(
              getInitialShiftFormInput(data.rosteringExceptionReport)
            );
            if (data?.rosteringExceptionReport?.rosteringEvent)
              setShiftEvent(data.rosteringExceptionReport.rosteringEvent);
          },
        }
      : { skip: true }
  );

  const [errors, setErrors] =
    useState<FormError<ExceptionReportShiftInputType> | null>(null);

  const { data: categoriesReasonsData } =
    useRosteringExceptionReportReasonCategoriesQuery();

  const [rosteringExceptionReportCreate] =
    useRosteringExceptionReportCreateMutation();

  const [rosteringExceptionReportUpdate] =
    useRosteringExceptionReportUpdateMutation();

  const createExceptionReport = async () => {
    const { data: result } = await rosteringExceptionReportCreate({
      variables: getMutationVariables(
        exceptionReportShiftInput,
        shiftEvent,
        Number(user?.rosteringOrganisationRegistrations?.[0].id)
      ),

      update: updateExceptionReportTableCache(
        Number(user?.rosteringOrganisations?.[0]?.id)
      ),
    });

    if (result?.rosteringExceptionReportCreate?.errors.length) {
      toast.error(formatError(result?.rosteringExceptionReportCreate.errors));
      return;
    }
    return result;
  };

  const updateExceptionReport = async () => {
    if (exceptionReportId) {
      const { data: result } = await rosteringExceptionReportUpdate({
        variables: {
          ...getMutationVariables(
            exceptionReportShiftInput,
            shiftEvent,
            Number(user?.rosteringOrganisationRegistrations?.[0].id)
          ),
          rosteringExceptionReportUpdateId: +exceptionReportId,
          shiftDate: shiftEvent?.startsAt,
          status: "draft",
        },
      });
      if (result?.rosteringExceptionReportUpdate?.errors?.length) {
        toast.error(formatError(result.rosteringExceptionReportUpdate.errors));
        return null;
      }

      return result;
    }
  };

  const onSaveForLater = async () => {
    try {
      const result = exceptionReportId
        ? await updateExceptionReport()
        : await createExceptionReport();
      if (result) {
        toast.success("Exception report added as a draft!");

        returnHome();
      }
    } catch (e) {
      toast.error("An error occurred");
    }
  };
  const onConfirm = async () => {
    try {
      if (validateRequiredFields(exceptionReportShiftInput, setErrors)) {
        if (exceptionReportId) {
          const result = await updateExceptionReport();

          const reportId =
            result?.rosteringExceptionReportUpdate?.rosteringExceptionReport
              ?.id;

          if (reportId) {
            setExceptionReportId(reportId);
          }

          setPage(ExceptionReportPages.ReviewPage);
        } else {
          const result = await createExceptionReport();

          const reportId =
            result?.rosteringExceptionReportCreate?.rosteringExceptionReport
              ?.id;

          if (reportId) {
            setExceptionReportId(reportId);
          }

          setPage(ExceptionReportPages.ReviewPage);
        }
      }
    } catch (e) {
      toast.error("An error occurred");
    }
  };

  const isSupervisorForCategory = hasSupervisorsForCategory;

  const isSupervisor = hasSupervisors;

  if (loading) {
    return (
      <FormStyled.Container>
        <Loading style={{ height: "500px" }} />
      </FormStyled.Container>
    );
  }

  return (
    <>
      <Styled.HeaderContainer>
        <FormStyled.Header>
          Tell us what happened on the shift
        </FormStyled.Header>
        <div style={{ display: "flex", gap: "10px" }}>
          <DefaultButton
            text="Save for later"
            type="white"
            onClick={onSaveForLater}
          />

          <FormStyled.ConfirmButton
            text="Confirm"
            onClick={onConfirm}
            disabled={!isSupervisor || !isSupervisorForCategory}
          />
        </div>
      </Styled.HeaderContainer>

      <FormStyled.Container>
        <NoSupervisorPrompt isSupervisor={isSupervisor} />

        <CategoryReasonFormSelect
          rosteringExceptionReportReasonCategories={
            categoriesReasonsData?.rosteringExceptionReportReasonCategories
          }
          exceptionReportCategoryValue={
            exceptionReportShiftInput.rosteringExceptionReportCategory
          }
          exceptionReportReasonValue={
            exceptionReportShiftInput.rosteringExceptionReportReason
          }
          setErrors={setErrors}
          setInput={setExceptionReportShiftInput}
          errors={errors}
          isSupervisorForCategory={isSupervisorForCategory}
          isSupervisor={isSupervisor}
        />

        <FormGroup>
          <FormStyled.RequiredLabel>Details</FormStyled.RequiredLabel>
          <FormInput
            as="textarea"
            name="details"
            placeholder="Please explain what happened in your shift as much detail as you can"
            style={{ maxWidth: "584px", minHeight: "160px" }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const { value } = e.target;
              setExceptionReportShiftInput((prev) => ({
                ...prev,
                details: value,
              }));
            }}
            value={exceptionReportShiftInput.details}
          />
        </FormGroup>

        <FormGroup>
          <FormStyled.RequiredLabel required>
            Did this impact patient safety?
          </FormStyled.RequiredLabel>
          <FormStyled.ToggleWrapper>
            <FormToggleButtons
              name="patientSafetyImpacted"
              input={exceptionReportShiftInput.patientSafetyImpacted}
              text={["No", "Yes"]}
              action={onToggleChange(setErrors, setExceptionReportShiftInput)}
            />
          </FormStyled.ToggleWrapper>
          {errors?.patientSafetyImpacted && (
            <FormStyled.ErrorLabel data-testid="error_safety_impacted">
              {errors.patientSafetyImpacted}
            </FormStyled.ErrorLabel>
          )}
        </FormGroup>

        <CompensationToggles
          onToggleChange={onToggleChange(
            setErrors,
            setExceptionReportShiftInput
          )}
          exceptionReportCategory={
            exceptionReportShiftInput?.rosteringExceptionReportCategory
          }
          exceptionReportRequestedCompensation={
            exceptionReportShiftInput.requestedCompensation
          }
          errors={errors}
        />

        <FormStyled.RequiredLabel style={{ fontSize: "16px" }}>
          Amend your shift time according to the hours you worked (if
          applicable)
        </FormStyled.RequiredLabel>
        <Styled.ShiftOuterBox>
          <ShiftDetailsBox
            rosteringExceptionReport={{ rosteringEvent: shiftEvent }}
            topHeader={false}
            createFlow
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex" }}>
              <FormGroup style={{ marginRight: "12px", gridColumn: "2" }}>
                <FormStyled.RequiredLabel style={{ marginTop: 0 }}>
                  Actual Start Time
                </FormStyled.RequiredLabel>
                <TimeInput
                  testId="shift_actual_start_time"
                  name="shiftActualStartTime"
                  onChange={onInputChange(
                    setErrors,
                    setExceptionReportShiftInput
                  )}
                  value={exceptionReportShiftInput?.shiftActualStartTime}
                />
                {isStartTimeBeforeEndTime(
                  exceptionReportShiftInput?.shiftActualStartTime,
                  exceptionReportShiftInput?.shiftActualEndTime
                ) && (
                  <Styled.TimeDateParagraph>
                    {dayjs(shiftEvent?.startsAt).format("Do MMM YYYY")}
                  </Styled.TimeDateParagraph>
                )}
                {errors?.shiftActualStartTime && (
                  <FormStyled.ErrorLabel data-testid="error_break_time">
                    {errors.shiftActualStartTime}
                  </FormStyled.ErrorLabel>
                )}
              </FormGroup>

              <FormGroup style={{ marginRight: "12px", gridColumn: "2" }}>
                <FormStyled.RequiredLabel style={{ marginTop: 0 }}>
                  Actual End Time
                </FormStyled.RequiredLabel>
                <TimeInput
                  testId="shift_actual_end_time"
                  name="shiftActualEndTime"
                  onChange={onInputChange(
                    setErrors,
                    setExceptionReportShiftInput
                  )}
                  value={exceptionReportShiftInput?.shiftActualEndTime}
                />
                {isStartTimeBeforeEndTime(
                  exceptionReportShiftInput.shiftActualStartTime,
                  exceptionReportShiftInput.shiftActualEndTime
                ) && (
                  <Styled.TimeDateParagraph>
                    {dayjs(shiftEvent?.startsAt)
                      .add(1, "d")
                      .format("Do MMM YYYY")}
                  </Styled.TimeDateParagraph>
                )}
                {errors?.shiftActualEndTime && (
                  <FormStyled.ErrorLabel data-testid="error_break_time">
                    {errors.shiftActualStartTime}
                  </FormStyled.ErrorLabel>
                )}
              </FormGroup>
            </div>
            <div style={{ display: "flex", gap: "15px" }}>
              <FormGroup data-testid="original_break">
                <FormStyled.RequiredLabel>
                  Original Break Time
                </FormStyled.RequiredLabel>
                <DurationPicker
                  property="breakScheduledTime"
                  setErrors={setErrors}
                  setInput={setExceptionReportShiftInput}
                  value={exceptionReportShiftInput.breakScheduledTime ?? ""}
                />
                {errors?.breakActualTime && (
                  <FormStyled.ErrorLabel data-testid="error_break_time">
                    {errors.breakActualTime}
                  </FormStyled.ErrorLabel>
                )}
              </FormGroup>

              <FormGroup data-testid="actual_break">
                <FormStyled.RequiredLabel>
                  Actual Break Time
                </FormStyled.RequiredLabel>
                <DurationPicker
                  property="breakActualTime"
                  setErrors={setErrors}
                  setInput={setExceptionReportShiftInput}
                  value={exceptionReportShiftInput.breakActualTime ?? ""}
                />
                {errors?.breakActualTime && (
                  <FormStyled.ErrorLabel data-testid="error_break_time">
                    {errors.breakActualTime}
                  </FormStyled.ErrorLabel>
                )}
              </FormGroup>
            </div>
          </div>
        </Styled.ShiftOuterBox>
        <FormGroup
          style={{ width: "320px" }}
          data-testid="select_most_senior_clinician"
        >
          <FormStyled.RequiredLabel>
            Who was the most senior clinician? (Optional)
          </FormStyled.RequiredLabel>
          <FormInput
            name="mostSeniorClinician"
            placeholder="Enter a clinician’s name"
            type="text"
            required
            onChange={onInputChange(setErrors, setExceptionReportShiftInput)}
            value={exceptionReportShiftInput.mostSeniorClinician}
          />
          {errors?.mostSeniorClinician && (
            <FormStyled.ErrorLabel data-testid="error_most_senior_clinician">
              {errors.mostSeniorClinician}
            </FormStyled.ErrorLabel>
          )}
        </FormGroup>
        <Styled.FooterText>
          The senior clinician will need to approve your report before your
          supervisor receives it.
        </Styled.FooterText>
      </FormStyled.Container>
      <Styled.FooterContainer>
        <DefaultButton
          text="Save for later"
          type="white"
          onClick={onSaveForLater}
        />

        <FormStyled.ConfirmButton
          text="Confirm"
          onClick={onConfirm}
          disabled={!isSupervisor || !isSupervisorForCategory}
        />
      </Styled.FooterContainer>
    </>
  );
};
