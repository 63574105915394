export const checkLeaveType = (
  type: string | null | undefined,
  total?: number | null | undefined
) => {
  if (!type) return "";

  if (type === "pa") {
    if (!total) return "PAs";
    if (total === 1) return "PA";

    return "PAs";
  }

  if (type === "days") {
    if (!total) return "DAYS";
    if (total === 1) return "Day";
    return "Days";
  }
};
