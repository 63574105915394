import dayjs from "dayjs";

import { formatCompensationForModal } from "components/ExceptionReports/ExceptionReportForms/ManualExceptionReportForm/ManualExceptionReportForm.helpers";
import { formattedOvertimeString } from "components/ExceptionReports/ExceptionReports.helpers";
import { RosteringExceptionReportNotesType } from "components/ExceptionReports/ExceptionReports.types";

import {
  ExceptionReportGivenCompensationEnum,
  ExceptionReportStatusEnum,
} from "../../../../baseCODEGEN";
import * as Styled from "./DecisionBox.styled";

interface Props {
  status: ExceptionReportStatusEnum;
  approvedAt: string | null;
  rejectedAt: string | null;
  rosteringExceptionReportNotes: RosteringExceptionReportNotesType;
  givenCompensation: ExceptionReportGivenCompensationEnum | null | undefined;
  entitlement: string | undefined;
  isDisputed: boolean;
}

export const DecisionBox = ({
  status,
  rosteringExceptionReportNotes,
  givenCompensation,
  rejectedAt,
  entitlement,
  approvedAt,
  isDisputed = false,
}: Props) => {
  if (
    status !== ExceptionReportStatusEnum.Approved &&
    status !== ExceptionReportStatusEnum.Rejected &&
    status !== ExceptionReportStatusEnum.RejectedDispute
  )
    return null;

  const getRejectNotes = () => {
    if (!rosteringExceptionReportNotes?.length) return "N/A";
    const filteredTier1RejectNotes = rosteringExceptionReportNotes?.filter(
      (note) => note.noteType === "tier1_reject"
    );
    const filteredTier2RejectNotes = rosteringExceptionReportNotes?.filter(
      (note) => note.noteType === "tier2_reject"
    );

    if (filteredTier2RejectNotes?.length && isDisputed) {
      return filteredTier2RejectNotes
        ?.map((note) => note.content || "N/A")
        .join(", ");
    } else {
      return filteredTier1RejectNotes
        ?.map((note) => note.content || "N/A")
        .join(", ");
    }
  };

  const getApproveNotes = () => {
    if (!rosteringExceptionReportNotes?.length) return "N/A";
    const filteredTier1ApproveNotes = rosteringExceptionReportNotes?.filter(
      (note) => note.noteType === "tier1_approve"
    );

    const filteredTier2ApproveNotes = rosteringExceptionReportNotes?.filter(
      (note) => note.noteType === "tier2_approve"
    );

    if (filteredTier2ApproveNotes?.length && isDisputed) {
      return filteredTier2ApproveNotes
        ?.map((note) => note.content || "N/A")
        .join(", ");
    } else {
      return filteredTier1ApproveNotes
        ?.map((note) => note.content || "N/A")
        .join(", ");
    }
  };

  const isRejected =
    status === ExceptionReportStatusEnum.Rejected ||
    status === ExceptionReportStatusEnum.RejectedDispute;

  return (
    <Styled.DecisionInfoBox status={status}>
      <Styled.DecisionBoxHeader>
        {isRejected ? (
          <RejectedIcon data-testid="rejected_icon" />
        ) : (
          <ApprovedIcon data-testid="approved_icon" />
        )}
        <p
          style={{
            paddingLeft: 5,
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
          {isRejected ? "Rejected" : "Approved"}
        </p>
      </Styled.DecisionBoxHeader>

      <div style={{ paddingLeft: "22px" }}>
        <Styled.DecisionBoxText data-testid="decision_text_box">
          {isRejected ? "Rejected: " : "Approved: "}
          <span>
            {dayjs(isRejected ? rejectedAt : approvedAt).format("Do MMM YYYY")}
          </span>
        </Styled.DecisionBoxText>

        {!isRejected && (
          <Styled.DecisionBoxText>
            Compensation Given:{" "}
            <span>
              {givenCompensation
                ? formatCompensationForModal(givenCompensation)
                : "None"}
            </span>
            {givenCompensation === "time_off_in_lieu" && (
              <span>
                {", "}
                {formattedOvertimeString(entitlement)}
              </span>
            )}
          </Styled.DecisionBoxText>
        )}

        <Styled.DecisionBoxText
          style={{
            display: "block",
            minHeight: 50,
            maxHeight: 100,
            overflow: "auto",
          }}
        >
          Notes:{" "}
          <span>{isRejected ? getRejectNotes() : getApproveNotes()}</span>{" "}
        </Styled.DecisionBoxText>
      </div>
    </Styled.DecisionInfoBox>
  );
};

const RejectedIcon = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>rejected_icon</title>
    <g clipPath="url(#clip0_638_18332)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0495 2.99967C5.90734 2.99967 2.54948 6.35754 2.54948 10.4997C2.54948 14.6418 5.90734 17.9997 10.0495 17.9997C14.1916 17.9997 17.5495 14.6418 17.5495 10.4997C17.5495 6.35754 14.1916 2.99967 10.0495 2.99967ZM0.882812 10.4997C0.882812 5.43706 4.98687 1.33301 10.0495 1.33301C15.1121 1.33301 19.2161 5.43706 19.2161 10.4997C19.2161 15.5623 15.1121 19.6663 10.0495 19.6663C4.98687 19.6663 0.882812 15.5623 0.882812 10.4997ZM13.1387 7.41043C13.4642 7.73587 13.4642 8.2635 13.1387 8.58894L11.228 10.4997L13.1387 12.4104C13.4642 12.7359 13.4642 13.2635 13.1387 13.5889C12.8133 13.9144 12.2857 13.9144 11.9602 13.5889L10.0495 11.6782L8.13874 13.5889C7.8133 13.9144 7.28566 13.9144 6.96023 13.5889C6.63479 13.2635 6.63479 12.7359 6.96023 12.4104L8.87097 10.4997L6.96023 8.58894C6.63479 8.2635 6.63479 7.73587 6.96023 7.41043C7.28566 7.08499 7.8133 7.08499 8.13874 7.41043L10.0495 9.32117L11.9602 7.41043C12.2857 7.08499 12.8133 7.08499 13.1387 7.41043Z"
        fill="#BD2D2E"
      />
    </g>
    <defs>
      <clipPath id="clip0_638_18332">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.0493164 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

const ApprovedIcon = () => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>approved_icon</title>
    <g clipPath="url(#clip0_643_17609)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.14455 2.27751C8.48547 1.97455 9.8884 2.11316 11.1441 2.67267C11.5014 2.83189 11.9202 2.67128 12.0794 2.31395C12.2386 1.95661 12.078 1.53786 11.7207 1.37865C10.1859 0.694799 8.47125 0.525387 6.83234 0.895675C5.19344 1.26596 3.71814 2.15611 2.62647 3.43336C1.5348 4.71062 0.885255 6.30654 0.774702 7.98311C0.66415 9.65968 1.09852 11.3271 2.01302 12.7366C2.92752 14.1461 4.27316 15.2223 5.84925 15.8046C7.42534 16.3869 9.14744 16.4441 10.7587 15.9677C12.37 15.4913 13.7841 14.5068 14.7901 13.1611C15.7961 11.8153 16.3402 10.18 16.3411 8.49982V7.84816C16.3411 7.45695 16.024 7.13982 15.6328 7.13982C15.2416 7.13982 14.9245 7.45695 14.9245 7.84816V8.49942C14.9237 9.87414 14.4785 11.2118 13.6554 12.3128C12.8323 13.4139 11.6753 14.2194 10.357 14.6092C9.03872 14.9989 7.62973 14.9521 6.3402 14.4757C5.05068 13.9993 3.9497 13.1188 3.20147 11.9655C2.45324 10.8123 2.09785 9.44806 2.1883 8.07632C2.27875 6.70458 2.8102 5.39883 3.70338 4.3538C4.59657 3.30878 5.80363 2.58047 7.14455 2.27751ZM16.1339 3.33377C16.4104 3.05701 16.4102 2.60852 16.1334 2.33204C15.8567 2.05556 15.4082 2.05578 15.1317 2.33254L8.54923 8.92159L6.92535 7.29771C6.64873 7.02109 6.20023 7.02109 5.92361 7.29771C5.64699 7.57433 5.64699 8.02282 5.92361 8.29944L8.04861 10.4244C8.18149 10.5573 8.36173 10.632 8.54966 10.6319C8.73758 10.6319 8.91778 10.5571 9.0506 10.4242L16.1339 3.33377Z"
        fill="#57A886"
      />
    </g>
    <defs>
      <clipPath id="clip0_643_17609">
        <rect
          width="17"
          height="17"
          fill="white"
          transform="translate(0.0493164)"
        />
      </clipPath>
    </defs>
  </svg>
);
