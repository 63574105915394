import { FormEvent, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import toast from "react-hot-toast";

import {
  isValidPassword,
  NOTIFICATION_MESSAGES,
} from "@patchworkhealth/web-components";

import { AccountActivation } from "components/Auth";
import LayoutAuth from "components/Layout/LayoutAuth";
import { FormContainer, Wrapper } from "components/Style";
import { useAccountActivationMutation } from "gql/new-gql/__generated__/mutations.generated";
import history from "helpers/functions";

function AccountActivationPage() {
  // State ******************************************************

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);

  // Graphql **************************************************

  const [accountActivation, { loading }] = useAccountActivationMutation();

  useEffect(() => {
    const index = window.location.href.indexOf("=");
    setToken(window.location.href.slice(index + 1));
  }, []);

  // Functions **********************************************

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error(NOTIFICATION_MESSAGES.PASSWORD.MISMATCH);
      return;
    }

    if (!isValidPassword(password)) {
      toast.error(NOTIFICATION_MESSAGES.PASSWORD.WEAK);
      return;
    }

    const toastId = toast.loading("Loading...");

    try {
      const { data } = await accountActivation({
        variables: {
          invitationToken: token,
          password: password,
        },
      });
      if (data?.accountAcceptInvite?.errors) {
        toast.error("There was an error!", { id: toastId });
        return;
      }

      toast.success("Password reset successfully!", { id: toastId });

      setTimeout(() => {
        history.push("/");
      }, 4000);
    } catch (e) {
      toast.error("There was an error!", { id: toastId });
    }
  };

  return (
    <LayoutAuth title="Activation">
      <Wrapper>
        <Container>
          <FormContainer data-testid="test__component__login">
            <AccountActivation
              onSubmit={onSubmit}
              setPassword={setPassword}
              isPasswordHidden={isPasswordHidden}
              setIsPasswordHidden={setIsPasswordHidden}
              setConfirmPassword={setConfirmPassword}
              loading={loading}
            />
          </FormContainer>
        </Container>
      </Wrapper>
    </LayoutAuth>
  );
}

export default AccountActivationPage;
