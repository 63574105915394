import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import moment, { Moment } from "moment";

import "react-datepicker/dist/react-datepicker.css";

interface Props {
  rosteringPeriodId: string;
  viewDate: Moment;
}

function CalendarHeader({ rosteringPeriodId, viewDate }: Props) {
  const history = useHistory();

  const setViewDate = (date: Moment) => {
    history.push(
      `/roster-preferences/${rosteringPeriodId}?viewDate=${date.format(
        "YYYY-MM-DD"
      )}`
    );
  };

  const handleMonthChange = (direction: "previous" | "next") => {
    const date: Moment =
      direction === "previous"
        ? viewDate.clone().subtract(1, "month")
        : viewDate.clone().add(1, "month");
    setViewDate(date);
  };

  const handleDateChange = (date: Date | null) => {
    setViewDate(moment(date));
  };

  return (
    <div className="w-[1021px] mx-auto flex justify-between mb-4">
      <div className="flex items-center [&>button]:ml-4">
        <DatePicker
          selected={viewDate.toDate()}
          onChange={handleDateChange}
          dateFormat="MMMM yyyy"
          showMonthYearPicker
          showFullMonthYearPicker
          className="px-6 py-1 mr-4 text-sm border rounded-lg border-grey-2 h-11"
        />
      </div>

      <div className="flex items-center">
        <button
          className="text-gray-500 hover:text-gray-700 hover:bg-grey-2 w-10 rounded-full text-[22px]"
          onClick={() => handleMonthChange("previous")}
        >
          &lt;
        </button>
        <div className="text-center month text-lg font-bold w-[140px]">
          {viewDate.format("MMMM YYYY")}
        </div>
        <button
          className="text-gray-500 hover:text-gray-700 hover:bg-grey-2 w-10 rounded-full text-[22px]"
          onClick={() => handleMonthChange("next")}
        >
          &gt;
        </button>
      </div>
    </div>
  );
}

export default CalendarHeader;
