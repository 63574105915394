const attachAndInit = ({
  firstName,
  lastName,
  email,
  uuid,
}: {
  firstName: string;
  lastName: string;
  email: string;
  uuid: string;
}) => {
  if (process.env.NODE_ENV !== "production") return;
  const beamerScript1 = document.createElement("script");
  beamerScript1.type = "text/javascript";
  beamerScript1.src = "https://app.getbeamer.com/js/beamer-embed.js";
  beamerScript1.defer = true;
  const beamerScript2 = document.createElement("script");
  beamerScript2.defer = true;

  beamerScript2.textContent = `	var beamer_config = {
		product_id : 'WHaotCXZ49442',
    selector: 'beamerBtn',
    bounce: false,
    icon: 'alert_circle',
    user_firstname: "${firstName}",
    user_lastname: "${lastName}",
    user_email: "${email}",
    user_id: "${uuid}",
    product_name: "wwv",
	};`;

  try {
    document.body.appendChild(beamerScript1);
    document.body.appendChild(beamerScript2);
    window?.Beamer?.init?.();
  } catch (error) {
    console.error("Failed to attach and init Beamer", { error });
  }
};

const destroy = () => {
  if (process.env.NODE_ENV !== "production") return;

  try {
    window?.Beamer?.destroy?.();
  } catch (error) {
    console.error("Failed to destroy Beamer", { error });
  }
};

export const beamer = {
  attachAndInit,
  destroy,
};
