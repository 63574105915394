import * as Types from '../../../baseCODEGEN';

import { gql } from '@apollo/client';
import { ErrorFragmentDoc } from '../../../gql/new-gql/__generated__/fragments.generated';
import * as Apollo from '@apollo/client';
import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from 'msw'
const defaultOptions = {} as const;
export type MissedShiftsFragment = { __typename?: 'RosteringEvent', id: string, startsAt: any, endsAt: any, schedule?: { __typename?: 'RosteringSchedule', id: string, roster?: { __typename?: 'RosteringRoster', id: number, rota: { __typename?: 'RosteringRota', id: string, name: string } } | null } | null, scheduleable?: { __typename?: 'RosteringShift', organisationActivity?: { __typename?: 'RosteringOrganisationActivity', id: number, name: string } | null, shiftType?: { __typename?: 'RosteringShiftType', id: string, name: string } | null } | null };

export type RosteringLeaveEntitlementsQueryVariables = Types.Exact<{
  organisationId: Types.Scalars['Int'];
  rosteringOrganisationRegistrationId: Types.Scalars['Int'];
  expired?: Types.InputMaybe<Types.Scalars['Boolean']>;
  entitlementLeaveTypeEnum?: Types.InputMaybe<Types.RosteringLeaveEntitlementLeaveTypeEnum>;
}>;


export type RosteringLeaveEntitlementsQuery = { __typename?: 'Query', rosteringLeaveEntitlements?: Array<{ __typename?: 'LeaveEntitlement', id: string, startDate: any, endDate: any, measurementType?: string | null, remainingBookableLeave: number, total: number, totalApprovedLeave: number, totalPendingLeave: number, rosteringLeaveType?: { __typename?: 'LeaveType', id: string, leaveType: string } | null, rosteringOrganisationRegistration?: { __typename?: 'RosteringOrganisationRegistration', id: string, organisation?: { __typename?: 'Organisation', id: number, name?: string | null } | null } | null }> | null };

export type RosteringLeaveReasonsQueryVariables = Types.Exact<{
  organisationId: Types.Scalars['Int'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  leaveAbsenceCategory?: Types.InputMaybe<Types.Scalars['String']>;
  searchTerm?: Types.InputMaybe<Types.Scalars['String']>;
  leaveAbsenceType?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type RosteringLeaveReasonsQuery = { __typename?: 'Query', rosteringLeaveReasons?: { __typename?: 'LeaveReasonConnection', nodes: Array<{ __typename?: 'LeaveReason', id: string, name: string }> } | null };

export type RosteringLeaveRequestsQueryVariables = Types.Exact<{
  organisationId: Types.Scalars['Int'];
  rosteringOrganisationRegistrationId: Types.Scalars['Int'];
  rosteringLeaveTypeId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type RosteringLeaveRequestsQuery = { __typename?: 'Query', rosteringLeaveRequests?: Array<{ __typename?: 'LeaveRequest', id: string, startDate?: any | null, endDate?: any | null, leaveFlowType?: string | null, total?: number | null, status?: string | null, rosteringLeaveEntitlement?: { __typename?: 'LeaveEntitlement', id: string, measurementType?: string | null } | null, rosteringLeaveType?: { __typename?: 'LeaveType', id: string, leaveType: string } | null }> | null };

export type RosteringLeaveRequestCalculationQueryVariables = Types.Exact<{
  accountId: Types.Scalars['Int'];
  organisationId: Types.Scalars['Int'];
  endDatetime: Types.Scalars['DateTime'];
  rosteringLeaveEntitlementId: Types.Scalars['Int'];
  startDatetime: Types.Scalars['DateTime'];
}>;


export type RosteringLeaveRequestCalculationQuery = { __typename?: 'Query', rosteringLeaveRequestCalculation?: { __typename?: 'LeaveRequestCalculation', shiftAmountTaken?: number | null, daysLeaveRemaining?: number | null, missedShifts?: Array<{ __typename?: 'RosteringEvent', id: string, startsAt: any, endsAt: any, schedule?: { __typename?: 'RosteringSchedule', id: string, roster?: { __typename?: 'RosteringRoster', id: number, rota: { __typename?: 'RosteringRota', id: string, name: string } } | null } | null, scheduleable?: { __typename?: 'RosteringShift', organisationActivity?: { __typename?: 'RosteringOrganisationActivity', id: number, name: string } | null, shiftType?: { __typename?: 'RosteringShiftType', id: string, name: string } | null } | null }> | null, errors?: Array<{ __typename?: 'ValidationError', attribute?: string | null, message: string }> | null } | null };

export type RosteringLeaveRequestQueryVariables = Types.Exact<{
  rosteringLeaveRequestId: Types.Scalars['Int'];
  organisationId: Types.Scalars['Int'];
  rosteringOrganisationRegistrationId: Types.Scalars['Int'];
}>;


export type RosteringLeaveRequestQuery = { __typename?: 'Query', rosteringLeaveRequest?: { __typename?: 'LeaveRequest', id: string, startDate?: any | null, endDate?: any | null, createdAt: any, status?: string | null, reasonForCancellation?: string | null, total?: number | null, reasonForLeave?: string | null, measurementType?: string | null, cancellableRequest: boolean, rosteringLeaveType?: { __typename?: 'LeaveType', id: string, leaveType: string } | null, rosteringLeaveRequestApprovals?: Array<{ __typename?: 'LeaveRequestApproval', id: string, status: string, reasonForDecision?: string | null, approver?: { __typename?: 'User', id: string, fullName: string, email?: string | null } | null }> | null, missedShifts?: Array<{ __typename?: 'RosteringEvent', id: string, startsAt: any, endsAt: any, schedule?: { __typename?: 'RosteringSchedule', id: string, roster?: { __typename?: 'RosteringRoster', id: number, rota: { __typename?: 'RosteringRota', id: string, name: string } } | null } | null, scheduleable?: { __typename?: 'RosteringShift', organisationActivity?: { __typename?: 'RosteringOrganisationActivity', id: number, name: string } | null, shiftType?: { __typename?: 'RosteringShiftType', id: string, name: string } | null } | null }> | null, rosteringLeaveReason?: { __typename?: 'LeaveReason', id: string, name: string } | null } | null };

export type RosteringLeaveRequestCreateMutationVariables = Types.Exact<{
  organisationId: Types.Scalars['Int'];
  accountId: Types.Scalars['Int'];
  endDatetime: Types.Scalars['DateTime'];
  rosteringLeaveEntitlementId: Types.Scalars['Int'];
  startDatetime: Types.Scalars['DateTime'];
  reasonForLeave?: Types.InputMaybe<Types.Scalars['String']>;
  rosteringLeaveReasonId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type RosteringLeaveRequestCreateMutation = { __typename?: 'Mutation', rosteringLeaveRequestCreate?: { __typename?: 'CreateLeaveRequestPayload', rosteringLeaveRequestType?: { __typename?: 'LeaveRequest', id: string, leaveFlowType?: string | null, rosteringLeaveEntitlement?: { __typename?: 'LeaveEntitlement', id: string, remainingBookableLeave: number, total: number, totalApprovedLeave: number, totalPendingLeave: number } | null } | null, errors: Array<{ __typename?: 'ValidationError', attribute?: string | null, message: string }> } | null };

export type RosteringLeaveRequestCancelMutationVariables = Types.Exact<{
  organisationId: Types.Scalars['Int'];
  id: Types.Scalars['Int'];
  reasonForCancellation?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type RosteringLeaveRequestCancelMutation = { __typename?: 'Mutation', rosteringLeaveRequestCancel?: { __typename?: 'CancelLeaveRequestPayload', rosteringLeaveRequest?: { __typename?: 'LeaveRequest', id: string, status?: string | null, reasonForCancellation?: string | null, rosteringLeaveRequestApprovals?: Array<{ __typename?: 'LeaveRequestApproval', id: string, status: string, reasonForDecision?: string | null, approver?: { __typename?: 'User', fullName: string, email?: string | null } | null }> | null } | null, errors: Array<{ __typename?: 'ValidationError', attribute?: string | null, message: string }> } | null };

export const MissedShiftsFragmentDoc = gql`
    fragment MissedShifts on RosteringEvent {
  id
  startsAt
  endsAt
  schedule {
    id
    roster {
      id
      rota {
        id
        name
      }
    }
  }
  scheduleable {
    ... on RosteringShift {
      organisationActivity {
        id
        name
      }
      shiftType {
        id
        name
      }
    }
  }
}
    `;
export const RosteringLeaveEntitlementsDocument = gql`
    query RosteringLeaveEntitlements($organisationId: Int!, $rosteringOrganisationRegistrationId: Int!, $expired: Boolean, $entitlementLeaveTypeEnum: RosteringLeaveEntitlementLeaveTypeEnum) {
  rosteringLeaveEntitlements(
    organisationId: $organisationId
    rosteringOrganisationRegistrationId: $rosteringOrganisationRegistrationId
    expired: $expired
    entitlementLeaveTypeEnum: $entitlementLeaveTypeEnum
  ) {
    id
    startDate
    endDate
    rosteringLeaveType {
      id
      leaveType
    }
    rosteringOrganisationRegistration {
      id
      organisation {
        id
        name
      }
    }
    measurementType
    remainingBookableLeave
    total
    totalApprovedLeave
    totalPendingLeave
  }
}
    `;

/**
 * __useRosteringLeaveEntitlementsQuery__
 *
 * To run a query within a React component, call `useRosteringLeaveEntitlementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRosteringLeaveEntitlementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRosteringLeaveEntitlementsQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      rosteringOrganisationRegistrationId: // value for 'rosteringOrganisationRegistrationId'
 *      expired: // value for 'expired'
 *      entitlementLeaveTypeEnum: // value for 'entitlementLeaveTypeEnum'
 *   },
 * });
 */
export function useRosteringLeaveEntitlementsQuery(baseOptions: Apollo.QueryHookOptions<RosteringLeaveEntitlementsQuery, RosteringLeaveEntitlementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RosteringLeaveEntitlementsQuery, RosteringLeaveEntitlementsQueryVariables>(RosteringLeaveEntitlementsDocument, options);
      }
export function useRosteringLeaveEntitlementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RosteringLeaveEntitlementsQuery, RosteringLeaveEntitlementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RosteringLeaveEntitlementsQuery, RosteringLeaveEntitlementsQueryVariables>(RosteringLeaveEntitlementsDocument, options);
        }
export type RosteringLeaveEntitlementsQueryHookResult = ReturnType<typeof useRosteringLeaveEntitlementsQuery>;
export type RosteringLeaveEntitlementsLazyQueryHookResult = ReturnType<typeof useRosteringLeaveEntitlementsLazyQuery>;
export type RosteringLeaveEntitlementsQueryResult = Apollo.QueryResult<RosteringLeaveEntitlementsQuery, RosteringLeaveEntitlementsQueryVariables>;
export const RosteringLeaveReasonsDocument = gql`
    query RosteringLeaveReasons($organisationId: Int!, $after: String, $before: String, $first: Int, $last: Int, $leaveAbsenceCategory: String, $searchTerm: String, $leaveAbsenceType: String) {
  rosteringLeaveReasons(
    organisationId: $organisationId
    after: $after
    before: $before
    first: $first
    last: $last
    leaveAbsenceCategory: $leaveAbsenceCategory
    searchTerm: $searchTerm
    leaveAbsenceType: $leaveAbsenceType
  ) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useRosteringLeaveReasonsQuery__
 *
 * To run a query within a React component, call `useRosteringLeaveReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRosteringLeaveReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRosteringLeaveReasonsQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      leaveAbsenceCategory: // value for 'leaveAbsenceCategory'
 *      searchTerm: // value for 'searchTerm'
 *      leaveAbsenceType: // value for 'leaveAbsenceType'
 *   },
 * });
 */
export function useRosteringLeaveReasonsQuery(baseOptions: Apollo.QueryHookOptions<RosteringLeaveReasonsQuery, RosteringLeaveReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RosteringLeaveReasonsQuery, RosteringLeaveReasonsQueryVariables>(RosteringLeaveReasonsDocument, options);
      }
export function useRosteringLeaveReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RosteringLeaveReasonsQuery, RosteringLeaveReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RosteringLeaveReasonsQuery, RosteringLeaveReasonsQueryVariables>(RosteringLeaveReasonsDocument, options);
        }
export type RosteringLeaveReasonsQueryHookResult = ReturnType<typeof useRosteringLeaveReasonsQuery>;
export type RosteringLeaveReasonsLazyQueryHookResult = ReturnType<typeof useRosteringLeaveReasonsLazyQuery>;
export type RosteringLeaveReasonsQueryResult = Apollo.QueryResult<RosteringLeaveReasonsQuery, RosteringLeaveReasonsQueryVariables>;
export const RosteringLeaveRequestsDocument = gql`
    query RosteringLeaveRequests($organisationId: Int!, $rosteringOrganisationRegistrationId: Int!, $rosteringLeaveTypeId: Int) {
  rosteringLeaveRequests(
    organisationId: $organisationId
    rosteringOrganisationRegistrationId: $rosteringOrganisationRegistrationId
    rosteringLeaveTypeId: $rosteringLeaveTypeId
  ) {
    id
    startDate
    endDate
    leaveFlowType
    total
    status
    rosteringLeaveEntitlement {
      id
      measurementType
    }
    rosteringLeaveType {
      id
      leaveType
    }
  }
}
    `;

/**
 * __useRosteringLeaveRequestsQuery__
 *
 * To run a query within a React component, call `useRosteringLeaveRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRosteringLeaveRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRosteringLeaveRequestsQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      rosteringOrganisationRegistrationId: // value for 'rosteringOrganisationRegistrationId'
 *      rosteringLeaveTypeId: // value for 'rosteringLeaveTypeId'
 *   },
 * });
 */
export function useRosteringLeaveRequestsQuery(baseOptions: Apollo.QueryHookOptions<RosteringLeaveRequestsQuery, RosteringLeaveRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RosteringLeaveRequestsQuery, RosteringLeaveRequestsQueryVariables>(RosteringLeaveRequestsDocument, options);
      }
export function useRosteringLeaveRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RosteringLeaveRequestsQuery, RosteringLeaveRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RosteringLeaveRequestsQuery, RosteringLeaveRequestsQueryVariables>(RosteringLeaveRequestsDocument, options);
        }
export type RosteringLeaveRequestsQueryHookResult = ReturnType<typeof useRosteringLeaveRequestsQuery>;
export type RosteringLeaveRequestsLazyQueryHookResult = ReturnType<typeof useRosteringLeaveRequestsLazyQuery>;
export type RosteringLeaveRequestsQueryResult = Apollo.QueryResult<RosteringLeaveRequestsQuery, RosteringLeaveRequestsQueryVariables>;
export const RosteringLeaveRequestCalculationDocument = gql`
    query RosteringLeaveRequestCalculation($accountId: Int!, $organisationId: Int!, $endDatetime: DateTime!, $rosteringLeaveEntitlementId: Int!, $startDatetime: DateTime!) {
  rosteringLeaveRequestCalculation(
    accountId: $accountId
    organisationId: $organisationId
    endDatetime: $endDatetime
    rosteringLeaveEntitlementId: $rosteringLeaveEntitlementId
    startDatetime: $startDatetime
  ) {
    shiftAmountTaken
    daysLeaveRemaining
    missedShifts {
      ...MissedShifts
    }
    errors {
      ...Error
    }
  }
}
    ${MissedShiftsFragmentDoc}
${ErrorFragmentDoc}`;

/**
 * __useRosteringLeaveRequestCalculationQuery__
 *
 * To run a query within a React component, call `useRosteringLeaveRequestCalculationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRosteringLeaveRequestCalculationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRosteringLeaveRequestCalculationQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      organisationId: // value for 'organisationId'
 *      endDatetime: // value for 'endDatetime'
 *      rosteringLeaveEntitlementId: // value for 'rosteringLeaveEntitlementId'
 *      startDatetime: // value for 'startDatetime'
 *   },
 * });
 */
export function useRosteringLeaveRequestCalculationQuery(baseOptions: Apollo.QueryHookOptions<RosteringLeaveRequestCalculationQuery, RosteringLeaveRequestCalculationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RosteringLeaveRequestCalculationQuery, RosteringLeaveRequestCalculationQueryVariables>(RosteringLeaveRequestCalculationDocument, options);
      }
export function useRosteringLeaveRequestCalculationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RosteringLeaveRequestCalculationQuery, RosteringLeaveRequestCalculationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RosteringLeaveRequestCalculationQuery, RosteringLeaveRequestCalculationQueryVariables>(RosteringLeaveRequestCalculationDocument, options);
        }
export type RosteringLeaveRequestCalculationQueryHookResult = ReturnType<typeof useRosteringLeaveRequestCalculationQuery>;
export type RosteringLeaveRequestCalculationLazyQueryHookResult = ReturnType<typeof useRosteringLeaveRequestCalculationLazyQuery>;
export type RosteringLeaveRequestCalculationQueryResult = Apollo.QueryResult<RosteringLeaveRequestCalculationQuery, RosteringLeaveRequestCalculationQueryVariables>;
export const RosteringLeaveRequestDocument = gql`
    query RosteringLeaveRequest($rosteringLeaveRequestId: Int!, $organisationId: Int!, $rosteringOrganisationRegistrationId: Int!) {
  rosteringLeaveRequest(
    id: $rosteringLeaveRequestId
    organisationId: $organisationId
    rosteringOrganisationRegistrationId: $rosteringOrganisationRegistrationId
  ) {
    id
    startDate
    endDate
    createdAt
    status
    reasonForCancellation
    total
    reasonForLeave
    measurementType
    rosteringLeaveType {
      id
      leaveType
    }
    rosteringLeaveRequestApprovals {
      id
      status
      reasonForDecision
      approver {
        id
        fullName
        email
      }
    }
    missedShifts {
      ...MissedShifts
    }
    rosteringLeaveReason {
      id
      name
    }
    cancellableRequest
  }
}
    ${MissedShiftsFragmentDoc}`;

/**
 * __useRosteringLeaveRequestQuery__
 *
 * To run a query within a React component, call `useRosteringLeaveRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useRosteringLeaveRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRosteringLeaveRequestQuery({
 *   variables: {
 *      rosteringLeaveRequestId: // value for 'rosteringLeaveRequestId'
 *      organisationId: // value for 'organisationId'
 *      rosteringOrganisationRegistrationId: // value for 'rosteringOrganisationRegistrationId'
 *   },
 * });
 */
export function useRosteringLeaveRequestQuery(baseOptions: Apollo.QueryHookOptions<RosteringLeaveRequestQuery, RosteringLeaveRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RosteringLeaveRequestQuery, RosteringLeaveRequestQueryVariables>(RosteringLeaveRequestDocument, options);
      }
export function useRosteringLeaveRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RosteringLeaveRequestQuery, RosteringLeaveRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RosteringLeaveRequestQuery, RosteringLeaveRequestQueryVariables>(RosteringLeaveRequestDocument, options);
        }
export type RosteringLeaveRequestQueryHookResult = ReturnType<typeof useRosteringLeaveRequestQuery>;
export type RosteringLeaveRequestLazyQueryHookResult = ReturnType<typeof useRosteringLeaveRequestLazyQuery>;
export type RosteringLeaveRequestQueryResult = Apollo.QueryResult<RosteringLeaveRequestQuery, RosteringLeaveRequestQueryVariables>;
export const RosteringLeaveRequestCreateDocument = gql`
    mutation RosteringLeaveRequestCreate($organisationId: Int!, $accountId: Int!, $endDatetime: DateTime!, $rosteringLeaveEntitlementId: Int!, $startDatetime: DateTime!, $reasonForLeave: String, $rosteringLeaveReasonId: Int) {
  rosteringLeaveRequestCreate(
    organisationId: $organisationId
    accountId: $accountId
    startDatetime: $startDatetime
    endDatetime: $endDatetime
    rosteringLeaveEntitlementId: $rosteringLeaveEntitlementId
    reasonForLeave: $reasonForLeave
    rosteringLeaveReasonId: $rosteringLeaveReasonId
  ) {
    rosteringLeaveRequestType {
      id
      leaveFlowType
      rosteringLeaveEntitlement {
        id
        remainingBookableLeave
        total
        totalApprovedLeave
        totalPendingLeave
      }
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RosteringLeaveRequestCreateMutationFn = Apollo.MutationFunction<RosteringLeaveRequestCreateMutation, RosteringLeaveRequestCreateMutationVariables>;

/**
 * __useRosteringLeaveRequestCreateMutation__
 *
 * To run a mutation, you first call `useRosteringLeaveRequestCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRosteringLeaveRequestCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rosteringLeaveRequestCreateMutation, { data, loading, error }] = useRosteringLeaveRequestCreateMutation({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      accountId: // value for 'accountId'
 *      endDatetime: // value for 'endDatetime'
 *      rosteringLeaveEntitlementId: // value for 'rosteringLeaveEntitlementId'
 *      startDatetime: // value for 'startDatetime'
 *      reasonForLeave: // value for 'reasonForLeave'
 *      rosteringLeaveReasonId: // value for 'rosteringLeaveReasonId'
 *   },
 * });
 */
export function useRosteringLeaveRequestCreateMutation(baseOptions?: Apollo.MutationHookOptions<RosteringLeaveRequestCreateMutation, RosteringLeaveRequestCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RosteringLeaveRequestCreateMutation, RosteringLeaveRequestCreateMutationVariables>(RosteringLeaveRequestCreateDocument, options);
      }
export type RosteringLeaveRequestCreateMutationHookResult = ReturnType<typeof useRosteringLeaveRequestCreateMutation>;
export type RosteringLeaveRequestCreateMutationResult = Apollo.MutationResult<RosteringLeaveRequestCreateMutation>;
export type RosteringLeaveRequestCreateMutationOptions = Apollo.BaseMutationOptions<RosteringLeaveRequestCreateMutation, RosteringLeaveRequestCreateMutationVariables>;
export const RosteringLeaveRequestCancelDocument = gql`
    mutation RosteringLeaveRequestCancel($organisationId: Int!, $id: Int!, $reasonForCancellation: String) {
  rosteringLeaveRequestCancel(
    organisationId: $organisationId
    id: $id
    reasonForCancellation: $reasonForCancellation
  ) {
    rosteringLeaveRequest {
      id
      status
      reasonForCancellation
      rosteringLeaveRequestApprovals {
        id
        status
        reasonForDecision
        approver {
          fullName
          email
        }
      }
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RosteringLeaveRequestCancelMutationFn = Apollo.MutationFunction<RosteringLeaveRequestCancelMutation, RosteringLeaveRequestCancelMutationVariables>;

/**
 * __useRosteringLeaveRequestCancelMutation__
 *
 * To run a mutation, you first call `useRosteringLeaveRequestCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRosteringLeaveRequestCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rosteringLeaveRequestCancelMutation, { data, loading, error }] = useRosteringLeaveRequestCancelMutation({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      id: // value for 'id'
 *      reasonForCancellation: // value for 'reasonForCancellation'
 *   },
 * });
 */
export function useRosteringLeaveRequestCancelMutation(baseOptions?: Apollo.MutationHookOptions<RosteringLeaveRequestCancelMutation, RosteringLeaveRequestCancelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RosteringLeaveRequestCancelMutation, RosteringLeaveRequestCancelMutationVariables>(RosteringLeaveRequestCancelDocument, options);
      }
export type RosteringLeaveRequestCancelMutationHookResult = ReturnType<typeof useRosteringLeaveRequestCancelMutation>;
export type RosteringLeaveRequestCancelMutationResult = Apollo.MutationResult<RosteringLeaveRequestCancelMutation>;
export type RosteringLeaveRequestCancelMutationOptions = Apollo.BaseMutationOptions<RosteringLeaveRequestCancelMutation, RosteringLeaveRequestCancelMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRosteringLeaveEntitlementsQuery((req, res, ctx) => {
 *   const { organisationId, rosteringOrganisationRegistrationId, expired, entitlementLeaveTypeEnum } = req.variables;
 *   return res(
 *     ctx.data({ rosteringLeaveEntitlements })
 *   )
 * })
 */
export const mockRosteringLeaveEntitlementsQuery = (resolver: ResponseResolver<GraphQLRequest<RosteringLeaveEntitlementsQueryVariables>, GraphQLContext<RosteringLeaveEntitlementsQuery>, any>) =>
  graphql.query<RosteringLeaveEntitlementsQuery, RosteringLeaveEntitlementsQueryVariables>(
    'RosteringLeaveEntitlements',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRosteringLeaveReasonsQuery((req, res, ctx) => {
 *   const { organisationId, after, before, first, last, leaveAbsenceCategory, searchTerm, leaveAbsenceType } = req.variables;
 *   return res(
 *     ctx.data({ rosteringLeaveReasons })
 *   )
 * })
 */
export const mockRosteringLeaveReasonsQuery = (resolver: ResponseResolver<GraphQLRequest<RosteringLeaveReasonsQueryVariables>, GraphQLContext<RosteringLeaveReasonsQuery>, any>) =>
  graphql.query<RosteringLeaveReasonsQuery, RosteringLeaveReasonsQueryVariables>(
    'RosteringLeaveReasons',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRosteringLeaveRequestsQuery((req, res, ctx) => {
 *   const { organisationId, rosteringOrganisationRegistrationId, rosteringLeaveTypeId } = req.variables;
 *   return res(
 *     ctx.data({ rosteringLeaveRequests })
 *   )
 * })
 */
export const mockRosteringLeaveRequestsQuery = (resolver: ResponseResolver<GraphQLRequest<RosteringLeaveRequestsQueryVariables>, GraphQLContext<RosteringLeaveRequestsQuery>, any>) =>
  graphql.query<RosteringLeaveRequestsQuery, RosteringLeaveRequestsQueryVariables>(
    'RosteringLeaveRequests',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRosteringLeaveRequestCalculationQuery((req, res, ctx) => {
 *   const { accountId, organisationId, endDatetime, rosteringLeaveEntitlementId, startDatetime } = req.variables;
 *   return res(
 *     ctx.data({ rosteringLeaveRequestCalculation })
 *   )
 * })
 */
export const mockRosteringLeaveRequestCalculationQuery = (resolver: ResponseResolver<GraphQLRequest<RosteringLeaveRequestCalculationQueryVariables>, GraphQLContext<RosteringLeaveRequestCalculationQuery>, any>) =>
  graphql.query<RosteringLeaveRequestCalculationQuery, RosteringLeaveRequestCalculationQueryVariables>(
    'RosteringLeaveRequestCalculation',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRosteringLeaveRequestQuery((req, res, ctx) => {
 *   const { rosteringLeaveRequestId, organisationId, rosteringOrganisationRegistrationId } = req.variables;
 *   return res(
 *     ctx.data({ rosteringLeaveRequest })
 *   )
 * })
 */
export const mockRosteringLeaveRequestQuery = (resolver: ResponseResolver<GraphQLRequest<RosteringLeaveRequestQueryVariables>, GraphQLContext<RosteringLeaveRequestQuery>, any>) =>
  graphql.query<RosteringLeaveRequestQuery, RosteringLeaveRequestQueryVariables>(
    'RosteringLeaveRequest',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRosteringLeaveRequestCreateMutation((req, res, ctx) => {
 *   const { organisationId, accountId, endDatetime, rosteringLeaveEntitlementId, startDatetime, reasonForLeave, rosteringLeaveReasonId } = req.variables;
 *   return res(
 *     ctx.data({ rosteringLeaveRequestCreate })
 *   )
 * })
 */
export const mockRosteringLeaveRequestCreateMutation = (resolver: ResponseResolver<GraphQLRequest<RosteringLeaveRequestCreateMutationVariables>, GraphQLContext<RosteringLeaveRequestCreateMutation>, any>) =>
  graphql.mutation<RosteringLeaveRequestCreateMutation, RosteringLeaveRequestCreateMutationVariables>(
    'RosteringLeaveRequestCreate',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRosteringLeaveRequestCancelMutation((req, res, ctx) => {
 *   const { organisationId, id, reasonForCancellation } = req.variables;
 *   return res(
 *     ctx.data({ rosteringLeaveRequestCancel })
 *   )
 * })
 */
export const mockRosteringLeaveRequestCancelMutation = (resolver: ResponseResolver<GraphQLRequest<RosteringLeaveRequestCancelMutationVariables>, GraphQLContext<RosteringLeaveRequestCancelMutation>, any>) =>
  graphql.mutation<RosteringLeaveRequestCancelMutation, RosteringLeaveRequestCancelMutationVariables>(
    'RosteringLeaveRequestCancel',
    resolver
  )
