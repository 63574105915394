import { FormEvent, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import toast from "react-hot-toast";

import { ResetPassword } from "components/Auth";
import LayoutAuth from "components/Layout/LayoutAuth";
import { Wrapper } from "components/Style";
import { ErrorFragment } from "gql/new-gql/__generated__/fragments.generated";
import { useResetPasswordMutation } from "gql/new-gql/__generated__/mutations.generated";
import history from "helpers/functions";

const ResetPasswordPage = () => {
  // State ******************************************************

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [resetPasswordErrors, setResetPasswordDataErrors] = useState<
    ErrorFragment[]
  >([]);

  // Graphql **************************************************

  const [resetPassword, { loading }] = useResetPasswordMutation();

  // Onload **************************************************

  useEffect(() => {
    // Pull out token from url
    const index = window.location.href.indexOf("=");
    setToken(window.location.href.slice(index + 1));
  }, []);

  // Functions **********************************************

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const toastId = toast.loading("Loading...");

    try {
      const { data } = await resetPassword({
        variables: {
          password: password,
          passwordConfirmation: confirmPassword,
          resetPasswordToken: token,
        },
      });

      if (data?.workerResetPassword?.errors.length) {
        setResetPasswordDataErrors(data.workerResetPassword?.errors);

        toast.error("Password reset Failed!", { id: toastId });
        return;
      }

      if (data?.workerResetPassword?.success) {
        toast.success("Password reset Successful!", { id: toastId });
        setTimeout(() => {
          history.push("/");
        }, 4000);
      }
    } catch (err) {
      toast.error("There was an error! ", { id: toastId });
    }
  };

  return (
    <LayoutAuth title="Reset">
      <Wrapper>
        <Container>
          <ResetPassword
            loading={loading}
            onSubmit={onSubmit}
            setPassword={setPassword}
            isPasswordHidden={isPasswordHidden}
            setIsPasswordHidden={setIsPasswordHidden}
            setConfirmPassword={setConfirmPassword}
            resetPasswordErrors={resetPasswordErrors}
          />
        </Container>
      </Wrapper>
    </LayoutAuth>
  );
};

export default ResetPasswordPage;
