export const CheckboxIcon = () => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 0C6.94271 0 0 6.94271 0 15.5C0 24.0573 6.94271 31 15.5 31C24.0573 31 31 24.0573 31 15.5C31 6.94271 24.0573 0 15.5 0V0Z"
      fill="#57A886"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5471 12.5359L14.7971 20.2859C14.5452 20.5378 14.2158 20.667 13.8864 20.667C13.5571 20.667 13.2277 20.5378 12.9758 20.2859L8.45498 15.7651C7.94477 15.2614 7.94477 14.4476 8.45498 13.9439C8.95873 13.4336 9.77248 13.4336 10.2762 13.9439L13.8864 17.5476L20.7258 10.7147C21.2296 10.2045 22.0433 10.2045 22.5471 10.7147C23.0573 11.2184 23.0573 12.0322 22.5471 12.5359Z"
      fill="white"
    />
  </svg>
);

export const TimeSheetRightIcon = () => (
  <svg
    width="8"
    height="15"
    viewBox="0 0 8 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.292893 0.792893C0.683417 0.402369 1.31658 0.402369 1.70711 0.792893L7.70711 6.79289C8.09763 7.18342 8.09763 7.81658 7.70711 8.20711L1.70711 14.2071C1.31658 14.5976 0.683417 14.5976 0.292893 14.2071C-0.0976311 13.8166 -0.0976311 13.1834 0.292893 12.7929L5.58579 7.5L0.292893 2.20711C-0.0976311 1.81658 -0.0976311 1.18342 0.292893 0.792893Z"
      fill="#7D8793"
    />
  </svg>
);

export const TimeSheetLeftIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7071 5.79289C15.3166 5.40237 14.6834 5.40237 14.2929 5.79289L8.29289 11.7929C7.90237 12.1834 7.90237 12.8166 8.29289 13.2071L14.2929 19.2071C14.6834 19.5976 15.3166 19.5976 15.7071 19.2071C16.0976 18.8166 16.0976 18.1834 15.7071 17.7929L10.4142 12.5L15.7071 7.20711C16.0976 6.81658 16.0976 6.18342 15.7071 5.79289Z"
      fill="#7C7D80"
    />
  </svg>
);
