import { useMemo, useState } from "react";

import { RosteringTeamRotaQuery } from "components/TeamRota/__generated__/TeamRota.generated";
import { TeamRotaTableFiltersType } from "components/TeamRota/TeamRotaTable/TeamRotaTableFilters.types";

export const useTeamRotaFilters = (
  teamRotaData: RosteringTeamRotaQuery | undefined,
  eventIdsForActivities: (activityIds: string[]) => number[]
) => {
  const [filters, setFilters] = useState<TeamRotaTableFiltersType>({
    isLeaveOnly: false,
    worker: null,
    activity: null,
    shiftType: null,
  });
  const filteredTeamRotaData = useMemo(():
    | RosteringTeamRotaQuery["rosteringTeamRota"]
    | undefined => {
    let rosteringTeamRotaCopy = structuredClone(
      teamRotaData?.rosteringTeamRota
    );

    if (filters.isLeaveOnly) {
      rosteringTeamRotaCopy = rosteringTeamRotaCopy?.map((teamRota) => {
        return {
          ...teamRota,
          rosteringOrganisationRegistrations:
            teamRota.rosteringOrganisationRegistrations.map((rosterOrgReg) => ({
              ...rosterOrgReg,
              eventsOnRoster: [],
            })),
        };
      });
    }

    if (filters.worker) {
      rosteringTeamRotaCopy = rosteringTeamRotaCopy?.map((teamRota) => ({
        ...teamRota,
        rosteringOrganisationRegistrations:
          teamRota.rosteringOrganisationRegistrations.filter(
            (rosterOrgReg) => rosterOrgReg.id === filters.worker?.id
          ),
      }));
    }

    if (filters.activity) {
      const eventIds = eventIdsForActivities([filters.activity.id]);
      rosteringTeamRotaCopy = rosteringTeamRotaCopy?.map((teamRota) => ({
        ...teamRota,
        rosteringOrganisationRegistrations:
          teamRota.rosteringOrganisationRegistrations.map((rosterOrgReg) => ({
            ...rosterOrgReg,
            eventsOnRoster: rosterOrgReg.eventsOnRoster.filter((event) =>
              eventIds.length > 0
                ? eventIds.includes(+event.id)
                : event.scheduleable?.organisationActivity?.id.toString() ===
                  filters.activity?.id
            ),
          })),
      }));
    }

    if (filters.shiftType) {
      rosteringTeamRotaCopy = rosteringTeamRotaCopy?.map((teamRota) => ({
        ...teamRota,
        rosteringOrganisationRegistrations:
          teamRota.rosteringOrganisationRegistrations.map((rosterOrgReg) => ({
            ...rosterOrgReg,
            eventsOnRoster: rosterOrgReg.eventsOnRoster.filter(
              (event) =>
                event.scheduleable?.shiftType?.id === filters.shiftType?.id
            ),
          })),
      }));
    }
    return rosteringTeamRotaCopy;
  }, [filters, teamRotaData]);

  return { filters, setFilters, filteredTeamRotaData };
};
