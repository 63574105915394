import { ChangeEvent, useState } from "react";
import styled from "styled-components";

import {
  FormInput,
  GREY,
  ModalHeader,
  ModalTitle,
} from "@patchworkhealth/web-components";

import { CloseModal } from "icons/referencesIcons";

import { Divider, StyledButton } from "../../../Style";

interface Props {
  onHide: () => void;
  onBack: () => void;
  handleRecordSubmit: (reasonForCancellation: string) => void;
  isApproved: boolean;
}
export const CancelLeaveRequestModalContent = ({
  onBack,
  onHide,
  handleRecordSubmit,
  isApproved,
}: Props) => {
  const [reasonForCancelling, setReasonForCancelling] = useState("");

  return (
    <>
      <ModalHeader>
        <ModalTitle>Cancel Leave Request</ModalTitle>
        <button className="btn btn-cyan" onClick={onHide}>
          <CloseModal />
        </button>
      </ModalHeader>
      <ModalBody>
        <p style={{ marginBottom: 20 }}>
          <span style={{ fontWeight: 600 }}>
            Are you sure you want to cancel your leave request?
          </span>{" "}
          {isApproved && (
            <>
              <br />
              <span>
                Your supervisor will need to approve your leave cancellation.
              </span>
            </>
          )}
        </p>

        {isApproved && (
          <>
            <p style={{ marginBottom: 8 }}>Reason for cancelling</p>
            <FormInput
              as="textarea"
              name="cancellationNote"
              style={{ maxWidth: "584px", minHeight: "160px" }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setReasonForCancelling(e.target.value);
              }}
              value={reasonForCancelling}
            />
          </>
        )}
      </ModalBody>
      <div style={{ margin: "0px 35px 0px 32px" }}>
        <Divider line="solid" top="12" bottom="32" />
      </div>
      <ModalFooter>
        <StyledButton
          onClick={onBack}
          color="white"
          size="small"
          length="100px"
        >
          Back
        </StyledButton>
        <StyledButton
          color="red"
          length="202px"
          onClick={() => handleRecordSubmit(reasonForCancelling)}
        >
          Yes, cancel request
        </StyledButton>
      </ModalFooter>
    </>
  );
};

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 27px 32px;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  p {
    color: ${GREY.ten};
    font-size: 14px;
    font-weight: 400;
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 25px 38px 0;

  button {
    margin-right: 8px;
  }

  @media (max-width: 468px) {
    flex-direction: column;
    text-align: center;
    button {
      font-size: 12px;
      margin-bottom: 10px;
      width: 100%;
    }
  }
`;
