import { useState } from "react";

import { DefaultButton } from "@patchworkhealth/web-components";

import { ShiftCard } from "components/ShiftsNew/ShiftCard";
import { ShiftsModal } from "components/ShiftsNew/ShiftsModal";

import { Loading } from "../Style";
import { TimesheetCardFragment } from "./__generated__/Timesheets.generated";

/* Types --------------------------------------------- */

type TimesheetProps = {
  shifts: {
    department: string;
    shifts: TimesheetCardFragment[];
  }[];
  loadingShifts: boolean;
  openModal: (shifts: TimesheetCardFragment[]) => void;
};

type InitInputs = {
  openModal: boolean;
  selectedShift: TimesheetCardFragment | null;
};

const initInputs: InitInputs = {
  openModal: false,
  selectedShift: null,
};

const TimesheetAttention = ({
  shifts,
  loadingShifts,
  openModal,
}: TimesheetProps) => {
  /* State ---------------------------------------------- */

  const [inputs, setInputs] = useState(initInputs);

  /* Functions ---------------------------------------------- */

  const toggleModal = (e: TimesheetCardFragment | null = null) => {
    setInputs({
      ...inputs,
      openModal: !inputs.openModal,
      selectedShift: e || null,
    });
  };

  const renderTitleContent = (row: TimesheetProps["shifts"][number]) => {
    const { department, shifts } = row;
    return (
      <>
        <h2 className="text-lg font-bold leading-6 text-gray-800">
          {department}
          {row.shifts[0].shiftType === "ONCALL" && " - On-Call"} (
          {shifts.length})
        </h2>
        <h4 className="mb-4 text-sm text-gray-800">
          {shifts[0].organisation?.name}
        </h4>

        {shifts[0].shiftType !== "ONCALL" && shifts.length > 1 && (
          <DefaultButton
            color="blue"
            className="relative w-full mb-4 sm:w-auto sm:absolute sm:right-6 sm:top-6"
            text={
              shifts.length === 1
                ? "Sign-off Timesheet"
                : "Sign-off multiple timesheets"
            }
            onClick={() => openModal(shifts)}
          />
        )}
      </>
    );
  };

  /* Render ---------------------------------------------- */

  return (
    <>
      <p className="hidden mb-8 sm:block">
        These timesheets need to be signed-off at the end of your shift to mark
        attendance.
      </p>

      {loadingShifts && <Loading />}
      {!loadingShifts &&
        shifts.map((row, index) => {
          const { shifts } = row;

          const isMod =
            ["Ministry of Defence", "MOD"].includes(
              shifts[0].organisation?.name ?? ""
            ) && shifts[0].shiftType !== "ONCALL";

          return (
            <div
              className="relative w-full px-4 mb-6 bg-white border border-gray-300 rounded-lg shadow-sm sm:px-8 py-7"
              key={index}
            >
              {renderTitleContent(row)}

              <div className="flex flex-wrap mb-10">
                {shifts?.map((shift) => (
                  <div
                    className="flex w-full justify-center sm:p-0 md:w-[360px] md:justify-start"
                    key={shift.id}
                  >
                    <ShiftCard
                      shift={shift}
                      key={shift.id}
                      onClick={toggleModal}
                      isMod={isMod}
                    />
                  </div>
                ))}
              </div>
            </div>
          );
        })}

      {inputs.openModal && (
        <ShiftsModal
          handleSignOff={openModal}
          handleClose={toggleModal}
          openModal={inputs.openModal}
          selectedShift={inputs.selectedShift}
        />
      )}
    </>
  );
};

export default TimesheetAttention;
