import styled from "styled-components";

import { GLOBAL, GREEN, GREY } from "@patchworkhealth/web-components";

import { formatTimeInTZ } from "helpers/momentHelpers";
import {
  AppliedShiftIcon,
  BookedShiftIcon,
  ClockIcon,
  DepartmentIcon,
  LocationIcon,
} from "icons/scheduleIcons";

export const RosteringScheduleBank = ({
  shift,
  size,
  setModalData,
  handleShow,
  checkbox,
}) => {
  const openModal = () => {
    setModalData(shift);
    handleShow();
  };

  return (
    <BankSchedule onClick={openModal} size={size} checkbox={checkbox}>
      <p>
        <ClockIcon /> {formatTimeInTZ(shift?.startTime, "H:mma")} -{" "}
        {formatTimeInTZ(shift?.endTime, "H:mma")}
      </p>
      <p>
        <DepartmentIcon /> {shift?.department?.name}
      </p>
      <p>
        <LocationIcon /> {shift?.shiftType}
      </p>

      {shift?.status === "BOOKED" ? <BookedShiftIcon /> : <AppliedShiftIcon />}
    </BankSchedule>
  );
};

const BankSchedule = styled.div.attrs((props) => ({
  size: props.size,
}))`
  background: ${GLOBAL.white};
  border: 1px solid ${GREY.two};
  border-radius: 8px;
  cursor: pointer;
  height: 96px;
  margin-bottom: 20px;
  padding: 15px 17px;
  position: relative;
  width: ${(props) =>
    (props.size === "small" && "294px") ||
    (props.size === "big" && props.checkbox === 2 ? "500px" : "333px")};

  @media (max-width: 768px) {
    width: 100%;
  }

  p {
    align-items: center;
    display: flex;
    font-size: 13px;
    line-height: 24px;
    margin: 0;

    svg {
      margin-right: 10px;
      position: initial;
    }
  }

  svg {
    position: absolute;
    right: 0;
    top: -5px;
  }

  span {
    align-items: center;
    color: ${GREEN.five};
    display: flex;
    font-size: 12px;
    font-weight: 800;
    line-height: 15px;
    position: absolute;
    right: 48px;
    top: 10px;
  }
`;
