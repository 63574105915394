import { useContext, useState } from "react";
import { Dropdown } from "react-bootstrap";
import toast from "react-hot-toast";
import styled from "styled-components";

import { BLUE, GLOBAL, GREY } from "@patchworkhealth/web-components";

import { useReadNotificationMutation } from "components/Notifications/__generated__/Notifications.generated";
import { AppContext } from "context/AppContext";
import { RosteringNotifications } from "context/AppContext.types";
import { parseTimeInTZ } from "helpers/momentHelpers";

import {
  IsReadIcon,
  LeaveAccepted,
  LeaveRejected,
  NotificationIcon,
  RosterIcon,
  ScheduleIcon,
  SickLeave,
} from "./icons/NotificationIcons";
import { NotificationConfirmModal } from "./NotificationConfirmModal";

export type RosteringNotificationType = RosteringNotifications[number];

export const NotificationsDropdown = () => {
  const store = useContext(AppContext);
  const [notification, setNotification] =
    useState<RosteringNotificationType | null>(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const [readNotification] = useReadNotificationMutation();

  const onReadNotification = async (notificationId: number) => {
    try {
      const { data } = await readNotification({
        variables: { notificationIds: notificationId },
      });

      if (data?.readNotifications?.errors.length) {
        toast.error("There was an error with notification!");
      }
    } catch (e) {
      toast.error("An error occurred!");
    }
  };

  return (
    <>
      <Dropdown.Menu className="notifications__dropdown">
        <NotificationsHeader>
          <h4>Notifications</h4>
        </NotificationsHeader>

        <NotificationsBody>
          {store?.notifications?.length === 0 ? (
            <div
              style={{
                height: "100px",
                color: "grey",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No Notifications
            </div>
          ) : (
            store?.notifications.map((notification) => (
              <NotificationItem
                isRead={notification.isRead}
                onClick={async () => {
                  await onReadNotification(+notification.id);
                  setNotification(notification);
                  setShowConfirm(true);
                }}
                key={notification.id}
              >
                {notificationIcon(notification.message)}

                <div
                  style={{
                    width: "250px",
                  }}
                >
                  <h4
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {notification.message}
                  </h4>
                  <p>{parseTimeInTZ(notification.createdAt).fromNow()}</p>
                </div>

                <IsReadWrapper>
                  {!notification.isRead && <IsReadIcon />}
                </IsReadWrapper>
              </NotificationItem>
            ))
          )}
        </NotificationsBody>
      </Dropdown.Menu>

      <NotificationConfirmModal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        notification={notification}
        acceptNotification={store.acceptNotificationFunc}
      />
    </>
  );
};
const notificationIcon = (notificationMessage: string | null | undefined) => {
  if (notificationMessage?.includes("Your Schedule has been changed on"))
    return <ScheduleIcon />;
  if (notificationMessage?.includes("accepted")) return <LeaveAccepted />;
  if (notificationMessage?.includes("rejected")) return <LeaveRejected />;
  if (notificationMessage?.includes("sick")) return <SickLeave />;
  if (notificationMessage?.includes("roster")) return <RosterIcon />;
  return <NotificationIcon />;
};

const NotificationsHeader = styled.div`
  display: flex;
  height: 57px;
  justify-content: space-between;
  padding: 25px 25px 11px;
  h4 {
    color: ${GREY.eight};
    font-size: 14px;
    font-weight: bold;
  }
`;

const NotificationsBody = styled.div`
  height: 500px;
  overflow-y: auto;
  button {
    color: ${GREY.five};
    display: block;
    font-size: 13px;
    line-height: 140%;
    margin: 20px auto;
    text-decoration: underline;
    width: 65px;
  }
`;

const NotificationItem = styled.div<{ isRead: boolean | null | undefined }>`
  background: ${GLOBAL.white};
  cursor: pointer;
  height: 68px;
  margin-bottom: 2px;
  opacity: ${({ isRead }) => (isRead ? 0.5 : 1)};
  padding: 16px 24px 16px 69px;
  position: relative;
  &:hover {
    background: ${BLUE.one};
  }
  svg:nth-child(1) {
    left: 24px;
    position: absolute;
    top: 12px;
  }
  h4 {
    color: ${GREY.ten} !important;
    font-size: 13px;
    margin-bottom: 5px;
  }
  p {
    color: ${GREY.four};
    font-size: 9px;
    font-weight: bold;
    letter-spacing: 0.025em;
    text-transform: uppercase;
  }
`;

const IsReadWrapper = styled.div`
  height: 20px;
  position: absolute;
  right: 30px;
  top: 15px;
  width: 20px;
`;
