import { gql } from "@apollo/client";

import { WorkerShiftMinimalFragment } from "gql/fragments";

// Hydration Query ***********************************************************

export const BOOKED_SHIFTS = gql`
  query bookedShifts {
    bookedShifts: workersShifts(status: BOOKED) {
      ...WorkerShiftMinimalFragment
    }

    appliedShifts: workersShifts(status: APPLIED) {
      ...WorkerShiftMinimalFragment
    }
  }

  ${WorkerShiftMinimalFragment}
`;

export const ROSTERING_EVENTS_QUERY_OLD = gql`
  query rosteringQueryOld(
    $startsAtFrom: Date
    $organisationActivityIds: [Int!]
    $eventTypes: [RosteringEventTypeEnum!]
  ) {
    rosteringEvents(
      startsAtFrom: $startsAtFrom
      organisationActivityIds: $organisationActivityIds
      eventTypes: $eventTypes
    ) {
      nodes {
        id
        eventType
        startTime: startsAt
        endTime: endsAt
        scheduleable {
          ... on RosteringShift {
            id
            sites {
              id
              name
            }
            organisationActivity {
              id
              name

              metaActivity {
                id
                name
              }
            }
            shiftType {
              id
              name
            }
          }
        }
      }
    }
  }
`;

// Big shift Card *******************************

export const GET_BIG_SHIFT_CARD = gql`
  query bigShiftCardQuery($shiftId: Int!) {
    rosterWorkersInShift(shiftId: $shiftId) {
      id
      grade {
        id
        title
        colour
      }
      eventWithPublicUserInfo {
        id
        startsAt
        endsAt
        userId
        firstName
        lastName
        profilePictureUrl
        phoneNumber
      }
      shiftType {
        name
      }
      organisationActivity {
        name
      }
      startHour
      startMinute
      endHour
      endMinute
    }
  }
`;

// User ******************
export const GET_ACCCOUNT_TYPE = gql`
  query currentAccount {
    currentAccount {
      productTypes
    }
  }
`;

export const REFRESH_DOCUMENTS = gql`
  query RefreshDocs {
    currentWorker {
      rightToWork {
        id
        title
        imageUrl
      }

      libraryItems {
        createdAt
        fileName
        url
      }
    }
  }
`;

// Shifts.js *****************************************************************

export const GET_SHIFTS = gql`
  query ShiftsQuery(
    $items: Int
    $fromStartTime: Date
    $toStartTime: Date
    $offset: Int
    $page: Int
    $filterGradeIds: [Int!]
    $organisationIds: [Int!]
    $siteIds: [Int!]
    $shiftType: ShiftTypeEnum
    $specialityIds: [Int!]
    $status: ShiftStatus
  ) {
    shifts: workersShifts(
      fromStartTime: $fromStartTime
      toStartTime: $toStartTime
      items: $items
      offset: $offset
      page: $page
      filterGradeIds: $filterGradeIds
      organisationIds: $organisationIds
      siteIds: $siteIds
      shiftType: $shiftType
      specialityIds: $specialityIds
      status: $status
    ) {
      ...WorkerShiftMinimalFragment
    }
  }
  ${WorkerShiftMinimalFragment}
`;

// Single Shift Detail for Modal ******************************************

export const GET_SINGLE_SHIFT = gql`
  query shiftWorker($id: Int!) {
    workerShift(id: $id) {
      organisation {
        id
      }
    }
  }
`;

// Calendar Summary *****************************************************

export const GET_CALENDAR_SHIFTS = gql`
  query calendarShiftsSummary(
    $fromStartTime: Date
    $toStartTime: Date
    $organisationIds: [Int!]
  ) {
    workersShifts(
      fromStartTime: $fromStartTime
      toStartTime: $toStartTime
      organisationIds: $organisationIds
    ) {
      startTime
      status
    }
  }
`;

// organisations.js ********************************************

export const GET_ORGANISATIONS_QUERY = gql`
  query GetOrganisations($ids: [Int!]) {
    organisations(ids: $ids) {
      description
      id
      logoUrl
      name
      agencyEmail
      tsoName
      tsoPhone
      leadEmployer
      allowsExternalApplicants
      collaborativeBank {
        usesLeadEmployerProcess
        id
        name
        organisations {
          id
          name
        }
      }
      organisationStaffGroups {
        askQuestion
        question
        staffBankAgreementForm
        staffGroup {
          id
          title
        }
      }
      postcode
      organisationType {
        id
        name
      }
    }
  }
`;

// Documents *****************************************************

export const GET_DOCTYPES = gql`
  query fetchDocuments {
    documentTypes {
      id
      title
      mid
      allowedFormats
      documentNumberVisibility
      documentTitleVisibility
      issuerVisibility
      issueDateVisibility
      expirationDateVisibility
      qualificationNameVisibility
      multipleUploadsAllowed
      workerUploads {
        id
        issuer
        documentNumber
        documentTitle
        qualificationName
        issueDate
        expirationDate
        imageUrl

        fileName
      }
      archivedWorkerUploads {
        id
        issuer
        documentNumber
        qualificationName
        issueDate
        expirationDate
        imageUrl

        fileName
      }
    }
  }
`;

// Notifications *******************************************

export const FETCH_NOTIFICATIONS = gql`
  query rosteringNotifications {
    rosteringNotifications {
      nodes {
        createdAt
        id
        isRead
        message
        messageType
        notificationGroupId
        organisation {
          id
          name
        }
        readAt
        recipient {
          id
          fullName
        }

        updatedAt
      }

      totalCount
    }
  }
`;

// Get details from Portal about Agency

export const VMS_WORKER_REGISTRATION = gql`
  query vmsWorkerRegistration($agencyRegistrationId: Int!) {
    vmsWorkerRegistration(agencyRegistrationId: $agencyRegistrationId) {
      nodes {
        agency {
          id
          title
          description
        }
        companyName
        compliancePackUrl
        confirmedAt
        employmentType
        grade {
          id
          title
        }
        id
        specialities {
          id
          title
        }
        skills {
          id
          name
        }
        invitedAt
        isSoleTrader
        paymentType
        user {
          niNumber
          regBodyNumber
          firstName
          lastName
          preferredName
          filterGrade {
            id
            title
          }
          gender
          dateOfBirth
          email
          phoneNumber
          addressLine1
          addressLine2
          city
          postcode
          staffGroup {
            idValue
            title
          }
          dbsNumber
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const SAIL_GET = gql`
  query GetSailFlag($name: String!) {
    sailGet(name: $name)
  }
`;
