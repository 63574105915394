import { Modal } from "react-bootstrap";
import * as Tooltip from "@radix-ui/react-tooltip";
import styled from "styled-components";

import {
  BLUE,
  GREY,
  SHIFTS,
  SHIFTS_NEW,
} from "@patchworkhealth/web-components";

export const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 672px;
  }
`;

export const WorkerPopover = styled(Tooltip.Content)`
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.15);
  height: 36px;
  padding: 9px;
  width: 88px;
  z-index: 1050;

  p {
    color: ${GREY.ten};
    font-size: 10px !important;
    font-weight: bold;
    position: absolute;
    top: 8px;
  }

  span {
    bottom: 5px;
    font-size: 8px;
    font-weight: bold;
    position: absolute;
  }
`;

export const WorkersOnShift = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  img {
    margin: 0;
  }
`;

export const ModalStrip = styled.div<{ type: string | undefined }>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 140px;
  background: ${({ type }) =>
    (type === "Early" && SHIFTS.early1) ||
    (type === "Late" && SHIFTS.late1) ||
    (type === "Twilight" && SHIFTS.twilight1) ||
    (type === "Day" && BLUE.one) ||
    (type === "Night" && SHIFTS.night1) ||
    (type === "Long Day" && SHIFTS.longDay1) ||
    (type === "Mid" && SHIFTS_NEW.mid.one) ||
    (type === "Night 1" && SHIFTS_NEW.night1.one) ||
    (type === "Night 2" && SHIFTS_NEW.night2.one) ||
    (type === "Non-Resident On Call" && SHIFTS_NEW.oncall.one)};

  .top_strip {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;

    svg {
      height: 50px;
      width: 50px;
    }

    h3 {
      color: ${(props) =>
        (props.type === "Early" && SHIFTS.early3) ||
        (props.type === "Late" && SHIFTS.late3) ||
        (props.type === "Twilight" && SHIFTS.twilight3) ||
        (props.type === "Day" && BLUE.seven) ||
        (props.type === "Night" && SHIFTS.night3) ||
        (props.type === "Long Day" && SHIFTS.longDay3) ||
        (props.type === "Mid" && SHIFTS_NEW.mid.three) ||
        (props.type === "Night 1" && SHIFTS_NEW.night1.three) ||
        (props.type === "Night 2" && SHIFTS_NEW.night2.three) ||
        (props.type === "Non-Resident On Call" && SHIFTS_NEW.oncall.three)};

      font-size: 13px;
      font-weight: 600;
      line-height: 12px;
      text-align: center;
    }
  }

  .bottom_strip {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
    gap: 5px;
    p {
      color: ${GREY.five};
      font-size: 8px;
      margin-top: 14px;
      text-transform: uppercase;
    }
    div {
      display: flex;
      gap: 5px;
      align-items: center;
    }
    svg {
      height: 15px;
      width: 15px;
    }
    span {
      font-size: 9px;
      font-weight: 600;
    }
  }
`;

export const ModalBody = styled.div<{ type: string | undefined }>`
  position: relative;
  border-left: 26px solid
    ${(props) =>
      (props.type === "Early" && SHIFTS.early3) ||
      (props.type === "Late" && SHIFTS.late3) ||
      (props.type === "Twilight" && SHIFTS.twilight3) ||
      (props.type === "Day" && BLUE.seven) ||
      (props.type === "Night" && SHIFTS.night3) ||
      (props.type === "Long Day" && SHIFTS.longDay3) ||
      (props.type === "Mid" && SHIFTS_NEW.mid.three) ||
      (props.type === "Night 1" && SHIFTS_NEW.night1.three) ||
      (props.type === "Night 2" && SHIFTS_NEW.night2.three) ||
      (props.type === "Non-Resident On Call" && SHIFTS_NEW.oncall.three)};

  border-radius: 15px;
  display: flex;
  height: 100%;
`;

export const ActivityPill = styled.div`
  flex-direction: column;
  background: ${GREY.one};
  height: 58px;
  width: 137px;
  border-radius: 14px;
  padding: 4px 10px;

  div {
    font-size: 13px;
  }
`;

export const ModalContent = styled.div`
  padding: 45px 59px;
  height: 550px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  svg {
    height: 20px;
    width: 20px;
  }

  h2 {
    font-size: 13px;
    font-weight: bold;
    line-height: 24px;
  }

  p {
    font-size: 13px;
  }

  button {
    position: absolute;
    right: 20px;
    top: 20px;
  }
`;
