import { ReactNode } from "react";

import {
  AnnualLeaveIcon,
  StudyLeaveIcon,
} from "components/Shared/LeaveRequest/LeaveRequestIcons";

import { LeaveButtonType } from "../RequestLeaveSection.types";

export const requestLeaveIconsDictionary: Record<
  LeaveButtonType,
  { title: string; icon: ReactNode }
> = {
  annual_leave: {
    title: "Annual Leave",
    icon: <AnnualLeaveIcon />,
  },
  study_leave: {
    title: "Study Leave",
    icon: <StudyLeaveIcon />,
  },
};
