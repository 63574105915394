import styled from "styled-components";

import { ProfileImage } from "components/Shared/ProfileImage";

const ProfileHeader = ({
  setIsOpen,
  store,
  removeProfilePicture,
  updateProfilePic,
}) => (
  <FormHeader data-testid="profile_header">
    <ProfileImage
      onClick={setIsOpen}
      src={store.user.profilePictureUrl}
      size="big"
    />

    <label className="custom-file-upload">
      <input type="file" onChange={updateProfilePic} />

      {store?.user?.profilePictureUrl === null ? "Add Image" : "Edit"}
    </label>

    {store?.user?.profilePictureUrl !== null && (
      <span onClick={removeProfilePicture} className="remove__profile__button">
        Remove profile picture
      </span>
    )}
  </FormHeader>
);

export default ProfileHeader;

export const FormHeader = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .remove__profile__button {
    color: #bd2d2e !important;
    cursor: pointer;
    font-size: 13px;
    left: 145px;
    position: absolute;
    text-decoration-line: underline;
    top: 60px;

    @media (max-width: 568px) {
      left: 0;
      top: 0;
      position: relative;
    }
  }
  @media (max-width: 568px) {
    flex-direction: column;
    img {
      margin-bottom: 10px;
    }
    button {
      margin: 15px 0 0 0;
    }
  }
`;
