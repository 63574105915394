import { client } from "apolloClient";

type ObjectWithIdAndTypename = {
  id: string | number;
  __typename: string;
};

export const apolloCacheDeleteItem = <T extends ObjectWithIdAndTypename>(
  item: T
) => {
  const { cache } = client;
  cache.evict({ id: cache.identify(item) });
  cache.gc();
};
