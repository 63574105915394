import { ReactNode } from "react";
import {
  RosteringLeaveRequestStatusEnum,
  RosteringLeaveTypeEnum,
} from "baseCODEGEN";
import styled from "styled-components";

import { GREY } from "@patchworkhealth/web-components";

import {
  ApprovedAnnualLeaveCalendar,
  ApprovedOtherLeaveBackground,
  ApprovedSickLeaveCalendar,
  ApprovedStudyLeaveCalendar,
  PendingAnnualLeaveCalendar,
  PendingStudyLeaveCalendar,
} from "icons/ShiftIcons";

export const LeaveRequestComponent = ({
  leaveType,
  status,
  onClick,
}: {
  leaveType: string | undefined;
  status: string;
  onClick: () => void;
}) => {
  const renderSVG = (svgComponent: ReactNode) => (
    <div className="cursor-pointer" onClick={onClick}>
      {svgComponent}
    </div>
  );

  if (
    status === RosteringLeaveRequestStatusEnum.Approved ||
    status === RosteringLeaveRequestStatusEnum.PendingCancellation
  ) {
    switch (leaveType) {
      case RosteringLeaveTypeEnum.Annual:
        return renderSVG(<ApprovedAnnualLeaveCalendar />);
      case RosteringLeaveTypeEnum.Study:
        return renderSVG(<ApprovedStudyLeaveCalendar />);
      case RosteringLeaveTypeEnum.Sick:
        return renderSVG(<ApprovedSickLeaveCalendar />);
      case RosteringLeaveTypeEnum.Special:
        return renderSVG(
          <CalendarWrapper>
            <ApprovedOtherLeaveBackground />
            <CalendarLeaveText>Special Leave</CalendarLeaveText>
          </CalendarWrapper>
        );
      case RosteringLeaveTypeEnum.Paid:
        return renderSVG(
          <CalendarWrapper>
            <ApprovedOtherLeaveBackground />
            <CalendarLeaveText>Paid Leave</CalendarLeaveText>
          </CalendarWrapper>
        );

      default:
        return null;
    }
  } else if (status === RosteringLeaveRequestStatusEnum.Pending) {
    switch (leaveType) {
      case RosteringLeaveTypeEnum.Annual:
        return renderSVG(<PendingAnnualLeaveCalendar />);
      case RosteringLeaveTypeEnum.Study:
        return renderSVG(<PendingStudyLeaveCalendar />);

      default:
        return null;
    }
  }

  return null;
};

export const CalendarLeaveText = styled.h6`
  align-items: center;
  color: ${GREY.seven};
  display: flex;
  font-size: 10px;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  line-height: 19.6px;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const CalendarWrapper = styled.div`
  position: relative;
  width: 125px;
  height: 51px;
`;
