import { Dispatch } from "react";
import dayjs from "dayjs";

import { FormGroup } from "@patchworkhealth/web-components";

import { DatePicker } from "components/Shared/DatePicker";
import { SingleRosterType } from "components/TeamRota/hooks/useWorkerRoster";
import { TeamRotaNavigation } from "components/TeamRota/TeamRotaHeader/components/TeamRotaNavigation";
import { Button } from "components/UI";

interface Props {
  weekStartDate: Date;
  setWeekStartDate: Dispatch<Date>;
  roster: SingleRosterType | undefined;
}

export const TeamRotaHeader = ({
  weekStartDate,
  setWeekStartDate,
  roster,
}: Props) => {
  const isRosterInPast =
    new Date(roster?.rota.endDate).getTime() < new Date().getTime();

  const isRosterInFuture =
    new Date(roster?.rota.startDate).getTime() > new Date().getTime();

  const setToday = () => {
    setWeekStartDate(new Date());
  };

  return (
    <div className="flex align-items-center gap-4 justify-between border-b-[2px] pb-8">
      <TeamRotaNavigation
        weekStartDate={weekStartDate}
        setWeekStartDate={setWeekStartDate}
        roster={roster}
      />
      <div className="flex align-items-center gap-2">
        {!isRosterInPast && !isRosterInFuture && (
          <Button onClick={setToday}>
            <p className="text-[14px] font-[600] text-blue-7 m-3">Today</p>
          </Button>
        )}
        <FormGroup className="z-20">
          <DatePicker
            datePickerConfig={{
              formatDate: (date) => dayjs(date).format("DD/MM/YYYY"),
              value: weekStartDate,
              parseDate: (value, format) => {
                const parsed = dayjs(value, format).toDate();
                if (dayjs(parsed).isValid()) {
                  return parsed;
                }
              },
              onDayChange: (day) => {
                if (day) setWeekStartDate(day);
              },
              placeholder: "DD/MM/YYYY",
              dayPickerProps: {
                month: weekStartDate,
                disabledDays: {
                  after: new Date(roster?.rota.endDate),
                  before: new Date(roster?.rota.startDate),
                },
                selectedDays: weekStartDate,
              },
            }}
            leftOffset={-85}
          />
        </FormGroup>
      </div>
    </div>
  );
};
