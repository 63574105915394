import { FormEvent, useState } from "react";
import toast from "react-hot-toast";

import {
  isValidPassword,
  NOTIFICATION_MESSAGES,
} from "@patchworkhealth/web-components";

import { SignUp } from "components/Auth";
import LayoutAuth from "components/Layout/LayoutAuth";
import { Wrapper } from "components/Style";
import { useSignUpMutation } from "gql/new-gql/__generated__/mutations.generated";

//TODO: CHECK WITH GREG IS THAT EVENT SOMETHING WE WANT TO HAVE IN A APP
// SING-UP Mutation is deprecated
export default function SignUpPage() {
  // State ***********************************************

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirmPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [registerSuccess, setRegisterSuccess] = useState(false);

  // Graphql & Functions ********************************

  const [signUp, { loading }] = useSignUpMutation();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password !== confirm) {
      toast.error(NOTIFICATION_MESSAGES.PASSWORD.MISMATCH);
      return;
    }

    if (!isValidPassword(password)) {
      toast.error(NOTIFICATION_MESSAGES.PASSWORD.WEAK);
      return;
    }

    if (!termsAccepted) {
      toast.error("Please accept Terms & Conditions");
      return;
    }

    const toastId = toast.loading("Loading...");

    try {
      const { data } = await signUp({
        variables: {
          email: email,
          password: password,
          passwordConfirmation: confirm,
          termsAccepted,
        },
      });

      if (data?.signUp?.errors?.length) {
        toast.error(
          `${data.signUp.errors[0].attribute} ${data.signUp.errors[0].message}`,
          { id: toastId }
        );
        return;
      }

      setRegisterSuccess(true);
      toast.success("Registration Successful!", { id: toastId });
    } catch (_e) {
      toast.error("There was an Error!", { id: toastId });
    }
  };

  return (
    <LayoutAuth title="Signup">
      <Wrapper>
        <SignUp
          loading={loading}
          email={email}
          registerSuccess={registerSuccess}
          onSubmit={onSubmit}
          setEmail={setEmail}
          setPassword={setPassword}
          setConfirmPassword={setConfirmPassword}
          isPasswordHidden={isPasswordHidden}
          setIsPasswordHidden={setIsPasswordHidden}
          setTermsAccepted={setTermsAccepted}
          termsAccepted={termsAccepted}
        />
      </Wrapper>
    </LayoutAuth>
  );
}
