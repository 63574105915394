import styled from "styled-components";

import { GREY, RBGREY } from "@patchworkhealth/web-components";

export const ShiftOuterBox = styled.div`
  padding: 40px;
  background: ${GREY.one};
  border: 1px solid ${GREY.two};
  border-radius: 8px;
  display: flex;
  gap: 30px;
`;

export const HeaderAndFooterWidthContainer = styled.div`
  min-width: 860px;
  display: flex;
`;

export const HeaderContainer = styled(HeaderAndFooterWidthContainer)`
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const FooterContainer = styled(HeaderAndFooterWidthContainer)`
  gap: 10px;
  justify-content: flex-end;
  margin: 12px 0 40px 0;
`;

export const FooterText = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: ${RBGREY.five};
  width: 320px;
  line-height: 18px;
  margin-top: 4px;
`;

export const TimeDateParagraph = styled.p`
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
  color: ${RBGREY.six};
`;
