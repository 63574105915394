import { RosteringLeaveRequestsQuery } from "components/LeaveManagement/__generated__/LeaveManagement.generated";
import { LeaveRecordsFiltersType } from "components/LeaveManagement/LeaveRecordTable/LeaveRecordTable.types";

export const leaveRecordFilters = (
  filter: LeaveRecordsFiltersType,
  leaveRequests: RosteringLeaveRequestsQuery | undefined
): {
  filteredLeaveRequests: RosteringLeaveRequestsQuery["rosteringLeaveRequests"];
} => {
  if (!leaveRequests?.rosteringLeaveRequests)
    return { filteredLeaveRequests: [] };

  let filteredLeaveRequests = leaveRequests.rosteringLeaveRequests;

  if (filter.status && filter.status !== "all") {
    filteredLeaveRequests = filteredLeaveRequests.filter(
      ({ status }) => status === filter.status
    );
  }

  if (filter.startDate !== null) {
    filteredLeaveRequests = filteredLeaveRequests
      ?.slice()
      .sort(
        (a, b) =>
          a?.startDate?.localeCompare(b?.startDate) * (filter.startDate ?? 0)
      );
  }

  return { filteredLeaveRequests };
};
