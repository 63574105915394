import { ApolloClient, from, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import apolloLogger from "apollo-link-logger";
import { createUploadLink } from "apollo-upload-client";
import dayjs from "dayjs";
import jwt_decode, { JwtPayload } from "jwt-decode";

import notify from "components/Notifications";
import { REFRESH_TOKEN_MUTATION } from "gql/Mutations";
import { deleteTokens, getTokens, saveTokens } from "helpers/functions";

const authMiddleware = setContext(() => {
  const tokens = getTokens();

  if (tokens) {
    const { exp: tokenExpiry } = jwt_decode<JwtPayload>(tokens.token);
    const { exp: refreshExpiry } = jwt_decode<JwtPayload>(tokens.refreshToken);

    const hasTokenExpired = dayjs().isAfter(dayjs.unix(tokenExpiry ?? 0));
    const hasRefreshTokenExpired = dayjs().isAfter(
      dayjs.unix(refreshExpiry ?? 0)
    );

    if (hasRefreshTokenExpired) {
      deleteTokens();
      window.location.reload();
      return {};
    }

    if (!hasTokenExpired) {
      return {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "x-authorization": tokens.token,
        },
      };
    }

    if (hasTokenExpired) {
      const temporaryClient = new ApolloClient({
        link: from([apolloLogger, uploadLink]),
        cache: new InMemoryCache(),
      });

      return temporaryClient
        .mutate({
          mutation: REFRESH_TOKEN_MUTATION,
          variables: { refreshToken: tokens.refreshToken },
        })
        .then((res) => {
          if (res.data.refreshToken.__typename === "NonAuthorisedUser") {
            window.alert(
              "Timed out, you will be taken back to the log in page. Please log back in to continue."
            );
            deleteTokens();
            window.location.reload();
            return;
          }

          saveTokens(res.data.refreshToken);

          return {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "x-authorization": res.data.refreshToken.token,
            },
          };
        });
    }
  }

  return {};
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message }) =>
        notify("danger", `[GraphQL Error]: ${message}`)
      );

    if (networkError) {
      notify("danger", `[Network Error]: ${networkError}`);
    }
    return forward(operation);
  }
);

const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: 5000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) => !!error,
  },
});

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_API}/graphql`,
});
const middleWares = [authMiddleware, retryLink, errorLink, uploadLink];

export const client = new ApolloClient({
  link:
    process.env.NODE_ENV === "test"
      ? from([...middleWares])
      : from([apolloLogger, ...middleWares]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all",
      fetchPolicy: "cache-and-network",
    },
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});
