/* eslint-disable react/button-has-type */
import { cva, VariantProps } from "class-variance-authority";

const buttonStyles = cva(
  "flex items-center justify-center border border-1 font-medium rounded-lg h-11 px-4 py-3 text-sm duration-300 transistion-bg",
  {
    variants: {
      variant: {
        blue: "bg-blue-5 hover:bg-blue-6 border-transparent text-white",
        green: "bg-green-5 hover:bg-green-6 text-blue-6 border-transparent",
        black: "bg-black-5 hover:bg-black-4 border-transparent text-white",
        purple: "bg-purple-6 hover:bg-purple-5 border-transparent",
        red: "bg-red-5 hover:bg-red-6 border-transparent text-white",
        outlineRed:
          "border-0 bg-transparent hover:bg-red-5 hover:border-red-5 hover:text-white text-red-5",
        grey: "bg-grey-2 hover:bg-grey-3 border-grey-4 text-black-4",
        lightgreen: "bg-lightgreen-6 hover:bg-lightgreen-5 ",
        pink: "bg-pink-6 hover:bg-pink-5 border-transparent",
        cyan: "bg-cyan-6 hover:bg-cyan-5 border-transparent",
        default:
          "bg-white text-black hover:bg-grey-2 border-grey-2 text-black-4",
      },
      disabled: {
        true: "opacity-60 pointer-events-none",
      },
      fullWidth: {
        true: "w-full",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

type ButtonInitial = {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: "button" | "submit" | "reset";
  className?: string;
  // Todo: Fix this any - https://www.developerway.com/posts/react-component-as-prop-the-right-way#part6
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
};

interface Props extends ButtonInitial, VariantProps<typeof buttonStyles> {}

export const Button = ({
  variant,
  disabled,
  fullWidth,
  children,
  onClick,
  type,
  icon,
  className,
}: Props) => {
  const Icon = icon;

  return (
    <button
      className={buttonStyles({ variant, disabled, fullWidth, className })}
      onClick={onClick}
      type={type ?? "button"}
    >
      <span className="relative">{children}</span>
      {icon && <Icon className="w-5 h-5 mr-2 opacity-40" title={children} />}
    </button>
  );
};
