import { NavDivider } from "components/Style";
import { classNames } from "helpers/newHelpers";

import { NavigationLink } from "./NavigationLink";
import { useNavigationLinks } from "./NavigationLinks";

type MobileNavigationProps = {
  showSidebar: boolean;
};

const MobileNavigation = ({ showSidebar }: MobileNavigationProps) => {
  const navigationLinks = useNavigationLinks();

  return (
    <div
      className={classNames(
        "bg-white border fixed bottom-0 left-0 top-[64px] w-[200px] pt-4 z-50 transition duration-500",
        showSidebar ? "translate-x-0" : "-translate-x-full"
      )}
    >
      {navigationLinks.map((link, index) =>
        link.divider ? (
          <NavDivider key={index} />
        ) : link.divider === false ? null : (
          <NavigationLink
            key={index}
            to={link.to || "/"}
            isActive={link.isActive || false}
            isHidden={link.isHidden}
            icon={link.icon}
            label={link.label}
          />
        )
      )}
    </div>
  );
};

export default MobileNavigation;
