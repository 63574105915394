import { useState } from "react";
import { CloseButton, Modal, ModalTitle } from "react-bootstrap";
import toast from "react-hot-toast";

import {
  DefaultButton,
  FormCheckbox,
  ModalHeader,
} from "@patchworkhealth/web-components";

import { ShiftCard } from "components/ShiftsNew/ShiftCard";
import { formatError } from "helpers/formatError";

import { ModalFooter } from "../Agencies/AgencyModal";
import {
  useWorkerOnCallRatesPreviewQuery,
  useWorkerSignOffRequestedMultipleOnCallMutation,
} from "./__generated__/Timesheets.generated";
import {
  AddTimeFrameIcon,
  BreaksIcon,
  ModalShiftType,
  OnCallInputsType,
} from "./TimesheetHelpers";
import TimesheetsOnCallHours from "./TimesheetsOnCallHours";
import TimesheetsOnCallPreview from "./TimesheetsOnCallPreview";
import TimesheetsOnCallTimeFrames from "./TimesheetsOnCallTimeframes";

const emptyInputs: OnCallInputsType = {
  checkbox: false,
  checkedShifts: [],
  reason: "",
  loading: false,
  previewArray: [],
  previewVariables: {
    shift: {
      endTime: "",
      id: 0,
      notes: "",
      startTime: "",
      timeframes: [],
    },
  },
  skip: true,
  fraudDeclaration: false,
  tab: "shifts",
};

type OnCallModalProps = {
  onHide: () => void;
  modalShifts: ModalShiftType;
  refetchSignoff: () => void;
};

const TimesheetsOnCallModal = ({
  onHide,
  modalShifts,
  refetchSignoff,
}: OnCallModalProps) => {
  /* State --------------------------------------------- */

  const [inputs, setInputs] = useState({
    ...emptyInputs,
    checkedShifts: modalShifts,
  });

  const { tab } = inputs;

  const [signOffOnCallMutation, { loading: signOffOnCallMutationIsLoading }] =
    useWorkerSignOffRequestedMultipleOnCallMutation();

  const usesFraudDeclaration =
    modalShifts[0].enforceFraudDeclarationStatement ?? false;

  /* Fraud Declaration Functions ----------------------------- */

  const handleFraudToggle = () => {
    setInputs({
      ...inputs,
      fraudDeclaration: !inputs.fraudDeclaration,
    });
  };

  const viewFraudDeclaration = () => {
    setInputs({
      ...inputs,
      tab: "fraud",
    });
  };

  /* Preview Rates ---------------------------------------------- */

  useWorkerOnCallRatesPreviewQuery({
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    skip: inputs.skip,
    variables: inputs.previewVariables,
    onCompleted: ({ workerOnCallRatesPreview }) => {
      setInputs({
        ...inputs,
        previewArray: [workerOnCallRatesPreview],
      });
    },
  });

  const handlePreviewRates = (shift: ModalShiftType[0]) => {
    setInputs({
      ...inputs,
      skip: !shift.agencyRegistration === null, // if agencyRegistration is null, skip the query
      previewVariables: {
        shift: {
          endTime: shift.endTime,
          id: shift.id ?? 0,
          notes: shift.reason,
          startTime: shift.startTime,
          timeframes: shift.timeframes.map((timeframe) => ({
            breaks: timeframe.breaks,
            endTime: timeframe.endTime,
            startTime: timeframe.startTime,
          })),
        },
      },
    });
  };

  /* Functions --------------------------------------------------- */

  const resetValues = () => {
    onHide();
    setInputs(emptyInputs);
  };

  /* Add TimeFrame ---------------------------------------------- */

  const addTimeFrame = (shift: ModalShiftType[0]) => {
    const shiftsCopy = [...inputs.checkedShifts];
    const index = shiftsCopy.findIndex((x) => x.id === shift.id);

    const previousTimeframes = shiftsCopy[index]["timeframes"];
    const newStartTime =
      previousTimeframes.length > 0
        ? previousTimeframes[previousTimeframes.length - 1].endTime
        : shiftsCopy[index].startTime; // Set newStartTime as the previous endTime
    const newEndTime = new Date(newStartTime); // Create a new Date object from newStartTime

    // Add 30 minutes to newEndTime
    newEndTime.setMinutes(newEndTime.getMinutes() + 30);

    shiftsCopy[index]["timeframes"].push({
      breaks: 0,
      startTime: newStartTime,
      endTime: newEndTime as unknown as string,
    });

    setInputs({
      ...inputs,
      checkedShifts: shiftsCopy,
    });

    handlePreviewRates(shiftsCopy[index]);
  };

  /* Request sign off Mutation --------------------------------------------- */

  const handleSignoff = () => {
    /*
      We need to check if the shift(s) have been amended,
      if it has we need to check if the reason has been added,
      if it has we can send the request, if not we need to display the reason box
    */

    const variables = inputs.checkedShifts.map((shift) => ({
      id: shift.id ?? 0,
      notes: shift.reason,
      startTime: shift.startTime,
      endTime: shift.endTime,
      timeframes: shift.timeframes,
    }));

    const toastId = toast.loading("Loading...");

    signOffOnCallMutation({
      variables: {
        shifts: variables,
      },
    })
      .then((res) => {
        const errors = res?.data?.workerSignOffRequestedMultipleOnCall?.errors;

        if (errors && errors.length > 0) {
          toast.error(formatError(errors), { id: toastId });
          return;
        }

        toast.success("Shift(s) successfully signed off!", { id: toastId });
        resetValues();
        refetchSignoff();
      })
      .catch(() => {
        toast.error(
          "There was an error signing off this shift(s), please try again!",
          { id: toastId }
        );
        resetValues();
        refetchSignoff();
      });
  };

  return (
    <Modal
      show={true}
      onHide={onHide}
      backdrop="static"
      centered
      size="lg"
      className="timesheets__modal"
    >
      <ModalHeader>
        <ModalTitle>Amend Timesheets</ModalTitle>
        <CloseButton onClick={() => resetValues()} />
      </ModalHeader>

      <div className="px-4 py-4 border-b ">
        {/* Slide Container ------------------------------------------------------------------------ */}

        {tab === "shifts" && (
          <>
            <div className="flex items-center">
              <div className="w-full min-h-[300px] sm:bg-gray-100 sm:border p-2 sm:p-8 rounded-lg sm:mx-12">
                {inputs.checkedShifts.map((shift) => (
                  <>
                    <div
                      className="flex justify-center relative flex-wrap sm:[&>div]:flex-1"
                      key={shift.id}
                    >
                      <ShiftCard
                        shift={shift}
                        onClick={() => null}
                        isMod={false}
                      />

                      {/* Slider Right ----------------------------------------------- */}

                      <TimesheetsOnCallHours
                        shift={shift}
                        inputs={inputs}
                        setInputs={setInputs}
                        handlePreviewRates={handlePreviewRates}
                      />
                    </div>

                    {/* Calculations + Timeframes ----------------------------------- */}

                    <div className="flex flex-wrap mt-4">
                      <TimesheetsOnCallPreview inputs={inputs} />
                      <TimesheetsOnCallTimeFrames
                        shift={shift}
                        inputs={inputs}
                        setInputs={setInputs}
                        handlePreviewRates={handlePreviewRates}
                      />
                    </div>

                    {/* Info Messages ---------------------------------------------- */}

                    <div className="flex justify-center mt-4">
                      <BreaksIcon />
                      <p className="ml-2">
                        Any breaks taken will be deducted from Call Out time and
                        paid at the On Call rate.
                      </p>
                    </div>

                    {shift.workerSignOff && (
                      <p className="font-[600] text-center mt-3">
                        This shift contains hours based on a previous sign off
                        request that was not accepted
                      </p>
                    )}
                  </>
                ))}
              </div>
            </div>

            {/* Buttons Add Section ---------------------------------------------------------------- */}
            <div className="mt-4 ml-16">
              {inputs.checkedShifts.map((shift) => (
                <div key={shift.id} className="flex">
                  {!shift.checkbox && (
                    <div className="flex items-center">
                      <DefaultButton
                        icon={AddTimeFrameIcon}
                        type="white"
                        text="Add new timeframe for Call Out"
                        onClick={() => addTimeFrame(shift)}
                      />
                      <div className="w-3" />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
        {/* Modal Fraud Declaration ---------------------------------------------------- */}

        {tab === "fraud" && (
          <div className="min-h-[410px] p-8">
            <p>{modalShifts[0].organisation.fraudDeclarationStatement ?? ""}</p>
          </div>
        )}
      </div>

      {/* Modal Footer ------------ */}

      <ModalFooter>
        {tab === "shifts" && usesFraudDeclaration && (
          <div className="sm:absolute sm:left-[36%] sm:top-7 sm:mt-0 flex mt-5 items-center text-xs">
            <FormCheckbox
              value={inputs.fraudDeclaration}
              name="selectedAllDepts"
              action={handleFraudToggle}
            />
            <div className="flex sm:mt-[-8px] text-gray-500">
              <p> I have read and accepted the </p>
              <button
                onClick={viewFraudDeclaration}
                className="text-blue-400  font-bold w-[140px]"
              >
                Fraud Declaration
              </button>
            </div>
          </div>
        )}{" "}
        <div className="flex p-4">
          <DefaultButton
            color="white"
            type="white"
            text={tab === "shifts" ? "Cancel" : "Back"}
            onClick={() => {
              tab === "shifts"
                ? resetValues()
                : setInputs({ ...inputs, tab: "shifts" });
            }}
          />

          {tab === "shifts" && (
            <DefaultButton
              color="blue"
              disabled={signOffOnCallMutationIsLoading}
              style={{
                opacity:
                  !usesFraudDeclaration || inputs.fraudDeclaration ? 1 : 0.5,
                pointerEvents:
                  !usesFraudDeclaration || inputs.fraudDeclaration
                    ? "auto"
                    : "none",
              }}
              text={`Request ${inputs.checkedShifts.length} Shift(s)`}
              onClick={() => handleSignoff()}
            />
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default TimesheetsOnCallModal;
