import { ErrorFragment } from "gql/new-gql/__generated__/fragments.generated";

export const formatValidationErrors = (errors: ErrorFragment[]): string[] => {
  return errors.map((error) => {
    if (!error.attribute) return upperCaseFirstLetter(error.message);
    return `${replaceUnderscoreWithSpace(
      upperCaseFirstLetter(error.attribute)
    )} ${error.message}`;
  });
};

export function formatError(errors: ErrorFragment[]): string {
  const { attribute, message } = errors[0];

  if (!attribute) return upperCaseFirstLetter(message);

  return `${replaceUnderscoreWithSpace(
    upperCaseFirstLetter(attribute)
  )} ${message}`;
}

const replaceUnderscoreWithSpace = (string: string) =>
  string.replace(/_/g, " ");

const upperCaseFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);
