import { CSSProperties, MouseEventHandler } from "react";
import dayjs from "dayjs";
import styled from "styled-components";

import { GLOBAL, GREY } from "@patchworkhealth/web-components";

import { checkLeaveType } from "components/LeaveManagement/LeaveManagement.helpers";
import { LockIcon } from "icons/LockIcon";

interface Props {
  startDate: string;
  endDate: string;
  remainingBookableLeave: number;
  total: number;
  totalApprovedLeave: number;
  totalPendingLeave: number;
  onClick: MouseEventHandler<HTMLButtonElement>;
  expired?: boolean;
  measurementType?: string | null | undefined;
}

export const RequestLeaveEntitlement = ({
  startDate,
  endDate,
  remainingBookableLeave,
  total,
  totalApprovedLeave,
  totalPendingLeave,
  measurementType,
  expired,
  onClick,
}: Props) => {
  const entitlementPeriod = `${dayjs(startDate).format("Do MMM")} - ${dayjs(
    endDate
  ).format("Do MMM YYYY")}`;

  const totalEntitlement = `${total} ${checkLeaveType(measurementType)}`;
  const taken = `${totalApprovedLeave} ${checkLeaveType(measurementType)}`;
  const pending = `${totalPendingLeave} ${checkLeaveType(measurementType)}`;
  const leaveRemaining = `${remainingBookableLeave} ${checkLeaveType(
    measurementType
  )}`;

  // const isDays = measurementType === "days";
  // const hoursInADay = 8;
  // const totalEntitlement = `${total} ${checkLeaveType(measurementType)} ${
  //   isDays ? ` (${total * hoursInADay} HOURS)` : ""
  // }`;
  // const taken = `${totalApprovedLeave} ${checkLeaveType(measurementType)} ${
  //   isDays ? ` (${totalApprovedLeave * hoursInADay} HOURS)` : ""
  // }`;
  // const pending = `${totalPendingLeave} ${checkLeaveType(measurementType)} ${
  //   isDays ? ` (${totalPendingLeave * hoursInADay} HOURS)` : ""
  // }`;
  // const leaveRemaining = `${remainingBookableLeave} ${checkLeaveType(
  //   measurementType
  // )} ${
  //   isDays
  //     ? `
  // (${remainingBookableLeave * hoursInADay} HOURS)`
  //     : ""
  // }`;

  return (
    <EntitlementGridContainer expired={expired}>
      <GridCell
        header="Entitlement Period"
        headerValue={entitlementPeriod}
        headerValueStyle={{ fontWeight: 500 }}
      />
      <GridCell header="Total Entitlement" headerValue={totalEntitlement} />
      <GridCell header="Taken" headerValue={taken} />
      <GridCell header="Pending" headerValue={pending} />
      <GridCell
        header="Leave Remaining"
        headerValue={leaveRemaining}
        headerValueStyle={{ fontWeight: 700 }}
      />
      {remainingBookableLeave > 0 && !expired && (
        <RequestButton
          type="button"
          onClick={onClick}
          data-testid="request_leave"
        >
          Request
        </RequestButton>
      )}
      {expired && (
        <LockIconContainer data-testid="expired_icon">
          <LockIcon />
        </LockIconContainer>
      )}
    </EntitlementGridContainer>
  );
};

const GridCell = ({
  header,
  headerValue,
  headerValueStyle,
}: {
  header: string;
  headerValue: string;
  headerValueStyle?: CSSProperties;
}) => {
  return (
    <div>
      <H6>{header}</H6>
      <P style={{ ...headerValueStyle }}>{headerValue}</P>
    </div>
  );
};

const H6 = styled.h6`
  color: ${GREY.six};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 4px;
`;

const P = styled.p`
  margin: 0;
  color: ${GREY.ten};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const EntitlementGridContainer = styled.li<{ expired: boolean | undefined }>`
  display: grid;
  padding: 16px 20px 18px 32px;
  border-radius: 8px;
  background: ${GREY.one};
  grid-template-columns: 3fr 1.5fr 1fr 1fr 1.5fr 1.5fr;
  gap: 20px;
  width: 888px;
  opacity: ${({ expired }) => (expired ? 0.6 : 1)};
`;

const RequestButton = styled.button`
  padding: 11px 32px;
  border-radius: 8px;
  border: 1px solid ${GREY.two};
  background: ${GLOBAL.white};
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05) !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  &:hover {
    background: ${GREY.one};
  }
`;

const LockIconContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  align-items: center;
`;
