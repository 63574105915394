/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";

// import axios from "axios";
import { FormCheckbox } from "@patchworkhealth/web-components";

import { Button } from "components/UI";
import { AppContext } from "context/AppContext";

import { useWorkerSignOffCreateMutation } from "./__generated__/Timesheets.generated";

const TimesheetsCreate = () => {
  /* MuTATION --------------------------------------------- */

  const [workerSignOffCreate] = useWorkerSignOffCreateMutation();

  /* State --------------------------------------------- */

  const store = useContext(AppContext);
  const [shifts, setShifts] = useState([]);
  const [newShift, setNewShift] = useState({
    blockID: "",
    shiftType: "",
    department: "",
    site: "",
    startTime: "",
    endTime: "",
    breaks: "0",
    notes: "", // Added notes
    fraudDeclaration: false, // Changed to boolean for checkbox
  });

  const handleInputChange = (index, e) => {
    const { name, value, type, checked } = e.target;
    const updatedShifts = [...shifts];
    updatedShifts[index] = {
      ...updatedShifts[index],
      [name]: type === "checkbox" ? checked : value, // Handle checkbox
    };
    setShifts(updatedShifts);
  };

  const handleAddShift = () => {
    setShifts([...shifts, newShift]);
    setNewShift({
      blockID: "",
      shiftType: "",
      department: "",
      site: "",
      startTime: "",
      endTime: "",
      breaks: "0",
      notes: "", // Reset notes
      fraudDeclaration: false, // Reset fraudDeclaration
    });
  };

  const handleRemoveShift = (index) => {
    const updatedShifts = [...shifts];
    updatedShifts.splice(index, 1);
    setShifts(updatedShifts);
  };

  const handleSubmit = async () => {
    if (
      shifts.some((shift) => shift.startTime === "" || shift.endTime === "")
    ) {
      toast.error("Please fill in all start/end times");
      return;
    }

    const toastId = toast.loading("Submitting timesheets...");

    // Construct the email content
    const emailContent = `
      <html>
        <body>
          ${shifts
            .map(
              (shift, index) => `
              <div style="border: 1px solid black;
                          padding: 12px;
                          margin-bottom: 19px;
                          border-radius: 12px;"             
              >
                <h3 style="text-decoration: underline;">Shift ${index + 1}</h3>
                <p><strong>Block ID:</strong> ${shift.blockID}</p>
                <p><strong>Shift Type:</strong> ${shift.shiftType}</p>
                <p><strong>Department:</strong> ${shift.department}</p>
                <p><strong>Site:</strong> ${shift.site}</p>
                <p><strong>Start Time:</strong> ${shift.startTime}</p>
                <p><strong>End Time:</strong> ${shift.endTime}</p>
                <p><strong>Breaks:</strong> ${shift.breaks}</p>
                <p><strong>Notes:</strong> ${shift.notes}</p>
                <p><strong>Accepted Fraud Declaration:</strong> ${
                  shift.fraudDeclaration ? "Yes" : "No"
                }</p>
           
              </div>
            `
            )
            .join("")}
        </body>
      </html>
    `;

    try {
      const { data: result } = await workerSignOffCreate({
        variables: {
          user: store.user?.firstName + " " + store.user?.lastName,
          content: emailContent,
          emails: [
            // "jamie.farley@rssglobal.com",
            // "gurjit.bahara@rssglobal.com",
            // "daniel.melrose@patchwork.health",
            // "greg@patchwork.health",
            "timesheetsWoS@litmus-solutions.com",
          ],
        },
      });

      // console.log("result", result);

      const errors = result?.workerSignOffCreate?.errors;

      if (errors) {
        toast.error("THere was an error", {
          id: toastId,
        });
        return;
      }

      toast.success("Timesheets submitted successfully", {
        id: toastId,
      });
    } catch (error) {
      toast.error("There was an error signing off this shift(s)", {
        id: toastId,
      });
    }
  };

  return (
    <div>
      {/* Shift Inputs */}
      {shifts.length === 0 && (
        <div className="h-[400px] flex flex-col justify-center items-center text-[17px] text-grey-5">
          Get started by adding your shifts
          <Button
            className="my-6"
            onClick={handleAddShift}
            variant="default"
            icon={AddIcon}
          >
            Add Shift
          </Button>
        </div>
      )}

      {shifts.length > 0 && (
        <div className="flex justify-between mt-10 mb-6">
          <h2 className="text-[20px] font-bold">Shifts in Timesheets</h2>

          <Button onClick={handleSubmit} variant="blue">
            Submit Timesheets
          </Button>
        </div>
      )}

      {shifts.length > 0 && (
        <p className="mb-4 font-bold ">
          These timesheets are only for additional time submission.
        </p>
      )}
      {shifts.map((shift, index) => (
        <div
          className="bg-white p-6 relative mb-8 shadow rounded-lg max-w-[600px] border text-[12px]"
          key={index}
        >
          <h2 className="font-semibold text-lg mb-4 underline">
            Shift {index + 1}
          </h2>

          {/* Remove Button */}
          <Button
            className="mt-6 absolute top-[-10px] right-2"
            onClick={() => handleRemoveShift(index)} // Pass index to handleRemoveShift
            variant="outlineRed"
          >
            Remove
          </Button>

          <div className="grid gap-y-5 lg:grid-cols-2">
            <div className="flex flex-col mr-4 w-full">
              <FormLabels>Block ID</FormLabels>
              <input
                type="text"
                name="blockID"
                placeholder="eg. 4217"
                className="w-[220px] h-[22px] border rounded-lg font-semibold cursor-pointer p-5 mb-2"
                value={shift.blockID}
                onChange={(e) => handleInputChange(index, e)}
              />
            </div>

            <div className="flex flex-col mr-4">
              <FormLabels>Shift Type</FormLabels>
              <input
                type="text"
                name="shiftType"
                placeholder="Hourly or On Call"
                className="w-[220px] h-[22px] border rounded-lg  cursor-pointer p-5 mb-2"
                value={shift.shiftType}
                onChange={(e) => handleInputChange(index, e)}
              />
            </div>

            <div className="flex flex-col mr-4">
              <FormLabels>Department</FormLabels>
              <input
                type="text"
                name="department"
                placeholder="Shift Department"
                className="w-[220px] h-[22px] border rounded-lg  cursor-pointer p-5 mb-2 "
                value={shift.department}
                onChange={(e) => handleInputChange(index, e)}
              />
            </div>

            <div className="flex flex-col mr-4">
              <FormLabels>Site</FormLabels>
              <input
                type="text"
                name="site"
                placeholder="Shift Site"
                className="w-[220px] h-[22px] border rounded-lg  cursor-pointer p-5 mb-2"
                value={shift.site}
                onChange={(e) => handleInputChange(index, e)}
              />
            </div>

            {/* Start Time Input */}
            <div className="flex flex-col mr-4">
              <FormLabels>Start Date & Time</FormLabels>
              <input
                type="datetime-local"
                name="startTime"
                className="w-[220px] h-[22px] border rounded-lg text-center  cursor-pointer p-5 mb-2"
                value={shift.startTime}
                onChange={(e) => handleInputChange(index, e)}
              />
            </div>

            {/* End Time Input */}
            <div className="flex flex-col mr-4">
              <FormLabels>End Date & Time</FormLabels>
              <input
                type="datetime-local"
                name="endTime"
                className="w-[220px] h-[22px] border rounded-lg text-center cursor-pointer p-5 mb-2"
                value={shift.endTime}
                onChange={(e) => handleInputChange(index, e)}
              />
            </div>

            {/* Breaks Input */}
            <div className="flex flex-col mr-4">
              <FormLabels>Breaks</FormLabels>
              <input
                type="number"
                name="breaks"
                min={0}
                className="w-[220px] h-[22px] border rounded-lg text-center  cursor-pointer p-5 mb-2"
                value={shift.breaks}
                onChange={(e) => handleInputChange(index, e)} // Pass index along with event
              />
              <p className="text-[10px]">
                Break will be deducted as per policy.
              </p>
            </div>

            <div className="flex flex-col mr-4">
              <FormLabels>Notes</FormLabels>
              <input
                type="text"
                placeholder="Enter notes..."
                name="notes"
                className="w-[220px] h-[22px] border rounded-lg text-center  cursor-pointer p-5 mb-2"
                value={shift.notes}
                onChange={(e) => handleInputChange(index, e)} // Pass index along with event
              />
            </div>
          </div>

          <div className="flex flex-col mr-4 mt-8 border-t pt-4">
            <FormLabels className="underline mb-2">
              Fraud Declaration Signed
            </FormLabels>
            <p className="text-[10px] mb-5">
              I declare that the information I have given on this form is
              correct and complete and that I have not claimed elsewhere for the
              hours/shifts detailed on this timesheet. I understand that if I
              knowingly provide false information, this may result in
              disciplinary action and I may be liable for prosecution and civil
              recovery proceedings which will include immediate reimbursement of
              any overpayment to the body that made the payment to me/my company
              for the work completed on this timesheet. I hereby consent to the
              disclosure of information from those form to and by the Trust,
              Health Board, Agency, NHS Protect and any organisation for the
              purpose of verification of any claim and the investigation,
              prevention, detection and prosecution of fraud. I also confirm
              that I have been given a local induction before commencing my
              initial shift.
            </p>

            <FormCheckbox
              label="I have read and accepted the Fraud Declaration"
              name="fraudDeclaration"
              value={shift.fraudDeclaration}
              action={(e) => handleInputChange(index, e)}
            />
          </div>
        </div>
      ))}

      {shifts.length > 0 && (
        <div className="flex  my-6 mb-10">
          <Button
            onClick={handleAddShift}
            variant="default"
            icon={AddIcon}
            className="mb-20"
          >
            Add another Shift
          </Button>
        </div>
      )}
    </div>
  );
};

export default TimesheetsCreate;

const AddIcon = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="ml-2"
  >
    <g opacity="0.5">
      <path
        d="M10.5 18.3337C15.1023 18.3337 18.8333 14.6027 18.8333 10.0003C18.8333 5.39795 15.1023 1.66699 10.5 1.66699C5.89759 1.66699 2.16663 5.39795 2.16663 10.0003C2.16663 14.6027 5.89759 18.3337 10.5 18.3337Z"
        stroke="#7C7D80"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 6.66699V13.3337"
        stroke="#7C7D80"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.16669 10H13.8334"
        stroke="#7C7D80"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const FormLabels = styled.label`
  font-size: 12px;
  margin: 0px 0px 2px;
  color: black;

  font-weight: 500;
`;
