import { FormInput } from "@patchworkhealth/web-components";

import { SearchIcon } from "components/Organisations/OrganisationsHelpers";
import { RefreshIcon } from "components/ShiftsNew/ShiftIcons";
import { Button } from "components/UI";
import {
  ExtendedOrganisation,
  OrganisationProps,
} from "containers/organisations";
import { classNames } from "helpers/newHelpers";

interface OrgHeaderProps {
  inputs: OrganisationProps;
  setInputs: (inputs: OrganisationProps) => void;
  myOrgs: ExtendedOrganisation[];
  refetch: () => void;
}

const OrganisationsHeader = ({
  inputs,
  setInputs,
  myOrgs,
  refetch,
}: OrgHeaderProps) => {
  return (
    <div className="flex flex-col items-center mt-2 mb-8 md:justify-between md:flex-row">
      {/* Tabs ----------------------------- */}
      <div className="flex mb-4 sm:mb-0">
        <div
          className={classNames(
            "cursor-pointer mr-6 pb-1",
            inputs.tab === "my-orgs" ? "border-b border-blue-900 font-bold" : ""
          )}
          onClick={() => setInputs({ ...inputs, tab: "my-orgs" })}
        >
          My Organisations ({myOrgs.length})
        </div>
        <div
          className={classNames(
            "cursor-pointer pb-1",
            inputs.tab === "all-orgs"
              ? "border-b border-blue-900 font-bold"
              : ""
          )}
          onClick={() => setInputs({ ...inputs, tab: "all-orgs" })}
        >
          All Organisations
        </div>
      </div>

      {/* Buttons ------------------------ */}

      <div className="flex ">
        <Button
          icon={RefreshIcon}
          className="relative mr-4 h-[42px]"
          onClick={refetch}
        >
          Refresh Organisations
        </Button>
        {inputs.tab === "all-orgs" && (
          <div className="w-[240px] relative [&>input]:pl-12">
            <SearchIcon />
            <FormInput
              value={inputs.search}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange={(e) => setInputs({ ...inputs, search: e.target.value })}
              type="text"
              placeholder="Search Organisations"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default OrganisationsHeader;
