import { Redirect, Route, RouteProps } from "react-router-dom";

import { getTokens } from "helpers/functions";

interface Props extends RouteProps {
  isPrivate?: boolean;
}

export const RouteWrapper = ({ component, isPrivate, ...rest }: Props) => {
  const signed = getTokens();

  if ((rest.path === "*" || isPrivate) && !signed) return <Redirect to="/" />;

  /**
   * Redirect user to SignIn page if he tries to access a aprivate route
   * without authentication.
   */
  if (isPrivate && !signed) {
    return <Redirect to="/" />;
  }

  /**
   * Redirect user to Main page if he tries to access a non private route
   * (SignIn or SignUp) after being authenticated.
   */
  if (!isPrivate && signed) {
    return <Redirect to="/shifts" />;
  }

  /**
   * If not included on both previous cases, redirect user to the desired route.
   */
  return <Route {...rest} component={component} />;
};
