import { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { GLOBAL, GREY, Loading, RED } from "@patchworkhealth/web-components";

import { SidebarToggleIcon } from "components/Navigation/NavIcons";
import { ProfileImage } from "components/Shared/ProfileImage";
import { AppContext } from "context/AppContext";
import { beamer } from "helpers/beamer";
import { deleteTokens } from "helpers/functions";
import {
  HeaderIcon,
  HeaderProfileIcon,
  HeaderSettingsIcon,
  LogoutIcon,
  NotificationsIcon,
} from "icons/headerIcons";

import { NotificationsDropdown } from "../Notifications/NotificationsDropdown";

interface Props {
  headerValueText: string;
  headerLoading?: boolean;
  setShowSidebar: (value: boolean) => void;
  showSidebar?: boolean;
}

const Header = ({
  headerValueText,
  headerLoading = false,
  setShowSidebar,
  showSidebar,
}: Props) => {
  const { productType, mobileVersion, unreadNotificationLength, user } =
    useContext(AppContext);

  const logOut = () => {
    deleteTokens();
    localStorage.removeItem("allow_mobile_token");
    window.location.reload();
    beamer.destroy();
  };

  return (
    <HeaderContainer>
      {/* Left Side *****************************/}
      <div className="hidden lg:flex min-w-[200px] items-center justify-center relative p-2">
        <Link to="/shifts" className="flex items-center h-full overflow-hidden">
          <HeaderIcon />
        </Link>

        <span className="absolute left-1 bottom-0 text-[8px]">
          v{productType}
        </span>

        {/* Display todays date */}
        <span className="absolute right-2 bottom-1 text-[6px] text-grey-5">
          {new Date().toLocaleDateString()}
        </span>
      </div>

      {/* Right Side *****************************/}

      <HeaderBodyContainer>
        <div className="flex items-center">
          <SidebarToggleIcon
            sidebar={showSidebar || false}
            setShowSidebar={setShowSidebar}
          />

          <div>
            <HeaderTitle data-testid="test__header__text">
              {headerLoading ? "Loading..." : headerValueText}
            </HeaderTitle>
          </div>
        </div>

        {headerLoading && (
          <HeaderLoadingContainer>
            <Loading />
          </HeaderLoadingContainer>
        )}

        <HeaderBodyRight>
          {!mobileVersion && !!productType && (
            <>
              <Dropdown className="notification__icon">
                <Dropdown.Toggle id="dropdown-autoclose-outside">
                  <NotificationsIcon />

                  {unreadNotificationLength && (
                    <NotificationCountContainer>
                      {unreadNotificationLength}
                    </NotificationCountContainer>
                  )}
                </Dropdown.Toggle>

                <NotificationsDropdown />
              </Dropdown>
            </>
          )}

          <HeaderUser>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-toggle">
                <ProfileImage size="small" src={user?.profilePictureUrl} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Link to="/profile">
                  <HeaderProfileIcon /> My Profile
                </Link>
                <Link to="/documents">
                  <HeaderSettingsIcon />
                  Documents
                </Link>
                <hr style={{ marginTop: "5px" }} />
                <LogoutButton onClick={logOut}>
                  <LogoutIcon />
                  <p>Sign Out</p>
                </LogoutButton>
              </Dropdown.Menu>
            </Dropdown>
          </HeaderUser>
        </HeaderBodyRight>
      </HeaderBodyContainer>
    </HeaderContainer>
  );
};

export default Header;

const HeaderLoadingContainer = styled.div`
  align-items: center;
  display: flex;
  height: 64px;
  left: 160px;
  position: absolute;
`;

const NotificationCountContainer = styled.div`
  align-items: center;

  background: ${RED.five};
  border-radius: 50%;
  color: ${GLOBAL.white};
  display: flex;

  font-size: 9px;
  font-weight: bold;

  height: 18px;
  justify-content: center;

  position: absolute;
  right: 0;
  top: 4px;
  width: 18px;
`;

const HeaderBodyRight = styled.div`
  display: flex;

  .notifications__dropdown {
    padding: 0 !important;
    width: 382px !important;
  }
`;

const LogoutButton = styled.div`
  align-items: center;
  border-radius: 8px;
  display: flex;
  padding: 10px 0;
  width: 132px;

  p {
    color: ${RED.four};
    font-weight: 600;
    margin-left: 12px;
  }

  svg {
    margin: 0 !important;
  }
`;

const HeaderContainer = styled.div`
  background: ${GLOBAL.white};
  border: 1px solid #d4dbe4;
  color: ${GREY.nine};
  display: flex;
  height: 64px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 50;

  .notification__icon {
    display: flex;
    justify-content: center;
    margin-right: 0;
    width: 45px;

    #dropdown-autoclose-outside {
      background: white;
      border: 0 solid white;
      display: flex;
      padding: 0;

      &:focus,
      &:active {
        box-shadow: none !important;
        outline: none !important;
      }
    }
  }

  .product__type__test {
    bottom: 0;
    font-size: 9px;
    left: 2px;
    opacity: 0.5;
    position: absolute;
  }

  /* Dropdown Menu ************************/

  .dropdown-menu {
    background: ${GLOBAL.white};
    border: 1px solid #cbd2d9;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    min-width: 220px;
    padding: 22px 27px 12px;
    a {
      align-items: center;
      color: ${GREY.eight};

      display: flex;
      font-size: 13px;
      font-weight: 600;
      height: 32px;

      margin-bottom: 0;
      margin-left: 32px;
      margin-top: 10px;
      position: relative;

      svg {
        left: -30px;
        position: absolute;
        top: 15px;
        width: 20px;
      }
    }
  }

  #dropdown-toggle {
    background: transparent;
    border: 0;
    font-size: 18px;
    img {
      cursor: pointer;
    }

    &:focus,
    &:active {
      box-shadow: none !important;
      outline: none !important;
    }
  }
`;

const HeaderUser = styled.div`
  align-items: center;
  display: flex;
  button {
    color: ${GLOBAL.white};
  }

  .btn-primary::after {
    display: none !important;
  }

  svg {
    margin-top: -10px;
  }
`;

const HeaderBodyContainer = styled.div`
  align-items: center;

  display: flex;
  justify-content: space-between;
  margin: 0;

  padding: 20px 30px 20px 24px;
  position: relative;
  width: 100%;

  .nav-link {
    align-items: center;
    color: ${GLOBAL.white};
    display: flex;
    padding: 10px 22px;
    position: relative;
  }

  @media only screen and (max-width: 768px) {
    padding: 10px 15px;
    .nav-link {
      padding: 13px;
    }
  }
`;

const HeaderTitle = styled.div`
  color: ${GREY.nine};
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
`;
