import { Dispatch, FormEventHandler, SetStateAction } from "react";
import { Form } from "react-bootstrap";

import {
  AuthPasswordEye,
  Button,
  FormGroup,
  FormInput,
  FormLabel,
  GLOBAL,
  PasswordCriteriaList,
  RBBLUE,
} from "@patchworkhealth/web-components";

import AuthErrorList from "components/Auth/AuthErrorMessages";
import {
  Divider,
  FormContainer,
  LoginHere,
  LogoWrapper,
} from "components/Style";
import { ErrorFragment } from "gql/new-gql/__generated__/fragments.generated";
import history from "helpers/functions";
import { LogoIcon } from "icons/login";

type Props = {
  loading: boolean;
  onSubmit: FormEventHandler<HTMLFormElement>;
  setPassword: (password: string) => void;
  isPasswordHidden: boolean;
  setIsPasswordHidden: Dispatch<SetStateAction<boolean>>;
  setConfirmPassword: (confirmPassword: string) => void;
  resetPasswordErrors: ErrorFragment[];
};

export const ResetPassword = ({
  loading,
  onSubmit,
  setPassword,
  isPasswordHidden,
  setIsPasswordHidden,
  setConfirmPassword,
  resetPasswordErrors,
}: Props) => {
  return (
    <FormContainer>
      <LogoWrapper>
        <LogoIcon />
      </LogoWrapper>
      <Form onSubmit={onSubmit} autoComplete="off">
        <FormGroup>
          <FormLabel style={{ color: GLOBAL.white }}>New Password</FormLabel>
          <FormInput
            autoFocus
            data-testid="test__reset__password"
            onChange={(e) => setPassword(e.target.value)}
            type={isPasswordHidden ? "password" : "text"}
            name="email"
            required
            placeholder="Enter Password"
            autoComplete="off"
          />

          <AuthPasswordEye onClick={() => setIsPasswordHidden((s) => !s)} />
        </FormGroup>

        <AuthErrorList errors={resetPasswordErrors} />

        <Divider top="10" bottom="10" line="none" />
        <FormGroup>
          <FormLabel style={{ color: GLOBAL.white }}>
            Confirm new Password
          </FormLabel>
          <FormInput
            data-testid="test__reset__confirm"
            onChange={(e) => setConfirmPassword(e.target.value)}
            type={isPasswordHidden ? "password" : "text"}
            name="password"
            required
            placeholder="Enter password"
            autoComplete="off"
          />

          <AuthPasswordEye onClick={() => setIsPasswordHidden((s) => !s)} />
        </FormGroup>

        <PasswordCriteriaList />

        <Divider top="40" bottom="0" line="none" />

        <FormGroup>
          <Button
            variant="success"
            type="submit"
            loading={loading}
            style={{
              width: "100%",
              fontSize: "14px",
              fontWeight: 500,
              color: RBBLUE.five,
            }}
          >
            Change my Password
          </Button>

          <br />
        </FormGroup>

        <p style={{ marginTop: 20 }}>
          or log in{" "}
          <LoginHere
            data-testid="test__reset__history"
            onClick={() => history.push("/")}
          >
            here
          </LoginHere>
        </p>
      </Form>
    </FormContainer>
  );
};
