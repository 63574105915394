import styled from "styled-components";

import { FormLabel, GLOBAL, GREY } from "@patchworkhealth/web-components";

import { ModalFlex } from "components/Agencies/AgencyModal";
import { Heading } from "components/Style";

import { truncate } from "../Organisations/OrganisationsHelpers";

const AgencyCards = ({ agencies, openModal }) => (
  <OrganisationsGrid>
    {agencies?.map((cc) => (
      <OrganisationCard
        key={cc.id}
        onClick={() => {
          !cc.trashedAt && openModal(cc);
        }}
      >
        <PaymentType>
          {cc?.employmentType === "direct_engagement"
            ? "Direct Engagement"
            : "Non-Direct Engagement"}
        </PaymentType>
        <div style={{ height: "30px", marginBottom: "10px" }}></div>

        <Heading size="5" mb="14">
          {truncate(cc.agency.title)}
        </Heading>

        <ModalFlex col={2} direction="col">
          <FormLabel>Agency Id:</FormLabel>
          <p>
            <strong>{cc?.agency?.id}</strong>
          </p>
        </ModalFlex>

        <ModalFlex col={1} direction="col">
          <FormLabel>ORG GRADE:</FormLabel>
          <p>
            <strong>{cc?.grade?.title}</strong>
          </p>
        </ModalFlex>

        <ModalFlex col={2} direction="col">
          <FormLabel>Staff Group:</FormLabel>
          <p>
            <strong>{cc?.staffGroup?.title}</strong>
          </p>
        </ModalFlex>

        <OrganisationFooter activated={cc.confirmedAt} trashed={cc.trashedAt}>
          {cc.trashedAt ? (
            <Heading size="7" color="#FFFFFF">
              Disabled by agency on:
              <strong> {cc.trashedAt}</strong>
            </Heading>
          ) : !cc.confirmedAt ? (
            <Heading size="7" color="#FF763F">
              <strong>Click here to complete</strong>
            </Heading>
          ) : (
            <Heading size="7">
              Activated on:
              <strong> {cc.confirmedAt}</strong>
            </Heading>
          )}
        </OrganisationFooter>
      </OrganisationCard>
    ))}
  </OrganisationsGrid>
);

export default AgencyCards;

const OrganisationsGrid = styled.div`
  display: grid;
  grid-gap: 19px;
  grid-template-columns: repeat(3, 344px);
  @media (max-width: 1340px) {
    grid-template-columns: repeat(2, 344px);
  }
  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 300px);
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex !important;
    flex-wrap: wrap;
  }

  label {
    color: rgb(125, 135, 147);
  }
`;

const OrganisationCard = styled.div`
  background: ${GLOBAL.white};
  border: 1px solid ${GREY.two};
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.101863);
  box-sizing: border-box;
  cursor: pointer;
  height: 242px;
  padding: 20px 25px;
  position: relative;
  width: 344px;
  img {
    height: 30px;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin: 30px 20px 0 0;
  }
`;

const OrganisationFooter = styled.div.attrs((props) => ({
  activated: props.activated,
  trashed: props.trashed,
}))`
  align-items: center;
  background: ${(props) =>
    (props.trashed && "#D63232") ||
    (props.activated && "#F2FCF6") ||
    (!props.activated && "#FFE4D9")};
  border: 1px solid
    ${(props) =>
      (props.activated && "#E4E7EB") || (!props.activated && "#FFC8B2")};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  bottom: -1px;
  display: flex;
  height: 32px;
  justify-content: center;
  left: -1px;
  position: absolute;
  right: -1px;
`;

const PaymentType = styled.div`
  align-items: center;
  background: ${GREY.two};
  border-radius: 4px;
  color: ${GREY.seven};
  display: flex;
  font-size: 9px;
  font-weight: 600;
  height: 22px;
  justify-content: center;
  line-height: 20px;
  position: absolute;
  right: 10px;
  width: 120px;
`;
