import { Loading, RBBLACK } from "@patchworkhealth/web-components";
interface Props {
  isLoading: boolean;
  isEmpty: boolean;
}
export const EmptyTeamRota = ({ isEmpty, isLoading }: Props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "15%",
      }}
    >
      {isLoading && (
        <div data-testid="loading_component">
          <Loading />
        </div>
      )}
      {!isLoading && isEmpty && (
        <>
          <TeamRotaTemplate />
          <p
            style={{ fontSize: "16px", fontWeight: 500, color: RBBLACK.three }}
          >
            Your Team Rota will appear here soon.
          </p>
        </>
      )}
    </div>
  );
};

const TeamRotaTemplate = () => (
  <svg
    width="216"
    height="66"
    viewBox="0 0 216 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="team_rota_template"
  >
    <g clipPath="url(#clip0_3654_63013)">
      <path
        d="M94.0814 38.8273C91.128 36.8895 89.659 32.386 90.3826 28.2674C91.1063 24.1489 93.8316 20.6014 97.0986 19.0716C98.6878 18.3496 100.43 18.0369 102.169 18.1615C103.908 18.286 105.589 18.8441 107.06 19.7854C110.056 21.6318 114.294 22.6432 115.716 26.3376C116.81 29.1766 115.423 34.9416 113.951 37.6991C112.68 40.0825 110.263 41.1727 107.992 41.6464C105.48 42.1627 102.887 42.1293 100.389 41.5483C97.8912 40.9674 95.546 39.8524 93.5124 38.2789L94.0814 38.8273Z"
        fill="#CCD2D8"
      />
      <path
        d="M92.541 60.7028L90.1797 64.8063L89.8677 65.3476L89.4136 66.1436H82.4519L82.9218 65.3476L84.138 63.2819L86.9733 58.458L90.1836 59.7515L92.541 60.7028Z"
        fill="white"
      />
      <path
        d="M81.9304 66.1434L80.6589 65.3474L80.501 65.2479L88.4815 50.5891C88.8247 49.9546 89.2893 49.395 89.8485 48.9428C90.4077 48.4905 91.0504 48.1545 91.7392 47.9542C92.6089 47.6932 93.5301 47.6589 94.4165 47.8547C94.9337 47.9648 95.4315 48.1526 95.8933 48.4119C96.0834 48.517 96.2667 48.634 96.4422 48.7622C97.1171 49.2391 97.6716 49.8689 98.0612 50.601C98.525 51.458 98.7541 52.424 98.7249 53.3998C98.6958 54.3756 98.4095 55.3259 97.8954 56.1533L94.8193 61.1086L92.1894 65.3474L91.6958 66.1434H81.9304Z"
        fill="#9CA5B0"
      />
      <path
        d="M102.434 44.0307C107.79 44.0307 112.132 39.654 112.132 34.2551C112.132 28.8562 107.79 24.4795 102.434 24.4795C97.0773 24.4795 92.7351 28.8562 92.7351 34.2551C92.7351 39.654 97.0773 44.0307 102.434 44.0307Z"
        fill="#E4E7EB"
      />
      <path
        d="M112.17 66.143H90.3691L90.4678 65.8605C90.4124 65.6893 90.3611 65.5182 90.3137 65.347C90.2624 65.1679 90.2189 64.9848 90.1795 64.8057C89.8253 63.1396 89.8266 61.4166 90.1834 59.751C91.3879 53.7887 96.1935 48.945 96.1935 48.945C96.2764 48.8813 96.3593 48.8216 96.4423 48.7619C97.1572 48.2343 97.9328 47.7959 98.7523 47.4564C102.322 45.9638 106.065 46.5648 108.581 47.337C109.457 47.604 110.313 47.9325 111.144 48.3201C111.436 48.4594 111.594 48.5469 111.594 48.5469L111.843 56.1927L112.143 65.347L112.17 66.143Z"
        fill="#CCD2D8"
      />
      <path
        d="M122.255 65.3479L120.589 56.8066L118.907 48.1936C118.837 47.8379 118.661 47.5119 118.404 47.2583C118.147 47.0048 117.82 46.8355 117.465 46.7727L113.943 46.1598L110.666 45.5906C110.35 45.5332 110.024 45.5641 109.724 45.6798C109.424 45.7956 109.161 45.9918 108.964 46.2474C108.717 46.5568 108.582 46.9412 108.581 47.3379C108.581 47.5829 108.63 47.8254 108.727 48.0503C110.437 52.0703 110.468 58.4345 110.034 65.348C110.014 65.6106 109.998 65.8773 109.978 66.144H122.413L122.255 65.3479Z"
        fill="#9CA5B0"
      />
      <path
        d="M98.7521 47.4566C98.6796 47.1669 98.5367 46.9 98.3365 46.6798C98.1362 46.4596 97.8847 46.2931 97.6047 46.1952C97.3247 46.0973 97.0249 46.0711 96.7324 46.119C96.4398 46.1669 96.1636 46.2873 95.9288 46.4695L94.4163 47.8546L88.2246 53.5382C88.0316 53.7128 87.8804 53.9291 87.7823 54.171C87.0833 55.8546 85.7211 59.178 84.1375 63.2815C83.8809 63.9502 83.6164 64.6388 83.3439 65.3472C83.249 65.6099 83.1465 65.8766 83.0437 66.1432H93.2475C93.3304 65.8766 93.4133 65.6099 93.4962 65.3472C93.9503 63.9024 94.3926 62.4855 94.8191 61.1084C96.0668 57.1084 97.1923 53.4745 98.0611 50.6009C98.3019 49.8049 98.5217 49.0698 98.7205 48.3959C98.8133 48.0911 98.8242 47.767 98.7521 47.4566Z"
        fill="#9CA5B0"
      />
      <path
        d="M124.546 66.1435H125.205L125.114 65.9207L124.873 65.3475L121.158 56.46L120.589 56.8062L116.024 59.5883L118.366 65.3475L118.69 66.1435H122.413H124.546Z"
        fill="white"
      />
      <path
        d="M120.778 49.4109C120.276 48.0845 119.284 47.0054 118.01 46.3993C116.736 45.7933 115.278 45.7072 113.943 46.1591C113.772 46.2161 113.605 46.2826 113.442 46.3581C112.506 46.7787 111.709 47.4594 111.143 48.3203C110.913 48.6641 110.723 49.0341 110.579 49.4228C110.322 50.0975 110.201 50.8166 110.222 51.5386C110.243 52.2607 110.406 52.9715 110.701 53.6298L111.842 56.193L115.925 65.3473L116.281 66.1433H124.546L125.114 65.9204L126.591 65.3473L126.796 65.2677L120.778 49.4109Z"
        fill="#9CA5B0"
      />
      <path
        d="M110.052 23.0275C110.39 23.1296 110.752 23.1166 111.082 22.9904C111.412 22.8642 111.691 22.6319 111.877 22.3294C112.053 22.0211 112.156 21.6763 112.179 21.3214C112.203 20.9666 112.145 20.6111 112.01 20.2823C111.735 19.6258 111.301 19.0492 110.749 18.6048C110.121 18.0323 109.357 17.6334 108.53 17.4473C108.118 17.3578 107.691 17.3587 107.278 17.4501C106.866 17.5415 106.478 17.7211 106.14 17.9769C105.809 18.2411 105.563 18.599 105.435 19.0047C105.307 19.4105 105.301 19.8456 105.419 20.2545C105.671 20.9886 106.351 21.4803 107.03 21.8466C108.378 22.5718 109.864 22.9977 111.389 23.0956L110.052 23.0275Z"
        fill="#CCD2D8"
      />
      <path
        d="M111.939 33.9626C110.38 34.1793 110.58 33.3793 109.955 31.923C109.331 30.4668 108.495 28.738 106.928 28.5905C105.643 28.4696 104.585 29.5045 103.545 30.273C101.69 31.6427 99.191 32.3131 97.0413 31.4914C94.8916 30.6697 93.3978 28.0874 94.2135 25.9208C94.4674 25.3225 94.8404 24.7832 95.3095 24.3362C95.7787 23.8892 96.3338 23.544 96.9407 23.3221C98.1581 22.8858 99.4511 22.7048 100.74 22.7901C103.608 22.8825 106.42 23.6081 108.979 24.9158C110.745 25.8202 112.464 27.1141 113.134 28.993C113.804 30.872 114.245 33.5124 112.329 34.0206L111.939 33.9626Z"
        fill="#CCD2D8"
      />
      <path
        d="M109.255 17.8598C108.484 16.5077 109.12 14.6523 110.381 13.7488C111.642 12.8453 113.334 12.7567 114.839 13.1191C116.327 13.4969 117.691 14.2626 118.793 15.3391C119.895 16.4157 120.696 17.7655 121.118 19.253C121.756 21.6176 121.377 24.1574 121.917 26.5468C122.206 27.8204 122.749 29.0211 123.515 30.0749C124.282 31.1287 125.253 32.0134 126.371 32.6743C127.489 33.3352 128.73 33.7584 130.016 33.9179C131.303 34.0774 132.608 33.9698 133.851 33.6017C135.471 33.1222 137.117 31.9566 137.277 30.2627C137.444 32.2079 137.004 34.1574 136.018 35.8381C135.032 37.5187 133.549 38.8465 131.777 39.6354C129.69 40.5129 127.362 40.5701 125.101 40.5116C123.628 40.4735 122.122 40.3841 120.763 39.8097C119.822 39.3774 118.977 38.7604 118.276 37.9947C117.576 37.2289 117.034 36.33 116.682 35.3504C115.99 33.3815 115.803 31.2682 116.139 29.207C116.361 27.6037 116.804 26.0322 116.928 24.4181C117.051 22.8041 116.817 21.0806 115.829 19.8036C114.842 18.5266 112.956 17.8744 111.553 18.6613C110.989 18.9776 110.533 19.4942 109.921 19.6983C109.308 19.9024 108.439 19.5438 108.495 18.8956L109.255 17.8598Z"
        fill="#CCD2D8"
      />
      <path
        d="M175.438 66.1432L175.513 65.3472L177.74 41.9919C178.47 41.4158 179.254 40.9132 180.082 40.4914C184.666 38.163 189.089 39.2774 191.482 40.2406C192.116 40.4909 192.729 40.793 193.314 41.1441L194.617 65.3472L194.661 66.1432H175.438Z"
        fill="#CCD2D8"
      />
      <path
        d="M185.868 35.7758C191.224 35.7758 195.566 31.3991 195.566 26.0002C195.566 20.6013 191.224 16.2246 185.868 16.2246C180.511 16.2246 176.169 20.6013 176.169 26.0002C176.169 31.3991 180.511 35.7758 185.868 35.7758Z"
        fill="#E4E7EB"
      />
      <path
        d="M193.737 31.9615C194.458 31.1957 194.936 30.2311 195.112 29.1903C195.287 28.1495 195.151 27.0797 194.722 26.1169C194.292 25.154 193.589 24.3417 192.7 23.7833C191.811 23.2248 190.777 22.9455 189.731 22.9807C188.39 23.0259 187.126 23.5791 185.846 23.9851C183.906 24.6502 181.837 24.838 179.81 24.5329C177.772 24.2139 175.932 23.1191 174.67 21.4739C173.47 19.8116 173.096 17.506 173.944 15.6362C174.922 13.4776 177.208 12.2882 179.359 11.3218C180.226 10.8841 181.153 10.5774 182.108 10.4113C182.587 10.3312 183.077 10.3515 183.548 10.4711C184.018 10.5907 184.46 10.8069 184.843 11.1062C185.448 11.6301 185.802 12.4176 186.46 12.8716C187.2 13.3827 188.161 13.3693 189.054 13.4594C191.065 13.6623 192.967 14.4775 194.508 15.7966C196.048 17.1157 197.153 18.8761 197.676 20.8438C198.2 22.8114 198.116 24.893 197.437 26.8117C196.758 28.7304 195.515 30.3953 193.875 31.5849L193.737 31.9615Z"
        fill="#9CA5B0"
      />
      <path
        d="M181.527 44.5357L180.082 40.4919L179.992 40.2398C179.949 40.1203 179.88 40.0122 179.789 39.9236C179.699 39.835 179.59 39.7683 179.47 39.7286C179.35 39.6888 179.223 39.677 179.098 39.6941C178.973 39.7112 178.854 39.7567 178.749 39.8272L177.108 40.9297C177.108 40.9297 166.585 40.9297 165.74 47.2939C165.728 47.3934 165.712 47.5048 165.7 47.6243C165.7 47.6297 165.698 47.6351 165.696 47.6402C165.59 48.0581 163.603 55.7955 161.897 65.3477C161.85 65.6104 161.803 65.8771 161.755 66.1438H184.623V66.1437C185.367 56.548 184.951 47.6263 181.527 44.5357Z"
        fill="#9CA5B0"
      />
      <path
        d="M208.805 65.3471C207.558 55.5481 205.773 47.6237 205.773 47.6237C205.757 47.5043 205.741 47.3928 205.729 47.2933C204.888 40.9291 194.365 40.9291 194.365 40.9291L192.725 39.8272C192.62 39.7567 192.5 39.7112 192.375 39.6941C192.25 39.677 192.123 39.6889 192.004 39.7287C191.884 39.7685 191.775 39.8353 191.684 39.924C191.594 40.0127 191.525 40.1209 191.482 40.2405L189.946 44.5351C186.522 47.6257 186.106 56.5473 186.85 66.1429L186.85 66.1431H208.904C208.873 65.8765 208.837 65.6098 208.805 65.3471Z"
        fill="#9CA5B0"
      />
      <path
        d="M23.5751 43.0351L21.2296 65.3476L21.1466 66.1437H14.801C14.876 65.885 14.951 65.6183 15.0301 65.3476C16.8308 59.1227 19.2829 50.0361 19.0182 47.6282C18.6037 43.8709 21.5061 42.6172 21.5061 42.6172L21.6877 42.653L23.5751 43.0351Z"
        fill="#CCD2D8"
      />
      <path
        d="M33.8097 36.1478C39.1661 36.1478 43.5083 31.7712 43.5083 26.3723C43.5083 20.9733 39.1661 16.5967 33.8097 16.5967C28.4533 16.5967 24.1111 20.9733 24.1111 26.3723C24.1111 31.7712 28.4533 36.1478 33.8097 36.1478Z"
        fill="#CCD2D8"
      />
      <path
        d="M46.3641 61.4067C45.875 62.3155 45.4743 63.2699 45.1676 64.2565C45.0531 64.6068 44.9426 64.9729 44.8399 65.3471C44.7649 65.6058 44.6978 65.8725 44.6307 66.1431H19.0503C19.086 65.8804 19.1174 65.6137 19.1531 65.3471C20.4601 54.9391 21.5183 44.3758 21.6882 42.6524C21.7039 42.4892 21.7119 42.4056 21.7119 42.4056C37.041 34.2623 48.2279 44.9131 48.2279 44.9131C48.2279 44.9131 48.2437 44.9887 48.2675 45.136C48.5124 46.6246 49.76 55.3172 46.3641 61.4067Z"
        fill="#CCD2D8"
      />
      <path
        d="M45.1677 64.2568L43.4619 60.5712L43.8764 45.9562L48.2676 45.1362L48.4216 45.1084C50.6913 46.4383 52.4476 48.5032 53.4048 50.9671C54.2578 53.1164 55.8807 59.5801 57.251 65.3473C57.3143 65.614 57.3773 65.8807 57.4406 66.1434H46.0404L45.6731 65.3473L45.1677 64.2568Z"
        fill="#CCD2D8"
      />
      <path
        d="M60.7693 0.470703H4.68862C3.48572 0.472152 2.33251 0.95443 1.48193 1.81175C0.63134 2.66906 0.15283 3.83143 0.151367 5.04387V61.5696C0.15283 62.782 0.63134 63.9444 1.48193 64.8017C2.33251 65.659 3.48572 66.1413 4.68862 66.1427H60.7693C61.9724 66.1419 63.1259 65.6599 63.9766 64.8024C64.8273 63.9449 65.3056 62.7822 65.3064 61.5696V5.04387C65.3056 3.83125 64.8273 2.66851 63.9766 1.81104C63.1259 0.953578 61.9724 0.471497 60.7693 0.470703ZM64.5166 61.5696C64.5158 62.571 64.1207 63.5313 63.4181 64.2394C62.7156 64.9476 61.7629 65.3458 60.7693 65.3467H4.68862C3.6952 65.3452 2.74289 64.9468 2.04043 64.2387C1.33797 63.5307 0.942647 62.5709 0.941125 61.5696V5.04387C0.942647 4.04257 1.33797 3.08272 2.04043 2.37469C2.74289 1.66667 3.6952 1.26824 4.68862 1.26673H60.7693C61.7629 1.26758 62.7156 1.66581 63.4181 2.37398C64.1207 3.08215 64.5158 4.04238 64.5166 5.04387V61.5696ZM21.2299 65.3467L21.147 66.1427H44.6306C44.6976 65.8721 44.7647 65.6054 44.8397 65.3467H21.2299Z"
        fill="#CCD2D8"
      />
      <path
        d="M136.037 0.470703H79.9567C78.7538 0.472153 77.6006 0.95443 76.75 1.81175C75.8994 2.66906 75.4209 3.83143 75.4194 5.04387V61.5696C75.4209 62.782 75.8994 63.9444 76.75 64.8017C77.6006 65.659 78.7538 66.1413 79.9567 66.1427H136.037C137.24 66.1419 138.394 65.6598 139.245 64.8024C140.095 63.9449 140.574 62.7822 140.574 61.5696V5.04387C140.574 3.83125 140.095 2.66851 139.245 1.81104C138.394 0.953578 137.24 0.471497 136.037 0.470703ZM139.785 61.5696C139.784 62.571 139.389 63.5313 138.686 64.2394C137.984 64.9476 137.031 65.3458 136.037 65.3467H79.9567C78.9633 65.3452 78.0109 64.9468 77.3085 64.2387C76.606 63.5307 76.2107 62.5709 76.2092 61.5696V5.04387C76.2107 4.04257 76.606 3.08272 77.3085 2.37469C78.0109 1.66667 78.9633 1.26824 79.9567 1.26673H136.037C137.031 1.26758 137.984 1.66581 138.686 2.37398C139.389 3.08215 139.784 4.04238 139.785 5.04387V61.5696ZM90.3143 65.3467C90.3618 65.5178 90.4131 65.689 90.4684 65.8601L90.3697 66.1427H109.979C109.999 65.876 110.015 65.6094 110.035 65.3467H90.3143Z"
        fill="#CCD2D8"
      />
      <path
        d="M211.309 0.470703H155.228C154.025 0.471456 152.871 0.953514 152.021 1.81098C151.17 2.66845 150.692 3.83122 150.691 5.04387V61.5696C150.692 62.7822 151.17 63.945 152.021 64.8024C152.871 65.6599 154.025 66.142 155.228 66.1427H211.309C212.512 66.1412 213.665 65.6589 214.515 64.8016C215.366 63.9443 215.844 62.782 215.846 61.5696V5.04387C215.844 3.83146 215.366 2.66912 214.515 1.81181C213.665 0.954493 212.512 0.472189 211.309 0.470703ZM215.056 61.5696C215.055 62.5708 214.659 63.5307 213.957 64.2387C213.255 64.9467 212.302 65.3452 211.309 65.3467H155.228C154.235 65.3459 153.282 64.9477 152.579 64.2395C151.877 63.5313 151.482 62.5711 151.481 61.5696V5.04387C151.482 4.04235 151.877 3.0821 152.579 2.37393C153.282 1.66576 154.235 1.26755 155.228 1.26673H211.309C212.302 1.26824 213.255 1.66668 213.957 2.37471C214.659 3.08273 215.055 4.04259 215.056 5.04387V61.5696ZM184.682 65.3467C184.662 65.6094 184.643 65.876 184.623 66.1427H201.638C201.67 65.88 201.705 65.6133 201.737 65.3467H184.682Z"
        fill="#CCD2D8"
      />
      <path
        d="M38.4482 34.8895C38.7767 35.216 39.2109 35.4121 39.6712 35.4418C40.1315 35.4715 40.587 35.3329 40.9541 35.0513C43.2399 33.3435 45.2466 31.2177 46.4341 28.6247C47.7949 25.6536 47.9467 22.0077 46.306 19.1838C44.9191 16.7966 42.47 15.2562 40.0291 13.9913C39.0814 13.4521 38.0605 13.0556 36.9989 12.8141C35.9259 12.5755 34.8031 12.7582 33.859 13.3248C33.3971 13.6167 33.0333 14.0421 32.8149 14.5455C32.5965 15.049 32.5337 15.6071 32.6346 16.147C31.4622 14.7987 29.9605 13.7826 28.2784 13.1995C27.2192 12.7818 26.0593 12.6971 24.9514 12.9564C23.4025 13.3993 22.2717 14.7864 21.6532 16.2847C21.153 17.4244 20.9803 18.6827 21.1547 19.9165C21.2464 20.5304 21.465 21.1181 21.7963 21.6416C22.1276 22.165 22.5643 22.6125 23.0779 22.9552C24.3586 23.7538 25.9924 23.6962 27.4631 23.3699C28.9338 23.0437 30.3575 22.4762 31.8567 22.3327C33.3559 22.1891 35.0254 22.5659 35.9377 23.7735C36.5803 24.6239 36.7453 25.7351 36.8895 26.7941L37.6397 32.3022C37.701 33.099 37.9029 33.8784 38.2359 34.6037C38.2936 34.7082 38.365 34.8043 38.4482 34.8895Z"
        fill="#9CA5B0"
      />
    </g>
    <defs>
      <clipPath id="clip0_3654_63013">
        <rect width="215" height="66" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
