import styled from "styled-components";

import Layout from "components/Layout";

export const UnauthorisedPage = () => {
  return (
    <Layout headerValueText="Unauthorised">
      <EmptyContainer>
        <h1>Unauthorised.</h1>
        <h2>
          Sorry, you do not have access to the page you were trying to access.
        </h2>
        <p>
          You can <a href="/">return to our front page</a>, or{" "}
          <a href="mailto:support@patchwork.health">drop us a line</a> if you
          think there is an issue.
        </p>
      </EmptyContainer>
    </Layout>
  );
};

const EmptyContainer = styled.div`
  h1 {
    font-size: 32px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;
  }

  p {
    font-size: 17px;
    font-weight: 400;
    line-height: 28px;
  }
`;
