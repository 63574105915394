import { ChangeEvent, useRef } from "react";
import styled from "styled-components";

import {
  FormInput,
  FormInputGroup,
  InputTime,
} from "@patchworkhealth/web-components";

interface Props {
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string | undefined;
  testId?: string;
}

export const TimeInput = ({ name, onChange, value, testId }: Props) => {
  const timeInputRef = useRef<HTMLInputElement>(null);
  return (
    <FormInputGroup style={{ width: "160px" }}>
      <FormInputGroup.Text
        onClick={() => timeInputRef.current?.showPicker()}
        style={{ cursor: "pointer" }}
      >
        <InputTime />
      </FormInputGroup.Text>
      <StyledTimeInput
        data-testid={testId}
        name={name}
        onChange={onChange}
        type="time"
        value={value}
        ref={timeInputRef}
        placeholder="00:00"
      />
    </FormInputGroup>
  );
};

const StyledTimeInput = styled(FormInput)`
  ::-webkit-calendar-picker-indicator {
    display: none;
  }
`;
