import { Dispatch, SetStateAction, useContext } from "react";

import {
  DateInput,
  FormGroup,
  FormLabel,
  FormSelect,
  FormSelectMulti,
} from "@patchworkhealth/web-components";

import { Button } from "components/UI";
import { AppContext } from "context/AppContext";
import { formatForReactSelect } from "helpers/newHelpers";
import { classNames } from "helpers/newHelpers";

import { InitFilters, InitInputs } from "./Shift.types";

interface FilterProps {
  inputs: InitInputs;
  filters: InitFilters;
  setInputs: Dispatch<SetStateAction<InitInputs>>;
  handleFilter: (e: unknown, name: keyof InitFilters) => void;
  handleDateChange: (day: string | null, name: string) => void;
  resetFilters:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
}

function ShiftFilters({
  inputs,
  setInputs,
  filters,
  handleFilter,
  handleDateChange,
  resetFilters,
}: FilterProps) {
  const { user, specialities } = useContext(AppContext);
  const sites = user?.organisations?.flatMap((org) => org?.sites);

  const shiftTypes = [
    { value: "HOURLY", label: "Hourly Shifts" },
    { value: "SESSIONAL", label: "Sessional Shifts" },
    { value: "ONCALL", label: "On-Call Shifts" },
  ];

  return (
    <div
      className={classNames(
        "fixed bottom-0 right-0 top-[64px] z-40 bg-white w-[375px] p-6 transition-all duration-[400ms] border border-grey-2 shadow-lg",
        inputs.sidebarOpen ? "right-0" : "right-[-400px]"
      )}
    >
      <FormGroup className="mb-6">
        <FormLabel>From Start Date</FormLabel>
        <DateInput
          name="fromStartTime"
          value={filters.fromStartTime}
          action={(date, name) => {
            handleDateChange(date ? date.toISOString() : null, name);
          }}
        />
      </FormGroup>

      <FormGroup className="mb-6">
        <FormLabel>To End Date</FormLabel>
        <DateInput
          name="toStartTime"
          value={filters.toStartTime}
          action={(date, name) => {
            handleDateChange(date ? date.toISOString() : null, name);
          }}
        />
      </FormGroup>

      <FormGroup className="mb-6">
        <FormLabel>Select Shift Type</FormLabel>
        <FormSelect
          onChange={(e) => handleFilter(e, "shiftType")}
          options={shiftTypes}
          isClearable
          placeholder={"Select ShiftType..."}
          value={formatForReactSelect(filters.shiftType)}
        />
      </FormGroup>

      <FormGroup className="mb-6">
        <FormLabel>Select Grades</FormLabel>
        <FormSelectMulti
          isClearable
          onChange={(e) => handleFilter(e, "gradeIds")}
          options={formatForReactSelect(user?.grades)}
          placeholder={"Select Grades..."}
          value={filters.gradeIds}
        />
      </FormGroup>

      <FormGroup className="mb-6">
        <FormLabel>Select Sites</FormLabel>
        <FormSelectMulti
          isClearable
          onChange={(e) => handleFilter(e, "siteIds")}
          options={formatForReactSelect(sites)}
          placeholder={"Select Sites..."}
          value={filters.siteIds}
        />
      </FormGroup>

      <FormGroup className="mb-6">
        <FormLabel>Select Specialities</FormLabel>
        <FormSelectMulti
          isClearable
          onChange={(e) => handleFilter(e, "specialityIds")}
          options={formatForReactSelect(specialities)}
          placeholder={"Select Specialities"}
          value={filters.specialityIds}
        />
      </FormGroup>

      <div className="flex justify-between w-full [&>button]:m-2">
        <Button
          variant="default"
          fullWidth
          onClick={() =>
            setInputs({
              ...inputs,
              sidebarOpen: false,
            })
          }
        >
          Close
        </Button>

        <Button variant="blue" fullWidth onClick={resetFilters}>
          Reset
        </Button>
      </div>
    </div>
  );
}

export default ShiftFilters;
