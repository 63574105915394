import { Form } from "react-bootstrap";
import styled from "styled-components";

import {
  Button,
  FormGroup,
  FormInput,
  FormLabel,
  FormSelect,
  FormToggleButtons,
  GREY,
} from "@patchworkhealth/web-components";

import { Divider, FormikErrors, Require } from "components/Style";
import { FormGrid } from "components/UI/Form";
import { onChangePicture, removeProfilePic } from "helpers/functions";
import { ProductType } from "helpers/productType";

import ProfileHeader from "./ProfileHeader";

const Profile = ({
  agency,
  formik,
  rosterProfile,
  store,
  setIsOpen,
  updateProfilePicture,
  removeProfilePicture,
  displayEdiFields,
  ediFieldsDisabled,
  onEdiConsentChange,
}) => {
  return (
    <>
      {!agency && <ProfileTitle>Basic Profile</ProfileTitle>}

      <ProfileCard agency={agency}>
        {agency && (
          <p style={{ marginBottom: 15 }}>
            <strong>{agency}</strong> has setup your profile, please review, and
            confirm the information is correct.
          </p>
        )}

        <Form onSubmit={formik.handleSubmit} data-testid="test__submit__form">
          <ProfileHeader
            store={store}
            setIsOpen={setIsOpen}
            removeProfilePicture={() => {
              removeProfilePic(store, removeProfilePicture);
            }}
            updateProfilePic={(e) =>
              onChangePicture(e, store, updateProfilePicture)
            }
          />

          <p className="absolute opacity-50 right-2 top-2">
            {store.user?.grades[0]?.title?.slice(0, 60)}
          </p>

          <Divider bottom="20" line="none" />

          <FormGrid columns={2}>
            <FormGroup>
              <FormLabel>
                First Name <Require>*</Require>
              </FormLabel>
              <FormInput
                name="firstName"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                disabled={store?.user?.firstName?.length > 0}
                data-testid="test__signup__firstname"
                placeholder="First Name"
              />
              <FormikErrors
                touch={formik?.touched?.firstName}
                err={formik?.errors?.firstName}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>
                Last Name <Require>*</Require>
              </FormLabel>
              <FormInput
                name="lastName"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                disabled={store?.user?.lastName?.length > 0}
                data-testid="test__signup__lastname"
                placeholder="Last Name"
              />
              <FormikErrors
                touch={formik?.touched.lastName}
                err={formik?.errors.lastName}
              />
            </FormGroup>
          </FormGrid>

          {/* Row 1.5 ****************************************** */}

          <FormGrid columns={2}>
            <FormGroup>
              <FormLabel>Preferred Name</FormLabel>
              <FormInput
                name="preferredName"
                onChange={formik.handleChange}
                value={formik.values.preferredName}
                data-testid="test__signup__preferredName"
                placeholder="Preferred Name"
              />
            </FormGroup>

            <FormGroup data-testid="select_suggested_grade">
              <FormLabel>Suggested Grade</FormLabel>
              <FormSelect
                placeholder="Select Grade..."
                type="text"
                value={!formik.values.grade.value ? null : formik.values.grade}
                options={store.grades}
                onChange={(option) => {
                  formik.setFieldValue("grade", option !== null ? option : []);
                }}
              />
              <FormikErrors
                touch={formik.touched.grade}
                err={formik.errors.grade}
              />
            </FormGroup>
          </FormGrid>

          {/* ROW 2 *******************************************************************/}

          <FormGrid columns={2}>
            <FormGroup>
              <FormLabel>
                Date of Birth<Require>*</Require>{" "}
              </FormLabel>

              <FormInput
                name="dateOfBirth"
                type="date"
                onChange={formik.handleChange}
                value={formik.values.dateOfBirth}
                disabled={store?.user?.dateOfBirth?.length > 0}
              />

              <FormikErrors
                touch={formik?.touched?.dateOfBirth}
                err={formik?.errors?.dateOfBirth}
              />
            </FormGroup>

            <FormGroup data-testid="select_gender">
              <FormLabel>
                Gender<Require>*</Require>
              </FormLabel>
              <FormSelect
                placeholder="Select Gender..."
                type="text"
                value={formik.values?.gender}
                options={store?.ediReferenceData?.gender?.map((cc) => ({
                  label: cc.value,
                  value: cc.id,
                }))}
                onChange={(option) => {
                  formik.setFieldValue("gender", option);
                }}
              />
              <FormikErrors
                touch={formik.touched.gender}
                err={formik.errors.gender}
              />
            </FormGroup>
          </FormGrid>

          <Divider top="26" bottom="26" line="solid" />

          {/* ROW 3 **************************************************************/}

          <FormGrid columns={2}>
            <FormGroup>
              <FormLabel>Email Address</FormLabel>
              <FormInput
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                disabled={store?.user?.email?.length > 0}
                data-testid="test__signup__firstname"
                placeholder="Email Address"
                style={{ pointerEvents: "none" }}
                className="no__pointer"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>
                Phone Number <Require>*</Require>
              </FormLabel>
              <FormInput
                name="phoneNumber"
                onChange={formik.handleChange}
                value={formik.values.phoneNumber}
                data-testid="test__signup__lastname"
                placeholder="Phone Number"
              />
              <FormikErrors
                touch={formik?.touched?.phoneNumber}
                err={formik?.errors?.phoneNumber}
              />
            </FormGroup>
          </FormGrid>

          {/* Row ********************************************************* */}

          <FormGrid columns={2}>
            <FormGroup>
              <FormLabel>Address line 1</FormLabel> <Require>*</Require>
              <FormInput
                name="addressLine1"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.addressLine1}
                data-testid="test__signup__addressLine1"
                placeholder="Address line 1"
              />
              <FormikErrors
                touch={formik?.touched.addressLine1}
                err={formik?.errors.addressLine1}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Address line 2</FormLabel>
              <FormInput
                name="addressLine2"
                type="name"
                onChange={formik.handleChange}
                value={formik.values.addressLine2}
                data-testid="test__signup__addressLine2"
                placeholder="Address line 2"
              />
              <FormikErrors
                touch={formik?.touched?.addressLine2}
                err={formik?.errors?.addressLine2}
              />
            </FormGroup>
          </FormGrid>

          {/* Row ********************************************* */}

          <FormGrid columns={2}>
            <FormGroup>
              <FormLabel>City</FormLabel> <Require>*</Require>
              <FormInput
                name="city"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.city}
                data-testid="test__signup__city"
                placeholder="City"
              />
              <FormikErrors
                touch={formik.touched.city}
                err={formik.errors.city}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>
                Post Code <Require>*</Require>
              </FormLabel>
              <FormInput
                name="postcode"
                type="name"
                onChange={formik.handleChange}
                value={formik.values.postcode}
                data-testid="test__signup__postcode"
                placeholder="Postcode"
              />
              <FormikErrors
                touch={formik?.touched?.postcode}
                err={formik?.errors?.postcode}
              />
            </FormGroup>
          </FormGrid>

          <Divider top="26" bottom="26" line="solid" />

          {/* ROW 4 *******************************************************************/}

          <FormGrid columns={2}>
            <FormGroup data-testid="select_staff_group">
              <FormLabel>
                Staff Group<span>*</span>
              </FormLabel>
              <FormSelect
                placeholder="Select Staff Group..."
                type="text"
                value={formik.values.staffGroup}
                options={store.staffGroups}
                onChange={(option) => {
                  formik.setFieldValue(
                    "staffGroup",
                    option !== null ? option : []
                  );
                }}
              />
              <FormikErrors
                touch={formik.touched.staffGroup}
                err={formik.errors.staffGroup}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Professional Number</FormLabel>
              <FormInput
                placeholder="Enter Professional No..."
                type="text"
                name="profNumber"
                onChange={formik.handleChange}
                value={formik.values.profNumber}
                disabled={store?.user?.regBodyNumber?.length > 0}
                data-testid="test__signup__profNumber"
              />
              <FormikErrors
                touch={formik.touched.profNumber}
                err={formik.errors.profNumber}
              />
            </FormGroup>
          </FormGrid>
        </Form>

        <Form data-testid="test__submit__form" onSubmit={formik.handleSubmit}>
          <FormGrid columns={2}>
            <FormGroup>
              <FormLabel>
                National Insurance No. <Require>*</Require>
              </FormLabel>
              <FormInput
                name="niNumber"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.niNumber}
                data-testid="test__signup__niNumber"
                placeholder="Ni Number"
              />
              <FormikErrors
                touch={formik.touched.niNumber}
                err={formik.errors.niNumber}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>DBS Number</FormLabel>
              <FormInput
                border={
                  formik.touched.dbsNumber && formik.errors.dbsNumber
                    ? "red"
                    : ""
                }
                name="dbsNumber"
                type="name"
                onChange={formik.handleChange}
                value={formik.values.dbsNumber}
                data-testid="test__signup__dbsNumber"
                placeholder="Dbs number"
              />
              <FormikErrors
                touch={formik.touched.dbsNumber}
                err={formik.errors.dbsNumber}
              />
            </FormGroup>
          </FormGrid>

          {displayEdiFields === true && (
            <>
              {/* ROW 5 EDI *******************************************************************/}
              <Divider top="26" bottom="26" line="solid" />
              <p className="mb-3 text-sm">
                I consent to you using the below information to report
                anonymously to NHS England for equality monitoring purposes.
              </p>
              <FormGrid columns={2}>
                <FormGroup>
                  <FormToggleButtons
                    name="ediConsent"
                    input={formik.values.ediConsent}
                    text={["I do not consent", "I consent"]}
                    action={(e, index) => {
                      onEdiConsentChange(e, index);
                    }}
                  />
                  <FormikErrors
                    touch={formik.touched.ediConsent}
                    err={formik.errors.ediConsent}
                  />
                </FormGroup>
              </FormGrid>
              <FormGrid columns={2}>
                <FormGroup>
                  <FormLabel>Ethnicity</FormLabel>
                  <FormSelect
                    onChange={(option) => {
                      formik.setFieldValue("ethnicity", option);
                    }}
                    options={store?.ediReferenceData?.ethnicitySection?.map(
                      (cc) => ({
                        label: cc.value,
                        options: cc.ethnicities?.map((eth) => ({
                          label: eth.value,
                          value: eth.id,
                        })),
                      })
                    )}
                    placeholder="Select Ethnicity"
                    value={formik.values?.ethnicity}
                    isClearable
                    isDisabled={ediFieldsDisabled}
                  />
                  <FormikErrors
                    touch={formik.touched.ethnicity}
                    err={formik.errors.ethnicity}
                  />
                </FormGroup>

                <FormGroup>
                  <FormLabel>Country of Qualification</FormLabel>
                  <FormSelect
                    onChange={(option) => {
                      formik.setFieldValue("countryOfQualification", option);
                    }}
                    options={store?.ediReferenceData?.countryOfQualification?.map(
                      (cc) => ({
                        label: cc.value,
                        value: cc.id,
                      })
                    )}
                    placeholder="Select Country of Qualification"
                    value={formik.values?.countryOfQualification}
                    isClearable
                    isDisabled={ediFieldsDisabled}
                  />
                  <FormikErrors
                    touch={formik.touched.countryOfQualification}
                    err={formik.errors.countryOfQualification}
                  />
                </FormGroup>
              </FormGrid>
              <FormGrid columns={2}>
                <FormGroup>
                  <FormLabel>Disability</FormLabel>
                  <FormSelect
                    onChange={(option) => {
                      formik.setFieldValue("disability", option);
                    }}
                    options={store?.ediReferenceData?.disability?.map((cc) => ({
                      label: cc.value,
                      value: cc.id,
                    }))}
                    placeholder="Select Disability"
                    value={formik.values?.disability}
                    isClearable
                    isDisabled={ediFieldsDisabled}
                  />
                  <FormikErrors
                    touch={formik.touched.disability}
                    err={formik.errors.disability}
                  />
                </FormGroup>
              </FormGrid>
            </>
          )}

          {/* 2ND ROW Employment ***************************************8 */}

          {!agency && (
            <>
              <Divider top="50" bottom="20" line="solid" />
              <ButtonContainer data-testid="test__signup__submit">
                <div />
                <Button variant="primary" type="submit">
                  Update Info
                </Button>
              </ButtonContainer>
            </>
          )}
          {store?.user?.fistName?.length > 0 && (
            <p className="mb-3 text-sm">
              If you wish to edit any of your profile details, please contact us
              at help@patchwork.health
            </p>
          )}
        </Form>
      </ProfileCard>

      {/* Roster Card ***********************************************************************/}
      <Divider top="20" bottom="0" line="none" />

      {(store.productType === ProductType.Rota ||
        store.productType === ProductType.BankAndRota) && (
        <>
          <ProfileTitle>Roster Profile</ProfileTitle>

          {rosterProfile?.map((org, index) => (
            <ProfileCard
              key={index}
              className="roster__card"
              style={{ marginBottom: "40px" }}
            >
              <FormGrid columns={2}>
                <FormGroup>
                  <FormLabel>Rostered Staff Grade</FormLabel>
                  <p>{org?.grade?.title}</p>
                </FormGroup>
                <FormGroup style={{ maxWidth: 290 }}>
                  <FormLabel>Employment Type</FormLabel>
                  <FormToggleButtons
                    input={org?.lessThanFullTime === true ? 1 : 0}
                    text={["Full-time", "Part-time"]}
                  />
                </FormGroup>
              </FormGrid>

              <Divider top="10" bottom="0" line="none" />
              <FormGrid columns={2}>
                <FormGroup>
                  <FormLabel>WTE / OOH</FormLabel>
                  <p>
                    {org?.wholeTimeEquivalent} / {org?.outOfHours}
                  </p>
                </FormGroup>
                <FormGroup>
                  <FormLabel>Working Arrangement Type</FormLabel>
                  <p>{org?.workingArrangement}</p>
                </FormGroup>
              </FormGrid>

              <Divider top="10" bottom="0" line="none" />
              <>
                <FormGrid columns={2}>
                  <FormGroup>
                    <>
                      <FormLabel>
                        Department(s) Worked in the last 12 months
                      </FormLabel>
                      {/* <p>
                              {org?.departmentsList?.length > 0 &&
                                org.departmentsList.map(
                                  (cc) => cc,
                                  ","
                                )}{" "}
                            </p> */}
                    </>
                  </FormGroup>

                  <FormGroup>
                    <FormLabel>Organisation</FormLabel>

                    <p>{org?.organisation?.name}</p>
                  </FormGroup>
                </FormGrid>
              </>
            </ProfileCard>
          ))}
        </>
      )}
    </>
  );
};

export default Profile;

// New Styles ********************************************************

const ProfileTitle = styled.div`
  color: ${GREY.ten};
  font-size: 18px;
  font-weight: 600;
  margin: 20px 0;
`;

const ProfileCard = styled.div`
  .roster__card {
    * {
      pointer-events: none;
    }
  }

  background-color: #fff;
  border: 1px solid rgba(228, 231, 235, 1);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.05);
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
  max-width: 800px;
  padding: 39px 40px;

  @media (max-width: 568px) {
    padding: 39px 12px;
  }

  label {
    color: rgb(125, 135, 147);
  }

  p {
    margin: 0;
    padding-left: 8px;
  }

  h6 {
    color: ${GREY.ten};
    display: block;
    font-size: 13px;
  }

  h3 {
    color: #002033;
    font-size: 13px;
    margin-bottom: 25px;
  }

  .form__email {
    color: rgba(128, 128, 128, 0.6);
    font-size: 13px;
    letter-spacing: 0.4px;
    position: absolute;
    right: 20px;
    top: 15px;

    @media (max-width: 568px) {
      right: 5px;
      top: 5px;
    }
  }
`;

// Submit & Cancel Buttons ********************************************

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    button {
      margin: 15px 0;
      width: 100%;
    }

    button:nth-child(1) {
      display: none;
    }
  }
`;
