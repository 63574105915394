import { useMemo } from "react";

import { formatTimeInTZ } from "helpers/functions";

import {
  RateItemProps,
  ShiftDetailsItemProps,
  ShiftDetailsProps,
  TotalPayProps,
} from "./Shift.types";
import { ShiftStatusIcons } from "./ShiftHelpers";
import { ShiftCardInfoIcon } from "./ShiftIcons";

/* Shift Details  -------------------------------------------------------- */
export const ShiftDetails = ({ workerShift, status }: ShiftDetailsProps) => {
  const {
    id,
    startTime,
    endTime,
    grade,
    site,
    shiftType,
    department,
    organisation,
    note,
  } = workerShift;

  const isMod = ["Ministry of Defence", "MOD"].includes(
    organisation?.name ?? ""
  );

  const shiftDetailItems = useMemo(
    () => [
      {
        label: "GRADE",
        value: grade?.title,
      },
      {
        label: "SITES",
        value: site?.name,
      },
      {
        label: "DEPARTMENT",
        value: department?.name,
      },
      {
        label: "Organisation",
        value: organisation?.name,
      },
      {
        label: "Contact",
        value: department?.number,
      },
      {
        label: "NOTES",
        value: note ? `${note.slice(0, 120)}...` : "_",
      },
    ],
    [grade, site, department, organisation, note]
  );

  const startDay = formatTimeInTZ(startTime, "ddd, Do MMMM YYYY");
  const endDay = formatTimeInTZ(endTime, "ddd, Do MMMM YYYY");

  return (
    <div className="relative flex-[5] p-9 pt-16 text-[14px]">
      <ShiftStatusIcons left status={status ?? ""} />

      <div className="flex justify-between mb-5">
        <div>
          <p> {startDay} </p>
          {shiftType === "ONCALL" && startDay !== endDay && <p>to {endDay} </p>}
          {!isMod && (
            <p className="font-semibold">
              ({formatTimeInTZ(startTime, "HH:mm")} -{" "}
              {formatTimeInTZ(endTime, "HH:mm")})
            </p>
          )}
        </div>

        <p>
          <strong>SHIFT ID: </strong>
          {id ?? "-"}
        </p>
      </div>

      {shiftDetailItems.map(({ label, value }) => (
        <ShiftDetailsItem key={label} label={label} value={value} />
      ))}

      {department?.email && (
        <a
          className="font-semibold underline"
          href={`mailto:${department.email}`}
        >
          CONTACT DEPARTMENT
        </a>
      )}
    </div>
  );
};
/* Shift Details Item -------------------------------------------------- */

const ShiftDetailsItem = ({ label, value }: ShiftDetailsItemProps) => (
  <div className="mb-8">
    <p className="font-semibold">{label}</p>
    <p>{value ?? "-"}</p>
  </div>
);

/* Payment Details ----------------------------------------------------- */

export const PaymentDetails = ({ workerShift }: ShiftDetailsProps) => {
  const {
    actualCoreHours,
    actualCoreRate,
    actualCoreBenefit,
    actualUnsocialHours,
    actualUnsocialRate,
    actualUnsocialBenefit,
    actualUsesFiftyPercentRule,
    breakMinutes,
    actualTotalPay,
    actualTotalBenefit,
    includesBenefit,
    rateNotApplicable,
    shiftType,
    grade,
  } = workerShift;

  const filteredAgencyRates =
    workerShift.agencyWorkerRates?.filter((rate) => {
      if (workerShift.shiftType === "ONCALL") {
        return (
          rate.payElementName === "On-Call" ||
          rate.payElementName === "Call-Out"
        );
      } else {
        return rate.hours && rate.hours > 0;
      }
    }) ?? [];

  const totalAgencyPay = filteredAgencyRates
    .reduce(
      (total, rate) =>
        total +
        (rate.hours ?? 0) *
          ((workerShift.agencyRegistration?.paymentType === "paye"
            ? rate.workerPayeRate
            : rate.workerRate) ?? 0),
      0
    )
    .toFixed(2);

  return (
    <>
      {/* Rate is Applicable */}
      {!rateNotApplicable && (
        <p className="p-3 mb-3 text-lg font-semibold text-center border-b border-dotted border-grey-3">
          Shift Payment Details
        </p>
      )}

      {/* Agency Payment details */}
      {workerShift.agencyRegistration?.id && (
        <>
          {shiftType === "ONCALL" && (
            <div className="mb-6">
              <p className="m-8 text-center">
                <strong>Based on booking your agency made, </strong>
                you will earn:
              </p>
              <div className="mb-4 rounded-lg border border-grey-3 bg-grey-2 px-4 py-3 text-[13px]">
                {filteredAgencyRates.map((rate, index) => (
                  <p key={index} style={{ margin: 12, textAlign: "left" }}>
                    <strong>{rate.payElementName} hours</strong> paid at: £
                    {workerShift.agencyRegistration?.paymentType === "paye"
                      ? rate.workerPayeRate
                      : rate.workerRate}
                    /hr
                  </p>
                ))}
              </div>
              <div className="p-4 mb-6 text-center bg-white border border-l-4 rounded-lg border-grey-3 border-l-green-600">
                <p className="mb-4 font-bold">
                  Total pay for the shift will be based on your hours On-Call vs
                  Call-Out
                </p>

                <p>
                  The final rates will be calculated based on how many hours of
                  the shift you are On-Call plus how many hours you are called
                  into work.
                </p>
              </div>
            </div>
          )}
          {shiftType === "HOURLY" && (
            <div className="mb-6">
              <p className="m-8 text-center">
                <strong>Based on booking your agency made, </strong>
                you will earn:
              </p>
              <div className="mb-4 rounded-lg border border-grey-3 bg-grey-2 px-4 py-3 text-[13px]">
                {filteredAgencyRates.map((rate, index) => (
                  <p key={index} style={{ margin: 12, textAlign: "left" }}>
                    <strong>{rate.hours?.toFixed(2)}</strong> hours paid at: £
                    {workerShift.agencyRegistration?.paymentType === "paye"
                      ? rate.workerPayeRate
                      : rate.workerRate}
                    /hr
                  </p>
                ))}
              </div>
              <div className="p-4 mb-6 text-center bg-white border border-l-4 rounded-lg border-grey-3 border-l-green-600">
                <p className="mb-4 font-bold">
                  Total pay based on initial shift hours:
                </p>
                <p className="text-3xl font-bold">£{totalAgencyPay}</p>
                <p className="text-xs">
                  Total pay may change based on changes submitted during sign
                  off process
                </p>
              </div>
            </div>
          )}
        </>
      )}

      {!workerShift.agencyRegistration?.id && (
        <>
          {shiftType === "ONCALL" && (
            <div className="mb-6">
              <p className="m-8 text-center">
                <strong>Based on your grade, </strong>
                you will earn:
              </p>

              <div className="p-4 mb-6 text-center bg-white border border-l-4 rounded-lg border-grey-3 border-l-green-600">
                <p className="mb-4 font-bold">
                  Total pay for the shift will be based on your hours On-Call vs
                  Call Out
                </p>

                <p>
                  The final rates will be calculated based on how many hours of
                  the shift you are On-Call plus how many hours you are called
                  into work.
                </p>
              </div>

              <div className="p-1 border border-b-0 rounded-t-lg border-grey-3">
                <p style={{ margin: 12, textAlign: "left" }}>
                  <strong> On-Call hours</strong> paid at: £
                  {grade.onCallRate.toFixed(2)}/hr
                </p>
              </div>
              <div className="p-1 border rounded-b-lg border-grey-3 bg-grey-2">
                <p style={{ margin: 12, textAlign: "left" }}>
                  <strong>Call Out hours </strong> paid at: £
                  {grade.callOutRate.toFixed(2)}/hr
                </p>
              </div>
            </div>
          )}

          {/* Is Sessional Shift? */}
          {shiftType === "SESSIONAL" && (
            <div className="mb-2 text-center">
              <span className="font-bold">Sessional Shift</span>
              <p className="text-sm">Based on your grade,</p>
              <p>you will earn:</p>
            </div>
          )}

          {/* ShiftType is Hourly && Rates are applied */}
          {shiftType === "HOURLY" && !rateNotApplicable && (
            <div className="mb-2 text-center ">
              <p className="font-semibold">Based on your grade,</p>
              <p className="mb-4">you will earn:</p>

              {/* Calculate Core/Unsocial Rates */}
              {actualCoreHours > 0 && (
                <CalculateRatesItem
                  hours={actualCoreHours}
                  rate={actualCoreRate}
                  benifit={actualCoreBenefit}
                  hoursType="Core"
                />
              )}

              {actualUnsocialHours > 0 && (
                <CalculateRatesItem
                  hours={actualUnsocialHours}
                  rate={actualUnsocialRate}
                  benifit={actualUnsocialBenefit}
                  hoursType="Unsocial"
                />
              )}
            </div>
          )}

          {shiftType !== "ONCALL" && breakMinutes > 0 && (
            <p className="mb-4 text-xs text-center">
              The trust <strong>enforces</strong> a mandatory unpaid{" "}
              <strong>{breakMinutes} minutes of break</strong> on this shift.
            </p>
          )}

          {shiftType !== "ONCALL" && !rateNotApplicable && (
            <>
              <CalculateTotalPay
                pay={actualTotalPay}
                includesBenifit={includesBenefit}
                benifit={actualTotalBenefit}
              />

              {actualUsesFiftyPercentRule && (
                <p className="mb-4 flex">
                  <ShiftCardInfoIcon />
                  All shift hours paid at unsocial rate in accordance with 50%
                  rule
                </p>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

/* Calculate Rates ------------------------------------------------------- */

const CalculateRatesItem = ({
  hours,
  rate,
  benifit,
  hoursType,
}: RateItemProps) => {
  return (
    <div className="mb-4 rounded-lg border border-grey-3 bg-grey-2 px-4 py-3 text-[13px]">
      <p>
        <strong>{hours.toFixed(2)} </strong>
        {hoursType} Hours paid at: <strong> £{rate?.toFixed(2)}</strong>
      </p>

      <p>
        includes <strong>£{benifit?.toFixed(2)} </strong>
        annual leave<strong> (WTD)</strong>
      </p>
    </div>
  );
};

/* Calculate Total Pay -------------------------------------------------- */

const CalculateTotalPay = ({
  pay,
  includesBenifit,
  benifit,
}: TotalPayProps) => {
  return (
    <div className="flex flex-col items-center p-4 mb-4 text-xs bg-white border border-l-4 rounded-lg border-grey-3 border-l-green-6">
      <p className="">Total pay for this shift:</p>
      <p className="text-3xl font-bold">£{pay?.toFixed(2)}</p>

      {includesBenifit && (
        <>
          <p>Above pay includes</p>
          <p>
            <strong>£{benifit?.toFixed(2)}</strong> annual leave accrual{" "}
            <strong>(WTD)</strong>
          </p>
        </>
      )}
    </div>
  );
};
