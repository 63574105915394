import { useEffect, useRef } from "react";
import { DayPickerInputProps } from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";
import styled from "styled-components";

import {
  BLUE,
  FormInputGroup,
  InputDate,
} from "@patchworkhealth/web-components";

interface Props {
  datePickerConfig: DayPickerInputProps;
  dataTestId?: string;
  leftOffset?: number;
}

export const DatePicker = ({
  datePickerConfig,
  leftOffset,
  dataTestId,
}: Props) => {
  const datePickerRef = useRef<DayPickerInput | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (!wrapperRef.current?.contains(e.target as Node)) {
      datePickerRef.current?.hideDayPicker();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div>
      <FormInputGroup>
        <FormInputGroup.Text
          style={{ cursor: "pointer" }}
          onClick={() => datePickerRef?.current?.showDayPicker()}
        >
          <InputDate />
        </FormInputGroup.Text>
        <DayPickerStyleWrapper
          ref={wrapperRef}
          leftOffset={leftOffset}
          data-testid={dataTestId}
        >
          <DayPickerInput
            {...datePickerConfig}
            ref={datePickerRef}
            onDayChange={(day, dayModifiers, dayPickerInput) => {
              if (datePickerRef.current) {
                datePickerRef.current?.hideDayPicker();
              }

              datePickerConfig.onDayChange?.(day, dayModifiers, dayPickerInput);
            }}
          />
        </DayPickerStyleWrapper>
      </FormInputGroup>
    </div>
  );
};

const DayPickerStyleWrapper = styled.div<{ leftOffset: number | undefined }>`
  width: 100%;

  .DayPickerInput-Overlay {
    border-radius: 8px;
    left: ${({ leftOffset }) => `${leftOffset}px` || "0"};
    margin-top: 10px;
    text-align: center;
    width: 275px;
  }

  .DayPicker-Day--selected {
    background-color: ${BLUE.one} !important;
    color: black !important;
  }
`;
