import styled from "styled-components";

import { GLOBAL, GREY } from "@patchworkhealth/web-components";

export const ShiftDetailsContainer = styled.div`
  background: ${GLOBAL.white};
  height: fit-content;
  border: 1px solid ${GREY.two};
  border-radius: 8px;
  padding: 30px 27px 24px 27px;
  display: flex;
  min-width: 350px;
`;

export const Header = styled.p`
  font-weight: 500;
  color: ${GREY.ten};
  font-size: 14px;
  margin-bottom: 5px;
`;

export const TextItem = styled.p`
  color: ${GREY.ten};
  font-size: 13px !important;
  margin-bottom: 0;
  line-height: normal;
`;
export const TextItemSmall = styled.p`
  text-transform: uppercase;
  font-size: 10px !important;
  color: ${GREY.ten};
  font-weight: 600;
  margin-bottom: 2px;
`;

export const SvgIcon = styled.div`
  svg {
    height: 50px;
    width: 50px;
    margin-left: 40px;
  }
`;
