import { Dispatch, FormEventHandler, SetStateAction } from "react";
import { Form } from "react-bootstrap";

import {
  AuthPasswordEye,
  Button,
  FormGroup,
  FormInput,
  FormLabel,
  GLOBAL,
  PasswordCriteriaList,
  RBBLUE,
} from "@patchworkhealth/web-components";

import { Divider, FormContainer, LogoWrapper } from "components/Style";
import { LogoIcon } from "icons/login";

type Props = {
  onSubmit: FormEventHandler<HTMLFormElement>;
  loading: boolean;
  setPassword: (password: string) => void;
  isPasswordHidden: boolean;
  setIsPasswordHidden: Dispatch<SetStateAction<boolean>>;
  setNiNumber: (niNumber: string) => void;
  email: string;
};

export const VmsLogin = ({
  onSubmit,
  loading,
  setPassword,
  isPasswordHidden,
  setIsPasswordHidden,
  setNiNumber,
  email,
}: Props) => {
  return (
    <FormContainer data-testid="test__login">
      <LogoWrapper>
        <LogoIcon />
      </LogoWrapper>
      <Form autoComplete="off" data-testid="test__form" onSubmit={onSubmit}>
        <FormGroup>
          <FormLabel style={{ color: GLOBAL.white }}>Email Address</FormLabel>
          <FormInput
            autoFocus
            value={email}
            data-testid="test__login__email"
            type="email"
            name="email"
            required
            placeholder="Enter email address"
          />
        </FormGroup>

        <Divider top="10" bottom="10" line="none" />

        <FormGroup>
          <FormLabel style={{ color: GLOBAL.white }}>NI Number</FormLabel>
          <FormInput
            autoFocus
            data-testid="test__login__niNumber"
            onChange={(e) => setNiNumber(e.target.value)}
            type="text"
            name="email"
            required
            placeholder="Enter NI Number"
          />
        </FormGroup>

        <Divider top="10" bottom="10" line="none" />

        <FormGroup>
          <FormLabel style={{ color: GLOBAL.white }}>Create Password</FormLabel>
          <FormInput
            data-testid="test__login__password"
            onChange={(e) => setPassword(e.target.value)}
            type={isPasswordHidden ? "password" : "text"}
            name="password"
            required
            placeholder="Enter password"
            autoComplete="off"
          />

          <AuthPasswordEye
            data-testid="test__login__show"
            onClick={() => setIsPasswordHidden((s) => !s)}
          />
        </FormGroup>

        <PasswordCriteriaList />

        <Divider top="10" bottom="30" line="none" />
        <Divider top="40" bottom="29" line="solid" />
        <Button
          variant="success"
          loading={loading}
          type="submit"
          style={{
            width: "100%",
            fontSize: "14px",
            fontWeight: 500,
            color: RBBLUE.five,
          }}
        >
          Sign Up
        </Button>
      </Form>
    </FormContainer>
  );
};
