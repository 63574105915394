import { ReactNode } from "react";
import styled from "styled-components";

import { GREY } from "@patchworkhealth/web-components";

import { ExceptionReportPages } from "components/ExceptionReports/ExceptionReports.types";
import Header from "components/Header";
import { ChevronIcon } from "components/Navigation/NavIcons";

interface Props {
  headerValueText: string;
  returnHome: (page: ExceptionReportPages) => void;
  navigationText: string;
  children: ReactNode;
  page: ExceptionReportPages;
}

export const ExceptionReportLayout = ({
  headerValueText,
  navigationText,
  returnHome,
  children,
  page,
}: Props) => {
  return (
    <Container>
      <Header
        headerValueText={headerValueText}
        setShowSidebar={() => null}
        showSidebar={false}
      />
      <NavigationButtonContainer>
        <NavigationButton
          data-testid="back-to-form-button"
          onClick={() => returnHome(page)}
        >
          <ChevronIcon /> <span>{navigationText}</span>
        </NavigationButton>
      </NavigationButtonContainer>
      {children}
    </Container>
  );
};

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const NavigationButtonContainer = styled.div`
  gap: 15px;
  margin-top: 90px;
  width: 90vw;
`;

const NavigationButton = styled.button`
  align-items: center;
  color: ${GREY.five};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  gap: 10px;
`;
