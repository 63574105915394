import { useContext, useState } from "react";
import toast from "react-hot-toast";
import dayjs from "dayjs";

import {
  DefaultButton,
  FormGroup,
  FormInput,
  FormToggleButtons,
  Loading,
} from "@patchworkhealth/web-components";

import { useSupervisors } from "components/ExceptionReports/components/SupervisorsList/useSupervisors";
import { CategoryReasonFormSelect } from "components/ExceptionReports/ExceptionReportForms/components/CategoryReasonFormSelect";
import { CompensationToggles } from "components/ExceptionReports/ExceptionReportForms/components/CompensationToggles";
import { DurationPicker } from "components/ExceptionReports/ExceptionReportForms/components/DurationPicker";
import { NoSupervisorPrompt } from "components/ExceptionReports/ExceptionReportForms/components/NoSupervisorPrompt";
import {
  onInputChange,
  onToggleChange,
  updateExceptionReportTableCache,
} from "components/ExceptionReports/ExceptionReportForms/ExceptionReportForms.helpers";
import {
  useRosteringExceptionReportCreateMutation,
  useRosteringExceptionReportUpdateMutation,
} from "components/ExceptionReports/ExceptionReportForms/ManualExceptionReportForm/__generated__/ManualExceptionReportForm.generated";
import {
  emptyFormInput,
  getCompensationValue,
  getInitialFormInput,
  getNoYesValue,
  isFollowingDay,
  validateRequiredFields,
} from "components/ExceptionReports/ExceptionReportForms/ManualExceptionReportForm/ManualExceptionReportForm.helpers";
import { ExceptionReportInputType } from "components/ExceptionReports/ExceptionReportForms/ManualExceptionReportForm/ManualExceptionReportForm.types";
import {
  ExceptionReportPages,
  WorkerLatestShiftType,
} from "components/ExceptionReports/ExceptionReports.types";
import { DatePicker } from "components/Shared/DatePicker";
import { TimeInput } from "components/Shared/TimeInput";
import { AppContext } from "context/AppContext";
import { formatError } from "helpers/formatError";
import { FormError } from "helpers/types";

import {
  useRosteringExceptionReportQuery,
  useRosteringExceptionReportReasonCategoriesQuery,
} from "../../__generated__/ExceptionReports.generated";
import * as FormStyled from "./../ExceptionReportForms.styled";
import * as Styled from "./ManualExceptionReportForm.styled";

interface Props {
  returnHome: () => void;
  exceptionReportId: string | undefined;
  setExceptionReportId: (id: string) => void;
  setPage: (page: ExceptionReportPages) => void;
  setShiftFlow: (shiftFlow: boolean) => void;
  workerMostRecentShift: WorkerLatestShiftType;
}

export const ManualExceptionReportForm = ({
  returnHome,
  exceptionReportId,
  setExceptionReportId,
  setPage,
  setShiftFlow,
  workerMostRecentShift,
}: Props) => {
  const { user } = useContext(AppContext);
  const [exceptionReportInput, setExceptionReportInput] =
    useState<ExceptionReportInputType>(emptyFormInput);
  const [errors, setErrors] =
    useState<FormError<ExceptionReportInputType> | null>(null);

  const { hasSupervisors, hasSupervisorsForCategory } = useSupervisors(
    exceptionReportInput.rosteringExceptionReportCategory?.name,
    "tier1"
  );

  useRosteringExceptionReportQuery(
    exceptionReportId
      ? {
          variables: { reportId: +exceptionReportId },
          onCompleted: (data) => {
            setExceptionReportInput(
              getInitialFormInput(data.rosteringExceptionReport)
            );
          },
        }
      : { skip: true }
  );

  const { data: categoriesReasonsData, loading } =
    useRosteringExceptionReportReasonCategoriesQuery();

  const [rosteringExceptionReportCreate] =
    useRosteringExceptionReportCreateMutation();

  const [rosteringExceptionReportUpdate] =
    useRosteringExceptionReportUpdateMutation();

  const createExceptionReport = async () => {
    const { rosteringExceptionReportReason, ...rest } = exceptionReportInput;

    const { data: result } = await rosteringExceptionReportCreate({
      variables: {
        ...rest,
        rosteringExceptionReportReasonIds: (
          rosteringExceptionReportReason ?? []
        ).map((reason) => +reason.id),
        requestedCompensation: getCompensationValue(
          exceptionReportInput.requestedCompensation
        ),
        differenceInWorkedHours: getNoYesValue(rest.differenceInWorkedHours),
        patientSafetyImpacted: getNoYesValue(rest.patientSafetyImpacted),
        rosteringOrganisationRegistrationId: Number(
          user?.rosteringOrganisationRegistrations?.[0].id
        ),
      },

      update: updateExceptionReportTableCache(
        Number(user?.rosteringOrganisations?.[0]?.id)
      ),
    });

    if (result?.rosteringExceptionReportCreate?.errors?.length) {
      toast.error(formatError(result?.rosteringExceptionReportCreate.errors));
      return null;
    }

    return result;
  };

  const updateExceptionReport = async () => {
    const { rosteringExceptionReportReason, ...rest } = exceptionReportInput;

    const { data: result } = await rosteringExceptionReportUpdate({
      variables: {
        ...rest,
        rosteringExceptionReportReasonIds: (
          rosteringExceptionReportReason ?? []
        ).map((reason) => +reason.id),
        requestedCompensation:
          exceptionReportInput?.rosteringExceptionReportCategory?.id === "3"
            ? null
            : getCompensationValue(exceptionReportInput.requestedCompensation),
        differenceInWorkedHours: getNoYesValue(rest.differenceInWorkedHours),
        patientSafetyImpacted: getNoYesValue(rest.patientSafetyImpacted),
        rosteringExceptionReportUpdateId: exceptionReportInput?.id
          ? Number(exceptionReportInput.id)
          : Number(exceptionReportId),
        status: "draft",
      },
    });

    if (result?.rosteringExceptionReportUpdate?.errors?.length) {
      toast.error(formatError(result.rosteringExceptionReportUpdate.errors));
      return null;
    }

    return result;
  };

  const onSaveAsDraft = async () => {
    try {
      const result = exceptionReportId
        ? await updateExceptionReport()
        : await createExceptionReport();
      if (result) {
        toast.success("Exception report added as a draft!");

        returnHome();
      }
    } catch (e) {
      toast.error("An error occurred");
    }
  };

  const onConfirm = async () => {
    try {
      if (validateRequiredFields(exceptionReportInput, setErrors)) {
        if (exceptionReportId) {
          const result = await updateExceptionReport();

          if (
            result?.rosteringExceptionReportUpdate?.rosteringExceptionReport?.id
          )
            setExceptionReportId(
              result?.rosteringExceptionReportUpdate.rosteringExceptionReport.id
            );

          setPage(ExceptionReportPages.ReviewPage);
        } else {
          const result = await createExceptionReport();

          if (
            result?.rosteringExceptionReportCreate?.rosteringExceptionReport?.id
          )
            setExceptionReportId(
              result?.rosteringExceptionReportCreate.rosteringExceptionReport.id
            );

          setPage(ExceptionReportPages.ReviewPage);
        }
      }
    } catch (e) {
      toast.error("An error occurred");
    }
  };

  const isSupervisorForCategory = hasSupervisorsForCategory;

  const isSupervisor = hasSupervisors;

  if (loading)
    return (
      <FormStyled.Container>
        <Loading style={{ height: "500px" }} />
      </FormStyled.Container>
    );

  return (
    <div>
      <FormStyled.Container>
        <FormStyled.Header data-testid="exception_report_form_header">
          Tell us about the shift
        </FormStyled.Header>
        <NoSupervisorPrompt isSupervisor={isSupervisor} />
        {workerMostRecentShift && !exceptionReportId && (
          <DefaultButton
            type="white"
            onClick={() => {
              setPage(ExceptionReportPages.ShiftCalendar);
              setShiftFlow(true);
            }}
            text="Select a shift instead"
            style={{ width: "fit-content" }}
          />
        )}
        <FormGroup style={{ width: "320px" }}>
          <FormStyled.RequiredLabel required>
            What was the roster and/or location of the shift?
          </FormStyled.RequiredLabel>
          <FormInput
            name="location"
            placeholder="Enter the roster and/or location of the shift"
            type="text"
            required
            onChange={onInputChange(setErrors, setExceptionReportInput)}
            value={exceptionReportInput.location}
          />
          {errors?.location && (
            <FormStyled.ErrorLabel data-testid="error_name">
              {errors.location}
            </FormStyled.ErrorLabel>
          )}
        </FormGroup>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "0.6fr 0.2fr 0.2fr",
          }}
        >
          <FormGroup
            data-testid="shift_date"
            style={{ marginRight: "12px", gridColumn: "1" }}
          >
            <FormStyled.RequiredLabel required>
              What date was the shift on?
            </FormStyled.RequiredLabel>
            <DatePicker
              datePickerConfig={{
                formatDate: (date) => dayjs(date).format("DD/MM/YYYY"),
                value: exceptionReportInput.shiftDate
                  ? dayjs(exceptionReportInput.shiftDate).format("DD/MM/YYYY")
                  : undefined,
                parseDate: (value, format) => {
                  const parsed = dayjs(value, format).toDate();
                  if (dayjs(parsed).isValid()) {
                    return parsed;
                  }
                },
                onDayChange: (day) => {
                  setErrors(null);
                  setExceptionReportInput((prev) => ({
                    ...prev,
                    shiftDate: day,
                  }));
                },
                placeholder: "DD/MM/YYYY",
                dayPickerProps: { disabledDays: { after: new Date() } },
              }}
            />
            {errors?.shiftDate && (
              <FormStyled.ErrorLabel data-testid="error_date">
                {errors.shiftDate}
              </FormStyled.ErrorLabel>
            )}
          </FormGroup>

          <FormGroup style={{ marginRight: "12px", gridColumn: "2" }}>
            <FormStyled.RequiredLabel required>
              Original Start Time
            </FormStyled.RequiredLabel>
            <TimeInput
              testId="shift_scheduled_start_time"
              name="shiftScheduledStartTime"
              onChange={onInputChange(setErrors, setExceptionReportInput)}
              value={exceptionReportInput.shiftScheduledStartTime}
            />
          </FormGroup>

          <FormGroup style={{ marginRight: "12px", gridColumn: "3" }}>
            <FormStyled.RequiredLabel required>
              Original End Time
            </FormStyled.RequiredLabel>
            <TimeInput
              testId="shift_scheduled_end_time"
              name="shiftScheduledEndTime"
              onChange={onInputChange(setErrors, setExceptionReportInput)}
              value={exceptionReportInput.shiftScheduledEndTime}
            />
            {isFollowingDay(
              exceptionReportInput.shiftScheduledStartTime,
              exceptionReportInput.shiftScheduledEndTime
            ) && <Styled.FollowingDay>(Following day)</Styled.FollowingDay>}
          </FormGroup>

          <div style={{ gridColumn: "2" }}>
            {errors?.shiftScheduledStartTime && (
              <FormStyled.ErrorLabel data-testid="error_sch_start_time">
                {errors.shiftScheduledStartTime}
              </FormStyled.ErrorLabel>
            )}
          </div>
          <div style={{ gridColumn: "3" }}>
            {errors?.shiftScheduledEndTime && (
              <FormStyled.ErrorLabel data-testid="error_sch_end_time">
                {errors.shiftScheduledEndTime}
              </FormStyled.ErrorLabel>
            )}
          </div>
        </div>

        <FormGroup data-testid="orignal_break">
          <FormStyled.RequiredLabel>
            How long was your break time meant to be?
          </FormStyled.RequiredLabel>
          <DurationPicker
            property="breakScheduledTime"
            setErrors={setErrors}
            setInput={setExceptionReportInput}
            value={exceptionReportInput.breakScheduledTime ?? ""}
          />

          {errors?.breakScheduledTime && (
            <FormStyled.ErrorLabel data-testid="error_break_sch">
              {errors.breakScheduledTime}
            </FormStyled.ErrorLabel>
          )}
        </FormGroup>

        <Styled.FormBreakLine />

        <FormStyled.Header>
          Tell us about what happened on the shift
        </FormStyled.Header>

        <CategoryReasonFormSelect
          exceptionReportCategoryValue={
            exceptionReportInput.rosteringExceptionReportCategory
          }
          exceptionReportReasonValue={
            exceptionReportInput.rosteringExceptionReportReason
          }
          rosteringExceptionReportReasonCategories={
            categoriesReasonsData?.rosteringExceptionReportReasonCategories
          }
          setInput={setExceptionReportInput}
          setErrors={setErrors}
          errors={errors}
          isSupervisorForCategory={isSupervisorForCategory}
          isSupervisor={isSupervisor}
        />

        <FormGroup>
          <FormStyled.RequiredLabel>Details</FormStyled.RequiredLabel>
          <FormInput
            as="textarea"
            name="details"
            placeholder="Please explain what happened in your shift as much detail as you can"
            style={{ maxWidth: "584px", minHeight: "160px" }}
            onChange={onInputChange(setErrors, setExceptionReportInput)}
            value={exceptionReportInput.details}
          />
        </FormGroup>

        <div style={{ gap: "20px" }}>
          <FormGroup>
            <FormStyled.RequiredLabel required>
              Were the actual hours you worked different to the original times?
            </FormStyled.RequiredLabel>

            <div style={{ width: "300px" }}>
              <FormStyled.ToggleWrapper>
                <FormToggleButtons
                  name="differenceInWorkedHours"
                  input={+exceptionReportInput.differenceInWorkedHours}
                  text={["No", "Yes"]}
                  action={onToggleChange(setErrors, setExceptionReportInput)}
                />
              </FormStyled.ToggleWrapper>
              {errors?.differenceInWorkedHours && (
                <FormStyled.ErrorLabel data-testid="error_diff_work_hrs">
                  {errors.differenceInWorkedHours}
                </FormStyled.ErrorLabel>
              )}
            </div>
          </FormGroup>

          {exceptionReportInput.differenceInWorkedHours === 1 && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", gap: "10px" }}>
                <FormGroup>
                  <FormStyled.RequiredLabel required>
                    Actual Start Time
                  </FormStyled.RequiredLabel>
                  <TimeInput
                    testId="shift_actual_start_time"
                    name="shiftActualStartTime"
                    onChange={onInputChange(setErrors, setExceptionReportInput)}
                    value={exceptionReportInput.shiftActualStartTime}
                  />
                </FormGroup>

                <FormGroup>
                  <FormStyled.RequiredLabel required>
                    Actual End Time
                  </FormStyled.RequiredLabel>
                  <TimeInput
                    testId="shift_actual_end_time"
                    name="shiftActualEndTime"
                    onChange={onInputChange(setErrors, setExceptionReportInput)}
                    value={exceptionReportInput.shiftActualEndTime}
                  />
                </FormGroup>

                <FormGroup data-testid="actual_break">
                  <FormStyled.RequiredLabel>
                    Actual Break Time
                  </FormStyled.RequiredLabel>
                  <DurationPicker
                    property="breakActualTime"
                    setErrors={setErrors}
                    setInput={setExceptionReportInput}
                    value={exceptionReportInput.breakActualTime ?? ""}
                  />
                </FormGroup>
              </div>
              {(errors?.shiftActualStartTime ||
                errors?.shiftActualEndTime ||
                errors?.breakActualTime) && (
                <FormStyled.ErrorLabel data-testid="error_actual_time">
                  {errors.shiftActualStartTime ||
                    errors.shiftActualEndTime ||
                    errors.breakActualTime}
                </FormStyled.ErrorLabel>
              )}
            </div>
          )}
        </div>

        <FormGroup>
          <div style={{ width: "300px" }}>
            <FormStyled.RequiredLabel required>
              Did this impact patient safety?
            </FormStyled.RequiredLabel>
            <FormStyled.ToggleWrapper>
              <FormToggleButtons
                name="patientSafetyImpacted"
                input={exceptionReportInput.patientSafetyImpacted}
                text={["No", "Yes"]}
                action={onToggleChange(setErrors, setExceptionReportInput)}
              />
            </FormStyled.ToggleWrapper>
            {errors?.patientSafetyImpacted && (
              <FormStyled.ErrorLabel data-testid="error_safety_impacted">
                {errors.patientSafetyImpacted}
              </FormStyled.ErrorLabel>
            )}
          </div>
        </FormGroup>

        <CompensationToggles
          onToggleChange={onToggleChange(setErrors, setExceptionReportInput)}
          exceptionReportCategory={
            exceptionReportInput?.rosteringExceptionReportCategory
          }
          exceptionReportRequestedCompensation={
            exceptionReportInput.requestedCompensation
          }
          errors={errors}
        />

        <FormGroup style={{ width: "320px" }}>
          <FormStyled.RequiredLabel>
            Who was the most senior clinician? (Optional)
          </FormStyled.RequiredLabel>
          <FormInput
            name="mostSeniorClinician"
            placeholder="Enter a clinician’s name"
            type="text"
            required
            onChange={onInputChange(setErrors, setExceptionReportInput)}
            value={exceptionReportInput.mostSeniorClinician}
          />
        </FormGroup>
      </FormStyled.Container>
      <Styled.ButtonsContainer>
        <DefaultButton
          text="Save for Later"
          onClick={onSaveAsDraft}
          type="white"
        />

        <FormStyled.ConfirmButton
          text="Confirm"
          onClick={onConfirm}
          disabled={!isSupervisor || !isSupervisorForCategory}
        />
      </Styled.ButtonsContainer>
    </div>
  );
};
