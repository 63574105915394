export const ChevronDownIcon = () => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9701 5.87348C14.2468 6.1501 14.2468 6.59859 13.9701 6.87522L9.72015 11.1252C9.44353 11.4018 8.99503 11.4018 8.71841 11.1252L4.46841 6.87522C4.19179 6.59859 4.19179 6.1501 4.46841 5.87348C4.74503 5.59686 5.19353 5.59686 5.47015 5.87348L9.21928 9.62261L12.9684 5.87348C13.245 5.59686 13.6935 5.59686 13.9701 5.87348Z"
      fill="#7D8793"
    />
  </svg>
);
