import { CSSProperties, PropsWithChildren } from "react";
import { RosteringEventTypeEnum } from "baseCODEGEN";

import { EventsContainer } from "components/TeamRota/TeamRotaTable/TeamRotaTable";

import { RosteringShift, ShiftType } from "../RosteringShift";

type RosteringShift = {
  id: string;
  scheduleable?: {
    id: string;
    organisationActivity?: {
      id: number;
      name: string;
    } | null;
    shiftType?: {
      id: string;
      name: string;
    } | null;
  } | null;
  eventType: RosteringEventTypeEnum;
  startsAt: string;
  endsAt: string;
};

type Props = {
  shifts: RosteringShift[];
  isMultipleShifts: boolean;
  onShiftClick: (event: RosteringShift) => void;
  minShiftHeight?: number | `${string}px`;
  styles?: CSSProperties;
  activitiesLabelForShift: (id: number, name: string | undefined) => string;
};

export const RosteringShiftList = ({
  shifts,
  isMultipleShifts,
  onShiftClick,
  styles,
  minShiftHeight,
  children,
  activitiesLabelForShift,
}: PropsWithChildren<Props>) => {
  return (
    <EventsContainer style={styles}>
      {shifts.map((shift) => (
        <RosteringShift
          key={shift.id}
          shiftType={shift.scheduleable?.shiftType?.name as ShiftType}
          activitiesLabel={activitiesLabelForShift(
            +shift.id,
            shift.scheduleable?.organisationActivity?.name
          )}
          eventType={shift.eventType}
          startsAt={shift.startsAt}
          endsAt={shift.endsAt}
          isMultiple={isMultipleShifts}
          onClick={() => onShiftClick(shift)}
          minShiftHeight={minShiftHeight}
        />
      ))}
      {children}
    </EventsContainer>
  );
};
