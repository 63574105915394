import { useContext } from "react";
import styled from "styled-components";

import { FormSelect, GREY } from "@patchworkhealth/web-components";

import { LeaveManagementPageType } from "components/LeaveManagement/LeaveManagement.types";
import { AppContext } from "context/AppContext";
import { RosteringOrganisationRegistrationsType } from "context/AppContext.types";

interface Props {
  page: LeaveManagementPageType;
  setPage: (page: LeaveManagementPageType) => void;
  chosenOrg: RosteringOrganisationRegistrationsType[number] | null | undefined;
  setChosenOrg: (
    rosteringOrgReg: RosteringOrganisationRegistrationsType[number]
  ) => void;
}
export const LeaveHeader = ({
  page,
  setPage,
  chosenOrg,
  setChosenOrg,
}: Props) => {
  const { user } = useContext(AppContext);

  return (
    <ManagementHeader>
      <div>
        <LeaveTabButton
          page="request-leave"
          activePage={page}
          setActivePage={setPage}
          text="Request Leave"
        />
        <LeaveTabButton
          page="leave-record"
          activePage={page}
          setActivePage={setPage}
          text="My Leave Record"
        />
      </div>

      {user?.rosteringOrganisationRegistrations &&
        user.rosteringOrganisationRegistrations.length > 1 && (
          <DropdownWrapper>
            <FormSelect
              onChange={(event) => {
                if (event) setChosenOrg(event);
              }}
              options={user.rosteringOrganisationRegistrations}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.organisation?.label ?? ""}
              value={chosenOrg}
            />
          </DropdownWrapper>
        )}
    </ManagementHeader>
  );
};

const LeaveTabButton = ({
  page,
  setActivePage,
  activePage,
  text,
}: {
  page: LeaveManagementPageType;
  setActivePage: (page: LeaveManagementPageType) => void;
  activePage: LeaveManagementPageType;
  text: string;
}) => (
  <button
    onClick={() => setActivePage(page)}
    className={activePage === page ? "active" : ""}
  >
    {text}
  </button>
);

const ManagementHeader = styled.div`
  margin-bottom: 30px;
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    border-radius: 2px;
    color: ${GREY.ten};
    font-size: 14px;
    margin-right: 25px;
    padding: 6px 0;

    &.active {
      border-bottom: 2px solid ${GREY.ten};
      font-weight: bold;
    }
  }
`;

const DropdownWrapper = styled.div`
  width: 250px;
`;
