import { AppContextValueFunctions, AppState } from "context/AppContext.types";

export const initialState: AppState = {
  organisations: [],
  user: null,
  toggleModal: false,
  approvedOrgs: [],
  pendingOrgs: [],
  isMobile: false,
  mobileVersion: false,
  accountActivated: false,
  productType: null,
  shiftType: null,
  loadingWorker: false,
  specialities: [],
  grades: [],
  unreadNotificationLength: 0,
  notifications: [],
  acceptNotification: false,
  staffGroups: [],
  timesheets: 0,
  agencyRegistrationId: null,
  agencyOnboarding: false,
  confirmAgencyInfo: false,
  ediReferenceData: {},
  mobileModal: false,
  expenses: 0,
};

export const initialFunctions: AppContextValueFunctions = {
  setUser: () => {},
  setProductType: () => {},
  setAgencyOnboarding: () => {},
  setAgencyRegistrationId: () => {},
  acceptNotificationFunc: () => {},
  setNotifications: () => {},
  setConfirmAgencyInfo: () => {},
  setIsMobile: () => {},
  setUnreadNotificationLength: () => {},
  setShiftType: () => {},
  setTimesheets: () => {},
  startApp: () => {},
  toggleModalFunction: () => {},
  fetchUser: null,
  fetchNotifications: null,
  setMobileModal: () => {},
  setExpenses: () => {},
};
