import { useContext, useState } from "react";
import toast from "react-hot-toast";

import Layout from "components/Layout";
import {
  TimesheetCardFragment,
  useShiftsToSignOffHubQuery,
} from "components/Timesheets/__generated__/Timesheets.generated";
import TimesheetsCreate from "components/Timesheets/TimesheetCreate";
import { InitInputs } from "components/Timesheets/TimesheetHelpers";
import TimesheetAttention from "components/Timesheets/TimesheetsAttention";
import TimesheetsFinalised from "components/Timesheets/TimesheetsFinalised";
import TimesheetsHeader from "components/Timesheets/TimesheetsHeader";
import TimesheetsModal from "components/Timesheets/TimesheetsModal";
import TimesheetsModModal from "components/Timesheets/TimesheetsModModal";
import TimesheetsOnCallModal from "components/Timesheets/TimesheetsOnCallModal";
import TimesheetsProgress from "components/Timesheets/TimesheetsProgress";
import { BackToTopButton } from "components/UI/BackToTop";
import { AppContext } from "context/AppContext";

const initInputs: InitInputs = {
  page: "Needs Attention",
  needsAttention: 0,
  shifts: [],
  modalShifts: [],
  modalShow: false,
  modalModShow: false,
  onCallModalShow: false,
};

const TimeSheetsPage = () => {
  /* State --------------------------------------------- */

  const store = useContext(AppContext);
  const [inputs, setInputs] = useState(initInputs);
  const { page } = inputs;

  /* GraphQL --------------------------------------------- */

  const { refetch, loading: loadingShifts } = useShiftsToSignOffHubQuery({
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data) {
        const combinedShifts = [
          ...data.shiftsToSignOffHub,
          ...data.shiftsToSignOffHubOrApp,
        ];

        const newShifts = formatTimesheets(combinedShifts);
        const needsAttention = combinedShifts.length;

        setInputs((prevInputs) => ({
          ...prevInputs,
          needsAttention,
          shifts: newShifts,
        }));
        store.setTimesheets(needsAttention);
      }
    },
  });

  /* Format Timesheets --------------------------------------------- */

  const formatTimesheets = (data: TimesheetCardFragment[]) => {
    const newArray: { department: string; shifts: TimesheetCardFragment[] }[] =
      [];
    const onCallArray: {
      department: string;
      shifts: TimesheetCardFragment[];
    }[] = [];

    /* loop through each shift and add to the respective array */
    data.forEach((shift) => {
      const department = shift.department.name ?? "No Department";
      const { shiftType } = shift;

      if (shiftType === "ONCALL") {
        const existingOnCallDepartment = onCallArray.find(
          (obj) => obj.department === department
        );

        if (existingOnCallDepartment) {
          existingOnCallDepartment.shifts.push(shift);
          return;
        }
        onCallArray.push({
          department,
          shifts: [shift],
        });
        return;
      }

      const existingDepartment = newArray.find(
        (obj) => obj.department === department
      );

      if (existingDepartment) {
        existingDepartment.shifts.push(shift);
        return;
      }
      newArray.push({
        department,
        shifts: [shift],
      });
    });

    return [...newArray, ...onCallArray];
  };

  /* Functions --------------------------------------------- */

  const openModal = (shifts: TimesheetCardFragment[]) => {
    // TODO WE need a refetch after signing contract to update the shifts contract status

    // Check if any shifts need a DE contract signing, if so block the modal
    const needsDEContract = shifts.some((shift) => {
      return shift?.requiresDeContractToSign;
    });
    // console.log("needsDEContract", needsDEContract);

    if (needsDEContract) {
      toast.error(
        "You need to sign a DE contract before you can sign off this shift"
      );
      return;
    }

    // Check if org is MOD
    const isMod =
      ["Ministry of Defence", "MOD"].includes(
        shifts[0].organisation.name ?? ""
      ) && shifts[0].shiftType !== "ONCALL";

    const modalShifts = shifts.map((shift) => {
      return {
        ...shift,
        startTime: isMod ? "" : shift.startTime,
        endTime: isMod ? "" : shift.endTime,
        startDifference: 0,
        endDifference: 0,
        originalStartTime: shift.startTime,
        originalEndTime: shift.endTime,
        originalBreakMinutes: shift.breakMinutes,
        showReason: false,
        reason: "",
        checkbox: false,
        timeframes: [],
        modStartDate: shift.startTime,
        modEndDate: shift.endTime,
        modBreakMinutes: isMod ? 0 : shift.breakMinutes,
      };
    });

    setInputs({
      ...inputs,
      modalShifts: modalShifts.slice(0),
      onCallModalShow: shifts[0].shiftType === "ONCALL",
      modalShow: !isMod && shifts[0].shiftType !== "ONCALL",
      modalModShow: isMod && shifts[0].shiftType !== "ONCALL",
    });
  };

  const closeModal = () => {
    setInputs({
      ...inputs,
      modalShow: false,
      onCallModalShow: false,
      modalModShow: false,
    });
  };

  const setPage = (e: string) => {
    setInputs({
      ...inputs,
      page: e,
    });
  };

  /* Render --------------------------------------------- */

  if (page === "Create") {
    return (
      <Layout headerValueText={"Timesheets"} navigation={undefined}>
        <button
          className="mt-6 flex text-black-3"
          onClick={() => {
            setPage("Needs Attention");
          }}
        >
          <svg
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.70711 0.292893C7.31658 -0.0976311 6.68342 -0.0976311 6.29289 0.292893L0.292893 6.29289C-0.0976315 6.68342 -0.0976315 7.31658 0.292893 7.70711L6.29289 13.7071C6.68342 14.0976 7.31658 14.0976 7.70711 13.7071C8.09763 13.3166 8.09763 12.6834 7.70711 12.2929L2.41421 7L7.70711 1.70711C8.09763 1.31658 8.09763 0.683417 7.70711 0.292893Z"
              fill="#7C7D80"
              className="mr-2"
            />
          </svg>
          <span className="ml-2"> Back</span>
        </button>
        <TimesheetsCreate />
      </Layout>
    );
  }

  return (
    <Layout headerValueText={"Timesheets"} navigation={undefined}>
      <TimesheetsHeader
        isMobile={store.isMobile}
        page={page}
        setPage={setPage}
        requiresSignoff={inputs.needsAttention}
        refetch={refetch}
      />

      {page === "In Progress" && <TimesheetsProgress />}
      {page === "Finalised" && <TimesheetsFinalised />}
      {page === "Needs Attention" && (
        <TimesheetAttention
          shifts={inputs.shifts}
          loadingShifts={loadingShifts}
          openModal={openModal}
        />
      )}
      {inputs.modalShow && (
        <TimesheetsModal
          modalShifts={inputs.modalShifts}
          onHide={closeModal}
          refetchSignoff={refetch}
        />
      )}

      {inputs.modalModShow && (
        <TimesheetsModModal
          modalShifts={inputs.modalShifts}
          onHide={closeModal}
          refetchSignoff={refetch}
        />
      )}

      {inputs.onCallModalShow && (
        <TimesheetsOnCallModal
          modalShifts={inputs.modalShifts}
          onHide={closeModal}
          refetchSignoff={refetch}
        />
      )}

      <BackToTopButton />
    </Layout>
  );
};

export default TimeSheetsPage;
