export const FolderPaperIcon = () => (
  <svg
    width="146"
    height="146"
    viewBox="0 0 146 146"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2562_14705)">
      <path
        d="M73 146C113.317 146 146 113.317 146 73C146 32.6832 113.317 0 73 0C32.6832 0 0 32.6832 0 73C0 113.317 32.6832 146 73 146Z"
        fill="#E4E7EB"
      />
      <path
        d="M102.566 84.2002H46.0537C45.4911 84.2015 44.9407 83.9881 44.4708 83.5867L44.4336 83.555V33.5211C44.4344 32.5837 44.7218 31.685 45.2326 31.0221C45.7435 30.3593 46.4361 29.9864 47.1586 29.9854H99.8413C100.564 29.9864 101.256 30.3593 101.767 31.0221C102.278 31.685 102.565 32.5837 102.566 33.5211V84.2002Z"
        fill="white"
      />
      <path
        d="M98.5966 46.4869H50.1113V36.8406H98.5966V46.4869Z"
        fill="white"
      />
      <path
        d="M56.394 44.1138C57.848 44.1138 59.0266 43.0167 59.0266 41.6633C59.0266 40.31 57.848 39.2129 56.394 39.2129C54.9401 39.2129 53.7615 40.31 53.7615 41.6633C53.7615 43.0167 54.9401 44.1138 56.394 44.1138Z"
        fill="#CCD2D8"
      />
      <path
        d="M62.4764 40.0303C62.36 40.0303 62.2484 40.0733 62.1661 40.1499C62.0838 40.2265 62.0376 40.3304 62.0376 40.4387C62.0376 40.547 62.0838 40.6509 62.1661 40.7275C62.2484 40.8041 62.36 40.8471 62.4764 40.8471H94.5111C94.6273 40.8468 94.7387 40.8037 94.8207 40.7271C94.9028 40.6506 94.9489 40.5468 94.9489 40.4387C94.9489 40.3305 94.9028 40.2268 94.8207 40.1502C94.7387 40.0737 94.6273 40.0305 94.5111 40.0303H62.4764Z"
        fill="#646E7B"
      />
      <path
        d="M62.4739 42.4807C62.3575 42.4805 62.2458 42.5233 62.1633 42.5997C62.0809 42.6762 62.0344 42.7799 62.0342 42.8883C62.0339 42.9966 62.0799 43.1005 62.162 43.1773C62.2442 43.2541 62.3556 43.2973 62.472 43.2975H82.7247C82.7824 43.2976 82.8395 43.2871 82.8929 43.2666C82.9462 43.2462 82.9946 43.2161 83.0354 43.1782C83.0763 43.1402 83.1086 43.0952 83.1307 43.0456C83.1528 42.996 83.1642 42.9428 83.1642 42.8891C83.1642 42.8354 83.1528 42.7823 83.1307 42.7327C83.1086 42.6831 83.0763 42.638 83.0354 42.6001C82.9946 42.5622 82.9462 42.5321 82.8929 42.5116C82.8395 42.4911 82.7824 42.4806 82.7247 42.4807H62.4739Z"
        fill="#646E7B"
      />
      <path
        d="M98.5966 46.4869H50.1113V36.8406H98.5966V46.4869ZM50.8516 45.7979H97.8564V37.5296H50.8516V45.7979Z"
        fill="#CCCCCC"
      />
      <path
        d="M98.5966 58.5447H50.1113V48.8984H98.5966V58.5447Z"
        fill="white"
      />
      <path
        d="M56.394 56.1721C57.848 56.1721 59.0266 55.075 59.0266 53.7217C59.0266 52.3683 57.848 51.2712 56.394 51.2712C54.9401 51.2712 53.7615 52.3683 53.7615 53.7217C53.7615 55.075 54.9401 56.1721 56.394 56.1721Z"
        fill="#E6E6E6"
      />
      <path
        d="M62.4764 52.0886C62.36 52.0886 62.2484 52.1316 62.1661 52.2082C62.0838 52.2848 62.0376 52.3887 62.0376 52.497C62.0376 52.6053 62.0838 52.7092 62.1661 52.7858C62.2484 52.8624 62.36 52.9054 62.4764 52.9054H94.5111C94.6273 52.9052 94.7387 52.862 94.8207 52.7855C94.9028 52.7089 94.9489 52.6052 94.9489 52.497C94.9489 52.3889 94.9028 52.2851 94.8207 52.2086C94.7387 52.132 94.6273 52.0889 94.5111 52.0886H62.4764Z"
        fill="#E6E6E6"
      />
      <path
        d="M62.4739 54.5381C62.3575 54.5379 62.2458 54.5807 62.1633 54.6571C62.0809 54.7335 62.0344 54.8373 62.0342 54.9456C62.0339 55.0539 62.0799 55.1579 62.162 55.2347C62.2442 55.3114 62.3556 55.3547 62.472 55.3549H82.7247C82.7824 55.355 82.8395 55.3445 82.8929 55.324C82.9462 55.3035 82.9946 55.2735 83.0354 55.2355C83.0763 55.1976 83.1086 55.1525 83.1307 55.1029C83.1528 55.0533 83.1642 55.0002 83.1642 54.9465C83.1642 54.8928 83.1528 54.8396 83.1307 54.79C83.1086 54.7405 83.0763 54.6954 83.0354 54.6575C82.9946 54.6195 82.9462 54.5895 82.8929 54.569C82.8395 54.5485 82.7824 54.538 82.7247 54.5381H62.4739Z"
        fill="#E6E6E6"
      />
      <path
        d="M98.5966 58.5447H50.1113V48.8984H98.5966V58.5447ZM50.8516 57.8557H97.8564V49.5875H50.8516V57.8557Z"
        fill="#E6E6E6"
      />
      <path
        d="M98.5966 70.6029H50.1113V60.9565H98.5966V70.6029Z"
        fill="white"
      />
      <path
        d="M56.394 68.23C57.848 68.23 59.0266 67.1329 59.0266 65.7795C59.0266 64.4262 57.848 63.3291 56.394 63.3291C54.9401 63.3291 53.7615 64.4262 53.7615 65.7795C53.7615 67.1329 54.9401 68.23 56.394 68.23Z"
        fill="#E6E6E6"
      />
      <path
        d="M62.4764 64.1458C62.36 64.1458 62.2484 64.1888 62.1661 64.2654C62.0838 64.342 62.0376 64.4458 62.0376 64.5542C62.0376 64.6625 62.0838 64.7664 62.1661 64.8429C62.2484 64.9195 62.36 64.9626 62.4764 64.9626H94.5111C94.6273 64.9623 94.7387 64.9192 94.8207 64.8426C94.9028 64.766 94.9489 64.6623 94.9489 64.5542C94.9489 64.446 94.9028 64.3423 94.8207 64.2657C94.7387 64.1891 94.6273 64.146 94.5111 64.1458H62.4764Z"
        fill="#E6E6E6"
      />
      <path
        d="M62.4739 66.5959C62.3575 66.5957 62.2458 66.6385 62.1633 66.715C62.0809 66.7914 62.0344 66.8952 62.0342 67.0035C62.0339 67.1118 62.0799 67.2158 62.162 67.2925C62.2442 67.3693 62.3556 67.4126 62.472 67.4129H82.7247C82.7824 67.413 82.8395 67.4024 82.8929 67.3819C82.9462 67.3614 82.9946 67.3314 83.0354 67.2935C83.0763 67.2556 83.1086 67.2104 83.1307 67.1608C83.1528 67.1112 83.1642 67.058 83.1642 67.0044C83.1642 66.9507 83.1528 66.8975 83.1307 66.8479C83.1086 66.7983 83.0763 66.7533 83.0354 66.7153C82.9946 66.6774 82.9462 66.6473 82.8929 66.6268C82.8395 66.6064 82.7824 66.5959 82.7247 66.5959H62.4739Z"
        fill="#E6E6E6"
      />
      <path
        d="M98.5966 70.6029H50.1113V60.9565H98.5966V70.6029ZM50.8516 69.9138H97.8564V61.6456H50.8516V69.9138Z"
        fill="#E6E6E6"
      />
      <path
        d="M114.52 69.4023V115.444H26.2292V61.074H40.0457L52.7855 69.4023H114.52Z"
        fill="#F5F7FA"
      />
      <path
        d="M67.678 106.802H31.6204V109.473H67.678V106.802Z"
        fill="#9CA5B0"
      />
      <path
        d="M49.6492 101.616H31.6204V104.287H49.6492V101.616Z"
        fill="#9CA5B0"
      />
      <path
        d="M114.689 115.601H26.0615V60.9172H40.0997L52.8402 69.2455H114.689V115.601ZM26.3985 115.287H114.352V69.5598H52.7338L39.9933 61.2315H26.3985V115.287Z"
        fill="#CCD2D8"
      />
    </g>
    <defs>
      <clipPath id="clip0_2562_14705">
        <rect width="146" height="146" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
