import { Dispatch, FormEventHandler, SetStateAction } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import {
  AuthPasswordEye,
  Button,
  FormGroup,
  FormInput,
  FormLabel,
  GLOBAL,
  PasswordCriteriaList,
  RBBLUE,
} from "@patchworkhealth/web-components";

import {
  Divider,
  FormContainer,
  LoginHere,
  LogoWrapper,
} from "components/Style";
import { LogoIcon } from "icons/login";

type Props = {
  onSubmit: FormEventHandler<HTMLFormElement>;
  setPassword: (password: string) => void;
  isPasswordHidden: boolean;
  setIsPasswordHidden: Dispatch<SetStateAction<boolean>>;
  setConfirmPassword: (confirmPassword: string) => void;
  loading: boolean;
};

export const AccountActivation = ({
  onSubmit,
  setPassword,
  isPasswordHidden,
  setIsPasswordHidden,
  setConfirmPassword,
  loading,
}: Props) => {
  const history = useHistory();
  return (
    <FormContainer>
      <LogoWrapper>
        <LogoIcon />
      </LogoWrapper>
      <Form autoComplete="off" onSubmit={onSubmit}>
        <FormGroup>
          <FormLabel style={{ color: GLOBAL.white }}>Password</FormLabel>
          <FormInput
            autoFocus
            data-testid="test__activation__password"
            onChange={(e) => setPassword(e.target.value)}
            type={isPasswordHidden ? "password" : "text"}
            name="email"
            required
            placeholder="Enter password"
            autoComplete="off"
          />

          <AuthPasswordEye onClick={() => setIsPasswordHidden((s) => !s)} />
        </FormGroup>

        <Divider top="10" bottom="10" line="none" />

        <FormGroup>
          <FormLabel style={{ color: GLOBAL.white }}>
            Confirm Password
          </FormLabel>
          <FormInput
            data-testid="test__activation__confirm"
            onChange={(e) => setConfirmPassword(e.target.value)}
            type={isPasswordHidden ? "password" : "text"}
            name="password"
            required
            placeholder="Confirm password"
            autoComplete="off"
          />
          <AuthPasswordEye
            data-testid="test__activation__eye"
            onClick={() => setIsPasswordHidden((s) => !s)}
          />
        </FormGroup>

        <PasswordCriteriaList />

        <Divider top="10" bottom="10" line="none" />

        <Button
          variant="success"
          loading={loading}
          type="submit"
          style={{
            width: "100%",
            fontSize: "14px",
            fontWeight: 500,
            color: RBBLUE.five,
          }}
        >
          Set password
        </Button>
        <br />
        <p style={{ marginTop: 20 }}>
          If you already have a patchwork account, please log in{" "}
          <LoginHere
            data-testid="test__activation__history"
            onClick={() => history.push("/")}
          >
            here
          </LoginHere>
        </p>
      </Form>
    </FormContainer>
  );
};
