import { Dispatch, SetStateAction } from "react";

import {
  compensationInputMap,
  getCompensationValue,
  getNoYesValue,
  noYesInputMap,
} from "components/ExceptionReports/ExceptionReportForms/ManualExceptionReportForm/ManualExceptionReportForm.helpers";
import { ExceptionReportInputType } from "components/ExceptionReports/ExceptionReportForms/ManualExceptionReportForm/ManualExceptionReportForm.types";
import { ExceptionReportShiftInputType } from "components/ExceptionReports/ExceptionReportForms/ShiftExceptionReportForm/ShiftExceptionReportForm.types";
import {
  ExceptionReportType,
  RosteringShiftEventType,
} from "components/ExceptionReports/ExceptionReports.types";
import { FormError } from "helpers/types";

import { RosteringExceptionReportCreateMutationVariables } from "../ManualExceptionReportForm/__generated__/ManualExceptionReportForm.generated";

export const exceptionReportShiftInputEmptyState: ExceptionReportShiftInputType =
  {
    details: undefined,
    rosteringExceptionReportCategory: null,
    rosteringExceptionReportReason: null,
    patientSafetyImpacted: -1,
    requestedCompensation: -1,
    mostSeniorClinician: "",
    shiftActualEndTime: "",
    shiftActualStartTime: "",
    breakActualTime: null,
    breakScheduledTime: null,
  };

export const isStartTimeBeforeEndTime = (
  startTime: string | undefined, //HH:MM
  endTime: string | undefined // HH:MM
) => {
  if (!startTime || !endTime) return false;

  const startTimeStringValue = startTime.replace(":", "");
  const endTimeStringValue = endTime.replace(":", "");

  return +startTimeStringValue > +endTimeStringValue;
};

export const getInitialShiftFormInput = (
  exception: ExceptionReportType
): ExceptionReportShiftInputType => {
  if (!exception) return exceptionReportShiftInputEmptyState;

  return {
    details: exception.details ?? undefined,
    patientSafetyImpacted: exception.patientSafetyImpacted
      ? noYesInputMap[exception.patientSafetyImpacted]
      : exceptionReportShiftInputEmptyState.patientSafetyImpacted,
    requestedCompensation: exception.requestedCompensation
      ? compensationInputMap[exception.requestedCompensation]
      : exceptionReportShiftInputEmptyState.requestedCompensation,
    mostSeniorClinician: exception.mostSeniorClinician ?? "",
    shiftActualEndTime: exception.shiftActualEndTime ?? "",
    shiftActualStartTime: exception.shiftActualStartTime ?? "",
    breakScheduledTime: exception.breakScheduledTime ?? null,
    breakActualTime: exception.breakActualTime ?? null,
    rosteringExceptionReportReason:
      exception.rosteringExceptionReportReasons?.map(
        ({ name, id }: { name: string; id: string }) => ({
          name,
          id,
        })
      ) ?? null,
    rosteringExceptionReportCategory:
      (exception.rosteringExceptionReportReasons?.[0] && {
        allowCompensation:
          exception.rosteringExceptionReportReasons?.[0]
            .rosteringExceptionReportReasonCategory.allowCompensation,
        name: exception.rosteringExceptionReportReasons?.[0]
          .rosteringExceptionReportReasonCategory.name,
        id: exception.rosteringExceptionReportReasons?.[0]
          .rosteringExceptionReportReasonCategory.id,
      }) ??
      null,
  };
};
const conditions = (
  exceptionReportShiftInput: ExceptionReportShiftInputType
): { condition: boolean; fieldName: string; message: string }[] => [
  {
    condition: !exceptionReportShiftInput.rosteringExceptionReportCategory,
    fieldName: "rosteringExceptionReportCategory",
    message: "Category is a required field!",
  },
  {
    condition:
      !exceptionReportShiftInput.rosteringExceptionReportReason?.length,
    fieldName: "rosteringExceptionReportReason",
    message: "Reason is a required field! Select at least one reason.",
  },
  {
    condition: exceptionReportShiftInput.patientSafetyImpacted === -1,
    fieldName: "patientSafetyImpacted",
    message: "Patient safety is a required field!",
  },
  {
    condition:
      exceptionReportShiftInput.requestedCompensation === -1 &&
      exceptionReportShiftInput.rosteringExceptionReportCategory
        ?.allowCompensation !== false,
    fieldName: "requestedCompensation",
    message: "Compensation is a required field!",
  },
];

export const validateRequiredFields = (
  exceptionReportShiftInput: ExceptionReportShiftInputType,
  setErrors: Dispatch<
    SetStateAction<FormError<ExceptionReportInputType> | null>
  >
) => {
  setErrors(null);
  conditions(exceptionReportShiftInput).forEach(
    ({ condition, message, fieldName }) => {
      if (condition) setErrors((prev) => ({ ...prev, [fieldName]: message }));
    }
  );

  return conditions(exceptionReportShiftInput).every(
    ({ condition }) => !condition
  );
};

export const getMutationVariables = (
  exceptionReportShiftInput: ExceptionReportShiftInputType,
  shiftEvent: RosteringShiftEventType | undefined,
  orgId: number
): RosteringExceptionReportCreateMutationVariables => {
  return {
    details: exceptionReportShiftInput.details,
    shiftActualStartTime: exceptionReportShiftInput.shiftActualStartTime,
    shiftActualEndTime: exceptionReportShiftInput.shiftActualEndTime,
    breakActualTime: exceptionReportShiftInput.breakActualTime,
    breakScheduledTime: exceptionReportShiftInput.breakScheduledTime,
    mostSeniorClinician: exceptionReportShiftInput.mostSeniorClinician,
    rosteringExceptionReportReasonIds:
      exceptionReportShiftInput.rosteringExceptionReportReason?.map(
        (reason) => +reason.id
      ),
    requestedCompensation: !exceptionReportShiftInput
      ?.rosteringExceptionReportCategory?.allowCompensation
      ? null
      : getCompensationValue(exceptionReportShiftInput?.requestedCompensation),
    patientSafetyImpacted: getNoYesValue(
      exceptionReportShiftInput.patientSafetyImpacted
    ),
    rosteringOrganisationRegistrationId: orgId,
    rosteringEventId: +(shiftEvent?.id ?? 0),
    shiftScheduledStartTime: shiftEvent?.startsAt.slice(0, 19),
    shiftScheduledEndTime: shiftEvent?.endsAt.slice(0, 19),
  };
};
