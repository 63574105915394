import * as Types from '../../../baseCODEGEN';

import { gql } from '@apollo/client';
import { ErrorFragmentDoc } from '../../../gql/new-gql/__generated__/fragments.generated';
import * as Apollo from '@apollo/client';
import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from 'msw'
const defaultOptions = {} as const;
export type ReadNotificationMutationVariables = Types.Exact<{
  notificationIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type ReadNotificationMutation = { __typename?: 'Mutation', readNotifications?: { __typename?: 'ReadNotificationsPayload', errors: Array<{ __typename?: 'ValidationError', attribute?: string | null, message: string }>, notifications?: Array<{ __typename?: 'RosteringNotifications', id: string, isRead?: boolean | null }> | null } | null };


export const ReadNotificationDocument = gql`
    mutation ReadNotification($notificationIds: [Int!]!) {
  readNotifications(notificationIds: $notificationIds) {
    errors {
      ...Error
    }
    notifications {
      id
      isRead
    }
  }
}
    ${ErrorFragmentDoc}`;
export type ReadNotificationMutationFn = Apollo.MutationFunction<ReadNotificationMutation, ReadNotificationMutationVariables>;

/**
 * __useReadNotificationMutation__
 *
 * To run a mutation, you first call `useReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationMutation, { data, loading, error }] = useReadNotificationMutation({
 *   variables: {
 *      notificationIds: // value for 'notificationIds'
 *   },
 * });
 */
export function useReadNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ReadNotificationMutation, ReadNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadNotificationMutation, ReadNotificationMutationVariables>(ReadNotificationDocument, options);
      }
export type ReadNotificationMutationHookResult = ReturnType<typeof useReadNotificationMutation>;
export type ReadNotificationMutationResult = Apollo.MutationResult<ReadNotificationMutation>;
export type ReadNotificationMutationOptions = Apollo.BaseMutationOptions<ReadNotificationMutation, ReadNotificationMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockReadNotificationMutation((req, res, ctx) => {
 *   const { notificationIds } = req.variables;
 *   return res(
 *     ctx.data({ readNotifications })
 *   )
 * })
 */
export const mockReadNotificationMutation = (resolver: ResponseResolver<GraphQLRequest<ReadNotificationMutationVariables>, GraphQLContext<ReadNotificationMutation>, any>) =>
  graphql.mutation<ReadNotificationMutation, ReadNotificationMutationVariables>(
    'ReadNotification',
    resolver
  )
