import styled from "styled-components";

import { GREY } from "@patchworkhealth/web-components";

export const TextItemSmall = styled.p`
  text-transform: uppercase;
  font-size: 10px;
  color: ${GREY.five};
  font-weight: 700;
`;

export const TextItem = styled.p`
  color: ${GREY.ten};
  font-size: 14px;
  font-weight: 400;
`;

export const GradeLabel = styled.p<{ colour: string | null | undefined }>`
  font-size: 10px;
  font-weight: 600;
  color: ${({ colour }) => colour};
`;

export const DepartmentItem = styled.p`
  text-transform: uppercase;
  font-size: 8px;
  color: ${GREY.six};
  font-weight: 700;
  background: ${GREY.two};
  border-radius: 3px;
  padding: 2px 6px;
  margin: 0;
`;
