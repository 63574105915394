export const AppleIcon = () => (
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4943 12.8228C16.5058 11.9255 16.743 11.0457 17.1837 10.2653C17.6244 9.48494 18.2544 8.82925 19.015 8.35929C18.5318 7.66575 17.8944 7.09498 17.1533 6.69234C16.4122 6.2897 15.5879 6.06626 14.7458 6.03979C12.9496 5.85031 11.2083 7.11998 10.2929 7.11998C9.35991 7.11998 7.95063 6.0586 6.43307 6.08998C5.45147 6.12185 4.49483 6.40872 3.65634 6.92263C2.81786 7.43654 2.12614 8.15997 1.64857 9.02244C-0.420157 12.622 1.12292 17.9121 3.10459 20.8217C4.09606 22.2465 5.2548 23.838 6.77099 23.7815C8.25467 23.7197 8.8088 22.8307 10.5997 22.8307C12.374 22.8307 12.8939 23.7815 14.4409 23.7456C16.0329 23.7197 17.036 22.3146 17.9927 20.8763C18.7051 19.8611 19.2532 18.7391 19.6169 17.5519C18.692 17.1587 17.9026 16.5006 17.3473 15.6596C16.7921 14.8187 16.4954 13.832 16.4943 12.8228Z"
      fill="white"
    />
    <path
      d="M13.5724 4.12644C14.4404 3.07917 14.8681 1.73307 14.7645 0.374023C13.4383 0.51401 12.2133 1.15101 11.3335 2.1581C10.9034 2.65009 10.5739 3.22246 10.364 3.84249C10.1541 4.46252 10.0678 5.11805 10.1101 5.7716C10.7735 5.77846 11.4297 5.63397 12.0294 5.349C12.6292 5.06403 13.1567 4.64602 13.5724 4.12644Z"
      fill="white"
    />
  </svg>
);

export const AndroidIcon = () => (
  <svg
    width="24"
    height="26"
    viewBox="0 0 24 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_ii_8322_16880)">
      <path
        d="M0.463001 0.396975C0.172001 0.704975 -4.27246e-07 1.18298 -4.27246e-07 1.80198V23.918C-4.27246e-07 24.538 0.172001 25.015 0.463001 25.323L0.537 25.395L12.926 13.006V12.86V12.714L0.537 0.323975L0.463001 0.396975Z"
        fill="url(#paint0_linear_8322_16880)"
      />
      <path
        d="M17.055 17.137L12.926 13.006V12.86V12.714L17.056 8.58398L17.149 8.63698L22.042 11.417C23.439 12.211 23.439 13.51 22.042 14.305L17.149 17.085L17.055 17.137Z"
        fill="url(#paint1_linear_8322_16880)"
      />
      <g filter="url(#filter1_i_8322_16880)">
        <path
          d="M17.149 17.0841L12.925 12.8601L0.463014 25.3231C0.923014 25.8111 1.68401 25.8711 2.54101 25.3851L17.149 17.0841Z"
          fill="url(#paint2_linear_8322_16880)"
        />
      </g>
      <path
        d="M17.149 8.63608L2.54101 0.336075C1.68401 -0.150925 0.923014 -0.0899245 0.463014 0.398075L12.926 12.8611L17.149 8.63608Z"
        fill="url(#paint3_linear_8322_16880)"
      />
    </g>
    <defs>
      <filter
        id="filter0_ii_8322_16880"
        x="0"
        y="0"
        width="23.0897"
        height="25.7207"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-0.15" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_8322_16880"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.15" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_8322_16880"
          result="effect2_innerShadow_8322_16880"
        />
      </filter>
      <filter
        id="filter1_i_8322_16880"
        x="0.463013"
        y="12.8601"
        width="16.686"
        height="12.8606"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-0.15" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_8322_16880"
        />
      </filter>
      <linearGradient
        id="paint0_linear_8322_16880"
        x1="11.8278"
        y1="1.56797"
        x2="-4.9542"
        y2="18.35"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00A0FF" />
        <stop offset="0.0066" stopColor="#00A1FF" />
        <stop offset="0.2601" stopColor="#00BEFF" />
        <stop offset="0.5122" stopColor="#00D2FF" />
        <stop offset="0.7604" stopColor="#00DFFF" />
        <stop offset="1" stopColor="#00E3FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8322_16880"
        x1="23.8605"
        y1="12.86"
        x2="-0.335374"
        y2="12.86"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE000" />
        <stop offset="0.4087" stopColor="#FFBD00" />
        <stop offset="0.7754" stopColor="#FFA500" />
        <stop offset="1" stopColor="#FF9C00" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_8322_16880"
        x1="14.8551"
        y1="15.154"
        x2="-7.90338"
        y2="37.9125"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF3A44" />
        <stop offset="1" stopColor="#C31162" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_8322_16880"
        x1="-2.67559"
        y1="-6.96412"
        x2="7.48671"
        y2="3.19818"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32A071" />
        <stop offset="0.0685" stopColor="#2DA771" />
        <stop offset="0.4762" stopColor="#15CF74" />
        <stop offset="0.8009" stopColor="#06E775" />
        <stop offset="1" stopColor="#00F076" />
      </linearGradient>
    </defs>
  </svg>
);

export const UseMobileIcon = () => (
  <svg
    width="314"
    height="309"
    viewBox="0 0 314 309"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M234.2 73.3022H232.499V26.8724C232.499 23.3434 231.801 19.8491 230.445 16.5888C229.09 13.3285 227.103 10.3661 224.598 7.87074C222.093 5.37541 219.119 3.39601 215.846 2.04554C212.573 0.69508 209.065 3.33964e-06 205.523 0H106.776C99.6215 -1.57373e-05 92.76 2.83115 87.7011 7.87067C82.6421 12.9102 79.8 19.7453 79.8 26.8723V281.59C79.8 288.717 82.6421 295.552 87.701 300.592C92.76 305.631 99.6214 308.462 106.776 308.462H205.523C212.677 308.462 219.539 305.631 224.598 300.592C229.657 295.552 232.499 288.717 232.499 281.59V106.352H234.2L234.2 73.3022Z"
      fill="#414C58"
    />
    <path
      d="M226.757 27.0601V281.403C226.757 286.726 224.634 291.83 220.856 295.594C217.078 299.357 211.954 301.472 206.611 301.472H107.39C102.047 301.472 96.9229 299.357 93.1448 295.594C89.3666 291.83 87.2441 286.726 87.244 281.403V27.0601C87.2441 21.7375 89.3666 16.633 93.1448 12.8694C96.9229 9.10574 102.047 6.99131 107.39 6.99121H119.427C118.835 8.43904 118.61 10.0095 118.771 11.5644C118.932 13.1193 119.474 14.611 120.349 15.9083C121.224 17.2056 122.406 18.2687 123.791 19.0042C125.175 19.7396 126.72 20.1249 128.289 20.126H184.861C186.43 20.1249 187.974 19.7396 189.359 19.0042C190.744 18.2687 191.926 17.2055 192.801 15.9082C193.676 14.6109 194.218 13.1192 194.379 11.5644C194.54 10.0095 194.315 8.43904 193.723 6.99121H206.611C211.954 6.99131 217.078 9.10574 220.856 12.8694C224.634 16.633 226.757 21.7375 226.757 27.0601Z"
      fill="white"
    />
    <path
      d="M129.601 76.7318H114.364C113.108 76.7303 111.905 76.2329 111.017 75.3486C110.129 74.4643 109.63 73.2653 109.629 72.0147V56.8362C109.63 55.5856 110.129 54.3866 111.017 53.5023C111.905 52.618 113.108 52.1206 114.364 52.1191H129.601C130.856 52.1206 132.06 52.618 132.948 53.5023C133.835 54.3866 134.335 55.5856 134.336 56.8362V72.0147C134.335 73.2653 133.835 74.4643 132.948 75.3486C132.06 76.2329 130.856 76.7303 129.601 76.7318Z"
      fill="#E4E7EB"
    />
    <path
      d="M164.619 76.7318H149.382C148.126 76.7303 146.923 76.2329 146.035 75.3486C145.147 74.4643 144.648 73.2653 144.647 72.0147V56.8362C144.648 55.5856 145.147 54.3866 146.035 53.5023C146.923 52.618 148.126 52.1206 149.382 52.1191H164.619C165.874 52.1206 167.078 52.618 167.966 53.5023C168.853 54.3866 169.353 55.5856 169.354 56.8362V72.0147C169.353 73.2653 168.853 74.4643 167.966 75.3486C167.078 76.2329 165.874 76.7303 164.619 76.7318Z"
      fill="#E4E7EB"
    />
    <path
      d="M199.637 76.7318H184.4C183.145 76.7303 181.941 76.2329 181.053 75.3486C180.166 74.4643 179.666 73.2653 179.665 72.0147V56.8362C179.666 55.5856 180.166 54.3866 181.053 53.5023C181.941 52.618 183.145 52.1206 184.4 52.1191H199.637C200.893 52.1206 202.096 52.618 202.984 53.5023C203.872 54.3866 204.371 55.5856 204.372 56.8362V72.0147C204.371 73.2653 203.872 74.4643 202.984 75.3486C202.096 76.2329 200.893 76.7303 199.637 76.7318Z"
      fill="#E6E6E6"
    />
    <path
      d="M129.601 115.517H114.364C113.108 115.516 111.905 115.018 111.017 114.134C110.129 113.249 109.63 112.05 109.629 110.8V95.6213C109.63 94.3707 110.129 93.1718 111.017 92.2874C111.905 91.4031 113.108 90.9057 114.364 90.9043H129.601C130.856 90.9057 132.06 91.4031 132.948 92.2874C133.835 93.1718 134.335 94.3707 134.336 95.6213V110.8C134.335 112.05 133.835 113.249 132.948 114.134C132.06 115.018 130.856 115.516 129.601 115.517Z"
      fill="#E6E6E6"
    />
    <path
      d="M164.619 115.517H149.382C148.126 115.516 146.923 115.018 146.035 114.134C145.147 113.249 144.648 112.05 144.647 110.8V95.6213C144.648 94.3707 145.147 93.1718 146.035 92.2874C146.923 91.4031 148.126 90.9057 149.382 90.9043H164.619C165.874 90.9057 167.078 91.4031 167.966 92.2874C168.853 93.1718 169.353 94.3707 169.354 95.6213V110.8C169.353 112.05 168.853 113.249 167.966 114.134C167.078 115.018 165.874 115.515 164.619 115.517Z"
      fill="#E6E6E6"
    />
    <path
      d="M199.637 115.517H184.4C183.145 115.516 181.941 115.018 181.053 114.134C180.166 113.249 179.666 112.05 179.665 110.8V95.6213C179.666 94.3707 180.166 93.1718 181.053 92.2874C181.941 91.4031 183.145 90.9057 184.4 90.9043H199.637C200.893 90.9057 202.096 91.4031 202.984 92.2874C203.872 93.1718 204.371 94.3707 204.372 95.6213V110.8C204.371 112.05 203.872 113.249 202.984 114.134C202.096 115.018 200.893 115.516 199.637 115.517Z"
      fill="#E6E6E6"
    />
    <path
      d="M129.601 154.295H114.364C113.108 154.294 111.905 153.796 111.017 152.912C110.129 152.028 109.63 150.829 109.629 149.578V134.4C109.63 133.149 110.129 131.95 111.017 131.066C111.905 130.181 113.108 129.684 114.364 129.683H129.601C130.856 129.684 132.06 130.181 132.948 131.066C133.835 131.95 134.335 133.149 134.336 134.4V149.578C134.335 150.829 133.835 152.028 132.948 152.912C132.06 153.796 130.856 154.294 129.601 154.295Z"
      fill="#E6E6E6"
    />
    <path
      d="M164.619 154.295H149.382C148.126 154.294 146.923 153.796 146.035 152.912C145.147 152.028 144.648 150.829 144.647 149.578V134.4C144.648 133.149 145.147 131.95 146.035 131.066C146.923 130.181 148.126 129.684 149.382 129.683H164.619C165.874 129.684 167.078 130.181 167.966 131.066C168.853 131.95 169.353 133.149 169.354 134.4V149.578C169.353 150.829 168.853 152.028 167.966 152.912C167.078 153.796 165.874 154.294 164.619 154.295Z"
      fill="#E6E6E6"
    />
    <path
      d="M199.637 154.295H184.4C183.145 154.294 181.941 153.796 181.053 152.912C180.166 152.028 179.666 150.829 179.665 149.578V134.4C179.666 133.149 180.166 131.95 181.053 131.066C181.941 130.181 183.145 129.684 184.4 129.683H199.637C200.893 129.684 202.096 130.181 202.984 131.066C203.872 131.95 204.371 133.149 204.372 134.4V149.578C204.371 150.829 203.872 152.028 202.984 152.912C202.096 153.796 200.893 154.294 199.637 154.295Z"
      fill="#E6E6E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M173.097 148C169.066 148 168.843 152.353 168.843 152.353V166.792H179.533C179.533 156.592 177.127 148 173.097 148Z"
      fill="#212932"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M168.843 166.789V181.228C168.843 181.228 168.621 185.582 164.59 185.582C160.56 185.582 158.154 176.989 158.154 166.789H168.843Z"
      fill="#FFD24C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M187.66 171.045C187.66 167.02 183.301 166.797 183.301 166.797H168.843V177.472C179.057 177.472 187.66 175.07 187.66 171.045Z"
      fill="#2BC0D4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M168.843 166.797H154.385C154.385 166.797 150.026 167.019 150.026 171.045C150.026 175.07 158.63 177.472 168.843 177.472V166.797Z"
      fill="#2BC0D4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150 162.549C150 166.574 154.359 166.797 154.359 166.797H168.817V156.121C158.603 156.121 150 158.524 150 162.549Z"
      fill="#FF7F4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M168.825 166.797H183.283C183.283 166.797 187.642 166.574 187.642 162.549C187.642 158.524 179.039 156.121 168.825 156.121V166.797Z"
      fill="#FF7F4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M168.843 166.797V181.236C168.843 181.236 169.066 185.589 173.097 185.589C177.127 185.589 179.533 176.997 179.533 166.797H168.843Z"
      fill="#212932"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M168.844 166.797V152.358C168.844 152.358 168.621 148.005 164.591 148.005C160.56 148.005 158.155 156.597 158.155 166.797H168.844Z"
      fill="#FFD24C"
    />
    <path
      d="M102.325 227.752C102.901 227.467 103.407 227.059 103.807 226.557C104.208 226.056 104.493 225.473 104.643 224.85C104.793 224.227 104.804 223.578 104.676 222.951C104.547 222.323 104.282 221.731 103.898 221.216L107.189 189.169H100.078L97.463 220.461C96.6378 221.16 96.0985 222.136 95.9474 223.204C95.7963 224.273 96.0438 225.359 96.6431 226.258C97.2424 227.157 98.1518 227.805 99.199 228.08C100.246 228.356 101.358 228.239 102.325 227.752Z"
      fill="#624703"
    />
    <path
      d="M108.854 171.483C114.41 171.483 118.914 166.996 118.914 161.461C118.914 155.926 114.41 151.439 108.854 151.439C103.298 151.439 98.7935 155.926 98.7935 161.461C98.7935 166.996 103.298 171.483 108.854 171.483Z"
      fill="#624703"
    />
    <path
      d="M110.748 207.832C110.345 207.831 109.954 207.7 109.633 207.457C109.313 207.213 109.082 206.872 108.975 206.486C108.169 203.568 106.072 198.636 102.742 191.825C101.991 190.285 101.575 188.605 101.521 186.894C101.468 185.183 101.778 183.48 102.432 181.897C103.085 180.313 104.068 178.886 105.314 177.707C106.561 176.529 108.043 175.626 109.665 175.059C111.201 174.518 112.831 174.29 114.457 174.388C116.084 174.486 117.674 174.908 119.134 175.629C120.594 176.35 121.893 177.355 122.957 178.586C124.02 179.816 124.824 181.246 125.323 182.791C127.903 190.852 127.779 199.486 127.605 202.823C127.584 203.225 127.431 203.609 127.169 203.916C126.908 204.223 126.552 204.436 126.158 204.523L111.143 207.789C111.013 207.817 110.881 207.832 110.748 207.832Z"
      fill="#CCCCCC"
    />
    <path
      d="M125.288 302.997L130.31 302.997L132.699 283.702L125.287 283.703L125.288 302.997Z"
      fill="#624703"
    />
    <path
      d="M139.994 307.642L124.212 307.642L124.212 301.568L133.896 301.568C134.697 301.567 135.49 301.725 136.23 302.03C136.97 302.335 137.642 302.782 138.208 303.346C138.774 303.911 139.224 304.58 139.53 305.317C139.837 306.054 139.994 306.844 139.994 307.642Z"
      fill="#002033"
    />
    <path
      d="M75.0737 296.513L79.4743 298.923L90.9 283.162L84.4052 279.605L75.0737 296.513Z"
      fill="#624703"
    />
    <path
      d="M85.7144 307.643L71.8842 300.068L74.8219 294.745L83.3086 299.393C84.7258 300.169 85.7754 301.474 86.2266 303.022C86.6777 304.569 86.4935 306.231 85.7144 307.643Z"
      fill="#002033"
    />
    <path
      d="M125.053 292.288C124.606 292.287 124.174 292.125 123.838 291.83C123.502 291.536 123.285 291.13 123.227 290.688L116.947 242.479C116.919 242.266 116.824 242.067 116.676 241.91C116.528 241.754 116.334 241.648 116.122 241.608C115.91 241.568 115.691 241.596 115.496 241.687C115.3 241.779 115.139 241.929 115.035 242.118L88.8614 289.421C88.6454 289.808 88.2975 290.104 87.8808 290.256C87.4642 290.408 87.0065 290.407 86.591 290.251L80.8861 288.086C80.6566 287.999 80.4469 287.867 80.2693 287.698C80.0917 287.529 79.9498 287.327 79.8519 287.102C79.754 286.878 79.7021 286.637 79.6992 286.392C79.6963 286.147 79.7425 285.905 79.835 285.678L97.9659 241.31C98.0118 241.197 98.043 241.079 98.0585 240.958C100.514 222.024 106.762 212.395 108.619 209.895C108.754 209.712 108.844 209.498 108.879 209.273C108.914 209.047 108.895 208.817 108.822 208.601L108.567 207.838C108.465 207.535 108.444 207.211 108.506 206.898C108.568 206.585 108.712 206.293 108.921 206.052C115.611 198.462 127.27 202.604 127.387 202.646L127.446 202.668L127.482 202.718C140.556 220.578 135.722 278.589 134.62 290.037C134.579 290.467 134.387 290.869 134.077 291.171C133.767 291.474 133.36 291.657 132.927 291.689L125.196 292.282C125.148 292.286 125.101 292.288 125.053 292.288Z"
      fill="#002033"
    />
    <path
      d="M147.965 173.102C147.914 173.322 147.882 173.546 147.867 173.771L130.271 183.883L125.994 181.43L121.435 187.376L128.583 192.451C129.16 192.861 129.855 193.074 130.564 193.056C131.273 193.039 131.957 192.793 132.514 192.356L150.658 178.097C151.477 178.399 152.369 178.445 153.215 178.23C154.061 178.015 154.821 177.548 155.394 176.892C155.967 176.236 156.326 175.422 156.424 174.557C156.521 173.693 156.352 172.819 155.939 172.053C155.525 171.287 154.888 170.664 154.111 170.268C153.334 169.871 152.454 169.72 151.588 169.834C150.722 169.948 149.912 170.321 149.265 170.905C148.618 171.489 148.164 172.255 147.965 173.102Z"
      fill="#624703"
    />
    <path
      d="M129.683 184.362L123.996 191.705C123.834 191.913 123.629 192.085 123.395 192.208C123.16 192.331 122.902 192.402 122.637 192.416C122.373 192.431 122.108 192.388 121.862 192.292C121.615 192.195 121.393 192.047 121.209 191.857L114.771 185.197C113.703 184.366 113.01 183.147 112.843 181.808C112.675 180.469 113.048 179.118 113.879 178.052C114.71 176.985 115.931 176.291 117.275 176.12C118.619 175.949 119.976 176.316 121.049 177.141L129.124 181.638C129.355 181.767 129.555 181.944 129.712 182.157C129.868 182.37 129.976 182.614 130.029 182.873C130.082 183.131 130.079 183.398 130.019 183.655C129.959 183.912 129.845 184.153 129.683 184.362Z"
      fill="#CCCCCC"
    />
    <path
      d="M107.641 197.119L98.4801 195.471C98.2194 195.424 97.972 195.322 97.7546 195.171C97.5372 195.02 97.355 194.825 97.2203 194.597C97.0857 194.37 97.0018 194.117 96.9743 193.854C96.9469 193.592 96.9766 193.326 97.0613 193.076L100.031 184.317C100.28 182.99 101.046 181.815 102.163 181.05C103.279 180.285 104.655 179.992 105.988 180.236C107.321 180.48 108.502 181.24 109.273 182.351C110.044 183.461 110.342 184.83 110.101 186.159L109.811 195.37C109.803 195.634 109.737 195.892 109.62 196.129C109.502 196.365 109.334 196.573 109.128 196.739C108.922 196.905 108.683 197.025 108.426 197.09C108.17 197.156 107.902 197.166 107.641 197.119H107.641Z"
      fill="#CCCCCC"
    />
    <path
      d="M110.446 159.156C113.032 160.918 116.427 162.731 119.068 160.853C119.894 160.227 120.494 159.35 120.778 158.355C121.063 157.361 121.016 156.301 120.645 155.335C119.381 151.732 115.906 150.146 112.584 148.931C108.265 147.35 103.562 146.093 99.1033 147.225C94.6446 148.357 90.7041 152.657 91.5089 157.17C92.1562 160.799 95.5927 163.819 95.1049 167.473C94.6139 171.15 90.4625 173.086 86.7974 173.743C83.1324 174.4 79.0311 174.611 76.3972 177.234C73.0372 180.579 73.8762 186.558 77.0379 190.091C80.1996 193.623 84.9707 195.224 89.5939 196.312C95.7194 197.754 102.345 198.512 108.162 196.119C113.98 193.725 118.507 187.283 116.822 181.242C116.11 178.69 114.433 176.534 112.812 174.434C111.192 172.335 109.551 170.139 108.932 167.563C108.417 165.417 108.799 162.924 110.228 161.314C110.487 161.032 110.651 160.676 110.695 160.296C110.738 159.915 110.66 159.531 110.471 159.198L110.446 159.156Z"
      fill="#002033"
    />
    <path
      d="M212.737 303.665H207.521L205.039 283.622H212.738L212.737 303.665Z"
      fill="#FFB8B8"
    />
    <path
      d="M203.795 302.18H213.855V308.489H197.461C197.461 307.661 197.625 306.84 197.943 306.075C198.262 305.309 198.728 304.614 199.316 304.028C199.904 303.442 200.603 302.977 201.371 302.66C202.14 302.343 202.963 302.18 203.795 302.18Z"
      fill="#002033"
    />
    <path
      d="M232.309 303.665H227.093L224.611 283.622H232.31L232.309 303.665Z"
      fill="#FFB8B8"
    />
    <path
      d="M223.367 302.18H233.427V308.489H217.033C217.033 307.661 217.197 306.84 217.515 306.075C217.833 305.309 218.3 304.614 218.888 304.028C219.476 303.442 220.174 302.977 220.943 302.66C221.711 302.343 222.535 302.18 223.367 302.18Z"
      fill="#002033"
    />
    <path
      d="M231.672 243.971C231.11 243.611 230.634 243.133 230.278 242.57C229.922 242.007 229.694 241.372 229.61 240.712C229.526 240.052 229.589 239.381 229.794 238.747C229.998 238.114 230.34 237.532 230.795 237.045L227.36 188.49L237.253 189.446L237.525 236.995C238.297 237.81 238.743 238.879 238.776 240C238.81 241.12 238.431 242.213 237.709 243.073C236.988 243.933 235.974 244.5 234.862 244.665C233.749 244.831 232.614 244.584 231.672 243.971H231.672Z"
      fill="#FFB8B8"
    />
    <path
      d="M212.491 293.568L206.748 293.295C206.259 293.272 205.798 293.063 205.458 292.711C205.119 292.359 204.928 291.891 204.925 291.404L204.524 233.525C204.522 233.251 204.58 232.979 204.693 232.728C204.807 232.478 204.973 232.255 205.181 232.074C205.389 231.894 205.633 231.76 205.898 231.682C206.163 231.605 206.441 231.585 206.714 231.624L229.687 234.946C230.141 235.01 230.556 235.234 230.858 235.578C231.159 235.922 231.325 236.363 231.326 236.82L234.281 290.45C234.283 290.702 234.235 290.951 234.139 291.184C234.044 291.417 233.903 291.629 233.725 291.808C233.547 291.987 233.336 292.128 233.102 292.225C232.869 292.322 232.619 292.372 232.366 292.372H226.176C225.719 292.374 225.277 292.212 224.93 291.916C224.582 291.621 224.353 291.211 224.284 290.761L220.465 266.573C220.409 266.219 220.226 265.898 219.951 265.667C219.676 265.437 219.326 265.314 218.966 265.32C218.607 265.327 218.262 265.463 217.995 265.703C217.728 265.944 217.558 266.272 217.515 266.628L214.483 291.89C214.426 292.352 214.201 292.778 213.851 293.088C213.501 293.397 213.05 293.569 212.582 293.57C212.552 293.57 212.521 293.57 212.491 293.568Z"
      fill="#002033"
    />
    <path
      d="M216.533 236.822C211.47 234.02 207.514 233.283 205.607 233.094C205.354 233.069 205.108 232.994 204.884 232.872C204.661 232.75 204.465 232.584 204.308 232.385C204.147 232.184 204.029 231.952 203.961 231.704C203.893 231.456 203.876 231.197 203.912 230.943L209.416 190.233C209.733 187.851 210.657 185.59 212.099 183.664C213.541 181.738 215.454 180.212 217.655 179.231C219.786 178.255 222.132 177.839 224.47 178.021C226.808 178.204 229.061 178.979 231.013 180.274C231.202 180.399 231.387 180.527 231.568 180.657C233.816 182.298 235.527 184.565 236.484 187.173C237.44 189.781 237.601 192.612 236.945 195.311C233.569 209.066 232.41 231.618 232.212 235.999C232.196 236.372 232.07 236.733 231.849 237.035C231.628 237.337 231.322 237.567 230.97 237.696C228.893 238.448 226.702 238.837 224.492 238.845C221.709 238.862 218.968 238.165 216.533 236.822Z"
      fill="#A2E5EF"
    />
    <path
      d="M229.599 204.806C229.368 204.64 229.176 204.424 229.04 204.174C228.903 203.925 228.824 203.648 228.809 203.364L228.084 190.278C228.012 188.981 228.425 187.704 229.242 186.693C230.06 185.681 231.224 185.007 232.511 184.8C233.798 184.594 235.116 184.869 236.211 185.573C237.307 186.277 238.101 187.36 238.442 188.613L241.627 200.313C241.759 200.802 241.692 201.322 241.439 201.761C241.187 202.199 240.77 202.52 240.28 202.653L231.221 205.1C230.946 205.175 230.658 205.187 230.377 205.136C230.096 205.085 229.831 204.972 229.599 204.806Z"
      fill="#A2E5EF"
    />
    <path
      d="M221.138 173.704C226.909 173.704 231.588 169.043 231.588 163.294C231.588 157.545 226.909 152.884 221.138 152.884C215.366 152.884 210.687 157.545 210.687 163.294C210.687 169.043 215.366 173.704 221.138 173.704Z"
      fill="#FFB8B8"
    />
    <path
      d="M183.269 183.746C183.321 183.974 183.355 184.207 183.371 184.441L201.648 194.944L206.09 192.397L210.826 198.573L201.323 205.321L180.471 188.934C179.62 189.248 178.694 189.296 177.816 189.072C176.937 188.849 176.148 188.364 175.552 187.682C174.957 187.001 174.584 186.155 174.483 185.257C174.382 184.36 174.558 183.453 174.987 182.657C175.416 181.861 176.078 181.214 176.886 180.803C177.693 180.391 178.606 180.234 179.505 180.352C180.404 180.47 181.246 180.858 181.918 181.464C182.591 182.071 183.062 182.866 183.269 183.746Z"
      fill="#FFB8B8"
    />
    <path
      d="M202.301 194.052C202.27 193.769 202.304 193.483 202.399 193.215C202.494 192.947 202.649 192.704 202.851 192.503L212.153 183.235C213.075 182.316 214.305 181.771 215.607 181.703C216.909 181.634 218.189 182.049 219.203 182.866C220.216 183.683 220.889 184.845 221.094 186.127C221.298 187.409 221.018 188.722 220.309 189.811L213.684 199.98C213.407 200.404 212.973 200.701 212.476 200.806C211.98 200.912 211.462 200.816 211.036 200.542L203.163 195.452C202.923 195.298 202.721 195.092 202.572 194.849C202.423 194.607 202.33 194.335 202.301 194.052Z"
      fill="#A2E5EF"
    />
    <path
      d="M228.533 171.529C226.583 173.598 222.963 172.487 222.709 169.661C222.689 169.442 222.69 169.221 222.713 169.002C222.844 167.75 223.57 166.613 223.396 165.292C223.357 164.963 223.234 164.649 223.039 164.381C221.485 162.309 217.838 165.308 216.372 163.432C215.473 162.281 216.53 160.47 215.84 159.185C214.93 157.488 212.233 158.325 210.541 157.396C208.66 156.362 208.772 153.485 210.011 151.736C211.521 149.603 214.17 148.464 216.786 148.3C219.401 148.136 221.999 148.84 224.441 149.788C227.216 150.866 229.967 152.354 231.674 154.785C233.751 157.74 233.951 161.713 232.912 165.169C232.28 167.272 230.125 169.84 228.533 171.529Z"
      fill="#002033"
    />
    <path
      d="M313.575 309H0.425474C0.312631 309 0.20441 308.955 0.124618 308.876C0.0448263 308.796 0 308.689 0 308.576C0 308.464 0.0448263 308.356 0.124618 308.276C0.20441 308.197 0.312631 308.152 0.425474 308.152H313.575C313.687 308.152 313.796 308.197 313.875 308.276C313.955 308.356 314 308.464 314 308.576C314 308.689 313.955 308.796 313.875 308.876C313.796 308.955 313.687 309 313.575 309Z"
      fill="#CCCCCC"
    />
  </svg>
);
