import styled from "styled-components";

import {
  ORANGE,
  RBBLACK,
  RBGREEN,
  RBGREY,
  RBRED,
} from "@patchworkhealth/web-components";

import { ExceptionReportStatusEnum } from "../../../../baseCODEGEN";

const exceptionReportStatusThemes = (status: ExceptionReportStatusEnum) => {
  switch (status) {
    case ExceptionReportStatusEnum.Draft:
      return {
        background: RBGREY.two,
        color: RBBLACK.four,
      };
    case ExceptionReportStatusEnum.Pending:
      return {
        background: ORANGE.one,
        color: ORANGE.six,
      };
    case ExceptionReportStatusEnum.Approved:
      return {
        background: RBGREEN.one,
        color: "#0E6728",
      };

    case ExceptionReportStatusEnum.Rejected:
    case ExceptionReportStatusEnum.RejectedDispute:
      return {
        background: RBRED.one,
        color: RBRED.six,
      };

    default:
      return {
        background: ORANGE.one,
        color: ORANGE.six,
      };
  }
};

export const StatusContainer = styled.div<{
  status: ExceptionReportStatusEnum;
}>`
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: fit-content;
  text-align: center;
  padding: 2px 0;
  justify-content: center;
  text-transform: uppercase;
  width: 71px;
  border-radius: 4px;
  background: ${({ status }) => exceptionReportStatusThemes(status).background};
  color: ${({ status }) => exceptionReportStatusThemes(status).color};
`;
