import { Dispatch, useCallback, useState } from "react";
import dayjs from "dayjs";

import {
  RosteringWorkerRostersQuery,
  useRosteringWorkerRostersQuery,
} from "components/TeamRota/__generated__/TeamRota.generated";

import { RosterStatusEnum } from "../../../baseCODEGEN";

export type SingleRosterType = NonNullable<
  RosteringWorkerRostersQuery["rosteringWorkerRosters"]
>["nodes"][number];

export const useWorkerRoster = (
  setWeekStartDate: Dispatch<Date>
): {
  roster: SingleRosterType | undefined;
  setRoster: Dispatch<SingleRosterType | undefined>;
  workerRosters: RosteringWorkerRostersQuery | undefined;
} => {
  const [roster, setRoster] = useState<SingleRosterType>();
  const setWeekStartDateAndRoster = useCallback(
    (roster: SingleRosterType) => {
      setRoster(roster);
      setWeekStartDate(new Date(roster.rota.startDate));
    },
    [setWeekStartDate]
  );

  const { data: workerRosters } = useRosteringWorkerRostersQuery({
    onCompleted: (data) => {
      const onlyPublishedRosters = data.rosteringWorkerRosters?.nodes.filter(
        ({ status }) => status === RosterStatusEnum.Published
      );

      if (onlyPublishedRosters) {
        const rosteringWorkerRostersNodesCopy =
          structuredClone(onlyPublishedRosters);

        const sortedRostersByDate = rosteringWorkerRostersNodesCopy.sort(
          (a, b) =>
            new Date(b.rota.startDate).getTime() -
            new Date(a.rota.startDate).getTime()
        );

        const firstActiveRoster = sortedRostersByDate.find((roster) =>
          dayjs().isBetween(roster.rota.startDate, roster.rota.endDate)
        );

        if (firstActiveRoster) {
          setWeekStartDateAndRoster(firstActiveRoster);
          return;
        }

        const firstActiveRosterInFuture = sortedRostersByDate.find(
          (roster) =>
            new Date(roster.rota.startDate).getTime() > new Date().getTime()
        );

        if (firstActiveRosterInFuture) {
          setWeekStartDateAndRoster(firstActiveRosterInFuture);
          return;
        }

        const firstRosteringInPast = sortedRostersByDate.find(
          (roster) =>
            new Date(roster.rota.startDate).getTime() < new Date().getTime()
        );

        if (firstRosteringInPast) {
          setWeekStartDateAndRoster(firstRosteringInPast);
          return;
        }
      }
    },
  });

  const sortedWorkerRosterNodes = structuredClone(
    workerRosters
  )?.rosteringWorkerRosters?.nodes.sort(
    (a, b) =>
      new Date(b.rota.startDate).getTime() -
      new Date(a.rota.startDate).getTime()
  );

  return {
    roster,
    setRoster,
    workerRosters: {
      ...workerRosters,
      rosteringWorkerRosters: {
        ...workerRosters?.rosteringWorkerRosters,
        nodes: sortedWorkerRosterNodes ?? [],
      },
    },
  };
};
