import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { IdleTimer, IdleTimerContext } from "@patchworkhealth/web-components";

import MobileModal from "containers/mobile";
import { AppContext } from "context/AppContext";
import { GET_ACCCOUNT_TYPE } from "gql/Queries";
import { beamer } from "helpers/beamer";
import { deleteTokens, getTokens } from "helpers/functions";
import PrivateRoutes from "routes/PrivateRoutes";
import PublicRoutes from "routes/PublicRoutes";

function App() {
  const [hasTimedOut, setHasTimedOut] = useState(false);
  const store = useContext(AppContext);
  const token = getTokens();
  const history = useHistory();

  const IdleTimerContextValue = useMemo(
    () => ({ hasTimedOut, setHasTimedOut }),
    [hasTimedOut]
  );

  useQuery(GET_ACCCOUNT_TYPE, {
    skip: !token,
    onCompleted: (data) => {
      if (data?.currentAccount) {
        const productTypes = data.currentAccount.productTypes;
        const Bank = productTypes.includes("BANK");
        const Rostering = productTypes.includes("ROSTERING");
        let type = -1;

        if (Bank && Rostering) {
          type = 2;
        } else if (Rostering) {
          type = 1;
        } else if (Bank) {
          type = 0;
        }

        if (type === -1) {
          deleteTokens();
          window.location.reload();
        }

        store.setProductType(type);
      }
    },
    onError: () => {
      deleteTokens();
      window.location.reload();
    },
  });

  useEffect(() => {
    store.startApp();
  }, []);

  const logout = () => {
    deleteTokens();
    store.setUser(null);
    history.push("/");
    beamer.destroy();
  };

  const onTimeout = () => {
    logout();
    setHasTimedOut(true);
  };

  if (!token)
    return (
      <IdleTimerContext.Provider value={IdleTimerContextValue}>
        <PublicRoutes />
      </IdleTimerContext.Provider>
    );

  return store.user ? (
    <>
      <MobileModal />
      <PrivateRoutes />
      <IdleTimer onTimeout={onTimeout} logout={logout} />
      <>
        {!!store.user.uuid &&
          beamer.attachAndInit({
            email: store?.user?.email,
            firstName: store?.user?.firstName,
            lastName: store?.user?.lastName,
            uuid: store?.user?.uuid,
          })}
      </>
    </>
  ) : null;
}

export default App;
