import { CurrentWorkerQuery } from "context/__generated__/AppContext.generated";

import {
  RosteringEventsQuery,
  RosteringExceptionReportQuery,
  RosteringExceptionReportReasonCategoriesQuery,
  RosteringExceptionReportsQuery,
} from "./__generated__/ExceptionReports.generated";

export type ExceptionReportsType = NonNullable<
  RosteringExceptionReportsQuery["rosteringExceptionReports"]
>[number];

export type ExceptionReportType =
  RosteringExceptionReportQuery["rosteringExceptionReport"];

export type RosteringExceptionReportReasonCategory =
  RosteringExceptionReportReasonCategoriesQuery["rosteringExceptionReportReasonCategories"][number];

export type RosteringExceptionReportCategory = Omit<
  RosteringExceptionReportReasonCategory,
  "rosteringExceptionReportReasons"
>;

export type RosteringExceptionReportReasons = Pick<
  RosteringExceptionReportReasonCategory,
  "rosteringExceptionReportReasons"
>["rosteringExceptionReportReasons"];

export type RosteringExceptionReportNotesType =
  NonNullable<ExceptionReportType>["rosteringExceptionReportNotes"];

export type RosteringExceptionReportSupervisorsType = NonNullable<
  CurrentWorkerQuery["currentWorker"]
>["workersSupervisors"];

export type RosteringShiftEventType = NonNullable<
  RosteringEventsQuery["rosteringEvents"]
>["nodes"][number];

export type SupervisorsType = NonNullable<
  CurrentWorkerQuery["currentWorker"]
>["workersSupervisors"];

export const enum ExceptionReportPages {
  ExceptionReportHome,
  ManualForm,
  ShiftCalendar,
  ShiftForm,
  ReviewPage,
  SuccessPage,
  DisputeForm,
}

export type WorkerLatestShiftType =
  | { startsAt: string; endsAt: string }
  | undefined
  | null;
