import { FormEvent, useState } from "react";
import { Container } from "react-bootstrap";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";

import { ForgottenPassword } from "components/Auth/ForgottenPassword";
import LayoutAuth from "components/Layout/LayoutAuth";
import { Wrapper } from "components/Style.js";
import { FORGOT_PASSWORD_MUTATION } from "gql/Mutations";

const ForgottenPasswordPage = () => {
  const [email, setEmail] = useState("");

  const [ForgotPasswordMutation, { loading }] = useMutation(
    FORGOT_PASSWORD_MUTATION
  );

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    ForgotPasswordMutation({
      variables: {
        email: email,
      },
    }).then((response) => {
      if (response.data === null || response.data === undefined) {
        toast.error("Something went wrong, try again.");
        return;
      }
      toast.success("Email has been sent, please check your inbox!");
    });
  };

  return (
    <LayoutAuth title="Forgotten Password">
      <Wrapper>
        <Container>
          <ForgottenPassword
            onSubmit={onSubmit}
            setEmail={setEmail}
            loading={loading}
          />
        </Container>
      </Wrapper>
    </LayoutAuth>
  );
};

export default ForgottenPasswordPage;
