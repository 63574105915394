import moment from "moment";

import { LeaveRequestComponent } from "components/Shared/LeaveRequests";
import { RosteringShiftList } from "components/Shared/RosteringShiftList";
import { CalendarBankShifts } from "components/UI";
import { classNames } from "helpers/newHelpers";
import { NoBankShifts } from "icons/calendarIcons";
import { DayOffCalendar } from "icons/ShiftIcons";

import "react-datepicker/dist/react-datepicker.css";
import { LeaveRequestFragmentFragment } from "./__generated__/Calendar.generated";
import {
  CalendarInputs,
  DayData,
  FormatDate,
  MonthData,
  MultiRotaCard,
  MultiShiftClass,
} from "./CalendarHelpers";

interface Props {
  inputs: CalendarInputs;
  calendarData: MonthData;
  setInputs: (inputs: CalendarInputs) => void;
  activitiesLabelForShift: (id: number, name: string | undefined) => string;
}

const CalendarBody = ({
  inputs,
  calendarData,
  setInputs,
  activitiesLabelForShift,
}: Props) => {
  /* State ----------------------------------------------------------- */
  const { calendarTab } = inputs;

  const handleBankClick = (date: string) =>
    setInputs({
      ...inputs,
      page: "Day",
      selectedDate: date,
    });

  const handleRotaClick = (shiftId: string) => {
    setInputs({
      ...inputs,
      selectedShiftId: shiftId,
    });
  };

  /* Leave Click Modal ----------------------------------------------------------- */

  const handleLeaveClick = (shift: LeaveRequestFragmentFragment) => {
    setInputs({
      ...inputs,
      showLeaveModal: true,
      selectedLeaveShift: shift,
    });
  };

  /* Rota Row --------------------------------------------------------------- */

  const renderRotaRow = (day: DayData) => {
    if (day.rota.length === 1 || day.rota.length === 2) {
      return (
        <RosteringShiftList
          activitiesLabelForShift={activitiesLabelForShift}
          onShiftClick={(shift) => handleRotaClick(shift.id)}
          isMultipleShifts={day.rota.length > 1}
          shifts={day.rota}
          styles={{
            padding: "0 16px 0 16px",
            height: "51px",
          }}
        />
      );
    }

    if (day.rota.length > 2 || day.leave.length > 1)
      return (
        <MultiRotaCard
          activitiesLabelForShift={activitiesLabelForShift}
          day={day}
          handleRotaClick={handleRotaClick}
          handleLeaveClick={handleLeaveClick}
        />
      );

    if (day.leave.length === 0) return <DayOffCalendar />;
    if (day.leave.length === 1)
      return (
        <LeaveRequestComponent
          leaveType={day?.leave[0]?.rosteringLeaveType?.leaveType}
          status={day?.leave[0]?.status || ""}
          onClick={() => handleLeaveClick(day.leave[0])}
        />
      );
  };

  /* Leave Row --------------------------------------------------------------- */

  const renderLeaveRow = (day: DayData) => {
    if (day.leave.length === 1) {
      return (
        <LeaveRequestComponent
          leaveType={day?.leave[0]?.rosteringLeaveType?.leaveType}
          status={day?.leave[0]?.status || ""}
          onClick={() => handleLeaveClick(day.leave[0])}
        />
      );
    }
  };

  /* BANK ROW --------------------------------------------------------------- */

  const renderBankRow = (day: DayData) => (
    <div>
      {day.bank.length === 0 && <NoBankShifts />}
      {day.bank.length < 3 &&
        day.bank.map((shift) => (
          <CalendarBankShifts
            key={day.date + shift.status}
            date={day.date}
            onClick={() => handleBankClick(day.date)}
            {...shift}
          />
        ))}

      {day.bank.length >= 3 && (
        <div
          className={MultiShiftClass}
          onClick={() => handleBankClick(day.date)}
        >
          + {day.bank.length} More
        </div>
      )}
    </div>
  );

  return (
    <div className="bg-white w-[1021px] min-h-[600px] mx-auto px-8 pt-8 shadow border border-grey-1 rounded-lg">
      <div className="flex mb-[10px] w-full text-xs text-gray-600">
        {["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"].map((weekday) => (
          <div key={weekday} className="w-[125px] mx-[6px]">
            {weekday}
          </div>
        ))}
      </div>
      <div className="flex flex-wrap w-full">
        {calendarData?.map((day) => (
          <div
            className={classNames(
              "w-[125px] mx-[6px] border-t pt-[10px] relative",
              moment(day.date).isSame(moment(), "day")
                ? "border-blue-6 border-t-2"
                : "border-grey-2",
              calendarTab === "All" ? "min-h-[170px]" : "min-h-[120px]"
            )}
            key={day.date}
          >
            <FormatDate date={day.date} inputs={inputs} />

            {/* Rota & Leave Row ----------------------------------------- */}

            {calendarTab !== "Bank" && (
              <div className="mb-2">
                {calendarTab === "All" || calendarTab === "Rota"
                  ? renderRotaRow(day)
                  : renderLeaveRow(day)}
              </div>
            )}

            {(calendarTab === "Bank" || calendarTab === "All") &&
              renderBankRow(day)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarBody;
