import React, { ChangeEvent, Dispatch, SetStateAction } from "react";
import { ApolloCache } from "@apollo/client";
import {
  DefaultContext,
  MutationUpdaterFunction,
} from "@apollo/client/core/types";

import {
  RosteringExceptionReportsDocument,
  RosteringExceptionReportsQuery,
  RosteringExceptionReportsQueryVariables,
} from "components/ExceptionReports/__generated__/ExceptionReports.generated";
import {
  RosteringExceptionReportCreateMutation,
  RosteringExceptionReportCreateMutationVariables,
} from "components/ExceptionReports/ExceptionReportForms/ManualExceptionReportForm/__generated__/ManualExceptionReportForm.generated";
import { FormError } from "helpers/types";

export const onToggleChange = <T extends Record<string, unknown>>(
  setError: Dispatch<SetStateAction<FormError<T> | null>>,
  setInput: Dispatch<SetStateAction<T>>
) => {
  return (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    const { name } = e.currentTarget;
    setError(null);
    setInput((prev) => ({ ...prev, [name]: index }));
  };
};

export const onInputChange = <T extends Record<string, unknown>>(
  setError: Dispatch<SetStateAction<FormError<T> | null>>,
  setInput: Dispatch<SetStateAction<T>>
) => {
  return (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setError(null);
    setInput((prev) => ({ ...prev, [name]: value }));
  };
};

export const updateExceptionReportTableCache = (
  orgId: number
): MutationUpdaterFunction<
  RosteringExceptionReportCreateMutation,
  RosteringExceptionReportCreateMutationVariables,
  DefaultContext,
  ApolloCache<unknown>
> => {
  return (cache, { data: mutationResult }) => {
    const cacheParams = {
      query: RosteringExceptionReportsDocument,
      variables: { organisationId: orgId },
    };

    cache.updateQuery<
      RosteringExceptionReportsQuery,
      RosteringExceptionReportsQueryVariables
    >(cacheParams, (rosteringExceptionReportQueryCache) => {
      const newExceptionReport =
        mutationResult?.rosteringExceptionReportCreate
          ?.rosteringExceptionReport;

      return {
        __typename: "Query",
        rosteringExceptionReports:
          (
            rosteringExceptionReportQueryCache?.rosteringExceptionReports ?? []
          ).concat(newExceptionReport || []) ?? [],
      };
    });
  };
};
