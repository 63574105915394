import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import dayjs from "dayjs";
import moment from "moment";

import { Loading } from "@patchworkhealth/web-components";

import Layout from "components/Layout/Layout";
import {
  ShiftCardFragment,
  useWorkersShiftsQuery,
} from "components/ShiftsNew/__generated__/Shifts.generated";
import { ShiftCard } from "components/ShiftsNew/ShiftCard";
import { ShiftDateBadge } from "components/ShiftsNew/ShiftHelpers";
import { ShiftsModal } from "components/ShiftsNew/ShiftsModal";
import { Button } from "components/UI";

import { CalendarInputs } from "./CalendarHelpers";

interface Props {
  inputs: CalendarInputs;
  setInputs: (inputs: CalendarInputs) => void;
}

function CalendarSingle({ inputs, setInputs }: Props) {
  /* State --------------------------------------------------------------- */

  const [openModal, setOpenModal] = useState(false);
  const [shift, setShift] = useState<ShiftCardFragment | null>(null);

  /* Graphql --------------------------------------------------------------- */

  const {
    data: { shifts } = {},
    loading,
    error,
    fetchMore,
  } = useWorkersShiftsQuery({
    skip: !inputs.selectedDate,
    variables: {
      fromStartTime: inputs.selectedDate,
      toStartTime: inputs.selectedDate,
    },
  });

  const fetchMoreShifts = async () => {
    await fetchMore({
      variables: {
        offset: shifts?.length ?? 0,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          ...prev,
          shifts: [...prev.shifts, ...fetchMoreResult.shifts],
        };
      },
    });
  };

  /* Function --------------------------------------------------------------- */

  const toggleModal = (shift: ShiftCardFragment | null = null) => {
    setOpenModal(!openModal);
    setShift(shift);
  };

  const handleDate = (type: string) => {
    setInputs({
      ...inputs,
      selectedDate:
        type === "previous"
          ? moment(inputs.selectedDate).subtract(1, "day").format("YYYY-MM-DD")
          : moment(inputs.selectedDate).add(1, "day").format("YYYY-MM-DD"),
    });
  };

  const handleBackButton = () => {
    setInputs({
      ...inputs,
      page: "Month",
      selectedDate: null,
    });
  };

  const groupedShifts: { [key: string]: ShiftCardFragment[] } = {};
  shifts?.forEach((shift) => {
    const dateStr = dayjs(shift.startTime).format("YYYY-MM-DD");
    if (groupedShifts[dateStr]) {
      groupedShifts[dateStr].push(shift);
    } else {
      groupedShifts[dateStr] = [shift];
    }
  });

  return (
    <Layout headerValueText="Calendar" navigation="calendar_single">
      <div className="hidden md:block p-5 bg-white border border-r-1 fixed top-[63px] w-[316px] z-10 left-0 bottom-0">
        <div className="flex items-center justify-center w-full mb-8">
          <button
            className="text-gray-500 hover:text-gray-700 hover:bg-grey-2 w-10 rounded-full text-[22px]"
            onClick={() => handleDate("previous")}
          >
            &lt;
          </button>
          <div className="text-center month text-lg font-bold w-[170px]">
            {moment(inputs.selectedDate).format("Do MMMM YY")}
          </div>
          <button
            className="text-gray-500 hover:text-gray-700 hover:bg-grey-2 w-10 rounded-full text-[22px]"
            onClick={() => handleDate("next")}
          >
            &gt;
          </button>
        </div>

        <Button onClick={handleBackButton} fullWidth variant="blue">
          Back to Calendar
        </Button>
      </div>

      {/* MAIN --------------------------------------------------------------------- */}

      <div className="md:ml-[300px] lg:ml-[100px]">
        <div className="block mb-8 md:hidden">
          <Button onClick={handleBackButton} variant="blue">
            Back to Calendar
          </Button>
        </div>

        {loading && !shifts && <Loading />}
        {!error && !!shifts?.length && (
          <InfiniteScroll
            dataLength={shifts.length}
            next={fetchMoreShifts}
            hasMore={true}
            style={{ overflow: "hidden" }}
            loader={loading && <Loading />}
          >
            {Object.keys(groupedShifts).map((date) => (
              <>
                <ShiftDateBadge date={date} />
                <div className="flex flex-wrap pb-8 mb-10 border-b border-b-grey-2">
                  {groupedShifts[date].map((shift) => (
                    <div
                      className="flex w-full justify-center sm:p-0 md:w-[370px] md:justify-start"
                      key={shift.id}
                    >
                      <ShiftCard
                        shift={shift}
                        key={shift.id}
                        onClick={toggleModal}
                      />
                    </div>
                  ))}
                </div>
              </>
            ))}
          </InfiniteScroll>
        )}

        {error && (
          <p className="font-bold text-center">
            Unable to load shifts, please refresh the page.
          </p>
        )}
        {!loading && shifts?.length === 0 && (
          <p className="font-bold text-center">No shifts found.</p>
        )}

        {/* Shift Modal ------------------------------------------------------------- */}

        {openModal && (
          <ShiftsModal
            handleClose={toggleModal}
            openModal={openModal}
            selectedShift={shift}
          />
        )}
      </div>
    </Layout>
  );
}

export default CalendarSingle;
