export const LogoIcon = () => (
  <svg
    width="1126"
    height="212"
    viewBox="0 0 1126 212"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M185.816 13.6057C173.893 -0.663334 153.108 -3.93414 130.226 4.86186C104.463 14.7525 79.8083 38.4561 62.5681 69.8741C53.4072 86.5799 46.3574 105.319 41.5098 125.647C40.8192 125.035 40.1416 124.422 39.477 123.771C28.2702 112.668 23.3836 96.249 25.6901 77.5233C28.153 57.5988 38.3563 37.7003 54.3976 21.5026C54.4367 21.4635 54.4758 21.4374 54.5019 21.3983L36.8577 3.51965C36.7535 3.6239 36.6623 3.71512 36.558 3.80634C16.6204 23.9394 3.90206 49.0243 0.76156 74.435C-2.53531 101.058 4.94455 124.904 21.7938 141.61C26.2374 146.015 31.1762 149.716 36.4668 152.739C34.043 170.552 33.1439 189.304 33.8085 208.747H58.9324C58.3069 191.884 58.9715 176.091 60.7047 161.379C65.7738 162.291 70.9862 162.747 76.2899 162.747C78.7918 162.747 81.3199 162.616 83.8609 162.408C97.7521 161.587 111.383 158.016 124.296 151.696C144.443 141.845 162.869 125.165 176.173 104.745C198.652 70.2389 202.535 33.6085 185.816 13.5927M155.128 91.0236C151.115 97.1742 146.332 103.208 140.872 108.798C126.056 123.458 108.385 132.697 91.406 136.228C83.027 137.727 74.1528 137.936 64.8486 136.476C77.7103 77.2627 108.855 39.9807 139.23 28.3179C151.44 23.6267 161.891 24.1609 166.53 29.7122C174.414 39.1467 172.303 64.6747 155.128 91.0366"
      fill="#5EF078"
    />
    <path
      d="M263.26 112.238C257.018 103.325 243.648 95.0762 227.594 95.0762C197.049 95.0762 173.867 121.151 173.867 153.026C173.867 184.9 197.049 210.988 227.594 210.988C243.648 210.988 257.018 202.739 263.26 193.826V208.76H287.785V97.3175H263.26V112.251V112.238ZM232.051 188.692C212.217 188.692 198.834 173.093 198.834 153.026C198.834 132.958 212.217 117.359 232.051 117.359C251.884 117.359 265.267 132.971 265.267 153.026C265.267 173.08 251.897 188.692 232.051 188.692Z"
      fill="#5EF078"
    />
    <path
      d="M350.008 97.3177H381.882V119.158H350.008V168.871C350.008 182.684 356.029 187.819 368.734 187.819C373.412 187.819 378.325 186.92 381.882 185.59V207.431C376.983 208.994 371.184 209.88 363.6 209.88C342.203 209.88 325.484 198.061 325.484 172.872V119.145H302.301V97.3046H325.484V66.3167H350.008V97.3046V97.3177Z"
      fill="#5EF078"
    />
    <path
      d="M414.773 153.039C414.773 172.885 428.82 188.262 448.654 188.262C462.923 188.262 473.843 180.013 479.199 168.65L500.596 180.235C491.005 198.739 471.615 211.001 448.654 211.001C415.216 211.001 389.805 186.034 389.805 153.039C389.805 120.044 415.216 95.0896 448.654 95.0896C471.615 95.0896 491.005 107.352 500.596 125.856L479.199 137.441C473.843 126.078 462.923 117.829 448.654 117.829C428.82 117.829 414.773 133.219 414.773 153.052"
      fill="#5EF078"
    />
    <path
      d="M619.27 141.676V208.773H594.746V145.689C594.746 128.071 585.832 117.373 571.563 117.373C555.287 117.373 542.804 130.078 542.804 153.938V208.773H518.279V41.5837H542.804V112.473C549.489 101.331 562.194 95.0895 576.684 95.0895C602.538 95.0895 619.257 113.372 619.257 141.676"
      fill="#5EF078"
    />
    <path
      d="M772.294 97.3179H798.37L760.918 208.773H737.736L713.211 136.542L688.921 208.773H665.739L628.288 97.3179H654.141L678.223 173.993L703.855 97.3179H722.581L747.991 173.993L772.294 97.3179Z"
      fill="#5EF078"
    />
    <path
      d="M859.89 95.0762C826.895 95.0762 801.484 120.708 801.484 153.026C801.484 185.343 826.895 210.988 859.89 210.988C892.884 210.988 918.295 185.356 918.295 153.026C918.295 120.695 892.884 95.0762 859.89 95.0762ZM859.89 188.262C840.056 188.262 826.23 172.872 826.23 153.039C826.23 133.205 840.056 117.815 859.89 117.815C879.723 117.815 893.327 133.205 893.327 153.039C893.327 172.872 879.501 188.262 859.89 188.262Z"
      fill="#5EF078"
    />
    <path
      d="M992.286 96.1968C995.413 96.1968 998.528 96.4183 1001.86 97.0959V122.285C997.628 120.943 994.058 120.278 989.601 120.278C975.554 120.278 962.848 132.084 962.848 154.381V208.773H938.324V97.3175H962.848V117.151C967.083 104.445 979.567 96.1968 992.273 96.1968"
      fill="#5EF078"
    />
    <path
      d="M1125.89 208.773H1094.46L1041.19 154.824V208.773H1016.67V41.5837H1041.19V139.669L1083.76 97.3178H1114.75L1065.04 147.018L1125.89 208.773Z"
      fill="#5EF078"
    />
  </svg>
);

export const PaperClipIcon = () => (
  <svg width="27" height="61" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.14 29.2l-.097-15.83c0-4.693 3.811-8.505 8.512-8.506a8.514 8.514 0 018.512 8.512l.098 37.346a5.32 5.32 0 01-10.64-.008V19.858v-.006a2.127 2.127 0 014.255.006l.001 16.354h4.256l-.001-16.354a6.383 6.383 0 10-12.767 0v30.857a9.576 9.576 0 0019.152.001c-.023-12.917-.15-21.052-.098-37.346 0-7.044-5.717-12.76-12.768-12.762C6.503.61.787 6.326.787 13.376l.098 15.836L5.14 29.2z"
      fill="#1E2D36"
      fillRule="nonzero"
    />
  </svg>
);

export const VerifyIcon = () => (
  <svg
    width="220"
    height="241"
    viewBox="0 0 220 241"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <mask
        id="mask0"
        maskUnits="userSpaceOnUse"
        x="16"
        y="14"
        width="188"
        height="209"
      >
        <path
          d="M24.9364 86.5259L98.4808 19.0766C105.982 12.1974 117.535 12.3245 124.883 19.3672L195.006 86.5806C200.432 91.7811 203.5 98.9711 203.5 106.487V202.258C203.5 213.68 194.241 222.939 182.82 222.939H36.6801C25.2588 222.939 16 213.68 16 202.258V106.847C16 99.1205 19.2419 91.7484 24.9364 86.5259Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <g filter="url(#filter1_d)">
          <path
            d="M24.9364 86.5259L98.4808 19.0766C105.982 12.1974 117.535 12.3245 124.883 19.3672L195.006 86.5806C200.432 91.7811 203.5 98.9711 203.5 106.487V202.258C203.5 213.68 194.241 222.939 182.82 222.939H36.6801C25.2588 222.939 16 213.68 16 202.258V106.847C16 99.1205 19.2419 91.7484 24.9364 86.5259Z"
            fill="black"
          />
        </g>
        <path
          d="M24.9364 86.5259L98.4808 19.0766C105.982 12.1974 117.535 12.3245 124.883 19.3672L195.006 86.5806C200.432 91.7811 203.5 98.9711 203.5 106.487V202.258C203.5 213.68 194.241 222.939 182.82 222.939H36.6801C25.2588 222.939 16 213.68 16 202.258V106.847C16 99.1205 19.2419 91.7484 24.9364 86.5259Z"
          fill="#FFF6DB"
        />
        <g filter="url(#filter2_d)">
          <path
            d="M16 94.7216L101.69 156.469C106.504 159.937 112.996 159.937 117.81 156.469L203.5 94.7216V209.152C203.5 216.766 197.327 222.939 189.713 222.939H29.7868C22.1725 222.939 16 216.766 16 209.152V94.7216Z"
            fill="black"
          />
        </g>
        <path
          d="M16 94.7216L101.69 156.469C106.504 159.937 112.996 159.937 117.81 156.469L203.5 94.7216V209.152C203.5 216.766 197.327 222.939 189.713 222.939H29.7868C22.1725 222.939 16 216.766 16 209.152V94.7216Z"
          fill="#FDFEFF"
        />
        <g filter="url(#filter3_d)">
          <path
            d="M117.81 161.191L203.5 222.939H16L101.69 161.191C106.504 157.723 112.996 157.723 117.81 161.191Z"
            fill="black"
          />
        </g>
        <path
          d="M117.81 161.191L203.5 222.939H16L101.69 161.191C106.504 157.723 112.996 157.723 117.81 161.191Z"
          fill="white"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.256 191.548C109.856 191.548 109.723 194.144 109.723 194.144V202.755H116.09C116.09 196.672 114.657 191.548 112.256 191.548Z"
        fill="#212932"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.723 202.753V211.364C109.723 211.364 109.59 213.96 107.19 213.96C104.789 213.96 103.356 208.836 103.356 202.753H109.723Z"
        fill="#FFD24C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.93 205.291C120.93 202.891 118.334 202.758 118.334 202.758H109.723V209.125C115.806 209.125 120.93 207.692 120.93 205.291Z"
        fill="#2BC0D4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.723 202.758H101.112C101.112 202.758 98.5158 202.891 98.5158 205.291C98.5158 207.692 103.64 209.125 109.723 209.125V202.758Z"
        fill="#2BC0D4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.5 200.224C98.5 202.625 101.096 202.758 101.096 202.758H109.707V196.391C103.624 196.391 98.5 197.824 98.5 200.224Z"
        fill="#FF7F4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.712 202.758H118.323C118.323 202.758 120.92 202.625 120.92 200.224C120.92 197.824 115.795 196.391 109.712 196.391V202.758Z"
        fill="#FF7F4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.723 202.758V211.369C109.723 211.369 109.856 213.965 112.256 213.965C114.657 213.965 116.09 208.841 116.09 202.758H109.723Z"
        fill="#212932"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.723 202.758V194.147C109.723 194.147 109.591 191.551 107.19 191.551C104.79 191.551 103.357 196.675 103.357 202.758H109.723Z"
        fill="#FFD24C"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="219.5"
        height="240.939"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="8" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.75575 0 0 0 0 0.894522 0 0 0 0 0.912428 0 0 0 0.6 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d"
        x="-19"
        y="-3"
        width="257.5"
        height="278.939"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="18" />
        <feGaussianBlur stdDeviation="17.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.226735 0 0 0 0 0.437054 0 0 0 0 0.687819 0 0 0 0.0769928 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d"
        x="-7"
        y="66.7216"
        width="233.5"
        height="174.217"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="-5" />
        <feGaussianBlur stdDeviation="11.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.108708 0 0 0 0 0.237823 0 0 0 0 0.391768 0 0 0 0.0690104 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d"
        x="7"
        y="144.59"
        width="205.5"
        height="82.3487"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="-5" />
        <feGaussianBlur stdDeviation="4.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.252325 0 0 0 0 0.423517 0 0 0 0 0.627631 0 0 0 0.02607 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
