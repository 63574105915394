import { ChangeEvent, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";
import { GraphQLErrors } from "@apollo/client/errors";
import dayjs from "dayjs";
import { GraphQLErrorExtensions } from "graphql/error";
import moment from "moment";
import styled from "styled-components";

import {
  BLUE,
  FormGrid,
  FormGroup,
  FormInput,
  FormLabel,
  FormSelect,
  GLOBAL,
  GREY,
  ModalHeader,
  ModalTitle,
  ORANGE,
} from "@patchworkhealth/web-components";

import {
  RosteringLeaveRequestsDocument,
  useRosteringLeaveReasonsQuery,
  useRosteringLeaveRequestCalculationQuery,
  useRosteringLeaveRequestCreateMutation,
} from "components/LeaveManagement/__generated__/LeaveManagement.generated";
import {
  EntitlementInputType,
  LeaveEntitlementType,
} from "components/LeaveManagement/LeaveEntitlementModal/LeaveEntitlementModal.types";
import { LeaveFormattedErrors } from "components/LeaveManagement/LeaveFormattedErrors";
import { checkLeaveType } from "components/LeaveManagement/LeaveManagement.helpers";
import { AppContext } from "context/AppContext";
import { RosteringOrganisationRegistrationsType } from "context/AppContext.types";
import { formatError, formatValidationErrors } from "helpers/formatError";
import { formatTimeInTZ, parseTimeInTZ } from "helpers/momentHelpers";
import { CalendarHeaderIcon, DatePickerChevron } from "icons/calendarIcons";
import { DaysLeftIcon } from "icons/DaysLeftIcon";
import { LeaveInfoIcon } from "icons/leaveManagementIcons";
import { CloseModal } from "icons/referencesIcons";

import { Divider, Loading, StyledButton } from "../../Style";
import { MissedShifts } from "../MissedShifts";
import LeaveSuggestion from "./LeaveSuggestion";

interface Props {
  onHide: () => void;
  leaveEntitlement: LeaveEntitlementType | null;
  chosenOrg: RosteringOrganisationRegistrationsType[number] | undefined | null;
}
export const LeaveEntitlementModal = ({
  onHide,
  leaveEntitlement,
  chosenOrg,
}: Props) => {
  const { user } = useContext(AppContext);

  const [input, setInput] = useState<EntitlementInputType>({
    leaveReason: null,
    startDate: undefined,
    endDate: undefined,
    reasonForLeave: "",
  });

  const [leaveManagementSuggestion, setLeaveManagementSuggestion] =
    useState<GraphQLErrorExtensions | null>(null);

  const [validationErrors, setValidationErrors] = useState<string[] | null>(
    null
  );

  const isDateInRotationPeriod = (date: Date) => {
    if (
      moment(date).isBetween(
        dayjs(leaveEntitlement?.startDate).startOf("day").toDate(),
        dayjs(leaveEntitlement?.endDate).endOf("day").toDate(),
        null,
        "[]"
      )
    )
      return true;

    toast.error("Select a date within the rotation Period");
    return false;
  };

  const onStartDatePick = (date: Date | null) => {
    if (date && isDateInRotationPeriod(date)) {
      setInput((prev) => ({
        ...prev,
        endDate: undefined,
        startDate: parseTimeInTZ(date).toDate(),
      }));
      setLeaveManagementSuggestion(null);
      return;
    }

    setInput((prev) => ({
      ...prev,
      startDate: undefined,
    }));
  };

  const onEndDatePick = (date: Date | null) => {
    if (!input.startDate) {
      toast.error("Please enter start date first");
      return;
    }

    const dateMovedToEndOfDay = input.endDate
      ? date
      : dayjs(date).endOf("day").toDate();

    if (!dateMovedToEndOfDay) {
      return;
    }

    if (dateMovedToEndOfDay.getTime() === new Date(input.startDate).getTime()) {
      toast.error("End date can't be the same as start date");
      return;
    }

    if (dateMovedToEndOfDay.getTime() < new Date(input.startDate).getTime()) {
      toast.error("End date can't be before start date");
      return;
    }

    if (isDateInRotationPeriod(dateMovedToEndOfDay)) {
      setInput((prev) => ({
        ...prev,
        endDate: parseTimeInTZ(dateMovedToEndOfDay).toDate(),
      }));
      setLeaveManagementSuggestion(null);
      return;
    }

    setInput((prev) => ({
      ...prev,
      endDate: undefined,
    }));
  };

  const { data: leaveReasons } = useRosteringLeaveReasonsQuery(
    chosenOrg?.organisation
      ? {
          variables: {
            organisationId: chosenOrg.organisation?.value,
            leaveAbsenceCategory: "Study Leave",
            leaveAbsenceType: "Study Decreasing Bal",
          },
        }
      : { skip: true }
  );

  const [createLeaveRequest] = useRosteringLeaveRequestCreateMutation();

  const validateInputFields = () => {
    if (!input.endDate || !input.startDate) {
      toast.error("Please complete date fields");
      return false;
    }

    if (
      !input.leaveReason &&
      leaveEntitlement?.rosteringLeaveType?.leaveType !== "annual"
    ) {
      toast.error("Please complete reason field");
      return false;
    }

    return true;
  };

  const submitRequest = async () => {
    if (user && validateInputFields()) {
      try {
        await createLeaveRequest({
          variables: {
            organisationId: Number(
              leaveEntitlement?.rosteringOrganisationRegistration?.organisation
                ?.id
            ),
            accountId: +user.id,
            rosteringLeaveEntitlementId: Number(leaveEntitlement?.id),
            startDatetime: input.startDate,
            endDatetime: dayjs(input.endDate).startOf("minute").toISOString(),
            reasonForLeave: input.reasonForLeave,
            rosteringLeaveReasonId: Number(input?.leaveReason?.id),
          },
          refetchQueries: [RosteringLeaveRequestsDocument],
          onCompleted: (data) => {
            if (!data.rosteringLeaveRequestCreate) return;

            if (data?.rosteringLeaveRequestCreate?.errors.length) {
              toast.error(
                formatError(data?.rosteringLeaveRequestCreate?.errors)
              );
              return;
            }
            toast.success("Leave request created");
            onHide();
          },
          onError: (error) => {
            if (error?.graphQLErrors) {
              toast.error(`${error?.graphQLErrors[0].message}`);
            }
          },
        });
      } catch (e) {
        toast.error("There was an error!");
      }
    }
  };

  const isLeaveManagementSuggestion = (graphQLErrors: GraphQLErrors) => {
    return graphQLErrors[0]?.extensions?.type === "lm-validation-suggestion";
  };

  const { data: calculationsData, loading: loadingCalculation } =
    useRosteringLeaveRequestCalculationQuery({
      skip: !input.startDate || !input.endDate,
      fetchPolicy: "no-cache",
      variables: {
        accountId: Number(user?.id),
        organisationId: Number(
          leaveEntitlement?.rosteringOrganisationRegistration?.organisation?.id
        ),
        startDatetime: input.startDate
          ? dayjs(input.startDate).startOf("minute").toISOString()
          : null,
        endDatetime: input.endDate
          ? dayjs(input.endDate).startOf("minute").toISOString()
          : null,
        rosteringLeaveEntitlementId: Number(leaveEntitlement?.id),
      },
      errorPolicy: "all",
      onCompleted: (data) => {
        setValidationErrors(null);
        if (!data.rosteringLeaveRequestCalculation) return;

        if (data?.rosteringLeaveRequestCalculation?.errors?.length) {
          setValidationErrors(
            formatValidationErrors(
              data?.rosteringLeaveRequestCalculation?.errors
            )
          );
          return;
        }
      },
      onError: (error) => {
        if (error?.graphQLErrors) {
          if (isLeaveManagementSuggestion(error?.graphQLErrors)) {
            setLeaveManagementSuggestion(error?.graphQLErrors[0].extensions);
          }
        }
      },
    });

  // const hoursInDay = 8;

  return (
    <>
      <StyledModal show onHide={onHide} centered size="lg">
        <ModalHeader>
          <ModalTitle>
            {leaveEntitlement?.rosteringLeaveType?.leaveType === "annual"
              ? "Request annual leave"
              : leaveEntitlement?.rosteringLeaveType?.leaveType === "study"
              ? "Request study leave"
              : "Add non-entitlement leave"}
          </ModalTitle>

          <RotationPeriod>
            <strong> Rotation Period: </strong>
            {formatTimeInTZ(leaveEntitlement?.startDate, "Do MMM YYYY")} -{" "}
            {formatTimeInTZ(leaveEntitlement?.endDate, "Do MMM YYYY")}
          </RotationPeriod>

          <button className="btn btn-cyan" onClick={onHide}>
            <CloseModal />
          </button>
        </ModalHeader>

        {/* Modal Body Content ******************************************* */}

        <ModalBody>
          <ModalLeft>
            <FormGrid columns={2}>
              <FormGroup data-testid="start_date">
                <FormLabel>Start Date</FormLabel>

                <DatepickerStyle>
                  <span className="span_icon">
                    <CalendarHeaderIcon />
                  </span>

                  <DatePicker
                    selected={input.startDate}
                    onChange={(date) => onStartDatePick(date)}
                    timeInputLabel="Time:"
                    placeholderText="Select Start Date"
                    dateFormat="dd/MM/yyyy  -  h:mm aa"
                    showTimeInput
                    startDate={moment(leaveEntitlement?.startDate).toDate()}
                    maxDate={moment(leaveEntitlement?.endDate).toDate()}
                    minDate={moment(leaveEntitlement?.startDate).toDate()}
                    openToDate={
                      moment().isBetween(
                        leaveEntitlement?.startDate,
                        leaveEntitlement?.endDate
                      )
                        ? moment().toDate()
                        : moment(leaveEntitlement?.startDate).toDate()
                    }
                  />

                  <div className="calendar__Chevron">
                    <DatePickerChevron />
                  </div>
                </DatepickerStyle>
              </FormGroup>

              <FormGroup data-testid="end_date">
                <FormLabel>End Date</FormLabel>

                <DatepickerStyle>
                  <span className="span_icon">
                    <CalendarHeaderIcon />
                  </span>
                  <DatePicker
                    selected={input.endDate}
                    onChange={(date) => onEndDatePick(date)}
                    maxDate={moment(leaveEntitlement?.endDate).toDate()}
                    minDate={moment(leaveEntitlement?.startDate).toDate()}
                    openToDate={input.startDate}
                    timeInputLabel="Time:"
                    placeholderText="Select End Date"
                    dateFormat="dd/MM/yyyy  -  h:mm aa"
                    showTimeInput
                  />

                  <div className="calendar__Chevron">
                    <DatePickerChevron />
                  </div>
                </DatepickerStyle>
              </FormGroup>
            </FormGrid>

            {validationErrors && (
              <FormGrid columns={1} style={{ marginTop: "32px" }}>
                <LeaveFormattedErrors validationErrors={validationErrors} />
              </FormGrid>
            )}

            {leaveManagementSuggestion ? (
              <LeaveSuggestion
                leaveManagementSuggestion={leaveManagementSuggestion}
              />
            ) : (
              <Divider line="none" top="0" bottom="32" />
            )}
            {leaveEntitlement?.rosteringLeaveType?.leaveType === "study" && (
              <FormGroup style={{ width: "50%" }}>
                <FormLabel>Leave Reason</FormLabel>
                <FormSelect
                  options={leaveReasons?.rosteringLeaveReasons?.nodes}
                  placeholder="Select Leave Reason"
                  value={input.leaveReason}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  onChange={(e) =>
                    setInput((prev) => ({ ...prev, leaveReason: e }))
                  }
                />
              </FormGroup>
            )}
            <FormGroup style={{ marginTop: "15px" }}>
              <FormLabel>Notes</FormLabel>

              <FormInput
                as="textarea"
                rows={4}
                placeholder="Enter Notes..."
                value={input.reasonForLeave}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const { value } = e.target;
                  if (value)
                    setInput((prev) => ({
                      ...prev,
                      reasonForLeave: value,
                    }));
                }}
              />
            </FormGroup>
          </ModalLeft>
          <ModalRight>
            <InfoBox>
              <h4>
                {checkLeaveType(leaveEntitlement?.measurementType) + " TAKEN"}
              </h4>
              <h3>
                {`${
                  calculationsData?.rosteringLeaveRequestCalculation
                    ?.shiftAmountTaken ?? "-"
                } ${checkLeaveType(leaveEntitlement?.measurementType)}`}

                {/*{leaveEntitlement?.measurementType === "days"*/}
                {/*  ? `(${*/}
                {/*      (calculationsData?.rosteringLeaveRequestCalculation*/}
                {/*        ?.shiftAmountTaken || 0) * hoursInDay*/}
                {/*    } Hours)`*/}
                {/*  : ""}*/}
              </h3>

              <div className="info__flex">
                <div>
                  <h4>BEGINS</h4>

                  {!input?.startDate ? (
                    <span> - </span>
                  ) : (
                    <div className="time__flex">
                      <span>
                        {formatTimeInTZ(input.startDate, "Do MMM YYYY")}
                      </span>
                      <span>{formatTimeInTZ(input.startDate, "HH:mm a")}</span>
                    </div>
                  )}
                </div>

                <div>
                  <h4>ENDS</h4>
                  {!input?.endDate ? (
                    <span> - </span>
                  ) : (
                    <div className="time__flex">
                      <span>
                        {formatTimeInTZ(input.endDate, "Do MMM YYYY")}
                      </span>
                      <span>{formatTimeInTZ(input.endDate, "HH:mm a")}</span>
                    </div>
                  )}
                </div>
              </div>
            </InfoBox>

            <DaysLeftBox>
              <DaysLeftIcon />
              {input?.endDate ? (
                <p>
                  You will have{" "}
                  {(
                    (leaveEntitlement?.remainingBookableLeave ?? 0) -
                    (calculationsData?.rosteringLeaveRequestCalculation
                      ?.shiftAmountTaken ?? 0)
                  ).toFixed(1)}{" "}
                  {checkLeaveType(leaveEntitlement?.measurementType)} remaining.
                </p>
              ) : (
                <p>
                  You will have {leaveEntitlement?.remainingBookableLeave}{" "}
                  {leaveEntitlement?.measurementType === "pa"
                    ? " PA's"
                    : leaveEntitlement?.measurementType?.toUpperCase()}{" "}
                  remaining.
                </p>
              )}
            </DaysLeftBox>

            <InfoTagline>
              <LeaveInfoIcon />
              <p>
                You can see the status of your leave requests in the 'My Leave
                Record' section
              </p>
            </InfoTagline>
          </ModalRight>
        </ModalBody>

        <div style={{ margin: "0px 35px 0px 25px" }}>
          {loadingCalculation ? (
            <Loading />
          ) : (
            !!calculationsData?.rosteringLeaveRequestCalculation?.missedShifts
              ?.length && (
              <>
                <FormLabel>SHIFTS YOU'LL MISS</FormLabel>
                <MissedShifts
                  missedShifts={
                    calculationsData?.rosteringLeaveRequestCalculation
                      ?.missedShifts
                  }
                />
              </>
            )
          )}

          <Divider line="solid" top="32" bottom="32" />
        </div>

        <ModalFooter>
          <StyledButton
            color="white"
            size="small"
            length="90px"
            onClick={onHide}
            className="modal__footer_cancel"
            data-testid="references__cancel"
          >
            Cancel
          </StyledButton>

          <StyledButton
            size="small"
            color="blue"
            length="177px"
            type="submit"
            data-testid="reference__modal__submit"
            onClick={submitRequest}
            disabled={!!validationErrors && validationErrors?.length > 0}
          >
            Submit Request
          </StyledButton>
        </ModalFooter>
      </StyledModal>
    </>
  );
};

const StyledModal = styled(Modal)`
  .modal-dialog {
    height: auto;
    max-width: 940px;
  }

  @media (max-width: 992px) {
    .modal-content,
    .modal-dialog {
      width: 100% !important;
    }
  }
`;

const ModalBody = styled.div`
  display: flex;
  max-width: 940px;
  padding: 27px 24px;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  label {
    color: rgb(125, 135, 147);
  }
`;

// Left Side ****************************

const ModalRight = styled.div`
  flex: 0 0 311px;
`;

const ModalLeft = styled.div`
  flex: auto;

  textarea {
    background: ${GLOBAL.white};
    border: 1px solid ${GREY.three};
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    color: ${GREY.four};
    font-size: 13px;
    line-height: 140%;
    min-height: 116px;
    padding: 16px;
    width: calc(100% - 35px);

    @media (max-width: 900px) {
      margin-bottom: 20px;
      width: 100%;
    }
  }
`;

// RightSide ****************************

const InfoBox = styled.div`
  background: ${BLUE.one};
  border-radius: 8px;
  color: ${GREY.six};
  font-size: 9px;
  font-weight: bold !important;
  height: 176px;
  margin-bottom: 8px;
  padding: 26px 28px;
  width: 311px;

  @media (max-width: 900px) {
    width: 100%;
  }

  .time__flex {
    color: ${GLOBAL.black};
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-weight: normal;
    line-height: 140%;
  }

  h3 {
    color: ${GLOBAL.black};
    font-size: 22px;

    font-weight: bold;
  }

  h4 {
    font-weight: bold;
  }

  .info__flex {
    display: flex;
    margin-top: 21px;

    div {
      flex: 1;
    }
  }
`;

const DaysLeftBox = styled.div`
  align-items: center;
  background: ${ORANGE.one};
  border-radius: 8px;
  display: flex;
  font-size: 13px;
  height: 50px;
  margin-bottom: 19px;
  padding: 12px 27px;
  p {
    color: ${GREY.ten};
    font-weight: 600;
  }

  svg {
    margin-right: 12px;
  }
`;

const InfoTagline = styled.div`
  display: flex;
  font-size: 12px;

  svg {
    margin-right: 9px;
    width: 40px;
  }
`;

// Footer ********************************

const RotationPeriod = styled.div`
  font-size: 13px;
  position: absolute;
  right: 100px;
  top: 32px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 25px 38px 0;
  button {
    margin-right: 8px;
  }
  @media (max-width: 468px) {
    flex-direction: column;
    text-align: center;
    button {
      font-size: 12px;
      margin-bottom: 10px;
      width: 100%;
    }
  }
`;

const DatepickerStyle = styled.div`
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  position: relative;
  width: 250px;

  .span_icon {
    align-items: center;
    background: ${GLOBAL.backgroundColor};
    border: 1px solid ${GREY.three};
    border-bottom-left-radius: 6px;
    border-right: 0;
    border-top-left-radius: 6px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    width: 60px;
    svg {
      position: relative;
    }
  }

  .calendar__Chevron {
    position: absolute;
    right: 14px;
    top: 18px;
  }

  .react-datepicker__input-container {
    input {
      background: ${GLOBAL.white};
      border: 1px solid ${GREY.three};
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
      box-sizing: border-box;
      color: #002033 !important;
      height: 44px;
      padding-left: 10px;
      width: 205px !important;
      z-index: 10;

      &:focus {
        background-color: ${GLOBAL.white};
        border-color: ${GREY.ten};
        box-shadow: 0 0 4px 1px rgba(43, 192, 212, 0.5);
        color: ${GLOBAL.fontDark};
        outline: 0;
      }

      @media (max-width: 768px) {
        max-width: 100%;
      }
    }
  }
`;
