import { Button, ButtonAdd, FormGroup } from "@patchworkhealth/web-components";

interface headerProps {
  createNewReport: () => void;
}

const ExceptionReportsHeader = ({ createNewReport }: headerProps) => {
  return (
    <FormGroup
      style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}
    >
      <Button variant="primary" onClick={createNewReport} icon={<ButtonAdd />}>
        New report
      </Button>
    </FormGroup>
  );
};

export default ExceptionReportsHeader;
