import { PropsWithChildren, useState } from "react";
import styled from "styled-components";

import { GREEN, GREY, ORANGE, RED } from "@patchworkhealth/web-components";

import { RosteringLeaveRequestsQuery } from "components/LeaveManagement/__generated__/LeaveManagement.generated";
import { LeaveRecordTableColumns } from "components/LeaveManagement/LeaveRecordTable/LeaveRecordTableColumns";

import { RosteringLeaveRequestStatusEnum } from "../../../baseCODEGEN";
import { LeaveGridHeader } from "../LeaveGridHeader";
import {
  dateSortOptions,
  initialFilters,
  statusSortOptions,
} from "../LeaveGridHeader/LeaveGridHeader.data";
import {
  GridContainer,
  GridHeader,
} from "../LeaveGridHeader/LeaveGridHeader.styled";
import { leaveRecordFilters } from "./leaveRecordFilters";
import { LeaveRecordsFiltersType } from "./LeaveRecordTable.types";

interface Props {
  leaveRequests: RosteringLeaveRequestsQuery | undefined;
  onOpenRecord: (leaveRequestId: string) => void;
  loadingLeaveRequests: boolean;
}

export const LeaveRecordTable = ({
  leaveRequests,
  onOpenRecord,
  loadingLeaveRequests,
}: Props) => {
  const [filter, setFilter] = useState<LeaveRecordsFiltersType>(initialFilters);

  const { filteredLeaveRequests } = leaveRecordFilters(filter, leaveRequests);

  return (
    <>
      <GridContainer>
        <GridHeader>
          <LeaveGridHeader
            options={dateSortOptions}
            filter={filter}
            setFilter={setFilter}
            property="startDate"
          >
            Dates
          </LeaveGridHeader>
          <LeaveGridHeader noFilters>Amount taken</LeaveGridHeader>
          <LeaveGridHeader noFilters>Type</LeaveGridHeader>
          <LeaveGridHeader
            filter={filter}
            setFilter={setFilter}
            property="status"
            options={statusSortOptions}
          >
            Status
          </LeaveGridHeader>
        </GridHeader>

        <div style={{ display: "block", maxHeight: "70vh", overflow: "auto" }}>
          <LeaveRecordTableColumns
            leaveRequests={filteredLeaveRequests}
            loading={loadingLeaveRequests}
            onOpenRecord={onOpenRecord}
          />
        </div>
      </GridContainer>
    </>
  );
};

const leaveStatusColors: Partial<{
  [key in RosteringLeaveRequestStatusEnum]: {
    background: string;
    color: string;
  };
}> = {
  [RosteringLeaveRequestStatusEnum.Approved]: {
    background: GREEN.two,
    color: GREEN.five,
  },
  [RosteringLeaveRequestStatusEnum.Pending]: {
    background: ORANGE.one,
    color: ORANGE.six,
  },
  [RosteringLeaveRequestStatusEnum.PendingCancellation]: {
    background: ORANGE.one,
    color: ORANGE.six,
  },
  [RosteringLeaveRequestStatusEnum.Rejected]: {
    background: RED.two,
    color: RED.five,
  },
};

export const LeaveStatusContainer = styled.div<{
  status: RosteringLeaveRequestStatusEnum;
}>`
  display: flex;
  align-items: center;

  background: ${({ status }) =>
    leaveStatusColors[status]?.background || GREY.two};
  color: ${({ status }) => leaveStatusColors[status]?.color || GREY.six};

  border-radius: 4px;
  padding: 2px 9px;
  font-size: 12px;
  font-weight: 600;
  height: fit-content;
  justify-content: center;
  text-transform: uppercase;
  max-width: 110px;
  text-align: left;
`;

export const LeaveStatus = ({
  status,
  children,
}: PropsWithChildren<{
  status: string | undefined | null;
}>) => {
  if (!status) return null;

  return (
    <LeaveStatusContainer
      status={status as RosteringLeaveRequestStatusEnum}
      data-testid="leave_status"
    >
      {getLeaveStatusDisplay(children as string)}
    </LeaveStatusContainer>
  );
};

const getLeaveStatusDisplay = (status: string) => {
  switch (status) {
    case "pending_cancellation":
      return "Pending (Cancellation)";
    case "approved":
      return "Accepted";
    case "rejected":
      return "Declined";
    default:
      return status;
  }
};
