import toast from "react-hot-toast";
import { motion } from "framer-motion";
import { createBrowserHistory } from "history";
import moment from "moment-timezone";

// Timezones for Momnet ---------------------------------------------------
export const DEFAULT_TIMEZONE = "Europe/London";
export const DEFAULT_TIME_FORMAT = "HH:mm";

export const formatTimeInTZ = (
  time,
  format = DEFAULT_TIME_FORMAT,
  timezone = DEFAULT_TIMEZONE
) => {
  return moment.tz(time, timezone).format(format);
};

export const parseTimeInTZ = (time, timezone = DEFAULT_TIMEZONE) => {
  return moment.tz(time || new Date(), timezone);
};

// History Object *********************************************************

const history = createBrowserHistory();

const hist = createBrowserHistory();
if (window.Cypress) {
  window.tgHistory = hist;
}

export default history;

// Token/LocalStorage Functions *********************************************
const TOKEN_KEY = "staging_patchwork_tokens";
export function saveTokens(tokens) {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(tokens));
}

export function getTokens() {
  const doesExist = localStorage.getItem(TOKEN_KEY);

  if (doesExist !== "undefined") {
    return JSON.parse(doesExist);
  }
}

export function deleteTokens() {
  localStorage.removeItem(TOKEN_KEY);
}

// Image Upload Function ***************************************************

export const convertToBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

// Change ProfileImage ***************************************************

export const onChangePicture = async (e, store, updateProfilePic) => {
  const toastId = toast.loading("Loading...");

  const file = e.target.files[0];
  let base64result;

  try {
    const base64 = await convertToBase64(file);

    if (base64) {
      base64result = base64.split(",")[1];

      updateProfilePic({
        variables: {
          file: base64result,
          fileName: "profile_img.jpg",
        },
      })
        .then((res) => {
          const errors = res.data?.uploadProfilePicture?.errors;

          if (errors.length > 0) {
            toast.error(`${errors[0].attribute} ${errors[0].message}`, {
              id: toastId,
            });
          } else {
            store.fetchUser();
            toast.success("Profile picture uploaded!", { id: toastId });
          }
        })
        .catch(() => {
          toast.error("There was an error!", { id: toastId });
        });
    } else {
      toast.error("Error with filetype!", { id: toastId });
    }
  } catch {
    toast.error("Catch Error: Unable to handle Image!", { id: toastId });
  }
};

// Remove profile Image ************************************************

export const removeProfilePic = (store, removeProfilePicture) => {
  const toastId = toast.loading("Loading...");

  removeProfilePicture()
    .then((res) => {
      const errors = res.data?.deleteProfilePicture?.errors;

      if (errors.length > 0) {
        toast.error("Couldn't delete Image", { id: toastId });
        return;
      }
      store.fetchUser();
      toast.success("Profile picture removed!", { id: toastId });
    })
    .catch(() => {
      toast.error("Couldn't delete Image", { id: toastId });
    });
};

// Mobile Function to help sort Organisations ****************************

export const hasJoinedCollabBank = (collabBankId, workerCollaborativeBanks) => {
  return workerCollaborativeBanks.some(
    (item) => item.collaborativeBank.id === collabBankId && item.active
  );
};

export const hideLeadEmployerDetails = (
  leadEmployer,
  usesLeadEmployerProcess
) => {
  return usesLeadEmployerProcess && !leadEmployer;
};

// Wrapper Component to Create mounting Animation **************************

export const AnimateOnLoad = ({ children }) => (
  <>
    <motion.div
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  </>
);

// Reset Input type=[file] fields **************

export const resetInputs = () => {
  let inputFields = window.document.querySelectorAll(".documents__value");
  inputFields.forEach((element) => (element.value = ""));
};
