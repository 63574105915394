import { datadogRum } from "@datadog/browser-rum";
import jwtDecode from "jwt-decode";
import { z } from "zod";

const TOKEN_KEY = "staging_patchwork_tokens";

export function saveTokens(tokens: Tokens, sessionType?: "local" | "session") {
  if (!TOKEN_KEY) {
    console.error("TOKEN_KEY is not defined");
    return;
  }

  if (sessionType === "session") {
    sessionStorage.setItem(TOKEN_KEY, JSON.stringify(tokens));
  } else {
    localStorage.setItem(TOKEN_KEY, JSON.stringify(tokens));
  }

  try {
    const decodedToken = jwtDecode<JwtData>(tokens.token);

    const id = decodedToken.data?.id;
    const tokenEmail = decodedToken.data?.email;

    const email = tokenEmail?.endsWith("@patchwork.health")
      ? tokenEmail
      : undefined;

    const userContext = decodedToken?.data?.context;

    const productTypes = decodedToken?.permissions?.product_types;
    const organisationIds = decodedToken?.permissions?.organisation_ids;

    datadogRum.setUser({
      id,
      email,
      productTypes,
      organisationIds,
      context: userContext,
    });
  } catch (error) {
    console.error("Error decoding token:", error);
  }
}

interface JwtData {
  data?: { id?: string; email?: string; context?: string };
  permissions?: { organisation_ids?: number[]; product_types?: string[] };
}

export function getTokens(): null | Tokens {
  if (!TOKEN_KEY) {
    console.error("TOKEN_KEY is not defined");
    return null;
  }

  const sessionStorageItem = sessionStorage.getItem(TOKEN_KEY);
  const localStorageItem = localStorage.getItem(TOKEN_KEY);

  if (sessionStorageItem) {
    const parsed = tokensSchema.safeParse(JSON.parse(sessionStorageItem));
    if (parsed.success) {
      return parsed.data;
    }

    console.error("Failed to parse tokens from sessionStorage:", parsed.error);
    return null;
  }

  if (localStorageItem) {
    const parsed = tokensSchema.safeParse(JSON.parse(localStorageItem));
    if (parsed.success) {
      return parsed.data;
    }

    console.error("Failed to parse tokens from localStorage:", parsed.error);
    return null;
  }

  return null;
}

export function deleteTokens() {
  if (!TOKEN_KEY) {
    console.error("TOKEN_KEY is not defined");
    return;
  }

  localStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem(TOKEN_KEY);
}

export const tokensSchema = z.object({
  token: z.string(),
  refreshToken: z.string(),
});

export type Tokens = z.infer<typeof tokensSchema>;

// Fetch and set user on initial load if tokens are available
const tokens = getTokens();
if (tokens) {
  saveTokens(tokens);
}
