import { PropsWithChildren, useState } from "react";

import Header from "components/Header";
import MobileNavigation from "components/Navigation/MobileNav";
import Navigation from "components/Navigation/Navigation";
import { AnimateOnLoad } from "helpers/functions";

interface Props {
  headerValueText: string;
  headerLoading?: boolean;
  navigation?: "calendar_single" | "profile" | "expenses";
}
const Layout = ({
  children,
  headerValueText,
  navigation,
  headerLoading,
}: PropsWithChildren<Props>) => {
  const [showSidebar, setShowSidebar] = useState(false);
  return (
    <>
      <Header
        headerValueText={headerValueText}
        headerLoading={headerLoading}
        setShowSidebar={setShowSidebar}
        showSidebar={showSidebar}
      />

      {navigation === "expenses" ? (
        <div className="pt-[100px] p-8  relative">
          <AnimateOnLoad>{children}</AnimateOnLoad>
        </div>
      ) : (
        <>
          <Navigation navigation={navigation} />
          <MobileNavigation showSidebar={showSidebar} />
          <div className="h-[500px] p-2 pt-[80px] lg:ml-[200px] relative sm:p-8 sm:pt-20">
            <AnimateOnLoad>{children}</AnimateOnLoad>
          </div>
        </>
      )}
    </>
  );
};

export default Layout;
