import styled from "styled-components";

import { BLUE, GREY, ORANGE } from "@patchworkhealth/web-components";

import { exhaustiveMatchingGuard } from "helpers/exhaustiveMatchingGuard";

import { LeaveButtonType } from "../RequestLeaveSection.types";
import { requestLeaveIconsDictionary } from "./shared";

interface Props {
  activeLeaveSection: LeaveButtonType;
  entitlementAmount: number;
}

export const RequestLeaveHeader = ({
  activeLeaveSection,
  entitlementAmount,
}: Props) => {
  const icon = requestLeaveIconsDictionary[activeLeaveSection].icon;
  const title = requestLeaveIconsDictionary[activeLeaveSection].title;

  return (
    <LeaveHeader>
      <LeaveIconWrapper activeLeaveSection={activeLeaveSection}>
        {icon}
      </LeaveIconWrapper>
      <div>
        <H1>{title}</H1>
        <EntitlementCounter>
          {`${entitlementAmount} active entitlement${
            entitlementAmount > 1 ? "s" : ""
          }`}
        </EntitlementCounter>
      </div>
    </LeaveHeader>
  );
};

const background = (activeLeaveSection: LeaveButtonType) => {
  switch (activeLeaveSection) {
    case "annual_leave":
      return ORANGE.two;
    case "study_leave":
      return BLUE.one;
    default:
      return exhaustiveMatchingGuard(activeLeaveSection);
  }
};

const LeaveHeader = styled.header`
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
`;

const H1 = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${GREY.ten};
`;

const EntitlementCounter = styled.p`
  color: ${GREY.eight};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;
const LeaveIconWrapper = styled.div<{ activeLeaveSection: LeaveButtonType }>`
  display: flex;
  width: 49px;
  height: 49px;
  padding: 10px 10px 9px 9px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  background: ${({ activeLeaveSection }) => background(activeLeaveSection)};

  svg {
    height: 30px;
    width: 30px;
  }
`;
