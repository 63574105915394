import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { BLUE, Loading } from "@patchworkhealth/web-components";

import { ShiftIcon } from "components/Shared/ShiftIcon";
import { AppContext } from "context/AppContext";
import {
  rosteringPeriodApi,
  selfRosteringPreferencesApi,
} from "helpers/kmonoApiClient";
import { classNames } from "helpers/newHelpers";

import { workerCanSubmit } from "./rosterPeriodHelper";

interface Props {
  rosteringPeriodId: string;
  shiftName: string;
  selected: boolean;
  index: number;
  date: Date;
}

interface MutationKey {
  date: Date;
  shiftName: string;
}

const SelfRosteringPreferenceButton = ({
  rosteringPeriodId,
  shiftName,
  selected,
  index,
  date,
}: Props) => {
  const { user } = useContext(AppContext);
  const queryClient = useQueryClient();

  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    setClicked(false);
  }, [selected]);

  const { data: rosteringPeriod } = useQuery(
    ["selfRosteringSubmission", rosteringPeriodId],
    () => {
      return rosteringPeriodApi.getApiRosteringPeriodsRosteringPeriodId({
        rosteringPeriodId,
      });
    }
  );

  const { mutate: setPreference } = useMutation(
    ({ date, shiftName }: MutationKey) => {
      return selfRosteringPreferencesApi
        .postApiSelfRosteringPeriodPreferencesRosteringPeriodId({
          rosteringPeriodId,
          apiPreferenceRequest: { date, shiftName },
        })
        .then(() => queryClient.invalidateQueries("selfRosteringPreferences"))
        .catch(console.error);
    }
  );

  const { mutate: unsetPreference } = useMutation(
    ({ date, shiftName }: MutationKey) => {
      return selfRosteringPreferencesApi
        .deleteApiSelfRosteringPeriodPreferencesRosteringPeriodId({
          rosteringPeriodId,
          apiPreferenceRequest: { date, shiftName },
        })
        .then(() => queryClient.invalidateQueries("selfRosteringPreferences"))
        .catch(console.error);
    }
  );

  const selectedBorderColour = selected
    ? borderColour(index)
    : "rgb(166,172,179)";

  const selectedMainColour = selected ? mainColour(index) : "rgb(237,239,241)";

  const toggle = () => {
    setClicked(true);
    if (!selected) {
      setPreference({ date: date, shiftName });
    } else {
      unsetPreference({ date: date, shiftName });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginBottom: 6,
        cursor: "pointer",
        opacity: clicked ? 0.7 : 1,
        transition: "transform 0.1s ease",
        transform: clicked ? "scale(0.95) translateY(1px)" : undefined,
        pointerEvents: workerCanSubmit(rosteringPeriod, user?.email)
          ? "auto"
          : "none",
      }}
      className={selected ? undefined : "grayscale"}
      onClick={toggle}
      key={`${date}-${shiftName}-${index}`}
    >
      <div
        className={classNames("text-blue-6 px-[4px] py-[4px] rounded-l-md")}
        style={{ backgroundColor: selectedBorderColour }}
      />
      <div
        className={classNames(
          "flex justify-between items-center text-blue-6 px-[4px] py-[4px] rounded-r-md flex-1 text-xs"
        )}
        style={{
          backgroundColor: selectedMainColour,
          transitionDuration: "200ms",
        }}
      >
        {shiftName}

        <div style={{ width: 20, height: 20 }}>
          {clicked ? (
            <Loading style={{ width: 16, height: 16 }} />
          ) : (
            <ShiftIcon type={shiftName} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SelfRosteringPreferenceButton;

const shiftBorderColours = [
  BLUE.seven,
  "rgb(230,169,94)",
  "rgb(6,11,147)",
  "rgb(23,62,115)",
];

const borderColour = (index: number) =>
  shiftBorderColours[index % shiftBorderColours.length];

const shiftMainColours = [
  "rgb(209, 242, 247)",
  "rgb(247,237,225)",
  "rgb(231,232,253)",
  "rgb(205,223,238)",
];

const mainColour = (index: number) =>
  shiftMainColours[index % shiftMainColours.length];
