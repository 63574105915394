import { PropsWithChildren } from "react";
import styled from "styled-components";

import {
  BLUE,
  FormRadioButtonIcon,
  GREY,
} from "@patchworkhealth/web-components";

import FiltersOption from "./FiltersOption.styled";

const TextContainer = styled.span`
  color: ${GREY.five};
`;

const RadioIcon = styled(FormRadioButtonIcon)`
  input:checked ~ & {
    border-color: ${BLUE.five};
    border-width: 8px;
  }
`;

interface Props {
  checked: boolean;
  onChange: () => void;
}

const FiltersOptionRadio = ({
  checked,
  children,
  onChange,
}: PropsWithChildren<Props>) => (
  <FiltersOption>
    <input type="radio" checked={checked} onChange={onChange} hidden />
    <RadioIcon active={checked} />
    <TextContainer>{children}</TextContainer>
  </FiltersOption>
);

export default FiltersOptionRadio;
