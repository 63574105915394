import { memo, ReactNode } from "react";
import * as Tooltip from "@radix-ui/react-tooltip";
import styled from "styled-components";

import { GREY } from "@patchworkhealth/web-components";

import * as Styled from "./LeaveRequestTooltip.styled";

interface Props {
  children: ReactNode;
  trigger: ReactNode;
  dataTestId?: string;
}

export const LeaveRequestTooltip = memo(({ children, trigger }: Props) => {
  return (
    <Tooltip.Provider delayDuration={100}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{trigger}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Styled.CalendarTooltipContent>
            {children}
            <TooltipArrow width={19} height={16} />
          </Styled.CalendarTooltipContent>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
});

const TooltipArrow = styled(Tooltip.Arrow)`
  fill: ${GREY.ten};
`;
