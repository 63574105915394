import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

import { ApiRosteringPeriod } from "@patchworkhealth/selfrostering-api";
import { Button, GLOBAL, GREEN, GREY } from "@patchworkhealth/web-components";

import { workerCanSubmit } from "components/SelfRostering/rosterPeriodHelper";
import { AppContext } from "context/AppContext";

export const SelfRosteringPeriodCard = ({
  rosteringPeriod,
}: {
  rosteringPeriod: ApiRosteringPeriod;
}) => {
  const { user } = useContext(AppContext);
  const history = useHistory();

  const navigate = () => {
    history.push(`/roster-preferences/${rosteringPeriod.id}`);
  };

  return (
    <div
      key={rosteringPeriod.id}
      style={{
        border: `1px solid ${GREY.two}`,
        borderRadius: 4,
        background: "white",
        margin: "15px 0",
        padding: "10px 15px",
        maxWidth: 320,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            backgroundColor: rosteringPeriod.isOpen ? GREEN.four : GREY.four,
            color: GLOBAL.white,
            width: 74,
            textAlign: "center",
            borderRadius: 3,
            padding: "2px 0",
            fontWeight: 600,
            fontSize: 12,
            marginBottom: 10,
            textDecoration: "none",
          }}
        >
          {rosteringPeriod.isOpen ? "OPEN" : "CLOSED"}
        </p>
        <p style={{ fontSize: 11, fontWeight: 700 }}>
          {rosteringPeriod.isOpen ? (
            <>
              Close date:{" "}
              <span style={{ fontWeight: 400 }}>
                {dayjs(rosteringPeriod.submissionDeadline).format("DD/MM/YYYY")}
              </span>
            </>
          ) : (
            <>
              Closed on:{" "}
              <span style={{ fontWeight: 400 }}>
                {dayjs(rosteringPeriod.closedOn).format("DD/MM/YYYY")}
              </span>
            </>
          )}
        </p>
      </div>
      <p style={{ fontWeight: 400, margin: 0, fontSize: 12 }}>
        Preference coverage:
      </p>

      <p
        style={{
          margin: 0,
          fontWeight: 600,
          fontSize: 15,
          marginTop: 8,
          marginBottom: 32,
        }}
      >
        {dayjs(rosteringPeriod.coverageStartDate).format("DD/MM/YYYY")} -
        {dayjs(rosteringPeriod.coverageEndDate).format("DD/MM/YYYY")}
      </p>

      <Button
        style={{
          width: "100%",
        }}
        variant={
          workerCanSubmit(rosteringPeriod, user?.email)
            ? "primary"
            : "secondary"
        }
        onClick={navigate}
      >
        {workerCanSubmit(rosteringPeriod, user?.email)
          ? "Submit preferences"
          : "View preferences"}
      </Button>
    </div>
  );
};
