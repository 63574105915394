import { Modal } from "react-bootstrap";
import styled from "styled-components";

import { GREY } from "@patchworkhealth/web-components";

import { StyledButton } from "components/Style";
import { DocumentsModalIcon } from "icons/documents";
import Welcome from "icons/images/documentsWelcome.png";

const DocumentsAlert = ({ onHide, show }) => {
  return (
    <>
      <Modal show={show} onHide={onHide} size="lg" centered>
        <ModalBody>
          <DocumentsModalIcon />

          <img alt="Welcome" src={Welcome} />

          <p>
            Welcome to Patchwork Web! This is the web version of the Patchwork
            App - you can access all your information just as you would on the
            mobile app.
          </p>

          <span>Before you get started, Please complete your profile</span>

          <div className="flex">
            <StyledButton
              onClick={() => onHide()}
              size="small"
              color="blue"
              length="237px"
              type="submit"
            >
              Complete Profile
            </StyledButton>
            {/* <StyledButton
              color="white"
              size="small"
              length="237px"
              onClick={() => onHide()}
              className="modal__footer_cancel"
            >
              Maybe later
            </StyledButton> */}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default DocumentsAlert;

const ModalBody = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 40px 90px;
  text-align: center;
  img {
    margin: 25px 0;
  }
  span {
    color: ${GREY.ten};
    font-weight: bold;
    margin: 25px 0;
  }
  button {
    margin-bottom: 10px;
  }
  @media (max-width: 768px) {
    padding: 20px;
  }
`;
