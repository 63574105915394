import dayjs from "dayjs";

import {
  calculateShiftDuration,
  isOvertimeValid,
} from "components/ExceptionReports/ExceptionReports.helpers";
import { OvertimeLabel } from "components/ExceptionReports/ExceptionReports.styled";
import { ExceptionReportType } from "components/ExceptionReports/ExceptionReports.types";
import { useGetShiftsActivities } from "components/Schedule/hooks/useServicePlansShiftActivities";
import { ShiftIcon } from "components/Shared/ShiftIcon";

import * as Styled from "./ShiftDetailsBox.styled";

interface Props {
  topHeader: boolean;
  rosteringExceptionReport: Partial<ExceptionReportType> | undefined;
  createFlow: boolean;
}

export const ShiftDetailsBox = ({
  topHeader,
  rosteringExceptionReport,
  createFlow,
}: Props) => {
  const data = formatData(rosteringExceptionReport, createFlow);
  const eventId = rosteringExceptionReport?.rosteringEvent?.id;
  const { activitiesLabelForShift } = useGetShiftsActivities({
    eventIds: eventId ? [+eventId] : [],
  });
  const activityName = eventId
    ? activitiesLabelForShift(+eventId, data?.activityName)
    : "";
  return (
    <Styled.ShiftDetailsContainer>
      <div>
        {topHeader && <Styled.Header>Shift Details</Styled.Header>}
        <div style={{ display: "flex", gap: "10px" }}>
          <div>
            <Styled.TextItemSmall>Start</Styled.TextItemSmall>
            <Styled.TextItem>{data?.startTime}</Styled.TextItem>
          </div>
          <div>
            <Styled.TextItemSmall>End</Styled.TextItemSmall>
            <Styled.TextItem>{data?.endTime}</Styled.TextItem>
          </div>
          <div style={{ marginLeft: "10px", width: "fit-content" }}>
            <Styled.TextItemSmall>Shift Duration</Styled.TextItemSmall>
            <div style={{ display: "flex", gap: "5px", width: "max-content" }}>
              <Styled.TextItem>{data?.shiftDuration}</Styled.TextItem>
              {isOvertimeValid(data?.overtime) && (
                <OvertimeLabel>{`+${data?.overtime}`}</OvertimeLabel>
              )}
            </div>
          </div>
          {data?.shiftType && (
            <Styled.SvgIcon>
              <ShiftIcon type={data?.shiftType?.name} />
            </Styled.SvgIcon>
          )}
        </div>
        <div style={{ marginTop: "10px" }}>
          <Styled.TextItemSmall>Date</Styled.TextItemSmall>
          <Styled.TextItem>{data?.date}</Styled.TextItem>
        </div>
        {data?.location && (
          <div style={{ marginTop: "10px" }}>
            <Styled.TextItemSmall>Location</Styled.TextItemSmall>
            <Styled.TextItem>{data.location}</Styled.TextItem>
          </div>
        )}
        <div style={{ marginTop: "20px" }}>
          {activityName && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <ActivityIcon />
              <Styled.TextItem>{activityName}</Styled.TextItem>
            </div>
          )}
          {data?.rotaName && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <RotaIcon />
              <Styled.TextItem>{data?.rotaName}</Styled.TextItem>
            </div>
          )}
          {data?.workerPattern && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <WorkerPatternIcon />
              <Styled.TextItem>{data?.workerPattern}</Styled.TextItem>
            </div>
          )}
        </div>
      </div>
    </Styled.ShiftDetailsContainer>
  );
};

const formatData = (
  exceptionReport: Partial<ExceptionReportType> | undefined | null,
  createFlow?: boolean
) => {
  const startTime = exceptionReport?.rosteringEvent
    ? exceptionReport?.rosteringEvent.startsAt.slice(11, 16)
    : exceptionReport?.shiftScheduledStartTime?.slice(0, 5);

  const startTimeDisplay = exceptionReport?.shiftScheduledStartTime?.slice(
    0,
    5
  );

  const endTime = exceptionReport?.rosteringEvent
    ? exceptionReport?.rosteringEvent.endsAt.slice(11, 16)
    : exceptionReport?.shiftScheduledEndTime?.slice(0, 5);

  const endTimeDisplay = exceptionReport?.shiftScheduledEndTime?.slice(0, 5);

  const date = dayjs(
    exceptionReport?.rosteringEvent
      ? exceptionReport?.rosteringEvent.startsAt.slice(0, 10)
      : exceptionReport?.shiftDate
  ).format("ddd, Do MMM YYYY");

  const shiftDuration = calculateShiftDuration(
    startTime,
    endTime,
    exceptionReport?.shiftDate
      ? exceptionReport?.shiftDate
      : exceptionReport?.rosteringEvent?.startsAt.slice(0, 10)
  );

  const shiftDurationDisplay = calculateShiftDuration(
    exceptionReport?.shiftScheduledStartTime,
    exceptionReport?.shiftScheduledEndTime,
    exceptionReport?.shiftDate
      ? exceptionReport?.shiftDate
      : exceptionReport?.rosteringEvent?.startsAt.slice(0, 10)
  );

  return {
    startTime: createFlow ? startTime || "-" : startTimeDisplay,
    endTime: createFlow ? endTime || "-" : endTimeDisplay,
    shiftDuration: createFlow ? shiftDuration || "-" : shiftDurationDisplay,
    date: date || "-",
    location: exceptionReport?.location || null,
    shiftType: exceptionReport?.rosteringEvent?.scheduleable?.shiftType || null,
    overtime: exceptionReport?.overtime,
    activityName:
      exceptionReport?.rosteringEvent?.scheduleable?.organisationActivity
        ?.name || null,
    rotaName:
      exceptionReport?.rosteringEvent?.schedule?.roster?.rota.name || null,
    workerPattern:
      exceptionReport?.rosteringEvent?.scheduleable?.rotaShiftSet
        ?.rosteringRotaSlot?.rosteringWorkerPattern.name || null,
  };
};

const ActivityIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1378 1.36179C15.337 1.56101 15.3895 1.86396 15.2688 2.11858L9.26885 14.7852C9.14831 15.0397 8.88088 15.191 8.60067 15.1633C8.32045 15.1355 8.08789 14.9347 8.0196 14.6615L6.78328 9.71627L1.838 8.47995C1.56483 8.41166 1.36402 8.17909 1.33627 7.89888C1.30852 7.61867 1.45982 7.35124 1.7143 7.2307L14.381 1.2307C14.6356 1.11009 14.9385 1.16256 15.1378 1.36179ZM3.96859 7.63823L7.49471 8.51976C7.73357 8.57947 7.92007 8.76598 7.97979 9.00483L8.86132 12.531L13.2648 3.23478L3.96859 7.63823Z"
      fill="#7C7D80"
    />
  </svg>
);

const RotaIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9997 1.8335H1.99967C1.82286 1.8335 1.65329 1.90373 1.52827 2.02876C1.40325 2.15378 1.33301 2.32335 1.33301 2.50016V14.5002C1.33301 14.677 1.40325 14.8465 1.52827 14.9716C1.65329 15.0966 1.82286 15.1668 1.99967 15.1668H13.9997C14.1765 15.1668 14.3461 15.0966 14.4711 14.9716C14.5961 14.8465 14.6663 14.677 14.6663 14.5002V2.50016C14.6663 2.32335 14.5961 2.15378 14.4711 2.02876C14.3461 1.90373 14.1765 1.8335 13.9997 1.8335ZM7.33301 13.8335H2.66634V11.1668H7.33301V13.8335ZM7.33301 9.8335H2.66634V7.16683H7.33301V9.8335ZM13.333 13.8335H8.66634V11.1668H13.333V13.8335ZM13.333 9.8335H8.66634V7.16683H13.333V9.8335ZM13.333 5.8335H2.66634V3.16683H13.333V5.8335Z"
      fill="#7C7D80"
    />
  </svg>
);

const WorkerPatternIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 5.16683C4 4.06226 4.89543 3.16683 6 3.16683C7.10457 3.16683 8 4.06226 8 5.16683C8 6.2714 7.10457 7.16683 6 7.16683C4.89543 7.16683 4 6.2714 4 5.16683ZM6 1.8335C4.15905 1.8335 2.66667 3.32588 2.66667 5.16683C2.66667 7.00778 4.15905 8.50016 6 8.50016C7.84095 8.50016 9.33333 7.00778 9.33333 5.16683C9.33333 3.32588 7.84095 1.8335 6 1.8335ZM10.0208 2.42147C10.1122 2.06479 10.4753 1.84967 10.832 1.941C11.549 2.12458 12.1846 2.54158 12.6384 3.12626C13.0922 3.71093 13.3386 4.43002 13.3386 5.17016C13.3386 5.91031 13.0922 6.6294 12.6384 7.21407C12.1846 7.79875 11.549 8.21575 10.832 8.39933C10.4753 8.49066 10.1122 8.27554 10.0208 7.91886C9.92951 7.56217 10.1446 7.19899 10.5013 7.10766C10.9315 6.99751 11.3128 6.74731 11.5851 6.39651C11.8574 6.0457 12.0052 5.61425 12.0052 5.17016C12.0052 4.72608 11.8574 4.29462 11.5851 3.94382C11.3128 3.59301 10.9315 3.34281 10.5013 3.23266C10.1446 3.14134 9.92951 2.77815 10.0208 2.42147ZM3.33333 9.8335C2.44928 9.8335 1.60143 10.1847 0.976311 10.8098C0.35119 11.4349 0 12.2828 0 13.1668V14.5002C0 14.8684 0.298477 15.1668 0.666667 15.1668C1.03486 15.1668 1.33333 14.8684 1.33333 14.5002V13.1668C1.33333 12.6364 1.54405 12.1277 1.91912 11.7526C2.29419 11.3775 2.8029 11.1668 3.33333 11.1668H8.66667C9.1971 11.1668 9.70581 11.3775 10.0809 11.7526C10.456 12.1277 10.6667 12.6364 10.6667 13.1668V14.5002C10.6667 14.8684 10.9651 15.1668 11.3333 15.1668C11.7015 15.1668 12 14.8684 12 14.5002V13.1668C12 12.2828 11.6488 11.4349 11.0237 10.8098C10.3986 10.1847 9.55072 9.8335 8.66667 9.8335H3.33333ZM13.5 9.94133C13.1435 9.84929 12.7799 10.0637 12.6878 10.4202C12.5958 10.7767 12.8102 11.1403 13.1667 11.2323C13.5957 11.3431 13.9759 11.5933 14.2473 11.9435C14.5188 12.2937 14.6663 12.7243 14.6667 13.1673V14.5002C14.6667 14.8684 14.9651 15.1668 15.3333 15.1668C15.7015 15.1668 16 14.8684 16 14.5002V13.1663C15.9994 12.4278 15.7536 11.7103 15.3011 11.1266C14.8486 10.5429 14.2151 10.126 13.5 9.94133Z"
      fill="#7C7D80"
    />
  </svg>
);
