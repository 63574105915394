import { useState } from "react";

import { ShiftCardFragment } from "components/ShiftsNew/__generated__/Shifts.generated";
import { ShiftsModal } from "components/ShiftsNew/ShiftsModal";

import { Loading } from "../Style";
import { useShiftsFinalisedQuery } from "./__generated__/Timesheets.generated";
import { TimesheetsGroup } from "./TimesheetHelpers";

type InitInputs = {
  sidebarOpen: boolean;
  openModal: boolean;
  selectedShift: ShiftCardFragment | null;
  hasMoreShifts: boolean;
};

const initInputs: InitInputs = {
  sidebarOpen: false,
  openModal: false,
  selectedShift: null,
  hasMoreShifts: true,
};

const TimesheetsFinalised = () => {
  /* State ------------------------------------------------ */

  const [inputs, setInputs] = useState(initInputs);

  /* Graphql ---------------------------------------------- */

  const { data, loading } = useShiftsFinalisedQuery({
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const { paid = [] } = data || {};

  /* Functions --------------------------------------------- */

  const toggleModal = (e: ShiftCardFragment | null = null) => {
    setInputs({
      ...inputs,
      openModal: !inputs.openModal,
      selectedShift: e || null,
    });
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && paid.length > 0 && (
        <TimesheetsGroup
          shifts={paid}
          toggleModal={toggleModal}
          title="with Payroll"
        />
      )}
      {!loading && paid.length === 0 && <h4>No Shifts Available</h4>}
      {inputs.openModal && (
        <ShiftsModal
          handleClose={toggleModal}
          openModal={inputs.openModal}
          selectedShift={inputs.selectedShift}
        />
      )}
    </>
  );
};

export default TimesheetsFinalised;
