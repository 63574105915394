import styled, { createGlobalStyle } from "styled-components";

import {
  BLUE,
  GLOBAL,
  GREY,
  Loading as StyledLoading,
  RBGREY,
  RED,
} from "@patchworkhealth/web-components";

// Global Styles ********************************************

export const GlobalStyle = createGlobalStyle`

  * {
    box-sizing: border-box;
    word-wrap: break-word;
  }

  body,html {   
    background-color: #F5F7FA;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.25;
    
  }

  body,button,html,input {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  main {
    padding: 30px;
    @media only screen and (max-width: 768px) {
      padding: 15px;
    }
  }

  h1,h2,h3,h4,h5 {
    line-height: 1.2;
  }

  h5 {
    color: #9CA5B0;
    font-size: 18px;
    font-weight: 600 !important;
  }

  h4 {
    font-size: 13px;
  }

  h6 {
    font-size: 12px;
    margin: 0;
  }
  
  p {
    color: #212932;
    font-weight: 400;
    line-height: 1.25;

    &:last-child {
      margin: 0;
    }
  }

  label {
    color: #7D8793;
    font-size: 10px ;
    font-weight: 700 ;
    margin-bottom: 4px;
    margin-left: 8px;
  }

  span {
    display: inline;
  }

  a {
    color: #1E2D36;
    font-weight: 600;
    text-decoration: none;

    &:hover {
      color: #1E2D36;
    }
  }
  
  button {
    align-items: center;
    background: transparent;
    border: 0;
    padding: 0;

    &:focus, &:active {
   box-shadow: none;
      outline: none !important;
    }
  }

  .defaultValue {
    color: red !important;
    opacity: 0.5 !important;
  }  

  .modal {
    &-backdrop {
      background: rgba(30,45,54,1);
    }

    &-content {
      border: 0;
      border-radius: 16px !important;
      box-shadow: 0 8px 32px 0 rgba(0,0,0,0.22);
    }

    &-header {
      align-items: center; 
      background: #F5F7FA;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      display: flex;
      height: 80px;


      .modal-title {
        color: ${GREY.ten};
        font-size: 18px;
        font-weight: 600;
      }
         
    }
    &-dialog {
      @media only screen and (max-width: 768px) {
        margin: 0.5rem 0 0;
      }
        }
      }

 .timesheets__modal .modal-content,
.timesheets__modal .modal-dialog {
  border: 0;
  height: auto;
  margin: auto;
  max-width: 1084px;
}

@media (max-width: 992px) {
  .timesheets__modal .modal-content,
  .timesheets__modal .modal-dialog {
    width: 100% !important;
  }
}


  .fade.in.modal {
    display:flex !important;
  }

  .modal-dialog {
    margin: auto;
  } 

 


    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  
      .css-yrak4c-placeholder,  .css-leftpi-indicatorContainer {
          margin-top: 10px !important;
        }

     
  }

  .react-datepicker__month-container {
    z-index: 1000000 !important;
  }

  /* Disable for Rostering Card */
  .roster__card {
    button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
      pointer-events: none !important;
    }
  }


.no__pointer {
  pointer-events: none !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}



input[type="file"] {
    display: none;
  }
  .custom-doc-upload {
    align-items: center;
    background: #f5f7fa;
    border: 1px dashed #9ca5b0 !important;
    border-radius: 8px;
    color: #17bfd6;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-weight: 600;
    height: 100px;
    justify-content: center;
    margin: 0;
    text-align: center;
    width: 100%;
    svg {
      margin-bottom: 7px;
    }
  }

  .custom-file-upload {
    border-radius: 8px;
    color: ${GREY.ten};
    cursor: pointer;
    display: inline-block;
    font-size: 14px !important;
    font-weight: normal;
    line-height: 20px;
    margin-right: 8px;
    margin-top: -20px;
    padding: 0;
    position: relative;
    text-align: center;
    text-decoration: underline;
    width: 100px;
    @media (max-width: 568px) {
      margin-top: 1rem;
    }
  }
`;

// Error message ******************************

// No results Component ***********************************************

export const NoResults = ({ title }) => (
  <NoShiftsContainer>
    <h5 data-testid="shifts__empty">{title ? title : "No Results Found"}</h5>
    <span></span>
  </NoShiftsContainer>
);

const NoShiftsContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 32px;

  h5 {
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    margin-right: 10px;
    text-transform: uppercase;
  }

  span {
    background-color: #cbd2d9;
    flex: 1;
    height: 1px;
  }

  @media screen and (max-width: 568px) {
    h5 {
      font-size: 13px;
    }
  }
`;

// Profile Image ***********************************************************

export const Loading = () => {
  return (
    <LoaderContainer>
      <StyledLoading />
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0;
  padding-top: 30px;
`;

// Styled Components ********************************************************

export const Wrapper = styled.div`
  align-items: center;
  background: linear-gradient(rgb(0, 32, 51) 0%, rgb(2, 21, 32) 100%),
    rgb(0, 32, 51);
  display: flex;
  height: 100vh;
  min-height: 540px;
  h3 {
    font-size: 18px;
    font-weight: 600;
  }

  p {
    text-align: center;
  }
  @keyframes fadeinout {
    50% {
      opacity: 1;
    }
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 60px;

  svg {
    height: 55px;
  }
`;

export const LoginHere = styled.span`
  color: blue;
  cursor: pointer;
  text-decoration: underline;
`;

// Divider ******************************************************************

export const Divider = styled.div.attrs((props) => ({
  line: props.line,
  bottom: props.bottom,
  top: props.top,
  opacity: props.opacity,
}))`
  border-style: ${(props) => (props.line === "dashed" ? "dashed" : "solid")};
  border-width: 1px;
  color: ${GREY.two};
  margin-bottom: ${(props) => props.bottom + "px"};
  margin-top: ${(props) => props.top + "px"};
  opacity: ${(props) =>
    props.line === "none" ? 0 : props.opacity ? props.opacity : 1};
  width: 100%;
`;

export const NavDivider = styled.div`
  border-bottom: 1px solid #e4e7eb;
  margin: 16px 8px;
`;

// Buttons *******************************************************************

export const StyledButton = styled.button.attrs((props) => ({
  color: props.color,
  size: props.size,
  length: props.length,
  isDisabled: props.isDisabled,
}))`
  align-items: center;
  background-color: ${(props) =>
    (props.disabled === true && RBGREY.one) ||
    (props.color === "blue" && BLUE.five) ||
    (props.color === "white" && GLOBAL.white) ||
    (props.color === "red" && RED.four) ||
    (props.color === "lightRed" && RED.one)};

  border: ${(props) => props.color === "white" && "1px solid #E4E7EB"};
  border-radius: ${(props) => (props.size === "big" ? "12px" : "8px")};
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);

  color: ${(props) =>
    (props.color === "white" && GLOBAL.fontDark) ||
    (props.color === "lightRed" && RED.one) ||
    GLOBAL.white};
  display: flex;
  font-size: 14px;
  font-weight: ${(props) => (props.size === "big" ? "bold" : 600)};
  height: ${(props) => (props.size === "big" ? "56px" : "42px")};
  justify-content: center;
  line-height: 20px;
  transition: all 0.3s ease-in-out;
  width: ${(props) => (props.length ? props.length : "100%")};
`;

// Forms ***************************************************************************

export const FormContainer = styled.div`
  margin: 0 auto;
  max-width: 350px;

  p {
    color: #fff;
    font-size: 13px;
    line-height: 1.5;
    /* margin-top: 20px; */
    opacity: 0.75;

    a,
    span {
      color: #fff;
    }
  }
`;

export const Require = styled.span`
  color: red;
`;

export const FormGrid = styled.div`
  display: grid;
  grid-gap: 30px 20px;
  grid-template-columns: 1fr 1fr;
  margin: 25px 0 !important;
  @media only screen and (max-width: 768px) {
    grid-template-columns: 100%;
  }
`;

export const Return = styled.div`
  margin-top: 64px;
  text-align: center;
  text-decoration: underline;

  a {
    color: #fff !important;
    font-weight: 500;
  }
`;

// Formik Error Message ********************************************

export const FormikErrors = ({ touch, err }) => {
  return (
    <FormikError>{touch && err ? <div>{err}</div> : undefined}</FormikError>
  );
};

const FormikError = styled.span`
  color: #ff6c6c;
  display: block;
  font-size: 12px;
  margin-top: 10px;
`;

// Typography ******************************************

export const Heading = styled.h4.attrs((props) => ({
  size: props.size,
  mb: props.mb,
  color: props.color,
}))`
  color: ${(props) => props.color && `${props.color}`};
  color: ${(props) => !props.color && GREY.ten};
  font-size: ${(props) =>
    props.size === "1"
      ? "48px"
      : props.size === "2"
      ? "36px"
      : props.size === "3"
      ? "24px"
      : props.size === "4"
      ? "20px"
      : props.size === "5"
      ? "18px"
      : props.size === "6"
      ? "16px"
      : props.size === "7"
      ? "12px"
      : "10px"};

  font-weight: ${(props) =>
    props.size === "3" ? "bold" : props.size === "5" ? "600" : "normal"};

  margin: 0;
  margin-bottom: ${(props) => props.mb && `${props.mb}px`};
`;

export const Text = styled.p.attrs((props) => ({
  size: props.size,
  mb: props.mb,
  color: props.color,
}))`
  color: ${(props) => props.color && `${props.color}`};
  color: ${(props) => !props.color && GREY.ten};
  font-size: ${(props) =>
    props.size === "big" ? "18px" : props.size === "small" ? "14px" : "16px"};
  margin: 0;
  margin-bottom: ${(props) => props.mb && `${props.mb}px`};
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-right: 8px;
  }
  @media (max-width: 468px) {
    flex-direction: column;
    text-align: center;
    button {
      font-size: 12px;
      margin-bottom: 10px;
      width: 100%;
    }
  }
`;
