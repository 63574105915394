import { useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import styled from "styled-components";

import {
  Button,
  CloseButton,
  ErrorContainer,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@patchworkhealth/web-components";

import { apolloCacheDeleteItem } from "helpers/apolloCacheDeleteItem";

import { useRosteringExceptionReportDeleteMutation } from "../__generated__/ExceptionReports.generated";

const ExceptionReportDeleteModal = ({
  onHide,
  exceptionReportId,
}: {
  onHide: () => void;
  exceptionReportId: string;
}) => {
  const [error, setError] = useState<string>();

  const [deleteExceptionReportMutation, { loading }] =
    useRosteringExceptionReportDeleteMutation();

  const deleteExceptionReport = async () => {
    try {
      const { data: result } = await deleteExceptionReportMutation({
        variables: {
          rosteringExceptionReportDeleteId: +exceptionReportId,
        },
      });

      if (result?.rosteringExceptionReportDelete?.errors.length) {
        setError("There was a problem deleting this Exception Report.");
      } else {
        if (result?.rosteringExceptionReportDelete?.rosteringExceptionReport)
          apolloCacheDeleteItem({
            id: result.rosteringExceptionReportDelete.rosteringExceptionReport
              .id,
            __typename: "RosteringExceptionReport",
          });
        toast.success("Exception report deleted");
        onHide();
      }
    } catch (e) {
      toast.error("An error occurred.");
    }
  };

  return (
    <Modal show={true} onHide={onHide} centered size="lg">
      <ModalHeader>
        <ModalTitle>Delete Exception Report</ModalTitle>
        <CloseButton onClick={onHide} />
      </ModalHeader>
      {loading ? (
        <Loading modal />
      ) : (
        <>
          <ModalBody>
            <p>Are you sure you want to delete this exception report?</p>
          </ModalBody>
          <ModalFooter>
            <div>
              {error && (
                <ErrorContainer>
                  <p>{error}</p>
                </ErrorContainer>
              )}
            </div>
            <ButtonsContainer>
              <Button onClick={onHide} variant="secondary" type="button">
                Cancel
              </Button>
              <Button
                onClick={deleteExceptionReport}
                type="button"
                variant="danger"
              >
                Delete
              </Button>
            </ButtonsContainer>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default ExceptionReportDeleteModal;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
