import toast from "react-hot-toast";
import dayjs from "dayjs";
import jwtDecode, { JwtPayload } from "jwt-decode";

import {
  CategoryApi,
  ClaimApi,
  Configuration as ExpensesApiConfiguration,
} from "@patchworkhealth/expenses-api";
import {
  Configuration as SelfRosteringConfiguration,
  RosteringPeriodsApi,
} from "@patchworkhealth/selfrostering-api";
import { SelfRosteringPreferencesApi } from "@patchworkhealth/selfrostering-api/dist/apis/SelfRosteringPreferencesApi";
import {
  Configuration as ServicePlanConfiguration,
  FetchParams,
  Middleware,
  RequestContext,
  ShiftActivitiesApi,
} from "@patchworkhealth/serviceplans-api";

import { basePathFor } from "./microServiceHelpers";
import { refreshToken } from "./refreshToken";
import { deleteTokens, getTokens } from "./tokens";

class ApiMiddleware implements Middleware {
  // Called before calling an api
  public async pre(context: RequestContext): Promise<void | FetchParams> {
    const tokens = getTokens();

    if (tokens) {
      const { exp: tokenExpiry } = jwtDecode<JwtPayload>(tokens.token);
      const { exp: refreshExpiry } = jwtDecode<JwtPayload>(tokens.refreshToken);

      const hasTokenExpired = dayjs().isAfter(dayjs.unix(tokenExpiry ?? 0));
      const hasRefreshTokenExpired = dayjs().isAfter(
        dayjs.unix(refreshExpiry ?? 0)
      );

      if (hasRefreshTokenExpired) {
        deleteTokens();
        window.location.reload();
        return;
      }

      if (!hasTokenExpired) {
        return {
          url: context.url,
          init: {
            ...context.init,
            headers: new Headers({
              ...context.init.headers,
              "x-authorization": tokens.token,
            }),
          },
        };
      }

      if (hasTokenExpired) {
        const refreshedToken = await refreshToken(tokens);

        if (refreshedToken)
          return {
            url: context.url,
            init: {
              ...context.init,
              headers: new Headers({
                ...context.init.headers,
                "x-authorization": refreshedToken,
              }),
            },
          };
      }
    }
  }
}

const DEFAULT_SERVICE_PLAN_CONFIG = new ServicePlanConfiguration({
  basePath: basePathFor("service-plans"),
  middleware: [new ApiMiddleware()],
});

export const servicePlanApiClient = {
  shiftActivitiesApi: new ShiftActivitiesApi(DEFAULT_SERVICE_PLAN_CONFIG),
};

const DEFAULT_SELF_ROSTERING_CONFIGURATION = new SelfRosteringConfiguration({
  basePath: basePathFor("self-rostering"),
  middleware: [new ApiMiddleware()],
});

export const selfRosteringPreferencesApi = new SelfRosteringPreferencesApi(
  DEFAULT_SELF_ROSTERING_CONFIGURATION
);

export const rosteringPeriodsApi = new RosteringPeriodsApi(
  DEFAULT_SELF_ROSTERING_CONFIGURATION
);

export const rosteringPeriodApi = new RosteringPeriodsApi(
  DEFAULT_SELF_ROSTERING_CONFIGURATION
);

const DEFAULT_EXPENSES_CONFIG = new ExpensesApiConfiguration({
  basePath: basePathFor("expenses"),
  middleware: [new ApiMiddleware()],
});

export const expensesApi = {
  categoryApi: new CategoryApi(DEFAULT_EXPENSES_CONFIG),
  claimApi: new ClaimApi(DEFAULT_EXPENSES_CONFIG),
};

export type ResponseError = {
  response?: {
    status: number;
    statusText: string;
  };
};

export const onReactQueryError = (error: ResponseError) => {
  if (error.response) {
    toast.error(`Error: ${error.response.status} ${error.response.statusText}`);
  } else {
    toast.error("Unknown Server Error");
  }
};
