import { FormEvent, useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

import {
  isValidPassword,
  NOTIFICATION_MESSAGES,
} from "@patchworkhealth/web-components";

import { VmsLogin } from "components/Auth";
import LayoutAuth from "components/Layout/LayoutAuth";
import { Wrapper } from "components/Style";
import { AppContext } from "context/AppContext";
import { useVmsSignupMutation } from "gql/new-gql/__generated__/mutations.generated";
import { useVmsAgencyInvitationQuery } from "gql/new-gql/__generated__/queries.generated";
import { formatError } from "helpers/formatError";
import { saveTokens } from "helpers/functions";

export default function AgencyPortalPage() {
  const store = useContext(AppContext);
  const history = useHistory();

  // TOKEN STATE ********************

  const [token, setToken] = useState("");
  const [loginVisible, setLoginVisible] = useState(false);

  // VMS LOGIN STATE ***************

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [niNumber, setNiNumber] = useState("");
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);

  // GQL ****************************

  const [vmsSignup, { loading }] = useVmsSignupMutation();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValidPassword(password)) {
      toast.error(NOTIFICATION_MESSAGES.PASSWORD.WEAK);
      return;
    }

    try {
      const { data } = await vmsSignup({
        variables: {
          password,
          niNumber: niNumber.toUpperCase(),
          token,
        },
      });

      if (data?.vmsSignup?.errors.length) {
        toast.error(formatError(data?.vmsSignup.errors));
        return;
      }

      /* Here if
          1. Details Incorrect - Prevent from signin up
          2. Details Correct - Trigger AgencyOnboarding,
          3. Log them into app and force to /agencies page - show user profile info step form
          4. Saved tokens from response to be able to log in.
        */
      toast.success("Agency Match");

      saveTokens({
        refreshToken: data?.vmsSignup?.account?.refreshToken,
        token: data?.vmsSignup?.account?.token,
      });
      window.location.reload();
    } catch (_e) {
      toast.error("Error!");
    }
  };

  // Determine Status of Token **********************
  useVmsAgencyInvitationQuery({
    skip: !token,
    variables: {
      token,
    },
    onCompleted: (data) => {
      if (!data) {
        toast.error("There was an error with Token");
        history.push("/");
        return;
      }

      if (data.vmsAgencyInvitation) {
        store.setAgencyRegistrationId(
          data.vmsAgencyInvitation?.agencyRegistrationId
        );
        store.setAgencyOnboarding(true);

        setEmail(data.vmsAgencyInvitation?.email);

        if (data.vmsAgencyInvitation.userStatus === "pending") {
          toast.success("Agency invite, please signup");
          setLoginVisible(true);
        } else {
          toast.success("User already Activated");
          history.push("/");
        }
      } else {
        toast.error("There was an error");
        history.push("/");
      }
    },
  });

  useEffect(() => {
    const index = window.location.href.indexOf("portal/");
    const token = decodeURIComponent(window.location.href.slice(index + 7));
    setToken(token);
  }, []);

  return loginVisible ? (
    <LayoutAuth title="Vms Login">
      <Wrapper>
        <Container>
          <VmsLogin
            isPasswordHidden={isPasswordHidden}
            setPassword={setPassword}
            setNiNumber={setNiNumber}
            onSubmit={onSubmit}
            setIsPasswordHidden={setIsPasswordHidden}
            loading={loading}
            email={email}
          />
        </Container>
      </Wrapper>
    </LayoutAuth>
  ) : null;
}
