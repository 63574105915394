import { ShiftIcon } from "components/Shared/ShiftIcon";
import { formatTimeInTZ } from "helpers/momentHelpers";

import { RosteringEventTypeEnum } from "../../../baseCODEGEN";
import {
  OtherEventContainer,
  RosteringShiftContainer,
} from "./RosteringShift.styled";

export enum ShiftType {
  Early = "Early",
  Twilight = "Twilight",
  Late = "Late",
  LongDay = "Long Day",
  Night = "Night",
  Day = "Day",
  OnCall = "Non-Resident On Call",
  Mid = "Mid",
  Night1 = "Night 1",
  Night2 = "Night 2",
}

interface Props {
  shiftType: ShiftType;
  activitiesLabel: string;
  startsAt: string;
  endsAt: string;
  isMultiple: boolean;
  eventType: RosteringEventTypeEnum;
  onClick: () => void;
  minShiftHeight?: number | `${string}px`;
}

export const RosteringShift = ({
  shiftType,
  startsAt,
  endsAt,
  activitiesLabel,
  isMultiple,
  eventType,
  onClick,
  minShiftHeight,
}: Props) => {
  if (eventType === RosteringEventTypeEnum.Rest) {
    return (
      <OtherEventContainer>
        <p>Day Off</p>
      </OtherEventContainer>
    );
  }

  if (!shiftType) return null;
  return (
    <RosteringShiftContainer
      shiftType={shiftType}
      onClick={onClick}
      minShiftHeight={minShiftHeight}
    >
      <div>
        <p>
          {`${formatTimeInTZ(startsAt, "HH:mm")} - ${formatTimeInTZ(
            endsAt,
            "HH:mm"
          )}`}
        </p>
        {!isMultiple && (
          <>
            <p className="activity_name">{activitiesLabel}</p>
            <div>
              <ShiftIcon type={shiftType} />
            </div>
          </>
        )}
      </div>
    </RosteringShiftContainer>
  );
};
