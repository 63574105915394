import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import dayjs from "dayjs";

import { Loading } from "@patchworkhealth/web-components";

import Layout from "components/Layout/Layout";
import {
  ShiftCardFragment,
  useWorkersShiftsQuery,
} from "components/ShiftsNew/__generated__/Shifts.generated";
import { InitFilters, InitInputs } from "components/ShiftsNew/Shift.types";
import { ShiftCard } from "components/ShiftsNew/ShiftCard";
import ShiftFilters from "components/ShiftsNew/ShiftFilters";
import { ShiftDateBadge } from "components/ShiftsNew/ShiftHelpers";
import NoShiftCard, { FiltersIcon } from "components/ShiftsNew/ShiftIcons";
import { ShiftsModal } from "components/ShiftsNew/ShiftsModal";
import { Button } from "components/UI";
import { BackToTopButton } from "components/UI/BackToTop";
import { formatTimeInTZ } from "helpers/functions";
import { createFormFunctions } from "helpers/newHelpers";

const initInputs: InitInputs = {
  sidebarOpen: false,
  openModal: false,
  selectedShift: null,
};

const initFilters: InitFilters = {
  fromStartTime: formatTimeInTZ(new Date(), "YYYY-MM-DD"),
  toStartTime: null,
  offset: 0,
  items: 22,
  organisationIds: [],
  siteIds: [],
  shiftType: undefined,
  specialityIds: [],
  status: null,
  gradeIds: [],
};

function ShiftPage() {
  /* State -------------------------------------------------------- */

  const [inputs, setInputs] = useState(initInputs);
  const [filters, setFilters] = useState(initFilters);
  const { handleFilter, handleDateChange } = createFormFunctions(setFilters);

  /* Graphql ------------------------------------------------------ */

  const {
    data: { shifts: result } = {},
    loading,
    error,
    fetchMore,
  } = useWorkersShiftsQuery({
    variables: {
      ...filters,
      shiftType: filters.shiftType?.value ?? undefined,
      organisationIds: filters?.organisationIds?.map((org) => org?.id),
      siteIds: filters?.siteIds?.map((site) => site?.value),
      status: filters.status?.value ?? null,
      specialityIds: filters?.specialityIds?.map((s) => s?.value),
      gradeIds: filters?.gradeIds?.map((grade) => Number(grade?.value)),
    },
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  /* Functions --------------------------------------------------- */

  const fetchMoreShifts = async () => {
    await fetchMore({
      variables: {
        offset: result?.length ?? 0,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          ...prev,
          shifts: [...prev.shifts, ...fetchMoreResult.shifts],
        };
      },
    });
  };

  const toggleModal = (e: ShiftCardFragment | null = null) => {
    setInputs({
      ...inputs,
      openModal: !inputs.openModal,
      selectedShift: e || null,
    });
  };

  const resetFilters = () => setFilters(initFilters);

  const toggleFilters = () => {
    setInputs({ ...inputs, sidebarOpen: !inputs.sidebarOpen });
  };

  const groupedShifts: { [key: string]: ShiftCardFragment[] } = {};
  result?.forEach((shift) => {
    const dateStr = dayjs(shift.startTime).format("YYYY-MM-DD");
    if (groupedShifts[dateStr]) {
      groupedShifts[dateStr].push(shift);
    } else {
      groupedShifts[dateStr] = [shift];
    }
  });

  const showReset = Object.keys(filters).some((key) => {
    return (
      filters[key as keyof typeof filters] !==
      initFilters[key as keyof typeof initFilters]
    );
  });

  /* Render ------------------------------------------------------ */

  return (
    <Layout headerValueText="Shifts">
      <div className="flex [&>button]:mr-4 mb-4">
        <Button icon={FiltersIcon} onClick={toggleFilters}>
          {inputs.sidebarOpen ? "Close" : "Filters"}
        </Button>

        {showReset && (
          <Button
            onClick={() => {
              resetFilters();
              setInputs({ ...inputs, sidebarOpen: false });
            }}
          >
            Reset Filters
          </Button>
        )}
      </div>

      {!error && result && result?.length > 0 && (
        <>
          <InfiniteScroll
            dataLength={result.length}
            next={fetchMoreShifts}
            hasMore
            loader={loading && <Loading />}
            style={{ overflow: "none" }}
          >
            {Object.keys(groupedShifts).map((date) => {
              return (
                <>
                  <ShiftDateBadge date={date} />
                  <div className="flex flex-wrap pb-8 mb-10 border-b border-b-grey-2">
                    {groupedShifts[date].map((shift) => (
                      <div
                        className="flex w-full justify-center sm:p-0 md:w-[364px] md:justify-start"
                        key={shift.id}
                      >
                        <ShiftCard
                          shift={shift}
                          key={shift.id}
                          onClick={toggleModal}
                        />
                      </div>
                    ))}
                  </div>
                </>
              );
            })}
          </InfiniteScroll>
        </>
      )}

      {loading && !result && <Loading />}

      {!loading && result?.length === 0 && <NoShiftCard />}

      {error && (
        <p className="font-bold text-center">
          Unable to load shifts, please refresh the page.
        </p>
      )}

      {inputs.openModal && (
        <ShiftsModal
          handleClose={toggleModal}
          openModal={inputs.openModal}
          selectedShift={inputs.selectedShift}
        />
      )}

      <ShiftFilters
        inputs={inputs}
        filters={filters}
        setInputs={setInputs}
        handleFilter={handleFilter}
        handleDateChange={handleDateChange}
        resetFilters={resetFilters}
      />

      <BackToTopButton />
    </Layout>
  );
}

export default ShiftPage;
