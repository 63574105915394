export const OrgTickIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0C3.58333 0 0 3.58333 0 8C0 12.4167 3.58333 16 8 16C12.4167 16 16 12.4167 16 8C16 3.58333 12.4167 0 8 0Z"
      fill="#57A886"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6372 6.47002L7.63722 10.47C7.50722 10.6 7.33722 10.6667 7.16722 10.6667C6.99722 10.6667 6.82722 10.6 6.69722 10.47L4.36388 8.13669C4.10055 7.87669 4.10055 7.45669 4.36388 7.19669C4.62388 6.93335 5.04388 6.93335 5.30388 7.19669L7.16722 9.05669L10.6972 5.53002C10.9572 5.26669 11.3772 5.26669 11.6372 5.53002C11.9005 5.79002 11.9005 6.21002 11.6372 6.47002Z"
      fill="white"
    />
  </svg>
);

export const OrgPendingIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.54157 14.148C9.41752 14.1789 9.29105 14.2063 9.16551 14.2294C8.83734 14.2905 8.62038 14.6061 8.68108 14.9346C8.71106 15.0961 8.80283 15.2307 8.92743 15.3186C9.05585 15.409 9.21929 15.4498 9.38583 15.4188C9.53533 15.3911 9.686 15.3584 9.83385 15.3215C10.1579 15.2409 10.3553 14.9126 10.2745 14.5887C10.1938 14.2645 9.86583 14.0672 9.54157 14.148Z"
      fill="#FF763F"
    />
    <path
      d="M14.0138 6.04184C14.0562 6.16951 14.1371 6.27374 14.2392 6.34575C14.3907 6.45244 14.5889 6.48833 14.7781 6.42579C15.0952 6.3205 15.267 5.97855 15.1621 5.6616C15.1143 5.51711 15.0612 5.37217 15.0047 5.23105C14.8807 4.92092 14.5289 4.76993 14.2186 4.89398C13.9087 5.01797 13.7576 5.36984 13.8818 5.68007C13.9292 5.79868 13.9737 5.92044 14.0138 6.04184Z"
      fill="#FF763F"
    />
    <path
      d="M11.5068 13.2844C11.4001 13.3548 11.29 13.423 11.1791 13.4869C10.8897 13.6538 10.7906 14.0237 10.9574 14.313C11.0027 14.3917 11.0631 14.456 11.1326 14.5051C11.3191 14.6364 11.5725 14.6561 11.7834 14.5345C11.9153 14.4585 12.0465 14.3776 12.1735 14.2935C12.4521 14.1095 12.5288 13.7343 12.3446 13.4556C12.1605 13.1768 11.7855 13.1002 11.5068 13.2844Z"
      fill="#FF763F"
    />
    <path
      d="M15.5381 7.73094C15.5249 7.39715 15.2438 7.13734 14.9099 7.15037C14.5764 7.16353 14.3164 7.44478 14.3295 7.77845C14.3345 7.90602 14.3358 8.03551 14.3329 8.16295C14.3282 8.37228 14.4306 8.55887 14.5897 8.67102C14.6845 8.73777 14.7995 8.77816 14.9242 8.78101C15.258 8.78841 15.5346 8.52366 15.542 8.1897C15.5454 8.03742 15.5441 7.88313 15.5381 7.73094Z"
      fill="#FF763F"
    />
    <path
      d="M13.9151 11.6935C13.6474 11.4926 13.2687 11.5472 13.0683 11.8143C12.9915 11.9167 12.9106 12.0177 12.8279 12.115C12.6115 12.3692 12.6421 12.751 12.8962 12.9675C12.9107 12.9798 12.9254 12.9911 12.9406 13.0017C13.1933 13.1798 13.5445 13.1389 13.7488 12.8991C13.8476 12.783 13.944 12.6623 14.0358 12.5401C14.2363 12.2729 14.182 11.894 13.9151 11.6935Z"
      fill="#FF763F"
    />
    <path
      d="M14.8041 9.50735C14.4854 9.40742 14.146 9.58483 14.0461 9.90353C14.0079 10.0253 13.9655 10.1476 13.9198 10.2674C13.8194 10.5312 13.9156 10.8214 14.1362 10.9769C14.1766 11.0053 14.2212 11.0294 14.2696 11.0477C14.5816 11.1668 14.931 11.0102 15.05 10.6981C15.1043 10.5556 15.1547 10.4101 15.2002 10.2653C15.3 9.94657 15.1227 9.60727 14.8041 9.50735Z"
      fill="#FF763F"
    />
    <path
      d="M6.90297 14.2346C6.36227 14.1375 5.8436 13.9722 5.35176 13.7416C5.34594 13.7386 5.34073 13.7351 5.33462 13.7324C5.21872 13.6778 5.10301 13.6194 4.99086 13.5583C4.99047 13.5579 4.98976 13.5576 4.98915 13.5573C4.78338 13.444 4.58256 13.3186 4.38746 13.1813C1.54258 11.1773 0.858557 7.23236 2.8627 4.38752C3.29849 3.76914 3.82587 3.25324 4.41382 2.84436C4.42106 2.83931 4.4283 2.8343 4.43548 2.82922C6.50731 1.40167 9.32053 1.30547 11.5205 2.7724L11.048 3.4551C10.9166 3.64512 10.9974 3.78359 11.2274 3.7629L13.2798 3.57915C13.5101 3.55845 13.6478 3.35928 13.5858 3.13692L13.0347 1.15115C12.973 0.928528 12.815 0.901881 12.6835 1.09187L12.2099 1.77618C10.5955 0.692425 8.65908 0.279172 6.73711 0.612452C6.54353 0.645954 6.35267 0.68696 6.16443 0.734691C6.16297 0.73495 6.16181 0.735112 6.16064 0.73537C6.15337 0.737149 6.14599 0.73951 6.13891 0.741482C4.48156 1.16709 3.03555 2.13372 2.00373 3.51289C1.99503 3.52321 1.98607 3.53329 1.97786 3.54452C1.94354 3.59073 1.90949 3.63801 1.87615 3.68529C1.82163 3.76277 1.76788 3.84219 1.71646 3.92161C1.71003 3.93119 1.70511 3.94092 1.69949 3.95059C0.848014 5.27003 0.437316 6.79544 0.507749 8.3484C0.507911 8.3535 0.50762 8.35865 0.507749 8.36389C0.514572 8.51558 0.526602 8.66939 0.542933 8.8208C0.543806 8.83056 0.545973 8.83981 0.547622 8.84958C0.564503 9.00183 0.585685 9.15443 0.612235 9.307C0.882067 10.8637 1.61641 12.2645 2.71679 13.3545C2.71934 13.357 2.722 13.3598 2.72458 13.3625C2.72549 13.3635 2.72649 13.364 2.72736 13.3649C3.023 13.6565 3.34458 13.9262 3.69082 14.1701C4.59695 14.8086 5.60594 15.2306 6.68954 15.4251C7.01832 15.4842 7.33239 15.2653 7.39138 14.9367C7.45033 14.6078 7.23166 14.2935 6.90297 14.2346Z"
      fill="#FF763F"
    />
    <path
      d="M7.6507 3.13623C7.38025 3.13623 7.16119 3.35549 7.16119 3.62554V8.50047L11.6197 10.8053C11.6915 10.8424 11.7683 10.8599 11.8439 10.8599C12.021 10.8599 12.192 10.7635 12.279 10.5952C12.403 10.3551 12.3092 10.0601 12.069 9.93604L8.13966 7.9046V3.62554C8.13963 3.35549 7.92082 3.13623 7.6507 3.13623Z"
      fill="#FF763F"
    />
  </svg>
);

export const OrgEmailIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 2.6665L2.66663 2.6665C1.93329 2.6665 1.33329 3.2665 1.33329 3.99984L1.33329 11.9998C1.33329 12.7332 1.93329 13.3332 2.66663 13.3332L13.3333 13.3332C14.0666 13.3332 14.6666 12.7332 14.6666 11.9998L14.6666 3.99984C14.6666 3.2665 14.0666 2.6665 13.3333 2.6665Z"
      stroke="#646E7B"
      strokeWidth="1.33962"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.33329 3.99951L7.99996 8.66618L14.6666 3.99951"
      stroke="#646E7B"
      strokeWidth="1.33962"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const OrgPhoneIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M12.6666 6.04207C12.5395 5.39091 12.2211 4.79248 11.752 4.32336C11.2828 3.85424 10.6844 3.53578 10.0333 3.40874M15.3333 6.04207C15.183 4.68922 14.5771 3.42768 13.6152 2.46458C12.6534 1.50148 11.3926 0.894062 10.0399 0.742069M4.71992 1.40874L2.71992 1.40874C2.53425 1.40798 2.35047 1.44601 2.18035 1.5204C2.01023 1.59478 1.85753 1.70387 1.73201 1.84068C1.60649 1.9775 1.51093 2.13902 1.45145 2.3149C1.39196 2.49079 1.36987 2.67715 1.38658 2.86207C1.60949 4.91351 2.31049 6.88407 3.43325 8.6154C4.45681 10.2262 5.82247 11.5918 7.43325 12.6154C9.17245 13.7421 11.1526 14.4432 13.2133 14.6621C13.3976 14.6787 13.5834 14.6568 13.7588 14.5977C13.9343 14.5387 14.0955 14.4437 14.2322 14.3189C14.3689 14.1941 14.4781 14.0422 14.5529 13.8729C14.6277 13.7036 14.6664 13.5205 14.6666 13.3354L14.6666 11.3354C14.6698 11.0119 14.5552 10.6982 14.3442 10.4529C14.1333 10.2076 13.8403 10.0474 13.5199 10.0021C12.8799 9.91765 12.2514 9.7611 11.6466 9.5354C11.408 9.44571 11.1486 9.42629 10.8993 9.47946C10.65 9.53264 10.4212 9.65616 10.2399 9.8354L9.39325 10.6821C7.72422 9.73303 6.34229 8.3511 5.39325 6.68207L6.23992 5.8354C6.41916 5.65414 6.54269 5.4253 6.59586 5.17599C6.64903 4.92668 6.62961 4.66735 6.53992 4.42874C6.31422 3.82389 6.15767 3.19545 6.07325 2.5554C6.02756 2.23156 5.86445 1.9358 5.61492 1.72438C5.3654 1.51296 5.04687 1.40062 4.71992 1.40874Z"
        stroke="#646E7B"
        strokeWidth="1.33962"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 16 16.0752)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const OrgLocationIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 7.33301L1.33333 1.33301L7.33333 13.9997L8.66667 8.66634L14 7.33301Z"
      stroke="#646E7B"
      strokeWidth="1.33962"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const OrgCollabIcon = () => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="mr-1"
  >
    <rect
      x="4.17139"
      y="1"
      width="8.4569"
      height="8.2139"
      rx="1.02674"
      fill="white"
      stroke="#007AC3"
      strokeWidth="1.02674"
    />
    <rect
      x="2.58569"
      y="2.54004"
      width="8.4569"
      height="8.2139"
      rx="1.02674"
      fill="white"
      stroke="#007AC3"
      strokeWidth="1.02674"
    />
    <rect
      x="1"
      y="4.08008"
      width="8.4569"
      height="8.2139"
      rx="1.02674"
      fill="white"
      stroke="#007AC3"
      strokeWidth="1.02674"
    />
    <path
      d="M7.87125 7.86611V8.50782C7.87125 8.64017 7.75976 8.74846 7.62349 8.74846H5.80658V10.5132C5.80658 10.6455 5.69509 10.7538 5.55882 10.7538H4.89813C4.76186 10.7538 4.65037 10.6455 4.65037 10.5132V8.74846H2.83345C2.69719 8.74846 2.58569 8.64017 2.58569 8.50782V7.86611C2.58569 7.73375 2.69719 7.62546 2.83345 7.62546H4.65037V5.86076C4.65037 5.72841 4.76186 5.62012 4.89813 5.62012H5.55882C5.69509 5.62012 5.80658 5.72841 5.80658 5.86076V7.62546H7.62349C7.75976 7.62546 7.87125 7.73375 7.87125 7.86611Z"
      fill="#007AC3"
    />
  </svg>
);

// Org icon for accept screen

export const OrgDocumentEmpty = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="7.5" fill="white" stroke="#CCD2D8" />
  </svg>
);

export const OrgAcceptIcon = () => (
  <svg
    width="209"
    height="174"
    viewBox="0 0 209 174"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4090:102405)">
      <path
        d="M149.221 39.2397H147.737C147.444 39.2397 147.207 39.477 147.207 39.7697V59.6777C147.207 59.9704 147.444 60.2076 147.737 60.2076H149.221C149.514 60.2076 149.751 59.9704 149.751 59.6777V39.7697C149.751 39.477 149.514 39.2397 149.221 39.2397Z"
        fill="#3F3D56"
      />
      <path
        d="M139.556 0H72.49C67.5066 0 63.4668 4.03983 63.4668 9.0232V163.955C63.4668 168.939 67.5066 172.979 72.49 172.979H139.556C144.539 172.979 148.579 168.939 148.579 163.955V9.0232C148.579 4.03983 144.539 0 139.556 0Z"
        fill="#3F3D56"
      />
      <path
        d="M136.733 4.50562H126.97V5.68009C126.97 6.41115 126.826 7.13506 126.546 7.81043C126.266 8.48581 125.856 9.09943 125.339 9.61623C124.822 10.133 124.208 10.5429 123.532 10.8224C122.857 11.1019 122.133 11.2455 121.402 11.2451H90.3517C89.6207 11.2455 88.8967 11.1019 88.2212 10.8224C87.5456 10.5429 86.9318 10.133 86.4147 9.61623C85.8976 9.09943 85.4874 8.48581 85.2076 7.81043C84.9277 7.13506 84.7837 6.41115 84.7837 5.68009V4.50562H75.6425C73.8497 4.50562 72.1303 5.21779 70.8627 6.48547C69.595 7.75315 68.8828 9.47249 68.8828 11.2653V161.725C68.8828 163.518 69.595 165.237 70.8627 166.505C72.1303 167.773 73.8497 168.485 75.6425 168.485H136.733C138.526 168.485 140.245 167.773 141.513 166.505C142.78 165.237 143.493 163.518 143.493 161.725V11.2768C143.494 10.3882 143.32 9.50793 142.981 8.68647C142.642 7.86502 142.145 7.11848 141.517 6.48956C140.889 5.86064 140.143 5.3617 139.322 5.02127C138.502 4.68084 137.622 4.50561 136.733 4.50562Z"
        fill="#F5F7FA"
      />
      <path
        d="M108.804 5.16577H98.7101C98.2154 5.16577 97.8145 5.56675 97.8145 6.06138V6.68229C97.8145 7.17692 98.2154 7.5779 98.7101 7.5779H108.804C109.299 7.5779 109.7 7.17692 109.7 6.68229V6.06138C109.7 5.56675 109.299 5.16577 108.804 5.16577Z"
        fill="#E6E8EC"
      />
      <path
        d="M112.866 7.74179C113.622 7.74179 114.236 7.12851 114.236 6.37199C114.236 5.61548 113.622 5.0022 112.866 5.0022C112.109 5.0022 111.496 5.61548 111.496 6.37199C111.496 7.12851 112.109 7.74179 112.866 7.74179Z"
        fill="#E6E8EC"
      />
      <path
        d="M106.542 104.714C115.434 104.714 122.643 97.5053 122.643 88.613C122.643 79.7208 115.434 72.5122 106.542 72.5122C97.65 72.5122 90.4414 79.7208 90.4414 88.613C90.4414 97.5053 97.65 104.714 106.542 104.714Z"
        fill="white"
      />
      <path
        d="M127.72 86.3646C127.72 90.6207 126.458 94.7812 124.093 98.32C121.729 101.859 118.368 104.617 114.435 106.245C110.503 107.874 106.176 108.3 102.002 107.47C97.8278 106.639 93.9935 104.59 90.984 101.58C87.9746 98.5702 85.9252 94.7358 85.095 90.5614C84.2648 86.387 84.6911 82.0602 86.32 78.1281C87.9489 74.196 90.7072 70.8352 94.2461 68.4708C97.785 66.1063 101.946 64.8443 106.202 64.8445C109.028 64.8446 111.826 65.4013 114.437 66.4828C117.048 67.5644 119.42 69.1495 121.418 71.1479C123.416 73.1462 125.001 75.5185 126.083 78.1294C127.164 80.7403 127.72 83.5386 127.72 86.3646ZM103.714 97.7552L119.677 81.7923C119.806 81.6635 119.909 81.5104 119.979 81.3419C120.049 81.1734 120.085 80.9927 120.085 80.8103C120.085 80.6278 120.049 80.4472 119.979 80.2786C119.909 80.1101 119.806 79.9571 119.677 79.8282L117.713 77.8785C117.584 77.7494 117.432 77.6469 117.263 77.577C117.095 77.5071 116.915 77.4712 116.732 77.4712C116.55 77.4712 116.37 77.5071 116.201 77.577C116.033 77.6469 115.88 77.7494 115.752 77.8785L102.732 90.8981L96.6532 84.8048C96.3922 84.5452 96.0392 84.3996 95.6711 84.3996C95.3031 84.3996 94.95 84.5452 94.6891 84.8048L92.725 86.7804C92.4655 87.0414 92.3198 87.3944 92.3198 87.7625C92.3198 88.1305 92.4655 88.4835 92.725 88.7445L101.751 97.7668C101.879 97.896 102.032 97.9985 102.201 98.0684C102.369 98.1383 102.55 98.1744 102.733 98.1744C102.915 98.1744 103.096 98.1383 103.264 98.0684C103.433 97.9985 103.586 97.896 103.715 97.7668L103.714 97.7552Z"
        fill="#6C63FF"
      />
      <path
        d="M66.1317 34.1018H65.9089C65.6162 34.1018 65.3789 34.3391 65.3789 34.6318V39.777C65.3789 40.0697 65.6162 40.307 65.9089 40.307H66.1317C66.4244 40.307 66.6617 40.0697 66.6617 39.777V34.6318C66.6617 34.3391 66.4244 34.1018 66.1317 34.1018Z"
        fill="#3F3D56"
      />
      <path
        d="M66.2475 45.4641H65.862C65.5693 45.4641 65.332 45.7014 65.332 45.9941V55.7338C65.332 56.0264 65.5693 56.2637 65.862 56.2637H66.2475C66.5402 56.2637 66.7775 56.0264 66.7775 55.7338V45.9941C66.7775 45.7014 66.5402 45.4641 66.2475 45.4641Z"
        fill="#3F3D56"
      />
      <path
        d="M66.2013 60.0425H65.8854C65.5927 60.0425 65.3555 60.2798 65.3555 60.5724V70.4049C65.3555 70.6976 65.5927 70.9349 65.8854 70.9349H66.2013C66.494 70.9349 66.7313 70.6976 66.7313 70.4049V60.5724C66.7313 60.2798 66.494 60.0425 66.2013 60.0425Z"
        fill="#3F3D56"
      />
      <path
        d="M48.3066 88.8457C48.3434 88.8812 48.3907 88.9039 48.4414 88.9104C48.4921 88.9169 48.5435 88.9069 48.5881 88.8818C48.493 88.8833 48.3982 88.8711 48.3066 88.8457Z"
        fill="url(#paint0_linear_4090:102405)"
      />
      <path
        d="M58.9452 93.0066L58.6675 94.5192L58.4532 95.6763L58.0635 97.7929L54.2846 100.439C54.2846 100.439 53.9439 100.147 53.382 99.6873C51.8333 98.4122 48.5784 95.8156 46.1882 94.4217C45.5734 94.0299 44.9082 93.7234 44.2109 93.5106L58.9452 93.0066Z"
        fill="#FEB9B9"
      />
      <path
        d="M56.1716 94.132C56.1716 94.132 60.5777 94.8883 60.8357 93.0066C61.0938 91.1249 58.5678 90.108 56.4271 89.9814C54.2864 89.8548 41.4383 90.7377 41.4383 90.7377C41.4383 90.7377 39.1719 93.7603 47.3569 94.2662L56.1716 94.132Z"
        fill="#3F3D56"
      />
      <path
        d="M65.8076 32.0393H42.7578V59.3713H65.8076V32.0393Z"
        fill="#2F2E41"
      />
      <path
        opacity="0.1"
        d="M65.8076 32.1633H42.7578V59.4954H65.8076V32.1633Z"
        fill="black"
      />
      <path
        d="M15.7437 78.828C15.3641 78.9236 14.8791 79.2909 14.3396 79.8218C12.0166 82.1217 8.6894 87.5189 8.6894 87.5189C4.43561 82.4519 9.14276 78.4485 11.1492 77.0727C11.637 76.7397 11.9647 76.5564 11.9647 76.5564L15.7437 78.828Z"
        fill="#FEB9B9"
      />
      <path
        d="M58.6912 40.6658C58.6912 40.6658 59.8252 48.6004 63.7296 50.4897C67.6339 52.379 58.6912 55.9101 58.6912 55.9101L46.4746 51.3723C46.4746 51.3723 53.4025 49.1044 52.0163 42.4285L58.6912 40.6658Z"
        fill="#FEB9B9"
      />
      <path
        d="M45.5922 88.4016L45.3159 89.9091L45.0914 91.0706L44.7017 93.1872L40.9228 95.8303C40.9228 95.8303 40.5822 95.5413 40.0203 95.0819C38.4716 93.8067 35.2168 91.2102 32.823 89.8163C32.2085 89.4239 31.5432 89.1174 30.8457 88.9052L45.5922 88.4016Z"
        fill="#FEB9B9"
      />
      <path
        d="M42.3231 90.2606C42.3231 90.2606 46.2774 90.2886 46.5153 88.4016C46.7533 86.5146 44.3603 85.503 42.3231 85.3765C40.2859 85.2499 27.908 86.1915 27.908 86.1915C27.908 86.1915 26.1519 89.7555 33.9377 90.2604L42.3231 90.2606Z"
        fill="#2F2E41"
      />
      <path
        d="M70.0485 119.905C69.8757 119.82 69.7026 119.732 69.5322 119.647L69.0675 119.061L62.9735 111.333V111.467C62.9323 112.758 62.4935 122.985 59.0707 123.929C55.4184 124.936 51.0123 125.189 49.0038 118.388C46.9953 111.586 43.3254 96.7237 39.5566 95.8411L45.1629 91.2441C45.1629 91.2441 48.8747 95.5856 50.387 99.7413C50.3303 99.4832 47.7671 87.5066 49.8707 85.3875H68.2631C68.2631 85.3875 71.1024 93.4743 73.0485 97.4803C74.5636 100.591 76.8376 107.944 78.3018 112.921C79.7512 117.835 74.6585 122.174 70.0485 119.905Z"
        fill="#3F3D56"
      />
      <path
        opacity="0.1"
        d="M69.5238 87.6454C66.6845 89.3258 54.22 88.6417 50.165 88.3682L49.0602 88.2881C48.9415 88.2881 49.0782 88.221 49.0782 88.221L49.1919 87.0775L52.2145 79.3959L68.3984 70.0107C68.0705 70.9994 67.9622 72.512 67.9881 74.2439C68.0628 79.809 69.5238 87.6454 69.5238 87.6454Z"
        fill="black"
      />
      <path
        d="M69.5239 87.266C66.6845 88.9464 54.2201 88.2623 50.1651 87.9887L49.0602 87.9112L48.877 87.8957L49.0783 87.8441L50.0746 87.586L52.2143 79.0218L68.3982 69.6392C68.0705 70.6278 67.962 72.1404 67.9879 73.8724C68.0629 79.4321 69.5239 87.266 69.5239 87.266Z"
        fill="#EFEFEF"
      />
      <path
        opacity="0.1"
        d="M67.9787 73.867C64.6876 74.6259 57.445 76.8524 54.6623 82.23C52.8066 85.8101 51.2449 87.3458 50.1556 87.9885L49.0508 87.911C49.058 87.8889 49.064 87.8665 49.0688 87.8439L50.0651 87.5858L52.2049 79.0215L68.3888 69.6392C68.0648 70.6225 67.9528 72.1351 67.9787 73.867Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M11.964 76.562L15.7429 78.8283C15.3634 78.9239 14.8783 79.2912 14.3388 79.8221C14.0239 78.9031 12.0477 77.6203 11.1484 77.0732C11.636 76.7453 11.964 76.562 11.964 76.562Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M48.3772 88.0583C48.3358 88.0799 48.2886 88.088 48.2424 88.0815C48.1961 88.0751 48.1531 88.0543 48.1191 88.0222C48.2029 88.0468 48.2899 88.0589 48.3772 88.0583Z"
        fill="black"
      />
      <path
        d="M56.9293 49.9861C56.9293 49.9861 53.5274 50.3629 51.1347 47.8438C51.1347 47.8438 41.0678 50.7424 37.6584 57.2908C34.2491 63.8391 11.0723 76.6882 11.0723 76.6882C11.0723 76.6882 15.1041 78.9546 14.7246 80.0876L29.9664 71.2704C29.9664 71.2704 35.3868 67.3676 36.8917 66.485C38.3967 65.6023 46.7182 60.187 48.4812 74.9229C50.2442 89.6588 48.4812 87.8958 48.4812 87.8958C48.4812 87.8958 51.0005 88.7787 54.6528 81.7242C58.3051 74.6698 69.6391 73.0334 69.6391 73.0334L70.2688 65.6047C70.2688 65.6047 72.517 60.6797 70.7851 58.6356C70.2604 58.0303 69.9113 57.293 69.7758 56.5035C69.4893 54.6167 69.7758 51.4728 73.7869 48.8607C79.9585 44.8289 82.3512 41.5561 82.3512 41.5561C82.3512 41.5561 82.4775 39.1608 78.6988 38.0276C74.9201 36.8943 59.5543 30.7229 59.5543 30.7229L59.038 32.8652L71.1618 41.4218C71.1618 41.4218 65.1168 46.3259 60.321 46.2072C60.3208 46.2072 61.2113 49.8595 56.9293 49.9861Z"
        fill="#17BFD6"
      />
      <path
        opacity="0.1"
        d="M59.2623 44.1193C58.9645 43.1096 58.7317 42.0819 58.5655 41.0425L51.8906 42.8059C52.1848 44.0802 52.1322 45.41 51.7383 46.657C53.103 47.0212 54.5448 46.9746 55.8832 46.5232C57.2215 46.0718 58.397 45.2356 59.2623 44.1193Z"
        fill="black"
      />
      <path
        d="M53.7174 46.5226C57.6818 46.5226 60.8956 43.3088 60.8956 39.3443C60.8956 35.3798 57.6818 32.166 53.7174 32.166C49.7529 32.166 46.5391 35.3798 46.5391 39.3443C46.5391 43.3088 49.7529 46.5226 53.7174 46.5226Z"
        fill="#FEB9B9"
      />
      <path
        opacity="0.1"
        d="M61.5254 97.1543C61.5254 97.1543 65.6811 105.342 64.4214 107.858L61.5254 97.1543Z"
        fill="black"
      />
      <path
        d="M57.5355 22.7653C59.528 22.9926 61.4202 23.8468 63.3819 24.314C63.8722 24.4664 64.3942 24.486 64.8945 24.3707C65.6559 24.1358 66.2754 23.3383 67.0653 23.3615C67.5512 23.377 67.9815 23.7074 68.3816 24.0247C69.0232 24.5158 69.6271 25.0542 70.1883 25.6354C71.5408 27.104 72.3797 29.0813 73.6134 30.6791C74.8471 32.2768 76.6066 34.1504 78.3592 33.4177C77.1669 38.0638 73.4482 40.9702 69.2231 41.2595C67.5376 41.3752 65.8107 41.0246 64.1794 41.5176C63.7731 41.6841 63.344 41.7885 62.9066 41.8272C61.967 41.8272 61.2107 40.9651 60.6247 40.1262C59.2515 38.1543 58.1649 35.9164 56.6188 34.1173C56.1025 33.5212 55.5064 32.9558 54.7836 32.8475C54.0609 32.7392 53.3976 33.0849 52.755 33.418C51.6941 33.9678 50.6101 34.5355 49.7867 35.483C48.9633 36.4304 48.4445 37.8291 48.7851 39.0966C48.9684 39.7833 49.3868 40.4028 49.3942 41.1202C49.4097 42.2662 48.3979 43.1026 48.1992 44.2176C48.1543 44.6859 48.1379 45.1566 48.1501 45.6269C48.0624 47.1543 47.0624 48.404 46.021 49.367C45.6132 49.7386 45.1647 50.1413 45.0453 50.7169C44.9652 51.1117 45.0453 51.5269 44.9937 51.9275C44.8414 52.9186 43.8528 53.422 42.9778 53.5511C41.9554 53.7025 40.9112 53.5787 39.9526 53.1924C41.1762 52.6139 41.9299 50.9675 41.6459 49.4936C41.2562 47.4492 39.2016 45.9806 39.378 43.9003C39.4746 43.262 39.666 42.6417 39.9459 42.06C40.3936 40.9359 40.719 39.7668 40.9165 38.5729C41.0713 37.6514 41.1463 36.6732 40.857 35.7931C40.599 34.9928 40.0595 34.3555 39.6025 33.679C38.9831 32.7588 38.4941 31.7572 38.1494 30.7029C38.0244 30.3902 37.9768 30.0519 38.0105 29.7168C38.114 29.3211 38.3344 28.9657 38.643 28.6972C39.7659 27.5169 40.966 26.2915 42.471 25.9534C43.189 25.8342 43.9192 25.8064 44.6443 25.8708C45.9052 25.9103 47.1673 25.8568 48.4204 25.7106C49.4528 25.5894 51.1975 25.6049 52.0727 24.9363C52.8883 24.3115 53.3479 23.5269 54.3518 23.1296C55.3685 22.7527 56.4614 22.6278 57.5369 22.7655L57.5355 22.7653Z"
        fill="#2F2E41"
      />
      <path d="M208.875 172.662H0V173.084H208.875V172.662Z" fill="#E4E7EB" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.5 65C94.6302 65 85 74.6302 85 86.5C85 98.3698 94.6302 108 106.5 108C118.37 108 128 98.3698 128 86.5C128 74.6302 118.37 65 106.5 65Z"
        fill="#57A886"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.275 82.3881L105.525 93.1381C105.176 93.4875 104.719 93.6666 104.262 93.6666C103.805 93.6666 103.348 93.4875 102.999 93.1381L96.728 86.8673C96.0203 86.1685 96.0203 85.0398 96.728 84.341C97.4268 83.6333 98.5555 83.6333 99.2543 84.341L104.262 89.3398L113.749 79.8618C114.448 79.1541 115.576 79.1541 116.275 79.8618C116.983 80.5606 116.983 81.6893 116.275 82.3881Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_4090:102405"
        x1="48.4488"
        y1="88.9128"
        x2="48.4488"
        y2="88.8457"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#808080" stopOpacity="0.251" />
        <stop offset="0.54" stopColor="#808080" stopOpacity="0.122" />
        <stop offset="1" stopColor="#808080" stopOpacity="0.102" />
      </linearGradient>
      <clipPath id="clip0_4090:102405">
        <rect width="208.875" height="173.326" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
