import styled from "styled-components";

import { FormInput, GREY } from "@patchworkhealth/web-components";

import { DocumentIcon, SearchIcon } from "icons/documents";

const ReferencesHeader = ({ onChange, value }) => (
  <>
    <Header>
      <div className="header__left">
        <DocumentIcon />
        <h3>Your Documents</h3>
      </div>
      <div className="header__right">
        <SearchIcon />
        <FormInput
          value={value}
          onChange={onChange}
          type="text"
          placeholder="Search Documents"
        />
      </div>
    </Header>
    <UploadedDivider>
      <label>Uploaded</label>
      <div></div>
    </UploadedDivider>
  </>
);
export default ReferencesHeader;

// Header *********************************

const Header = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .header__left {
    align-items: center;
    display: flex;
    h3 {
      color: #55606c;
      font-size: 14px;
      font-weight: 600;
      line-height: 28px;
      margin: 0 0 0 8px;
    }
  }
  .header__right {
    display: flex;
    position: relative;
    input {
      padding-left: 40px;
      width: 344px;
    }
    svg {
      left: 15px;
      position: absolute;
      top: 50%;
      transform: translate(0%, -50%);
    }
  }
  @media (max-width: 568px) {
    .header__left {
      margin-bottom: 20px;
    }

    .header__right,
    input {
      width: 100% !important;
    }
  }
`;

const UploadedDivider = styled.div`
  align-items: center;
  display: flex;
  margin: 25px 0;
  label {
    color: ${GREY.four};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
  }
  div {
    border: 1px solid #e4e7eb;
    flex: 1;
    height: 1px;
    margin-left: 14px;
  }
`;
