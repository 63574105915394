import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import styled from "styled-components";
import * as Yup from "yup";

import { FormInput, FormLabel } from "@patchworkhealth/web-components";

import { ADD_DOC } from "gql/Mutations";
import { convertToBase64, resetInputs } from "helpers/functions";
import { CloseModal } from "icons/referencesIcons";

import { Divider, FormGrid, Require, StyledButton } from "../Style";

const DocumentsModal = ({ onHide, document, show, details, refetch }) => {
  const [loading, setLoading] = useState(false);

  const [addDoc] = useMutation(ADD_DOC);

  // Formik Function ******************************************

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      documentNumberVisibility: "",
      documentTitleVisibility: "",
      expirationDateVisibility: "",
      issueDateVisibility: "",
      issuerVisibility: "",
      qualificationNameVisibility: "",
    },
    validationSchema: Yup.object({
      documentNumberVisibility:
        document?.documentNumberVisibility > 1 &&
        Yup.string().required("Required."),
      documentTitleVisibility:
        document?.documentTitleVisibility > 1 &&
        Yup.string().required("Required."),
      expirationDateVisibility:
        document?.expirationDateVisibility > 1 &&
        Yup.string().required("Required."),
      issueDateVisibility:
        document?.issueDateVisibility > 1 && Yup.string().required("Required."),
      issuerVisibility:
        document?.issuerVisibility > 1 && Yup.string().required("Required."),
      qualificationNameVisibility:
        document?.qualificationNameVisibility > 1 &&
        Yup.string().required("Required."),
    }),
    onSubmit: async (values) => {
      try {
        const base64 = await convertToBase64(details.file);

        if (base64) {
          let base64result = base64.split(",")[1];

          addDoc({
            variables: {
              file: base64result,
              fileName: details?.file?.name,
              documentTypeId: details?.type?.id,
              documentNumber:
                values.documentNumberVisibility === ""
                  ? null
                  : values.documentNumberVisibility,
              qualificationName:
                values.qualificationNameVisibility === ""
                  ? null
                  : values.qualificationNameVisibility,
              documentTitle:
                values.documentTitleVisibility === ""
                  ? null
                  : values.documentTitleVisibility,
              issuer:
                values.issuerVisibility === "" ? null : values.issuerVisibility,
              issueDate:
                values.issueDateVisibility === ""
                  ? null
                  : values.issueDateVisibility,
              expirationDate:
                values.expirationDateVisibility === ""
                  ? null
                  : values.expirationDateVisibility,
            },
          }).then((res) => {
            const errors = res?.data?.addWorkerUpload?.errors;

            formik.handleReset();
            onHide();
            resetInputs();

            if (errors.length > 0) {
              toast.error(
                "We can’t accept that file type. Please try uploading one of the following file types: JPEG, PNG, etc"
              );

              return;
            }

            toast.success("Document Uploaded!");

            refetch();
          });
        }
      } catch (err) {
        onHide();
        resetInputs();
        toast.error("There was an error!");
      }
    },
  });

  return (
    <>
      <Modal show={show} onHide={onHide} centered size="lg">
        <Modal.Header>
          <Modal.Title>Document Requires Extra Details</Modal.Title>
          <button
            className="btn btn-cyan"
            onClick={() => {
              formik.handleReset();
              setLoading(false);
              onHide();
              resetInputs();
            }}
          >
            <CloseModal />
          </button>
        </Modal.Header>
        <ModalBody>
          <form onSubmit={formik.handleSubmit}>
            <FormGrid>
              {/* Row 1 ****************************************************/}
              {document?.issuerVisibility > 0 && (
                <div>
                  <FormLabel>
                    Issuer{" "}
                    {document?.issuerVisibility === 2 && <Require>*</Require>}
                  </FormLabel>
                  <FormInput
                    border={
                      formik.touched.issuerVisibility &&
                      formik.errors.issuerVisibility
                        ? "red"
                        : ""
                    }
                    required="required"
                    name="issuerVisibility"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.issuerVisibility}
                    placeholder="Enter issuer here..."
                  />
                  <ErrorMessage>
                    {formik.touched.issuerVisibility &&
                    formik.errors.issuerVisibility ? (
                      <div>{formik.errors.issuerVisibility}</div>
                    ) : undefined}
                  </ErrorMessage>
                </div>
              )}

              {/* Row 2 ****************************************************/}
              {document?.documentTitleVisibility > 0 && (
                <div>
                  <FormLabel>
                    Title{" "}
                    {document?.documentTitleVisibility === 2 && (
                      <Require>*</Require>
                    )}
                  </FormLabel>
                  <FormInput
                    border={
                      formik.touched.documentTitleVisibility &&
                      formik.errors.documentTitleVisibility
                        ? "red"
                        : ""
                    }
                    name="documentTitleVisibility"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.documentTitleVisibility}
                    data-testid="test__signup__niNumber"
                    placeholder="Enter title here..."
                  />
                  <ErrorMessage>
                    {formik.touched.documentTitleVisibility &&
                    formik.errors.documentTitleVisibility ? (
                      <div>{formik.errors.documentTitleVisibility}</div>
                    ) : undefined}
                  </ErrorMessage>
                </div>
              )}

              {/* Row 3 ****************************************************/}
              {document?.documentNumberVisibility > 0 && (
                <div>
                  <FormLabel>
                    Number{" "}
                    {document?.documentNumberVisibility === 2 && (
                      <Require>*</Require>
                    )}
                  </FormLabel>
                  <FormInput
                    border={
                      formik.touched.documentNumberVisibility &&
                      formik.errors.documentNumberVisibility
                        ? "red"
                        : ""
                    }
                    required={true}
                    name="documentNumberVisibility"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.documentNumberVisibility}
                    data-testid="doc_number"
                    placeholder="Enter number here..."
                  />
                  <ErrorMessage>
                    {formik.touched.documentNumberVisibility &&
                    formik.errors.documentNumberVisibility ? (
                      <div>{formik.errors.documentNumberVisibility}</div>
                    ) : undefined}
                  </ErrorMessage>
                </div>
              )}

              {/* Row 4 ****************************************************/}
              {document?.issueDateVisibility > 0 && (
                <div>
                  <FormLabel>
                    Date Obtained{" "}
                    {document?.issueDateVisibility === 2 && (
                      <Require>*</Require>
                    )}
                  </FormLabel>
                  <FormInput
                    border={
                      formik.touched.issueDateVisibility &&
                      formik.errors.issueDateVisibility
                        ? "red"
                        : ""
                    }
                    name="issueDateVisibility"
                    type="date"
                    onChange={formik.handleChange}
                    value={formik.values.issueDateVisibility}
                    data-testid="doc_obtained_date"
                    placeholder="Referee Name"
                  />
                  <ErrorMessage>
                    {formik.touched.issueDateVisibility &&
                    formik.errors.issueDateVisibility ? (
                      <div>{formik.errors.issueDateVisibility}</div>
                    ) : undefined}
                  </ErrorMessage>
                </div>
              )}

              {/* Row 5 ****************************************************/}
              {document?.expirationDateVisibility > 0 && (
                <div>
                  <FormLabel>
                    Expiry Date
                    {document?.expirationDateVisibility === 2 && (
                      <Require>*</Require>
                    )}
                  </FormLabel>
                  <FormInput
                    border={
                      formik.touched.expirationDateVisibility &&
                      formik.errors.expirationDateVisibility
                        ? "red"
                        : ""
                    }
                    name="expirationDateVisibility"
                    type="date"
                    onChange={formik.handleChange}
                    value={formik.values.expirationDateVisibility}
                    data-testid="test__signup__niNumber"
                    placeholder="Referee Name"
                  />
                  <ErrorMessage>
                    {formik.touched.expirationDateVisibility &&
                    formik.errors.expirationDateVisibility ? (
                      <div>{formik.errors.expirationDateVisibility}</div>
                    ) : undefined}
                  </ErrorMessage>
                </div>
              )}

              {/* Row 6 ****************************************************/}
              {document?.qualificationNameVisibility > 0 && (
                <div>
                  <FormLabel>
                    Name of Qualification{" "}
                    {document?.qualificationNameVisibility === 2 && (
                      <Require>*</Require>
                    )}
                  </FormLabel>
                  <FormInput
                    border={
                      formik.touched.qualificationNameVisibility &&
                      formik.errors.qualificationNameVisibility
                        ? "red"
                        : ""
                    }
                    name="qualificationNameVisibility"
                    type="input"
                    onChange={formik.handleChange}
                    value={formik.values.qualificationNameVisibility}
                    data-testid="qualication_name"
                    placeholder="Enter name here..."
                  />
                  <ErrorMessage>
                    {formik.touched.qualificationNameVisibility &&
                    formik.errors.qualificationNameVisibility ? (
                      <div>{formik.errors.qualificationNameVisibility}</div>
                    ) : undefined}
                  </ErrorMessage>
                </div>
              )}
            </FormGrid>
          </form>

          <Divider top="40" bottom="32" line="solid" />

          <ModalFooter>
            {loading && (
              <Spinner
                style={{ position: "absolute", left: "50px" }}
                animation="border"
              />
            )}

            <StyledButton
              color="white"
              size="small"
              length="90px"
              onClick={() => {
                formik.handleReset();
                setLoading(false);
                onHide();
                resetInputs();
              }}
              className="modal__footer_cancel"
            >
              Cancel
            </StyledButton>

            <StyledButton
              onClick={() => formik.handleSubmit()}
              size="small"
              color="blue"
              length="148px"
              type="button"
            >
              {document && "Add Document"}
            </StyledButton>
          </ModalFooter>
        </ModalBody>
      </Modal>
    </>
  );
};

export default DocumentsModal;

const ModalBody = styled.div`
  padding: 25px 32px;

  label {
    color: rgb(125, 135, 147);
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-right: 8px;
  }

  @media (max-width: 468px) {
    flex-direction: column;
    text-align: center;
    button {
      font-size: 12px;
      margin-bottom: 10px;
      width: 100%;
    }
  }
`;

const ErrorMessage = styled.span`
  color: #ff6c6c;
  display: block;
  font-size: 12px;
  margin-top: 10px;
`;
