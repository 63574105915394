import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { RosteringLeaveRequestStatusEnum } from "baseCODEGEN";

import {
  RosteringLeaveRequestQuery,
  useRosteringLeaveRequestCancelMutation,
  useRosteringLeaveRequestQuery,
} from "components/LeaveManagement/__generated__/LeaveManagement.generated";
import { LeaveRecordModalContent } from "components/LeaveManagement/LeaveRecordModal/components/LeaveRecordModalContent";
import { AppContext } from "context/AppContext";
import { RosteringOrganisationRegistrationsType } from "context/AppContext.types";
import { formatError } from "helpers/formatError";

import { CancelLeaveRequestModalContent } from "./components/CancelLeaveRequestModalContent";

interface Props {
  onHide: () => void;
  leaveRecordId: string | null;
  chosenOrg: RosteringOrganisationRegistrationsType[number] | undefined | null;
}

export const LeaveRecordModal = ({
  onHide,
  leaveRecordId,
  chosenOrg,
}: Props) => {
  const { productType } = useContext(AppContext);

  const [showCancelModal, setShowCancelModal] = useState(false);

  const { data: leaveRecord, loading: leaveRecordLoading } =
    useRosteringLeaveRequestQuery(
      chosenOrg?.organisation && leaveRecordId
        ? {
            variables: {
              rosteringLeaveRequestId: +leaveRecordId,
              organisationId: chosenOrg?.organisation?.value,
              rosteringOrganisationRegistrationId: +chosenOrg?.id,
            },
            skip: productType === 0,
          }
        : { skip: true }
    );

  const [cancelLeaveRequest] = useRosteringLeaveRequestCancelMutation();

  const handleCancelLeaveRequest = async (
    leaveRequest: RosteringLeaveRequestQuery["rosteringLeaveRequest"],
    reasonForCancellation: string
  ) => {
    if (
      (leaveRequest?.status !== "pending" &&
        leaveRequest?.status !== "approved") ||
      !leaveRequest?.id
    ) {
      return;
    }

    if (chosenOrg?.organisation)
      try {
        const { data } = await cancelLeaveRequest({
          variables: {
            id: +leaveRequest.id,
            organisationId: chosenOrg?.organisation?.value,
            reasonForCancellation: reasonForCancellation,
          },
        });

        if (data?.rosteringLeaveRequestCancel?.errors.length) {
          toast.error(formatError(data?.rosteringLeaveRequestCancel.errors));
          return;
        }

        toast.success(
          leaveRequest.status === RosteringLeaveRequestStatusEnum.Approved
            ? "Requested leave request cancellation"
            : "Leave request cancelled"
        );
        onHide();
      } catch (e) {
        toast.error("There was an error!");
      }
  };

  return (
    <>
      <Modal show onHide={onHide} centered size="lg" className="leave__modal">
        {showCancelModal ? (
          <CancelLeaveRequestModalContent
            onBack={() => setShowCancelModal(false)}
            onHide={onHide}
            handleRecordSubmit={async (reasonForCancellation) => {
              await handleCancelLeaveRequest(
                leaveRecord?.rosteringLeaveRequest,
                reasonForCancellation
              );
              setShowCancelModal(false);
            }}
            isApproved={
              leaveRecord?.rosteringLeaveRequest?.status ===
              RosteringLeaveRequestStatusEnum.Approved
            }
          />
        ) : (
          <LeaveRecordModalContent
            onHide={onHide}
            leaveRecordLoading={leaveRecordLoading}
            leaveRecord={leaveRecord}
            setShowCancelModal={setShowCancelModal}
          />
        )}
      </Modal>
    </>
  );
};
