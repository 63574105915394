import * as Types from '../../baseCODEGEN';

import { gql } from '@apollo/client';
export type WorkerShiftMinimalFragmentFragment = { __typename?: 'WorkerShift', id?: number | null, bookingAction: Types.BookingAction, startTime: any, endTime: any, status: Types.ShiftStatus, withSecondaryBank: boolean, actualCoreRate?: number | null, actualCoreHours: number, actualUnsocialHours: number, actualUnsocialRate?: number | null, actualTotalPay?: number | null, shiftBlockId?: string | null, breakMinutes: number, shiftType?: Types.ShiftTypeEnum | null, shouldEnforceBreaks?: boolean | null, gradeBreakMinutes?: number | null, gradeBreakRepeatMinutes?: number | null, agencyRegistration?: { __typename?: 'AgencyRegistration', id: string, agency?: { __typename?: 'Agency', id: string } | null } | null, organisation: { __typename?: 'Organisation', id: number, name?: string | null }, department: { __typename?: 'Department', id: number, name?: string | null }, grade: { __typename?: 'Grade', id: string, title: string, onCallRate: number, callOutRate: number }, site: { __typename?: 'Site', id: number, name?: string | null }, workerSignOff?: { __typename?: 'ShiftWorkerSignOff', id: number, reportedStartTime?: string | null, reportedEndTime?: string | null, confirmedStartTime?: string | null, confirmedEndTime?: string | null } | null };

export const WorkerShiftMinimalFragmentFragmentDoc = gql`
    fragment WorkerShiftMinimalFragment on WorkerShift {
  id
  bookingAction
  startTime
  endTime
  status
  withSecondaryBank
  actualCoreRate
  actualCoreHours
  actualUnsocialHours
  actualUnsocialRate
  actualTotalPay
  agencyRegistration {
    id
    agency {
      id
    }
  }
  shiftBlockId
  breakMinutes
  shiftType
  organisation {
    id
    name
  }
  department {
    id
    name
  }
  grade {
    id
    title
    onCallRate
    callOutRate
  }
  site {
    id
    name
  }
  workerSignOff {
    id
    reportedStartTime
    reportedEndTime
    confirmedStartTime
    confirmedEndTime
  }
  shouldEnforceBreaks
  gradeBreakMinutes
  gradeBreakRepeatMinutes
}
    `;