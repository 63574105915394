import { GraphQLErrorExtensions } from "graphql/error";
import styled from "styled-components";

import { RED } from "@patchworkhealth/web-components";

interface Props {
  leaveManagementSuggestion: GraphQLErrorExtensions;
}
const LeaveSuggestion = ({ leaveManagementSuggestion }: Props) => {
  const castedExtensions = {
    title: leaveManagementSuggestion.title as string[],
    body: leaveManagementSuggestion.body as string[],
    footer: leaveManagementSuggestion.footer as string[],
  };

  return (
    <InfoBox>
      {castedExtensions.title.map((text, index) => {
        return <div key={`lm-title-${index}`}>{text}</div>;
      })}
      <DateItems>
        {castedExtensions.body.map((text, index) => {
          return <li key={`lm-body-${index}`}>{text}</li>;
        })}
      </DateItems>
      {castedExtensions.footer.map((text, index) => {
        return <div key={`lm-footer-${index}`}>{text}</div>;
      })}
    </InfoBox>
  );
};

export default LeaveSuggestion;

const InfoBox = styled.div`
  color: ${RED.four};
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 24px;
`;

const DateItems = styled.ul`
  margin-top: 1rem;
  padding-left: 20px;
`;
