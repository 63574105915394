import { PropsWithChildren } from "react";
import { Helmet } from "react-helmet-async";

import { AnimateOnLoad } from "helpers/functions";

interface Props {
  title: string;
}

const LayoutAuth = ({ children, title }: PropsWithChildren<Props>) => {
  return (
    <AnimateOnLoad>
      <Helmet>
        <title>{title ? title + " - " : ""} Patchwork</title>
      </Helmet>

      {children}
    </AnimateOnLoad>
  );
};

export default LayoutAuth;
