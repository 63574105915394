import * as Types from '../../../baseCODEGEN';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from 'msw'
const defaultOptions = {} as const;
export type RotaShiftFragmentFragment = { __typename?: 'RosteringEvent', id: string, eventType: Types.RosteringEventTypeEnum, startsAt: any, endsAt: any, scheduleable?: { __typename?: 'RosteringShift', id: string, workerNote?: string | null, sites?: Array<{ __typename?: 'Site', id: number, name?: string | null }> | null, eventWithPublicUserInfo?: { __typename?: 'RosteringEventWithPublicUserInfo', phoneNumber?: string | null, id: number } | null, organisationActivity?: { __typename?: 'RosteringOrganisationActivity', id: number, name: string, metaActivity: { __typename?: 'MetaActivity', id: number, name: string } } | null, shiftType?: { __typename?: 'RosteringShiftType', id: string, name: string } | null } | null };

export type LeaveRequestFragmentFragment = { __typename?: 'LeaveRequest', id: string, status?: string | null, startDate?: any | null, endDate?: any | null, rosteringLeaveType?: { __typename?: 'LeaveType', leaveType: string, id: string } | null };

export type CalendarShiftsSummaryQueryVariables = Types.Exact<{
  fromStartTime?: Types.InputMaybe<Types.Scalars['Date']>;
  toStartTime?: Types.InputMaybe<Types.Scalars['Date']>;
  organisationIds?: Types.InputMaybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
}>;


export type CalendarShiftsSummaryQuery = { __typename?: 'Query', workersShifts: Array<{ __typename?: 'WorkerShift', startTime: any, status: Types.ShiftStatus }> };

export type RosteringEventsForCalendarQueryVariables = Types.Exact<{
  startsAtFrom?: Types.InputMaybe<Types.Scalars['Date']>;
  startsAtUntil?: Types.InputMaybe<Types.Scalars['Date']>;
  organisationActivityIds?: Types.InputMaybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
  eventTypes?: Types.InputMaybe<Array<Types.RosteringEventTypeEnum> | Types.RosteringEventTypeEnum>;
}>;


export type RosteringEventsForCalendarQuery = { __typename?: 'Query', rosteringEvents?: { __typename?: 'RosteringEventConnection', nodes: Array<{ __typename?: 'RosteringEvent', id: string, eventType: Types.RosteringEventTypeEnum, startsAt: any, endsAt: any, scheduleable?: { __typename?: 'RosteringShift', id: string, workerNote?: string | null, sites?: Array<{ __typename?: 'Site', id: number, name?: string | null }> | null, eventWithPublicUserInfo?: { __typename?: 'RosteringEventWithPublicUserInfo', phoneNumber?: string | null, id: number } | null, organisationActivity?: { __typename?: 'RosteringOrganisationActivity', id: number, name: string, metaActivity: { __typename?: 'MetaActivity', id: number, name: string } } | null, shiftType?: { __typename?: 'RosteringShiftType', id: string, name: string } | null } | null }> } | null };

export type RosteringLeaveRequestsForCalendarQueryVariables = Types.Exact<{
  organisationId: Types.Scalars['Int'];
  rosteringOrganisationRegistrationId: Types.Scalars['Int'];
  statuses?: Types.InputMaybe<Array<Types.RosteringLeaveRequestStatusEnum> | Types.RosteringLeaveRequestStatusEnum>;
  startDateUntil?: Types.InputMaybe<Types.Scalars['Date']>;
  endDateFrom?: Types.InputMaybe<Types.Scalars['Date']>;
}>;


export type RosteringLeaveRequestsForCalendarQuery = { __typename?: 'Query', rosteringLeaveRequests?: Array<{ __typename?: 'LeaveRequest', id: string, status?: string | null, startDate?: any | null, endDate?: any | null, rosteringLeaveType?: { __typename?: 'LeaveType', leaveType: string, id: string } | null }> | null };

export const RotaShiftFragmentFragmentDoc = gql`
    fragment RotaShiftFragment on RosteringEvent {
  id
  eventType
  startsAt
  endsAt
  scheduleable {
    ... on RosteringShift {
      id
      sites {
        id
        name
      }
      workerNote
      eventWithPublicUserInfo {
        phoneNumber
        id
      }
      organisationActivity {
        id
        name
        metaActivity {
          id
          name
        }
      }
      shiftType {
        id
        name
      }
    }
  }
}
    `;
export const LeaveRequestFragmentFragmentDoc = gql`
    fragment LeaveRequestFragment on LeaveRequest {
  id
  status
  startDate
  endDate
  rosteringLeaveType {
    leaveType
    id
  }
}
    `;
export const CalendarShiftsSummaryDocument = gql`
    query CalendarShiftsSummary($fromStartTime: Date, $toStartTime: Date, $organisationIds: [Int!]) {
  workersShifts(
    fromStartTime: $fromStartTime
    toStartTime: $toStartTime
    organisationIds: $organisationIds
  ) {
    startTime
    status
  }
}
    `;

/**
 * __useCalendarShiftsSummaryQuery__
 *
 * To run a query within a React component, call `useCalendarShiftsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarShiftsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarShiftsSummaryQuery({
 *   variables: {
 *      fromStartTime: // value for 'fromStartTime'
 *      toStartTime: // value for 'toStartTime'
 *      organisationIds: // value for 'organisationIds'
 *   },
 * });
 */
export function useCalendarShiftsSummaryQuery(baseOptions?: Apollo.QueryHookOptions<CalendarShiftsSummaryQuery, CalendarShiftsSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarShiftsSummaryQuery, CalendarShiftsSummaryQueryVariables>(CalendarShiftsSummaryDocument, options);
      }
export function useCalendarShiftsSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarShiftsSummaryQuery, CalendarShiftsSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarShiftsSummaryQuery, CalendarShiftsSummaryQueryVariables>(CalendarShiftsSummaryDocument, options);
        }
export type CalendarShiftsSummaryQueryHookResult = ReturnType<typeof useCalendarShiftsSummaryQuery>;
export type CalendarShiftsSummaryLazyQueryHookResult = ReturnType<typeof useCalendarShiftsSummaryLazyQuery>;
export type CalendarShiftsSummaryQueryResult = Apollo.QueryResult<CalendarShiftsSummaryQuery, CalendarShiftsSummaryQueryVariables>;
export const RosteringEventsForCalendarDocument = gql`
    query RosteringEventsForCalendar($startsAtFrom: Date, $startsAtUntil: Date, $organisationActivityIds: [Int!], $eventTypes: [RosteringEventTypeEnum!]) {
  rosteringEvents(
    startsAtFrom: $startsAtFrom
    startsAtUntil: $startsAtUntil
    organisationActivityIds: $organisationActivityIds
    eventTypes: $eventTypes
  ) {
    nodes {
      ...RotaShiftFragment
    }
  }
}
    ${RotaShiftFragmentFragmentDoc}`;

/**
 * __useRosteringEventsForCalendarQuery__
 *
 * To run a query within a React component, call `useRosteringEventsForCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useRosteringEventsForCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRosteringEventsForCalendarQuery({
 *   variables: {
 *      startsAtFrom: // value for 'startsAtFrom'
 *      startsAtUntil: // value for 'startsAtUntil'
 *      organisationActivityIds: // value for 'organisationActivityIds'
 *      eventTypes: // value for 'eventTypes'
 *   },
 * });
 */
export function useRosteringEventsForCalendarQuery(baseOptions?: Apollo.QueryHookOptions<RosteringEventsForCalendarQuery, RosteringEventsForCalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RosteringEventsForCalendarQuery, RosteringEventsForCalendarQueryVariables>(RosteringEventsForCalendarDocument, options);
      }
export function useRosteringEventsForCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RosteringEventsForCalendarQuery, RosteringEventsForCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RosteringEventsForCalendarQuery, RosteringEventsForCalendarQueryVariables>(RosteringEventsForCalendarDocument, options);
        }
export type RosteringEventsForCalendarQueryHookResult = ReturnType<typeof useRosteringEventsForCalendarQuery>;
export type RosteringEventsForCalendarLazyQueryHookResult = ReturnType<typeof useRosteringEventsForCalendarLazyQuery>;
export type RosteringEventsForCalendarQueryResult = Apollo.QueryResult<RosteringEventsForCalendarQuery, RosteringEventsForCalendarQueryVariables>;
export const RosteringLeaveRequestsForCalendarDocument = gql`
    query RosteringLeaveRequestsForCalendar($organisationId: Int!, $rosteringOrganisationRegistrationId: Int!, $statuses: [RosteringLeaveRequestStatusEnum!], $startDateUntil: Date, $endDateFrom: Date) {
  rosteringLeaveRequests(
    organisationId: $organisationId
    rosteringOrganisationRegistrationId: $rosteringOrganisationRegistrationId
    statuses: $statuses
    startDateUntil: $startDateUntil
    endDateFrom: $endDateFrom
  ) {
    ...LeaveRequestFragment
  }
}
    ${LeaveRequestFragmentFragmentDoc}`;

/**
 * __useRosteringLeaveRequestsForCalendarQuery__
 *
 * To run a query within a React component, call `useRosteringLeaveRequestsForCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useRosteringLeaveRequestsForCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRosteringLeaveRequestsForCalendarQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      rosteringOrganisationRegistrationId: // value for 'rosteringOrganisationRegistrationId'
 *      statuses: // value for 'statuses'
 *      startDateUntil: // value for 'startDateUntil'
 *      endDateFrom: // value for 'endDateFrom'
 *   },
 * });
 */
export function useRosteringLeaveRequestsForCalendarQuery(baseOptions: Apollo.QueryHookOptions<RosteringLeaveRequestsForCalendarQuery, RosteringLeaveRequestsForCalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RosteringLeaveRequestsForCalendarQuery, RosteringLeaveRequestsForCalendarQueryVariables>(RosteringLeaveRequestsForCalendarDocument, options);
      }
export function useRosteringLeaveRequestsForCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RosteringLeaveRequestsForCalendarQuery, RosteringLeaveRequestsForCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RosteringLeaveRequestsForCalendarQuery, RosteringLeaveRequestsForCalendarQueryVariables>(RosteringLeaveRequestsForCalendarDocument, options);
        }
export type RosteringLeaveRequestsForCalendarQueryHookResult = ReturnType<typeof useRosteringLeaveRequestsForCalendarQuery>;
export type RosteringLeaveRequestsForCalendarLazyQueryHookResult = ReturnType<typeof useRosteringLeaveRequestsForCalendarLazyQuery>;
export type RosteringLeaveRequestsForCalendarQueryResult = Apollo.QueryResult<RosteringLeaveRequestsForCalendarQuery, RosteringLeaveRequestsForCalendarQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCalendarShiftsSummaryQuery((req, res, ctx) => {
 *   const { fromStartTime, toStartTime, organisationIds } = req.variables;
 *   return res(
 *     ctx.data({ workersShifts })
 *   )
 * })
 */
export const mockCalendarShiftsSummaryQuery = (resolver: ResponseResolver<GraphQLRequest<CalendarShiftsSummaryQueryVariables>, GraphQLContext<CalendarShiftsSummaryQuery>, any>) =>
  graphql.query<CalendarShiftsSummaryQuery, CalendarShiftsSummaryQueryVariables>(
    'CalendarShiftsSummary',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRosteringEventsForCalendarQuery((req, res, ctx) => {
 *   const { startsAtFrom, startsAtUntil, organisationActivityIds, eventTypes } = req.variables;
 *   return res(
 *     ctx.data({ rosteringEvents })
 *   )
 * })
 */
export const mockRosteringEventsForCalendarQuery = (resolver: ResponseResolver<GraphQLRequest<RosteringEventsForCalendarQueryVariables>, GraphQLContext<RosteringEventsForCalendarQuery>, any>) =>
  graphql.query<RosteringEventsForCalendarQuery, RosteringEventsForCalendarQueryVariables>(
    'RosteringEventsForCalendar',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRosteringLeaveRequestsForCalendarQuery((req, res, ctx) => {
 *   const { organisationId, rosteringOrganisationRegistrationId, statuses, startDateUntil, endDateFrom } = req.variables;
 *   return res(
 *     ctx.data({ rosteringLeaveRequests })
 *   )
 * })
 */
export const mockRosteringLeaveRequestsForCalendarQuery = (resolver: ResponseResolver<GraphQLRequest<RosteringLeaveRequestsForCalendarQueryVariables>, GraphQLContext<RosteringLeaveRequestsForCalendarQuery>, any>) =>
  graphql.query<RosteringLeaveRequestsForCalendarQuery, RosteringLeaveRequestsForCalendarQueryVariables>(
    'RosteringLeaveRequestsForCalendar',
    resolver
  )
