import { useEffect, useState } from "react";
import styled from "styled-components";

import { RBCYAN } from "@patchworkhealth/web-components";

import { SingleRosterType } from "components/TeamRota/hooks/useWorkerRoster";
import {
  isLeftEdgeDate,
  isRightEdgeDate,
} from "components/TeamRota/TeamRota.helpers";
import { CloseIcon } from "icons/calendarIcons";

interface Props {
  roster: SingleRosterType | undefined;
  weekStartDate: Date;
}
export const EdgeOfRosterMessage = ({ roster, weekStartDate }: Props) => {
  const [edge, setEdge] = useState<"end" | "beginning" | null>(
    isInEdgeOfRoster(roster, weekStartDate)
  );

  useEffect(() => {
    setEdge(isInEdgeOfRoster(roster, weekStartDate));
  }, [weekStartDate, roster]);

  if (!edge) return null;

  return (
    <Container>
      <p>
        You have reached the {edge} of this rota. Select another rota to view
        different dates.
      </p>
      <button onClick={() => setEdge(null)}>
        <CloseIcon />
      </button>
    </Container>
  );
};

const Container = styled.div`
  align-items: center;
  background: ${RBCYAN.one};
  border: 1px solid ${RBCYAN.five};
  border-radius: 4px;
  display: flex;
  height: 48px;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 20px;
  width: 100%;
`;

const isInEdgeOfRoster = (
  roster: SingleRosterType | undefined,
  weekStartDate: Date
) => {
  if (!isLeftEdgeDate(weekStartDate, roster?.rota.startDate))
    return "beginning";
  if (!isRightEdgeDate(weekStartDate, roster?.rota.endDate)) return "end";

  return null;
};
