import { FormGrid, FormGroup } from "@patchworkhealth/web-components";

import { ShiftDetailsBox } from "components/ExceptionReports/components/ShiftDetailsBox/ShiftDetailsBox";
import {
  formatCompensationForModal,
  formatYesNoEnumForModal,
} from "components/ExceptionReports/ExceptionReportForms/ManualExceptionReportForm/ManualExceptionReportForm.helpers";
import { ExceptionReportType } from "components/ExceptionReports/ExceptionReports.types";

import * as Styled from "./ExceptionReportDetails.styled";
interface Props {
  rosteringExceptionReport: ExceptionReportType | undefined;
}

export const ExceptionReportDetails = ({ rosteringExceptionReport }: Props) => {
  const filteredCategories =
    rosteringExceptionReport?.rosteringExceptionReportReasons?.map(
      (report) => report.rosteringExceptionReportReasonCategory.name
    );

  return (
    <Styled.ExceptionReportDetailsContainer>
      <div>
        <ShiftDetailsBox
          rosteringExceptionReport={rosteringExceptionReport}
          topHeader={rosteringExceptionReport?.rosteringEvent === null}
          createFlow={false}
        />
        <Styled.TimeBlueContainer>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div>
              <Styled.HeaderLabel>Actual start time</Styled.HeaderLabel>
              <Styled.ItemLabel>
                {rosteringExceptionReport?.shiftActualStartTime?.slice(0, 5) ||
                  "-"}
              </Styled.ItemLabel>
            </div>

            <div>
              <Styled.HeaderLabel>Original break</Styled.HeaderLabel>
              <Styled.ItemLabel>
                {rosteringExceptionReport?.breakScheduledTime?.slice(0, 5) ||
                  "-"}
              </Styled.ItemLabel>
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div>
              <Styled.HeaderLabel>Actual end time</Styled.HeaderLabel>
              <Styled.ItemLabel>
                {rosteringExceptionReport?.shiftActualEndTime?.slice(0, 5) ||
                  "-"}
              </Styled.ItemLabel>
            </div>

            <div>
              <Styled.HeaderLabel>Actual break</Styled.HeaderLabel>
              <Styled.ItemLabel>
                {rosteringExceptionReport?.breakActualTime?.slice(0, 5) || "-"}
              </Styled.ItemLabel>
            </div>
          </div>
        </Styled.TimeBlueContainer>
      </div>

      <FormGroup style={{ padding: "5px 5px 5px 20px" }}>
        <FormGrid columns={2} style={{ gridRowGap: "26px" }}>
          <FormGroup gridColumn="1/span 2">
            <Styled.HeaderLabel>Reason Category</Styled.HeaderLabel>
            <Styled.ItemLabel>
              {!filteredCategories?.length ? "-" : filteredCategories?.[0]}
            </Styled.ItemLabel>
          </FormGroup>

          <FormGroup gridColumn="1/span 2" style={{ height: "auto" }}>
            <Styled.HeaderLabel>Reason Type</Styled.HeaderLabel>
            <Styled.ItemLabel>
              {rosteringExceptionReport?.rosteringExceptionReportReasons
                ?.map((reason) => reason.name)
                .join(", ")}
            </Styled.ItemLabel>
          </FormGroup>

          <FormGroup gridColumn="1">
            <Styled.HeaderLabel>Compensation</Styled.HeaderLabel>
            <Styled.ItemLabel>
              {rosteringExceptionReport?.requestedCompensation === null
                ? "-"
                : formatCompensationForModal(
                    rosteringExceptionReport?.requestedCompensation
                  )}
            </Styled.ItemLabel>
          </FormGroup>

          <FormGroup gridColumn="2">
            <Styled.HeaderLabel>Impacts Patient Safety</Styled.HeaderLabel>
            <Styled.ItemLabel>
              {formatYesNoEnumForModal(
                rosteringExceptionReport?.patientSafetyImpacted
              )}
            </Styled.ItemLabel>
          </FormGroup>

          <FormGroup gridColumn="1/span 2">
            <Styled.HeaderLabel>Reason Details</Styled.HeaderLabel>
            <Styled.ItemLabel>
              {rosteringExceptionReport?.details || "-"}
            </Styled.ItemLabel>
          </FormGroup>

          <FormGroup gridColumn="1/span 2">
            <Styled.HeaderLabel>Senior Clinician</Styled.HeaderLabel>
            <Styled.ItemLabel>
              {rosteringExceptionReport?.mostSeniorClinician || "-"}
            </Styled.ItemLabel>
          </FormGroup>
        </FormGrid>
      </FormGroup>
    </Styled.ExceptionReportDetailsContainer>
  );
};
