import { classNames } from "helpers/newHelpers";

const SelfRosteringBlankCell = () => {
  return (
    <div
      className={classNames(
        "w-[125px] mx-[6px] border-t pt-[6px] relative",
        "min-h-[170px]"
      )}
    />
  );
};

export default SelfRosteringBlankCell;
