import { CurrentWorker } from "context/AppContext.types";

import { StaffGroupEnum } from "../baseCODEGEN";

export const userObject = {
  rosteringOrganisationRegistrations: [{ id: "1", organisation: { value: 1 } }],
  workersSupervisors: [
    {
      supervisorsCategories: [
        {
          tier1: true,
          tier2: false,
          exceptionReportCategory: { name: "Category1", id: "1" },
        },
        {
          tier1: true,
          tier2: true,
          exceptionReportCategory: { name: "Category2", id: "2" },
        },
        {
          tier1: false,
          tier2: true,
          exceptionReportCategory: { name: "Category3", id: "3" },
        },
      ],
      supervisor: {
        id: "supervisor1",
        fullName: "John Doe",
        email: "john.doe@example.com",
        avatar: "",
      },
      worker: {
        workerName: "Worker1",
      },
    },
    {
      supervisorsCategories: [
        {
          tier1: true,
          tier2: true,
          exceptionReportCategory: { name: "Category2", id: "2" },
        },
        {
          tier1: true,
          tier2: false,
          exceptionReportCategory: { name: "Category4", id: "4" },
        },
      ],
      supervisor: {
        id: "supervisor2",
        fullName: "Jane Smith",
        email: "jane.smith@example.com",
      },
      worker: {
        workerName: "Worker1",
      },
    },
    {
      supervisorsCategories: [
        {
          tier1: true,
          tier2: false,
          exceptionReportCategory: { name: "Category2", id: "2" },
        },
      ],
      supervisor: {
        id: "supervisor3",
        fullName: "Jake Smith",
        email: "jake.smith@example.com",
      },
      worker: {
        workerName: "Worker1",
      },
    },
  ],
  grades: [],
  departments: [],
  agencyRegistrations: [{ id: "1", agency: { id: "1" } }],
  profileComplete: true,
  avatarUrl: "/workers/5764/avatar",
  email: "greg.s.pask@gmail.com",
  firstName: "Greg12345",
  lastName: "Pask",
  preferredName: "Greg @theBest",
  dateOfBirth: "1995-08-01",
  profilePictureUrl:
    "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbDBhIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--4baf999f6d6e458d3b1c96e63c4589e4917a7852/39F0F396-9F44-4480-B691-3549DB7765EC.png",
  phoneNumber: "12312312312",
  postcode: "Le16bw",
  niNumber: "GF403210G",
  dbsNumber: "999999999999",
  regBodyNumber: "1234",
  id: "5764",
  addressLine1: "7 Bowring Court, Roby Road",
  addressLine2: "Childwall2",
  city: "Liverpool",
  providedEdiData: false,
  rightToWork: [
    {
      id: 1631,
      __typename: "Document",
    },
  ],
  libraryItems: [
    {
      createdAt: "2021-11-29 23:11:46 +0000",
      fileName: "Screenshot%202021-03-15%20at%2020.49.00.png",
      url: "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbWtjIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--6ea061147ce0ddd95d3a8d3d160c2b11080221a6/Screenshot%202021-03-15%20at%2020.49.00.png",
      __typename: "LibraryItem",
    },
  ],
  workerCollaborativeBanks: [
    {
      id: 468,
      active: true,
      collaborativeBank: {
        id: 15,
        name: "Testing Payroll",
        leadEmployerOrganisation: {
          name: "Test Organisation",
          __typename: "Organisation",
        },
        organisations: [
          {
            id: 7,
            name: "Test Organisation",
            sites: [
              {
                name: "Home",
                id: 10,
                __typename: "Site",
              },
              {
                name: "City",
                id: 11,
                __typename: "Site",
              },
            ],
            __typename: "Organisation",
          },
          {
            id: 28,
            name: "Adj TestOrg",
            sites: [
              {
                name: "test site",
                id: 146,
                __typename: "Site",
              },
            ],
            __typename: "Organisation",
          },
        ],
        __typename: "CollaborativeBank",
      },
      __typename: "WorkerCollaborativeBank",
    },
    {
      id: 509,
      active: true,
      collaborativeBank: {
        id: 1,
        name: "NHS Test Org Collaborative Bank",
        leadEmployerOrganisation: {
          name: "Patchwork NHS Organisation",
          __typename: "Organisation",
        },
        organisations: [
          {
            id: 1,
            name: "NHS Test Org",
            sites: [
              {
                name: "St Marys Hospital1234",
                id: 1,
                __typename: "Site",
              },
              {
                name: "Queen Elizabeth Hospital",
                id: 2,
                __typename: "Site",
              },
              {
                name: "Royal Infirmarys",
                id: 25,
                __typename: "Site",
              },
              {
                name: "Test Org",
                id: 91,
                __typename: "Site",
              },
            ],
            __typename: "Organisation",
          },
          {
            id: 2,
            name: "Patchwork NHS Organisation",
            sites: [
              {
                name: "London Hospital",
                id: 3,
                __typename: "Site",
              },
              {
                name: "Patchwork Hospital",
                id: 26,
                __typename: "Site",
              },
            ],
            __typename: "Organisation",
          },
          {
            id: 24,
            name: "Medics Academy",
            sites: [
              {
                name: "Imperial College Healthcare",
                id: 79,
                __typename: "Site",
              },
              {
                name: "Royal Berkshire NHS Foundation Trust",
                id: 80,
                __typename: "Site",
              },
            ],
            __typename: "Organisation",
          },
          {
            id: 37,
            name: "Josh Trust",
            sites: [],
            __typename: "Organisation",
          },
        ],
        __typename: "CollaborativeBank",
      },
      __typename: "WorkerCollaborativeBank",
    },
    {
      id: 510,
      active: false,
      collaborativeBank: {
        id: 17,
        name: "repro",
        leadEmployerOrganisation: {
          name: "Support Engineer",
          __typename: "Organisation",
        },
        organisations: [
          {
            id: 35,
            name: "Support Engineer",
            sites: [
              {
                name: "Dr Tech Test",
                id: 143,
                __typename: "Site",
              },
              {
                name: "Sunderland Royal",
                id: 144,
                __typename: "Site",
              },
            ],
            __typename: "Organisation",
          },
          {
            id: 52,
            name: "Repro 1",
            sites: [],
            __typename: "Organisation",
          },
        ],
        __typename: "CollaborativeBank",
      },
      __typename: "WorkerCollaborativeBank",
    },
    {
      id: 515,
      active: false,
      collaborativeBank: {
        id: 7,
        name: "testing bank3",
        leadEmployerOrganisation: {
          name: "Imperial College Healthcare NHS Trust",
          __typename: "Organisation",
        },
        organisations: [
          {
            id: 18,
            name: "Well Pharmacy",
            sites: [
              {
                name: "South West England",
                id: 45,
                __typename: "Site",
              },
              {
                name: "London & South East",
                id: 46,
                __typename: "Site",
              },
              {
                name: "East of England",
                id: 47,
                __typename: "Site",
              },
              {
                name: "Wales",
                id: 48,
                __typename: "Site",
              },
              {
                name: "West Midlands",
                id: 49,
                __typename: "Site",
              },
              {
                name: "East Midlands",
                id: 50,
                __typename: "Site",
              },
              {
                name: "Yorkshire and Humber",
                id: 51,
                __typename: "Site",
              },
              {
                name: "North West England",
                id: 52,
                __typename: "Site",
              },
              {
                name: "North East England",
                id: 53,
                __typename: "Site",
              },
              {
                name: "Scotland",
                id: 54,
                __typename: "Site",
              },
            ],
            __typename: "Organisation",
          },
          {
            id: 22,
            name: "Imperial College Healthcare NHS Trust",
            sites: [
              {
                name: "Test",
                id: 93,
                __typename: "Site",
              },
            ],
            __typename: "Organisation",
          },
          {
            id: 42,
            name: "Sharan Test",
            sites: [],
            __typename: "Organisation",
          },
        ],
        __typename: "CollaborativeBank",
      },
      __typename: "WorkerCollaborativeBank",
    },
    {
      id: 656,
      active: false,
      collaborativeBank: {
        id: 6,
        name: "Patchwork Collaborative Bank",
        leadEmployerOrganisation: {
          name: "Push Doctor",
          __typename: "Organisation",
        },
        organisations: [
          {
            id: 8,
            name: "London North West University Healthcare",
            sites: [
              {
                name: "Ealing Hospital",
                id: 13,
                __typename: "Site",
              },
              {
                name: "Northwick Park Hospital",
                id: 14,
                __typename: "Site",
              },
              {
                name: "Central Middlesex Hospital",
                id: 15,
                __typename: "Site",
              },
              {
                name: "Royal University Hospital",
                id: 104,
                __typename: "Site",
              },
            ],
            __typename: "Organisation",
          },
          {
            id: 17,
            name: "Push Doctor",
            sites: [
              {
                name: "Manchester ",
                id: 36,
                __typename: "Site",
              },
            ],
            __typename: "Organisation",
          },
          {
            id: 20,
            name: "Royal University Hospital NHS Trust",
            sites: [
              {
                name: "New Zealand",
                id: 68,
                __typename: "Site",
              },
              {
                name: "Royal University Hospital",
                id: 105,
                __typename: "Site",
              },
            ],
            __typename: "Organisation",
          },
          {
            id: 32,
            name: "Northampton General Hospital",
            sites: [
              {
                name: "Northampton General Hospital",
                id: 106,
                __typename: "Site",
              },
            ],
            __typename: "Organisation",
          },
        ],
        __typename: "CollaborativeBank",
      },
      __typename: "WorkerCollaborativeBank",
    },
  ],
  specialities: [
    {
      id: 20,
      title: "General Practice",
      __typename: "Speciality",
    },
    {
      id: 2,
      title: "General Internal Medicine",
      __typename: "Speciality",
    },
  ],
  referees: [
    {
      contactNumber: "12312313212",
      emailAddress: "greg@greg.com",
      firstName: "greg",
      id: 1677,
      lastName: "pask1",
      mobileNumber: "12312312312",
      organisation: "patchwork",
      position: "123",
      __typename: "Referee",
    },
    {
      contactNumber: "12312313212",
      emailAddress: "greg@greg.com",
      firstName: "greg1",
      id: 1678,
      lastName: "pask1",
      mobileNumber: "12312312312",
      organisation: "patchwork",
      position: "123",
      __typename: "Referee",
    },
  ],
  staffGroup: {
    id: StaffGroupEnum.Medical,
    idValue: 1,
    title: "Medical",
    regulationBody: {
      id: "2",
      name: "General Medical Council",
      __typename: "RegulationBody",
    },
    specialities: [
      {
        id: 24,
        title: "COVID vaccinator1",
        __typename: "Speciality",
      },
      {
        id: 13,
        title: "Dermatology1\t",
        __typename: "Speciality",
      },
      {
        id: 3,
        title: "Emergency Medicine",
        __typename: "Speciality",
      },
    ],
    __typename: "StaffGroup",
  },
  rosteringWorkerProfile: {
    rosteringOrganisationRegistrations: [
      {
        contractType: "Junior Doctor",
        lessThanFullTime: false,
        wholeTimeEquivalent: null,
        outOfHours: null,
        workingArrangement: "",
        active: false,
        enableRostering: true,
        organisation: {
          name: "Test Organisation",
          __typename: "Organisation",
        },
        __typename: "RosteringOrganisationRegistration",
      },
    ],
    __typename: "RosteringWorkerProfile",
  },
  rosteringOrganisations: [
    {
      id: 7,
      name: "Test Organisation",
      __typename: "Organisation",
    },
  ],
  organisationRegistrations: [
    {
      id: 5500,
      approved: false,
      requiresActivation: false,
      approvedAt: null,
      esrNumber: null,
      grade: null,
      organisation: {
        id: 63,
        description: "Some kind of description",
        logoUrl:
          "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa29TIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--fe5a83a021e9abcff41ac2527e995a69be1836f1/spiderman.jpg",
        name: "LP Test Organisation",
        agencyEmail: "lukasz.pajak+lpstaff@patchwork.health",
        tsoPhone: "1233214325",
        leadEmployer: false,
        organisationStaffGroups: [
          {
            staffBankAgreementForm: "Do you agree?",
            staffGroup: {
              id: StaffGroupEnum.Medical,
              __typename: "StaffGroup",
            },
            __typename: "OrganisationStaffGroup",
          },
        ],
        collaborativeBank: {
          id: 31,
          name: "2Test boolean",
          contractText: "sdasdsa checksss",
          usesLeadEmployerProcess: false,
          organisations: [
            {
              id: 48,
              name: "Dan Org Test 3",
              __typename: "Organisation",
            },
            {
              id: 57,
              name: "Belema Test Org updated",
              __typename: "Organisation",
            },
          ],
          __typename: "CollaborativeBank",
        },
        sites: [
          {
            id: 145,
            name: "GLI",
            __typename: "Site",
          },
        ],
        __typename: "Organisation",
      },
      __typename: "OrganisationRegistration",
    },
    {
      id: 5480,
      approved: false,
      requiresActivation: true,
      approvedAt: null,
      esrNumber: null,
      grade: null,
      organisation: {
        id: 17,
        description:
          "Same day appointments with the UK's number one online doctor. Talk face-to-face with an NHS-trained GP on your mobile, tablet or laptop today.",
        logoUrl: "/organisations/17/logo",
        name: "Push Doctor",
        agencyEmail: "hank+pushdoctor@patchwork.health",
        tsoPhone: "2534592420",
        leadEmployer: true,
        organisationStaffGroups: [
          {
            staffBankAgreementForm: "",
            staffGroup: {
              id: StaffGroupEnum.Medical,
              __typename: "StaffGroup",
            },
            __typename: "OrganisationStaffGroup",
          },
        ],
        collaborativeBank: {
          id: 6,
          name: "Patchwork Collaborative Bank",
          contractText: "",
          usesLeadEmployerProcess: true,
          organisations: [
            {
              id: 8,
              name: "London North West University Healthcare",
              __typename: "Organisation",
            },
            {
              id: 17,
              name: "Push Doctor",
              __typename: "Organisation",
            },
          ],
          __typename: "CollaborativeBank",
        },
        sites: [
          {
            id: 36,
            name: "Manchester ",
            __typename: "Site",
          },
        ],
        __typename: "Organisation",
      },
      __typename: "OrganisationRegistration",
    },
    {
      id: 5162,
      approved: false,
      requiresActivation: true,
      approvedAt: null,
      esrNumber: null,
      grade: null,
      organisation: {
        id: 18,
        description: "Well Pharmacy's bank platform (test)",
        logoUrl: "/organisations/18/logo",
        name: "Well Pharmacy",
        agencyEmail: "tania+agency@patchwork.health",
        tsoPhone: "0987657654321",
        leadEmployer: false,
        organisationStaffGroups: [
          {
            staffBankAgreementForm: "Example terms and conditions here:\n",
            staffGroup: {
              id: StaffGroupEnum.Medical,
              __typename: "StaffGroup",
            },
            __typename: "OrganisationStaffGroup",
          },
          {
            staffBankAgreementForm: "",
            staffGroup: {
              id: StaffGroupEnum.Nursing,
              __typename: "StaffGroup",
            },
            __typename: "OrganisationStaffGroup",
          },
        ],
        collaborativeBank: {
          id: 7,
          name: "testing bank3",
          contractText: "Testing Testing Test",
          usesLeadEmployerProcess: true,
          organisations: [
            {
              id: 18,
              name: "Well Pharmacy",
              __typename: "Organisation",
            },
            {
              id: 22,
              name: "Imperial College Healthcare NHS Trust",
              __typename: "Organisation",
            },
          ],
          __typename: "CollaborativeBank",
        },
        sites: [
          {
            id: 45,
            name: "South West England",
            __typename: "Site",
          },
          {
            id: 46,
            name: "London & South East",
            __typename: "Site",
          },
        ],
        __typename: "Organisation",
      },
      __typename: "OrganisationRegistration",
    },
    {
      id: 5157,
      approved: false,
      requiresActivation: true,
      approvedAt: null,
      esrNumber: null,
      grade: null,
      organisation: {
        id: 35,
        description:
          "Added to check proper error messages when logo image validation failed",
        logoUrl:
          "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcGNDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b5194efd36260b66e0ca6337bd7f3b091fbe526c/Group%20510.png",
        name: "Support Engineer",
        agencyEmail: "noreply@patchwork.health",
        tsoPhone: "1234567890",
        leadEmployer: true,
        organisationStaffGroups: [
          {
            staffBankAgreementForm: "n/a",
            staffGroup: {
              id: StaffGroupEnum.Medical,
              __typename: "StaffGroup",
            },
            __typename: "OrganisationStaffGroup",
          },
        ],
        collaborativeBank: {
          id: 17,
          name: "repro",
          contractText: null,
          usesLeadEmployerProcess: true,
          organisations: [
            {
              id: 35,
              name: "Support Engineer",
              __typename: "Organisation",
            },
            {
              id: 52,
              name: "Repro 1",
              __typename: "Organisation",
            },
          ],
          __typename: "CollaborativeBank",
        },
        sites: [
          {
            id: 143,
            name: "Dr Tech Test",
            __typename: "Site",
          },
          {
            id: 144,
            name: "Sunderland Royal",
            __typename: "Site",
          },
        ],
        __typename: "Organisation",
      },
      __typename: "OrganisationRegistration",
    },
    {
      id: 4762,
      approved: true,
      requiresActivation: false,
      approvedAt: "2021-07-30T12:20:00+01:00",
      esrNumber: "123456",
      grade: {
        title: "General Practitioner",
        __typename: "Grade",
      },
      organisation: {
        id: 1,
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, .",
        logoUrl: "/organisations/1/logo",
        name: "NHS Test Org",
        agencyEmail: "omolola.ibojo@patchwork.health",
        tsoPhone: "02033152265",
        leadEmployer: false,
        organisationStaffGroups: [
          {
            staffBankAgreementForm: "",
            staffGroup: {
              id: StaffGroupEnum.Nursing,
              __typename: "StaffGroup",
            },
            __typename: "OrganisationStaffGroup",
          },
          {
            staffBankAgreementForm: "",
            staffGroup: {
              id: StaffGroupEnum.NonClinical,
              __typename: "StaffGroup",
            },
            __typename: "OrganisationStaffGroup",
          },
        ],
        collaborativeBank: {
          id: 1,
          name: "NHS Test Org Collaborative Bank",
          contractText: "hub-3000",
          usesLeadEmployerProcess: true,
          organisations: [
            {
              id: 1,
              name: "NHS Test Org",
              __typename: "Organisation",
            },
            {
              id: 2,
              name: "Patchwork NHS Organisation",
              __typename: "Organisation",
            },
          ],
          __typename: "CollaborativeBank",
        },
        sites: [
          {
            id: 1,
            name: "St Marys Hospital1234",
            __typename: "Site",
          },
          {
            id: 2,
            name: "Queen Elizabeth Hospital",
            __typename: "Site",
          },
        ],
        __typename: "Organisation",
      },
      __typename: "OrganisationRegistration",
    },
    {
      id: 4809,
      approved: true,
      requiresActivation: false,
      approvedAt: "2021-08-10T17:18:51+01:00",
      esrNumber: "123456",
      grade: {
        title: "SHO",
        __typename: "Grade",
      },
      organisation: {
        id: 7,
        description:
          "Write what ever you like in here lad. \r\nFill your shine boots ",
        logoUrl: "/organisations/7/logo",
        name: "Test Organisation",
        agencyEmail: "reuben@patchwork.health",
        tsoPhone: "12345678901",
        leadEmployer: true,
        organisationStaffGroups: [
          {
            staffBankAgreementForm:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at nibh eu arcu scelerisque vehicula in eu tellus. Cras facilisis porta egestas. Phasellus at tempus leo. Nam nisl massa, iaculis quis est ut, auctor tempus lectus. Fusce at hendrerit quam. Suspendisse suscipit tellus non magna vestibulum, et facilisis lectus aliquet. In et orci nisi. Duis varius justo sem. Aliquam tincidunt odio interdum ultricies ultricies. Vestibulum tortor",
            staffGroup: {
              id: StaffGroupEnum.Medical,
              __typename: "StaffGroup",
            },
            __typename: "OrganisationStaffGroup",
          },
          {
            staffBankAgreementForm: "Duncs No Reg Body agreement form",
            staffGroup: {
              id: StaffGroupEnum.Medical,
              __typename: "StaffGroup",
            },
            __typename: "OrganisationStaffGroup",
          },
        ],
        collaborativeBank: {
          id: 15,
          name: "Testing Payroll",
          contractText: "graph",
          usesLeadEmployerProcess: true,
          organisations: [
            {
              id: 7,
              name: "Test Organisation",
              __typename: "Organisation",
            },
            {
              id: 28,
              name: "Adj TestOrg",
              __typename: "Organisation",
            },
          ],
          __typename: "CollaborativeBank",
        },
        sites: [
          {
            id: 10,
            name: "Home",
            __typename: "Site",
          },
          {
            id: 11,
            name: "City",
            __typename: "Site",
          },
          {
            id: 12,
            name: "Village",
            __typename: "Site",
          },
        ],
        __typename: "Organisation",
      },
      __typename: "OrganisationRegistration",
    },
    {
      id: 5156,
      approved: true,
      requiresActivation: false,
      approvedAt: "2021-09-19T23:42:45+01:00",
      esrNumber: "123123",
      grade: {
        title: "FY1",
        __typename: "Grade",
      },
      organisation: {
        id: 2,
        description:
          "Organisations --> Org Description\r\nLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the .",
        logoUrl:
          "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcFFTIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--75fe0f1ffcad8ba4b234dad9a1d614a641d2866e/Process_Overview.png",
        name: "Patchwork NHS Organisation",
        agencyEmail: "tania.ali@patchwork.health",
        tsoPhone: "123456789000",
        leadEmployer: true,
        organisationStaffGroups: [
          {
            staffBankAgreementForm:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
            staffGroup: {
              id: StaffGroupEnum.Medical,
              __typename: "StaffGroup",
            },
            __typename: "OrganisationStaffGroup",
          },
          {
            staffBankAgreementForm: "test",
            staffGroup: {
              id: StaffGroupEnum.Nursing,
              __typename: "StaffGroup",
            },
            __typename: "OrganisationStaffGroup",
          },
        ],
        collaborativeBank: {
          id: 1,
          name: "NHS Test Org Collaborative Bank",
          contractText: "hub-3000",
          usesLeadEmployerProcess: true,
          organisations: [
            {
              id: 1,
              name: "NHS Test Org",
              __typename: "Organisation",
            },
            {
              id: 2,
              name: "Patchwork NHS Organisation",
              __typename: "Organisation",
            },
          ],
          __typename: "CollaborativeBank",
        },
        sites: [
          {
            id: 3,
            name: "London Hospital",
            __typename: "Site",
          },
          {
            id: 26,
            name: "Patchwork Hospital",
            __typename: "Site",
          },
          {
            id: 72,
            name: "PW NHS Healthcare Trust",
            __typename: "Site",
          },
          {
            id: 192,
            name: "RH- 2 Site Test",
            __typename: "Site",
          },
        ],
        __typename: "Organisation",
      },
      __typename: "OrganisationRegistration",
    },
  ],
  __typename: "Worker",
} satisfies CurrentWorker;
