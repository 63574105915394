import { useState } from "react";

import { useSupervisors } from "components/ExceptionReports/components/SupervisorsList/useSupervisors";
import { ChevronIcon } from "components/Navigation/NavIcons";
import { ProfileImage } from "components/Shared/ProfileImage";

import * as Styled from "./SupervisorsList.styled";

interface Props {
  amountOfSupervisorsToDisplay: number;
  supervisorsColumn: number;
  categoryName: string | undefined | null;
  isModal?: boolean;
  isDispute?: boolean;
}

export const SupervisorsList = ({
  supervisorsColumn,
  amountOfSupervisorsToDisplay,
  categoryName,
  isModal = false,
  isDispute = false,
}: Props) => {
  const [viewMore, setViewMore] = useState<boolean>(false);
  const { supervisorsWithCategory } = useSupervisors(
    categoryName,
    isDispute ? "tier2" : "tier1"
  );

  const howManyMoreSupervisors =
    (supervisorsWithCategory?.length ?? amountOfSupervisorsToDisplay) -
    amountOfSupervisorsToDisplay;

  const supervisorsToDisplay = supervisorsWithCategory?.slice(
    0,
    viewMore
      ? amountOfSupervisorsToDisplay + howManyMoreSupervisors
      : amountOfSupervisorsToDisplay
  );

  const onViewMore = () => {
    setViewMore((prevState) => !prevState);
  };

  return (
    <div>
      <Styled.Header>
        {isDispute ? "Next Supervisor In Line" : "Your supervisors"}
      </Styled.Header>
      <div
        style={{
          gap: "10px",
          columnCount: supervisorsColumn,
        }}
      >
        {supervisorsToDisplay?.map(
          ({ fullName, id, email, profilePictureUrl }) => (
            <Styled.SupervisorContainer key={id}>
              <ProfileImage size="small" src={profilePictureUrl} />
              <div style={{ width: "190px" }}>
                <Styled.EllipsisContainer>
                  <Styled.FullName>{fullName}</Styled.FullName>
                </Styled.EllipsisContainer>
                <Styled.EllipsisContainer>
                  <Styled.EmailAddress>{email}</Styled.EmailAddress>
                </Styled.EllipsisContainer>
              </div>
            </Styled.SupervisorContainer>
          )
        )}
      </div>
      {howManyMoreSupervisors > 0 && (
        <Styled.ViewMoreButton viewMore={viewMore} onClick={onViewMore}>
          <p>
            View {howManyMoreSupervisors} {viewMore ? "less" : "more"}
          </p>
          <ChevronIcon />
        </Styled.ViewMoreButton>
      )}
      {supervisorsToDisplay?.length === 1 && !isModal && !isDispute && (
        <Styled.WrongSupervisorContainer>
          <p>
            If this supervisor is incorrect, please contact your roster admin to
            assign the correct person(s)
          </p>
        </Styled.WrongSupervisorContainer>
      )}
    </div>
  );
};
