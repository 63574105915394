import styled from "styled-components";

import { GREY } from "@patchworkhealth/web-components";

export const FollowingDay = styled.span`
  font-weight: 600;
  font-size: 12px;
  color: ${GREY.four};
`;
export const FormBreakLine = styled.div`
  border: 1px solid ${GREY.three};
  margin: 48px 0;
`;

export const ButtonsContainer = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
