import { Fragment, ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { classNames } from "helpers/newHelpers";

import { Button } from "./Button";

export const ModalContainer = ({
  size = "md",
  children,
  openModal,
  handleClose,
}: {
  size?: "sm" | "md" | "lg";
  children: React.ReactNode;
  openModal: boolean;
  handleClose: () => void;
}) => {
  return (
    <Transition appear as={Fragment} show={openModal}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div aria-hidden="true" className="fixed inset-0 bg-black-5/50" />
        </Transition.Child>

        <div className="fixed inset-0">
          <div className="flex items-center justify-center min-h-full p-4 overflow-y-auto">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  size === "sm" ? "max-w-[800px]" : "",
                  size === "md" ? "max-w-3xl" : "",
                  size === "lg" ? "max-w-5xl" : "",
                  "mx-auto min-h-[350px] w-full max-w-3xl rounded-xl bg-white shadow-md overflow-y-auto"
                )}
                style={{
                  maxHeight: "calc(100vh - 1rem)",

                  // This ensures the modal's content is within the viewport height
                }}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

/* Modal (lg) Content ------------------------------------------------------- */

export const ModalContent = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex-1 border rounded-b-xl md:rounded-bl-none">
      {children}
    </div>
  );
};

/* Modal Header ------------------------------------------------------------- */

export const ModalHeader = ({
  children,
  onClose,
}: {
  children: React.ReactNode;
  onClose: () => void;
}) => {
  return (
    <div className="flex h-16 items-center justify-between rounded-tl-xl rounded-tr-xl border-b border-grey-2 bg-[#F5F7FA] px-4 md:h-20 md:px-8">
      <h3 className="text-xl font-semibold text-black-4">{children}</h3>
      <CloseIcon onClose={onClose} />
    </div>
  );
};

export const CloseIcon = ({ onClose }: { onClose: () => void }) => {
  return (
    <button onClick={onClose} type="button">
      <svg
        className="h-6 w-6 text-[#7D8793]"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 18L18 6M6 6l12 12"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
      </svg>
    </button>
  );
};

export const ModalHeaderItem = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex h-[44px] flex-col justify-center border-r border-r-grey-3 px-4">
      {children}
    </div>
  );
};

/* Modal Sidebar (lg) ------------------------------------------------- */

interface ModalSidebarProps {
  setCurrentTab: (id: number) => void;
  currentTab: number;
  tabOptions: { id: number; name: string }[];
}

export const ModalSidebar = ({
  setCurrentTab,
  currentTab,
  tabOptions,
}: ModalSidebarProps) => {
  return (
    <div className="w-full border-r border-r-grey-2 bg-grey-2 p-2 pt-6 text-[13px] md:w-[181px] md:rounded-bl-xl">
      {tabOptions.map((item) => {
        return (
          <div
            key={item.id}
            className={classNames(
              currentTab === item.id ? "bg-grey-3 font-semibold" : "",
              "mb-2 flex h-8 cursor-pointer items-center rounded pl-2 text-black-5 transition"
            )}
            onClick={() => setCurrentTab(item.id)}
          >
            {item.name}
          </div>
        );
      })}
    </div>
  );
};

/* Modal Wrapper class for Tabs --------------------------------------------- */

export const modalTabClasses =
  "mb-[8px] h-[45vh] max-h-[45vh] flex-1 overflow-y-auto p-5 sm:h-[70vh] sm:max-h-[70vh]";

/* Modal Footer ------------------------------------------------------------- */

export const ModalFooter = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex items-center justify-between py-5 mx-4 border-t border-grey-1 md:mx-8">
      {children}
    </div>
  );
};

export const ModalFooterCancel = ({
  closeModal,
}: {
  closeModal: () => void;
}) => {
  return (
    <ModalFooter>
      <div />
      <div className="flex space-x-2">
        <Button onClick={closeModal} type="submit" variant="default">
          Close
        </Button>
      </div>
    </ModalFooter>
  );
};
