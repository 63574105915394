import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import styled from "styled-components";

import {
  DefaultButton,
  FormLabel,
  GREY,
} from "@patchworkhealth/web-components";

import { Divider } from "components/Style";
import ProfilePage from "containers/profile";
import { AppContext } from "context/AppContext";
import { VMS_WORKER_CONFIRM_REGISTRATION } from "gql/Mutations";
import ProfileIcon from "icons/images/profileImageDefault.svg";
import {
  OrgEmailIcon,
  OrgPhoneIcon,
  OrgTickIcon,
} from "icons/organisationIcons";
import { CloseModal } from "icons/referencesIcons";

import "./agencies.css";
import { Text } from "../Style";

const AgencyModal = ({ onHide, show, profile }) => {
  const store = useContext(AppContext);

  let existingAgencies = store.user.agencyRegistrations.filter(
    (a) => a.confirmedAt
  );

  useEffect(() => {
    if (show && profile && profile.confirmedAt) {
      setPage(3);
      return;
    }

    if (show && profile) {
      let duplicate = false;

      existingAgencies.forEach((element) => {
        if (element.agency.title === profile.agency.title) {
          duplicate = true;
        }
      });

      duplicate ? setPage(2) : setPage(0);

      return;
    }

    if (show && !profile) {
      setPage(1);
    }
  }, [show, profile]);

  // State **********************************

  const [page, setPage] = useState(0);
  const [confirmed, setConfirmed] = useState(false);

  // Gql ************************************

  const [vmsConfirm] = useMutation(VMS_WORKER_CONFIRM_REGISTRATION);

  const joinAgency = () => {
    store.setAgencyOnboarding(false);

    vmsConfirm({
      variables: {
        agencyRegistrationId: store?.agencyRegistrationId,
      },
    })
      .then((res) => {
        const errors = res.data.vmsWorkerConfirmRegistration.errors;

        if (errors.length > 0) {
          toast.error(`${errors[0].message}`);
          return;
        }

        toast.success("Joined Agency");
        store.fetchUser();
        setPage(3);
      })
      .catch((err) => {
        toast.error("There was an error!", err);
      });
  };

  // This part will submit fhe form and advance the setp form
  const confirmInfo = () => {
    store.setConfirmAgencyInfo(true);
    setConfirmed(true);
  };

  useEffect(() => {
    if (!store.confirmAgencyInfo && confirmed) {
      setPage(2);
    }
  }, [store.confirmAgencyInfo]);

  const checkProfileAddressInputs = () => {
    return (
      store.user.addressLine1 !== null &&
      store.user.addressLine1 !== "" &&
      store.user.city !== null &&
      store.user.city !== "" &&
      store.user.postcode !== null &&
      store.user.postcode !== ""
    );
  };

  return (
    <>
      <Modal
        show={show}
        className="agency__modal"
        onHide={onHide}
        size="lg"
        centered={true}
      >
        <Modal.Header>
          <Modal.Title>
            {page === 0
              ? "Profile Check"
              : page === 1
              ? `${profile?.agency?.title}`
              : page === 2
              ? "Confirm Your Identity"
              : page === 3
              ? "Application Accepted"
              : ""}
          </Modal.Title>
          <button className="btn" onClick={onHide}>
            <CloseModal />
          </button>
        </Modal.Header>

        <ModalBody>
          {page === 0 && <ProfilePage agency={profile?.agency?.title} />}

          {page === 1 && (
            <ModalWrapper>
              <ModalImage src={ProfileIcon} alt={`${profile?.agency?.title}`} />

              <Text color={GREY.ten} size="small" align="center" mb="20">
                {profile?.agency?.description}
              </Text>

              <ModalFlex direction="col">
                <FormLabel>ANY QUESTIONS REACH OUT TO THE AGENCY ON:</FormLabel>
                <ModalFlex>
                  <OrgPhoneIcon />
                  <p style={{ textDecoration: "underline" }}>
                    <strong>Needed</strong>
                  </p>
                </ModalFlex>
              </ModalFlex>

              <Divider top="0" bottom="10" line="none" />

              <ModalFlex>
                <OrgEmailIcon />
                <p>needed</p>
              </ModalFlex>
            </ModalWrapper>
          )}

          {page === 2 && (
            <>
              <Text color={GREY.ten} size="small">
                Let's check a few things{" "}
                <strong>
                  {profile?.user?.firstName} {profile?.user?.lastName}.
                </strong>
              </Text>

              <Text color={GREY.ten} size="small" mb="40">
                To amend incorrect information below, please contact the agency
                before continuing.
              </Text>

              <ModalGrid cols={3}>
                <ModalFlex col={1} direction="col">
                  <FormLabel>AGENCY YOU WANT TO JOIN:</FormLabel>
                  <p>
                    <strong>{profile?.agency?.title}</strong>
                  </p>
                </ModalFlex>

                <ModalFlex col={2} direction="col">
                  <FormLabel>EMPLOYMENT STATUS:</FormLabel>
                  <p>
                    <strong>You don't</strong> currently work there
                  </p>
                </ModalFlex>
              </ModalGrid>

              <ModalGrid cols={3}>
                <ModalFlex direction="col">
                  <FormLabel>EMPLOYMENT TYPE:</FormLabel>
                  <p>
                    <strong>
                      {profile?.employmentType === "direct_engagement"
                        ? "Direct Engagement"
                        : "Non-Direct Engagement"}
                    </strong>
                  </p>
                </ModalFlex>

                <ModalFlex col={2} direction="col">
                  <FormLabel>PAYMENT TYPE:</FormLabel>

                  <p>
                    <strong>
                      {profile?.paymentType === "paye"
                        ? "PAYE"
                        : profile?.paymentType === "ltd" &&
                          profile?.isSoleTrader
                        ? "Sole Trader"
                        : profile?.paymentType === "ltd" &&
                          !profile?.isSoleTrader
                        ? "LTD"
                        : "Umbrella Company"}
                    </strong>
                  </p>
                </ModalFlex>
              </ModalGrid>

              <ModalFlex direction="col">
                <FormLabel>WHICH MEANS:</FormLabel>

                <ModalFlex>
                  <OrgTickIcon />
                  <p>
                    <strong>You're NOT</strong> currently on their payroll
                  </p>
                </ModalFlex>

                <ModalFlex>
                  <OrgTickIcon />
                  <p>
                    <strong>You're NOT</strong> currently a full-time worker
                    there
                  </p>
                </ModalFlex>

                <ModalFlex>
                  <OrgTickIcon />
                  <p>
                    <strong>You're NOT</strong> a current active member of their
                    staff
                  </p>
                </ModalFlex>
              </ModalFlex>
              <br />

              <ModalGrid cols={3}>
                <ModalFlex col={1} direction="col">
                  <FormLabel>GRADE</FormLabel>
                  <p>
                    <strong>{profile?.grade?.title}</strong>
                  </p>
                </ModalFlex>

                <ModalFlex col={2} direction="col">
                  <FormLabel>SPECIALITY:</FormLabel>
                  <p>
                    {profile?.specialities?.length === 0
                      ? "-"
                      : profile.specialities.map((cc) => (
                          <>
                            <strong>{cc.title} </strong>
                            <br />
                          </>
                        ))}
                  </p>
                </ModalFlex>

                <ModalFlex col={1} direction="col">
                  <FormLabel>SKILLS:</FormLabel>
                  <p>
                    {profile?.skills?.length === 0
                      ? "-"
                      : profile.skills.map((cc) => (
                          <>
                            {" "}
                            <strong>{cc.name}</strong>
                            <br />
                          </>
                        ))}
                  </p>
                </ModalFlex>
              </ModalGrid>
            </>
          )}

          {page === 3 && (
            <ModalWrapper>
              <ModalImage src={ProfileIcon} alt={`${profile?.agency?.title}`} />

              <Text color={GREY.ten} size="small" align="center" mb="35">
                Great news, <strong>you have been accepted! </strong> You are
                now able to view and book shifts at this agency.
              </Text>

              <ModalGrid cols={2}>
                <ModalFlex col={1} direction="col">
                  <FormLabel>ORG APPLICATION STATUS:</FormLabel>
                  <ModalFlex>
                    <OrgTickIcon />
                    <p>
                      <strong>Application accepted</strong>
                    </p>
                  </ModalFlex>
                </ModalFlex>

                <ModalFlex col={2} direction="col">
                  <FormLabel>EMPLOYMENT TYPE:</FormLabel>
                  <ModalFlex>
                    <p>
                      <strong>
                        {profile?.employmentType === "direct_engagement"
                          ? "Direct Engagement"
                          : "Non-Direct Engagement"}
                      </strong>
                    </p>
                  </ModalFlex>
                </ModalFlex>
              </ModalGrid>

              <ModalGrid cols={2}>
                <ModalFlex col={1} direction="col">
                  <FormLabel>ORG DEFINED GRADE:</FormLabel>
                  <ModalFlex>
                    <p>
                      <strong>{profile?.grade?.title}</strong>
                    </p>
                  </ModalFlex>
                </ModalFlex>

                <ModalFlex col={2} direction="col">
                  <FormLabel>PAYMENT TYPE:</FormLabel>
                  <ModalFlex>
                    <p>
                      <strong>
                        {profile?.paymentType === "paye"
                          ? "PAYE"
                          : profile?.paymentType === "ltd" &&
                            profile?.isSoleTrader
                          ? "SOLE TRADER"
                          : profile?.paymentType === "ltd" &&
                            !profile?.isSoleTrader
                          ? "LTD"
                          : "UMBRELLA"}
                      </strong>
                    </p>
                  </ModalFlex>
                </ModalFlex>
              </ModalGrid>
            </ModalWrapper>
          )}
          {!checkProfileAddressInputs() && (
            <span>
              Please click this{" "}
              <Link to="/profile">
                <u>link</u>
              </Link>{" "}
              to complete your profile
            </span>
          )}
          <Divider top="40" bottom="22" line="solid" />
          <ModalFooter>
            <DefaultButton
              color="white"
              type={"white"}
              onClick={() => {
                page === 0
                  ? onHide()
                  : page === 1
                  ? setPage(0)
                  : page === 2
                  ? setPage(0)
                  : onHide();
              }}
            >
              {page === 0 || page === 3 ? "close" : "Back"}
            </DefaultButton>

            {page !== 3 && (
              <DefaultButton
                disabled={!checkProfileAddressInputs()}
                onClick={() => {
                  page === 0
                    ? confirmInfo()
                    : page === 1
                    ? setPage(2)
                    : page === 2
                    ? joinAgency()
                    : onHide();
                }}
                size="small"
                color="blue"
                style={{ opacity: checkProfileAddressInputs() ? 1 : 0.5 }}
                length="235px"
                type="submit"
              >
                {page === 0
                  ? "Confirm Agency Info"
                  : page === 1
                  ? "Join this Agency"
                  : page === 2
                  ? "Correct, join the agency"
                  : ""}
              </DefaultButton>
            )}
          </ModalFooter>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AgencyModal;

const ModalBody = styled.div`
  overflow: auto;
  padding: 34px 38px;
  position: relative;

  label {
    color: rgb(125, 135, 147);
  }
`;

const ModalWrapper = styled.div`
  padding: 0 30px;
`;

export const ModalFooter = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  button {
    margin-right: 8px;
  }
  @media (max-width: 468px) {
    flex-direction: column;
    text-align: center;
    button {
      font-size: 12px;
      margin-bottom: 10px;
      width: 100%;
    }
  }
`;

const ModalImage = styled.img`
  display: flex;
  height: 90px;
  justify-content: center;
  margin: 0 auto 40px;
  max-width: 143px;
  width: 100%;
  img {
    height: 54px;
  }
`;

export const ModalFlex = styled.div`
  align-items: ${(props) => (props.direction === "col" ? "initial" : "center")};
  display: flex;
  position: relative;
  flex-direction: ${(props) => (props.direction === "col" ? "column" : "row")};
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 6px;
  label {
    margin: 0;
  }
  svg {
    margin-right: 5px;
    margin-top: 0;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    -ms-grid-column: ${(props) => props.col};
  }
`;

export const ModalGrid = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.cols},1fr)`};
  margin: 0 auto 15px;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr;
`;
