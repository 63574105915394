import { gql } from "@apollo/client";

export const WorkerShiftMinimalFragment = gql`
  fragment WorkerShiftMinimalFragment on WorkerShift {
    id
    bookingAction
    startTime
    endTime
    status
    withSecondaryBank
    actualCoreRate
    actualCoreHours
    actualUnsocialHours
    actualUnsocialRate
    actualTotalPay
    agencyRegistration {
      id
      agency {
        id
      }
    }
    shiftBlockId
    breakMinutes
    shiftType

    organisation {
      id
      name
    }
    department {
      id
      name
    }
    grade {
      id
      title
      onCallRate
      callOutRate
    }
    site {
      id
      name
    }
    workerSignOff {
      id
      reportedStartTime
      reportedEndTime
      confirmedStartTime
      confirmedEndTime
    }

    shouldEnforceBreaks
    gradeBreakMinutes
    gradeBreakRepeatMinutes
  }
`;
