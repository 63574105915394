export const profileImageIcon = () => (
  <svg
    width="69"
    height="69"
    viewBox="0 0 69 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.5739 49.1664C47.3752 54.0979 41.1201 57.2386 34.108 57.2386C27.0959 57.2386 20.8407 54.0979 16.642 49.1664C15.5138 47.8447 15.3852 45.9404 16.2278 44.4197C19.5268 38.5361 26.2961 34.5 34.108 34.5C41.9198 34.5 48.6891 38.5361 51.9881 44.4197C52.8307 45.9404 52.7022 47.8447 51.5739 49.1664ZM34.5 11.7614C39.6967 11.7614 43.9091 15.9737 43.9091 21.1705C43.9091 26.3672 39.6967 30.5795 34.5 30.5795C29.3033 30.5795 25.0909 26.3672 25.0909 21.1705C25.0909 15.9737 29.3033 11.7614 34.5 11.7614ZM34.5 0C15.4531 0 0 15.4531 0 34.5C0 53.5469 15.4531 69 34.5 69C53.5469 69 69 53.5469 69 34.5C69 15.4531 53.5469 0 34.5 0Z"
      fill="#CCD2D8"
    />
  </svg>
);
