import moment from "moment/moment";

import { ApiCalendarDay } from "@patchworkhealth/selfrostering-api";

import SelfRosteringPreferenceButton from "components/SelfRostering/SelfRosteringPreferenceButton";
import { classNames } from "helpers/newHelpers";

interface Props {
  day: ApiCalendarDay;
  rosteringPeriodId: string;
}

const SelfRosteringCell = ({ day, rosteringPeriodId }: Props) => {
  const isToday = moment(day.date).isSame(moment(), "day");

  return (
    <div
      className={classNames(
        "w-[125px] mx-[6px] border-t pt-[6px] relative",
        isToday ? "border-blue-6 border-t-2" : "border-grey-2",
        "min-h-[170px]"
      )}
    >
      <p
        className={classNames(
          "absolute right-0 top-[-28px] text-sm font-semibold",
          isToday
            ? "text-white bg-blue-6 px-[4px] py-[1px] rounded"
            : "text-blue-2 px-[4px] py-[1px] rounded"
        )}
      >
        {moment(day.date).format("D")}
      </p>

      {day.shiftTypes.map((shiftType, index) => (
        <SelfRosteringPreferenceButton
          rosteringPeriodId={rosteringPeriodId}
          shiftName={shiftType.name}
          selected={shiftType.selected}
          index={index}
          date={day.date}
          key={`${day.date.toLocaleDateString()}-${shiftType.name}`}
        />
      ))}
    </div>
  );
};

export default SelfRosteringCell;
