import { AppActions, AppState } from "context/AppContext.types";

export const reducer = (state: AppState, action: AppActions): AppState => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };

    case "SET_ORGANISATIONS":
      return {
        ...state,
        organisations: action.payload,
      };

    case "TOGGLE_MODAL":
      return {
        ...state,
        toggleModal: !state.toggleModal,
      };

    case "SET_APPROVED_ORGS":
      return {
        ...state,
        approvedOrgs: action.payload,
      };

    case "SET_PENDING_ORGS":
      return {
        ...state,
        pendingOrgs: action.payload,
      };

    case "SET_IS_MOBILE":
      return {
        ...state,
        isMobile: action.payload,
      };

    case "SET_MOBILE_VERSION":
      return {
        ...state,
        mobileVersion: action.payload,
      };

    case "SET_ACCOUNT_ACTIVATED":
      return {
        ...state,
        accountActivated: true,
      };

    case "SET_PRODUCT_TYPE":
      return {
        ...state,
        productType: action.payload,
      };

    case "SET_SHIFT_TYPE":
      return {
        ...state,
        shiftType: action.payload,
      };

    case "LOADING_WORKER":
      return {
        ...state,
        loadingWorker: action.payload,
      };

    case "HYDRATION_QUERY":
      return {
        ...state,
        specialities: action.payload.specialities,
        grades: action.payload.grades,
        staffGroups: action.payload.staffGroups,
        ediReferenceData: action.payload.ediReferenceData,
      };

    case "SET_UNREAD_NOTIFICATION_LENGTH":
      return {
        ...state,
        unreadNotificationLength: action.payload,
      };

    case "SET_NOTIFICATIONS":
      return {
        ...state,
        notifications: action.payload,
      };

    case "ACCEPT_NOTIFICATION":
      return {
        ...state,
        acceptNotification: !state.acceptNotification,
      };

    //Timesheets
    case "SET_TIMESHEETS":
      return {
        ...state,
        timesheets: action.payload,
      };

    //Expenses
    case "SET_EXPENSES":
      return {
        ...state,
        expenses: action.payload,
      };

    //VMS
    case "SET_AGENCY_REGISTRATION_ID":
      return {
        ...state,
        agencyRegistrationId: action.payload,
      };

    case "SET_AGENCY_ONBOARDING":
      return {
        ...state,
        agencyOnboarding: action.payload,
      };

    case "SET_CONFIRM_AGENCY_INFO":
      return {
        ...state,
        confirmAgencyInfo: action.payload,
      };

    case "SET_MOBILE_MODAL":
      return {
        ...state,
        mobileModal: action.payload,
      };

    default:
      return { ...state };
  }
};
