import styled from "styled-components";

import { GREY } from "@patchworkhealth/web-components";

export const HeaderAndFooterWidthContainer = styled.div`
  min-width: 860px;
  display: flex;
`;

export const HeaderContainer = styled(HeaderAndFooterWidthContainer)`
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const DisputeFormTitle = styled.h2`
  color: ${GREY.ten};
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 40px;
`;

export const DisputeFormSubTitle = styled.h3`
  color: ${GREY.ten};
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
`;
