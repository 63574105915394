import React from "react";
import moment from "moment/moment";

import {
  ApiCalendarDay,
  ApiCalendarMonthResponse,
} from "@patchworkhealth/selfrostering-api";

import SelfRosteringBlankCell from "components/SelfRostering/SelfRosteringBlankCell";
import SelfRosteringCell from "components/SelfRostering/SelfRosteringCell";

interface Props {
  data: ApiCalendarMonthResponse | undefined;
  rosteringPeriodId: string;
}

const SelfRosteringCalendar = ({ data, rosteringPeriodId }: Props) => {
  if (!data) {
    return (
      <div className="bg-white w-[1021px] min-h-[600px] mx-auto px-8 pt-8 shadow border border-grey-1 rounded-lg">
        Loading
      </div>
    );
  }

  const paddingCells = Array((data.viewDate.getDay() + 6) % 7)
    .fill(null)
    .map((_, idx) => <SelfRosteringBlankCell key={idx} />);

  return (
    <div className="bg-white w-[1021px] min-h-[600px] mx-auto px-8 pt-8 shadow border border-grey-1 rounded-lg">
      <div className="flex mb-[10px] w-full text-xs text-gray-600">
        {["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"].map((weekday) => (
          <div key={weekday} className="w-[125px] mx-[6px]">
            {weekday}
          </div>
        ))}
      </div>
      <div className="flex flex-wrap w-full">
        {paddingCells}
        {data.days.map((day: ApiCalendarDay) => (
          <SelfRosteringCell
            day={day}
            key={moment(day.date).format("YYYY-MM-DD")}
            rosteringPeriodId={rosteringPeriodId}
          />
        ))}
      </div>
    </div>
  );
};

export default SelfRosteringCalendar;
