import { useState } from "react";
import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";

import { Loading } from "@patchworkhealth/web-components";

import Layout from "components/Layout/Layout";
import { PreferencesHeader } from "components/SelfRostering/components/PreferencesHeader";
import { SuccessPage } from "components/SelfRostering/components/SuccessPage";
import SelfRosteringCalendar from "components/SelfRostering/SelfRosteringCalendar";
import SelfRosteringCalendarHeader from "components/SelfRostering/SelfRosteringCalendarHeader";
import {
  rosteringPeriodsApi,
  selfRosteringPreferencesApi,
} from "helpers/kmonoApiClient";

interface RoutePathParams {
  rosteringPeriodId: string;
}

const enum SelfRosteringPages {
  PreferencesCalendar,
  SuccessPage,
}

const SelfRosteringPreferences = () => {
  const queryClient = useQueryClient();

  const { rosteringPeriodId } = useParams<RoutePathParams>();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const viewDate = searchParams.get("viewDate") || undefined;

  const [page, setPage] = useState<SelfRosteringPages>(
    SelfRosteringPages.PreferencesCalendar
  );

  const { data: calendarData, isLoading } = useQuery(
    ["selfRosteringPreferences", rosteringPeriodId, viewDate],
    () =>
      selfRosteringPreferencesApi.getApiSelfRosteringPeriodPreferencesRosteringPeriodId(
        {
          rosteringPeriodId,
          viewDate,
        }
      )
  );

  const { mutate: submitPreferences } = useMutation(
    (rosteringPeriodId: string) => {
      return rosteringPeriodsApi
        .putApiRosteringPeriodsSubmitRosteringPeriodId({
          rosteringPeriodId,
        })
        .then(() => queryClient.invalidateQueries("selfRosteringPeriod"))
        .catch(console.error);
    }
  );

  const submitPreferencesAction = () => {
    try {
      submitPreferences(rosteringPeriodId);
      setPage(SelfRosteringPages.SuccessPage);
    } catch (error) {
      toast.error("Failed to submit preferences");
    }
  };

  return (
    <Layout headerValueText="Roster preferences">
      {page === SelfRosteringPages.PreferencesCalendar && (
        <>
          <PreferencesHeader
            rosteringPeriodId={rosteringPeriodId}
            submitPreferencesAction={submitPreferencesAction}
          />

          {isLoading ? (
            <div className="flex justify-center items-center h-[600px]">
              <Loading />
            </div>
          ) : (
            <>
              <SelfRosteringCalendarHeader
                viewDate={moment(viewDate || calendarData?.viewDate)}
                rosteringPeriodId={rosteringPeriodId}
              />
              <SelfRosteringCalendar
                data={calendarData}
                rosteringPeriodId={rosteringPeriodId}
              />
            </>
          )}
        </>
      )}

      {page === SelfRosteringPages.SuccessPage && <SuccessPage />}
    </Layout>
  );
};

export default SelfRosteringPreferences;
