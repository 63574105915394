import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import styled from "styled-components";

import { useRosteringExceptionReportQuery } from "components/ExceptionReports/__generated__/ExceptionReports.generated";
import { ExceptionReportPages } from "components/ExceptionReports/ExceptionReports.types";
import { ExceptionReportModalContent } from "components/ExceptionReports/modals/ExceptionReportModal/ExceptionReportModalContent";

interface Props {
  onHide: () => void;
  show: boolean;
  exceptionReportId: string | undefined | null;
  setPage: (page: ExceptionReportPages) => void;
  setExceptionReportId: (id: string) => void;
}

export const ExceptionReportModal = ({
  onHide,
  show,
  exceptionReportId,
  setPage,
  setExceptionReportId,
}: Props) => {
  const { data: exceptionReport } = useRosteringExceptionReportQuery(
    exceptionReportId
      ? {
          variables: { reportId: +exceptionReportId },
          onCompleted: (data) => {
            if (data.rosteringExceptionReport === null) {
              onHide?.();
              toast.error(
                `Unable to view exception report #${exceptionReportId}`
              );
            }
          },
          onError: ({ networkError }) => {
            if (networkError) {
              onHide?.();
            }
          },
        }
      : { skip: true }
  );

  return (
    <>
      <StyledModal show={show} onHide={onHide} centered size="lg">
        <ExceptionReportModalContent
          exceptionReport={exceptionReport?.rosteringExceptionReport}
          onHide={onHide}
          setPage={setPage}
          setExceptionReportId={setExceptionReportId}
        />
      </StyledModal>
    </>
  );
};

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 950px;
    min-width: 800px;
  }
`;
