import { useState } from "react";
import { makeVar, useReactiveVar } from "@apollo/client";
import { RosteringLeaveEntitlementLeaveTypeEnum } from "baseCODEGEN";

import { RosteringOrganisationRegistrationsType } from "context/AppContext.types";

import {
  RosteringLeaveEntitlementsQuery,
  useRosteringLeaveEntitlementsQuery,
} from "../__generated__/LeaveManagement.generated";
import { LeaveEntitlementType } from "../LeaveEntitlementModal/LeaveEntitlementModal.types";
import { LeaveButton } from "./components/RequestLeaveButton";
import { RequestLeaveEntitlementsList } from "./components/RequestLeaveEntitlementsList";
import { RequestLeaveHeader } from "./components/RequestLeaveHeader";
import * as Styled from "./RequestLeaveSection.styled";
import { LeaveButtonType } from "./RequestLeaveSection.types";

const entitlementsVar = makeVar<
  RosteringLeaveEntitlementsQuery["rosteringLeaveEntitlements"]
>([]);

interface Props {
  onOpenEntitlement: (leaveEntitlement: LeaveEntitlementType) => void;
  chosenOrg: RosteringOrganisationRegistrationsType[number] | undefined | null;
}

export const RequestLeaveSection = ({
  onOpenEntitlement,
  chosenOrg,
}: Props) => {
  const activeRosteringLeaveEntitlements =
    useReactiveVar(entitlementsVar) ?? [];

  const [activeLeaveSection, setActiveLeaveSection] =
    useState<LeaveButtonType>("annual_leave");
  const [isExpiredOpen, setIsExpiredOpen] = useState<boolean>(false);

  const { data: leaveEntitlements, loading } =
    useRosteringLeaveEntitlementsQuery(
      chosenOrg?.organisation && chosenOrg.id
        ? {
            variables: {
              organisationId: chosenOrg?.organisation.value,
              rosteringOrganisationRegistrationId: +chosenOrg.id,
              expired: isExpiredOpen,
              entitlementLeaveTypeEnum:
                activeLeaveSection as RosteringLeaveEntitlementLeaveTypeEnum,
            },
            fetchPolicy: "cache-first",
            onCompleted: (data) => {
              if (!isExpiredOpen) {
                entitlementsVar(data.rosteringLeaveEntitlements);
              }
            },
          }
        : { skip: true }
    );

  const onSectionChange = (sectionName: LeaveButtonType) => {
    setActiveLeaveSection(sectionName);
    setIsExpiredOpen(false);
  };

  const onPastEntitlementOpen = () => {
    setIsExpiredOpen((prev) => !prev);
  };

  const rosteringLeaveEntitlements =
    leaveEntitlements?.rosteringLeaveEntitlements ?? [];

  return (
    <Styled.RequestLeaveSectionContainer>
      <Styled.Aside>
        <LeaveButton
          type="annual_leave"
          onClick={() => {
            onSectionChange("annual_leave");
          }}
          isActive={activeLeaveSection === "annual_leave"}
        />
        <LeaveButton
          type="study_leave"
          onClick={() => {
            onSectionChange("study_leave");
          }}
          isActive={activeLeaveSection === "study_leave"}
        />
      </Styled.Aside>
      <Styled.MainContainer>
        <RequestLeaveHeader
          activeLeaveSection={activeLeaveSection}
          entitlementAmount={activeRosteringLeaveEntitlements.length}
        />
        <RequestLeaveEntitlementsList
          activeRosteringLeaveEntitlements={activeRosteringLeaveEntitlements}
          pastRosteringLeaveEntitlements={rosteringLeaveEntitlements}
          onOpenEntitlement={onOpenEntitlement}
          isExpiredOpen={isExpiredOpen}
          onPastEntitlementOpen={onPastEntitlementOpen}
          loading={loading}
        />
      </Styled.MainContainer>
    </Styled.RequestLeaveSectionContainer>
  );
};
