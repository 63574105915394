import { useEffect, useState } from "react";

import { classNames } from "helpers/newHelpers";

export const BackToTopButton = () => {
  const [showButton, setShowButton] = useState(false);
  const handleScroll = () => setShowButton(window.scrollY > 1500);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <button
      type="button"
      className={classNames(
        showButton ? "pointer-events-auto opacity-70" : "opacity-0",
        "pointer-events-none fixed bottom-4 right-4 flex h-[50px] w-[50px] cursor-pointer items-center justify-center rounded-lg border-0 bg-black-2 text-lg text-white transition hover:bg-black-4"
      )}
      onClick={handleClick}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.18368 0.166687C7.43064 0.166693 7.65669 0.256221 7.83115 0.404591C7.86333 0.431935 7.89393 0.461443 7.92271 0.493014L13.2402 6.32635C13.6122 6.7345 13.583 7.36699 13.1748 7.73905C12.7667 8.11111 12.1342 8.08185 11.7621 7.67369L8.18365 3.74808V12.8334C8.18365 13.3856 7.73594 13.8334 7.18365 13.8334C6.63137 13.8334 6.18365 13.3856 6.18365 12.8334V3.74813L2.60525 7.67369C2.23319 8.08185 1.6007 8.11111 1.19254 7.73905C0.78439 7.36699 0.755129 6.7345 1.12719 6.32635L6.43977 0.49837C6.62283 0.294745 6.88829 0.166687 7.18365 0.166687C7.18366 0.166687 7.18367 0.166687 7.18368 0.166687Z"
          fill="#ffffff"
        />
      </svg>
    </button>
  );
};
