import { useContext } from "react";
import { useQuery } from "react-query";

import { Button } from "@patchworkhealth/web-components";

import { workerCanSubmit } from "components/SelfRostering/rosterPeriodHelper";
import { AppContext } from "context/AppContext";
import { rosteringPeriodsApi } from "helpers/kmonoApiClient";

import { useCurrentWorkerForSelfRosteringQuery } from "../__generated__/SelfRostering.generated";

export const PreferencesHeader = ({
  rosteringPeriodId,
  submitPreferencesAction,
}: {
  rosteringPeriodId: string;
  submitPreferencesAction: () => void;
}) => {
  const { user } = useContext(AppContext);
  const { data: rosterPeriodData } = useQuery(
    ["selfRosteringPeriod", rosteringPeriodId],
    () =>
      rosteringPeriodsApi.getApiRosteringPeriodsRosteringPeriodId({
        rosteringPeriodId,
      })
  );

  const { data: contractedWorkAmount } =
    useCurrentWorkerForSelfRosteringQuery();

  const contractedAmount =
    contractedWorkAmount?.currentWorker
      ?.rosteringOrganisationRegistrations?.[0];

  return (
    <div
      className="flex items-center justify-between mb-8 border-b-2 border-black p-4 sticky top-[64px] bg-[#F5F7FA] z-10"
      id="main_header"
    >
      <div className="flex flex-col">
        <h1 className="text-2xl font-bold">Roster preferences</h1>
        <p>
          {rosterPeriodData?.grade.name} - {rosterPeriodData?.roster.name}
        </p>
      </div>
      <div>
        <p>
          Preference coverage:{" "}
          <span className="font-semibold">
            {rosterPeriodData?.coverageStartDate?.toLocaleDateString() || ""} -
            {rosterPeriodData?.coverageEndDate?.toLocaleDateString() || ""}
          </span>
        </p>
        <p>
          Contracted weekly{" "}
          {contractedAmount?.contractedWorkIsHours === true
            ? "hours: "
            : "PAs: "}
          <span className="font-semibold">
            {contractedAmount ? contractedAmount.contractedWorkAmount : "N/A"}
          </span>
        </p>
      </div>
      {workerCanSubmit(rosterPeriodData, user?.email) ? (
        <Button variant="primary" onClick={submitPreferencesAction}>
          Submit preferences{" "}
        </Button>
      ) : (
        <Button variant="secondary" disabled>
          Submit preferences{" "}
        </Button>
      )}
    </div>
  );
};
