import toast from "react-hot-toast";

import {
  DefaultButton,
  FormGridContainer,
  Loading,
} from "@patchworkhealth/web-components";

import { useRosteringExceptionReportQuery } from "components/ExceptionReports/__generated__/ExceptionReports.generated";
import { ExceptionReportDetails } from "components/ExceptionReports/components/ExceptionReportDetails/ExceptionReportDetails";
import { SupervisorsList } from "components/ExceptionReports/components/SupervisorsList/SupervisorsList";
import { useRosteringExceptionReportUpdateMutation } from "components/ExceptionReports/ExceptionReportForms/ManualExceptionReportForm/__generated__/ManualExceptionReportForm.generated";
import { ExceptionReportPages } from "components/ExceptionReports/ExceptionReports.types";
import { formatError } from "helpers/formatError";

import { ReportersDetails } from "../components/ReportersDetails/ReportersDetails";
import {
  InnerContainer,
  ReviewPageContainer,
  ReviewPageFooter,
  ReviewPageHeader,
  ReviewPageOuterContainer,
} from "./ReviewPage.styled";

interface Props {
  setReportId: (reportId: string) => void;
  reportId: string;
  setPage: (page: ExceptionReportPages) => void;
}

const ReviewPage = ({ setReportId, reportId, setPage }: Props) => {
  const { data: exceptionReport } = useRosteringExceptionReportQuery({
    variables: { reportId: +reportId },
  });

  const [rosteringExceptionReportUpdate] =
    useRosteringExceptionReportUpdateMutation();

  const updateExceptionReportForSubmit = async () => {
    const { data: result } = await rosteringExceptionReportUpdate({
      variables: {
        rosteringExceptionReportUpdateId: +reportId,
        shiftDate:
          exceptionReport?.rosteringExceptionReport?.rosteringEvent?.startsAt,
        status: "pending",
      },
    });

    if (result?.rosteringExceptionReportUpdate?.errors.length) {
      toast.error(formatError(result?.rosteringExceptionReportUpdate.errors));
      return null;
    }
    return result;
  };

  const submitReport = async () => {
    try {
      const result = await updateExceptionReportForSubmit();
      if (result) {
        setReportId(
          result?.rosteringExceptionReportUpdate?.rosteringExceptionReport
            ?.id ?? ""
        );
        setPage(ExceptionReportPages.SuccessPage);
      }
    } catch (e) {
      toast.error("An error occurred");
    }
  };

  if (!exceptionReport) {
    return (
      <InnerContainer
        style={{
          width: "600px",
          height: "500px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0",
        }}
      >
        <Loading />
      </InnerContainer>
    );
  }

  const { rosteringExceptionReport } = exceptionReport;

  return (
    <ReviewPageOuterContainer>
      <ReviewPageHeader>
        Check your exception report is correct
      </ReviewPageHeader>
      <ReviewPageContainer>
        <InnerContainer>
          <h2 style={{ fontSize: 16, paddingBottom: 27 }}>
            Your supervisor will receive a notification once your report has
            been passed on.
          </h2>
          <FormGridContainer>
            <div style={{ display: "flex", gap: "10px" }}>
              <ReportersDetails
                rosteringExceptionReport={rosteringExceptionReport}
              />
              <SupervisorsList
                amountOfSupervisorsToDisplay={4}
                supervisorsColumn={2}
                categoryName={
                  exceptionReport?.rosteringExceptionReport
                    ?.rosteringExceptionReportReasons?.[0]
                    .rosteringExceptionReportReasonCategory.name
                }
              />
            </div>

            <ExceptionReportDetails
              rosteringExceptionReport={rosteringExceptionReport}
            />
          </FormGridContainer>
        </InnerContainer>
      </ReviewPageContainer>
      <ReviewPageFooter>
        <DefaultButton action={submitReport} text="Submit Report" />
      </ReviewPageFooter>
    </ReviewPageOuterContainer>
  );
};

export default ReviewPage;
