import { cva } from "class-variance-authority";

import { classNames, formatStatus } from "helpers/newHelpers";

// When rebrand colors are added to figma, add them to the variants below

const cvaClass = cva("", {
  variants: {
    variant: {
      Available: "bg-orange-old-1 border-orange-old-2 text-orange-old-5",
      Urgent: "bg-red-old-1 border-red-old-2 text-red-old-5",
      Applied: "bg-yellow-old-1 border-yellow-old-5 text-yellow-old-6",
      Booked: "bg-green-old-1 border-green-old-3 text-green-old-4",
      "To Sign Off": "bg-blue-old-1 border-blue-old-3 text-blue-old-7",
      Requested: "bg-grey-old-2 border-grey-old-3 text-black",
      "Signed Off": "bg-grey-old-4 border-grey-old-7 text-white",
      Approved: "bg-grey-old-5 border-grey-old-8 text-white",
      Payroll: "bg-grey-old-9 border-grey-old-10 text-white",
    },
    outer: {
      Urgent: "bg-red-old-2",
      Applied: "bg-yellow-old-5",
      Booked: "bg-green-old-3",
      "To Sign Off": "bg-blue-old-4",
      Requested: "bg-grey-old-4",
      "Signed Off": "bg-grey-old-2",
      Approved: "bg-grey-old-3",
      Payroll: "bg-grey-old-3",
      Available: "bg-orange-old-2",
    },
    inner: {
      Urgent: "bg-red-old-5",
      Applied: "bg-yellow-old-6",
      Booked: "bg-green-old-5",
      "To Sign Off": "bg-green-old-5",
      Requested: "bg-grey-old-10",
      "Signed Off": "bg-white",
      Approved: "bg-grey-old-1",
      Payroll: "bg-white",
      Available: "bg-orange-old-5",
    },
  },
});

interface IShifts {
  date?: string;
  status: string;
  number: number;
  onClick: (date: string | undefined, status: string) => void;
}

export const CalendarBankShifts = ({
  status,
  number,
  onClick,
  date,
}: IShifts) => (
  <div
    onClick={() => onClick(date, status)}
    className={classNames(
      "flex items-center w-[125px] h-[24px] pl-2 border rounded font-[500] text-[10px] cursor-pointer mb-[3px] leading-[2.5]",
      cvaClass({ variant: formatStatus(status) })
    )}
  >
    <div
      className={classNames(
        "w-[9px] h-[9px] mr-[5px] rounded-full flex items-center justify-center",
        cvaClass({ outer: formatStatus(status) })
      )}
    >
      <div
        className={classNames(
          "rounded-full w-[3px] h-[3px]",
          cvaClass({ inner: formatStatus(status) })
        )}
      />
    </div>
    <span className="mr-1">{number}</span>
    <div>{formatStatus(status)}</div>
  </div>
);
