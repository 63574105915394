import {
  LeaveRecordsFiltersType,
  LeaveRecordsOptionsType,
} from "components/LeaveManagement/LeaveRecordTable/LeaveRecordTable.types";

import { RosteringLeaveRequestStatusEnum } from "../../../baseCODEGEN";

export const initialFilters: LeaveRecordsFiltersType = {
  startDate: -1,
  status: "all",
};

export const dateSortOptions: LeaveRecordsOptionsType<number> = [
  { value: 1, label: "Oldest" },
  { value: -1, label: "Newest" },
];

export const statusSortOptions: LeaveRecordsOptionsType<
  `${RosteringLeaveRequestStatusEnum}` | "all"
> = [
  { value: "all", label: "All" },
  { value: "approved", label: "Accepted" },
  { value: "archived", label: "Archived" },
  { value: "cancelled", label: "Cancelled" },
  { value: "rejected", label: "Declined" },
  { value: "pending", label: "Pending" },
  {
    value: "pending_cancellation",
    label: "Pending cancellation",
  },
];
