import * as Types from '../../../baseCODEGEN';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from 'msw'
const defaultOptions = {} as const;
export type RosteringWorkerRostersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RosteringWorkerRostersQuery = { __typename?: 'Query', rosteringWorkerRosters?: { __typename?: 'RosteringRosterConnection', nodes: Array<{ __typename?: 'RosteringRoster', id: number, status: Types.RosterStatusEnum, expired: boolean, rota: { __typename?: 'RosteringRota', id: string, name: string, startDate: any, endDate: any } }> } | null };

export type RosteringTeamRotaQueryVariables = Types.Exact<{
  endDate: Types.Scalars['Date'];
  rosterId: Types.Scalars['Int'];
  startDate: Types.Scalars['Date'];
  eventTypes?: Types.InputMaybe<Array<Types.RosteringEventTypeEnum> | Types.RosteringEventTypeEnum>;
  statuses?: Types.InputMaybe<Array<Types.RosteringLeaveRequestStatusEnum> | Types.RosteringLeaveRequestStatusEnum>;
}>;


export type RosteringTeamRotaQuery = { __typename?: 'Query', rosteringTeamRota: Array<{ __typename?: 'TeamRota', grade?: { __typename?: 'Grade', title: string, id: string, colour?: string | null } | null, rosteringOrganisationRegistrations: Array<{ __typename?: 'RosteringOrganisationRegistration', profilePictureUrl?: string | null, workerName?: string | null, id: string, eventsOnRoster: Array<{ __typename?: 'RosteringEvent', endsAt: any, startsAt: any, eventType: Types.RosteringEventTypeEnum, id: string, scheduleable?: { __typename?: 'RosteringShift', id: string, workerNote?: string | null, organisationActivity?: { __typename?: 'RosteringOrganisationActivity', name: string, id: number } | null, shiftType?: { __typename?: 'RosteringShiftType', id: string, name: string } | null, location?: { __typename?: 'Location', id: number, name: string } | null, sites?: Array<{ __typename?: 'Site', id: number, name?: string | null }> | null, eventWithPublicUserInfo?: { __typename?: 'RosteringEventWithPublicUserInfo', id: number, phoneNumber?: string | null } | null } | null }>, rosteringLeaveRequests: Array<{ __typename?: 'LeaveRequest', id: string, status?: string | null, startDate?: any | null, endDate?: any | null, rosteringLeaveType?: { __typename?: 'LeaveType', id: string, leaveType: string } | null }> }> }> };


export const RosteringWorkerRostersDocument = gql`
    query RosteringWorkerRosters {
  rosteringWorkerRosters {
    nodes {
      id
      status
      rota {
        id
        name
        startDate
        endDate
      }
      expired
    }
  }
}
    `;

/**
 * __useRosteringWorkerRostersQuery__
 *
 * To run a query within a React component, call `useRosteringWorkerRostersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRosteringWorkerRostersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRosteringWorkerRostersQuery({
 *   variables: {
 *   },
 * });
 */
export function useRosteringWorkerRostersQuery(baseOptions?: Apollo.QueryHookOptions<RosteringWorkerRostersQuery, RosteringWorkerRostersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RosteringWorkerRostersQuery, RosteringWorkerRostersQueryVariables>(RosteringWorkerRostersDocument, options);
      }
export function useRosteringWorkerRostersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RosteringWorkerRostersQuery, RosteringWorkerRostersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RosteringWorkerRostersQuery, RosteringWorkerRostersQueryVariables>(RosteringWorkerRostersDocument, options);
        }
export type RosteringWorkerRostersQueryHookResult = ReturnType<typeof useRosteringWorkerRostersQuery>;
export type RosteringWorkerRostersLazyQueryHookResult = ReturnType<typeof useRosteringWorkerRostersLazyQuery>;
export type RosteringWorkerRostersQueryResult = Apollo.QueryResult<RosteringWorkerRostersQuery, RosteringWorkerRostersQueryVariables>;
export const RosteringTeamRotaDocument = gql`
    query RosteringTeamRota($endDate: Date!, $rosterId: Int!, $startDate: Date!, $eventTypes: [RosteringEventTypeEnum!], $statuses: [RosteringLeaveRequestStatusEnum!]) {
  rosteringTeamRota(endDate: $endDate, rosterId: $rosterId, startDate: $startDate) {
    grade {
      title
      id
      colour
    }
    rosteringOrganisationRegistrations {
      profilePictureUrl
      eventsOnRoster(
        rosterId: $rosterId
        startDateFrom: $startDate
        startDateUntil: $endDate
        eventTypes: $eventTypes
      ) {
        endsAt
        startsAt
        eventType
        id
        scheduleable {
          ... on RosteringShift {
            id
            organisationActivity {
              name
              id
            }
            shiftType {
              id
              name
            }
            location {
              id
              name
            }
            sites {
              id
              name
            }
            workerNote
            eventWithPublicUserInfo {
              id
              phoneNumber
            }
          }
        }
      }
      workerName
      id
      rosteringLeaveRequests(
        startDateUntil: $endDate
        endDateFrom: $startDate
        statuses: $statuses
      ) {
        id
        status
        startDate
        endDate
        rosteringLeaveType {
          id
          leaveType
        }
      }
    }
  }
}
    `;

/**
 * __useRosteringTeamRotaQuery__
 *
 * To run a query within a React component, call `useRosteringTeamRotaQuery` and pass it any options that fit your needs.
 * When your component renders, `useRosteringTeamRotaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRosteringTeamRotaQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      rosterId: // value for 'rosterId'
 *      startDate: // value for 'startDate'
 *      eventTypes: // value for 'eventTypes'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useRosteringTeamRotaQuery(baseOptions: Apollo.QueryHookOptions<RosteringTeamRotaQuery, RosteringTeamRotaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RosteringTeamRotaQuery, RosteringTeamRotaQueryVariables>(RosteringTeamRotaDocument, options);
      }
export function useRosteringTeamRotaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RosteringTeamRotaQuery, RosteringTeamRotaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RosteringTeamRotaQuery, RosteringTeamRotaQueryVariables>(RosteringTeamRotaDocument, options);
        }
export type RosteringTeamRotaQueryHookResult = ReturnType<typeof useRosteringTeamRotaQuery>;
export type RosteringTeamRotaLazyQueryHookResult = ReturnType<typeof useRosteringTeamRotaLazyQuery>;
export type RosteringTeamRotaQueryResult = Apollo.QueryResult<RosteringTeamRotaQuery, RosteringTeamRotaQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRosteringWorkerRostersQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ rosteringWorkerRosters })
 *   )
 * })
 */
export const mockRosteringWorkerRostersQuery = (resolver: ResponseResolver<GraphQLRequest<RosteringWorkerRostersQueryVariables>, GraphQLContext<RosteringWorkerRostersQuery>, any>) =>
  graphql.query<RosteringWorkerRostersQuery, RosteringWorkerRostersQueryVariables>(
    'RosteringWorkerRosters',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRosteringTeamRotaQuery((req, res, ctx) => {
 *   const { endDate, rosterId, startDate, eventTypes, statuses } = req.variables;
 *   return res(
 *     ctx.data({ rosteringTeamRota })
 *   )
 * })
 */
export const mockRosteringTeamRotaQuery = (resolver: ResponseResolver<GraphQLRequest<RosteringTeamRotaQueryVariables>, GraphQLContext<RosteringTeamRotaQuery>, any>) =>
  graphql.query<RosteringTeamRotaQuery, RosteringTeamRotaQueryVariables>(
    'RosteringTeamRota',
    resolver
  )
