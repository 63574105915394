import moment from "moment";

import { CurrentWorker } from "context/AppContext.types";

import { ShiftCardFragment } from "./__generated__/Shifts.generated";
import {
  AppliedIcon,
  ApprovedIcon,
  AvailableIcon,
  BookedIcon,
  CollabBankIcon,
  InstantBookIcon,
  PayrollIcon,
  SignedOffIcon,
  SignoffIcon,
  SignOffRequestedIcon,
  UrgentIcon,
} from "./ShiftIcons";

/* We need to make some modifications if shifts are provisionally booked */

export const checkProvisional = (
  user: CurrentWorker | null,
  selectedShift: ShiftCardFragment | null
) => {
  const combinedOrgIds = new Set(
    user?.organisationRegistrations?.map((s) => s.organisation.id) ?? []
  );

  for (const bank of user?.workerCollaborativeBanks ?? []) {
    for (const org of bank.collaborativeBank.organisations) {
      combinedOrgIds.add(org.id);
    }
  }

  // Convert the Set back to an array if needed
  const uniqueCombinedOrgIds = Array.from(combinedOrgIds);

  const allAgencies =
    user?.agencyRegistrations?.map((cc) => cc?.agency?.id).length ?? 0;

  const availableAgencies =
    user?.agencyRegistrations
      ?.filter((cc) => cc.confirmedAt === null)
      .map((dd) => dd?.agency?.id) ?? [];

  const isBankWorker = uniqueCombinedOrgIds.includes(
    selectedShift?.organisation?.id ?? 0
  );

  const showAgencyUI = !isBankWorker && allAgencies > 0;

  const needsToJoinAgency = availableAgencies.includes(
    selectedShift?.agencyRegistration?.agency?.id
  );

  const statusCheck = () => {
    if (isBankWorker && selectedShift?.status === "PROVISIONALLY_BOOKED") {
      return "AVAILABLE";
    }
    if (!isBankWorker && selectedShift?.status === "PROVISIONALLY_BOOKED") {
      return "BOOKED";
    }
    return selectedShift?.status ?? "AVAILABLE";
  };

  return {
    availableAgencies,
    isBankWorker,
    showAgencyUI,
    needsToJoinAgency,
    statusCheck: statusCheck(),
  };
};

/* Displays the correct icon for the shift status ---------------------- */

const iconMap: {
  [key: string]: ({ className }: { className: string }) => JSX.Element;
} = {
  URGENT: UrgentIcon,
  APPLIED: AppliedIcon,
  BOOKED: BookedIcon,
  PROVISIONALLY_BOOKED: AvailableIcon,
  TO_SIGN_OFF_ON_HUB: SignoffIcon,
  TO_SIGN_OFF_ON_HUB_OR_APP: SignoffIcon,
  SIGN_OFF_REQUESTED: SignOffRequestedIcon,
  SIGNED_OFF: SignedOffIcon,
  APPROVED: ApprovedIcon,
  PAYROLL: PayrollIcon,
  PAID: PayrollIcon,
};
export const ShiftStatusIcons = ({
  status,
  left,
}: {
  status: string;
  left: boolean;
}) => {
  const className = `absolute top-[-5px] ${left ? "left-4" : "right-2"}`;
  const Icon = iconMap[status] || AvailableIcon;
  return <Icon className={className} />;
};

/* Displays the correct actions badges for the shift ---------------- */

const iconActionsMap: {
  [key: string]: () => JSX.Element;
} = {
  instant: InstantBookIcon,
  collab: CollabBankIcon,
};
export const ShiftCardActions = ({ status }: { status: string }) => {
  const Icon = iconActionsMap[status];
  return <Icon />;
};

/* Displays a badge with the date of the shift ---------------------- */

export const ShiftDateBadge = ({ date }: { date: string }) => {
  return (
    <div className="flex justify-center p-2 text-sm font-semibold rounded-md w-28 bg-grey-2">
      <h2>{moment(date).format("D MMM YYYY")}</h2>
    </div>
  );
};
