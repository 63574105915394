import { Loading } from "@patchworkhealth/web-components";

import {
  ColumnHeader,
  GridContainer,
  GridHeader,
  ScrollableContainer,
} from "../ExceptionReports.styled";
import { ExceptionReportsType } from "../ExceptionReports.types";
import ExceptionReportTableRow from "./ExceptionReportTableRow";

interface Props {
  exceptionReports: ExceptionReportsType[] | undefined;
  viewExceptionReport: (id: string) => void;
  editExceptionReport: (report: ExceptionReportsType) => void;
  loading: boolean;
}

const ExceptionReportsTable = ({
  exceptionReports,
  viewExceptionReport,
  editExceptionReport,
  loading,
}: Props) => {
  if (loading)
    return (
      <Loading
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      />
    );

  return (
    <GridContainer>
      <GridHeader>
        <ColumnHeader style={{ paddingLeft: 5 }}>ID</ColumnHeader>
        <ColumnHeader style={{ paddingLeft: 5 }}>Dates & Hours</ColumnHeader>
        <ColumnHeader>Location/Roster</ColumnHeader>
        <ColumnHeader>Category Type</ColumnHeader>
        <ColumnHeader>Date Submitted</ColumnHeader>
        <ColumnHeader>Status</ColumnHeader>
        <ColumnHeader />
      </GridHeader>

      <ScrollableContainer>
        {exceptionReports?.map((report) => (
          <ExceptionReportTableRow
            key={report.id}
            exceptionReport={report}
            viewExceptionReport={viewExceptionReport}
            editExceptionReport={editExceptionReport}
          />
        ))}
      </ScrollableContainer>
    </GridContainer>
  );
};

export default ExceptionReportsTable;
