import _ from "lodash";

import { ActivityName } from "components/Schedule/hooks/useServicePlansShiftActivities";
import { formatTimeInTZ } from "helpers/momentHelpers";

export const timeFormat = (time: Date | string, format: string) => {
  return formatTimeInTZ(time, format === "y" ? "YYYY-MM-DD" : "");
};

export interface BankData {
  appliedShifts: Shift[];
  bookedShifts: Shift[];
}

export interface RosteredShifts {
  rosteringEvents: {
    nodes: Shift[];
  };
}

export interface Shift {
  id: number;
  __typename: string;
  startTime: string;
  endTime: string;
  eventType: string;
  scheduleable: {
    organisationActivity: {
      name: string;
      id: string;
    };
    shiftType: {
      name: string;
    };
  };
}

export interface Activity {
  label: string;
  value: string;
}

export interface ShiftPlusPlus {
  date: string;
  shiftType: "Bank" | "Rostered";
  shift: Shift;
}

export interface SortedShifts {
  dates: string[];
  shifts: ShiftPlusPlus[];
  activities: Activity[];
  nextShift: Shift | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const sortShifts = (
  bankData: BankData,
  rosterData: RosteredShifts,
  activityIds: string[],
  eventIdsForActivities: (activityIds: string[]) => number[],
  servicePlanActivityNames: ActivityName[]
): SortedShifts => {
  const useOldActivities = servicePlanActivityNames.length === 0;

  const appliedShifts: ShiftPlusPlus[] = bankData.appliedShifts.map(
    (appliedShift) => ({
      date: dateFromISO8601(appliedShift.startTime),
      shiftType: "Bank",
      shift: appliedShift,
    })
  );
  const bookedShifts: ShiftPlusPlus[] = bankData.bookedShifts.map(
    (bookedShift) => ({
      date: dateFromISO8601(bookedShift.startTime),
      shiftType: "Bank",
      shift: bookedShift,
    })
  );
  const rosteredShifts: ShiftPlusPlus[] = rosterData.rosteringEvents.nodes.map(
    (rosteredShift) => ({
      date: dateFromISO8601(rosteredShift.startTime),
      shiftType: "Rostered",
      shift: rosteredShift,
    })
  );
  const activityFilter = (shift: ShiftPlusPlus) => {
    const alwaysShow = shift.shiftType === "Bank" || activityIds.length === 0;
    if (alwaysShow) {
      return true;
    }
    const eventIdsMatchingSelectedActivityIds =
      eventIdsForActivities(activityIds);
    return useOldActivities
      ? activityIds.includes(shift.shift?.id.toString())
      : eventIdsMatchingSelectedActivityIds.includes(+shift.shift?.id);
  };
  const shifts: ShiftPlusPlus[] = [
    ...appliedShifts,
    ...bookedShifts,
    ...rosteredShifts,
  ]
    .filter((shift) => shift.date !== undefined)
    .filter(activityFilter)
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  const dates = _.uniq(shifts.map((shift) => shift.date));
  const activities = useOldActivities
    ? shifts
        .filter(
          (shift) => !!shift.shift?.scheduleable?.organisationActivity?.name
        )
        .map((shift) => ({
          label: shift.shift?.scheduleable?.organisationActivity?.name,
          value: shift.shift?.scheduleable?.organisationActivity?.id,
        }))
    : servicePlanActivityNames.map((activity) => ({
        label: activity.name,
        value: activity.id,
      }));

  const nextShift = shifts.find(
    (shift) =>
      shift.shiftType === "Rostered" && shift.shift.eventType === "shift"
  )?.shift;

  return { dates, shifts, activities, nextShift };
};

const dateFromISO8601 = (iSO8601: string) => iSO8601.substring(0, 10);
