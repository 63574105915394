import styled from "styled-components";

import {
  GLOBAL,
  GREY,
  RBBLUE,
  RBCYAN,
  RBGREY,
  RBLIGHTGREEN,
} from "@patchworkhealth/web-components";

export const CalendarOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

export const CalendarHeader = styled.h1`
  color: ${GREY.ten};
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 20px;
`;

export const CalendarNavAndTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const PrevButton = styled.button`
  display: flex;
  cursor: pointer;

  p {
    color: ${GREY.six};
    font-size: 16px;
    font-weight: 500;
    & :hover {
      color: ${GREY.ten};
    }
  }
`;

export const NextButton = styled.button`
  display: flex;
  cursor: pointer;

  p {
    color: ${GREY.six};
    font-size: 16px;
    font-weight: 500;
    & :hover {
      color: ${GREY.ten};
    }
  }
`;

export const MonthButton = styled.button`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  width: 100%;
`;

export const MonthDatePickerContainter = styled.div`
  height: 50px;
  width: 190px;
  .react-datepicker__input-container {
    visibility: hidden;
    display: none;
  }
  .react-datepicker-popper {
    left: 67px;
  }

  .react-datepicker {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    border: none;
    padding: 10px;
    position: absolute;
    left: -31px;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__navigation--previous {
    margin-top: 10px;
  }
  .react-datepicker__navigation--next {
    margin-top: 10px;
  }
  .react-datepicker__header {
    background: ${GLOBAL.white};
    border-bottom: none;

    .react-datepicker__current-month {
      color: ${RBBLUE.six};
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .react-datepicker__day {
      width: 1.8rem;
    }
    .react-datepicker__day-name {
      color: ${RBGREY.six};
      font-size: 12px;
      font-weight: 400;
    }
  }

  .react-datepicker__day--selected {
    background-color: ${RBCYAN.four};
    color: ${RBBLUE.six};
    font-weight: 500;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: inherit;
    color: inherit;
    &:hover {
      background-color: #f0f0f0;
    }
  }
  .react-datepicker__day--outside-month {
    color: #ccc;
  }
`;

export const MonthTitle = styled.h2`
  margin: 0;
  color: ${RBBLUE.six};
  font-size: 20px;
  font-weight: 500;
`;

export const CalendarBodyContainer = styled.div`
  background: white;
  border-radius: 8px;
  margin: 12px auto 20px;
  padding: 25px 16px 20px 32px;
  width: 1148px;
  height: 428px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

export const CalendarDayCell = styled.div<{ validRange: boolean }>`
  width: 149px;
  height: 120px;
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${({ validRange }) =>
    validRange ? RBLIGHTGREEN.two : GLOBAL.white};
  border-radius: 4px;
  padding: 0 6px;
`;

export const DayCellContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 120px;
`;

export const DayCellHeader = styled.div<{
  today: boolean;
  validRange: boolean;
}>`
  display: flex;
  align-items: center;
  border-bottom: ${({ today }) =>
    today ? `2px solid ${RBBLUE.five}` : `1px solid ${GREY.three}`};
  height: 36px;
  width: 100%;
  padding: 10px 0 5px 0;

  p {
    position: absolute;
    right: 10px;
    color: ${({ today, validRange }) =>
      today ? GLOBAL.white : validRange ? GREY.ten : GREY.five};
    font-weight: 500;
    font-size: 14px;
    background: ${({ today, validRange }) =>
      today ? RBBLUE.five : validRange ? RBLIGHTGREEN.two : GLOBAL.white};
    padding: ${({ today }) => (today ? "3px 4px" : 0)};
    border-radius: ${({ today }) => (today ? "4px" : 0)};
  }

  div {
    color: ${({ today }) => (today ? RBBLUE.five : GREY.five)};
    font-weight: 500;
    font-size: ${({ today }) => (today ? "16px" : "12px")};
    text-transform: ${({ today }) => (today ? null : "uppercase")};
  }
`;

export const DayCellBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
`;

export const CalendarFooterContainer = styled.div`
  display: flex;
  margin-top: 10px;

  p {
    font-size: 12px !important;
    font-weight: 400;
    color: #55606c;
    padding-left: 7px;
    margin: 2px 0;
    span {
      font-weight: bold;
    }
  }
`;

export const PopoverBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${GLOBAL.white};
  width: 250px;
  max-height: 400px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border: none;
  padding: 10px;
`;

export const PopoverHeader = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${RBBLUE.six};
  text-align: center;
  border-bottom: 1px solid ${GREY.three};
  padding: 3px 0 9px 0;
`;
