import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";

import DocumentsAlert from "components/Documents/DocumentsAlert";
import Layout from "components/Layout";
import Profile, { ProfilePicture } from "components/Profile";
import { AppContext } from "context/AppContext";
import {
  DELETE_PROFILE_PIC,
  UPDATE_AVATAR,
  UPDATE_EMPLOYMENT,
  UPDATE_PROFILE,
} from "gql/Mutations";
import { formatError } from "helpers/formatError";
import history from "helpers/functions";

const ProfilePage = ({ agency }) => {
  // State *****************************************************

  const store = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [displayEdiFields, setDisplayEdiFields] = useState(false);
  const [ediFieldsDisabled, setEdiFieldsDisabled] = useState(
    store.user.ediConsent === false
  );

  useEffect(() => {
    const primaryOrgs = store.user.organisationRegistrations.filter(
      (registration) => {
        return (
          registration?.organisation?.organisationType?.name === "Primary care"
        );
      }
    );
    setDisplayEdiFields(primaryOrgs?.length > 0);
  }, [store.user.organisationRegistrations]);

  useEffect(() => {
    if (store.confirmAgencyInfo) {
      formik.handleSubmit();
      store.setConfirmAgencyInfo(false);
    }
  }, [store.confirmAgencyInfo]);

  const onEdiConsentChange = (e, index) => {
    e.preventDefault();
    formik.setFieldValue("ediConsent", index);

    if (index === 0) {
      formik.setTouched({}, false);
      formik.setFieldValue("ethnicity", null);
      formik.setFieldValue("countryOfQualification", null);
      formik.setFieldValue("disability", null);
    }
    setEdiFieldsDisabled(index !== 1);
  };

  // Graphql ***************************************************

  const [updateProfile] = useMutation(UPDATE_PROFILE);
  const [updateEmployment] = useMutation(UPDATE_EMPLOYMENT);
  const [removeProfilePicture] = useMutation(DELETE_PROFILE_PIC);
  const [updateProfilePicture] = useMutation(UPDATE_AVATAR);

  // Formik Functions ******************************************

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: store?.user?.firstName || "",
      lastName: store?.user?.lastName || "",
      dateOfBirth: store?.user?.dateOfBirth || "",
      preferredName: store?.user?.preferredName,
      phoneNumber: store?.user?.phoneNumber,
      postcode: store?.user?.postcode || "",
      grade: {
        label: store?.user?.filterGrade?.title,
        value: store?.user?.filterGrade?.id,
      },
      staffGroup: store?.user?.staffGroup?.title
        ? {
            label: store?.user?.staffGroup?.title,
            value: store?.user?.staffGroup?.idValue,
          }
        : [],
      profNumber: store?.user?.regBodyNumber || "",
      email: store?.user?.email,
      addressLine1: store?.user?.addressLine1,
      addressLine2: store?.user?.addressLine2,
      city: store?.user?.city,
      niNumber: store?.user?.niNumber,
      dbsNumber: store?.user?.dbsNumber,
      gender: store?.user?.ediGender?.value
        ? {
            label: store?.user?.ediGender?.value,
            value: store?.user?.ediGender?.id,
          }
        : null,
      ediConsent: displayEdiFields
        ? store?.user?.ediConsent == null
          ? null
          : store?.user?.ediConsent
          ? 1
          : 0
        : null,
      countryOfQualification:
        store?.user?.ediCountryOfQualification == null
          ? null
          : {
              label: store?.user?.ediCountryOfQualification?.value,
              value: store?.user?.ediCountryOfQualification?.id,
            },
      disability:
        store?.user?.ediDisability == null
          ? null
          : {
              label: store?.user?.ediDisability?.value,
              value: store?.user?.ediDisability?.id,
            },
      ethnicity:
        store?.user?.ediEthnicity == null
          ? null
          : {
              label: store?.user?.ediEthnicity?.value,
              value: store?.user?.ediEthnicity?.id,
            },
      ediFieldsPresent: displayEdiFields,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
      lastName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
      dateOfBirth: Yup.string().required("Required"),
      postcode: Yup.string()
        .required("Required")
        .matches(
          /^([A-Z][A-HJ-Y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|GIR ?0A{2})$/i,
          "Invalid UK Postcode!"
        ),
      phoneNumber: Yup.string()
        .required("Required")
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Invalid Phone Number"
        ),
      staffGroup: Yup.object().required("Required"),
      email: Yup.string()
        .email("Invalid Email")
        .nullable()
        .required("Required"),
      profNumber: Yup.string().max(
        7,
        "Professional Number is longer than 7 characters!"
      ),
      niNumber: Yup.string()
        .nullable()
        .required("Required")
        .matches(
          /^[A-Za-z]{2}[0-9]{6}[A-Za-z]{1}$/,
          "Incorrect National Insurance number format!"
        ),
      gender: Yup.object().required("Required"),
      ediConsent: Yup.number().when("ediFieldsPresent", {
        is: true,
        then: () => Yup.number().required("Required"),
        otherwise: () => Yup.number().nullable(),
      }),
      countryOfQualification: Yup.object().when("ediConsent", {
        is: 1,
        then: () => Yup.object().required("Required"),
        otherwise: () => Yup.object().nullable(),
      }),
      disability: Yup.object().when("ediConsent", {
        is: 1,
        then: () => Yup.object().required("Required"),
        otherwise: () => Yup.object().nullable(),
      }),
      ethnicity: Yup.object().when("ediConsent", {
        is: 1,
        then: () => Yup.object().required("Required"),
        otherwise: () => Yup.object().nullable(),
      }),
      addressLine1: Yup.string().nullable().required("Required"),
      city: Yup.string().nullable().required("Required"),
    }),

    onSubmit: (values) => {
      const toastId = toast.loading("Loading...");

      updateEmployment({
        variables: {
          dbsNumber: values.dbsNumber,
          niNumber: values.niNumber.toUpperCase(),
          // niNumber: values.niNumber,
        },
      })
        .then((response) => {
          const errors = response.data?.updateEmploymentInfo?.errors;

          if (errors.length > 0) {
            toast.error(formatError(errors), { id: toastId });
            return;
          }

          let ediData = {};
          if (displayEdiFields === true) {
            ediData.ediConsent = Boolean(values.ediConsent);
            ediData.ediCountryOfQualificationId =
              values.countryOfQualification?.value;
            ediData.ediDisabilityId = values.disability?.value;
            ediData.ediEthnicityId = values.ethnicity?.value;
          }

          updateProfile({
            variables: {
              firstName: values.firstName?.trim(),
              lastName: values.lastName?.trim(),
              preferredName: values.preferredName?.trim(),
              dateOfBirth: values.dateOfBirth,
              phoneNumber: values.phoneNumber.toString()?.trim(),
              postcode: values.postcode?.trim(),
              staffGroupId: values.staffGroup.value,
              regBodyNumber: values.profNumber?.trim(),
              email: values.email?.trim(),
              addressLine1: values.addressLine1?.trim(),
              addressLine2: values.addressLine2?.trim(),
              city: values.city,
              filterGradeId: values.grade.value,
              ediGenderId: values.gender.value,
              ...ediData,
            },
          })
            .then((response) => {
              const errors = response.data?.workerUpdateProfile?.errors;

              if (errors.length > 0) {
                toast.error(formatError(errors), { id: toastId });
                return;
              }

              let firstTime = store?.user?.profileComplete;

              if (!firstTime) {
                history.push("/schedule");
              }

              store.fetchUser();
              toast.success("Profile Updated", {
                id: toastId,
              });
            })
            .catch((err) => {
              toast.error("There was an error!", err, { id: toastId });
            });
        })
        .catch((err) => {
          toast.error("There was an error!", err, { id: toastId });
        });
    },
  });

  const rosterProfile =
    store?.user?.rosteringWorkerProfile?.rosteringOrganisationRegistrations;

  return agency ? (
    <>
      {isOpen && <ProfilePicture store={store} setIsOpen={setIsOpen} />}
      <Profile
        agency={agency}
        store={store}
        formik={formik}
        setIsOpen={setIsOpen}
        rosterProfile={rosterProfile}
        updateProfilePicture={updateProfilePicture}
        removeProfilePicture={removeProfilePicture}
      />
    </>
  ) : (
    <Layout headerValueText="Profile">
      {isOpen && <ProfilePicture store={store} setIsOpen={setIsOpen} />}

      <DocumentsAlert show={modalShow} onHide={() => setModalShow(false)} />

      <Profile
        store={store}
        formik={formik}
        setIsOpen={setIsOpen}
        rosterProfile={rosterProfile}
        updateProfilePicture={updateProfilePicture}
        removeProfilePicture={removeProfilePicture}
        displayEdiFields={displayEdiFields}
        ediFieldsDisabled={ediFieldsDisabled}
        onEdiConsentChange={onEdiConsentChange}
      />
    </Layout>
  );
};

export default ProfilePage;
