import styled from "styled-components";

import { GLOBAL, GREY } from "@patchworkhealth/web-components";

export const RequestLeaveSectionContainer = styled.div`
  border-radius: 8px;
  border: 1px solid ${GREY.two};
  background: ${GLOBAL.white};
  padding: 21px 34px 21px 15px;
  display: flex;
  width: 1130px;
`;

export const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 0;
  width: 100%;
`;
