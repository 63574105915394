import styled from "styled-components";

import { GLOBAL, GREY } from "@patchworkhealth/web-components";

import { ShiftIcon } from "components/Shared/ShiftIcon";
import { Divider } from "components/Style";
import { formatTimeInTZ, parseTimeInTZ } from "helpers/momentHelpers";
import { Shift } from "helpers/rostering";
import { ArrowIcon, ClockIcon } from "icons/scheduleIcons";
import {
  AnnualShiftBackground,
  NoShiftBackground,
  OtherLeaveBackground,
  RestDayBackground,
  SickLeaveBackground,
  StudyLeaveBackground,
} from "icons/ShiftIcons";

interface Props {
  shift: Shift | null;
  activitiesLabel: (id: number, name: string | undefined) => string;
  size: "small" | "big" | "modal";
  openRosteringModal: (shift: Shift) => void;
  checkbox?: number;
}

export const RosteringScheduleShift = ({
  shift,
  activitiesLabel,
  size,
  openRosteringModal,
  checkbox,
}: Props) => {
  return shift === null ? (
    <NoShiftBackground checkbox={checkbox} />
  ) : shift.eventType === "sick_leave" ? (
    <LeaveWrapper checkbox={checkbox}>
      <SickLeaveBackground checkbox={checkbox} />
    </LeaveWrapper>
  ) : shift.eventType === "study_leave" ? (
    <LeaveWrapper checkbox={checkbox}>
      <StudyLeaveBackground checkbox={checkbox} />
    </LeaveWrapper>
  ) : shift.eventType === "annual_leave" ? (
    <LeaveWrapper checkbox={checkbox}>
      <AnnualShiftBackground checkbox={checkbox} />
    </LeaveWrapper>
  ) : shift.eventType === "rest" ? (
    <LeaveWrapper checkbox={checkbox}>
      <RestDayBackground checkbox={checkbox} />
    </LeaveWrapper>
  ) : shift.eventType === "special_leave" ? (
    <LeaveWrapper checkbox={checkbox}>
      <OtherLeaveBackground checkbox={checkbox} />
      <LeaveText>Special Leave</LeaveText>
    </LeaveWrapper>
  ) : shift.eventType === "paid_leave" ? (
    <LeaveWrapper checkbox={checkbox}>
      <OtherLeaveBackground checkbox={checkbox} />
      <LeaveText>Paid Leave</LeaveText>
    </LeaveWrapper>
  ) : (
    <RosteringSchedule
      size={size}
      onClick={() => openRosteringModal(shift)}
      checkbox={checkbox}
    >
      {size === "small" && (
        <div className="box__title">
          <h6>NEXT SHIFT</h6>
        </div>
      )}

      {size === "modal" && (
        <p>
          <LeaveCalendarIcon />{" "}
          {formatTimeInTZ(shift?.startTime, "ddd, Do MMM")}
        </p>
      )}

      {size === "big" && <Divider top="5" bottom="5" line="none" />}

      <p>
        <ClockIcon /> {formatTimeInTZ(shift?.startTime, "HH:mm")} -{" "}
        {formatTimeInTZ(shift?.endTime, "HH:mm")}
      </p>
      <p>
        <ArrowIcon />{" "}
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "80%",
          }}
        >
          {activitiesLabel(
            shift.id,
            shift?.scheduleable?.organisationActivity?.name.slice(0, 18)
          )}
        </div>
      </p>

      <div className="box__hours">
        {parseTimeInTZ(shift.endTime).diff(
          parseTimeInTZ(shift.startTime),
          "hours"
        )}
        hrs
      </div>

      <ShiftIcon type={shift?.scheduleable?.shiftType?.name} />
    </RosteringSchedule>
  );
};

interface LeaveWrapperProps {
  checkbox?: number;
}
const LeaveWrapper = styled.div<LeaveWrapperProps>`
  position: relative;
  margin-bottom: 20px;
  width: ${(props) => (props.checkbox === 3 ? "500px" : "333px")};
`;

const LeaveText = styled.h6`
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  color: ${GREY.seven};
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface RosteringScheduleProps {
  size: "small" | "big" | "modal";
  checkbox?: number;
}
const RosteringSchedule = styled.div<RosteringScheduleProps>`
  background-color: ${GLOBAL.white};
  border: 1px solid #e4e7eb;
  border-radius: 8px;

  cursor: ${(props) => (props.size === "modal" ? "initial" : "pointer")};

  font-size: 13px;
  height: 97px;
  margin-bottom: ${(props) => (props.size === "modal" ? "9px" : "20px")};
  padding: 13px 15px;
  position: relative;

  width: ${(props) =>
    (props.size === "small" && "294px") ||
    (props.size === "modal" && "100%") ||
    (props.size === "big" && props.checkbox === 3 ? "500px" : "333px")};

  @media (max-width: 768px) {
    width: 100%;
  }

  svg {
    height: 44px;
    position: absolute;
    right: 30px;
    top: 24px;
    width: 44px;
  }

  p {
    align-items: center;
    display: flex;
    line-height: 24px;
    margin: 0 !important;
    position: relative;

    svg {
      height: initial !important;
      margin-right: 10px !important;
      position: initial !important;
      width: initial !important;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        height: 15px;
        left: 0;
        position: relative;
        top: 0;
        width: 15px;
      }
    }
  }
`;

const LeaveCalendarIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.324 15.768H2.84449C2.01768 15.768 1.34741 15.1143 1.34741 14.308V4.08798C1.34741 3.28164 2.01768 2.62797 2.84449 2.62797H4.34156V1.16797H5.83864V2.62797H10.3299V1.16797H11.8269V2.62797H13.324C14.1508 2.62797 14.8211 3.28164 14.8211 4.08798V14.308C14.8211 15.1143 14.1508 15.768 13.324 15.768ZM2.84449 7.00798V14.308H13.324V7.00798H2.84449ZM2.84449 4.08798V5.54798H13.324V4.08798H2.84449ZM8.83279 12.648C8.83279 12.7585 8.74325 12.848 8.63279 12.848H7.53572C7.42526 12.848 7.33572 12.7585 7.33572 12.648V11.588C7.33572 11.4775 7.42526 11.388 7.53572 11.388H8.63279C8.74325 11.388 8.83279 11.4775 8.83279 11.588V12.648ZM5.83864 12.648C5.83864 12.7585 5.7491 12.848 5.63864 12.848H4.54156C4.43111 12.848 4.34156 12.7585 4.34156 12.648V11.588C4.34156 11.4775 4.43111 11.388 4.54156 11.388H5.63864C5.7491 11.388 5.83864 11.4775 5.83864 11.588V12.648ZM11.8269 9.72799C11.8269 9.83845 11.7374 9.92799 11.6269 9.92799H10.5299C10.4194 9.92799 10.3299 9.83845 10.3299 9.72799V8.66799C10.3299 8.55753 10.4194 8.46799 10.5299 8.46799H11.6269C11.7374 8.46799 11.8269 8.55753 11.8269 8.66799V9.72799ZM8.83279 9.72799C8.83279 9.83845 8.74325 9.92799 8.63279 9.92799H7.53572C7.42526 9.92799 7.33572 9.83845 7.33572 9.72799V8.66799C7.33572 8.55753 7.42526 8.46799 7.53572 8.46799H8.63279C8.74325 8.46799 8.83279 8.55753 8.83279 8.66799V9.72799ZM5.83864 9.72799C5.83864 9.83845 5.7491 9.92799 5.63864 9.92799H4.54156C4.43111 9.92799 4.34156 9.83845 4.34156 9.72799V8.66799C4.34156 8.55753 4.43111 8.46799 4.54156 8.46799H5.63864C5.7491 8.46799 5.83864 8.55753 5.83864 8.66799V9.72799Z"
      fill="#9CA5B0"
    />
  </svg>
);
