import { ExceptionReportStatusEnum } from "baseCODEGEN";

import { DefaultButton } from "@patchworkhealth/web-components";

import {
  SuccessInnerContainer,
  SuccessOuterContainer,
  SuccessText,
  TextItem,
} from "./SuccessPage.styled";

const SuccessPage = ({
  returnHome,
  reportId,
  setReportId,
  reportStatus,
}: {
  returnHome: () => void;
  reportId: string | null | undefined;
  setReportId: (reportId: string) => void;
  reportStatus: ExceptionReportStatusEnum | undefined;
}) => {
  const handleReturnHome = () => {
    setReportId("");
    returnHome();
  };

  const disputeTextBody =
    "The report has been sent to your next supervisor in-line who will aim to get back to you within 7 days.";

  const firstReportTextBody = `Your report ID is #${reportId}. The report has been sent to your supervisor who will aim to get back to you within 7 days.`;

  return (
    <SuccessOuterContainer>
      <SuccessInnerContainer data-testid="submission_text">
        <SuccessfulSubmitIcon />
        <SuccessText>
          Your{" "}
          {reportStatus === ExceptionReportStatusEnum.PendingDispute
            ? "dispute"
            : "report"}{" "}
          has been submitted successfully
        </SuccessText>
        <TextItem
          style={{
            fontSize: "16px",
            width: 635,
            textAlign: "center",
            marginBottom: 40,
          }}
        >
          {reportStatus === ExceptionReportStatusEnum.PendingDispute
            ? disputeTextBody
            : firstReportTextBody}
        </TextItem>
        <DefaultButton
          action={handleReturnHome}
          text="Back to Exception Reports"
          type="white"
        />
      </SuccessInnerContainer>
    </SuccessOuterContainer>
  );
};

export default SuccessPage;

const SuccessfulSubmitIcon = () => (
  <svg
    width="105"
    height="105"
    viewBox="0 0 105 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.8225 14.0682C52.1047 12.197 60.7698 13.0531 68.5257 16.5089C70.7327 17.4923 73.3191 16.5003 74.3025 14.2933C75.2859 12.0862 74.294 9.49981 72.0869 8.5164C62.6075 4.29264 52.0168 3.24627 41.8942 5.53335C31.7715 7.82042 22.6594 13.3184 15.9167 21.2073C9.17406 29.0962 5.16214 38.9534 4.47932 49.3087C3.79649 59.664 6.47934 69.9626 12.1277 78.6685C17.7761 87.3745 26.0875 94.0214 35.8221 97.6178C45.5568 101.214 56.1933 101.568 66.1452 98.6252C76.0971 95.6827 84.8312 89.6021 91.0449 81.2902C97.2587 72.9783 100.619 62.8779 100.625 52.5001V48.4751C100.625 46.0589 98.6663 44.1001 96.25 44.1001C93.8338 44.1001 91.875 46.0589 91.875 48.4751V52.4976C91.8702 60.9886 89.1207 69.2504 84.0368 76.0511C78.9528 82.8517 71.8067 87.8268 63.6642 90.2342C55.5218 92.6417 46.8192 92.3526 38.8545 89.4101C30.8898 86.4675 24.0896 81.0292 19.4682 73.9061C14.8467 66.783 12.6517 58.3569 13.2104 49.8844C13.769 41.4119 17.0515 33.3469 22.5682 26.8924C28.085 20.4378 35.5404 15.9395 43.8225 14.0682ZM99.3451 20.5922C101.053 18.8828 101.051 16.1127 99.342 14.405C97.6327 12.6973 94.8625 12.6987 93.1549 14.4081L52.4985 55.1052L42.4686 45.0753C40.76 43.3668 37.99 43.3668 36.2814 45.0753C34.5729 46.7839 34.5729 49.554 36.2814 51.2625L49.4064 64.3875C50.2271 65.2082 51.3404 65.6692 52.5011 65.6689C53.6618 65.6686 54.7748 65.2071 55.5951 64.386L99.3451 20.5922Z"
      fill="#57A886"
    />
  </svg>
);
