import { useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import { z } from "zod";

export const BANK_HOLIDAY_API = "https://www.gov.uk/bank-holidays.json";

let bankHolidays: BankHoliday[] | null = null; // mini cache

const fetchBankHolidays = async () => {
  if (bankHolidays !== null) return; // don't fetch again, data won't change

  const response = await (await fetch(BANK_HOLIDAY_API)).json();
  const parsed = bankHolidayApiSchema.safeParse(response);

  if (!parsed.success) {
    console.error(parsed, "bankHolidayApiSchema failed");
    return;
  }

  const { events } = parsed.data["england-and-wales"];
  bankHolidays = events;
};

export const useBankHolidays = () => {
  useEffect(() => {
    fetchBankHolidays();
  }, []);

  return bankHolidays;
};

export const getBankHoliday = (
  day: Dayjs,
  bankHolidays: BankHoliday[] | null
) =>
  bankHolidays?.find(
    (bankHoliday) =>
      dayjs(bankHoliday.date).format("YYYY-MM-DD") === day.format("YYYY-MM-DD")
  );

//--
const eventsSchema = z.object({
  title: z.string(),
  date: z.string(),
  notes: z.string(),
  bunting: z.boolean(),
});

const bankHolidayApiSchema = z.object({
  "england-and-wales": z.object({
    division: z.string(),
    events: z.array(eventsSchema),
  }),
  scotland: z.object({
    division: z.string(),
    events: z.array(eventsSchema),
  }),
  "northern-ireland": z.object({
    division: z.string(),
    events: z.array(eventsSchema),
  }),
});

type BankHoliday = z.infer<typeof eventsSchema>;
