import { ExceptionReportStatusEnum } from "../../../../baseCODEGEN";
import * as Styled from "./ExceptionReportStatus.styled";

interface Props {
  status: ExceptionReportStatusEnum;
}
export const ExceptionReportStatus = ({ status }: Props) => {
  return (
    <Styled.StatusContainer status={status}>
      {formatStatus(status)}
    </Styled.StatusContainer>
  );
};

const formatStatus = (status: ExceptionReportStatusEnum) => {
  if (status === ExceptionReportStatusEnum.PendingDispute) {
    return status.replace("_", " ");
  }

  if (status === ExceptionReportStatusEnum.RejectedDispute) {
    return ExceptionReportStatusEnum.Rejected;
  }

  return status;
};
