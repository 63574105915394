import { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import AgencyCards from "components/Agencies/AgencyCards";
import AgencyModal from "components/Agencies/AgencyModal";
import Layout from "components/Layout";
import { AppContext } from "context/AppContext";
import { VMS_WORKER_REGISTRATION } from "gql/Queries";

const AgenciesPage = () => {
  const store = useContext(AppContext);
  const [modalShow, setModalShow] = useState(false);
  const [profile, setProfile] = useState(false);
  const [skip, setSkip] = useState(true);

  // Functions ****************************************************

  /*
      1. Once new Vms worker logs in, they are directed to agencyPage,
      2. They will see a modal of profile info that the agency has set for them.
      3. Then once happy, they will have the ability to join
      4. Profile boolean, If true we will get the profile from the agency else normal join agencyModal
      accountActivated: false
      agencyId: 112
      agencyOnboarding: true
      vmsToken: "pYMaJ4q4oFHJqFrXG/I4aDnFpOZbNvpeyBAklwCES9+4owePFAydVgbrcY2664lfAcdUWePjM22AD5KaTNYl4eI=--XWfYWQkDInRcuWZ/--8859Wk3PAp+d8z/2s9Zq1g=="
    */

  // Query to get details from Portal **********************

  useQuery(VMS_WORKER_REGISTRATION, {
    skip,
    fetchPolicy: "no-cache",
    variables: {
      agencyRegistrationId: store?.agencyRegistrationId,
    },
    onCompleted: (res) => {
      if (res?.vmsWorkerRegistration?.nodes[0]) {
        setProfile(res.vmsWorkerRegistration.nodes[0]);
        setModalShow(true);
      }
    },
    onError: () => {},
  });

  // If we have forced set AgencyOnboarding in global set we will auto fire off Query to get registration details

  useEffect(() => {
    if (store.agencyOnboarding) {
      setSkip(false);
    }
  }, [store.agencyOnboarding]);

  const closeModal = () => {
    setModalShow(false);
  };

  const openModal = (agency) => {
    setModalShow(true);
    setProfile(agency);
    store.setAgencyRegistrationId(Number(agency?.id));
  };

  return (
    <Layout headerValueText="Agencies">
      <AgencyCards
        agencies={store?.user?.agencyRegistrations}
        openModal={openModal}
        closeModal={closeModal}
      />

      <AgencyModal profile={profile} show={modalShow} onHide={closeModal} />
    </Layout>
  );
};

export default AgenciesPage;
