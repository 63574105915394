import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import { GREY } from "@patchworkhealth/web-components";

import { RosteringLeaveEntitlementsQuery } from "components/LeaveManagement/__generated__/LeaveManagement.generated";
import { LeaveEntitlementType } from "components/LeaveManagement/LeaveEntitlementModal/LeaveEntitlementModal.types";
import { ChevronIcon } from "components/Navigation/NavIcons";

import { NoEntitlements } from "./NoEntitlements";
import { RequestLeaveEntitlement } from "./RequestLeaveEntitlement";

type RosteringLeaveEntitlementsType = NonNullable<
  RosteringLeaveEntitlementsQuery["rosteringLeaveEntitlements"]
>;

interface Props {
  activeRosteringLeaveEntitlements: RosteringLeaveEntitlementsType;
  pastRosteringLeaveEntitlements: RosteringLeaveEntitlementsType;
  onOpenEntitlement: (leaveEntitlement: LeaveEntitlementType) => void;
  isExpiredOpen: boolean;
  onPastEntitlementOpen: () => void;
  loading: boolean;
}

export const RequestLeaveEntitlementsList = ({
  activeRosteringLeaveEntitlements,
  pastRosteringLeaveEntitlements,
  onOpenEntitlement,
  isExpiredOpen,
  onPastEntitlementOpen,
  loading,
}: Props) => {
  return (
    <>
      <LeaveEntitlementsList
        leaveEntitlements={activeRosteringLeaveEntitlements}
        onOpenEntitlement={onOpenEntitlement}
        loading={loading && !isExpiredOpen}
      />
      <PastEntitlementsButton
        type="button"
        isOpen={isExpiredOpen}
        onClick={onPastEntitlementOpen}
      >
        Past Entitlements <ChevronIcon />
      </PastEntitlementsButton>
      {isExpiredOpen && (
        <LeaveEntitlementsList
          leaveEntitlements={pastRosteringLeaveEntitlements}
          onOpenEntitlement={onOpenEntitlement}
          loading={loading && isExpiredOpen}
          expired
        />
      )}
    </>
  );
};

const LeaveEntitlementsList = ({
  leaveEntitlements,
  expired,
  onOpenEntitlement,
  loading,
}: {
  leaveEntitlements: RosteringLeaveEntitlementsType;
  loading: boolean;
  onOpenEntitlement: (leaveEntitlement: LeaveEntitlementType) => void;
  expired?: boolean;
}) => {
  if (loading) return <LoadingSkeletons />;
  if (!leaveEntitlements.length && !loading)
    return <NoEntitlements expired={expired} />;

  return (
    <LeaveEntitlementsListContainer>
      {leaveEntitlements.map((entitlement) => (
        <RequestLeaveEntitlement
          key={entitlement.id}
          onClick={() => onOpenEntitlement(entitlement)}
          expired={expired}
          {...entitlement}
        />
      ))}
    </LeaveEntitlementsListContainer>
  );
};

const LoadingSkeletons = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      {[...Array(3).keys()].map((v) => (
        <Skeleton height={75} width={888} key={v} />
      ))}
    </div>
  );
};

const LeaveEntitlementsListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  transition: 500ms ease-out;
`;

const PastEntitlementsButton = styled.button<{ isOpen: boolean }>`
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  color: ${GREY.eight};
  gap: 10px;
  margin: 20px 0;
  width: fit-content;

  svg {
    transition-duration: 0.5s;
    transition-property: transform;
    transform: ${({ isOpen }) => (isOpen ? "rotate(-90deg)" : "rotate(90deg)")};
  }
`;
