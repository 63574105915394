import { memo, ReactNode } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import ReactNotification from "react-notifications-component";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import dayjs from "dayjs";
import advancedFormatPLugin from "dayjs/plugin/advancedFormat";
import isBetween from "dayjs/plugin/isBetween";
import isoWeek from "dayjs/plugin/isoWeek";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import relativeTimePlugin from "dayjs/plugin/relativeTime";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";

import { GlobalStyle } from "components/Style";
import AppContextProvider from "context/AppContext";

import { client } from "./apolloClient";
import history from "./helpers/functions";

dayjs.extend(relativeTimePlugin);
dayjs.extend(advancedFormatPLugin);
dayjs.extend(isBetween);
dayjs.extend(isoWeek);
dayjs.extend(isSameOrBefore);

const queryClient = new QueryClient();

interface Props {
  children?: ReactNode;
  test?: boolean;
}

export const GlobalWrappers = memo(({ children, test = true }: Props) => (
  <>
    <GlobalStyle />
    <Toaster toastOptions={toastOptions} />
    <ReactNotification />
    <HelmetProvider>
      <ApolloProvider client={client}>
        <QueryClientProvider client={queryClient}>
          <Router history={history}>
            <AppContextProvider test={test}>
              <Helmet>
                <link
                  rel="stylesheet"
                  href="https://use.typekit.net/hse5ekh.css"
                />
              </Helmet>
              <QueryParamProvider adapter={ReactRouter5Adapter}>
                {children}
              </QueryParamProvider>
            </AppContextProvider>
          </Router>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ApolloProvider>
    </HelmetProvider>
  </>
));

const toastOptions = {
  success: {
    duration: process.env.NODE_ENV === "test" ? 100 : 5000,
  },
  error: {
    duration: process.env.NODE_ENV === "test" ? 100 : 6500,
  },
};
