import styled from "styled-components";

import {
  BLUE,
  GREY,
  ORANGE,
  RBRED,
  RED,
} from "@patchworkhealth/web-components";

import { exhaustiveMatchingGuard } from "helpers/exhaustiveMatchingGuard";

import {
  RosteringLeaveRequestStatusEnum,
  RosteringLeaveTypeEnum,
} from "../../../baseCODEGEN";

export const LeaveRequestContainer = styled.button<{
  leaveType: RosteringLeaveTypeEnum;
  leaveStatus: RosteringLeaveRequestStatusEnum;
}>`
  ${({ leaveType, leaveStatus }) => {
    const [firstColor, secondColor] = backgroundColors(leaveType);

    return leaveStatus === RosteringLeaveRequestStatusEnum.Approved
      ? `background: ${firstColor};`
      : `background-image: repeating-linear-gradient(
          -65deg,
          ${secondColor},
          ${secondColor} 15px,
          ${firstColor} 15px,
          ${firstColor} 30px
         );`;
  }}
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;

  min-height: 13px;
  max-height: 51px;
  height: 41px;
  width: 100%;
  min-width: 132px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  p {
    font-size: 0.625rem;
    color: ${({ leaveType }) => textColors(leaveType)};
    text-transform: capitalize;
    font-weight: 500;
    margin-top: 2px;
  }
`;

const backgroundColors = (leaveType: RosteringLeaveTypeEnum) => {
  switch (leaveType) {
    case RosteringLeaveTypeEnum.Annual:
      return [ORANGE.one, "#FFF2EC"];
    case RosteringLeaveTypeEnum.Study:
      return [BLUE.one, "#E8F9FB"];
    case RosteringLeaveTypeEnum.Sick:
      return [RBRED.one];
    case RosteringLeaveTypeEnum.Paid:
    case RosteringLeaveTypeEnum.Special:
      return [GREY.one, GREY.two];
    default:
      return exhaustiveMatchingGuard(leaveType);
  }
};

const textColors = (leaveType: RosteringLeaveTypeEnum) => {
  switch (leaveType) {
    case RosteringLeaveTypeEnum.Annual:
      return ORANGE.six;
    case RosteringLeaveTypeEnum.Study:
      return BLUE.seven;
    case RosteringLeaveTypeEnum.Sick:
      return RED.five;
    case RosteringLeaveTypeEnum.Paid:
    case RosteringLeaveTypeEnum.Special:
      return GREY.seven;
    default:
      return exhaustiveMatchingGuard(leaveType);
  }
};
