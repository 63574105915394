import { useContext, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";
import styled from "styled-components";

import { AppContext } from "context/AppContext";
import { DELETE_DOC } from "gql/Mutations";
import { CloseModal } from "icons/referencesIcons";

import { Divider, StyledButton } from "../Style";

const DocumentsConfirm = ({ onHide, document, show, refetch }) => {
  const store = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [deleteDoc] = useMutation(DELETE_DOC);

  const deleteDocument = () => {
    const toastId = toast.loading("Loading...");

    deleteDoc({
      variables: {
        id: document?.id,
      },
    })
      .then(() => {
        onHide();
        refetch();
        store.fetchUser();
        toast.success("Document Deleted!", { id: toastId });
      })
      .catch(() => {
        setLoading(false);

        toast.error("There was an error!", { id: toastId });

        window.location.reload();
      });
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered size="lg">
        <Modal.Header>
          <Modal.Title>Delete Document</Modal.Title>
          <button className="btn btn-cyan" onClick={onHide}>
            <CloseModal />
          </button>
        </Modal.Header>
        <ModalBody>
          <div style={{ textAlign: "left", paddingTop: "32px" }}>
            Are you sure you want to remove this document from your profile?
          </div>

          <Divider top="51" bottom="32" line="solid" />

          <ModalFooter>
            {loading && (
              <Spinner
                style={{ position: "absolute", left: "50px" }}
                animation="border"
              />
            )}

            <StyledButton
              color="white"
              size="small"
              length="90px"
              onClick={() => onHide()}
              className="modal__footer_cancel"
            >
              Cancel
            </StyledButton>

            <StyledButton
              onClick={deleteDocument}
              color="red"
              size="small"
              length="133px"
            >
              Yes, Delete it
            </StyledButton>
          </ModalFooter>
        </ModalBody>
      </Modal>
    </>
  );
};

export default DocumentsConfirm;

const ModalBody = styled.div`
  padding: 25px 32px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-right: 8px;
  }

  @media (max-width: 468px) {
    flex-direction: column;
    text-align: center;
    button {
      font-size: 12px;
      margin-bottom: 10px;
      width: 100%;
    }
  }
`;
