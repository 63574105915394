import { Dispatch, PropsWithChildren, SetStateAction } from "react";
import * as Popover from "@radix-ui/react-popover";

import {
  LeaveRecordsFiltersType,
  LeaveRecordsOptionsType,
} from "components/LeaveManagement/LeaveRecordTable/LeaveRecordTable.types";
import { ChevronDownIcon } from "icons/chevronDownIcon";

import FiltersMenuSingle from "../Filters/FiltersMenuSingle";
import { GridHeaderTitle } from "./LeaveGridHeader.styled";

type Props<TFilter, TOptions> =
  | {
      filter: TFilter;
      property: keyof TFilter;
      setFilter: Dispatch<SetStateAction<TFilter>>;
      options: TOptions;
    }
  | { noFilters: boolean };

const LeaveGridHeader = <
  TFilter extends LeaveRecordsFiltersType,
  TOptions extends LeaveRecordsOptionsType<TOptions[number]["value"]>
>({
  children,
  ...props
}: PropsWithChildren<Props<TFilter, TOptions>>) => {
  if ("noFilters" in props) {
    return <GridHeaderTitle>{children}</GridHeaderTitle>;
  }

  const { filter, property, setFilter, options } = props;
  return (
    <Popover.Root>
      <GridHeaderTitle as={Popover.Trigger}>
        {children} <ChevronDownIcon />
      </GridHeaderTitle>
      <FiltersMenuSingle
        as={Popover.Content}
        align="start"
        options={options}
        value={filter[property]}
        onChange={(value) =>
          setFilter((prev) => ({ ...prev, [property]: value }))
        }
      />
    </Popover.Root>
  );
};

export default LeaveGridHeader;
