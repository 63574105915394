import { useContext } from "react";

import { SupervisorFragment } from "context/__generated__/AppContext.generated";
import { AppContext } from "context/AppContext";

type UseSupervisorsReturnType = {
  supervisorsWithCategory: SupervisorFragment[];
  allSupervisors: SupervisorFragment[];
  hasSupervisors: boolean;
  hasSupervisorsForCategory: boolean;
};

export const useSupervisors = (
  categoryName: string | undefined | null,
  tier?: "tier1" | "tier2"
): UseSupervisorsReturnType => {
  const { user: currentWorker } = useContext(AppContext);

  if (!currentWorker)
    return {
      supervisorsWithCategory: [],
      allSupervisors: [],
      hasSupervisors: false,
      hasSupervisorsForCategory: false,
    };

  const { workersSupervisors, rosteringOrganisations } = currentWorker;

  const allSupervisors = [
    ...(workersSupervisors?.map(({ supervisor }) => supervisor) ?? []),
    ...(rosteringOrganisations?.[0].rosteringExceptionReportReasonCategories?.flatMap(
      ({ exceptionReportSupervisorCategories }) =>
        (exceptionReportSupervisorCategories ?? [])?.map(
          ({ supervisor }) => supervisor
        )
    ) ?? []),
  ] as SupervisorFragment[];

  //filters supervisors from workersSupervisors, that supervise categoryName
  const categorySupervisors = workersSupervisors
    ?.filter(({ supervisorsCategories }) =>
      supervisorsCategories.some((exceptionReportCategory) => {
        //will filter supervisors based on they tier
        if (tier) {
          return (
            exceptionReportCategory[tier] &&
            exceptionReportCategory.exceptionReportCategory?.name ===
              categoryName
          );
        }
        return exceptionReportCategory.exceptionReportCategory?.name;
      })
    )
    .map(({ supervisor }) => supervisor);

  const categoryWithSupervisors =
    rosteringOrganisations?.[0].rosteringExceptionReportReasonCategories?.find(
      ({ name }) => name === categoryName
    );
  //filters supervisors that supervise allWorkers from rosteringOrganisations
  const supervisorsWithAllWorkersPermissions =
    categoryWithSupervisors?.exceptionReportSupervisorCategories
      ?.filter((supervisorsCategory) => {
        //will filter supervisors based on they tier
        if (tier) {
          return supervisorsCategory[tier];
        }
        return true;
      })
      .map(({ supervisor }) => supervisor);

  const supervisorsWithCategory = [
    ...(categorySupervisors ?? []),
    ...(supervisorsWithAllWorkersPermissions ?? []),
  ] as SupervisorFragment[];

  return {
    //all supervisors with category and tiers if provided
    supervisorsWithCategory,
    //all supervisors, category and tiers not filtered
    allSupervisors,
    //checks if user has any supervisors
    hasSupervisors: allSupervisors.length > 0,
    //checks if user has any supervisors for provided category
    hasSupervisorsForCategory:
      !categoryName || supervisorsWithCategory.length > 0,
  };
};
