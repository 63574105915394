import { useContext, useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";

import DocumentsAlert from "components/Documents/DocumentsAlert";
import Layout from "components/Layout";
import { useActivitiesAndAlsoWorkingOnShift } from "components/Schedule/hooks/useServicePlanActivitiesForShiftModal";
import Schedule from "components/Schedule/index";
import { ShiftModal } from "components/Shared/ShiftModal/ShiftModal";
import { ShiftsModal } from "components/ShiftsNew/ShiftsModal";
import { TimesheetCardFragment } from "components/Timesheets/__generated__/Timesheets.generated";
import { AppContext } from "context/AppContext";
import { AppState } from "context/AppContext.types";
import { BOOKED_SHIFTS, ROSTERING_EVENTS_QUERY_OLD } from "gql/Queries";
import { ProductType } from "helpers/productType";
import { Shift, SortedShifts, sortShifts, timeFormat } from "helpers/rostering";

const SchedulesPage = () => {
  // State *****************************************
  const store: AppState = useContext(AppContext);

  const [sortedShifts, setSortedShifts] = useState<SortedShifts | null>(null);

  const [show, setShow] = useState(false);
  const [checkbox, setCheckbox] = useState(1);
  const [modalData, setModalData] = useState<TimesheetCardFragment | undefined>(
    undefined
  );
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [modalType, setModalType] = useState<Shift | null>(null);

  const [activityIds, setActivityIds] = useState<string[]>([]);

  const fromDay = new Date();

  const [modalShow, setModalShow] = useState(false);

  const closeModal = () => {
    setModalShow(false);
  };

  // Queries ***************************************
  const [getBankShifts, { data: bankData, loading: loading1 }] = useLazyQuery(
    BOOKED_SHIFTS,
    {
      variables: {
        fromStartTime:
          endDate === null
            ? timeFormat(fromDay, "y")
            : timeFormat(endDate, "y"),
      },
    }
  );

  const { data: rosterData, loading: loading2 } = useQuery(
    ROSTERING_EVENTS_QUERY_OLD,
    {
      variables: {
        startsAtFrom:
          endDate === null
            ? timeFormat(fromDay, "y")
            : timeFormat(endDate, "y"),
      },
    }
  );

  const eventIds: number[] =
    rosterData?.rosteringEvents.nodes.map(
      (rosteredShift: Shift) => rosteredShift.id
    ) ?? [];

  const {
    activitiesForShift,
    activitiesLabelForShift,
    eventIdsForActivities,
    activityNames,
    alsoWorkingOnThisShift,
  } = useActivitiesAndAlsoWorkingOnShift({ eventIds });

  useEffect(() => {
    if (store?.productType === ProductType.Rota) {
      setCheckbox(3);
      // getRosterShifts();
    }

    if (store?.productType === ProductType.BankAndRota) {
      getBankShifts();
    }
  }, []);

  useEffect(() => {
    if (store.productType === ProductType.Rota) {
      rosterData &&
        setSortedShifts(
          sortShifts(
            {
              appliedShifts: [],
              bookedShifts: [],
            },
            rosterData,
            activityIds,
            eventIdsForActivities,
            activityNames
          )
        );
    }

    if (store.productType === ProductType.BankAndRota) {
      bankData &&
        rosterData &&
        setSortedShifts(
          sortShifts(
            bankData,
            rosterData,
            activityIds,
            eventIdsForActivities,
            activityNames
          )
        );
    }
  }, [
    bankData,
    rosterData,
    store.productType,
    activityNames.length,
    activityIds.length,
  ]);

  // Functions *************************************

  const handleClose = () => {
    setShow(false);
    setModalData(undefined);
  };

  const setModalDataFunc = (shift: TimesheetCardFragment) => {
    setModalData(shift);
  };

  const handleShow = () => setShow(true);

  const handleEndDate = (event: Date | null) => {
    if (event === null) {
      setEndDate(null);
      getBankShifts();
    } else {
      setEndDate(event);
      getBankShifts();
    }
  };

  const openRosteringModal = (shift: Shift) => {
    setModalType(shift);
  };

  const closeRosterModal = () => {
    setModalType(null);
  };

  useEffect(() => {
    if (store?.user?.rightToWork.length === 0) {
      setModalShow(true);
    }
  }, [store]);

  return (
    <Layout headerValueText="My Schedule">
      <DocumentsAlert show={modalShow} onHide={closeModal} />

      <Schedule
        loading1={loading1 || loading2}
        store={store}
        openRosteringModal={openRosteringModal}
        endDate={endDate}
        handleEndDate={handleEndDate}
        checkbox={checkbox}
        setActivityIds={setActivityIds}
        activitiesLabelForShift={activitiesLabelForShift}
        setCheckbox={setCheckbox}
        shifts={sortedShifts}
        handleShow={handleShow}
        setModalDataFunc={setModalDataFunc}
      />

      {modalData && (
        <ShiftsModal
          handleClose={handleClose}
          openModal={show}
          selectedShift={modalData}
        />
      )}

      {modalType && (
        <ShiftModal
          eventId={+modalType.id}
          onHide={closeRosterModal}
          activities={activitiesForShift(+modalType.id)}
          activitiesLabelForShift={activitiesLabelForShift}
          alsoWorkingOnThisShift={alsoWorkingOnThisShift(+modalType.id)}
        />
      )}
    </Layout>
  );
};

export default SchedulesPage;
