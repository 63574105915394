import { Fragment, memo } from "react";
import styled from "styled-components";

import { GREY } from "@patchworkhealth/web-components";

import { MissedShiftsFragment } from "components/LeaveManagement/__generated__/LeaveManagement.generated";
import { ShiftIcon } from "components/Shared/ShiftIcon";
import { formatTimeInTZ } from "helpers/momentHelpers";

interface Props {
  missedShifts: MissedShiftsFragment[];
}

export const MissedShifts = memo(({ missedShifts }: Props) => {
  const sortedMissedShifts = (() => {
    const rotaList = missedShifts.map((missedShift) => ({
      rota: missedShift.schedule?.roster?.rota.name,
    }));
    const filteredRotas = [
      ...new Map(rotaList.map((item) => [item["rota"], item])).values(),
    ];

    return filteredRotas.map((filteredRota) => ({
      rota: filteredRota.rota,
      shifts: missedShifts.filter(
        (missedShift) =>
          missedShift.schedule?.roster?.rota?.name === filteredRota.rota
      ),
    }));
  })();

  return (
    <RotaWrapper>
      {sortedMissedShifts?.map(({ rota, shifts }, index) => (
        <Fragment key={index}>
          <RotaName>
            Roster: <strong>{rota}</strong>
          </RotaName>

          {shifts.map((shift) => (
            <MissedShift key={shift.id}>
              <MissedShiftDate>
                {formatTimeInTZ(shift?.startsAt, "ddd, D MMM")}
              </MissedShiftDate>

              <ShiftIcon type={shift?.scheduleable?.shiftType?.name} />
              <p>
                {`${formatTimeInTZ(
                  shift?.startsAt,
                  "HH:mm"
                )} - ${formatTimeInTZ(shift?.endsAt, "HH:mm")}`}
              </p>

              <span>{shift?.scheduleable?.organisationActivity?.name}</span>
            </MissedShift>
          ))}
        </Fragment>
      ))}
    </RotaWrapper>
  );
});

const RotaWrapper = styled.div`
  border-radius: 6px;
  height: auto;
  overflow-y: auto;
`;

const RotaName = styled.h2`
  color: ${GREY.ten};
  font-size: 12px;
  margin: 16px 0 12px;
  strong {
    font-weight: 600;
  }
`;

const MissedShift = styled.div`
  align-items: center;
  background: ${GREY.one};
  border-radius: 4px;
  color: ${GREY.ten};
  display: flex;
  font-size: 13px;
  height: 40px;
  margin-bottom: 6px;
  overflow: hidden;
  padding: 5px 33px;
  position: relative;
  * {
    margin: 0;
  }
  svg {
    height: 25px;
    margin-right: 11px;
    width: 25px;
  }

  span {
    padding-left: 50px;
  }
`;

const MissedShiftDate = styled.h2`
  font-size: 13px;
  font-weight: 600;
  margin-right: 18px;
  width: 100px;
`;
