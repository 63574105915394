/* eslint-disable */
import { useState, useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";

import Layout from "components/Layout";

import DocumentsList, {
  DocumentsConfirm,
  DocumentsHeader,
  DocumentsModal,
} from "components/Documents";

import { GET_DOCTYPES } from "gql/Queries";
import { ADD_DOC } from "gql/Mutations";
import { AppContext } from "context/AppContext";
import { convertToBase64, resetInputs } from "helpers/functions";

const Documents = () => {
  // State *****************************************

  const store = useContext(AppContext);

  const [modalShow, setModalShow] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const [Document, setDocument] = useState(false);
  const [details, setDetails] = useState("");
  const [value, setValue] = useState("");
  const [awaitingDoc, setAwaitingDoc] = useState("");

  // Graphql ************************************

  const { data, refetch } = useQuery(GET_DOCTYPES);
  const [addDoc] = useMutation(ADD_DOC);

  // Functions ********************************

  const closeModal = () => {
    setAwaitingDoc("");
    setModalShow(false);
    setConfirmShow(false);
    setDocument(false);
    setDetails("");
  };

  const closeConfirm = () => {
    setAwaitingDoc("");
    setConfirmShow(false);
    setDocument(false);
    setDetails("");
  };

  const deleteDocument = (doc) => {
    setDocument(doc);
    setConfirmShow(true);
  };

  const onChangePicture = async (e, type) => {
    e.persist();
    const file = e.target.files[0];

    let base64result;

    let details = {
      type,
      file,
    };

    let typesObject = {
      documentNumberVisibility: false,
      documentTitleVisibility: false,
      expirationDateVisibility: false,
      issueDateVisibility: false,
      issuerVisibility: false,
      qualificationNameVisibility: false,
    };

    // Todo - If the type === 2 this means BE requires this field to be completed by user.
    for (let x in type) {
      for (let y in typesObject) {
        if (x === y) {
          typesObject[y] = type[x];
        }
      }
    }

    // Here we check if the document type requires extra details, if so we open the modal.
    let exists = [1, 2].some((i) => Object.values(typesObject).includes(i));

    if (exists) {
      setModalShow(true);
      setDocument(typesObject);
      setDetails(details);
      return;
    }

    const toastId = toast.loading("Loading...");

    try {
      const base64 = await convertToBase64(file);

      if (base64) {
        base64result = base64.split(",")[1];

        addDoc({
          variables: {
            file: base64result,
            fileName: e.target.files[0].name,
            documentTypeId: type.id,
          },
        })
          .then((res) => {
            window.scrollTo(0, 0);
            const errors = res.data?.addWorkerUpload?.errors;

            resetInputs();

            if (errors.length > 0) {
              toast.error(
                "We can’t accept that file type. Please try uploading one of the following file types: JPEG, PNG, etc",
                {
                  id: toastId,
                }
              );
              return;
            }

            refetch();
            store.fetchUser();
            toast.success("Document Uploaded!", { id: toastId });
          })
          .catch((err) => {
            toast.error("There was an error!", { id: toastId });
            window.location.reload();
          });
      }
    } catch (err) {
      toast.error("There was an error!", { id: toastId });
      window.location.reload();
    }
  };

  // search function *********************************************

  const filteredDocs = value
    ? data?.documentTypes.filter((item) => {
        return item.title.toLowerCase().includes(value.toLowerCase());
      })
    : data?.documentTypes;

  return (
    <Layout headerValueText="Documents Upload" navigation="profile">
      <div style={{ marginTop: "24px", maxWidth: "980px" }}>
        <DocumentsHeader
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />

        <DocumentsList
          filteredDocs={filteredDocs}
          deleteDocument={deleteDocument}
          onChangePicture={onChangePicture}
          awaitingDoc={awaitingDoc}
        />
      </div>

      <DocumentsModal
        show={modalShow}
        onHide={closeModal}
        document={Document}
        details={details}
        refetch={refetch}
      />

      <DocumentsConfirm
        show={confirmShow}
        onHide={closeConfirm}
        document={Document}
        refetch={refetch}
      />
    </Layout>
  );
};

export default Documents;
