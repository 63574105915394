export const UploadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
      stroke="#9CA5B0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 8L12 3L7 8"
      stroke="#9CA5B0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 3V15"
      stroke="#9CA5B0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DownloadIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <path
        d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83341 8.33333L10.0001 12.5L14.1667 8.33333"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 12.5L10 2.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export const CloseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke="#DC5A54"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 9L9 15"
      stroke="#DC5A54"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 9L15 15"
      stroke="#DC5A54"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FileCheck = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 8.94C19.9896 8.84813 19.9695 8.75763 19.94 8.67V8.58C19.8919 8.47718 19.8278 8.38267 19.75 8.3L13.75 2.3C13.6673 2.22222 13.5728 2.15808 13.47 2.11C13.4402 2.10576 13.4099 2.10576 13.38 2.11C13.2784 2.05174 13.1662 2.01434 13.05 2H7C6.20435 2 5.44129 2.31607 4.87868 2.87868C4.31607 3.44129 4 4.20435 4 5V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V9C20 9 20 9 20 8.94ZM14 5.41L16.59 8H15C14.7348 8 14.4804 7.89464 14.2929 7.70711C14.1054 7.51957 14 7.26522 14 7V5.41ZM18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V5C6 4.73478 6.10536 4.48043 6.29289 4.29289C6.48043 4.10536 6.73478 4 7 4H12V7C12 7.79565 12.3161 8.55871 12.8787 9.12132C13.4413 9.68393 14.2044 10 15 10H18V19ZM14.29 12.29L11 15.59L9.71 14.29C9.5217 14.1017 9.2663 13.9959 9 13.9959C8.7337 13.9959 8.4783 14.1017 8.29 14.29C8.1017 14.4783 7.99591 14.7337 7.99591 15C7.99591 15.2663 8.1017 15.5217 8.29 15.71L10.29 17.71C10.383 17.8037 10.4936 17.8781 10.6154 17.9289C10.7373 17.9797 10.868 18.0058 11 18.0058C11.132 18.0058 11.2627 17.9797 11.3846 17.9289C11.5064 17.8781 11.617 17.8037 11.71 17.71L15.71 13.71C15.8983 13.5217 16.0041 13.2663 16.0041 13C16.0041 12.7337 15.8983 12.4783 15.71 12.29C15.5217 12.1017 15.2663 11.9959 15 11.9959C14.7337 11.9959 14.4783 12.1017 14.29 12.29Z"
      fill="#316959"
    />
  </svg>
);

export const DocumentIcon = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 18H5C4.20435 18 3.44129 17.6839 2.87868 17.1213C2.31607 16.5587 2 15.7956 2 15V5C2 4.73478 1.89464 4.48043 1.70711 4.29289C1.51957 4.10536 1.26522 4 1 4C0.734784 4 0.48043 4.10536 0.292893 4.29289C0.105357 4.48043 0 4.73478 0 5V15C0 16.3261 0.526784 17.5979 1.46447 18.5355C2.40215 19.4732 3.67392 20 5 20H13C13.2652 20 13.5196 19.8946 13.7071 19.7071C13.8946 19.5196 14 19.2652 14 19C14 18.7348 13.8946 18.4804 13.7071 18.2929C13.5196 18.1054 13.2652 18 13 18ZM7 11C7 11.2652 7.10536 11.5196 7.29289 11.7071C7.48043 11.8946 7.73478 12 8 12H13C13.2652 12 13.5196 11.8946 13.7071 11.7071C13.8946 11.5196 14 11.2652 14 11C14 10.7348 13.8946 10.4804 13.7071 10.2929C13.5196 10.1054 13.2652 10 13 10H8C7.73478 10 7.48043 10.1054 7.29289 10.2929C7.10536 10.4804 7 10.7348 7 11ZM18 6.94C17.9896 6.84813 17.9695 6.75763 17.94 6.67V6.58C17.8919 6.47718 17.8278 6.38267 17.75 6.3L11.75 0.3C11.6673 0.222216 11.5728 0.158081 11.47 0.11C11.4402 0.10576 11.4099 0.10576 11.38 0.11C11.2784 0.0517412 11.1662 0.0143442 11.05 0H7C6.20435 0 5.44129 0.316071 4.87868 0.87868C4.31607 1.44129 4 2.20435 4 3V13C4 13.7956 4.31607 14.5587 4.87868 15.1213C5.44129 15.6839 6.20435 16 7 16H15C15.7956 16 16.5587 15.6839 17.1213 15.1213C17.6839 14.5587 18 13.7956 18 13V7C18 7 18 7 18 6.94ZM12 3.41L14.59 6H13C12.7348 6 12.4804 5.89464 12.2929 5.70711C12.1054 5.51957 12 5.26522 12 5V3.41ZM16 13C16 13.2652 15.8946 13.5196 15.7071 13.7071C15.5196 13.8946 15.2652 14 15 14H7C6.73478 14 6.48043 13.8946 6.29289 13.7071C6.10536 13.5196 6 13.2652 6 13V3C6 2.73478 6.10536 2.48043 6.29289 2.29289C6.48043 2.10536 6.73478 2 7 2H10V5C10.0027 5.34114 10.0636 5.67933 10.18 6H8C7.73478 6 7.48043 6.10536 7.29289 6.29289C7.10536 6.48043 7 6.73478 7 7C7 7.26522 7.10536 7.51957 7.29289 7.70711C7.48043 7.89464 7.73478 8 8 8H16V13Z"
      fill="#55606C"
    />
  </svg>
);

export const SearchIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4733 12.5266L10.9999 10.0733C11.96 8.87621 12.4249 7.3568 12.2991 5.82747C12.1733 4.29814 11.4664 2.87512 10.3236 1.85103C9.18085 0.82694 7.68914 0.27961 6.15522 0.321583C4.6213 0.363557 3.16175 0.991644 2.0767 2.0767C0.991644 3.16175 0.363557 4.6213 0.321583 6.15522C0.27961 7.68914 0.82694 9.18085 1.85103 10.3236C2.87512 11.4664 4.29814 12.1733 5.82747 12.2991C7.3568 12.4249 8.87621 11.96 10.0733 10.9999L12.5266 13.4533C12.5886 13.5158 12.6623 13.5654 12.7436 13.5992C12.8248 13.633 12.9119 13.6505 12.9999 13.6505C13.0879 13.6505 13.1751 13.633 13.2563 13.5992C13.3376 13.5654 13.4113 13.5158 13.4733 13.4533C13.5934 13.329 13.6606 13.1628 13.6606 12.9899C13.6606 12.817 13.5934 12.6509 13.4733 12.5266ZM6.33327 10.9999C5.41029 10.9999 4.50804 10.7262 3.74061 10.2135C2.97318 9.70068 2.37504 8.97185 2.02183 8.11913C1.66862 7.2664 1.57621 6.32809 1.75627 5.42285C1.93634 4.5176 2.38079 3.68608 3.03344 3.03344C3.68608 2.38079 4.5176 1.93634 5.42285 1.75627C6.32809 1.57621 7.2664 1.66862 8.11913 2.02183C8.97185 2.37504 9.70068 2.97318 10.2135 3.74061C10.7262 4.50804 10.9999 5.41029 10.9999 6.33327C10.9999 7.57095 10.5083 8.75793 9.6331 9.6331C8.75793 10.5083 7.57095 10.9999 6.33327 10.9999Z"
      fill="#646E7B"
    />
  </svg>
);

export const DocumentsModalIcon = () => (
  <svg
    width="217"
    height="157"
    viewBox="0 0 217 157"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M104.813 3.72461C83.6167 3.72461 66.4339 15.1649 66.4339 29.2772C66.4339 35.5784 69.8617 41.3458 75.5408 45.801V64.3468L87.7067 52.1554C93.2304 53.953 99.0051 54.8558 104.813 54.8297C126.009 54.8297 143.192 43.3895 143.192 29.2772C143.192 15.1649 126.009 3.72461 104.813 3.72461Z"
        fill="#E4E7EB"
      />
      <path
        d="M1.79711 156.664H213.182"
        stroke="#002033"
        strokeMiterlimit="10"
      />
      <path
        d="M107.525 0.101562C86.3294 0.101562 69.1466 11.5418 69.1466 25.6541C69.1466 31.9554 72.5743 37.7227 78.2534 42.178V60.7237L90.4193 48.5323C95.9431 50.33 101.718 51.2328 107.525 51.2067C128.721 51.2067 145.904 39.7664 145.904 25.6541C145.904 11.5418 128.721 0.101562 107.525 0.101562Z"
        stroke="#002033"
        strokeMiterlimit="10"
      />
      <path
        d="M90.8497 66.219C90.8497 66.219 90.0777 55.8007 90.4412 53.8952C90.8046 51.9897 91.1461 46.5658 89.1476 48.3487C87.1492 50.1317 87.8592 54.177 87.8592 54.177C87.8592 54.177 85.9628 65.1876 87.7167 66.6813C89.4707 68.1751 90.8497 66.219 90.8497 66.219Z"
        fill="#A0616A"
      />
      <path
        d="M92.289 76.5221C92.289 76.5221 86.3406 71.2057 86.6621 66.6947C86.6621 66.6947 90.199 63.9559 91.0029 65.4059C91.8067 66.8558 97.6496 72.5124 96.6574 74.4367C95.6652 76.361 92.289 76.5221 92.289 76.5221Z"
        fill="#17BFD6"
      />
      <path
        d="M113.35 67.0154C113.35 67.0154 116.887 57.188 117.047 55.2548C117.208 53.3215 118.334 48.005 119.78 50.2605C121.227 52.516 119.459 56.2214 119.459 56.2214C119.459 56.2214 118.334 67.3377 116.244 68.3043C114.154 69.2709 113.35 67.0154 113.35 67.0154Z"
        fill="#A0616A"
      />
      <path
        d="M103.704 116.316H113.189L113.671 121.15L111.42 121.633C111.42 121.633 106.115 120.022 103.061 120.505C100.006 120.988 103.704 116.316 103.704 116.316Z"
        fill="#A0616A"
      />
      <path
        d="M109.974 115.67C109.974 115.67 114.475 112.609 115.279 115.187C116.083 117.764 118.977 127.109 118.012 128.075C117.047 129.042 115.922 127.27 115.922 127.27C115.922 127.27 113.993 123.242 112.546 123.081C111.099 122.92 110.295 121.631 110.295 121.631C110.295 121.631 114.475 118.409 109.974 115.67Z"
        fill="#002033"
      />
      <path
        d="M101.292 140.642C101.292 140.642 103.061 147.892 102.739 149.019C102.417 150.147 107.884 149.503 107.884 149.503L108.205 147.408C108.205 147.408 104.99 142.253 104.99 139.998C104.99 137.742 101.292 140.642 101.292 140.642Z"
        fill="#A0616A"
      />
      <path
        d="M107.884 145.959C107.884 145.959 112.224 149.181 109.974 150.792C107.723 152.403 99.3628 156.592 99.3628 156.592C99.3628 156.592 96.3082 157.075 98.559 154.336C100.81 151.598 101.453 151.114 101.614 149.664C101.753 148.699 102.091 147.773 102.605 146.944C102.605 146.944 104.829 151.437 107.884 145.959Z"
        fill="#002033"
      />
      <path
        d="M94.7005 107.292C94.7005 107.292 92.9321 124.047 95.6652 129.363C98.3982 134.68 101.614 141.93 101.453 142.252C101.292 142.574 106.115 141.768 106.115 140.318C106.115 138.869 100.81 124.369 100.81 124.369C100.81 124.369 103.06 105.52 104.829 104.231C106.597 102.942 94.7005 107.292 94.7005 107.292Z"
        fill="#002033"
      />
      <path
        opacity="0.1"
        d="M94.7005 107.292C94.7005 107.292 92.9321 124.047 95.6652 129.363C98.3982 134.68 101.614 141.93 101.453 142.252C101.292 142.574 106.115 141.768 106.115 140.318C106.115 138.869 100.81 124.369 100.81 124.369C100.81 124.369 103.06 105.52 104.829 104.231C106.597 102.942 94.7005 107.292 94.7005 107.292Z"
        fill="#002033"
      />
      <path
        d="M98.3342 60.0078C97.1851 60.2163 96.0466 60.849 95.4751 61.9295C94.942 62.9374 94.9081 64.2846 94.06 65.0084C93.5537 65.4405 92.8663 65.5404 92.3011 65.8798C91.8994 66.1373 91.5727 66.4964 91.3539 66.921C91.1351 67.3455 91.032 67.8204 91.055 68.2976C91.1003 69.1865 91.5681 70.0144 91.5581 70.9045C91.5452 72.0617 90.7467 73.0171 89.9957 73.8547L92.4864 74.3178C92.8628 74.4186 93.2575 74.4291 93.6387 74.3485C94.0689 74.2208 94.4183 73.8659 94.8532 73.7576C95.4768 73.6024 96.0939 73.9825 96.6503 74.3198C98.2342 75.2798 99.9325 76.0044 101.625 76.7259C102.122 76.9381 102.687 77.1506 103.178 76.9216C103.575 76.7367 103.822 76.3045 104.195 76.0706C104.863 75.6518 105.702 75.967 106.453 76.1738C108.151 76.6356 109.958 76.4815 111.553 75.7386C111.209 75.3359 110.758 75.0543 110.388 74.6793C110.017 74.3044 109.717 73.7735 109.815 73.2395C109.915 72.695 110.382 72.3314 110.701 71.892C110.936 71.5438 111.099 71.1522 111.182 70.7399C111.265 70.3277 111.264 69.9031 111.181 69.491C111.014 68.6711 110.616 67.9164 110.034 67.3161C109.702 67.0046 109.389 66.6736 109.096 66.3248C108.51 65.5319 108.365 64.4821 108.178 63.4935C107.99 62.505 107.694 61.4529 106.934 60.851C105.721 59.892 103.753 60.2189 102.336 60.1237C100.985 60.0329 99.6822 60.0078 98.3342 60.0078Z"
        fill="#002033"
      />
      <path
        d="M90.3598 95.8554L93.4144 103.427C93.4144 103.427 79.4275 118.732 87.3052 120.827C95.1829 122.921 97.9159 119.216 102.9 122.116C102.9 122.116 105.311 118.41 104.99 116.155C104.99 116.155 98.0767 114.866 94.379 115.027C94.379 115.027 104.347 106.327 105.311 106.005C106.276 105.683 109.491 100.528 104.507 96.3388C99.5236 92.15 100.81 89.8945 100.81 89.8945L90.3598 95.8554Z"
        fill="#002033"
      />
      <path
        d="M97.5944 69.5932C97.5944 69.5932 98.0767 74.5874 95.9867 75.2318C93.8967 75.8763 104.186 75.7151 104.186 75.7151C104.186 75.7151 101.774 74.2652 102.9 71.2042C104.025 68.1432 97.5944 69.5932 97.5944 69.5932Z"
        fill="#A0616A"
      />
      <path
        opacity="0.1"
        d="M97.5944 69.5932C97.5944 69.5932 98.0767 74.5874 95.9867 75.2318C93.8967 75.8763 104.186 75.7151 104.186 75.7151C104.186 75.7151 101.774 74.2652 102.9 71.2042C104.025 68.1432 97.5944 69.5932 97.5944 69.5932Z"
        fill="#002033"
      />
      <path
        d="M108.527 78.2944C108.527 78.2944 107.562 73.3002 105.633 73.6224C103.704 73.9446 99.8451 74.2668 99.3628 74.589C98.8805 74.9112 96.3082 75.5715 96.3082 75.5715C96.3082 75.5715 97.2728 74.4279 96.3082 74.2668C95.3436 74.1057 92.289 74.9112 91.9675 75.5556C91.6459 76.2001 90.3598 77.4889 90.3598 79.7444C90.3598 81.9998 89.3952 85.8664 89.7167 87.4774C90.0383 89.0885 89.5559 92.7939 88.5913 94.8883C87.6267 96.9826 88.4306 99.077 90.0383 96.9826C91.6459 94.8883 90.8421 96.9826 92.1282 97.1437C93.4144 97.3048 99.202 91.3439 100.81 92.7939C102.417 94.2439 104.025 95.2105 104.347 94.405C104.668 93.5994 103.06 92.1495 103.06 91.5051C103.06 90.8606 100.488 93.5994 103.221 87.9607C105.954 82.3221 103.382 80.711 108.527 78.2944Z"
        fill="#17BFD6"
      />
      <path
        d="M107.241 77.9718L108.527 78.294C108.527 78.294 113.189 74.9108 114.475 72.4942C115.761 70.0776 116.726 68.1444 116.726 68.1444C116.726 68.1444 114.636 65.5667 113.35 66.5333C112.064 67.4999 106.276 74.1052 106.276 74.1052L107.241 77.9718Z"
        fill="#17BFD6"
      />
      <path
        d="M101.292 72.9788C104.045 72.9788 106.276 70.7427 106.276 67.9845C106.276 65.2262 104.045 62.9902 101.292 62.9902C98.5396 62.9902 96.3082 65.2262 96.3082 67.9845C96.3082 70.7427 98.5396 72.9788 101.292 72.9788Z"
        fill="#A0616A"
      />
      <path
        d="M96.8832 66.5357C97.7349 65.8278 98.8225 65.4694 99.9272 65.5325C101.838 65.6503 103.519 67.0145 105.433 67.0571C105.356 66.6425 105.414 66.2142 105.597 65.8348C105.746 65.5301 105.98 65.2348 105.949 64.8972C105.896 64.6293 105.75 64.389 105.536 64.2198C104.43 63.1688 102.9 62.6271 101.378 62.5604C100.357 62.5156 98.4078 62.5399 97.616 63.3146C96.9385 63.9774 97.0173 65.6766 96.8832 66.5357Z"
        fill="#002033"
      />
      <path
        d="M107.525 40.8825C113.296 40.8825 117.974 36.1947 117.974 30.412C117.974 24.6292 113.296 19.9414 107.525 19.9414C101.755 19.9414 97.0767 24.6292 97.0767 30.412C97.0767 36.1947 101.755 40.8825 107.525 40.8825Z"
        fill="#17BFD6"
      />
      <path
        d="M104.972 27.7825C105.526 27.7825 105.974 27.1937 105.974 26.4674C105.974 25.7411 105.526 25.1523 104.972 25.1523C104.419 25.1523 103.97 25.7411 103.97 26.4674C103.97 27.1937 104.419 27.7825 104.972 27.7825Z"
        fill="#353F4A"
      />
      <path
        d="M110.15 27.7825C110.703 27.7825 111.152 27.1937 111.152 26.4674C111.152 25.7411 110.703 25.1523 110.15 25.1523C109.597 25.1523 109.148 25.7411 109.148 26.4674C109.148 27.1937 109.597 27.7825 110.15 27.7825Z"
        fill="#353F4A"
      />
      <path
        d="M113.789 30.6034C113.789 32.4918 110.642 35.6724 107.466 35.6724C104.29 35.6724 101.262 32.42 101.262 30.5317C101.262 28.6433 104.224 30.3703 107.4 30.3703C110.576 30.3703 113.789 28.7151 113.789 30.6034Z"
        fill="#002033"
      />
      <path
        d="M102.263 30.9258C102.263 30.9258 107.972 32.199 112.767 30.9616C112.767 30.9616 113.354 30.9616 112.953 31.6416C112.849 31.818 112.696 31.9609 112.514 32.0538C111.589 32.5271 108.167 33.9259 102.696 32.1418C102.527 32.0883 102.373 31.9938 102.249 31.8666C102.125 31.7394 102.035 31.5834 101.986 31.4126C101.921 31.1792 101.949 30.9581 102.263 30.9258Z"
        fill="#E4E7EB"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="217" height="157" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
