import { forwardRef } from "react";

import { LeaveRequestContainer } from "components/Shared/LeaveRequest/LeaveRequest.styled";
import { LeaveRequestIcons } from "components/Shared/LeaveRequest/LeaveRequestIcons";
import { exhaustiveMatchingGuard } from "helpers/exhaustiveMatchingGuard";

import {
  RosteringLeaveRequestStatusEnum,
  RosteringLeaveTypeEnum,
} from "../../../baseCODEGEN";

// This is used as a trigger in a tooltip, so we need ensure it is accessible and functional
//https://www.radix-ui.com/docs/primitives/guides/composition

export const LeaveRequest = forwardRef<
  HTMLButtonElement,
  {
    leaveType: RosteringLeaveTypeEnum;
    leaveStatus: RosteringLeaveRequestStatusEnum;
  }
>(({ leaveType, leaveStatus, ...props }, ref) => {
  switch (leaveType) {
    case RosteringLeaveTypeEnum.Annual:
    case RosteringLeaveTypeEnum.Study:
    case RosteringLeaveTypeEnum.Sick:
      return (
        <LeaveRequestContainer
          ref={ref}
          leaveStatus={leaveStatus}
          leaveType={leaveType}
          {...props}
        >
          <LeaveRequestIcons leaveType={leaveType} />
          <p>{`${leaveType} Leave`}</p>
        </LeaveRequestContainer>
      );

    case RosteringLeaveTypeEnum.Paid:
    case RosteringLeaveTypeEnum.Special:
      return (
        <LeaveRequestContainer
          ref={ref}
          leaveStatus={leaveStatus}
          leaveType={leaveType}
          {...props}
        >
          <LeaveRequestContainer
            leaveStatus={leaveStatus}
            leaveType={leaveType}
          >
            <p>Other Leave</p>
          </LeaveRequestContainer>
        </LeaveRequestContainer>
      );
    default:
      return exhaustiveMatchingGuard(leaveType);
  }
});
