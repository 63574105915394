import { ChangeEvent, Dispatch, FormEvent, SetStateAction } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  AuthPasswordEye,
  Button,
  FormGroup,
  FormInput,
  FormLabel,
  GLOBAL,
  IdleTimerNotification,
  RBBLUE,
} from "@patchworkhealth/web-components";

import { Divider, FormContainer, LogoWrapper } from "components/Style";
import history from "helpers/functions";
import { LogoIcon } from "icons/login";

interface Props {
  errors: string | null;
  loginUser: (e: FormEvent) => Promise<void>;
  loading: boolean;
  setEmail: Dispatch<SetStateAction<string>>;
  setPassword: Dispatch<SetStateAction<string>>;
  isPasswordHidden: boolean;
  setIsPasswordHidden: Dispatch<SetStateAction<boolean>>;
  setErrors: Dispatch<SetStateAction<string | null>>;
}

export const Login = ({
  errors,
  loginUser,
  loading,
  setEmail,
  setPassword,
  isPasswordHidden,
  setIsPasswordHidden,
  setErrors,
}: Props) => {
  const onRegister = () => history.push("/signup");

  return (
    <FormContainer data-testid="test__login">
      <LogoWrapper>
        <LogoIcon />
      </LogoWrapper>
      <IdleTimerNotification />
      <Form data-testid="test__form" onSubmit={loginUser}>
        <FormGroup>
          <FormLabel style={{ color: GLOBAL.white }}>Email Address</FormLabel>
          <FormInput
            autoFocus
            data-testid="test__login__email"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value);
              setErrors(null);
            }}
            type="email"
            name="email"
            required
            placeholder="Enter email address"
          />
        </FormGroup>

        <Divider top="10" bottom="10" line="none" />
        <FormGroup>
          <FormLabel style={{ color: GLOBAL.white }}>Password</FormLabel>
          <FormInput
            data-testid="test__login__password"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setPassword(e.target.value);
              setErrors(null);
            }}
            type={isPasswordHidden ? "password" : "text"}
            name="password"
            required
            placeholder="Enter password"
            autoComplete="off"
          />

          <AuthPasswordEye
            data-testid="test__login__show"
            onClick={() => setIsPasswordHidden((s) => !s)}
          />
        </FormGroup>
        <Divider top="10" bottom="10" line="none" />
        <Form.Group className={"text-right"}>
          <Link
            data-testid="test__forgot__password"
            to={"/forgot"}
            style={{
              color: GLOBAL.white,
              fontSize: 12,
              fontWeight: 400,
              opacity: 0.5,
            }}
          >
            Forgot Password?
          </Link>
        </Form.Group>
        <Button
          variant="success"
          loading={loading}
          type="submit"
          style={{
            width: "100%",
            fontSize: "14px",
            fontWeight: 500,
            color: RBBLUE.five,
            marginTop: 4,
          }}
        >
          Sign In
        </Button>

        {errors && <ErrorContainer>{errors}</ErrorContainer>}
      </Form>
      <Divider top="24" bottom="24" line="solid" opacity="0.2" />
      <Button
        variant="primary"
        onClick={onRegister}
        style={{
          width: "100%",
          fontSize: "14px",
          fontWeight: 500,
        }}
      >
        Register for Patchwork
      </Button>
    </FormContainer>
  );
};

const ErrorContainer = styled.div`
  margin-top: 16px;
  border-radius: 4px;
  border: 1px solid rgb(248 113 113);
  background-color: rgb(254 226 226);
  padding: 16px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: rgb(185 28 28);
`;
