import { ApolloClient, from, InMemoryCache } from "@apollo/client";
import apolloLogger from "apollo-link-logger";
import { createUploadLink } from "apollo-upload-client";

import { RefreshTokenMutationDocument } from "gql/__generated__/Mutations.generated";

// import { RefreshTokenDocument } from "helpers/gql/__generated__/mutations.generated";
import { saveTokens, Tokens, tokensSchema } from "./tokens";

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_API}/graphql`,
});

export const refreshToken = async (tokens: Tokens) => {
  const temporaryClient = new ApolloClient({
    link: from([apolloLogger, uploadLink]),
    cache: new InMemoryCache(),
  });

  return temporaryClient
    .mutate({
      mutation: RefreshTokenMutationDocument,
      variables: { refreshToken: tokens.refreshToken },
    })
    .then((res) => {
      const parsedTokens = tokensSchema.safeParse(res.data?.refreshToken);
      if (!parsedTokens.success) {
        console.error("Error refreshing token");
        return;
      }
      const sessionStorageItem = process.env.REACT_APP_TOKENS
        ? sessionStorage.getItem(process.env.REACT_APP_TOKENS)
        : null;

      const doesSessionExist =
        sessionStorageItem && JSON.parse(sessionStorageItem);

      saveTokens(parsedTokens.data, doesSessionExist ? "session" : "local");

      return parsedTokens.data.token;
    });
};
