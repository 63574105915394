import {
  DayShiftIcon,
  EarlyShiftIcon,
  LateShiftIcon,
  LongDayShiftIcon,
  MidShiftIcon,
  Night1ShiftIcon,
  Night2ShiftIcon,
  NightShiftIcon,
  OnCallShiftIcon,
  TwilightShiftIcon,
} from "icons/calendarIcons";

interface Props {
  type: string | undefined;
}

export const ShiftIcon = ({ type }: Props) => {
  switch (type) {
    case "Day":
      return <DayShiftIcon />;
    case "Night":
      return <NightShiftIcon />;
    case "Non-Resident On Call":
      return <OnCallShiftIcon />;
    case "Late":
      return <LateShiftIcon />;
    case "Early":
      return <EarlyShiftIcon />;
    case "Long Day":
      return <LongDayShiftIcon />;
    case "Mid":
      return <MidShiftIcon />;
    case "Night 1":
      return <Night1ShiftIcon />;
    case "Night 2":
      return <Night2ShiftIcon />;
    case "Twilight":
      return <TwilightShiftIcon />;
    default:
      return null;
  }
};
