import { FormEventHandler } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";

import {
  Button,
  FormInput,
  FormLabel,
  GLOBAL,
  RBBLUE,
} from "@patchworkhealth/web-components";

import { FormContainer, LogoWrapper, Return } from "components/Style.js";
import { LogoIcon } from "icons/login";

type Props = {
  onSubmit: FormEventHandler<HTMLFormElement>;
  setEmail: (email: string) => void;
  loading: boolean;
};

export const ForgottenPassword = ({ onSubmit, setEmail, loading }: Props) => {
  return (
    <FormContainer>
      <LogoWrapper>
        <LogoIcon />
      </LogoWrapper>
      <Form onSubmit={onSubmit}>
        <Form.Group controlId="formBasicEmail">
          <FormLabel style={{ color: GLOBAL.white }}>Email Address</FormLabel>
          <FormInput
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            name="email"
            aria-label="email"
            placeholder="Enter email address"
            required
            data-testid="test__forgotten__email"
          />
        </Form.Group>
        <Form.Group>
          <Button
            variant="success"
            type="submit"
            loading={loading}
            style={{
              width: "100%",
              fontSize: "14px",
              fontWeight: 500,
              color: RBBLUE.five,
            }}
          >
            Reset Password
          </Button>
        </Form.Group>
        <Return>
          <Link data-testid="test__forgotten__home" to={"/"}>
            ← Return home
          </Link>
        </Return>
      </Form>
    </FormContainer>
  );
};
