import styled from "styled-components";

import { GLOBAL, GREY } from "@patchworkhealth/web-components";

export const TextItem = styled.p`
  color: ${GREY.ten};
  font-weight: 400;
`;

export const SuccessOuterContainer = styled.div`
  width: 1021px;
  height: 463px;
  background: ${GLOBAL.white};
  border-radius: 8px;
  margin-top: 152px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 110px auto;
`;

export const SuccessInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SuccessText = styled.p`
  font-size: 24px;
  font-weight: 700;
  color: ${GREY.ten};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
`;
