import * as Types from '../../../baseCODEGEN';

import { gql } from '@apollo/client';
import { ErrorFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from 'msw'
const defaultOptions = {} as const;
export type LoginMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  password: Types.Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: { __typename?: 'NonAuthorisedUser', refreshToken?: string | null, token?: string | null, status?: string | null } | null };

export type ResetPasswordMutationVariables = Types.Exact<{
  password: Types.Scalars['String'];
  passwordConfirmation: Types.Scalars['String'];
  resetPasswordToken: Types.Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', workerResetPassword?: { __typename?: 'WorkerResetPasswordPayload', success: boolean, errors: Array<{ __typename?: 'ValidationError', attribute?: string | null, message: string }> } | null };

export type SignUpMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  password: Types.Scalars['String'];
  passwordConfirmation: Types.Scalars['String'];
  termsAccepted?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type SignUpMutation = { __typename?: 'Mutation', signUp?: { __typename?: 'SignupPayload', worker?: { __typename?: 'NonAuthorisedUser', token?: string | null, refreshToken?: string | null } | null, errors: Array<{ __typename?: 'ValidationError', attribute?: string | null, message: string }> } | null };

export type VmsSignupMutationVariables = Types.Exact<{
  password: Types.Scalars['String'];
  niNumber: Types.Scalars['String'];
  token: Types.Scalars['String'];
}>;


export type VmsSignupMutation = { __typename?: 'Mutation', vmsSignup?: { __typename?: 'SignupWithTokenPayload', success: boolean, account?: { __typename?: 'Account', email?: string | null, id: string, invitationAcceptedAt?: string | null, invitationToken?: string | null, productTypes?: Array<Types.ProductTypeEnum> | null, refreshToken?: string | null, token?: string | null } | null, errors: Array<{ __typename?: 'ValidationError', attribute?: string | null, message: string }> } | null };

export type AccountActivationMutationVariables = Types.Exact<{
  invitationToken: Types.Scalars['String'];
  password: Types.Scalars['String'];
}>;


export type AccountActivationMutation = { __typename?: 'Mutation', accountAcceptInvite?: { __typename?: 'AcceptInvitePayload', account?: { __typename?: 'Account', email?: string | null, id: string } | null, errors: Array<{ __typename?: 'ValidationError', attribute?: string | null, message: string }> } | null };


export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    refreshToken
    token
    status
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($password: String!, $passwordConfirmation: String!, $resetPasswordToken: String!) {
  workerResetPassword(
    password: $password
    passwordConfirmation: $passwordConfirmation
    resetPasswordToken: $resetPasswordToken
  ) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      resetPasswordToken: // value for 'resetPasswordToken'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($email: String!, $password: String!, $passwordConfirmation: String!, $termsAccepted: Boolean) {
  signUp(
    email: $email
    password: $password
    passwordConfirmation: $passwordConfirmation
    termsAccepted: $termsAccepted
  ) {
    worker {
      token
      refreshToken
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      termsAccepted: // value for 'termsAccepted'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const VmsSignupDocument = gql`
    mutation VmsSignup($password: String!, $niNumber: String!, $token: String!) {
  vmsSignup(password: $password, niNumber: $niNumber, token: $token) {
    account {
      email
      id
      invitationAcceptedAt
      invitationToken
      productTypes
      refreshToken
      token
    }
    errors {
      ...Error
    }
    success
  }
}
    ${ErrorFragmentDoc}`;
export type VmsSignupMutationFn = Apollo.MutationFunction<VmsSignupMutation, VmsSignupMutationVariables>;

/**
 * __useVmsSignupMutation__
 *
 * To run a mutation, you first call `useVmsSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVmsSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vmsSignupMutation, { data, loading, error }] = useVmsSignupMutation({
 *   variables: {
 *      password: // value for 'password'
 *      niNumber: // value for 'niNumber'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVmsSignupMutation(baseOptions?: Apollo.MutationHookOptions<VmsSignupMutation, VmsSignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VmsSignupMutation, VmsSignupMutationVariables>(VmsSignupDocument, options);
      }
export type VmsSignupMutationHookResult = ReturnType<typeof useVmsSignupMutation>;
export type VmsSignupMutationResult = Apollo.MutationResult<VmsSignupMutation>;
export type VmsSignupMutationOptions = Apollo.BaseMutationOptions<VmsSignupMutation, VmsSignupMutationVariables>;
export const AccountActivationDocument = gql`
    mutation AccountActivation($invitationToken: String!, $password: String!) {
  accountAcceptInvite(invitationToken: $invitationToken, password: $password) {
    account {
      email
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type AccountActivationMutationFn = Apollo.MutationFunction<AccountActivationMutation, AccountActivationMutationVariables>;

/**
 * __useAccountActivationMutation__
 *
 * To run a mutation, you first call `useAccountActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountActivationMutation, { data, loading, error }] = useAccountActivationMutation({
 *   variables: {
 *      invitationToken: // value for 'invitationToken'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAccountActivationMutation(baseOptions?: Apollo.MutationHookOptions<AccountActivationMutation, AccountActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountActivationMutation, AccountActivationMutationVariables>(AccountActivationDocument, options);
      }
export type AccountActivationMutationHookResult = ReturnType<typeof useAccountActivationMutation>;
export type AccountActivationMutationResult = Apollo.MutationResult<AccountActivationMutation>;
export type AccountActivationMutationOptions = Apollo.BaseMutationOptions<AccountActivationMutation, AccountActivationMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockLoginMutation((req, res, ctx) => {
 *   const { email, password } = req.variables;
 *   return res(
 *     ctx.data({ login })
 *   )
 * })
 */
export const mockLoginMutation = (resolver: ResponseResolver<GraphQLRequest<LoginMutationVariables>, GraphQLContext<LoginMutation>, any>) =>
  graphql.mutation<LoginMutation, LoginMutationVariables>(
    'Login',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockResetPasswordMutation((req, res, ctx) => {
 *   const { password, passwordConfirmation, resetPasswordToken } = req.variables;
 *   return res(
 *     ctx.data({ workerResetPassword })
 *   )
 * })
 */
export const mockResetPasswordMutation = (resolver: ResponseResolver<GraphQLRequest<ResetPasswordMutationVariables>, GraphQLContext<ResetPasswordMutation>, any>) =>
  graphql.mutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
    'ResetPassword',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSignUpMutation((req, res, ctx) => {
 *   const { email, password, passwordConfirmation, termsAccepted } = req.variables;
 *   return res(
 *     ctx.data({ signUp })
 *   )
 * })
 */
export const mockSignUpMutation = (resolver: ResponseResolver<GraphQLRequest<SignUpMutationVariables>, GraphQLContext<SignUpMutation>, any>) =>
  graphql.mutation<SignUpMutation, SignUpMutationVariables>(
    'SignUp',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockVmsSignupMutation((req, res, ctx) => {
 *   const { password, niNumber, token } = req.variables;
 *   return res(
 *     ctx.data({ vmsSignup })
 *   )
 * })
 */
export const mockVmsSignupMutation = (resolver: ResponseResolver<GraphQLRequest<VmsSignupMutationVariables>, GraphQLContext<VmsSignupMutation>, any>) =>
  graphql.mutation<VmsSignupMutation, VmsSignupMutationVariables>(
    'VmsSignup',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAccountActivationMutation((req, res, ctx) => {
 *   const { invitationToken, password } = req.variables;
 *   return res(
 *     ctx.data({ accountAcceptInvite })
 *   )
 * })
 */
export const mockAccountActivationMutation = (resolver: ResponseResolver<GraphQLRequest<AccountActivationMutationVariables>, GraphQLContext<AccountActivationMutation>, any>) =>
  graphql.mutation<AccountActivationMutation, AccountActivationMutationVariables>(
    'AccountActivation',
    resolver
  )
