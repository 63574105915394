import { useContext } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

import { Button } from "components/UI";
import { AppContext } from "context/AppContext";
import { AndroidIcon, AppleIcon } from "icons/mobileIcons";

const MobileModal = () => {
  const store = useContext(AppContext);

  const appleAppUrl =
    "https://apps.apple.com/gb/app/patchwork-health/id1274146437";
  const googlePlayUrl =
    "https://play.google.com/store/apps/details?id=com.locumtap.app&hl=en_GB&gl=US";

  return (
    <Modal
      backdrop="static"
      centered
      show={store.mobileModal}
      onHide={() => store.setMobileModal(false)}
    >
      <div className="mobile-modal-content p-6 h-[360px]">
        <p className="modal-title my-6 text-[20px] font-bold text-center">
          Get the best experience
        </p>
        <p className="mb-10 text-center modal-description">
          You are using a stripped back version of the Patchwork mobile App. For
          the best experience, view on desktop or download the app
        </p>
        <ButtonWrapper>
          <a href={appleAppUrl} target="_blank" rel="noopener noreferrer">
            <AppleIcon />
            <div className="button_text">
              <h5>GET IT ON</h5>
              <h6>App Store</h6>
            </div>
          </a>

          <a href={googlePlayUrl} target="_blank" rel="noopener noreferrer">
            <AndroidIcon />
            <div className="button_text">
              <h5>GET IT ON</h5>
              <h6>Google Play</h6>
            </div>
          </a>
        </ButtonWrapper>
        <Button
          onClick={() => store.setMobileModal(false)}
          className="absolute continue-button bottom-6 left-6 right-6"
          variant="black"
        >
          Continue on mobile site
        </Button>
      </div>
    </Modal>
  );
};

export default MobileModal;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 272px;

  a {
    align-items: center;
    background: #000;
    border-radius: 8px;
    color: white;
    display: flex;
    height: 41px;
    justify-content: center;
    text-decoration: none;
    width: 126px;

    &:nth-child(2) {
      width: 137px;
    }
  }

  svg {
    margin: 0 10px;
    width: 30px;
  }

  .button_text {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5px;
    padding-left: 0;
    width: 100%;
  }

  h5 {
    font-size: 9px;
    letter-spacing: 0.3px;
    margin: 0;
  }

  h6 {
    font-size: 14px;
    margin: 0;
  }
`;
