import { FormLabel, Modal } from "react-bootstrap";
import styled from "styled-components";

import { ModalHeader } from "@patchworkhealth/web-components";

import { RosteringNotificationType } from "components/Notifications/NotificationsDropdown";
import { Divider, StyledButton } from "components/Style";
import history from "helpers/functions";
import { CloseModal } from "icons/referencesIcons";

interface Props {
  show: boolean;
  notification: RosteringNotificationType | null;
  onHide: () => void;
  acceptNotification: () => void;
}

export const NotificationConfirmModal = ({
  show,
  onHide,
  notification,
  acceptNotification,
}: Props) => (
  <Modal show={show} onHide={onHide} centered size="lg">
    <ModalHeader>
      <Modal.Title>Notification</Modal.Title>
      <button className="btn btn-cyan" onClick={onHide}>
        <CloseModal />
      </button>
    </ModalHeader>
    <ModalBody>
      <div style={{ textAlign: "left", paddingTop: "32px" }}>
        <FormLabel>{notification?.organisation?.name}</FormLabel>
        {notification?.message && <p>{notification.message}</p>}
      </div>

      <Divider top="51" bottom="32" line="solid" />

      <ModalFooter>
        <StyledButton
          color="white"
          size="small"
          length="90px"
          onClick={() => onHide()}
          className="modal__footer_cancel"
          data-testid="references__cancel"
        >
          Close
        </StyledButton>

        <StyledButton
          onClick={() => {
            acceptNotification();
            history.push("/leave-management");

            notification?.message?.match(/Your leave request/)
              ? history.push("/leave-management")
              : history.push("/team-rota");

            onHide();
          }}
          color="blue"
          size="small"
          length="133px"
        >
          Review
        </StyledButton>
      </ModalFooter>
    </ModalBody>
  </Modal>
);

const ModalBody = styled.div`
  padding: 25px 32px;

  label {
    font-size: 16px !important;
    font-weight: bold !important;
    margin: 0 0 5px;
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin-right: 8px;
  }

  @media (max-width: 468px) {
    flex-direction: column;
    text-align: center;
    button {
      font-size: 12px;
      margin-bottom: 10px;
      width: 100%;
    }
  }
`;
