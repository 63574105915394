import { gql } from "@apollo/client";

// Update Profile *******************************************************

export const UPDATE_PROFILE = gql`
  mutation updateProfile(
    $staffGroupId: Int!
    $postcode: String!
    $regBodyNumber: String
    $firstName: String!
    $lastName: String!
    $dateOfBirth: String!
    $phoneNumber: String!
    $email: String
    $addressLine1: String
    $addressLine2: String
    $city: String
    $preferredName: String
    $filterGradeId: Int
    $ediGenderId: Int
    $ediConsent: Boolean
    $ediCountryOfQualificationId: Int
    $ediDisabilityId: Int
    $ediEthnicityId: Int
  ) {
    workerUpdateProfile(
      staffGroupId: $staffGroupId
      postcode: $postcode
      regBodyNumber: $regBodyNumber
      firstName: $firstName
      lastName: $lastName
      dateOfBirth: $dateOfBirth
      phoneNumber: $phoneNumber
      email: $email
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      preferredName: $preferredName
      filterGradeId: $filterGradeId
      ediGenderId: $ediGenderId
      ediConsent: $ediConsent
      ediCountryOfQualificationId: $ediCountryOfQualificationId
      ediDisabilityId: $ediDisabilityId
      ediEthnicityId: $ediEthnicityId
    ) {
      worker {
        firstName
        lastName
        phoneNumber
        gender
        dateOfBirth
        email
        ediGender {
          id
          value
        }

        staffGroup {
          id

          specialities {
            id
            title
          }
        }
        postcode
        regBodyNumber
      }
      errors {
        attribute
        message
      }
    }
  }
`;

// Delete Profile Picture *********************************

export const DELETE_PROFILE_PIC = gql`
  mutation deleteProfilePic {
    deleteProfilePicture {
      errors {
        message
      }
      worker {
        email
        profilePictureUrl
      }
    }
  }
`;

// Update Employment Profile *****************************************

export const UPDATE_EMPLOYMENT = gql`
  mutation updateEmployment(
    $dbsNumber: String
    $niNumber: String
    $filterGradeId: Int
    $specialityIds: [Int!]
  ) {
    updateEmploymentInfo(
      dbsNumber: $dbsNumber
      niNumber: $niNumber
      filterGradeId: $filterGradeId
      specialityIds: $specialityIds
    ) {
      worker {
        niNumber
        dbsNumber
        id
        filterGrade {
          id
        }
        specialities {
          id
        }
      }
      errors {
        attribute
        message
      }
    }
  }
`;

// Profile Pic Upload **************************************************

export const UPDATE_AVATAR = gql`
  mutation updateAvatar($file: String!, $fileName: String!) {
    uploadProfilePicture(file: $file, fileName: $fileName) {
      worker {
        firstName
      }
      errors {
        attribute
        message
      }
    }
  }
`;

// Forgot Password  ******************************************************

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation sendResetPasswordInstructions($email: String!) {
    sendResetPasswordInstructions(email: $email)
  }
`;

// Refresh Token  ******************************************************

export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshTokenMutation($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      refreshToken
      token
    }
  }
`;

// Documents *********************************************************************

export const DELETE_DOC = gql`
  mutation deleteDoc($id: Int!) {
    deleteWorkerUpload(id: $id) {
      documentType {
        id
        title
        mid
        allowedFormats
        documentNumberVisibility
        documentTitleVisibility
        issuerVisibility
        issueDateVisibility
        expirationDateVisibility
        qualificationNameVisibility
        multipleUploadsAllowed
        workerUploads {
          id
          issuer
          documentNumber
          documentTitle
          qualificationName
          issueDate
          expirationDate
          imageUrl
          fileName
          trashedAt
        }
        archivedWorkerUploads {
          id
          issuer
          documentNumber
          documentTitle
          qualificationName
          issueDate
          expirationDate
          imageUrl
          fileName
          trashedAt
        }
      }
      errors {
        attribute
        message
      }
    }
  }
`;

export const ADD_DOC = gql`
  mutation addDoc(
    $file: String!
    $fileName: String!
    $documentTypeId: Int!
    $documentNumber: String
    $documentTitle: String
    $qualificationName: String
    $issuer: String
    $issueDate: String
    $expirationDate: String
  ) {
    addWorkerUpload(
      file: $file
      fileName: $fileName
      documentTypeId: $documentTypeId
      documentNumber: $documentNumber
      documentTitle: $documentTitle
      issuer: $issuer
      qualificationName: $qualificationName
      issueDate: $issueDate
      expirationDate: $expirationDate
    ) {
      documentType {
        id
        title
        mid
        allowedFormats
        documentNumberVisibility
        documentTitleVisibility
        issuerVisibility
        issueDateVisibility
        expirationDateVisibility
        multipleUploadsAllowed
        workerUploads {
          id
          issuer
          documentNumber
          documentTitle
          qualificationName
          issueDate
          expirationDate
          imageUrl

          fileName
          trashedAt
        }
        archivedWorkerUploads {
          id
          issuer
          documentNumber
          documentTitle
          qualificationName
          issueDate
          expirationDate
          imageUrl

          fileName
          trashedAt
        }
      }
      errors {
        attribute
        message
      }
    }
  }
`;

// APPLIYING TO ORGS ********************************

// 1. Internal Application ****

export const INTERNAL_APPLICATION = gql`
  mutation internalApp(
    $organisationId: Int!
    $answer: String
    $signature: String!
  ) {
    internalApplication(
      organisationId: $organisationId
      answer: $answer
      signature: $signature
    ) {
      errors {
        message
        attribute
      }
      organisationRegistration {
        id
      }
    }
  }
`;

// 2. External Application *********************

export const EXTERNAL_APPLICATION_MUTATION = gql`
  mutation externalApplicationMutation(
    $organisationId: Int!
    $signature: String!
    $answer: String
  ) {
    externalApplication(
      organisationId: $organisationId
      signature: $signature
      answer: $answer
    ) {
      errors {
        attribute
        message
      }
      organisationRegistration {
        id
      }
    }
  }
`;

// 3. Join Collab Bank

export const COLLAB_BANK_JOIN = gql`
  mutation joinCollaborativeBankMutation(
    $collaborativeBankId: Int!
    $primaryOrganisationId: Int!
    $signature: String!
  ) {
    joinCollaborativeBank(
      collaborativeBankId: $collaborativeBankId
      primaryOrganisationId: $primaryOrganisationId
      signature: $signature
    ) {
      errors {
        message
      }
      workerCollaborativeBank {
        active
        id
      }
    }
  }
`;

// 4. Create Leave Request ************

// Confirm worker to join Vms Agency *******

export const VMS_WORKER_CONFIRM_REGISTRATION = gql`
  mutation vmsWorkerConfirmRegistration($agencyRegistrationId: Int!) {
    vmsWorkerConfirmRegistration(agencyRegistrationId: $agencyRegistrationId) {
      errors {
        attribute
        message
      }
      success
    }
  }
`;
