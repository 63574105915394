import { Dispatch, SetStateAction } from "react";

import {
  FormGroup,
  FormSelect,
  FormSelectMulti,
} from "@patchworkhealth/web-components";

import { RosteringExceptionReportReasonCategoriesQuery } from "components/ExceptionReports/__generated__/ExceptionReports.generated";
import * as FormStyled from "components/ExceptionReports/ExceptionReportForms/ExceptionReportForms.styled";
import {
  RosteringExceptionReportCategory,
  RosteringExceptionReportReasons,
} from "components/ExceptionReports/ExceptionReports.types";
import { FormError } from "helpers/types";

interface Props<T extends Record<string, unknown>> {
  rosteringExceptionReportReasonCategories:
    | RosteringExceptionReportReasonCategoriesQuery["rosteringExceptionReportReasonCategories"]
    | undefined;
  exceptionReportCategoryValue:
    | RosteringExceptionReportCategory
    | null
    | undefined;
  exceptionReportReasonValue:
    | RosteringExceptionReportReasons
    | null
    | undefined;
  setErrors: Dispatch<SetStateAction<FormError<T> | null>>;
  setInput: Dispatch<SetStateAction<T>>;
  errors: (FormError<T> & { rosteringExceptionReportCategory?: string }) | null;
  isSupervisorForCategory: boolean;
  isSupervisor: boolean;
}

export const CategoryReasonFormSelect = <T extends Record<string, unknown>>({
  rosteringExceptionReportReasonCategories,
  setErrors,
  setInput,
  exceptionReportCategoryValue,
  errors,
  isSupervisorForCategory,
  exceptionReportReasonValue,
  isSupervisor,
}: Props<T>) => {
  if (!rosteringExceptionReportReasonCategories?.length) return null;

  const reasons = rosteringExceptionReportReasonCategories?.find(
    (category) => category.id === exceptionReportCategoryValue?.id
  )?.rosteringExceptionReportReasons;

  return (
    <>
      <div style={{ display: "flex", gap: "10px" }}>
        <FormGroup
          style={{ width: "340px" }}
          data-testid="select_shift_category"
        >
          <FormStyled.RequiredLabel required>
            What category does this fall under?
          </FormStyled.RequiredLabel>
          <FormSelect
            options={rosteringExceptionReportReasonCategories}
            placeholder="Select a category"
            getOptionLabel={(option: { name: string }) => option.name}
            getOptionValue={(option: { id: string }) => option.id}
            onChange={(event) => {
              setErrors(null);
              setInput((prevState) => ({
                ...prevState,
                rosteringExceptionReportCategory: event ?? undefined,
                rosteringExceptionReportReason: [],
              }));
            }}
            value={exceptionReportCategoryValue}
          />

          {errors?.rosteringExceptionReportCategory && (
            <FormStyled.ErrorLabel data-testid="error_category">
              {errors.rosteringExceptionReportCategory}
            </FormStyled.ErrorLabel>
          )}
        </FormGroup>
        {exceptionReportCategoryValue && (
          <FormGroup style={{ width: "320px" }} data-testid="shift_category">
            <FormStyled.RequiredLabel required>
              What reason does this fall under?
            </FormStyled.RequiredLabel>
            <FormSelectMulti
              isClearable
              options={reasons}
              placeholder="Select a reason"
              getOptionLabel={(option: { name: string }) => option.name}
              getOptionValue={(option: { id: string }) => option.id}
              onChange={(event) => {
                setErrors(null);
                setInput((prevState) => ({
                  ...prevState,
                  rosteringExceptionReportReason: (event ?? []).map(
                    (reason: { id: string; name: string }) => ({
                      id: reason.id,
                      name: reason.name,
                    })
                  ),
                }));
              }}
              value={exceptionReportReasonValue}
            />
            {errors?.rosteringExceptionReportReason && (
              <FormStyled.ErrorLabel data-testid="error_reasor">
                {errors.rosteringExceptionReportReason}
              </FormStyled.ErrorLabel>
            )}
          </FormGroup>
        )}
      </div>
      {/*Show on No Compensation on category */}
      {exceptionReportCategoryValue &&
        !exceptionReportCategoryValue.allowCompensation && (
          <FormStyled.NoCompensationLabel>
            <p>There is no compensation for this category.</p>
          </FormStyled.NoCompensationLabel>
        )}
      {/* Show only if there is a supervisor but not for current category, don't show if there are no supervisors */}
      {!isSupervisorForCategory && isSupervisor && (
        <FormStyled.NoSupervisorContainer>
          <p>
            We’ve detected that you do not have a supervisor to approve your
            report for this category.
          </p>
          <p>
            Please contact your roster admin to assign a supervisor for you to
            submit your report.
          </p>
        </FormStyled.NoSupervisorContainer>
      )}
    </>
  );
};
