import { useContext } from "react";
import {
  RosteringEventTypeEnum,
  RosteringLeaveRequestStatusEnum,
  ShiftStatus,
} from "baseCODEGEN";
import dayjs from "dayjs";

import { AppContext } from "context/AppContext";

import {
  CalendarShiftsSummaryQuery,
  RosteringEventsForCalendarQuery,
  RosteringLeaveRequestsForCalendarQuery,
  useCalendarShiftsSummaryQuery,
  useRosteringEventsForCalendarQuery,
  useRosteringLeaveRequestsForCalendarQuery,
} from "../__generated__/Calendar.generated";
import { DayData, MonthData } from "../CalendarHelpers";

type BankShiftStatus = {
  number: number;
  status: ShiftStatus;
};

type Props = {
  monthStart: string; //YYYY-MM-DD
  monthEnd: string; //YYYY-MM-DD
  monthData: MonthData;
};

export const useCalendarData = ({ monthStart, monthEnd, monthData }: Props) => {
  const { productType, organisations, user } = useContext(AppContext);

  const organisationId = user?.rosteringOrganisations?.[0]?.id || 0;
  const organisationRegistrationId = +(
    user?.rosteringOrganisationRegistrations?.[0]?.id || 0
  );

  const { data: calendarData } = useCalendarShiftsSummaryQuery({
    skip: productType === 1,
    variables: {
      fromStartTime: monthStart,
      organisationIds: organisations,
      toStartTime: monthEnd,
    },
  });

  const { data: rosteringData } = useRosteringEventsForCalendarQuery({
    skip: productType === 0,
    variables: {
      startsAtFrom: monthStart,
      startsAtUntil: monthEnd,
      organisationActivityIds: [],
      eventTypes: [RosteringEventTypeEnum.Shift, RosteringEventTypeEnum.Rest],
    },
  });

  const { data: leaveRequestsForCalendarData } =
    useRosteringLeaveRequestsForCalendarQuery({
      skip: productType === 0,
      variables: {
        organisationId: organisationId ?? 0,
        rosteringOrganisationRegistrationId: +organisationRegistrationId,
        statuses: [
          RosteringLeaveRequestStatusEnum.Approved,
          RosteringLeaveRequestStatusEnum.Pending,
          RosteringLeaveRequestStatusEnum.PendingCancellation,
        ],
        endDateFrom: monthStart,
        startDateUntil: monthEnd,
      },
    });

  const workersShifts = calendarData?.workersShifts || [];

  if (productType === 0) {
    return monthData.map((day) => {
      const matchingShifts = getMatchingShifts(workersShifts, day.date);
      const uniqueStatuses = getUniqueShiftStatuses(matchingShifts);

      return {
        ...day,
        bank: uniqueStatuses.map((status) => ({
          status: status,
          number: matchingShifts.filter((shift) => shift.status === status)
            .length,
        })),
      };
    });
  }

  if (productType === 1) {
    return monthData.map((day) =>
      createMonthData(
        day,
        rosteringData?.rosteringEvents,
        leaveRequestsForCalendarData?.rosteringLeaveRequests
      )
    );
  }

  if (productType === 2) {
    return monthData.map((day) => {
      const matchingShifts = getMatchingShifts(workersShifts, day.date);
      const uniqueStatuses = getUniqueShiftStatuses(matchingShifts);
      const bank: BankShiftStatus[] = uniqueStatuses.map((status) => ({
        status: status,
        number: matchingShifts.filter((shift) => shift.status === status)
          .length,
      }));

      return {
        ...createMonthData(
          day,
          rosteringData?.rosteringEvents,
          leaveRequestsForCalendarData?.rosteringLeaveRequests
        ),
        bank,
      };
    });
  }

  return [] as MonthData;
};

const createMonthData = (
  day: DayData,
  rosteringEvents: RosteringEventsForCalendarQuery["rosteringEvents"],
  rosteringLeaveRequests: RosteringLeaveRequestsForCalendarQuery["rosteringLeaveRequests"]
) => {
  return {
    ...day,
    rota:
      rosteringEvents?.nodes?.filter(
        (shift) => shift.startsAt.slice(0, 10) === day.date
      ) ?? [],
    leave:
      rosteringLeaveRequests?.filter((shift) => {
        return dayjs(day.date).isBetween(
          dayjs(shift.startDate),
          dayjs(shift.endDate),
          "day",
          "[]"
        );
      }) ?? [],
  };
};

type WorkersShifts = CalendarShiftsSummaryQuery["workersShifts"];

const getUniqueShiftStatuses = (workersShifts: WorkersShifts) =>
  Array.from(new Set(workersShifts.map((shift) => shift.status)));

const getMatchingShifts = (
  workersShifts: WorkersShifts,
  date: DayData["date"]
) =>
  (workersShifts || []).filter(
    (shift) => shift.startTime.slice(0, 10) === date
  );
