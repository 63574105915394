import { useState } from "react";
import toast from "react-hot-toast";
import { ExceptionReportStatusEnum } from "baseCODEGEN";

import {
  DefaultButton,
  FormGroup,
  FormInput,
  FormLabel,
} from "@patchworkhealth/web-components";

import { useRosteringExceptionReportQuery } from "components/ExceptionReports/__generated__/ExceptionReports.generated";
import { DecisionBox } from "components/ExceptionReports/components/DecisionBox/DecisionBox";
import { ExceptionReportDetails } from "components/ExceptionReports/components/ExceptionReportDetails/ExceptionReportDetails";
import { ReportersDetails } from "components/ExceptionReports/components/ReportersDetails/ReportersDetails";
import { SupervisorsList } from "components/ExceptionReports/components/SupervisorsList/SupervisorsList";
import { useRosteringExceptionReportDisputeMutation } from "components/ExceptionReports/ExceptionReportForms/DisputeExceptionReportForm/__generated__/DisputeExceptionReportForm.generated";
import { ExceptionReportPages } from "components/ExceptionReports/ExceptionReports.types";
import { formatError } from "helpers/formatError";
import { FormError } from "helpers/types";

import { onInputChange } from "../ExceptionReportForms.helpers";
import { FormBreakLine } from "../ManualExceptionReportForm/ManualExceptionReportForm.styled";
import { FooterContainer } from "../ShiftExceptionReportForm/ShiftExceptionReportForm.styled";
import * as FormStyled from "./../ExceptionReportForms.styled";
import * as Styled from "./DisputeExceptionReportForm.styled";

type DisputeFormInputType = {
  disputeNote: string;
};

const initInputs: DisputeFormInputType = {
  disputeNote: "",
};

interface Props {
  exceptionReportId: string;
  setPage: (page: ExceptionReportPages) => void;
  setReportStatus: (status: ExceptionReportStatusEnum | undefined) => void;
}
export const DisputeExceptionReportForm = ({
  exceptionReportId,
  setPage,
  setReportStatus,
}: Props) => {
  const [disputeFormInputs, setDisputeFormInputs] =
    useState<DisputeFormInputType>(initInputs);
  const [error, setError] = useState<FormError<DisputeFormInputType> | null>(
    null
  );

  const { data: exceptionReportData } = useRosteringExceptionReportQuery({
    variables: { reportId: +exceptionReportId },
  });

  const [disputeExceptionReport] = useRosteringExceptionReportDisputeMutation();

  const exceptionReport = exceptionReportData?.rosteringExceptionReport;

  const validateRequiredInputs = () => {
    if (!disputeFormInputs.disputeNote) {
      setError((s) => ({
        ...s,
        disputeNote: "Dispute reason is required",
      }));
    } else {
      return true;
    }
  };

  const onSubmitDispute = async () => {
    if (validateRequiredInputs() && exceptionReport?.id) {
      try {
        const { data } = await disputeExceptionReport({
          variables: {
            rosteringExceptionReportDisputeId: +exceptionReport.id,
            note: disputeFormInputs.disputeNote,
          },
        });

        if (data?.rosteringExceptionReportDispute?.errors.length) {
          toast.error(
            formatError(data?.rosteringExceptionReportDispute.errors)
          );
          return;
        }

        setPage(ExceptionReportPages.SuccessPage);
        setReportStatus(
          data?.rosteringExceptionReportDispute?.rosteringExceptionReport
            ?.status
        );
      } catch (e) {
        toast.error("An error occurred!");
      }
    }
  };

  return (
    <>
      <Styled.HeaderContainer>
        <FormStyled.Header>
          Tell us your reasons for raising a dispute
        </FormStyled.Header>
      </Styled.HeaderContainer>

      <FormStyled.Container>
        <Styled.DisputeFormTitle>
          Your report will be raised with the next supervisor in line.
        </Styled.DisputeFormTitle>

        {/*tier 2 approvers */}
        <SupervisorsList
          amountOfSupervisorsToDisplay={4}
          supervisorsColumn={2}
          categoryName={
            exceptionReport?.rosteringExceptionReportReasons?.[0]
              .rosteringExceptionReportReasonCategory.name
          }
          isDispute
        />

        <FormGroup style={{ marginTop: 30 }}>
          <FormLabel isRequired style={{ marginLeft: 0 }}>
            What caused you to raise a dispute?
          </FormLabel>
          <FormInput
            as="textarea"
            name="disputeNote"
            placeholder="Please explain is as much detail as you can"
            style={{ maxWidth: "584px", minHeight: "160px" }}
            onChange={onInputChange(setError, setDisputeFormInputs)}
            value={disputeFormInputs.disputeNote}
          />
        </FormGroup>

        {error?.disputeNote && (
          <FormStyled.ErrorLabel>{error.disputeNote}</FormStyled.ErrorLabel>
        )}

        <FormBreakLine />

        <Styled.DisputeFormSubTitle>Original Report</Styled.DisputeFormSubTitle>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <ReportersDetails rosteringExceptionReport={exceptionReport} />
          {/*Tier1 approvers*/}
          <SupervisorsList
            amountOfSupervisorsToDisplay={2}
            supervisorsColumn={1}
            categoryName={
              exceptionReport?.rosteringExceptionReportReasons?.[0]
                .rosteringExceptionReportReasonCategory.name
            }
          />

          {exceptionReport && (
            <DecisionBox
              status={exceptionReport.status}
              approvedAt={exceptionReport.approvedAt}
              rejectedAt={exceptionReport.rejectedAt}
              rosteringExceptionReportNotes={
                exceptionReport.rosteringExceptionReportNotes
              }
              givenCompensation={exceptionReport.givenCompensation}
              entitlement={exceptionReport?.timeOffInLieu?.entitlement}
              isDisputed={false}
            />
          )}
        </div>
        <ExceptionReportDetails rosteringExceptionReport={exceptionReport} />
      </FormStyled.Container>

      <FooterContainer>
        <DefaultButton
          text="Submit Dispute"
          type="blue"
          onClick={onSubmitDispute}
        />
      </FooterContainer>
    </>
  );
};
