import { memo, MouseEventHandler } from "react";
import styled from "styled-components";

import { GLOBAL, GREY } from "@patchworkhealth/web-components";

import { LeaveButtonType } from "../RequestLeaveSection.types";
import { requestLeaveIconsDictionary } from "./shared";

interface Props {
  type: LeaveButtonType;
  onClick: MouseEventHandler<HTMLButtonElement>;
  isActive: boolean;
}

export const LeaveButton = memo(({ type, onClick, isActive }: Props) => {
  const icon = requestLeaveIconsDictionary[type].icon;
  const title = requestLeaveIconsDictionary[type].title;

  return (
    <StyledButton type="button" onClick={onClick} isActive={isActive}>
      {icon}
      {title}
    </StyledButton>
  );
});

const StyledButton = styled.button<{ isActive: boolean }>`
  display: flex;
  padding: 8px;
  align-items: center;
  border-radius: 8px;
  gap: 6px;
  min-width: 170px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  color: ${({ isActive }) => (isActive ? GREY.ten : GREY.four)};
  background: ${({ isActive }) => (isActive ? GREY.one : GLOBAL.white)};

  svg {
    height: 25px;
    width: 25px;
    fill: ${({ isActive }) => (isActive ? GREY.ten : GREY.four)};
    path {
      fill: ${({ isActive }) => (isActive ? GREY.ten : GREY.four)};
    }
  }

  &:hover {
    opacity: 0.7;
    background: ${GREY.one};
    color: ${GREY.ten};
    svg {
      fill: ${GREY.ten};
      path {
        fill: ${GREY.ten};
      }
    }
  }
`;
