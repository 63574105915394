import React from "react";

import { FormGroup, FormToggleButtons } from "@patchworkhealth/web-components";

import * as FormStyled from "components/ExceptionReports/ExceptionReportForms/ExceptionReportForms.styled";
import { RosteringExceptionReportCategory } from "components/ExceptionReports/ExceptionReports.types";

interface Props {
  onToggleChange: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => void;
  exceptionReportCategory: RosteringExceptionReportCategory | null | undefined;
  exceptionReportRequestedCompensation: number | null;
  errors: { requestedCompensation?: string } | null;
}

export const CompensationToggles = ({
  onToggleChange,
  exceptionReportRequestedCompensation,
  exceptionReportCategory,
  errors,
}: Props) => {
  //Hide for No compensation on category
  if (exceptionReportCategory && !exceptionReportCategory.allowCompensation) {
    return null;
  }

  return (
    <FormGroup>
      <FormStyled.RequiredLabel required>
        How would you like to be compensated?
      </FormStyled.RequiredLabel>
      <FormStyled.ToggleWrapper>
        <FormToggleButtons
          name="requestedCompensation"
          input={+(exceptionReportRequestedCompensation ?? -1)}
          text={["TOIL (Time off in Lieu)", "Pay", "No Preference"]}
          action={onToggleChange}
        />
      </FormStyled.ToggleWrapper>
      {errors?.requestedCompensation && (
        <FormStyled.ErrorLabel data-testid="error_compensation">
          {errors.requestedCompensation}
        </FormStyled.ErrorLabel>
      )}
    </FormGroup>
  );
};
