/* eslint-disable no-console */
import { useState } from "react";
import { CloseButton, Modal, ModalTitle } from "react-bootstrap";
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";
// import { log } from "console";
import moment from "moment";

import { DefaultButton, ModalHeader } from "@patchworkhealth/web-components";

import { ShiftCard } from "components/ShiftsNew/ShiftCard";
import { formatError } from "helpers/formatError";

import { Divider } from "../Style";
import { useWorkerSignOffRequestedMultipleMutation } from "./__generated__/Timesheets.generated";
import {
  BreaksIcon,
  calculateModMinimumBreaks,
  ModalShiftType,
  TimesheetsModalFooter,
} from "./TimesheetHelpers";
import {
  CheckboxIcon,
  TimeSheetLeftIcon,
  TimeSheetRightIcon,
} from "./TimesheetIcons";

type ModModalInputsType = {
  tab: number;
  checkbox: boolean;
  checkedShifts: ModalShiftType;
  reason: string;
  loading: boolean;
  activeSlide: number;
  fraudDeclaration: boolean;
};

const emptyInputs: ModModalInputsType = {
  tab: 1,
  checkbox: false,
  checkedShifts: [],
  reason: "",
  loading: false,
  activeSlide: 1,
  fraudDeclaration: false,
};

type ModalProps = {
  onHide: () => void;
  modalShifts: ModalShiftType;
  refetchSignoff: () => void;
};

const TimesheetsModModal = ({
  onHide,
  modalShifts,
  refetchSignoff,
}: ModalProps) => {
  /* State ------------------------------------------------------- */
  const [inputs, setInputs] = useState(emptyInputs);
  const [signOffRequestMutation] = useWorkerSignOffRequestedMultipleMutation();
  const { checkedShifts, tab, activeSlide } = inputs;

  const usesFraudDeclaration =
    modalShifts[0].enforceFraudDeclarationStatement ?? false;

  console.log("modalShifts", modalShifts);

  console.log("Inputs", inputs);

  /* Fraud Declaration Functions ----------------------------- */

  const handleFraudToggle = () => {
    setInputs({
      ...inputs,
      fraudDeclaration: !inputs.fraudDeclaration,
    });
  };

  const viewFraudDeclaration = () => {
    setInputs({
      ...inputs,
      tab: 3,
    });
  };

  /* Functions --------------------------------------------------- */

  const handleToggle = () => {
    setInputs({
      ...inputs,
      checkbox: !inputs.checkbox,
      checkedShifts: !inputs.checkbox ? modalShifts.slice(0, 15) : [],
    });
  };

  const checkFunction = (shift: ModalShiftType[0]) => {
    const copy = [...checkedShifts];
    const index = copy.findIndex((x) => x.id === shift.id);
    index === -1 ? copy.push(shift) : copy.splice(index, 1);
    setInputs({
      ...inputs,
      checkedShifts: copy,
    });
  };

  const checkIcon = (id: number) => checkedShifts.some((el) => el.id === id);

  const resetValues = () => {
    onHide();
    setInputs(emptyInputs);
  };

  /* Handle Slides ---------------------------------------------- */

  const handleSlide = (direction: "prev" | "next") => {
    const slideCount = checkedShifts.length;

    const newActiveSlide =
      direction === "prev"
        ? activeSlide === 1
          ? slideCount
          : activeSlide - 1
        : (inputs.activeSlide + 1) % slideCount || slideCount;

    setInputs({
      ...inputs,
      activeSlide: newActiveSlide,
    });
  };

  const handleDotClick = (index: number) => {
    setInputs({
      ...inputs,
      activeSlide: index,
    });
  };

  const handleBreaks = (shift: ModalShiftType[0], value: string) => {
    const shiftsCopy = [...checkedShifts];
    const index = shiftsCopy.findIndex((x) => x.id === shift.id);
    shiftsCopy[index].modBreakMinutes = value;
    shiftsCopy[index].showReason = true;

    setInputs({
      ...inputs,
      checkedShifts: shiftsCopy,
    });
  };

  /* Handle Reason ------------------------------------------- */

  const handleReason = (shift: ModalShiftType[0], value: string) => {
    setInputs({
      ...inputs,
      checkedShifts: checkedShifts.map((s) =>
        s.id === shift.id ? { ...s, reason: value } : s
      ),
    });
  };

  const handleReset = (shift: ModalShiftType[0]) => {
    const shiftsCopy = [...checkedShifts];
    const index = shiftsCopy.findIndex((x) => x.id === shift.id);

    shiftsCopy[index].startTime = "";
    shiftsCopy[index].endTime = "";
    shiftsCopy[index].showReason = false;
    shiftsCopy[index].reason = "";
    shiftsCopy[index].modStartDate = shiftsCopy[index].originalStartTime;
    shiftsCopy[index].modEndDate = shiftsCopy[index].originalEndTime;
    shiftsCopy[index].modBreakMinutes = "";

    setInputs({
      ...inputs,
      checkedShifts: shiftsCopy,
    });
  };

  // MOD NEW -------------------------------------------------------------

  const selectTimeNew = (
    shift: ModalShiftType[0],
    time: "startTime" | "endTime",
    value: string | Date | null
  ) => {
    setInputs((prevState) => ({
      ...prevState,
      checkedShifts: prevState.checkedShifts.map((s) =>
        s.id === shift.id
          ? {
              ...s,
              [time === "startTime" ? "modStartDate" : "modEndDate"]:
                value ?? "",
              showReason: true,
            }
          : s
      ),
    }));
  };

  // 24hr timepicekrs -----------------------------------------------------

  const selectTimepicker = (
    shift: ModalShiftType[0],
    type: "startTime" | "endTime",
    value: string
  ) => {
    const shiftsCopy = checkedShifts.map((s) =>
      s.id === shift.id ? { ...s, [type]: value, showReason: true } : s
    );

    setInputs((prevState) => ({
      ...prevState,
      checkedShifts: shiftsCopy,
    }));
  };

  // Show Reason Function --------------------------------------------------

  const showReasonFunction = (s: ModalShiftType[0]) => {
    let showReasonBox = false;

    // Difference on inputs are Fine, but also check duration...

    const enteredStartDate = moment(
      `${s.modStartDate} ${s.startTime}`,
      "YYYY-MM-DD HH:mm"
    ).format("YYYY-MM-DDTHH:mm:ss+00:00");

    const enteredEndDate = moment(
      `${s.modEndDate} ${s.endTime}`,
      "YYYY-MM-DD HH:mm"
    ).format("YYYY-MM-DDTHH:mm:ss+00:00");

    // console.log("enteredStartDate", enteredStartDate);
    // console.log("enteredEndDate", enteredEndDate);
    // console.log("s.originalStartTime", s.originalStartTime);
    // console.log("s.originalEndTime", s.originalEndTime);
    // console.log("s.originalBreakMinutes", s.originalBreakMinutes);
    // console.log("s.modBreakMinutes", s.modBreakMinutes);

    if (s.startTime !== "" && s.endTime !== "") {
      if (s.originalStartTime !== enteredStartDate) {
        showReasonBox = true;
      }

      if (s.originalEndTime !== enteredEndDate) {
        showReasonBox = true;
      }

      if (s.originalBreakMinutes !== Number(s.modBreakMinutes)) {
        showReasonBox = true;
      }
    }

    return showReasonBox;
  };

  // Request sign off Mutation ------------------------------------------------------

  const handleSignoff = async () => {
    const shiftsCopy = [...checkedShifts];

    /* 1. Check for no time shifts */
    const noTimeShifts = shiftsCopy.filter(
      (shift) =>
        shift.startTime === "" ||
        shift.endTime === "" ||
        shift.modBreakMinutes === ""
    );

    if (noTimeShifts.length > 0) {
      toast.error(`Please input times for ${noTimeShifts.length} shift(s)`);
      return;
    }

    /* 2. Check for invalid dates */
    const invalidShifts = shiftsCopy.filter((shift) =>
      moment(shift.endTime).isBefore(moment(shift.startTime))
    );

    if (invalidShifts.length > 0) {
      toast.error(`Invalid Dates for ${invalidShifts.length} Shift(s)`);
      return;
    }

    /* 3. Check for invalid breaks */
    const invalidBreaks = shiftsCopy.filter((shift) => {
      const minBreaks = calculateModMinimumBreaks(shift);

      console.log("Min breaks =======", minBreaks);

      return (
        shift.shouldEnforceBreaks && Number(shift.modBreakMinutes) < minBreaks
      );
    });

    if (invalidBreaks.length > 0) {
      toast.error(
        `Minimum Breaks required on ${invalidBreaks.length} Shift(s)`
      );
      return;
    }

    /* 4. Check for no reason shifts if inputs are different from scheduled */
    const noReasonShifts = shiftsCopy.filter((shift) => {
      console.log("sh1111ift", shift);

      const showReason = showReasonFunction(shift);

      console.log("showReason", shift.id, showReason);

      return showReason && shift.reason === "";
    });

    console.log("noReasonShifts", noReasonShifts);

    if (noReasonShifts.length > 0) {
      toast.error(
        `Please add a reason for ${noReasonShifts.length} shift(s) you have amended`
      );

      return;
    }

    /*  prepare variables for mutation */
    // const variables = {
    //   shifts: shiftsCopy.map((shift) => ({
    //     id: shift.id ?? 0,
    //     startTime: moment(
    //       `${shift.modStartDate} ${shift.startTime}`,
    //       "YYYY-MM-DD HH:mm"
    //     ).format("YYYY-MM-DDTHH:mm:ssZ"),
    //     endTime: moment(
    //       `${shift.modEndDate} ${shift.endTime}`,
    //       "YYYY-MM-DD HH:mm"
    //     ).format("YYYY-MM-DDTHH:mm:ssZ"),
    //     breaks: Number(shift.modBreakMinutes),
    //     reportedNotes: shift.reason ? shift.reason : "n/a",
    //   })),
    // };

    const variables = {
      shifts: shiftsCopy.map((shift) => ({
        id: shift.id ?? 0,
        startTime: moment
          .tz(
            `${shift.modStartDate} ${shift.startTime}`,
            "YYYY-MM-DD HH:mm",
            "Europe/London"
          )
          .format("YYYY-MM-DDTHH:mm:ss"), // Notice the removal of 'Z' to exclude timezone information
        endTime: moment
          .tz(
            `${shift.modEndDate} ${shift.endTime}`,
            "YYYY-MM-DD HH:mm",
            "Europe/London"
          )
          .format("YYYY-MM-DDTHH:mm:ss"), // Same here
        breaks: Number(shift.modBreakMinutes),
        reportedNotes: shift.reason ? shift.reason : "n/a",
      })),
    };

    // console.log("variables", variables);

    const toastId = toast.loading("Loading...");

    try {
      const { data: result } = await signOffRequestMutation({
        variables,
      });

      const errors = result?.workerSignOffRequestedMultiple?.errors;

      if (errors && errors.length > 0) {
        if (
          errors[0].message.match(
            /Could not sign off shift, your break should last at least/
          )
        ) {
          toast.error(errors[0].message, { id: toastId });
        } else {
          toast.error(formatError(errors), { id: toastId });
        }
      } else {
        toast.success("Shift(s) successfully signed off!", { id: toastId });
        resetValues();
        refetchSignoff();
      }
    } catch (error) {
      toast.error("There was an error signing off this shift(s)", {
        id: toastId,
      });
      resetValues();
      refetchSignoff();
    }
  };

  return (
    <>
      <Modal
        show={true}
        onHide={onHide}
        backdrop="static"
        centered
        size="lg"
        className="timesheets__modal"
      >
        <ModalHeader>
          <ModalTitle className="text-sm sm:text-lg">
            {tab === 1 && "MOD - Select shifts for sign-off (Max 15)"}
            {tab === 2 && "Please check all Shifts"}
            {tab === 3 && "Fraud Declaration Statement"}
          </ModalTitle>
          <CloseButton onClick={() => resetValues()} />
        </ModalHeader>

        <div>
          {tab === 1 && modalShifts.length > 0 && (
            <div className="p-4">
              <div className="mb-5">
                <h6>Select the timesheet(s) you wish to sign-off.</h6>
                <p>Simply click on a shift to select or de-select it.</p>
              </div>

              <div
                className="grid"
                style={{
                  gridGap: 26,
                  gridTemplateColumns: "repeat(auto-fill, 330px)",
                }}
              >
                {modalShifts.map((shift) => (
                  <div
                    key={shift.id}
                    className="h-[190px] w-[330px] relative mb-4"
                  >
                    <ShiftCard
                      shift={shift}
                      key={shift.id}
                      onClick={() => checkFunction(shift)}
                      isMod={true}
                      modStartDate={shift.modStartDate}
                    />

                    {checkIcon(shift.id ?? 0) && (
                      <div className="absolute right-[-12px] top-[-12px] z-10">
                        <CheckboxIcon />
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <Divider top="40" bottom="0" line="solid" />
            </div>
          )}

          {tab === 2 && (
            <div className="px-4 py-10 border">
              <p className="hidden font-bold sm:block">
                Please input the times worked and breaks taken.
              </p>
              <p className="hidden mb-10 sm:block">
                Use the arrows to go through to view other timesheets
              </p>

              {/* Slide Container ------------------------------------------------------------------------ */}

              <div className="w-full">
                <div className="flex items-center">
                  <button
                    className="justify-center hidden w-20 sm:flex"
                    onClick={() => handleSlide("prev")}
                  >
                    <TimeSheetLeftIcon />
                  </button>

                  {/* Middle Section ---------------------------------------------------------------------- */}

                  <div className="w-full min-h-[350px] sm:bg-gray-100 sm:border p-0 sm:p-4 rounded-lg relative">
                    {checkedShifts.map((shift, index) => {
                      /* Reasons for change should popup if:

                      - start/emd/break times is different from original                      
                      - after that check, we make sure breaks is >= minBreak
                      
                      */

                      const showReasonBox = showReasonFunction(shift);

                      const minAllowedBreaks = shift.shouldEnforceBreaks
                        ? calculateModMinimumBreaks(shift)
                        : shift.breakMinutes;

                      if (index === activeSlide - 1) {
                        return (
                          <>
                            <div
                              className="relative flex flex-wrap w-full "
                              key={index}
                            >
                              <div className="flex-1 p-0 sm:p-4">
                                <ShiftCard
                                  shift={shift}
                                  key={shift.id}
                                  onClick={() => null}
                                  isMod={true}
                                  modStartDate={shift.modStartDate}
                                />
                              </div>

                              {/* Start Time -------------------------------------------- */}
                              <div className="flex-1 px-4 py-2">
                                <div className="flex h-auto mb-2 border-b">
                                  <p className="font-semibold w-[120px] mt-2">
                                    Start Time
                                  </p>

                                  <div className="flex flex-col items-center px-4">
                                    <input
                                      onChange={(e) => {
                                        let modDate = "";
                                        if (e.target.value) {
                                          modDate = e.target.value;
                                        }
                                        selectTimepicker(
                                          shift,
                                          "startTime",
                                          modDate
                                        );
                                      }}
                                      type="time"
                                      value={shift.startTime}
                                      className="w-full h-[20px] rounded-lg border text-center font-[500] cursor-pointer p-5 mb-2"
                                    />

                                    <DatePicker
                                      className="w-full h-[22px] rounded-lg bg-transparent text-center  cursor-pointer p-3"
                                      selected={new Date(shift.modStartDate)}
                                      onChange={(date) => {
                                        const formattedTime =
                                          moment(date).format("YYYY-MM-DD");
                                        selectTimeNew(
                                          shift,
                                          "startTime",
                                          formattedTime
                                        );
                                      }}
                                      timeInputLabel="Select Time:"
                                      dateFormat="do MMM yyyy"
                                      tabIndex={-1}
                                    />
                                  </div>
                                </div>

                                {/* EndTime ------------------------------------------------------------- */}

                                <div className="flex h-auto mb-2 border-b">
                                  <p className="font-semibold w-[120px] mt-2">
                                    End Time
                                  </p>

                                  <div className="flex flex-col px-4 ">
                                    <input
                                      onChange={(e) => {
                                        let modDate = "";
                                        if (e.target.value) {
                                          modDate = e.target.value;
                                        }
                                        selectTimepicker(
                                          shift,
                                          "endTime",
                                          modDate
                                        );
                                      }}
                                      type="time"
                                      value={shift.endTime}
                                      className="w-full h-[20px] rounded-lg border text-center  font-[500] cursor-pointer p-5 mb-2"
                                    />

                                    <DatePicker
                                      className="w-full h-[22px] rounded-lg bg-transparent  text-center  cursor-pointer p-3 "
                                      selected={new Date(shift.modEndDate)}
                                      onChange={(date) => {
                                        const formattedTime =
                                          moment(date).format("YYYY-MM-DD");
                                        selectTimeNew(
                                          shift,
                                          "endTime",
                                          formattedTime
                                        );
                                      }}
                                      timeInputLabel="Select Time:"
                                      dateFormat="do MMM yyyy"
                                      tabIndex={-1}
                                    />
                                  </div>
                                </div>

                                {/* Breaks ------------------------------------------------------------- */}

                                <div className="flex h-auto border-b">
                                  <p className="font-semibold w-[120px] mt-3">
                                    Breaks
                                  </p>

                                  <div className="flex flex-col items-center justify-center px-4">
                                    <input
                                      placeholder=""
                                      className="w-[172px] h-[22px] border rounded-lg text-center font-semibold cursor-pointer p-5 mb-2"
                                      type="number"
                                      min="0"
                                      value={shift?.modBreakMinutes}
                                      onChange={(e) =>
                                        handleBreaks(shift, e.target.value)
                                      }
                                    />
                                    <p className="mb-2">Mins</p>
                                  </div>
                                </div>

                                {/* Reason -------------------------------------------------------------- */}

                                {showReasonBox && (
                                  <div className="h-[66px] flex items-center border-b">
                                    <p className="mr-3 font-semibold">Reason</p>
                                    <input
                                      className="w-[170px] mr-4 h-[38px] border border-gray-300 p-2 rounded-lg"
                                      type="input"
                                      placeholder="Reason for Change"
                                      value={shift.reason ?? ""}
                                      onChange={(e) =>
                                        handleReason(shift, e.target.value)
                                      }
                                    />

                                    <DefaultButton
                                      type={"grey"}
                                      text={"Reset"}
                                      onClick={() => handleReset(shift)}
                                    />
                                  </div>
                                )}
                              </div>

                              {/* End TimeFields -------------------------------------------------------- */}

                              <p className="absolute top-[-10px] font-semibold text-lg mb-4">
                                Shift {index + 1} of {checkedShifts.length}
                              </p>
                            </div>

                            {!shift.shouldEnforceBreaks && (
                              <div
                                style={{ background: "#FFF6DB" }}
                                className="flex items-center mt-4  h-[62px] p-4 text-sm border border-yellow-300 "
                              >
                                <BreaksIcon />

                                <div className="ml-4">
                                  <p className="font-bold">
                                    You must include your actual break taken.
                                  </p>

                                  <p>
                                    If you work less than 6 hours and no break
                                    is taken <strong> you must enter 0</strong>
                                  </p>
                                  <p>
                                    Break must be at least 30mins once you have
                                    completed <strong> 6 hours+</strong> and at
                                    least 60mins for
                                    <strong> 12hours+</strong>
                                  </p>
                                </div>
                              </div>
                            )}

                            {shift.shouldEnforceBreaks &&
                              minAllowedBreaks === 0 && (
                                <div
                                  style={{ background: "#FFF6DB" }}
                                  className="flex items-center mt-4  h-[52px] p-4 text-sm border border-yellow-300"
                                >
                                  <BreaksIcon />

                                  <div className="ml-4">
                                    <p className="font-bold">
                                      You must include your actual break taken.
                                    </p>

                                    <p>
                                      Inline with your organisations minimum
                                      break duration, this must be atleast
                                      <strong>
                                        {" "}
                                        {shift?.gradeBreakMinutes}mins{" "}
                                      </strong>
                                      for every{" "}
                                      <strong>
                                        {shift?.gradeBreakRepeatMinutes}mins{" "}
                                      </strong>
                                      worked.
                                    </p>
                                  </div>
                                </div>
                              )}

                            {shift.shouldEnforceBreaks &&
                              minAllowedBreaks > 0 && (
                                <div
                                  style={{ background: "#FFF6DB" }}
                                  className="flex items-center mt-4  h-[52px] p-4 text-sm border border-yellow-300"
                                >
                                  <BreaksIcon />

                                  <div className="ml-4">
                                    <p className="font-bold">
                                      You must include your actual break taken.
                                    </p>
                                    <p>
                                      You can't submit a break shorter than{" "}
                                      <strong>({minAllowedBreaks})</strong>{" "}
                                      minutes as your organisation has
                                      implemented a minimum break duration.
                                    </p>
                                  </div>
                                </div>
                              )}

                            {shift.workerSignOff && (
                              <p className="font-[600] text-center mt-3">
                                This shift contains hours based on a previous
                                sign off request that was not accepted
                              </p>
                            )}
                          </>
                        );
                      }
                      return null;
                    })}
                  </div>

                  <button
                    className="justify-center hidden w-20 sm:flex"
                    onClick={() => handleSlide("next")}
                  >
                    <TimeSheetRightIcon />
                  </button>
                </div>

                {/* Dots Container ------------------------------------------------------------------- */}

                <div className="flex justify-center mt-4">
                  {checkedShifts.map((shift, index) => (
                    <button
                      key={shift.id}
                      className={`w-3 h-3 m-2 rounded-full transition  ${
                        index + 1 === activeSlide
                          ? "bg-gray-500"
                          : "bg-gray-200"
                      }`}
                      onClick={() => handleDotClick(index + 1)}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}

          {tab === 3 && (
            <div className="min-h-[410px] p-8">
              <p>
                {modalShifts[0].organisation.fraudDeclarationStatement ?? ""}
              </p>
            </div>
          )}

          <TimesheetsModalFooter
            inputs={inputs}
            usesFraudDeclaration={usesFraudDeclaration}
            viewFraudDeclaration={viewFraudDeclaration}
            setInputs={setInputs}
            resetValues={resetValues}
            handleToggle={handleToggle}
            handleFraudToggle={handleFraudToggle}
            handleSignoff={handleSignoff}
          />
        </div>
      </Modal>
    </>
  );
};

export default TimesheetsModModal;
