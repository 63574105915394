import { useContext } from "react";

import { TimesheetsAlert } from "components/Navigation/NavigationLinks";
import { RefreshIcon } from "components/ShiftsNew/ShiftIcons";
import { Button } from "components/UI";
import { AppContext } from "context/AppContext";
import { classNames } from "helpers/newHelpers";

type HeaderProps = {
  page: string;
  setPage: (page: string) => void;
  requiresSignoff: number;
  refetch: () => void;
  isMobile?: boolean;
};

const TimesheetsHeader = ({
  page,
  setPage,
  requiresSignoff,
  refetch,
  isMobile,
}: HeaderProps) => {
  const store = useContext(AppContext);

  const medacsOrganistionIds = [140, 126, 144, 139];

  const isMedacs = store.user?.agencyRegistrations?.some((agencyRegistration) =>
    medacsOrganistionIds.includes(
      agencyRegistration.grade?.organisation.id ?? 0
    )
  );

  /* 
      For the 4 orgs, if the worker has any agencyRegistration - > grade -> org that matches, then show the button:

        NHS Ayr and Arran - 140
        NHS Lanarkshire - 126
        NHS Glasgow - 144
        NHS Dumfries and Galloway - 139
    */

  // const isRSSDemo =
  //   store.user?.agencyRegistrations?.some(
  //     (agencyRegistration) => Number(agencyRegistration?.agency?.id) === 233
  //   ) ||
  //   store.user?.organisationRegistrations?.some(
  //     (organisationRegistration) =>
  //       organisationRegistration?.organisation?.name === "RSS Demo"
  //   );

  return (
    <div className="relative w-full mt-2">
      <div className="flex flex-col items-start sm:w-[400px] mb-5 sm:flex-row sm:justify-between sm:items-center sm:h-10">
        {["Needs Attention", "In Progress", "Finalised"].map((tab, index) => {
          const tabId = index + 1;
          const isActive = tab === page;
          const isSignOff = tabId === 1 && requiresSignoff > 0;

          return (
            <div
              key={tab}
              className={classNames(
                isActive
                  ? "border-black-4 font-bold"
                  : "border-transparent font-normal",
                isMobile ? "w-full p-2" : "",
                "border-b cursor-pointer relative flex mb-5 sm:mb-0  sm:w-auto"
              )}
              onClick={() => setPage(tab)}
            >
              {tab}
              {isSignOff && !isMobile && (
                <TimesheetsAlert number={requiresSignoff} navigation={true} />
              )}
            </div>
          );
        })}
      </div>

      {isMedacs && (
        <Button
          className="w-full mb-4 sm:w-auto top-0 absolute right-[195px]"
          onClick={() => {
            setPage("Create");
          }}
          variant="blue"
        >
          Additional Timesheet
        </Button>
      )}
      {page === "Needs Attention" && (
        <Button
          icon={RefreshIcon}
          className="relative w-full mb-4 sm:w-auto sm:top-0 sm:absolute sm:right-0"
          onClick={() => refetch()}
        >
          Update Timesheets
        </Button>
      )}
    </div>
  );
};
export default TimesheetsHeader;
