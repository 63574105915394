import styled from "styled-components";

import { Button, ButtonAdd, RBBLACK } from "@patchworkhealth/web-components";

interface Props {
  createNewReport: () => void;
  noCategoriesAndReasons: boolean | undefined;
}

const ExceptionReportEmptyState = ({
  createNewReport,
  noCategoriesAndReasons,
}: Props) => {
  return (
    <EmptyStateContainer>
      <ExceptionReportEmptyImage />
      <EmptyStateText>
        {noCategoriesAndReasons
          ? "Exception reporting hasn't been set up yet"
          : "You have no exception reports yet."}
      </EmptyStateText>
      {!noCategoriesAndReasons && (
        <Button
          icon={<ButtonAdd />}
          variant="primary"
          onClick={createNewReport}
        >
          New report
        </Button>
      )}
    </EmptyStateContainer>
  );
};

export default ExceptionReportEmptyState;

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
`;

const EmptyStateText = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: ${RBBLACK.three};
  padding-top: 10px;
`;

const ExceptionReportEmptyImage = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="220" height="168" fill="none">
    <g clipPath="url(#a)">
      <path
        fill="#F2F2F2"
        d="m20.302 167.738-.01.066a12.203 12.203 0 0 1-.035-.196 25.89 25.89 0 0 1 1.004-12.719c.261-.778.558-1.542.891-2.291a25.393 25.393 0 0 1 5.325-7.779 10.96 10.96 0 0 1 2.47-1.895c.133-.071.268-.138.405-.199a4.522 4.522 0 0 1 2.71-.393 3.888 3.888 0 0 1 1.276.494 5.35 5.35 0 0 1 2.342 3.585 8.361 8.361 0 0 1-.117 3.26c-.022.102-.046.203-.07.305-.18.712-.405 1.411-.675 2.094-2.702 6.951-8.508 13.117-15.515 15.668Z"
      />
      <path
        fill="#fff"
        d="M34.008 142.678a146.064 146.064 0 0 0-13.836 24.682c-.092.209.219.392.312.179a145.825 145.825 0 0 1 13.836-24.681c.132-.19-.18-.369-.312-.18Z"
      />
      <path
        fill="#fff"
        d="M36.264 149.689a9.87 9.87 0 0 1-6.49 3.761 9.402 9.402 0 0 1-2.166.043c-.231-.022-.233.338-.004.359 2.684.226 5.35-.616 7.417-2.343a9.759 9.759 0 0 0 1.554-1.64c.139-.185-.174-.363-.311-.18ZM22.734 160.855a8.254 8.254 0 0 1-.912-1.685 8.719 8.719 0 0 1-.56-4.281c.26-.778.557-1.541.89-2.291a.208.208 0 0 1 .017.203 7.888 7.888 0 0 0-.503 1.751 8.332 8.332 0 0 0 1.38 6.125.146.146 0 0 1 .024.141.195.195 0 0 1-.157.115.195.195 0 0 1-.179-.078ZM30 142.696a8.363 8.363 0 0 0 .37 5.197.176.176 0 0 0 .108.084.176.176 0 0 0 .219-.126.184.184 0 0 0-.016-.136 7.96 7.96 0 0 1-.334-4.924.18.18 0 0 0-.346-.095Z"
      />
      <path
        fill="#F2F2F2"
        d="m20.259 159.058.021.063a7.486 7.486 0 0 1-.122-.158 25.884 25.884 0 0 1-5.02-11.729c-.13-.81-.222-1.624-.275-2.443a25.399 25.399 0 0 1 1.101-9.362c.299-.999.739-1.95 1.307-2.825.085-.124.173-.246.266-.364a4.515 4.515 0 0 1 2.217-1.606c.326-.1.664-.155 1.005-.162.118-.003.236-.001.354.006a5.361 5.361 0 0 1 3.74 2.087 8.36 8.36 0 0 1 1.41 2.941c.03.101.056.202.08.303.173.714.298 1.438.376 2.168.837 7.41-1.44 15.567-6.46 21.081Z"
      />
      <path
        fill="#fff"
        d="M20.753 130.5a146.206 146.206 0 0 0-1.085 22.092c.056 2.066.156 4.13.3 6.192.015.228.375.245.358.014a145.847 145.847 0 0 1 .785-28.284c.03-.229-.33-.242-.358-.014Z"
      />
      <path
        fill="#fff"
        d="M26.008 135.661a9.872 9.872 0 0 1-4 6.345c-.59.42-1.228.77-1.899 1.044-.214.088-.049.407.164.32a10.232 10.232 0 0 0 5.48-5.52 9.747 9.747 0 0 0 .614-2.174c.037-.228-.322-.241-.36-.015ZM19.214 151.833a8.233 8.233 0 0 1-1.591-1.069 8.72 8.72 0 0 1-2.485-3.53c-.13-.81-.222-1.625-.275-2.443a.202.202 0 0 1 .077.072c.019.03.03.064.032.1.053.607.177 1.206.368 1.784a8.33 8.33 0 0 0 4.067 4.783.146.146 0 0 1 .087.114.197.197 0 0 1-.181.208.2.2 0 0 1-.1-.019ZM17.212 132.378a8.366 8.366 0 0 0 2.741 4.431c.04.025.09.034.136.023a.18.18 0 0 0 .113-.078.184.184 0 0 0-.055-.248 7.963 7.963 0 0 1-2.583-4.206.178.178 0 0 0-.08-.108.178.178 0 0 0-.133-.023.183.183 0 0 0-.111.076.182.182 0 0 0-.027.133Z"
      />
      <path
        fill="#F2F2F2"
        d="m20.414 167.782.053.042-.188-.067a25.902 25.902 0 0 1-10.547-7.18 25.837 25.837 0 0 1-1.55-1.91 25.403 25.403 0 0 1-4.114-8.481 10.958 10.958 0 0 1-.42-3.084c.004-.151.012-.301.027-.451a4.518 4.518 0 0 1 1.003-2.547 3.726 3.726 0 0 1 1.061-.862 5.348 5.348 0 0 1 4.276-.257 8.38 8.38 0 0 1 2.773 1.719c.078.07.154.141.23.213.53.508 1.025 1.051 1.485 1.624 4.696 5.793 7.171 13.892 5.91 21.241Z"
      />
      <path
        fill="#fff"
        d="M5.448 143.454a145.899 145.899 0 0 0 14.573 24.254c.136.184.449.005.31-.181a145.88 145.88 0 0 1-14.573-24.254c-.099-.209-.409-.027-.31.181Z"
      />
      <path
        fill="#fff"
        d="M12.655 144.973a9.867 9.867 0 0 1 .048 7.499 9.387 9.387 0 0 1-1.038 1.903c-.133.189.179.369.31.181a10.227 10.227 0 0 0 1.644-7.602 9.753 9.753 0 0 0-.653-2.163c-.091-.211-.401-.029-.31.182ZM15.642 162.257a8.244 8.244 0 0 1-1.916-.044 8.718 8.718 0 0 1-3.995-1.637 25.82 25.82 0 0 1-1.548-1.909.205.205 0 0 1 .184.086c.372.482.799.92 1.272 1.305a8.336 8.336 0 0 0 6.003 1.84.149.149 0 0 1 .134.049.197.197 0 0 1-.04.273.199.199 0 0 1-.094.037ZM3.476 146.942a8.372 8.372 0 0 0 4.697 2.258c.097.013.178-.092.18-.18a.186.186 0 0 0-.18-.18 7.962 7.962 0 0 1-4.443-2.152c-.166-.161-.42.093-.254.254Z"
      />
      <path
        fill="#CDCDCD"
        d="M44.951 120.377a2.9 2.9 0 0 0 1.252-2.121 2.907 2.907 0 0 0-.823-2.322l1.13-25.204-5.75.645.334 24.654a2.924 2.924 0 0 0-.004 3.887 2.928 2.928 0 0 0 3.861.461Z"
      />
      <path
        fill="#ECECEC"
        d="m55.44 163.043 3.273.575 3.234-12.346-4.288-.849-2.219 12.62Z"
      />
      <path
        fill="#9FA0A2"
        d="m64.49 167.766-10.285-1.808.698-3.973 6.311 1.11a4.028 4.028 0 0 1 3.24 3.098c.115.517.127 1.051.036 1.573Z"
      />
      <path
        fill="#ECECEC"
        d="M46.458 164.681h3.323l1.58-12.814h-4.903v12.814Z"
      />
      <path
        fill="#9FA0A2"
        d="M56.189 167.766H45.746v-4.034h6.408a4.037 4.037 0 0 1 4.035 4.034ZM60.592 161.172a1.15 1.15 0 0 1-.155-.009l-3.91-.321a1.315 1.315 0 0 1-.905-.521 1.32 1.32 0 0 1-.245-1.013l3.61-20.236-2.44-12.864a.44.44 0 0 0-.738-.235.437.437 0 0 0-.134.278l-3.05 34.312a1.335 1.335 0 0 1-1.412 1.203l-3.685-.138a1.321 1.321 0 0 1-1.23-1.254l-.247-41.125 19.102-2.387 1.334 20.605-.005.022-4.605 22.673a1.322 1.322 0 0 1-1.285 1.01Z"
      />
      <path
        fill="#CDCDCD"
        d="M52.35 74.396a6.656 6.656 0 1 0 0-13.31 6.656 6.656 0 1 0 0 13.31Z"
      />
      <path
        fill="#7C7D80"
        d="M58.621 122.255a5.45 5.45 0 0 1-2.942-.842c-3.225-2.014-6.887-1.214-8.783-.557a1.33 1.33 0 0 1-1.144-.13 1.315 1.315 0 0 1-.603-.963l-3.448-30.788c-.578-5.159 2.53-10.01 7.39-11.533.183-.057.368-.111.557-.163a10.725 10.725 0 0 1 8.936 1.551 10.894 10.894 0 0 1 4.653 7.954l2.902 30.995a1.304 1.304 0 0 1-.413 1.086c-1.018.941-3.971 3.39-7.105 3.39Z"
      />
      <path
        fill="#7C7D80"
        d="m48.122 95.577-7.78-.855a1.55 1.55 0 0 1-1.329-1.933l1.98-7.546a4.303 4.303 0 0 1 8.552.966l.294 7.77a1.55 1.55 0 0 1-1.717 1.598Z"
      />
      <path
        fill="#CDCDCD"
        d="M66.108 119.298a2.918 2.918 0 0 0 .987-2.257 2.915 2.915 0 0 0-1.097-2.206l-1.917-25.157-5.634 1.268 3.307 24.5a2.924 2.924 0 0 0 .466 3.859 2.928 2.928 0 0 0 3.888-.007Z"
      />
      <path
        fill="#7C7D80"
        d="M56.287 93.996a1.547 1.547 0 0 1-.492-1.193l.294-7.77a4.302 4.302 0 0 1 6.955-3.839 4.302 4.302 0 0 1 1.597 2.874l1.98 7.545a1.549 1.549 0 0 1-1.33 1.933l-7.779.855a1.55 1.55 0 0 1-1.225-.405Z"
      />
      <path
        fill="#9FA0A2"
        d="M51.953 74.647c-.118 0-.236-.014-.351-.04l-.034-.01c-5.852-.894-7.146-4.283-7.43-5.699-.294-1.465.041-2.88.797-3.43-.412-1.3-.346-2.455.197-3.43.948-1.702 3.003-2.277 3.28-2.348 1.641-1.21 3.605-.402 3.963-.238 3.176-1.175 4.39-.197 4.61.02 1.419.256 2.284.804 2.571 1.63.54 1.554-1.166 3.486-1.24 3.567l-.037.042-2.542.121a1.724 1.724 0 0 0-1.621 1.983c.068.308.155.612.26.91.434 1.356.76 2.515.34 2.955a.68.68 0 0 1-.712.123c-.397-.106-.667-.083-.801.067-.21.233-.146.822.179 1.66a1.554 1.554 0 0 1-.283 1.584 1.508 1.508 0 0 1-1.146.533Z"
      />
      <path
        fill="#666"
        d="M130.565 95.922H97.772a4.613 4.613 0 0 1-4.607-4.606v-49.27a4.61 4.61 0 0 1 4.607-4.607h32.793a4.613 4.613 0 0 1 4.607 4.606v49.27a4.608 4.608 0 0 1-4.607 4.607ZM97.772 37.981a4.07 4.07 0 0 0-4.065 4.064v49.27a4.069 4.069 0 0 0 4.065 4.065h32.793a4.069 4.069 0 0 0 4.065-4.064v-49.27a4.068 4.068 0 0 0-4.065-4.065H97.772Z"
      />
      <path
        fill="#7C7D80"
        d="M125.551 61.126h-22.765a2.308 2.308 0 0 1-2.304-2.303V47.984a2.308 2.308 0 0 1 2.304-2.303h22.765a2.306 2.306 0 0 1 2.304 2.303v10.84a2.307 2.307 0 0 1-2.304 2.302Z"
      />
      <path
        fill="#CCC"
        d="M123.925 71.287h-19.513a2.17 2.17 0 0 1-2.169-2.168 2.17 2.17 0 0 1 2.169-2.167h19.513a2.169 2.169 0 0 1 0 4.335ZM123.925 79.416h-19.513a2.171 2.171 0 0 1-2.169-2.167 2.17 2.17 0 0 1 2.169-2.168h19.513a2.169 2.169 0 0 1 0 4.335ZM123.925 87.545h-19.513a2.17 2.17 0 0 1-2.169-2.167 2.17 2.17 0 0 1 2.169-2.168h19.513a2.169 2.169 0 0 1 0 4.335Z"
      />
      <path
        fill="#666"
        d="M210.514 123.562h-32.792a4.615 4.615 0 0 1-4.608-4.607v-49.27a4.613 4.613 0 0 1 4.608-4.607h32.792a4.614 4.614 0 0 1 4.608 4.607v49.27a4.615 4.615 0 0 1-4.608 4.607ZM177.722 65.62a4.071 4.071 0 0 0-4.066 4.065v49.27a4.073 4.073 0 0 0 4.066 4.065h32.792a4.068 4.068 0 0 0 4.066-4.065v-49.27a4.068 4.068 0 0 0-4.066-4.065h-32.792Z"
      />
      <path
        fill="#CCC"
        d="M203.875 77.249h-19.514a2.169 2.169 0 0 1 0-4.336h19.514a2.169 2.169 0 0 1 0 4.336ZM203.875 85.377h-19.514a2.169 2.169 0 0 1 0-4.335h19.514a2.169 2.169 0 0 1 0 4.335Z"
      />
      <path
        fill="#E6E6E6"
        d="M205.501 106.107h-22.766a2.309 2.309 0 0 1-2.303-2.303v-10.84a2.302 2.302 0 0 1 2.303-2.302h22.766a2.308 2.308 0 0 1 2.303 2.303v10.839a2.309 2.309 0 0 1-2.303 2.303Z"
      />
      <path
        fill="#CCC"
        d="M203.875 115.726h-19.514a2.167 2.167 0 1 1 0-4.335h19.514a2.167 2.167 0 1 1 0 4.335Z"
      />
      <path
        fill="#666"
        d="M190.459 45.252h-32.793a4.614 4.614 0 0 1-4.607-4.607V10.071a4.609 4.609 0 0 1 4.607-4.606h32.793a4.614 4.614 0 0 1 4.608 4.606v30.574a4.614 4.614 0 0 1-4.608 4.606ZM157.666 6.007a4.07 4.07 0 0 0-4.065 4.064v30.574a4.068 4.068 0 0 0 4.065 4.064h32.793a4.073 4.073 0 0 0 4.066-4.064V10.071a4.072 4.072 0 0 0-4.066-4.064h-32.793Z"
      />
      <path
        fill="#CCC"
        d="M183.819 35.655h-19.513a2.169 2.169 0 0 1 0-4.335h19.513a2.171 2.171 0 0 1 2.169 2.168 2.17 2.17 0 0 1-2.169 2.167ZM183.819 27.526h-19.513a2.169 2.169 0 0 1 0-4.336h19.513a2.171 2.171 0 0 1 2.169 2.168 2.17 2.17 0 0 1-2.169 2.168ZM183.819 19.397h-19.513a2.169 2.169 0 0 1 0-4.335h19.513a2.171 2.171 0 0 1 2.169 2.167 2.17 2.17 0 0 1-2.169 2.168Z"
      />
      <path
        fill="#7C7D80"
        d="M132.462 44.98a6.233 6.233 0 1 0 0-12.465 6.233 6.233 0 0 0 0 12.466Z"
      />
      <path
        fill="#fff"
        d="M131.844 41.126a.647.647 0 0 1-.389-.13l-.007-.004-1.465-1.122a.649.649 0 0 1-.122-.913.652.652 0 0 1 .914-.12l.949.728 2.242-2.924a.652.652 0 0 1 .912-.12l-.014.018.015-.018a.653.653 0 0 1 .12.912l-2.637 3.44a.658.658 0 0 1-.518.253Z"
      />
      <path
        fill="#666"
        d="M213.767 72.89a6.233 6.233 0 1 0-6.234-6.232 6.233 6.233 0 0 0 6.234 6.232Z"
      />
      <path
        fill="#fff"
        d="M213.149 69.036a.646.646 0 0 1-.389-.13l-.007-.005-1.465-1.121a.651.651 0 1 1 .792-1.033l.949.727 2.242-2.923a.65.65 0 0 1 .912-.12l-.014.018.015-.019a.653.653 0 0 1 .12.912l-2.637 3.44a.652.652 0 0 1-.518.254Z"
      />
      <path
        fill="#666"
        d="M193.982 12.464a6.233 6.233 0 1 0-6.233-6.232 6.233 6.233 0 0 0 6.233 6.232Z"
      />
      <path
        fill="#fff"
        d="M193.365 8.61a.644.644 0 0 1-.389-.13l-.007-.004-1.465-1.122a.654.654 0 0 1-.235-.685.651.651 0 0 1 1.027-.348l.948.728 2.243-2.924a.647.647 0 0 1 .912-.12l-.014.018.014-.018a.648.648 0 0 1 .121.912l-2.637 3.439a.652.652 0 0 1-.518.254Z"
      />
      <path
        fill="#666"
        d="M103.528 168H.271a.272.272 0 0 1 0-.542h103.257a.27.27 0 1 1 0 .542Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h220v168H0z" />
      </clipPath>
    </defs>
  </svg>
);
