import { useQuery } from "react-query";

import { ApiRosteringPeriod } from "@patchworkhealth/selfrostering-api";
import { Button, Loading } from "@patchworkhealth/web-components";

import Layout from "components/Layout/Layout";
import { SelfRosteringPeriodCard } from "components/SelfRostering/SelfRosteringPeriodCard";
import { Divider } from "components/Style";
import { rosteringPeriodsApi } from "helpers/kmonoApiClient";
import { FolderPaperIcon } from "icons/FolderPaperIcon";

const SelfRosteringSubmissions = () => {
  const { data: rosteringPeriodsData, isLoading } = useQuery(
    ["selfRosteringSubmissions"],
    () => {
      return rosteringPeriodsApi.getApiRosteringPeriodsWorker();
    }
  );

  return (
    <Layout headerValueText="Roster preferences">
      {rosteringPeriodsData && rosteringPeriodsData.length > 0 ? (
        <div className="flex items-center justify-between mb-8 p-4">
          <div className="flex flex-col">
            <h1 className="text-2xl font-bold">Roster periods</h1>
          </div>

          <Button variant="secondary" onClick={() => {}}>
            {" "}
            2024
          </Button>
        </div>
      ) : null}

      {isLoading && (
        <div className="flex justify-center items-center h-[600px]">
          <Loading />
        </div>
      )}

      {rosteringPeriodsData && rosteringPeriodsData.length > 0 ? (
        rosteringPeriodsData?.map((period: ApiRosteringPeriod) => (
          <SelfRosteringPeriodCard rosteringPeriod={period} key={period.id} />
        ))
      ) : (
        <BlankState />
      )}
    </Layout>
  );
};

export default SelfRosteringSubmissions;

const BlankState = () => {
  return (
    <div className="flex items-center justify-center h-[600px]">
      <div className="text-center min-w-[785px]">
        <div className="flex items-center justify-center mt-14 mb-12">
          <FolderPaperIcon />
        </div>

        <div className="flex flex-col items-center">
          <h2 className="text-3xl font-bold">Welcome to roster preferences</h2>
          <p className="mt-6 text-xl font-medium w-[611px]">
            Patchwork will be adding functionality where your preferences will
            be factored in when scheduling your shifts.
          </p>
        </div>

        <div style={{ margin: "45px 0px 45px 0px" }}>
          <Divider line="solid" top="0" bottom="22" />
        </div>

        <p className="text-left mt-6">
          To find out more or to enable this for your department, please contact
          the Patchwork implementation team.
        </p>
      </div>
    </div>
  );
};
