import * as FormStyled from "components/ExceptionReports/ExceptionReportForms/ExceptionReportForms.styled";

interface Props {
  isSupervisor: boolean;
}

export const NoSupervisorPrompt = ({ isSupervisor }: Props) => {
  if (isSupervisor) return null;

  return (
    <FormStyled.NoSupervisorContainer>
      <p>
        We’ve detected that you do not have a supervisor to approve your
        exception reports.
      </p>
      <p>
        Please contact your roster admin to assign a supervisor for you to
        submit your report.
      </p>
    </FormStyled.NoSupervisorContainer>
  );
};
