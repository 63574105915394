export const getBeginningOfWeek = (
  rosterStartDate?: string | undefined
): Date => {
  const currentDate = rosterStartDate ? new Date(rosterStartDate) : new Date();
  const currentDay = currentDate.getDay();
  const difference = currentDay >= 1 ? currentDay - 1 : 6; // Adjust for Sunday being the first day of the week

  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - difference
  );
};
export const getEndOfWeek = (beginningOfWeek: Date): Date => {
  const endOfWeek = new Date(
    beginningOfWeek.getFullYear(),
    beginningOfWeek.getMonth(),
    beginningOfWeek.getDate() + 6
  );
  endOfWeek.setHours(23, 59, 59, 999); // Set the time to the end of the day
  return endOfWeek;
};

export const addDays = (date: Date, days: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
};

export const getConcurrentDates = (startDate: Date): Date[] => {
  const dates: Date[] = [];

  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(startDate.getTime());
    currentDate.setDate(startDate.getDate() + i);
    dates.push(currentDate);
  }

  return dates;
};

export const isLeftEdgeDate = (
  weekStartDate: Date,
  startDate: string | undefined
) =>
  addDays(weekStartDate, -1).getTime() >= new Date(startDate ?? "").getTime();

export const isRightEdgeDate = (
  weekStartDate: Date,
  endDate: string | undefined
) => addDays(weekStartDate, 7).getTime() <= new Date(endDate ?? "").getTime();
