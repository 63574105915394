import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { Loading } from "@patchworkhealth/web-components";

import Layout from "components/Layout/Layout";
import {
  ShiftCardFragment,
  useBookedShiftsQuery,
} from "components/ShiftsNew/__generated__/Shifts.generated";
import { InitInputs } from "components/ShiftsNew/Shift.types";
import { ShiftCard } from "components/ShiftsNew/ShiftCard";
import { NoShiftCard } from "components/ShiftsNew/ShiftIcons";
import { ShiftsModal } from "components/ShiftsNew/ShiftsModal";
import { BackToTopButton } from "components/UI/BackToTop";

const initInputs: InitInputs = {
  sidebarOpen: false,
  openModal: false,
  selectedShift: null,
};

function PlannerPage() {
  /* State ---------------------------------------------------- */

  const [inputs, setInputs] = useState(initInputs);

  /* Graphql -------------------------------------------------- */

  const { loading, error, fetchMore, data } = useBookedShiftsQuery({
    variables: {
      offset: 0,
      items: 30,
    },
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  /* Functions ------------------------------------------------ */

  const fetchMoreShifts = async () => {
    await fetchMore({
      variables: {
        offset: shiftsArray.length ?? 0,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          ...prev,
          bookedShifts: [...prev.bookedShifts, ...fetchMoreResult.bookedShifts],
          appliedShifts: [
            ...prev.appliedShifts,
            ...fetchMoreResult.appliedShifts,
          ],
        };
      },
    });
  };

  const toggleModal = (e: ShiftCardFragment | null = null) => {
    setInputs({
      ...inputs,
      openModal: !inputs.openModal,
      selectedShift: e || null,
    });
  };

  const shiftsArray = [data?.bookedShifts, data?.appliedShifts]
    .filter(Boolean)
    .flat();

  /* Render ----------------------------------------------------- */

  return (
    <Layout headerValueText="Planner">
      {loading && !data && <Loading />}
      {!loading && shiftsArray.length === 0 && <NoShiftCard />}

      {!error && shiftsArray.length > 0 && (
        <InfiniteScroll
          dataLength={shiftsArray.length}
          next={fetchMoreShifts}
          hasMore
          loader={loading && <Loading />}
          style={{ overflow: "none" }}
        >
          <div className="mb-10 flex flex-wrap border-b border-b-grey-2 pb-8">
            {shiftsArray.map((shift) => {
              if (!shift) return null;
              return (
                <div
                  className="flex w-full justify-center sm:p-0 md:w-[370px] md:justify-start"
                  key={shift?.id}
                >
                  <ShiftCard
                    shift={shift}
                    key={shift?.id}
                    onClick={toggleModal}
                  />
                </div>
              );
            })}
          </div>
        </InfiniteScroll>
      )}

      {error && (
        <p className="font-bold text-center">
          Unable to load shifts, please refresh the page.
        </p>
      )}

      {inputs.openModal && (
        <ShiftsModal
          handleClose={toggleModal}
          openModal={inputs.openModal}
          selectedShift={inputs.selectedShift}
        />
      )}

      <BackToTopButton />
    </Layout>
  );
}

export default PlannerPage;
