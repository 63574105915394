import dayjs from "dayjs";

export const formattedOvertimeString = (
  overtime: string | undefined | null
) => {
  if (!overtime || overtime[0] === "-") return "";
  const hours = +overtime?.slice(0, 2);
  const minutes = +overtime?.slice(4, 6);

  if (!hours) {
    return `+${minutes}m`;
  }
  if (!minutes) {
    return `+${hours}h`;
  }

  return `+${hours}h ${minutes}m`;
};
export const isOvertimeValid = (overtime: string | null | undefined) =>
  overtime && overtime[0] !== "-";

export const calculateShiftDuration = (
  shiftScheduledStartTime: string | undefined | null,
  shiftScheduledEndTime: string | undefined | null,
  shiftDate: string
) => {
  if (!shiftScheduledStartTime || !shiftScheduledEndTime) return "";

  //check if end time is lower than start time, which indicates next day
  const isEndTimeNextDay =
    +shiftScheduledEndTime.replaceAll(":", "") <
    +shiftScheduledStartTime.replaceAll(":", "");

  const dateTimeStart = dayjs(shiftDate + "T" + shiftScheduledStartTime + "z");
  const dateTimeEnd = dayjs(shiftDate + "T" + shiftScheduledEndTime + "z").add(
    isEndTimeNextDay ? 1 : 0,
    "d"
  );

  const totalDiffInMins = dayjs(dateTimeEnd).diff(dateTimeStart, "minute");
  const hours = Math.floor(totalDiffInMins / 60);
  const mins = Math.abs(totalDiffInMins - hours * 60);

  return `${Math.abs(hours)}h ${mins > 0 ? mins + "m" : ""}`;
};
