import { formatTimeInTZ } from "helpers/momentHelpers";

type Props = {
  startTime: string | undefined;
  name: string | undefined;
};

export const WelcomeMessage = ({ startTime, name }: Props) => (
  <div style={{ marginBottom: "20px" }}>
    <h2 style={{ marginBottom: "10px" }}>Hey {name}</h2>
    {startTime ? (
      <p>
        Your next shift:
        <span style={{ fontSize: "13px" }}>
          {" "}
          {formatTimeInTZ(startTime, "dddd Do MMMM")}.
        </span>
      </p>
    ) : (
      <p style={{ marginBottom: "10px" }}>No shifts Rostered</p>
    )}
  </div>
);
