import { CSSProperties } from "react";
import * as Popover from "@radix-ui/react-popover";
import moment, { Moment } from "moment";
import styled from "styled-components";

import {
  FormRadioButtonIcon,
  RBBLUE,
  RBGREY,
} from "@patchworkhealth/web-components";

import {
  PopoverBodyContainer,
  PopoverHeader,
} from "components/ExceptionReports/ExceptionReportShiftCalendar/ExceptionReportShiftCalendar.styled";
import { LeaveRequestComponent } from "components/Shared/LeaveRequests";
import { RosteringShiftList } from "components/Shared/RosteringShiftList";
import { ShiftCardFragment } from "components/ShiftsNew/__generated__/Shifts.generated";
import { classNames } from "helpers/newHelpers";

import {
  LeaveRequestFragmentFragment,
  RotaShiftFragmentFragment,
} from "./__generated__/Calendar.generated";
/* Calendar Types -------------------------------------------------------- */

export type DayData = {
  date: string;
  bank: {
    status: string;
    number: number;
  }[];
  rota: RotaShiftFragmentFragment[];
  leave: LeaveRequestFragmentFragment[];
};

export type MonthData = DayData[];

export type CalendarInputs = {
  page: "Month" | "Day";
  calendarTab: string;
  currentDate: Moment;

  // Single Calendar
  openModal: boolean;
  selectedShift: ShiftCardFragment | null;
  selectedDate: string | null;
  // Rota Calendar
  selectedShiftId: string | null;
  // Leave Calendar
  showLeaveModal: boolean;
  selectedLeaveShift: LeaveRequestFragmentFragment | null;
};

/* Calendar Helpers ------------------------------------------------------ */

export const checkTabs = (productType: number | null) => {
  const tabs = [
    { label: "All Calendars", value: "All" },
    { label: "Bank", value: "Bank" },
    { label: "Substantive", value: "Rota" },
    { label: "Leave only", value: "Leave" },
  ];

  if (productType === 0) return [];

  if (productType === 1)
    return tabs.filter((tab) => tab.value === "Rota" || tab.value === "Leave");

  return tabs;
};

export const getInitialCalendarTab = (productType: number) => {
  if (productType === 0) {
    return "Bank";
  } else if (productType === 1) {
    return "Rota";
  }
  return "All";
};

/* Format Date ---------------------------------------------------------- */

export const FormatDate = ({
  date,
  inputs,
}: {
  date: string;
  inputs: CalendarInputs;
}) => {
  const isToday = moment(date).isSame(moment(), "day");
  const isCurrentMonth = moment(date).isSame(
    inputs.currentDate.clone(),
    "month"
  );

  return (
    <p
      className={classNames(
        "absolute right-0 top-[-28px] text-sm font-semibold",
        isToday
          ? "text-white bg-blue-6 px-[4px] py-[1px] rounded"
          : isCurrentMonth
          ? "text-gray-700"
          : "text-gray-300"
      )}
    >
      {moment(date).format("D")}
    </p>
  );
};

/* Old Form Radio buttons for Cal, replace with web-components later --------- */

const RadioIcon = styled(FormRadioButtonIcon)`
  input:checked ~ & {
    border-color: ${RBBLUE.six};
    border-width: 8px;
  }
`;

interface FormRadioProps {
  value: boolean;
  action: () => void;
  label: string;
  style?: CSSProperties;
  name: string;
}

export const FormRadio = ({ value, action, label, name }: FormRadioProps) => (
  <label className="flex items-center mt-1 ml-5 cursor-pointer select-none">
    <input type="radio" checked={value} onChange={action} hidden name={name} />
    <RadioIcon active={value} />
    <p
      className={classNames(
        "text-sm transition",
        value ? RBBLUE.six : RBGREY.six
      )}
    >
      {label}
    </p>
  </label>
);

/* Multi Calendar ShiftPills ---------------------------------------------- */

export const MultiShiftClass =
  "w-full h-[51px] mb-[4px] rounded border flex items-center justify-center cursor-pointer border-green-500 bg-green-1 hover:bg-green-2 text-green-800 text-sm";

export const MultiRotaCard = ({
  day,
  handleLeaveClick,
  handleRotaClick,
  activitiesLabelForShift,
}: {
  handleLeaveClick: (leave: LeaveRequestFragmentFragment) => void;
  handleRotaClick: (shiftId: string) => void;
  day: DayData;
  activitiesLabelForShift: (id: number, name: string | undefined) => string;
}) => (
  <Popover.Root>
    <Popover.Trigger style={{ width: "100%" }}>
      <div className={MultiShiftClass}>
        + {day.rota.length + day.leave.length} More
      </div>
    </Popover.Trigger>
    <Popover.PopoverClose />
    <Popover.Portal>
      <Popover.Content
        sideOffset={10}
        side="right"
        align="center"
        alignOffset={-15}
      >
        <PopoverBodyContainer>
          <PopoverHeader>{day.date}</PopoverHeader>
          <div className="max-h-[400px] overflow-auto p-2">
            <RosteringShiftList
              activitiesLabelForShift={activitiesLabelForShift}
              shifts={day.rota}
              isMultipleShifts={false}
              onShiftClick={(shift) => handleRotaClick(shift.id)}
              styles={{ width: "100px", gap: "8px" }}
              minShiftHeight={"51px"}
            >
              <div className="max-h-[400px] overflow-auto p-2">
                {day.leave.map((leave) => (
                  <>
                    <LeaveRequestComponent
                      leaveType={leave?.rosteringLeaveType?.leaveType}
                      status={leave?.status || ""}
                      onClick={() => handleLeaveClick(leave)}
                    />
                    <div className="mt-2" />
                  </>
                ))}
              </div>
            </RosteringShiftList>
          </div>
        </PopoverBodyContainer>
      </Popover.Content>
    </Popover.Portal>
  </Popover.Root>
);
