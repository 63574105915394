import { useContext, useState } from "react";

import Layout from "components/Layout";
import {
  LeaveEntitlementModal,
  LeaveHeader,
  LeaveRecordModal,
  LeaveRecordTable,
} from "components/LeaveManagement";
import { useRosteringLeaveRequestsQuery } from "components/LeaveManagement/__generated__/LeaveManagement.generated";
import { LeaveEntitlementType } from "components/LeaveManagement/LeaveEntitlementModal/LeaveEntitlementModal.types";
import { LeaveManagementPageType } from "components/LeaveManagement/LeaveManagement.types";
import { RequestLeaveSection } from "components/LeaveManagement/RequestLeaveSection/RequestLeaveSection";
import { AppContext } from "context/AppContext";
import { RosteringOrganisationRegistrationsType } from "context/AppContext.types";

export const LeaveManagementPage = () => {
  const { user } = useContext(AppContext);

  const [page, setPage] = useState<LeaveManagementPageType>("request-leave");

  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [showRecordModal, setShowRecordModal] = useState(false);
  const [leaveRecordId, setLeaveRecordId] = useState<string | null>(null);

  const [leaveEntitlement, setLeaveEntitlement] =
    useState<LeaveEntitlementType | null>(null);

  const [chosenOrg, setChosenOrg] = useState<
    RosteringOrganisationRegistrationsType[number] | null | undefined
  >(user?.rosteringOrganisationRegistrations?.[0]);

  const { data: leaveRequests, loading: loadingLeaveRequests } =
    useRosteringLeaveRequestsQuery(
      chosenOrg?.organisation
        ? {
            variables: {
              organisationId: chosenOrg?.organisation?.value,
              rosteringOrganisationRegistrationId: Number(chosenOrg?.id),
            },
          }
        : { skip: true }
    );

  const closeModal = () => {
    setShowLeaveModal(false);
    setLeaveEntitlement(null);
  };

  const openModal = (leaveEntitlement: LeaveEntitlementType) => {
    setLeaveEntitlement(leaveEntitlement);
    setShowLeaveModal(true);
  };

  const openModalRecord = (id: string) => {
    setLeaveRecordId(id);
    setShowRecordModal(true);
  };

  const closeModalRecord = () => {
    setShowRecordModal(false);
  };

  return (
    <Layout headerValueText="My Leave Record">
      {!!user?.rosteringOrganisationRegistrations?.length && (
        <LeaveHeader
          page={page}
          setPage={setPage}
          chosenOrg={chosenOrg}
          setChosenOrg={setChosenOrg}
        />
      )}

      {!user?.rosteringOrganisationRegistrations?.length && (
        <p>No Leave Record</p>
      )}

      {page === "request-leave" && (
        <>
          <RequestLeaveSection
            chosenOrg={chosenOrg}
            onOpenEntitlement={openModal}
          />
          {showLeaveModal && (
            <LeaveEntitlementModal
              onHide={closeModal}
              leaveEntitlement={leaveEntitlement}
              chosenOrg={chosenOrg}
            />
          )}
        </>
      )}

      {page === "leave-record" && (
        <>
          <LeaveRecordTable
            onOpenRecord={openModalRecord}
            leaveRequests={leaveRequests}
            loadingLeaveRequests={loadingLeaveRequests}
          />
          {showRecordModal && (
            <LeaveRecordModal
              onHide={closeModalRecord}
              leaveRecordId={leaveRecordId}
              chosenOrg={chosenOrg}
            />
          )}
        </>
      )}
    </Layout>
  );
};
