import { Dispatch, FormEventHandler, SetStateAction } from "react";
import { Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  AuthPasswordEye,
  BLUE,
  Button,
  FormCheckbox,
  FormGroup,
  FormInput,
  FormLabel,
  GLOBAL,
  PasswordCriteriaList,
  RBBLUE,
} from "@patchworkhealth/web-components";

import { Divider, FormContainer, LogoWrapper, Return } from "components/Style";
import { LogoIcon, VerifyIcon } from "icons/login";

type Props = {
  loading: boolean;
  email: string;
  registerSuccess: boolean;
  onSubmit: FormEventHandler<HTMLFormElement>;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  setConfirmPassword: (confirmPassword: string) => void;
  isPasswordHidden: boolean;
  setIsPasswordHidden: Dispatch<SetStateAction<boolean>>;
  setTermsAccepted: Dispatch<SetStateAction<boolean>>;
  termsAccepted: boolean;
};

export const SignUp = ({
  loading,
  email,
  registerSuccess,
  onSubmit,
  setEmail,
  setPassword,
  setConfirmPassword,
  isPasswordHidden,
  setIsPasswordHidden,
  setTermsAccepted,
  termsAccepted,
}: Props) => {
  return (
    <Container>
      {!registerSuccess ? (
        <FormContainer data-testid="test__signup">
          <LogoWrapper>
            <LogoIcon />
          </LogoWrapper>
          <Form
            autoComplete="off"
            data-testid="test_signup__form"
            onSubmit={onSubmit}
          >
            <FormGroup>
              <FormLabel style={{ color: GLOBAL.white }}>
                Email Address
              </FormLabel>
              <FormInput
                autoFocus
                data-testid="test__signup__email"
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                name="email"
                required
                placeholder="Enter email address"
              />
            </FormGroup>
            <Divider top="10" bottom="0" line="none" />
            <FormGroup>
              <FormLabel style={{ color: GLOBAL.white }}>Password</FormLabel>
              <FormInput
                data-testid="test__signup__password"
                onChange={(e) => setPassword(e.target.value)}
                type={isPasswordHidden ? "password" : "text"}
                name="password"
                required
                placeholder="Enter password"
                autoComplete="off"
              />

              <AuthPasswordEye onClick={() => setIsPasswordHidden((s) => !s)} />
            </FormGroup>
            <Divider top="10" bottom="0" line="none" />
            <FormGroup>
              <FormLabel style={{ color: GLOBAL.white }}>
                Confirm Password
              </FormLabel>
              <FormInput
                data-testid="test__signup__confirm"
                onChange={(e) => setConfirmPassword(e.target.value)}
                type={isPasswordHidden ? "password" : "text"}
                name="confirm"
                required
                placeholder="Re-type password"
                autoComplete="off"
              />

              <AuthPasswordEye
                data-testid="test__signup__eye"
                onClick={() => setIsPasswordHidden((s) => !s)}
              />
            </FormGroup>

            <PasswordCriteriaList />

            <Divider top="0" bottom="20" line="none" />

            <CheckboxWrapper>
              <FormCheckbox
                style={{ marginTop: 8 }}
                value={termsAccepted}
                action={() => {
                  setTermsAccepted((s) => !s);
                }}
              />
              <p>
                Do you agree with our{" "}
                <a
                  target="_blank"
                  href="https://www.patchwork.health/terms/"
                  rel="noreferrer"
                >
                  terms
                </a>{" "}
                and{" "}
                <a
                  target="_blank"
                  href="https://www.patchwork.health/privacy/"
                  rel="noreferrer"
                >
                  {" "}
                  privacy policy?
                </a>
              </p>
            </CheckboxWrapper>

            <Divider top="0" bottom="20" line="none" />
            <Button
              variant="success"
              loading={loading}
              type="submit"
              style={{
                width: "100%",
                fontSize: "14px",
                fontWeight: 500,
                color: RBBLUE.five,
              }}
            >
              Setup account
            </Button>
          </Form>

          <Return>
            <Link to={"/"}>← Return home</Link>
          </Return>
        </FormContainer>
      ) : (
        <SuccessContainer data-testid="test__signup__success">
          <VerifyIcon />
          <h3>Verify your email</h3>
          <p>
            We've sent you a verification email to '{email}', go to your inbox
            and click the link to verify your account.
          </p>
          <Divider top="0" bottom="40" line="none" />
          <Return>
            <Link to={"/"}>← Return home</Link>
          </Return>
        </SuccessContainer>
      )}
    </Container>
  );
};

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: 12px;
  }

  a {
    font-weight: bold;
    color: ${BLUE.six};
  }
`;

const SuccessContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 380px;
  text-align: center;
  width: 100%;

  * {
    color: white !important;
  }

  h3 {
    margin-bottom: 20px;
    margin-top: 20px;
  }
`;
