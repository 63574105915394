import styled from "styled-components";

import { GLOBAL } from "@patchworkhealth/web-components";

import { RosteringTeamRotaQuery } from "components/TeamRota/__generated__/TeamRota.generated";

type TeamRotaGrade =
  RosteringTeamRotaQuery["rosteringTeamRota"][number]["grade"];

interface Props {
  grade: TeamRotaGrade;
}

export const GradeLineDivider = ({ grade }: Props) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <GradeLabel gradeColor={grade?.colour}>
      <p>{grade?.title}</p>
    </GradeLabel>
    <Line gradeColor={grade?.colour} data-testid="grade_line" />
  </div>
);

const GradeLabel = styled.div<{
  gradeColor: string | undefined | null;
}>`
  width: fit-content;
  padding: 0 8px;
  height: 18px;
  background: ${({ gradeColor }) => gradeColor ?? ""};
  border-radius: 8px;
  display: flex;
  align-items: center;
  p {
    color: ${GLOBAL.white};
    white-space: nowrap;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
  }
`;

const Line = styled.div<{
  gradeColor: string | undefined | null;
}>`
  background: ${({ gradeColor }) => gradeColor ?? ""};
  width: 100%;
  height: 2px;
`;
