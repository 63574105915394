import { useQuery } from "@apollo/client";

import { GetSailFlagDocument } from "gql/__generated__/Queries.generated";

export enum SailFlag {
  showSelfRostering = "self_rostering",
  servicePlansEnabled = "service_plans_for_web_worker",
}

export const useFeatureFlag = (flag: SailFlag) => {
  const { data } = useQuery(GetSailFlagDocument, {
    variables: {
      name: flag,
    },
  });
  return !!data?.sailGet;
};
