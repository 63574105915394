import { ReactNode } from "react";

export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

export const getColumnClassNames = (columns: number, gap = "gap-4") => {
  let colSize;
  switch (columns) {
    case 2:
      colSize = "sm:grid-cols-2";
      break;
    case 3:
      colSize = "sm:grid-cols-3";
      break;
    case 4:
      colSize = "sm:grid-cols-4";
      break;
    default:
      colSize = "sm:grid-cols-1";
  }
  return `${colSize} grid grid-cols-1 ${gap}`;
};

export const FormGrid = ({
  disabled = false,
  children,
  border,
  title,
  columns = 1,
}: {
  children: ReactNode;
  border?: boolean;
  columns?: 1 | 2 | 3 | 4;
  title?: string;
  disabled?: boolean;
}) => {
  return (
    <div
      className={classNames(
        border
          ? "relative mb-6 border-b border-grey-2 pb-6 last:mb-0 last:border-0"
          : "",
        disabled ? "pointer-events-none" : "",
        "pb-4 last:pb-0"
      )}
    >
      {title && <h3 className="mb-6 font-medium text ">{title}</h3>}
      <div className={getColumnClassNames(columns)}>{children}</div>
    </div>
  );
};
