import { ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";

import { classNames } from "helpers/newHelpers";

interface Props extends LinkProps {
  isActive: boolean;
  icon: ReactNode;
  label: ReactNode;
  isHidden?: boolean;
}

export const NavigationLink = ({
  icon,
  label,
  isActive,
  isHidden,
  ...props
}: Props) => {
  if (isHidden) return null;

  const hoverClass = "hover:bg-cyan-1";

  return (
    <Link
      className={classNames(
        "flex items-center  h-10 mt-[5px] mr-5 overflow-hidden pl-5 relative text-sm rounded-r-lg no-underline [&>svg]:mr-3",
        isActive
          ? "font-semibold bg-cyan-1 text-black-5"
          : "font-medium text-gray-600",
        hoverClass
      )}
      {...props}
    >
      {icon}
      <span>{label}</span>
    </Link>
  );
};
