export const BeamerButton = () => (
  <button
    className="flex items-center align-middle  h-10 mt-[5px] mr-5 overflow-hidden pl-5 relative text-sm rounded-r-lg no-underline [&>svg]:mr-3   "
    id="beamerBtn"
    aria-label="Product Updates"
  >
    <QuestionMarkIcon />
    <span>What's new?</span>
  </button>
);

const QuestionMarkIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.946 11.063a1.23 1.23 0 0 0-.093.117.592.592 0 0 0-.07.14.5.5 0 0 0-.047.14 1.058 1.058 0 0 0 0 .156.655.655 0 0 0 .062.296.701.701 0 0 0 .421.421.731.731 0 0 0 .592 0 .701.701 0 0 0 .421-.42.78.78 0 0 0 .047-.297.779.779 0 0 0-1.333-.553ZM8.5.708a7.792 7.792 0 1 0 0 15.583A7.792 7.792 0 0 0 8.5.708Zm0 14.025a6.234 6.234 0 1 1 0-12.467 6.234 6.234 0 0 1 0 12.467Zm0-10.13a2.337 2.337 0 0 0-2.026 1.17.78.78 0 1 0 1.348.779.78.78 0 0 1 1.229-.162.78.78 0 0 1-.551 1.33.78.78 0 0 0-.78.78v.779a.78.78 0 0 0 1.559 0v-.14a2.338 2.338 0 0 0-.78-4.535Z"
      fill="#7C7D80"
    />
  </svg>
);
