import { OnCallInputsType } from "./TimesheetHelpers";

const TimesheetsOnCallPreview = ({ inputs }: { inputs: OnCallInputsType }) => {
  return (
    <div className="flex-1 [&>p]:mb-5">
      {inputs.previewArray.map((rate, index: number) => {
        const { preview } = rate;

        if (!preview || preview?.length === 0) {
          return <h2 key={index}>There was an error!</h2>;
        }

        if (preview) {
          const showMultiple = preview?.length > 1;
          return (
            <div
              className="border bg-white w-[330px] sm:w-[437px] p-3 rounded-xl shadow-sm [&>p]:mb-2 mb-4"
              key={index}
            >
              {showMultiple && (
                <>
                  <p>
                    <strong>On-Call Hours:</strong> {preview[0]?.hours} hrs
                    (including breaks )
                  </p>
                  <p>
                    <strong>On-Call Pay:</strong> {preview[0].hours} hrs x £
                    {preview[0].totalRate} = £{preview[0].totalPay}
                  </p>
                  <p className="mt-4">
                    <strong>Call Out Hours:</strong> {preview[1].hours}
                    hrs
                  </p>
                  <p>
                    <strong> Call Out Pay:</strong> {preview[1].hours} hrs x £
                    {preview[1].totalRate} = £{preview[1].totalPay}
                  </p>
                </>
              )}

              {!showMultiple && preview[0]?.paymentType === "OnCall" && (
                <>
                  <p>
                    <strong>On-Call Hours:</strong> {preview[0]?.hours} hrs (
                    including breaks)
                  </p>
                  <p>
                    <strong>On-Call Pay:</strong> {preview[0]?.hours} hrs x £
                    {preview[0]?.totalRate} = £{preview[0]?.totalPay}
                  </p>
                </>
              )}

              {!showMultiple && preview[0].paymentType === "CallOut" && (
                <>
                  <p>
                    <strong>Call Out Hours:</strong> {preview[0].hours}
                    hrs
                  </p>

                  <p>
                    <strong> Call Out Pay:</strong> {preview[0].hours} hrs x £
                    {preview[0].totalRate} = £{preview[0].totalPay}
                  </p>
                </>
              )}

              <div className="mt-6">
                <p className="text-sm font-semibold">Total pay</p>
                <p className="text-2xl font-semibold">
                  £
                  {showMultiple
                    ? (preview[0].totalPay + preview[1].totalPay).toFixed(2)
                    : preview[0].totalPay}
                </p>
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default TimesheetsOnCallPreview;
