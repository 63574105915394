import { ChangeEvent, Dispatch, SetStateAction } from "react";
import dayjs from "dayjs";

import { InitFilters, InitInputs } from "components/ShiftsNew/Shift.types";

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const createFormFunctions = <
  InputsT extends Record<keyof InputsT, unknown> & InitInputs
>(
  setFiltersFunc: Dispatch<SetStateAction<InitFilters>>,
  setInputsFunc?: Dispatch<SetStateAction<InputsT>>,
  setErrorFunc?: Dispatch<null>
) => {
  return {
    handleFilter: (
      event: unknown,
      inputNameToUpdateStateObject: keyof InitFilters
    ) => {
      setErrorFunc?.(null);
      setInputsFunc?.((s) => ({
        ...s,
        showClearFilters: true,
      }));

      setFiltersFunc((s) => ({
        ...s,
        [inputNameToUpdateStateObject]: event ?? [],
      }));
    },

    handleInputChange: ({
      target: { name, value },
    }: ChangeEvent<HTMLInputElement>) => {
      setErrorFunc?.(null);
      setFiltersFunc((s) => ({ ...s, [name]: value }));
    },

    handleDateChange: (day: string | null, name: string) => {
      setInputsFunc?.((s) => ({
        ...s,
        showClearFilters: true,
      }));

      if (day === undefined) {
        setFiltersFunc((inputs) => ({
          ...inputs,
          [name]: undefined,
        }));
      } else {
        setFiltersFunc((inputs) => ({
          ...inputs,
          [name]: dayjs(day).format("YYYY-MM-DD"),
        }));
      }
    },
  };
};

// TODO - look to remove this for new solution in future

export const formatForReactSelect = (
  /*eslint-disable-next-line @typescript-eslint/no-explicit-any */
  data: any,
  includeOtherProperties?: string
) => {
  if (!data) return null;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const FindValueAndLabel = (object: any) => {
    //takes an object and formats it for react-select
    //by finding an id value (number) and label (string)
    const cloneObject = { ...object };
    delete cloneObject.__typename;
    const arrayOfPropertyValues = Object.values(cloneObject);
    const filteredArrayOfPropertyValues = arrayOfPropertyValues.filter(
      (e) => typeof e === "number" || typeof e === "string"
    );

    let value;
    let label;

    if (
      typeof filteredArrayOfPropertyValues[0] === "number" ||
      !Number.isNaN(Number(filteredArrayOfPropertyValues[0]))
      // some id's come back as strings, try to coerce into number
    ) {
      value = filteredArrayOfPropertyValues[0];
      label = filteredArrayOfPropertyValues[1];
    } else {
      value = filteredArrayOfPropertyValues[1];
      label = filteredArrayOfPropertyValues[0];
    }

    if (includeOtherProperties === "includeOtherProperties") {
      return { ...object, value, label };
    } else {
      return { value, label };
    }
  };
  if (Array.isArray(data)) {
    return data?.map((e) => FindValueAndLabel(e));
  } else {
    return FindValueAndLabel(data);
  }
};

/* Format Statuses ---------------------------- */

export const formatStatus = (status: string) => {
  switch (status) {
    case "AVAILABLE":
      return "Available";
    case "URGENT":
      return "Urgent";
    case "BOOKED" || "PROVISIONALLY_BOOKED":
      return "Booked";
    case "TO_SIGN_OFF":
      return "To Sign Off";
    case "TO_SIGN_OFF_ON_HUB":
      return "To Sign Off";
    case "TO_SIGN_OFF_ON_HUB_OR_APP":
      return "To Sign Off";
    case "SIGN_OFF_REQUESTED":
      return "Requested";
    case "SIGNED_OFF":
      return "Signed Off";
    case "APPROVED":
      return "Approved";
    case "PAID":
      return "Payroll";
    default:
      return "Available";
  }
};
