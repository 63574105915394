import { useContext, useState } from "react";
import { ExceptionReportStatusEnum } from "baseCODEGEN";
import { StringParam, useQueryParams } from "use-query-params";

import { Loading } from "@patchworkhealth/web-components";

import {
  useRosteringExceptionReportReasonCategoriesQuery,
  useRosteringExceptionReportsQuery,
  useRosteringWorkerMostRecentShiftQuery,
} from "components/ExceptionReports/__generated__/ExceptionReports.generated";
import { ExceptionReportLayout } from "components/ExceptionReports/components/ExceptionReportLayout";
import ExceptionReportsHeader from "components/ExceptionReports/components/ExceptionReportsHeader";
import { DisputeExceptionReportForm } from "components/ExceptionReports/ExceptionReportForms/DisputeExceptionReportForm/DisputeExceptionReportForm";
import { ManualExceptionReportForm } from "components/ExceptionReports/ExceptionReportForms/ManualExceptionReportForm/ManualExceptionReportForm";
import { ShiftExceptionReportForm } from "components/ExceptionReports/ExceptionReportForms/ShiftExceptionReportForm/ShiftExceptionReportForm";
import {
  ExceptionReportPages,
  ExceptionReportsType,
  RosteringShiftEventType,
} from "components/ExceptionReports/ExceptionReports.types";
import { ExceptionReportShiftCalendar } from "components/ExceptionReports/ExceptionReportShiftCalendar/ExceptionReportShiftCalendar";
import ExceptionReportEmptyState from "components/ExceptionReports/ExceptionReportsTable/ExceptionReportEmptyState";
import ExceptionReportsTable from "components/ExceptionReports/ExceptionReportsTable/ExceptionReportsTable";
import { ExceptionReportModal } from "components/ExceptionReports/modals/ExceptionReportModal/ExceptionReportModal";
import ReviewPage from "components/ExceptionReports/ReviewPage/ReviewPage";
import SuccessPage from "components/ExceptionReports/SuccessPage/SuccessPage";
import Layout from "components/Layout";
import { AppContext } from "context/AppContext";

const ExceptionReportsPage = () => {
  const { user } = useContext(AppContext);
  const [modalParams, setModalParams] = useQueryParams(
    {
      exceptionReportId: StringParam,
    },
    { updateType: "replace" }
  );

  const [exceptionReportId, setExceptionReportId] = useState<string>("");
  const [shiftEvent, setShiftEvent] = useState<RosteringShiftEventType>();
  const [page, setPage] = useState<ExceptionReportPages>(
    ExceptionReportPages.ExceptionReportHome
  );
  const [shiftFlow, setShiftFlow] = useState(false);
  const [reportStatus, setReportStatus] = useState<
    ExceptionReportStatusEnum | undefined
  >();

  const orgId = user?.rosteringOrganisations?.[0]?.id;
  const orgRegId = user?.rosteringOrganisationRegistrations?.[0]?.id;

  const { data: workerMostRecentShift, loading: mostRecentShiftLoading } =
    useRosteringWorkerMostRecentShiftQuery(
      orgRegId
        ? {
            variables: { rosteringOrganisationRegistrationId: +orgRegId },
          }
        : { skip: true }
    );

  const { data: exceptionReports, loading: exceptionReportLoading } =
    useRosteringExceptionReportsQuery(
      orgId
        ? {
            variables: {
              organisationId: orgId,
            },
          }
        : { skip: true }
    );

  const { data: categoriesAndReasons, loading: categoriesReasonsLoading } =
    useRosteringExceptionReportReasonCategoriesQuery();

  const noCategoriesAndReasons =
    !categoriesAndReasons ||
    !categoriesAndReasons.rosteringExceptionReportReasonCategories.length;

  const sortedExceptionReports = exceptionReports?.rosteringExceptionReports
    ?.slice()
    .sort((a, b) => +b.id - +a.id);

  const createNewReport = () => {
    if (workerMostRecentShift?.rosteringWorkerMostRecentShift) {
      setExceptionReportId("");
      setPage(ExceptionReportPages.ShiftCalendar);
      setShiftFlow(true);
    } else {
      setExceptionReportId("");
      setPage(ExceptionReportPages.ManualForm);
    }
  };

  const returnHome = () => {
    setPage(ExceptionReportPages.ExceptionReportHome);
  };

  const goBack = () => {
    if (page === ExceptionReportPages.ReviewPage) {
      shiftFlow
        ? setPage(ExceptionReportPages.ShiftForm)
        : setPage(ExceptionReportPages.ManualForm);

      return;
    }

    if (page === ExceptionReportPages.ShiftForm) {
      if (exceptionReportId) {
        returnHome();
        return;
      }
      setPage(ExceptionReportPages.ShiftCalendar);
      return;
    }

    returnHome();
  };

  const editExceptionReport = (report: ExceptionReportsType) => {
    setExceptionReportId(report.id);
    if (report.rosteringEvent) {
      setPage(ExceptionReportPages.ShiftForm);
      setShiftFlow(true);
    } else {
      setPage(ExceptionReportPages.ManualForm);
      setShiftFlow(false);
    }
  };

  const viewExceptionReport = (id: string) => {
    setExceptionReportId(id);
    setModalParams({ exceptionReportId: id });
  };

  const onHideExceptionReportModal = () => {
    setModalParams({});
  };

  const getHeaderText = () => {
    if (page === ExceptionReportPages.DisputeForm) {
      return "Exception Reports - Dispute Decision";
    }

    if (
      page === ExceptionReportPages.SuccessPage &&
      reportStatus === ExceptionReportStatusEnum.Rejected
    ) {
      return "Exception Reports";
    }

    return "Exception Reports - New Report";
  };

  if (
    mostRecentShiftLoading ||
    exceptionReportLoading ||
    categoriesReasonsLoading
  )
    return (
      <Layout headerValueText="Exception Reports">
        <Loading
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        />
      </Layout>
    );

  if (page === ExceptionReportPages.ExceptionReportHome) {
    return (
      <Layout headerValueText="Exception Reports">
        {!exceptionReports?.rosteringExceptionReports?.length ||
        noCategoriesAndReasons ? (
          <ExceptionReportEmptyState
            createNewReport={createNewReport}
            noCategoriesAndReasons={noCategoriesAndReasons}
          />
        ) : (
          <>
            <ExceptionReportsHeader createNewReport={createNewReport} />
            <ExceptionReportsTable
              exceptionReports={sortedExceptionReports}
              viewExceptionReport={viewExceptionReport}
              editExceptionReport={editExceptionReport}
              loading={exceptionReportLoading}
            />
            {!!modalParams.exceptionReportId && (
              <ExceptionReportModal
                exceptionReportId={modalParams.exceptionReportId}
                onHide={onHideExceptionReportModal}
                show={!!modalParams.exceptionReportId}
                setPage={setPage}
                setExceptionReportId={setExceptionReportId}
              />
            )}
          </>
        )}
      </Layout>
    );
  }

  return (
    <ExceptionReportLayout
      page={page}
      returnHome={goBack}
      headerValueText={getHeaderText()}
      navigationText="Back"
    >
      {page === ExceptionReportPages.ManualForm && (
        <ManualExceptionReportForm
          returnHome={returnHome}
          exceptionReportId={exceptionReportId}
          setExceptionReportId={setExceptionReportId}
          setPage={setPage}
          setShiftFlow={setShiftFlow}
          workerMostRecentShift={
            workerMostRecentShift?.rosteringWorkerMostRecentShift
          }
        />
      )}
      {page === ExceptionReportPages.ShiftCalendar && (
        <ExceptionReportShiftCalendar
          setPage={setPage}
          setShiftFlow={setShiftFlow}
          setShiftEvent={setShiftEvent}
          loading={mostRecentShiftLoading}
          workerMostRecentShift={
            workerMostRecentShift?.rosteringWorkerMostRecentShift
          }
        />
      )}
      {page === ExceptionReportPages.ShiftForm && (
        <ShiftExceptionReportForm
          exceptionReportId={exceptionReportId}
          setExceptionReportId={setExceptionReportId}
          shiftEvent={shiftEvent}
          setShiftEvent={setShiftEvent}
          setPage={setPage}
          returnHome={returnHome}
        />
      )}
      {page === ExceptionReportPages.ReviewPage && (
        <ReviewPage
          setPage={setPage}
          setReportId={setExceptionReportId}
          reportId={exceptionReportId}
        />
      )}
      {page === ExceptionReportPages.SuccessPage && (
        <SuccessPage
          returnHome={returnHome}
          reportId={exceptionReportId}
          setReportId={setExceptionReportId}
          reportStatus={reportStatus}
        />
      )}
      {page === ExceptionReportPages.DisputeForm && (
        <DisputeExceptionReportForm
          exceptionReportId={exceptionReportId}
          setPage={setPage}
          setReportStatus={setReportStatus}
        />
      )}
    </ExceptionReportLayout>
  );
};

export default ExceptionReportsPage;
