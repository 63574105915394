import * as Types from '../../../../../baseCODEGEN';

import { gql } from '@apollo/client';
import { ErrorFragmentDoc } from '../../../../../gql/new-gql/__generated__/fragments.generated';
import { ExceptionReportFragmentDoc } from '../../../__generated__/ExceptionReports.generated';
import * as Apollo from '@apollo/client';
import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from 'msw'
const defaultOptions = {} as const;
export type RosteringExceptionReportCreateMutationVariables = Types.Exact<{
  rosteringOrganisationRegistrationId: Types.Scalars['Int'];
  breakActualTime?: Types.InputMaybe<Types.Scalars['String']>;
  details?: Types.InputMaybe<Types.Scalars['String']>;
  breakScheduledTime?: Types.InputMaybe<Types.Scalars['String']>;
  differenceInWorkedHours?: Types.InputMaybe<Types.NoYesEnum>;
  mostSeniorClinician?: Types.InputMaybe<Types.Scalars['String']>;
  mostSeniorClinicianId?: Types.InputMaybe<Types.Scalars['Int']>;
  patientSafetyImpacted?: Types.InputMaybe<Types.NoYesEnum>;
  requestedCompensation?: Types.InputMaybe<Types.ExceptionReportRequestedCompensationEnum>;
  rosteringExceptionReportReasonIds?: Types.InputMaybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
  location?: Types.InputMaybe<Types.Scalars['String']>;
  shiftActualEndTime?: Types.InputMaybe<Types.Scalars['String']>;
  shiftActualStartTime?: Types.InputMaybe<Types.Scalars['String']>;
  shiftDate?: Types.InputMaybe<Types.Scalars['Date']>;
  shiftScheduledEndTime?: Types.InputMaybe<Types.Scalars['String']>;
  shiftScheduledStartTime?: Types.InputMaybe<Types.Scalars['String']>;
  rosteringEventId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type RosteringExceptionReportCreateMutation = { __typename?: 'Mutation', rosteringExceptionReportCreate?: { __typename?: 'RosteringExceptionReportCreatePayload', errors: Array<{ __typename?: 'ValidationError', attribute?: string | null, message: string }>, rosteringExceptionReport?: { __typename?: 'RosteringExceptionReport', id: string, location?: string | null, overtime?: string | null, sentToEsr: boolean, sentToEsrAt?: any | null, shiftDate?: any | null, shiftScheduledEndTime?: string | null, shiftScheduledStartTime?: string | null, status: Types.ExceptionReportStatusEnum, submittedAt?: any | null, rosteringExceptionReportReasons?: Array<{ __typename?: 'RosteringExceptionReportReason', name: string, id: string, rosteringExceptionReportReasonCategory: { __typename?: 'RosteringExceptionReportReasonCategory', allowCompensation: boolean, name: string, id: string } }> | null, rosteringEvent?: { __typename?: 'RosteringEvent', id: string, startsAt: any, endsAt: any, roster: { __typename?: 'RosteringRoster', id: number, rota: { __typename?: 'RosteringRota', id: string, name: string } }, scheduleable?: { __typename?: 'RosteringShift', shiftType?: { __typename?: 'RosteringShiftType', id: string, name: string } | null } | null } | null } | null } | null };

export type RosteringExceptionReportUpdateMutationVariables = Types.Exact<{
  rosteringExceptionReportUpdateId: Types.Scalars['Int'];
  breakActualTime?: Types.InputMaybe<Types.Scalars['String']>;
  breakScheduledTime?: Types.InputMaybe<Types.Scalars['String']>;
  details?: Types.InputMaybe<Types.Scalars['String']>;
  differenceInWorkedHours?: Types.InputMaybe<Types.NoYesEnum>;
  location?: Types.InputMaybe<Types.Scalars['String']>;
  mostSeniorClinician?: Types.InputMaybe<Types.Scalars['String']>;
  patientSafetyImpacted?: Types.InputMaybe<Types.NoYesEnum>;
  requestedCompensation?: Types.InputMaybe<Types.ExceptionReportRequestedCompensationEnum>;
  rosteringExceptionReportReasonIds?: Types.InputMaybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
  shiftActualEndTime?: Types.InputMaybe<Types.Scalars['String']>;
  shiftActualStartTime?: Types.InputMaybe<Types.Scalars['String']>;
  shiftDate?: Types.InputMaybe<Types.Scalars['Date']>;
  shiftScheduledEndTime?: Types.InputMaybe<Types.Scalars['String']>;
  shiftScheduledStartTime?: Types.InputMaybe<Types.Scalars['String']>;
  rosteringEventId?: Types.InputMaybe<Types.Scalars['Int']>;
  status?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type RosteringExceptionReportUpdateMutation = { __typename?: 'Mutation', rosteringExceptionReportUpdate?: { __typename?: 'RosteringExceptionReportUpdatePayload', rosteringExceptionReport?: { __typename?: 'RosteringExceptionReport', id: string, location?: string | null, overtime?: string | null, sentToEsr: boolean, sentToEsrAt?: any | null, shiftDate?: any | null, shiftScheduledEndTime?: string | null, shiftScheduledStartTime?: string | null, status: Types.ExceptionReportStatusEnum, submittedAt?: any | null, rosteringExceptionReportReasons?: Array<{ __typename?: 'RosteringExceptionReportReason', name: string, id: string, rosteringExceptionReportReasonCategory: { __typename?: 'RosteringExceptionReportReasonCategory', allowCompensation: boolean, name: string, id: string } }> | null, rosteringEvent?: { __typename?: 'RosteringEvent', id: string, startsAt: any, endsAt: any, roster: { __typename?: 'RosteringRoster', id: number, rota: { __typename?: 'RosteringRota', id: string, name: string } }, scheduleable?: { __typename?: 'RosteringShift', shiftType?: { __typename?: 'RosteringShiftType', id: string, name: string } | null } | null } | null } | null, errors: Array<{ __typename?: 'ValidationError', attribute?: string | null, message: string }> } | null };


export const RosteringExceptionReportCreateDocument = gql`
    mutation RosteringExceptionReportCreate($rosteringOrganisationRegistrationId: Int!, $breakActualTime: String, $details: String, $breakScheduledTime: String, $differenceInWorkedHours: NoYesEnum, $mostSeniorClinician: String, $mostSeniorClinicianId: Int, $patientSafetyImpacted: NoYesEnum, $requestedCompensation: ExceptionReportRequestedCompensationEnum, $rosteringExceptionReportReasonIds: [Int!], $location: String, $shiftActualEndTime: String, $shiftActualStartTime: String, $shiftDate: Date, $shiftScheduledEndTime: String, $shiftScheduledStartTime: String, $rosteringEventId: Int) {
  rosteringExceptionReportCreate(
    rosteringOrganisationRegistrationId: $rosteringOrganisationRegistrationId
    breakActualTime: $breakActualTime
    details: $details
    breakScheduledTime: $breakScheduledTime
    differenceInWorkedHours: $differenceInWorkedHours
    mostSeniorClinician: $mostSeniorClinician
    mostSeniorClinicianId: $mostSeniorClinicianId
    patientSafetyImpacted: $patientSafetyImpacted
    requestedCompensation: $requestedCompensation
    rosteringExceptionReportReasonIds: $rosteringExceptionReportReasonIds
    location: $location
    shiftActualEndTime: $shiftActualEndTime
    shiftActualStartTime: $shiftActualStartTime
    shiftDate: $shiftDate
    shiftScheduledEndTime: $shiftScheduledEndTime
    shiftScheduledStartTime: $shiftScheduledStartTime
    rosteringEventId: $rosteringEventId
  ) {
    errors {
      ...Error
    }
    rosteringExceptionReport {
      ...ExceptionReport
    }
  }
}
    ${ErrorFragmentDoc}
${ExceptionReportFragmentDoc}`;
export type RosteringExceptionReportCreateMutationFn = Apollo.MutationFunction<RosteringExceptionReportCreateMutation, RosteringExceptionReportCreateMutationVariables>;

/**
 * __useRosteringExceptionReportCreateMutation__
 *
 * To run a mutation, you first call `useRosteringExceptionReportCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRosteringExceptionReportCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rosteringExceptionReportCreateMutation, { data, loading, error }] = useRosteringExceptionReportCreateMutation({
 *   variables: {
 *      rosteringOrganisationRegistrationId: // value for 'rosteringOrganisationRegistrationId'
 *      breakActualTime: // value for 'breakActualTime'
 *      details: // value for 'details'
 *      breakScheduledTime: // value for 'breakScheduledTime'
 *      differenceInWorkedHours: // value for 'differenceInWorkedHours'
 *      mostSeniorClinician: // value for 'mostSeniorClinician'
 *      mostSeniorClinicianId: // value for 'mostSeniorClinicianId'
 *      patientSafetyImpacted: // value for 'patientSafetyImpacted'
 *      requestedCompensation: // value for 'requestedCompensation'
 *      rosteringExceptionReportReasonIds: // value for 'rosteringExceptionReportReasonIds'
 *      location: // value for 'location'
 *      shiftActualEndTime: // value for 'shiftActualEndTime'
 *      shiftActualStartTime: // value for 'shiftActualStartTime'
 *      shiftDate: // value for 'shiftDate'
 *      shiftScheduledEndTime: // value for 'shiftScheduledEndTime'
 *      shiftScheduledStartTime: // value for 'shiftScheduledStartTime'
 *      rosteringEventId: // value for 'rosteringEventId'
 *   },
 * });
 */
export function useRosteringExceptionReportCreateMutation(baseOptions?: Apollo.MutationHookOptions<RosteringExceptionReportCreateMutation, RosteringExceptionReportCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RosteringExceptionReportCreateMutation, RosteringExceptionReportCreateMutationVariables>(RosteringExceptionReportCreateDocument, options);
      }
export type RosteringExceptionReportCreateMutationHookResult = ReturnType<typeof useRosteringExceptionReportCreateMutation>;
export type RosteringExceptionReportCreateMutationResult = Apollo.MutationResult<RosteringExceptionReportCreateMutation>;
export type RosteringExceptionReportCreateMutationOptions = Apollo.BaseMutationOptions<RosteringExceptionReportCreateMutation, RosteringExceptionReportCreateMutationVariables>;
export const RosteringExceptionReportUpdateDocument = gql`
    mutation RosteringExceptionReportUpdate($rosteringExceptionReportUpdateId: Int!, $breakActualTime: String, $breakScheduledTime: String, $details: String, $differenceInWorkedHours: NoYesEnum, $location: String, $mostSeniorClinician: String, $patientSafetyImpacted: NoYesEnum, $requestedCompensation: ExceptionReportRequestedCompensationEnum, $rosteringExceptionReportReasonIds: [Int!], $shiftActualEndTime: String, $shiftActualStartTime: String, $shiftDate: Date, $shiftScheduledEndTime: String, $shiftScheduledStartTime: String, $rosteringEventId: Int, $status: String) {
  rosteringExceptionReportUpdate(
    id: $rosteringExceptionReportUpdateId
    breakActualTime: $breakActualTime
    breakScheduledTime: $breakScheduledTime
    details: $details
    differenceInWorkedHours: $differenceInWorkedHours
    location: $location
    mostSeniorClinician: $mostSeniorClinician
    patientSafetyImpacted: $patientSafetyImpacted
    requestedCompensation: $requestedCompensation
    rosteringExceptionReportReasonIds: $rosteringExceptionReportReasonIds
    shiftActualEndTime: $shiftActualEndTime
    shiftActualStartTime: $shiftActualStartTime
    shiftDate: $shiftDate
    shiftScheduledEndTime: $shiftScheduledEndTime
    shiftScheduledStartTime: $shiftScheduledStartTime
    rosteringEventId: $rosteringEventId
    status: $status
  ) {
    rosteringExceptionReport {
      ...ExceptionReport
    }
    errors {
      ...Error
    }
  }
}
    ${ExceptionReportFragmentDoc}
${ErrorFragmentDoc}`;
export type RosteringExceptionReportUpdateMutationFn = Apollo.MutationFunction<RosteringExceptionReportUpdateMutation, RosteringExceptionReportUpdateMutationVariables>;

/**
 * __useRosteringExceptionReportUpdateMutation__
 *
 * To run a mutation, you first call `useRosteringExceptionReportUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRosteringExceptionReportUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rosteringExceptionReportUpdateMutation, { data, loading, error }] = useRosteringExceptionReportUpdateMutation({
 *   variables: {
 *      rosteringExceptionReportUpdateId: // value for 'rosteringExceptionReportUpdateId'
 *      breakActualTime: // value for 'breakActualTime'
 *      breakScheduledTime: // value for 'breakScheduledTime'
 *      details: // value for 'details'
 *      differenceInWorkedHours: // value for 'differenceInWorkedHours'
 *      location: // value for 'location'
 *      mostSeniorClinician: // value for 'mostSeniorClinician'
 *      patientSafetyImpacted: // value for 'patientSafetyImpacted'
 *      requestedCompensation: // value for 'requestedCompensation'
 *      rosteringExceptionReportReasonIds: // value for 'rosteringExceptionReportReasonIds'
 *      shiftActualEndTime: // value for 'shiftActualEndTime'
 *      shiftActualStartTime: // value for 'shiftActualStartTime'
 *      shiftDate: // value for 'shiftDate'
 *      shiftScheduledEndTime: // value for 'shiftScheduledEndTime'
 *      shiftScheduledStartTime: // value for 'shiftScheduledStartTime'
 *      rosteringEventId: // value for 'rosteringEventId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useRosteringExceptionReportUpdateMutation(baseOptions?: Apollo.MutationHookOptions<RosteringExceptionReportUpdateMutation, RosteringExceptionReportUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RosteringExceptionReportUpdateMutation, RosteringExceptionReportUpdateMutationVariables>(RosteringExceptionReportUpdateDocument, options);
      }
export type RosteringExceptionReportUpdateMutationHookResult = ReturnType<typeof useRosteringExceptionReportUpdateMutation>;
export type RosteringExceptionReportUpdateMutationResult = Apollo.MutationResult<RosteringExceptionReportUpdateMutation>;
export type RosteringExceptionReportUpdateMutationOptions = Apollo.BaseMutationOptions<RosteringExceptionReportUpdateMutation, RosteringExceptionReportUpdateMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRosteringExceptionReportCreateMutation((req, res, ctx) => {
 *   const { rosteringOrganisationRegistrationId, breakActualTime, details, breakScheduledTime, differenceInWorkedHours, mostSeniorClinician, mostSeniorClinicianId, patientSafetyImpacted, requestedCompensation, rosteringExceptionReportReasonIds, location, shiftActualEndTime, shiftActualStartTime, shiftDate, shiftScheduledEndTime, shiftScheduledStartTime, rosteringEventId } = req.variables;
 *   return res(
 *     ctx.data({ rosteringExceptionReportCreate })
 *   )
 * })
 */
export const mockRosteringExceptionReportCreateMutation = (resolver: ResponseResolver<GraphQLRequest<RosteringExceptionReportCreateMutationVariables>, GraphQLContext<RosteringExceptionReportCreateMutation>, any>) =>
  graphql.mutation<RosteringExceptionReportCreateMutation, RosteringExceptionReportCreateMutationVariables>(
    'RosteringExceptionReportCreate',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRosteringExceptionReportUpdateMutation((req, res, ctx) => {
 *   const { rosteringExceptionReportUpdateId, breakActualTime, breakScheduledTime, details, differenceInWorkedHours, location, mostSeniorClinician, patientSafetyImpacted, requestedCompensation, rosteringExceptionReportReasonIds, shiftActualEndTime, shiftActualStartTime, shiftDate, shiftScheduledEndTime, shiftScheduledStartTime, rosteringEventId, status } = req.variables;
 *   return res(
 *     ctx.data({ rosteringExceptionReportUpdate })
 *   )
 * })
 */
export const mockRosteringExceptionReportUpdateMutation = (resolver: ResponseResolver<GraphQLRequest<RosteringExceptionReportUpdateMutationVariables>, GraphQLContext<RosteringExceptionReportUpdateMutation>, any>) =>
  graphql.mutation<RosteringExceptionReportUpdateMutation, RosteringExceptionReportUpdateMutationVariables>(
    'RosteringExceptionReportUpdate',
    resolver
  )
