import { useContext, useRef, useState } from "react";
import { CloseButton, Modal, ModalTitle } from "react-bootstrap";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";

import { DefaultButton, ModalHeader } from "@patchworkhealth/web-components";

import { FormGrid } from "components/UI/Form";
import { ExtendedOrganisation } from "containers/organisations";
import { AppContext } from "context/AppContext";
import { COLLAB_BANK_JOIN } from "gql/Mutations";
import { formatError } from "helpers/formatError";
import { OrgPendingIcon, OrgTickIcon } from "icons/organisationIcons";

import { ModalFooter } from "../Agencies/AgencyModal";
import {
  CollabBankIcon,
  defaultImgOnError,
  truncate,
} from "./OrganisationsHelpers";

type OnCallModalProps = {
  onHide: () => void;
  org: ExtendedOrganisation | null;
  handleJoinOrg: (org: ExtendedOrganisation) => void;
  refetch: () => void;
};

interface InputProps {
  page: number;
  isDrawing: boolean;
  hasInput: boolean;
}

const initialInputs: InputProps = {
  page: 1,
  isDrawing: false,
  hasInput: false,
};

const OrganisationsViewModal = ({
  onHide,
  org,
  handleJoinOrg,
  refetch,
}: OnCallModalProps) => {
  /* State --------------------------------------------- */

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { user, fetchUser } = useContext(AppContext);
  const [inputs, setInputs] = useState(initialInputs);
  const [joinCollabMutation] = useMutation(COLLAB_BANK_JOIN);

  /* Functions ----------------------------------------- */

  const handleJoinCollabBank = () => {
    const toastId = toast.loading("Loading...");

    joinCollabMutation({
      variables: {
        collaborativeBankId: org?.collaborativeBank?.id,
        primaryOrganisationId: org?.id,
        signature: canvasRef.current
          ?.toDataURL("image/png")
          .split(";base64,")[1],
      },
    })
      .then((res) => {
        fetchUser && fetchUser();
        const errors = res.data.joinCollaborativeBank.errors;

        if (errors.length > 0) {
          toast.error(formatError(errors), {
            id: toastId,
          });
          return;
        }

        toast.success("Accepted at Collaborative Bank!", {
          id: toastId,
        });

        onHide();
        refetch();
      })
      .catch(() => {
        toast.error("There was a network error, please try again!", {
          id: toastId,
        });
        onHide();
      });
  };

  /* Canvas  --------------------------------------- */

  const startDrawing = () => {
    setInputs({
      ...inputs,
      isDrawing: true,
      hasInput: true,
    });
  };

  const stopDrawing = () => setInputs({ ...inputs, isDrawing: false });

  const handleMouseMove = (e: MouseEvent) => {
    if (!inputs.isDrawing) return;

    const canvas = canvasRef?.current as unknown as HTMLCanvasElement;
    const ctx = canvas?.getContext("2d");
    const rect = canvas?.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    ctx?.lineTo(x, y);
    ctx?.stroke();
  };

  const clearSignature = () => {
    const canvas = canvasRef.current as unknown as HTMLCanvasElement;
    const ctx = canvas!.getContext("2d");

    if (ctx) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.beginPath();
      setInputs({ ...inputs, hasInput: false });
    }
  };

  return (
    <Modal show={true} onHide={onHide} backdrop="static" centered size="lg">
      <ModalHeader>
        <ModalTitle>
          {truncate(org?.name ?? "Org")} ({org?.id})
        </ModalTitle>
        <CloseButton onClick={onHide} />
      </ModalHeader>

      <div className="p-12 border-b">
        {inputs.page === 1 && (
          <>
            <img
              alt="Organisation"
              className="max-w-[200px] h-[100px] border mb-8 mx-auto"
              onError={defaultImgOnError}
              src={`${process.env.REACT_APP_API}/${org?.logoUrl}`}
            />

            {/* User has applied & been accepted to Org */}
            {org?.approved && (
              <p className="text-center mb-7">
                You are active on the bank! This means you can view and book
                shifts at this Organisation. Please direct any questions about
                payments and payroll to the Organisation directly, using the
                contact details below.
              </p>
            )}

            {/* User has applied & is waiting for approval */}
            {org?.approved === false && (
              <p className="text-center mb-7">
                You've done your bit <strong>{user?.firstName}</strong>, your
                application is now with <strong> {org?.name}.</strong>. If you
                have any questions about the status of your application, please
                reach out to the Organisation directly, using the contact
                details below.
              </p>
            )}

            {/* User has not applied to Org */}
            {org?.approved === null && (
              <p className="text-center mb-7">{org.description ?? ""}</p>
            )}

            {/* Show Collab Bank card if Org is part of Collaborative Bank */}
            {org?.partOfCollab && (
              <div
                style={{ background: "#E6F2F9" }}
                className="relative w-full py-5 pr-5 mb-4 text-sm rounded-lg pl-14"
              >
                <CollabBankIcon />

                {org?.approved === true && !org.joinedCollabBank && (
                  <p className="mb-4 font-bold">Join this Collaborate Bank</p>
                )}

                {org?.approved === true && org.joinedCollabBank && (
                  <p className="mb-4 font-bold">
                    You've joined this Collaborative Bank
                  </p>
                )}

                {org?.approved !== true && (
                  <p className="mb-4 font-bold">
                    Part of the {org?.collaborativeBank?.name}
                  </p>
                )}

                <p className="mb-3">
                  Part of the <strong>{org?.collaborativeBank?.name}</strong>,
                  once joined you'll be able to book shifts at:
                </p>

                {org?.collaborativeBank?.organisations.map((bank) => (
                  <p key={bank.id} className="font-[500] mb-1">
                    {bank.name}
                  </p>
                ))}

                {org?.approved === true && !org.joinedCollabBank && (
                  <DefaultButton
                    onClick={() => setInputs({ ...inputs, page: 2 })}
                    color="white"
                    type="white"
                    text="Join Collaborative Bank"
                    style={{ marginTop: "1rem" }}
                  />
                )}
              </div>
            )}

            {/* User has applied - either accepted or pending -------------------- */}

            {org?.approved !== null && (
              <FormGrid columns={2}>
                <div>
                  <p className="font-bold text-[10px] mb-1 uppercase">
                    Org Application Status:
                  </p>
                  <div className="flex items-center">
                    {org?.approved ? <OrgTickIcon /> : <OrgPendingIcon />}
                    <p className="ml-3 font-bold text-black-5">
                      {org?.approved ? "Application Accepted" : "In progress"}
                    </p>
                  </div>

                  <p className="mt-6 font-bold text-[10px] mb-1 uppercase">
                    Org Defined Grade:
                  </p>
                  <p className="font-bold text-black-5">{org?.grade ?? "-"}</p>

                  <p className="mt-6 font-bold text-[10px] mb-1 uppercase">
                    ESR NO:
                  </p>
                  <p className="font-bold text-black-5">
                    {org?.esrNumber ?? "-"}
                  </p>
                </div>
              </FormGrid>
            )}
          </>
        )}

        {inputs.page === 2 && (
          <div>
            {org?.collaborativeBank?.contractText !== null && (
              <div className="border rounded-lg bg-grey-1 max-h-[290px] overflow-y-auto p-8 mb-4">
                <p className="text-sm">
                  {org?.collaborativeBank?.contractText}
                </p>
              </div>
            )}

            <p className="m-6 text-center">
              By signing below you agree to the{" "}
              <strong>Collaborative Bank Agreement Form</strong>. You also agree
              that your data will be shared amongst organisations in the
              collaborative bank.
            </p>

            <div className="relative">
              <canvas
                ref={canvasRef}
                width={380}
                height={200}
                className="w-[380px] mx-auto border h-[200px] rounded-lg bg-grey-1"
                onMouseDown={startDrawing}
                onMouseUp={stopDrawing}
                onMouseOut={stopDrawing}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onMouseMove={handleMouseMove}
              />
              <br />
              {inputs.hasInput && (
                <button
                  className="absolute top-4 right-[190px]"
                  onClick={clearSignature}
                >
                  Clear
                </button>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Modal Footer ---------------------------------------- */}

      <ModalFooter>
        <div />
        <div className="flex p-4">
          <DefaultButton
            onClick={onHide}
            color="white"
            type="white"
            text="Cancel"
          />

          {inputs.page === 1 && org?.approved === null && (
            <DefaultButton
              color="blue"
              text="Join this Organisation"
              onClick={() => handleJoinOrg(org)}
            />
          )}

          {inputs.page === 2 && (
            <DefaultButton
              color="blue"
              style={{ opacity: inputs.hasInput ? 1 : 0.5 }}
              text="Agree & Sign"
              onClick={handleJoinCollabBank}
            />
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default OrganisationsViewModal;
