import styled from "styled-components";

import {
  BLUE,
  DefaultButton,
  FormErrorLabel,
  FormLabel,
  GLOBAL,
  GREY,
  RBPURPLE,
  RED,
} from "@patchworkhealth/web-components";

export const RequiredLabel = styled(FormLabel)<{ required?: boolean }>`
  color: ${GREY.ten};
  margin-left: 0;
  margin-bottom: 5px;
  margin-top: 24px;
  font-weight: 600;

  ${({ required }) =>
    required &&
    ` &:after {
    content: "*";
    color: ${RED.four};
  }`};
`;

export const ErrorLabel = styled(FormErrorLabel)`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: ${RED.four};
`;

export const ToggleWrapper = styled.div`
  && {
    > div > button {
      width: auto;
      > span {
        margin-right: 50px;
      }
    }
  }
`;

export const NoCompensationLabel = styled.div`
  background: ${BLUE.two};
  border-left: 10px solid ${BLUE.five};
  border-radius: 5px;
  padding: 5px 5px 5px 8px;
  width: 340px;
  margin-top: 10px;
  p {
    font-weight: 500;
  }
`;

export const Header = styled.h3`
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 1.5rem;
`;

export const Container = styled.div`
  background: ${GLOBAL.white};
  border-radius: 8px;
  margin-top: 10px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  min-width: 860px;
  max-width: 900px;
`;

export const NoSupervisorContainer = styled.div`
  background: ${RBPURPLE.one};
  border-left: 10px solid ${RBPURPLE.five};
  border-radius: 5px;
  padding: 10px 10px 10px 8px;
  margin: 10px 0;

  width: 85%;
  p {
    margin-bottom: 0;
  }
  p:nth-of-type(2) {
    font-weight: 500;
  }
`;

export const ConfirmButton = styled(DefaultButton)`
  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`;
