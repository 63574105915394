import styled from "styled-components";

import {
  GREY,
  RBBLUE,
  RBGREY,
  RBPURPLE,
} from "@patchworkhealth/web-components";

export const Header = styled.p`
  text-transform: uppercase;
  font-size: 10px !important;
  color: ${GREY.five};
  font-weight: 700;
  margin: 0;
`;

export const EllipsisContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FullName = styled.span`
  color: ${GREY.ten};
  font-weight: 500;
  font-size: 14px !important;
  margin: 0;
`;

export const EmailAddress = styled.span`
  color: ${GREY.seven};
  font-size: 12px !important;
  font-weight: 500;
  text-decoration-line: underline;
`;

export const SupervisorContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: 5px;
  align-items: center;
  width: 250px;
`;
export const ViewMoreButton = styled.div<{ viewMore: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  svg {
    transform: rotate(${({ viewMore }) => (viewMore ? "90deg" : "-90deg")});
  }

  p {
    margin: 0;
    color: ${RBGREY.six};
    font-size: 12px;
    text-decoration-line: underline;
    font-weight: 500;
  }
`;

export const WrongSupervisorContainer = styled.div`
  background: ${RBPURPLE.one};
  border-radius: 8px;
  border-left: 14px solid ${RBPURPLE.five};
  padding: 10px;
  max-width: 450px;
  margin-top: 20px;
  p {
    color: ${RBBLUE.six};
    font-weight: 500;
    font-size: 12px;
  }
`;
