import { BeamerButton } from "components/Beamer/BeamerButton";

import { NavDivider } from "../Style";
import { NavigationLink } from "./NavigationLink";
import { useNavigationLinks } from "./NavigationLinks";

const Navigation = ({
  navigation,
}: {
  navigation?: "calendar_single" | "profile" | "expenses";
}) => {
  const navigationLinks = useNavigationLinks();

  if (navigation === "calendar_single") return null;

  return (
    <div className="bg-white border fixed bottom-0 border-t-0 left-0 top-[64px] w-[200px] pt-6 hidden lg:block z-50">
      {navigationLinks.map((link, index) =>
        link.divider ? (
          <NavDivider key={index} />
        ) : link.divider === false ? null : (
          <NavigationLink
            key={index}
            to={link.to || "/"}
            isActive={link.isActive || false}
            isHidden={link.isHidden}
            icon={link.icon}
            label={link.label}
            data-testid={link.testid}
          />
        )
      )}
      {process.env.NODE_ENV === "production" && (
        <div className="absolute bottom-0">
          <BeamerButton />
        </div>
      )}
    </div>
  );
};

export default Navigation;
