import styled from "styled-components";

import { ErrorContainer, GLOBAL, RBRED } from "@patchworkhealth/web-components";

import { InfoIcon } from "icons/InfoIcon";

type Props = {
  validationErrors: string[];
};
export const LeaveFormattedErrors = ({ validationErrors }: Props) => {
  const formatErrorToDisplay = (errorLines: string[]) => {
    const formattedMessage: JSX.Element[] = [];
    let bulletList: JSX.Element[] = [];

    errorLines.forEach((line, index) => {
      if (line.trim().startsWith("•")) {
        bulletList.push(
          <li key={`list-item-${index}`}>{line.trim().substring(1).trim()}</li>
        );
      } else {
        if (bulletList.length > 0) {
          formattedMessage.push(<ul key={`list-${index}`}>{bulletList}</ul>);
          bulletList = [];
        }
        formattedMessage.push(<p key={`paragraph-${index}`}>{line.trim()}</p>);
      }
    });

    if (bulletList.length > 0) {
      formattedMessage.push(<ul key="ul-last">{bulletList}</ul>);
    }

    return formattedMessage;
  };

  const errorText = validationErrors.length > 1 ? "errors" : "error";

  return (
    <ErrorSection data-testid="calculation_errors">
      <ErrorHeading>
        <InfoIcon strokeColour={RBRED.six} width="20" height="20" />
        <h4>
          Please correct the {errorText} before submitting your leave request
        </h4>
      </ErrorHeading>
      {validationErrors.map((error: string, index) => {
        const [errorHeading, ...rest] = error.split("\n");
        const errorToDisplay = formatErrorToDisplay(rest);

        return (
          <ValidationError key={`validation-error-${index}`}>
            <h5>{errorHeading}</h5>
            <MainSection>{errorToDisplay}</MainSection>
          </ValidationError>
        );
      })}
    </ErrorSection>
  );
};

const ErrorSection = styled(ErrorContainer)`
  background: ${RBRED.one};
  border-radius: 8px;
  border: 1px solid ${RBRED.five};
  color: ${GLOBAL.darkBlue};
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  max-height: 150px;
  overflow-y: auto;
  width: calc(100% - 35px);

  h4 {
    color: inherit;
  }

  h5 {
    color: inherit;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  ul {
    list-style: disc;
    margin-left: 22px;
    margin-bottom: 5px;
  }
`;

const ErrorHeading = styled.div`
  align-items: center;
  display: flex;
  font-size: 12px;
  margin-bottom: 15px;

  svg {
    margin-right: 10px;
  }
`;

const ValidationError = styled.div`
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 16.8px;
`;

const MainSection = styled.div`
  line-height: 16.8px;
`;
