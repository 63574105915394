import * as Types from '../../../baseCODEGEN';

import { gql } from '@apollo/client';
export type ErrorFragment = { __typename?: 'ValidationError', attribute?: string | null, message: string };

export const ErrorFragmentDoc = gql`
    fragment Error on ValidationError {
  attribute
  message
}
    `;