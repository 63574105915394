import * as Types from '../../../baseCODEGEN';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from 'msw'
const defaultOptions = {} as const;
export type VmsAgencyInvitationQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type VmsAgencyInvitationQuery = { __typename?: 'Query', vmsAgencyInvitation: { __typename?: 'InvitationToken', userStatus: string, agencyRegistrationId: number, email: string } };


export const VmsAgencyInvitationDocument = gql`
    query VmsAgencyInvitation($token: String!) {
  vmsAgencyInvitation(token: $token) {
    userStatus
    agencyRegistrationId
    email
  }
}
    `;

/**
 * __useVmsAgencyInvitationQuery__
 *
 * To run a query within a React component, call `useVmsAgencyInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useVmsAgencyInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVmsAgencyInvitationQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVmsAgencyInvitationQuery(baseOptions: Apollo.QueryHookOptions<VmsAgencyInvitationQuery, VmsAgencyInvitationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VmsAgencyInvitationQuery, VmsAgencyInvitationQueryVariables>(VmsAgencyInvitationDocument, options);
      }
export function useVmsAgencyInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VmsAgencyInvitationQuery, VmsAgencyInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VmsAgencyInvitationQuery, VmsAgencyInvitationQueryVariables>(VmsAgencyInvitationDocument, options);
        }
export type VmsAgencyInvitationQueryHookResult = ReturnType<typeof useVmsAgencyInvitationQuery>;
export type VmsAgencyInvitationLazyQueryHookResult = ReturnType<typeof useVmsAgencyInvitationLazyQuery>;
export type VmsAgencyInvitationQueryResult = Apollo.QueryResult<VmsAgencyInvitationQuery, VmsAgencyInvitationQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockVmsAgencyInvitationQuery((req, res, ctx) => {
 *   const { token } = req.variables;
 *   return res(
 *     ctx.data({ vmsAgencyInvitation })
 *   )
 * })
 */
export const mockVmsAgencyInvitationQuery = (resolver: ResponseResolver<GraphQLRequest<VmsAgencyInvitationQueryVariables>, GraphQLContext<VmsAgencyInvitationQuery>, any>) =>
  graphql.query<VmsAgencyInvitationQuery, VmsAgencyInvitationQueryVariables>(
    'VmsAgencyInvitation',
    resolver
  )
